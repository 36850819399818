/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { I18N } from '../../../../tpz-i18n/tpz-i18n-static';
import { TpzMenuElement } from '../../../menu/tpz-menu';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzPlugin } from '../../tpz-plugin-core';
import { defaultMenuPluginConfig, MenuPlugin, MenuPluginConfig } from './plugin-menu';
import { SimpleMenuHelper } from './plugin-simple-menu-helper';
import { deepCopy } from '../../../tools/deep-copy';
import { TpzApplicationTypes } from '../../../tpz-application-types';

const SIMPLE_MENU_PLUGIN_TYPE: string = 'SimpleMenuPluginType';

/**
 * Simple menu plugin configuration
 */
export interface SimpleMenuPluginConfig extends MenuPluginConfig {
    reloadOnDesktopChange?: boolean;
}

/**
 * Simple menu plugin configuration
 */
const defaultSimpleMenuPluginConfig: SimpleMenuPluginConfig = {
    ...defaultMenuPluginConfig,
    type: SIMPLE_MENU_PLUGIN_TYPE,
    reloadOnDesktopChange: false
};

/**
 * Simple menu creates a menu using configuration description
 * Based on: https://line25.com/tutorials/how-to-create-a-pure-css-dropdown-menu
 */
export class SimpleMenuPlugin extends MenuPlugin {
    public static readonly SIMPLE_MENU_PLUGIN_TYPE: string = SIMPLE_MENU_PLUGIN_TYPE; // plugin type

    private rootElements: TpzMenuElement[] = [];

    /**
     * Constructor
     */
    constructor(config: SimpleMenuPluginConfig) {
        super(deepCopy(defaultSimpleMenuPluginConfig, config));
    }

    /**
     * config getter specialization
     */
    public getConfig(): SimpleMenuPluginConfig {
        return super.getConfig() as SimpleMenuPluginConfig;
    }

    /**
     * get root elements
     */
    public getRootElements(): TpzMenuElement[] {
        if (!this.rootElements) this.rootElements = [];
        return this.rootElements;
    }

    /**
     * add a root element
     */
    public addRootElements(element: TpzMenuElement): void {
        if (!this.rootElements) this.rootElements = [];
        this.rootElements.push(element);
    }

    /**
     * set root element
     */
    public setRootElements(elements: TpzMenuElement[]): void {
        this.rootElements = elements;
    }

    /**
     * Method to overload creating the menu content. This method is called in menu container creation
     * Use this.getMenuContainer().appendChild() method to insert elements in menu
     */
    public createMenuContent(): void {
        // empty container
        this.getMainContainer().innerHTML = '';
        // // append div brand to main container
        // let divBrand: HTMLDivElement = document.createElement('div');
        // this.getMenuContainer().appendChild(divBrand);
        // append nav to main container
        const nav: HTMLElement = document.createElement('nav');
        nav.append(this.createSubMenu(this.getRootElements()));
        this.getMainContainer().appendChild(nav);
    }

    /**
     * create HTMLElement from a menu element
     * (returns an array with a single <li> with menu element content)
     */
    protected createMenuItem(menuElement: TpzMenuElement): HTMLLIElement {
        if (!menuElement) return null;
        const liElement: HTMLLIElement = document.createElement('li');
        liElement.id = menuElement.id;

        // add clickale element
        const anchor: HTMLAnchorElement = document.createElement('a');
        anchor.href = '#';

        const div: HTMLSpanElement = document.createElement('div');
        anchor.appendChild(div);

        // add image icon
        if (menuElement.iconSrc) {
            const icon: HTMLImageElement = document.createElement('img');
            icon.src = menuElement.iconSrc;
            div.appendChild(icon);
        }
        // add menu name
        if (menuElement.name) {
            const span: HTMLSpanElement = document.createElement('span');
            span.innerText = menuElement.name;
            if (menuElement.i18n) span.setAttribute(I18N.I18N_ATTRIBUTE, menuElement.i18n);
            div.appendChild(span);
        }
        // add action
        if (menuElement.action) {
            anchor.addEventListener('click', menuElement.action);
        }
        // add html
        if (menuElement.html) {
            const span: HTMLSpanElement = document.createElement('span');
            span.innerHTML = menuElement.html;
            div.appendChild(span);
        }

        // TODO: add internationalization

        liElement.appendChild(anchor);

        // add sub elements. SubElements can be:
        // - an array of TpzElementMenu
        // - a function returning an array of TpzElementMenu
        if (menuElement.subElements) {
            liElement.appendChild(this.createSubMenu(menuElement.subElements));
        }
        return liElement;
    }

    /**
     * create a submenu
     * Creates a <ul> tag filled with all elements
     */
    protected createSubMenu(elements: TpzMenuElement[]): HTMLUListElement {
        if (!elements) return null;
        const ulElement: HTMLUListElement = document.createElement('ul');
        elements?.forEach((element: TpzMenuElement) => {
            const liElement: HTMLLIElement = this.createMenuItem(element);
            if (liElement) ulElement.append(liElement);
        });
        return ulElement;
    }

    /**
     * Application event callback
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.DESKTOP_DISPLAYED:
                TpzApplicationEventType.checkEvent(event, 'desktopId');
                if (this.getConfig().reloadOnDesktopChange) this.update();
                return true;
            case TpzApplicationEventType.ITEM_FACTORY_REGISTERED:
                TpzApplicationEventType.checkEvent(event, 'factory');
                this.update();
                return true;
            case TpzApplicationEventType.ADDON_STARTED:
            case TpzApplicationEventType.ADDON_STOPPED:
                TpzApplicationEventType.checkEvent(event, 'addOnId');
                this.getLogger().info(
                    event.type + ' #' + event.content.addOnId + ' => Update Simple Menu #' + this.getId()
                );
                this.update();
                return true;
            case TpzApplicationEventType.APPLICATION_STARTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.update();
                return true;
            case TpzApplicationEventType.ITEM_CONFIG_REGISTERED:
                TpzApplicationEventType.checkEvent(event, 'itemConfig');
                // update if registered item is a desktop to add it into the desktop list
                if (event.content?.itemConfig.type === TpzApplicationTypes.TPZ_DESKTOP_TYPE) this.update();
                return true;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * update menu when plugged
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then((plugin: TpzPlugin) => {
            this.update();
            return plugin;
        });
    }

    /**
     * update Menu by creating all menus elements (UL & LI)
     */
    public update(): Promise<void> {
        return super.update().then(() => {
            const menuConsistencyErrors: Error[] = SimpleMenuHelper.getConsistencyErrorsFromArray(
                this.getRootElements()
            );
            if (menuConsistencyErrors) {
                menuConsistencyErrors.forEach((error: Error) => {
                    this.getLogger().error('menu error: ', error);
                });
            }
        });
    }
}
