/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemConfig, defaultItemConfig } from '../../tpz-catalog/tpz-item-config';
import { JsPanelOptions } from '../jspanel-core';

/** JsPanel specific Event types */
export class TpzWindowEventTypes {
    public static readonly WINDOW_CREATED = 'WINDOW CREATED';
    public static readonly WINDOW_CLOSED = 'WINDOW CLOSED';
    public static readonly WINDOW_MINIMIZED = 'WINDOW MINIMIZED';
    public static readonly WINDOW_MAXIMIZED = 'WINDOW MAXIMIZED';
    public static readonly WINDOW_DISPLAYED = 'WINDOW DISPLAYED';
    public static readonly WINDOW_ICONIFIED = 'WINDOW ICONIFIED';
    public static readonly WINDOW_NORMALIZED = 'WINDOW NORMALIZED';
    public static readonly WINDOW_SMALLIFIED = 'WINDOW SMALLIFIED';
}

/**
 * Modules Parameters contain all values used to generate
 * a window of a special type via the Module Factory
 * This interface must be extended by modules types to add their specific values
 */
export interface TpzWindowConfig extends ItemConfig {
    left?: number;
    top?: number;
    width?: number;
    height?: number;
    jsPanelOptions?: JsPanelOptions; //
    title?: string; // title
    logoIcon?: string;
    i18nTitleId?: string; // title internationalization id
    frontZIndex?: number; // zindex of this window (used to set window as foreground or background)
    classes?: string[]; // list of classes set in window main container div
    updateOnResize?: boolean; // call update on resize event
    menuRemovalTime?: number; // time after which the logo menu is removed (in milliseconds)
    // childrenIds?: string[], // list of all views to be added in window
}

/**
 * Default values for Window configuration
 */
export const defaultTpzWindowConfig: TpzWindowConfig = {
    ...defaultItemConfig,
    // type is not defined for abstract classes
    title: 'untitled',
    logoIcon: null,
    i18nTitleId: null,
    frontZIndex: null,
    classes: [],
    updateOnResize: true,
    jsPanelOptions: {
        position: {
            my: 'center',
            at: 'center',
            minTop: 0,
            minLeft: 0
        },
        panelSize: '50% 50%'
        // dragit: {
        //     containment: 0,
        //     snap: true
        // }
    },
    menuRemovalTime: 2000
};
