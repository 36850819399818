/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../tpz-log/tpz-log-core';
import { I18NDictionary, I18NDictionaryManager } from './tpz-i18n-core';

/**
 * Translation class. This static class is only composed of static methods
 * A currentLanguage can be set
 * use setLogger() method to change logging system. Default is Logger.getDefaultLogger()
 */
export class I18N {
    public static readonly DEFAULT_GROUP_ID = 'default';
    public static readonly I18N_ATTRIBUTE: string = 'i18n';
    private static currentLanguage: string = null;
    private static manager: I18NDictionaryManager = null;
    private static logger: Logger = Logger.getDefaultLogger();

    /**
     * Static constructor/initializer
     */
    public static initialize(): void {
        I18N.manager = new I18NDictionaryManager();
        I18N.currentLanguage = '';
        I18N.logger = Logger.getDefaultLogger();
    }

    /**
     * Set logger to be used by I18N library
     */
    public static setLogger(logger: Logger): void {
        if (logger) I18N.logger = logger;
    }

    /**
     * Get logger defined by I18N library (can be null)
     * Use I18N.getLogger()?.error/info/...()
     */
    public static getLogger(): Logger {
        return I18N.logger;
    }

    /**
     * Get the current language
     */
    public static getLanguage(): string {
        return I18N.currentLanguage;
    }

    /**
     * Get a current dictionary
     */
    public static getCurrentDictionaries(): I18NDictionary[] {
        return this.manager.getDictionariesByLanguage(I18N.getLanguage());
    }

    /**
     * add dictionaries in Internationalization static
     * @param dict dictionary array resource
     */
    public static addDictionaries(dicts: I18NDictionary[]): boolean {
        return I18N.manager.addDictionaries(dicts);
    }

    /**
     * add dictionary in Internationalization manager
     * @param dict dictionary resource
     */
    public static addDictionary(dict: I18NDictionary): boolean {
        return I18N.manager.addDictionary(dict);
    }

    /**
     * get all dictionaries associated to a given language
     * @param lang language
     * @return undefined if no dictionaries were added
     */
    public static getDictionariesByLanguage(lang: string): I18NDictionary[] {
        return I18N.manager.getDictionariesByLanguage(lang);
    }

    /**
     * get a dictionary associated to a given language and a given groupId
     * @param lang language
     */
    public static getDictionaryByLanguageAndGroup(
        lang: string,
        groupId: string = I18N.DEFAULT_GROUP_ID
    ): I18NDictionary {
        return I18N.manager.getDictionaryByLanguageAndGroup(lang, groupId);
    }
}
