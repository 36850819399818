/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application/tpz-application-core';
import { ItemInstanceState } from '../tpz-catalog/tpz-catalog-core';
import { AccessorEvent, AccessorEventType } from './tpz-access-event';
import { AccessorValueImportExport, AccessorType } from './tpz-access-types';
import { defaultValueAccessorConfig, ValueAccessor, ValueAccessorConfig } from './tpz-access-value';
import { deepCopy } from '../tpz-application/tools/deep-copy';

/////////////////////////////////// QUEUED ACCESSORS /////////////////////////////

/**
 * Queued Accessor stores the n lasts values given by another Accessor
 * The queued accessor is a series accessor because it stores an array of Data (which can be values, series or any oher data type)
 */

export interface QueuedAccessorConfig<DataType> extends ValueAccessorConfig<DataType[]> {
    queueSize?: number;
}

// default config
export const defaultQueuedAccessorConfig: QueuedAccessorConfig<any> = {
    ...defaultValueAccessorConfig,
    queueSize: 1000
};

/**
 * Queued accessor collects data from a value accessor and creates an history of its values in a fixed size array
 */
export class QueuedAccessor<DataType> extends ValueAccessor<DataType[]> {
    //private accessorManager: CSAccessorManager<ValueAccessor<DataType>> = null;
    private inputAccessor: ValueAccessor<DataType> = null;

    /** Constructor */
    constructor(config: QueuedAccessorConfig<DataType>, app: TpzApplication) {
        super(
            deepCopy(defaultQueuedAccessorConfig, config),
            AccessorType.QUEUED_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringDefault,
            AccessorValueImportExport.convertToStringDefault
        );
        if (!this.getValue()) {
            this.setValue([]);
            this.applyConfig();
        }
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): QueuedAccessorConfig<DataType> {
        return super.getConfig() as QueuedAccessorConfig<DataType>;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: QueuedAccessorConfig<DataType> = null): boolean {
        let changes: boolean = false;
        const config: QueuedAccessorConfig<DataType> = this.getConfig();
        if (config?.queueSize !== newConfig?.queueSize) {
            // nothing to be done
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }

    /**
     * Remove all values from the queue
     */
    public clearQueue(): void {
        this.setValue([], true);
    }

    /** Truncate the current queue to the given size.
     * If size if greater than queue size: do nothing
     * Else truncate lasts elements to match exactly 'size' elements in the queue
     */
    private truncateArray(size: number): boolean {
        if (!size || size <= 0) return true; // undefined, negative or null ==> no trunc
        if (!this.getValue()) return false;
        if (this.getValue().length <= size) return false;
        const currentValue: DataType[] = this.getValue();
        this.setValue(currentValue.slice(currentValue.length - size, size + 1));
        return true;
    }

    /**
     * Retrieve data from input accessor and queued it
     */
    private addAccessorData(): void {
        if (!this.isRunning()) return;
        this.getAccessor().then((accessor: ValueAccessor<any>) => {
            const accessorValue: DataType = accessor.getValue();
            const configValues: DataType[] = this.getValue() || [];
            if (typeof accessorValue === 'undefined') return;
            this.setValue(configValues.concat(accessorValue));
            this.truncateArray(this.getQueueSize());
            if (this.getState() === ItemInstanceState.RUNNING) this.fireItemEvent(AccessorEventType.DATA, null);
        });
    }

    /**
     * Action to be performed on accessor change.
     * It fires an event only in state 'RUNNNING'
     */
    public onAccessorChange(event: AccessorEvent): boolean {
        if (this.getState() != ItemInstanceState.RUNNING) return false;
        if (event.type === AccessorEventType.DATA) this.addAccessorData();
        return true;
    }

    /** Accessor start function */
    public postStart(): Promise<void> {
        return super.postStart().then(() => {
            this.addAccessorData();
        });
    }

    /** Start converter */
    public doStart(): Promise<void> {
        return super.doStart();
    }

    /** Stop converter */
    public doStop(): Promise<void> {
        return super.doStop().then(() => {
            // stop listening to input accessor
            if (this.inputAccessor) {
                this.inputAccessor.removeCallback(this.getId());
            }
        });
    }

    /** get the current queue size */
    public getQueueSize(): number {
        return this.getConfig().queueSize;
    }
}
