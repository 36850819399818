/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application/tpz-application-core';
import {
    DefaultConverterAccessor,
    DefaultConverterAccessorConfig,
    defaultConverterAccessorConfig
} from './tpz-access-convert';
import { Accessor, AccessorFactory } from './tpz-access-core';
import {
    defaultNumberDiscretizerAccessorConfig,
    NumberDiscretizerAccessor,
    NumberDiscretizerAccessorConfig
} from './tpz-access-discretizer';
import { defaultQueuedAccessorConfig, QueuedAccessor, QueuedAccessorConfig } from './tpz-access-queued';
import {
    defaultJsonRemoteAccessorConfig,
    defaultStringRemoteAccessorConfig,
    JsonRemoteAccessor,
    JsonRemoteAccessorConfig,
    StringRemoteAccessor,
    StringRemoteAccessorConfig
} from './tpz-access-remote';
import { ValueAccessorConfig } from './tpz-access-value';
import { AccessorType, AccessorCategoryType } from './tpz-access-types';
import {
    defaultStaticValueAccessorConfig,
    defaultStaticValueAccessorNumberConfig,
    defaultStaticValueAccessorStringConfig,
    StaticValueAccessor,
    StaticValueAccessorNumber,
    StaticValueAccessorString
} from './tpz-access-value-static';
import {
    defaultNumberWebSocketAccessorConfig,
    defaultStringWebSocketAccessorConfig,
    NumberWebSocketAccessor,
    NumberWebSocketAccessorConfig,
    StringWebSocketAccessor,
    StringWebSocketAccessorConfig
} from './tpz-access-websocket';

//////////////////////////////////////////// Accessors Default Factory /////////////////////////////////////

/**
 * Default Accessor factory handles:
 * - QueuedAccessor.QUEUED_ACCESSOR_TYPE,
 * - StaticValueAccessor.STATIC_VALUE_ACCESSOR_TYPE,
 * - StringWebSocketAccessor.STRING_WEBSOCKET_ACCESSOR_TYPE,
 * - NumberWebSocketAccessor.NUMBER_WEBSOCKET_ACCESSOR_TYPE,
 */
export class DefaultAccessorFactory extends AccessorFactory {
    private readonly app: TpzApplication = null;
    public static readonly DEFAULT_ACCESSOR_FACTORY_TYPE: string = 'DefaultAccessorFactory';

    /** Constructor */
    constructor(app: TpzApplication) {
        super(DefaultAccessorFactory.DEFAULT_ACCESSOR_FACTORY_TYPE);
        this.app = app;
        this.addCategory(AccessorCategoryType.ACCESSOR_CATEGORY_TYPE);
        this.addHandledItem(
            AccessorType.QUEUED_ACCESSOR_TYPE,
            this.createQueuedAccessor.bind(this),
            defaultQueuedAccessorConfig
        );
        this.addHandledItem(
            AccessorType.NUMBER_STATIC_VALUE_ACCESSOR_TYPE,
            this.createStaticValueAccessorNumber.bind(this),
            defaultStaticValueAccessorNumberConfig
        );
        this.addHandledItem(
            AccessorType.STRING_STATIC_VALUE_ACCESSOR_TYPE,
            this.createStaticValueAccessorString.bind(this),
            defaultStaticValueAccessorStringConfig
        );
        this.addHandledItem(
            AccessorType.GENERIC_STATIC_VALUE_ACCESSOR_TYPE,
            this.createStaticValueAccessor.bind(this),
            defaultStaticValueAccessorConfig
        );
        this.addHandledItem(
            AccessorType.STRING_WEBSOCKET_ACCESSOR_TYPE,
            this.createStringWebSocketAccessor.bind(this),
            defaultStringWebSocketAccessorConfig
        );
        this.addHandledItem(
            AccessorType.NUMBER_WEBSOCKET_ACCESSOR_TYPE,
            this.createNumberWebSocketAccessor.bind(this),
            defaultNumberWebSocketAccessorConfig
        );
        this.addHandledItem(
            AccessorType.DEFAULT_CONVERTER_ACCESSOR_TYPE,
            this.createDefaultConverterAccessor.bind(this),
            defaultConverterAccessorConfig
        );
        this.addHandledItem(
            AccessorType.NUMBER_DISCRETIZER_ACCESSOR_TYPE,
            this.createNumberDiscretizerAccessor.bind(this),
            defaultNumberDiscretizerAccessorConfig
        );
        this.addHandledItem(
            AccessorType.STRING_REMOTE_ACCESSOR_TYPE,
            this.createStringRemoteAccessor.bind(this),
            defaultStringRemoteAccessorConfig
        );
        this.addHandledItem(
            AccessorType.JSON_REMOTE_ACCESSOR_TYPE,
            this.createJsonRemoteAccessor.bind(this),
            defaultJsonRemoteAccessorConfig
        );
    }

    /** stored application getter */
    public getApplication(): TpzApplication {
        return this.app;
    }

    /**  QueuedAccessor creator function */
    private createQueuedAccessor(config: QueuedAccessorConfig<any>): Promise<QueuedAccessor<any>> {
        return Promise.resolve(new QueuedAccessor<any>(config as QueuedAccessorConfig<any>, this.getApplication()));
    }

    /**  creator StaticValueAccessorNumber function */
    private createStaticValueAccessorNumber(config: ValueAccessorConfig<number>): Promise<StaticValueAccessorNumber> {
        return Promise.resolve(new StaticValueAccessorNumber(config, this.getApplication()));
    }

    /**  creator StaticValueAccessorString function */
    private createStaticValueAccessorString(config: ValueAccessorConfig<string>): Promise<StaticValueAccessorString> {
        return Promise.resolve(new StaticValueAccessorString(config, this.getApplication()));
    }

    /**  creator StaticValueAccessor function */
    private createStaticValueAccessor(config: ValueAccessorConfig<any>): Promise<StaticValueAccessor<any>> {
        return Promise.resolve(
            new StaticValueAccessor(config, AccessorType.GENERIC_STATIC_VALUE_ACCESSOR_TYPE, this.getApplication())
        );
    }

    /**  creator StringWebSocketAccessor function */
    private createStringWebSocketAccessor(config: StringWebSocketAccessorConfig): Promise<StringWebSocketAccessor> {
        return Promise.resolve(new StringWebSocketAccessor(config, this.getApplication()));
    }

    /**  creator NumberWebSocketAccessor function */
    private createNumberWebSocketAccessor(config: NumberWebSocketAccessorConfig): Promise<NumberWebSocketAccessor> {
        return Promise.resolve(new NumberWebSocketAccessor(config, this.getApplication()));
    }

    /**  creator DefaultConverterAccessor function */
    private createDefaultConverterAccessor(config: DefaultConverterAccessorConfig): Promise<DefaultConverterAccessor> {
        return Promise.resolve(new DefaultConverterAccessor(config, this.getApplication()));
    }

    /**  creator NumberDiscretizerAccessor function */
    private createNumberDiscretizerAccessor(
        config: NumberDiscretizerAccessorConfig
    ): Promise<NumberDiscretizerAccessor> {
        return Promise.resolve(new NumberDiscretizerAccessor(config, this.getApplication()));
    }

    /**  creator StringRemoteAccessor function */
    private createStringRemoteAccessor(config: StringRemoteAccessorConfig): Promise<StringRemoteAccessor> {
        return Promise.resolve(new StringRemoteAccessor(config, this.getApplication()));
    }

    /**  creator JsonRemoteAccessor function */
    private createJsonRemoteAccessor(config: JsonRemoteAccessorConfig): Promise<JsonRemoteAccessor> {
        return Promise.resolve(new JsonRemoteAccessor(config, this.getApplication()));
    }
}
