/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoggerDateFormat } from './dateformat';
import { Logger, LoggerMessage } from './tpz-log-core';
import { LoggerMessageFilter } from './tpz-log-filter';

/**
 * Appender is a object dealing with a message. It can write it on console, in a file or whatever...
 */
export abstract class Appender {
    private readonly type: string = 'undefined appender type';
    private dateFormat: string = 'ddd, mmmm dS, yyyy, HH:MM:ss';
    // method used to handle message or not
    private messageFilter: LoggerMessageFilter = null;

    /**
     * Appender constructor
     * @param type Appender type
     */
    constructor(type: string) {
        if (typeof type === 'undefined' || type === null || type.length === 0) {
            throw new Error('Appender type cannot be null');
        }
        this.type = type;
    }

    /** appender type getter */
    public getType(): string {
        return this.type;
    }

    /**
     * Do something with this event
     */
    public addLogMessage(message: LoggerMessage): Promise<void> {
        // if a filter is defined, return directly if message does not pass the filter test
        if (this.messageFilter && !this.messageFilter.filter(message)) return Promise.resolve(null);
        return this.addLogMessageInternal(message);
    }

    /**
     * Method handling message to be overloaded
     */
    protected abstract addLogMessageInternal(message: LoggerMessage): Promise<void>;

    /**
     * Set message filtering method
     * @param filter message filtering class
     */
    public setMessageFilter(filter: LoggerMessageFilter): void {
        this.messageFilter = filter;
    }

    /**
     * get the date format
     */
    public getDateFormat(): string {
        return this.dateFormat;
    }

    /**
     * set date format
     */
    public setDateFormat(format: string): void {
        this.dateFormat = format;
    }

    /**
     * format date as string
     * @param date date as number
     */
    public formatDate(date: number): string {
        return LoggerDateFormat.format(new Date(date), this.getDateFormat());
    }
    /*
     * format a logger message with a date format
     */
    public getFormatedMessage(message: LoggerMessage): string {
        return (
            '[' +
            this.formatDate(message.date) +
            '] - ' +
            Logger.getDefaultLevelName(message.level) +
            ' : ' +
            message.content
        );
    }
}

/**
 * Console Appender prints message on the console
 */
export class ConsoleAppender extends Appender {
    public static readonly CONSOLE_APPENDER_TYPE = 'ConsoleAppender';

    /** constructor */
    constructor() {
        super(ConsoleAppender.CONSOLE_APPENDER_TYPE);
    }

    /**
     * Console appender simply prints the log message on the console
     */
    protected addLogMessageInternal(message: LoggerMessage): Promise<void> {
        if (!message) return Promise.resolve(null);
        switch (message.level) {
            case Logger.INFO:
                console.info(this.getFormatedMessage(message));
                break;
            case Logger.DEBUG:
                console.debug(this.getFormatedMessage(message));
                break;
            case Logger.WARN:
                console.warn(this.getFormatedMessage(message));
                break;
            case Logger.ERROR:
                console.error(this.getFormatedMessage(message));
                break;
            case Logger.CRITICAL:
                console.error(this.getFormatedMessage(message));
                break;
            default:
                console.log(this.getFormatedMessage(message));
                break;
        }
        return Promise.resolve(null);
    }
}

/**
 * Buffered Appender stores logs in memory (limited buffer size)
 */
export class BufferedAppender extends Appender {
    public static readonly BUFFERED_APPENDER_TYPE = 'BufferedAppender';
    private readonly maxCount: number = 0;
    private messages: LoggerMessage[] = [];

    /** constructor */
    constructor(maxCount: number = 10000) {
        super(BufferedAppender.BUFFERED_APPENDER_TYPE);
        this.maxCount = maxCount;
    }

    /**
     * Buffered appender stores a limited number of messages in memory
     */
    protected addLogMessageInternal(message: LoggerMessage): Promise<void> {
        if (!message) return Promise.resolve(null);
        // if message list is full, remove the older ones
        if (this.messages.length >= this.maxCount) {
            this.messages = this.messages.slice(this.messages.length - this.maxCount + 1, this.maxCount);
        }
        this.messages.push(message);
        return Promise.resolve(null);
    }

    /**
     * Get all stored messages
     */
    public getMessages(): LoggerMessage[] {
        return this.messages;
    }

    /**
     * Clear all messages
     */
    public clearMessages(): void {
        this.messages = [];
    }
}
