/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Progress Loader configuration
 */
export interface ProgressLoaderConfig {
    id: string;
    type: string;
    title: string;
    progressClass: string;
    maxDescriptionLines: number;
    displayPercentage?: boolean; // add % value as text in progress bar (unused for indeterminate mode)
    closeDelai?: number; // time in ms after which the progress loader is closed
}

/**
 * Progress Loader Object
 * Progress Loader show the progression of some long term actions
 */
export abstract class ProgressLoader {
    public static readonly INDETERMINATED_CLASS: string = 'indeterminated';
    public static readonly VALUED_CLASS: string = 'valued';
    public static CLEAN_PROGRESSLOADER_TIMEOUT: number = 10000;
    private readonly config: ProgressLoaderConfig;
    private readonly source: any;
    private mainContainer: HTMLDivElement = null;
    private descriptionLines: string[] = [];
    private displayed: boolean = false;
    private progressValue: number = null; // progressValue is going from 0 to 1
    private cleanerTimeout: any = null; // timeout method used to stop loader after a timeout (in case of problems)
    private closeDelaiTimer: any = null; // timeout waiting the `closeDelai` time after really stopping this loader
    private readonly startTime: Date = null;

    /**
     * constructor
     */
    constructor(source: any, config: ProgressLoaderConfig) {
        this.source = source;
        this.config = config;
        this.startTime = new Date();
        this.cleanerTimeout = null;
        this.closeDelaiTimer = null;
    }

    /**
     * get progress loader source
     */
    public getSource(): any {
        return this.source;
    }

    /**
     * configuration getter
     */
    public getConfig(): ProgressLoaderConfig {
        return this.config;
    }

    /**
     * id getter
     */
    public getId(): string {
        return this.getConfig().id;
    }

    /**
     * start time getter
     */
    public getStartTime(): Date {
        return this.startTime;
    }

    /**
     * start time getter
     */
    public abstract getHTMLParentElement(): HTMLElement;

    /**
     * HTML main container id getter
     */
    public getMainContainerId(): string {
        return 'progress-loader-' + this.getId();
    }

    /**
     * HTML main container getter
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.id = this.getMainContainerId();
            this.mainContainer.classList.add(this.getConfig().progressClass);
            this.createUI(this.mainContainer);
        }
        return this.mainContainer;
    }

    /**
     * return if progress loader is indeterminate or is valuable (0..1)
     */
    public isIndeterminate(): boolean {
        return this.progressValue === null;
    }

    /**
     * Description Lines getter
     */
    public getDescriptionLines(): string[] {
        return this.descriptionLines;
    }

    /**
     * add a text description (add to existing)
     * @param description line text description to replace existing
     */
    public addDescription(description: string): void {
        if (!description) this.descriptionLines = [];
        else this.descriptionLines.push(description);
        this.updateUI();
    }

    /**
     * set a text description (replace existing)
     * @param description line text description to replace existing
     */
    public setDescription(description: string): void {
        this.descriptionLines = [description];
        this.updateUI();
    }

    /**
     * start loader (display in HTML)
     */
    public start(): void {
        if (this.closeDelaiTimer) {
            clearTimeout(this.closeDelaiTimer);
            this.closeDelaiTimer = null;
        }
        if (this.getHTMLParentElement()) {
            this.getHTMLParentElement().appendChild(this.getMainContainer());
            // create a sweeper function (in case of something wrong and unexpected appens)
            this.cleanerTimeout = setTimeout(() => {
                this.stop();
            }, ProgressLoader.CLEAN_PROGRESSLOADER_TIMEOUT);
            this.displayed = true;
        }
        this.updateUI();
    }

    /**
     * return the close delai in ms
     */
    public getCloseDelai(): number {
        return this.getConfig().closeDelai ?? 500;
    }

    /**
     * stop loader (remove from HTML)
     * It waits the 'closeDelai' time before returning
     * When Promise is fullfilled, check .isStopped() method
     * to see if progress hasn't been restarted...
     */
    public stop(): Promise<void> {
        return new Promise((resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) => {
            if (this.closeDelaiTimer) {
                clearTimeout(this.cleanerTimeout);
                this.closeDelaiTimer = null;
            }
            if (!this.displayed) {
                return resolve();
            }
            this.closeDelaiTimer = setTimeout(() => {
                if (this.getHTMLParentElement() && this.getHTMLParentElement().contains(this.getMainContainer())) {
                    this.getHTMLParentElement().removeChild(this.getMainContainer());
                    if (this.cleanerTimeout) clearTimeout(this.cleanerTimeout);
                    this.cleanerTimeout = null;
                    this.displayed = false;
                }
                return resolve();
            }, this.getCloseDelai());
        });
    }

    /**
     * Set the progress value of this loader
     * @param progress value between 0 & 1
     */
    public setProgress(progress: number): void {
        if (progress === null) {
            this.progressValue = null;
        } else {
            this.progressValue = Math.max(0, Math.min(1, progress));
        }
        this.updateUI();
    }

    /**
     * Progress value getter (value should be between 0 & 1)
     * or null if in indeterminate mode
     */
    public getProgressValue(): number {
        return this.progressValue;
    }
    /**
     * Return if the progress loader is currently displayed in UI
     */
    public isDisplayed(): boolean {
        return this.displayed;
    }

    /**
     * update UI Elements to reflect progress changes
     */
    protected abstract updateUI(): void;
    /**
     * method called when displaying Progress Loader
     */
    protected abstract createUI(parent: HTMLDivElement): void;
}
