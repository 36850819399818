/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { ItemConfig, defaultItemConfig } from '../../tpz-catalog/tpz-item-config';
import { TpzApplicationFactory } from '../tpz-application-factory';
import { TpzApplicationUI } from '../tpz-application-ui';
import { uuidv4 } from '../tools/uuid';
import { deepCopy } from '../tools/deep-copy';
import { TpzDesktop } from '../desktop/tpz-desktop-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../tpz-application-event';
import { TpzApplicationCategories, TpzApplicationTypes } from '../tpz-application-types';

/**
 * desktop container view configuration. ChildrenIds must refer exclusively to Desktop Ids
 */
export interface DesktopContainerItemConfig extends ItemConfig {
    containerId?: string;
    classes?: string[];
}

/**
 * Default Desktop container displayer configuration
 */
export const defaultDesktopContainerConfig: DesktopContainerItemConfig = {
    ...defaultItemConfig,
    containerId: 'desktop-container',
    classes: ['desktop-container']
};

/**
 * Desktop container item is a simple HTML Div Element receiving TpzDesktop
 */
export abstract class DesktopContainerItem extends ItemInstance {
    private desktopContainer: HTMLDivElement = null;
    // current cached values from the state
    private desktopsMap: { [id: string]: TpzDesktop } = {}; // registered desktops

    /** Constructor */
    constructor(config: DesktopContainerItemConfig, type: string, application: TpzApplication) {
        super(deepCopy(defaultDesktopContainerConfig, config), type, application);
        this.addCategory(TpzApplicationCategories.TPZ_DESKTOP_CONTAINER_CATEGORY);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): DesktopContainerItemConfig {
        return super.getConfig() as DesktopContainerItemConfig;
    }

    /**
     * invalidate desktop configuration cache (force re-creation at next call)
     */
    private invalidateDesktopConfigMap(): void {
        this.desktopsMap = null;
    }

    /**
     * clear desktop manager state
     */
    public clear(): void {
        this.invalidateDesktopConfigMap();
    }

    /**
     * add a desktop to the cache
     * @param desktop desktop to be cached
     * @throw an error if the desktop id is not defined
     */
    private addDesktopToCache(desktop: TpzDesktop): void {
        if (!desktop) return;
        if (!desktop.getId()) throw new Error('Cannot cache a desktop with a null ID');
        if (!this.desktopsMap) this.desktopsMap = {};
        this.desktopsMap[desktop.getId()] = desktop;
    }

    /**
     * get a desktop from the cache
     * @param desktopID desktop Id to be retrieved
     */
    private getDesktopFromCache(desktopId: string): TpzDesktop {
        if (!desktopId) return null;
        if (!this.desktopsMap) this.desktopsMap = {};
        return this.desktopsMap[desktopId];
    }

    /**
     * get a registered desktop by its name. Use cache for further calls
     */
    public getDesktopById(desktopId: string): Promise<TpzDesktop> {
        if (!desktopId) throw new Error('Illegal parameter. desktop Id is null in DesktopManager::getDesktopById()');
        // try to retrieve existing desktop from cache
        let desktop: TpzDesktop = this.getDesktopFromCache(desktopId);
        if (desktop) return Promise.resolve(desktop);
        // retrieve it from ItemInstances
        return this.getItemCatalog()
            .getOrCreateInstanceById(desktopId)
            .then((item: ItemInstance) => {
                if (!item.containsCategory(TpzApplicationCategories.TPZ_DESKTOP_CATEGORY)) {
                    throw new Error(
                        `Asked for Desktop #${desktopId} which is not a desktop... type = ${item.getType()}`
                    );
                }
                desktop = item as TpzDesktop;
                this.addDesktopToCache(desktop);
                return desktop;
            });
    }

    /**
     * Add a div used to receives all desktops
     * @param parent this parent should be NULL as root item
     */
    public doPlugInParent(parent: ItemInstance): Promise<void> {
        if (parent) {
            throw new Error(
                `DesktopContainerItem is a special item which -currently- should not be enclosed in a parent (#${parent.getId()}). If you want to put a desktop container in other HTMLElement, you have to implement it`
            );
            // implementation may be only replacing
            // rootParent.appendChild(this.getDesktopContainerDiv());
            // by
            // rootParent.appendChild(parent ||? this.getDesktopContainerDiv());
            // to be tested
        }
        return Promise.resolve().then(() => {
            const rootParent: HTMLElement = this.getApplication().getApplicationDiv();
            if (!rootParent.contains(this.getDesktopContainerDiv())) {
                rootParent.appendChild(this.getDesktopContainerDiv());
            }
        });
    }

    /**
     * This item has no parent, it is plugged if desktopContainer is in the DOM
     * @returns
     */
    public isPlugged(): boolean {
        if (!this.desktopContainer) return false;
        return document.getElementById(this.desktopContainer.id) != null;
    }

    /**
     * Removes the div used to receives all desktops
     * @param parent this parent should be NULL as root item
     */
    public doUnplugFromParent(parent: ItemInstance): Promise<void> {
        return Promise.resolve().then(() => {
            const rootParent: HTMLElement = this.getApplication().getApplicationDiv();
            if (rootParent.contains(this.getDesktopContainerDiv())) {
                rootParent.removeChild(this.getDesktopContainerDiv());
            }
        });
    }

    /**
     * Main UI Container getter
     */
    public getDesktopContainerDiv(): HTMLDivElement {
        if (!this.desktopContainer) {
            this.desktopContainer = TpzApplicationUI.createDiv({
                id: this.getConfig().containerId || uuidv4(),
                classes: this.getConfig().classes
            });
        }
        return this.desktopContainer;
    }

    /**
     * Generic UI getter
     */
    public getUI(): HTMLElement {
        return this.getDesktopContainerDiv();
    }

    /**
     * get all desktops displayed in this desktop container
     */
    public getDisplayedDesktops(): TpzDesktop[] {
        return this.getManagedChildren() as TpzDesktop[];
    }

    /**
     * this method arranges desktops in the desktop container. It can be splitting, tabs, etc...
     */
    public abstract updateDesktopLayout(): void;

    /**
     * ToPaZ event management
     * @param event event received
     * @returns true if event has been treated
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            case TpzApplicationEventType.ITEM_PLUGGED:
            case TpzApplicationEventType.ITEM_UNPLUGGED:
                {
                    // if children have been added or removed to/from this desktop container, update Layout
                    TpzApplicationEventType.checkEvent(event, 'childId', 'parentId');
                    if (event.content.parentId === this.getId()) {
                        this.updateDesktopLayout();
                    }
                }
                break;
        }
        return super.onApplicationEvent(event);
    }
}

/**
 * desktop container view configuration. ChildrenIds must refer exclusively to Desktop Ids
 */
export type DesktopContainerItemHSplitConfig = DesktopContainerItemConfig;

/**
 * Default Desktop container displayer configuration
 */
export const defaultDesktopContainerHSplitConfig: DesktopContainerItemHSplitConfig = {
    ...defaultDesktopContainerConfig,
    type: TpzApplicationTypes.DESKTOP_CONTAINER_HSPLIT_TYPE
};

/**
 * This DesktopContainer arrange desktop in an horizontal splitting row
 */
export class DesktopContainerItemHSplit extends DesktopContainerItem {
    /** Constructor */
    constructor(config: DesktopContainerItemHSplitConfig, application: TpzApplication) {
        super(
            deepCopy(defaultDesktopContainerConfig, config),
            TpzApplicationTypes.DESKTOP_CONTAINER_HSPLIT_TYPE,
            application
        );
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): DesktopContainerItemHSplitConfig {
        return super.getConfig() as DesktopContainerItemHSplitConfig;
    }

    /**
     * JsPanel works only with absolute parent positioning. Desktops have to be
     * placed manually.
     * TODO: implement a TpzDesktopLayoutManager in order to switch between panels or
     * split screen (or whatever implemented)
     */
    public updateDesktopLayout(): void {
        const desktopParent: HTMLElement = this.getApplication().getApplicationDiv();
        if (!desktopParent) throw new Error('Desktop parent is not defined in updateLayout method');
        DesktopContainerItemHSplit.applyHorizontalSplit(desktopParent, this.getDisplayedDesktops());
    }

    /**
     * Split screen layout
     * @param desktopParent
     * @param desktops
     */
    private static applyHorizontalSplit(desktopParent: HTMLElement, desktops: TpzDesktop[]): void {
        if (!desktops) return;
        const nbDesktops: number = desktops.length;
        if (nbDesktops == 0) return;
        const clientRect = desktopParent.getBoundingClientRect();

        desktops.forEach((desktop, nDesktop) => {
            desktop.getMainContainerDiv().style.position = 'absolute';
            desktop.getMainContainerDiv().style.width = `${clientRect.width / nbDesktops}px`;
            desktop.getMainContainerDiv().style.height = `${clientRect.height}px`;
            desktop.getMainContainerDiv().style.top = '0px';
            desktop.getMainContainerDiv().style.left = `${nDesktop * (clientRect.width / nbDesktops)}px`;
        });
    }
}

/**
 * Factory handling DesktopContainer creation
 */
export class DesktopContainerFactory extends TpzApplicationFactory {
    private static readonly DESKTOP_CONTAINER_FACTORY_TYPE: string = 'DesktopContainerFactoryType';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(DesktopContainerFactory.DESKTOP_CONTAINER_FACTORY_TYPE, application);
        this.addHandledItem(
            TpzApplicationTypes.DESKTOP_CONTAINER_HSPLIT_TYPE,
            this.createDesktopContainerHSplit.bind(this),
            defaultDesktopContainerConfig
        );
    }

    /** DesktopContainer creator function */
    private createDesktopContainerHSplit(config: DesktopContainerItemConfig): Promise<DesktopContainerItem> {
        return Promise.resolve(new DesktopContainerItemHSplit(config, this.getApplication()));
    }
}
