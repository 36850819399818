/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationFactory } from '../tpz-application-factory';
import { TpzApplicationCategories, TpzApplicationTypes } from '../tpz-application-types';
import { defaultTpzDesktopConfig, TpzDesktopConfig } from './tpz-desktop-config';
import { TpzDesktop } from './tpz-desktop-core';

/**
 * Desktop factory
 */
export class TpzDesktopFactory extends TpzApplicationFactory {
    private static readonly DESKTOP_FACTORY_TYPE: string = 'TpzDesktopFactory';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(TpzDesktopFactory.DESKTOP_FACTORY_TYPE, application);
        this.addHandledItem(
            TpzApplicationTypes.TPZ_DESKTOP_TYPE,
            this.createDesktop.bind(this),
            defaultTpzDesktopConfig
        );
        this.addCategory(TpzApplicationCategories.TPZ_DESKTOP_CATEGORY);
    }

    /** Desktop creator function */
    private createDesktop(config: TpzDesktopConfig): Promise<TpzDesktop> {
        return Promise.resolve(new TpzDesktop(config, this.getApplication()));
    }
}
