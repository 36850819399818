/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventManager } from '../tpz-event/tpz-event-core';
import { Logger } from '../tpz-log/tpz-log-core';
import { LoggerHelper } from '../tpz-log/tpz-log-helper';
import { LibraryManager } from './libraries/library-manager';
import { TpzLibraryDescriptor } from './libraries/library-descriptor';
import { TpzPluginManager } from './plugins/tpz-plugin-manager';
import { LoadHelper, TpzProtocolResponse } from './tools/loader';
import { TpzApplicationEvent, TpzApplicationEventCategory, TpzApplicationEventType } from './tpz-application-event';
import { TpzDesktopManager } from './desktop/tpz-desktop-manager';
import { ProgressLoaderManager } from './progress-loader/progress-loader-manager';
import { TpzAddOnManager } from './addons/addons-manager';
import { ItemCatalog } from '../tpz-catalog/tpz-catalog-core';
import { ItemFactory } from '../tpz-catalog/tpz-item-factory';
import { ItemFactoryManager } from '../tpz-catalog/tpz-catalog-manager';
import { TpzSessionManager } from './server/session-manager';
import { I18NManager } from '../tpz-i18n/tpz-i18n-manager';
import { TpzApplicationState } from './tpz-application-state';
import { uuidv4 } from './tools/uuid';
import { TpzClientSession } from './server/session';
import { TpzClientSessionInformation, TpzServerInformation } from './server/server';
import { TpzDesktopFactory } from './desktop/tpz-desktop-factory';
import { deepCopy } from '../tpz-application/tools/deep-copy';
import { ApplicationConfigurationEditorViewFactory } from './items/instances/application-configuration/application-configuration-view';
import { DesktopContainerFactory } from './desktop/tpz-desktop-container-item';
import JSONEditorType, { JSONEditorOptions } from 'jsoneditor'; // types only

declare var JSONEditor: typeof JSONEditorType;
/**
 * Notification sent by messages/event
 */
export interface NotificationMessage {
    type: string;
    message: string;
}

/**
 * Application configuration
 */
export interface TpzApplicationConfig {
    id?: string;
    css?: string[];
    logEvents?: boolean;
    applicationContainerId?: string;
    backendURL?: string; // backend URL to connect to
    connectService?: string; // connect service path of the server
    addOnRegistryURLs?: string[]; // URLs where to find addons
    addOnIds?: string[]; // Add-ons to be loaded at application start
    authToken?: string; // authorization token
    variables?: { [id: string]: any }; // variable are a set of generic data injected into topaz application
    autoStart?: boolean; // if set to true, the application will launch ::start() method in the constructor
    autoConnect?: boolean; // if true, the application will try to reconnect automatically to the server in case of problems
    configFileURL?: string; // URL where to find config file. it can be absolute (http://...) or relative (others)
}

/**
 * Default Application configuration
 */
const defaultTpzApplicationConfig: TpzApplicationConfig = {
    id: 'tpz-application',
    css: ['css/topaz.css', 'css/progress-loader.css'],
    addOnRegistryURLs: [process.env.ADDON_REGISTRY_URL], // add-ons registry url. Is defined in .env file
    addOnIds: [], // list of addons to be loaded at startup
    logEvents: false, // log all events
    authToken: null, // authorization token
    variables: {}, // application defined variables
    autoStart: false, // autoStart is used in application template, it must be set to true in template configuration
    backendURL: process.env.BACK_END_URL, // backend url. Is defined in .env file
    connectService: '/connect',
    autoConnect: true // launch connection to the backendURL at application startup
};

/**
 * Main class application for Topaz single page application (SPA)
 */
export class TpzApplication {
    private static JSPANEL_BASE: string = `js/libs/jspanel4/dist`;
    private static JSPANEL_CSS_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/jspanel.css`;
    private static JSPANEL_MAIN_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/jspanel.js`;
    private static JSPANEL_EXT_MODAL_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/extensions/modal/jspanel.modal.js`;
    private static JSPANEL_EXT_TOOLTIP_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/extensions/tooltip/jspanel.tooltip.js`;
    private static JSPANEL_EXT_HINT_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/extensions/hint/jspanel.hint.js`;
    private static JSPANEL_EXT_LAYOUT_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/extensions/layout/jspanel.layout.js`;
    private static JSPANEL_EXT_CONTEXTMENU_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/extensions/contextmenu/jspanel.contextmenu.js`;
    private static JSPANEL_EXT_DOCK_LOCATION: string = `${TpzApplication.JSPANEL_BASE}/extensions/dock/jspanel.dock.js`;
    private static JSONEDITOR_BASE: string = `js/libs/jsoneditor/dist`;
    private static JSONEDITOR_CSS_LOCATION: string = `${TpzApplication.JSONEDITOR_BASE}/jsoneditor.css`;
    private static JSONEDITOR_TOPAZ_CSS_LOCATION: string = `css/json-editor.css`;
    private static JSONEDITOR_JS_LOCATION: string = `${TpzApplication.JSONEDITOR_BASE}/jsoneditor.js`;

    private config: TpzApplicationConfig = null;
    // frontend communication channel
    private eventManager: EventManager<TpzApplicationEvent> = null;
    // application logger
    private logger: Logger = null;
    // application plugins
    private pluginManager: TpzPluginManager = null;
    // application addon
    private addonManager: TpzAddOnManager = null;
    // library plugins
    private libraryManager: LibraryManager = null;
    // session manager
    private sessionManager: TpzSessionManager = null;
    // progress loader manager
    private progressLoaderManager: ProgressLoaderManager = null;
    // desktops
    private desktopManager: TpzDesktopManager = null;
    // Item catalog. This one MUST be started at the very beginning of the application
    private itemCatalog: ItemCatalog = null;
    // Application main DIV container
    private applicationDiv: HTMLDivElement = null;
    // FIXME: i18n is not under maintenance for a long time...
    private internationalizationManager: I18NManager = null;
    // private rootItem: RootApplicationItem = null;
    private serverConnectionError: boolean = null;
    // will be set to true if a problem occurs while connecting to server
    // and to false if the server is correcty connected

    /**
     * Constructor.
     * Configuration is mixed with defaultTpzApplicationConfig
     * @param config application configuration
     */
    constructor(config: TpzApplicationConfig) {
        const me: TpzApplication = this;
        this.config = deepCopy(defaultTpzApplicationConfig, config);
        // if id is not set, generates one
        if (!this.config.id) {
            this.config.id = uuidv4();
        }
        // item catalog is the first mandatory application component
        // desktop factory is mandatory
        this.getItemCatalog().getFactoryManager().registerItemFactory(new TpzDesktopFactory(this));

        // exception unhandled in Promises
        window.addEventListener('unhandledrejection', function (e: PromiseRejectionEvent) {
            if (typeof e === 'string') me.getLogger()?.error(`Promise uncaught exception: ${e as string}`);
            else {
                me.getLogger()?.error(
                    `Promise uncaught exception: Type = ${typeof e}. Reason = ${JSON.stringify(e.reason)}`
                );
            }
        });
        window.addEventListener('resize', () =>
            this.fireEvent(this.getId(), TpzApplicationEventType.WINDOW_RESIZED, null, [
                TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
            ])
        );
        this.registerDefaults();
        // launch ::start() method if autostart is set
        if (this.config.autoStart) this.start();
    }

    /**
     * Register mandatory items, factories and libraries
     */
    private registerDefaults(): void {
        // register ToPaZ configuration view Factory
        this.getItemCatalog()
            .getFactoryManager()
            .registerItemFactory(new ApplicationConfigurationEditorViewFactory(this));
        // register Desktop Factory
        this.getItemCatalog().getFactoryManager().registerItemFactory(new TpzDesktopFactory(this));

        // register Desktop Container Item Factory
        this.getItemCatalog().getFactoryManager().registerItemFactory(new DesktopContainerFactory(this));

        // register JSONEditor library
        // this.getLibraryManager().registerLibrary(JSON_EDITOR_LIBRARY_CONFIG);
    }
    // /**
    //  * Get the root item of this application (parent of any desktop)
    //  */
    // public getRootItem(): RootApplicationItem {
    //     if (!this.rootItem) this.rootItem = new RootApplicationItem(this);
    //     return this.rootItem;
    // }

    /**
     * Id getter (from config)
     */
    public getId(): string {
        return this.getConfig()?.id;
    }

    /**
     * Application Configuration getter (this is not a copy)
     */
    public getConfig(): TpzApplicationConfig {
        return this.config;
    }

    /**
     * Application Configuration getter
     */
    public getConfigSetter(): TpzApplicationConfig {
        return this.config;
    }

    /**
     * set the application configuration. This method does not automatically call
     * update(). You MUST do an application update request to take changes into account.
     * Use TpzApplicationCommander.requestApplicationUpdate()
     * @param applicationConfig new application configuration to be set
     * @throws an error if config is null
     */
    public setConfig(config: TpzApplicationConfig): void {
        if (!config) throw new Error('Invalid application configuration');
        // copy application configuration but the ID
        const id: string = this.getId();
        this.config = {
            ...config,
            id: id
        };
    }

    /**
     * set configuration backend URL
     * @param backendURL new URL to set
     */
    public setBackendURL(backendURL: string): void {
        if (this.getConfigSetter().backendURL != backendURL) {
            this.disconnectFromServer();
            this.getConfigSetter().backendURL = backendURL;
        }
    }

    /**
     * set the application variable with the given name
     * @param variableName name of the variable to set/replace
     * @param variableValue variable value to set
     */
    public setVariable(variableName: string, variableValue: any): void {
        if (!this.config.variables) this.getConfigSetter().variables = {};
        this.getConfigSetter().variables[variableName] = variableValue;
    }

    /**
     * unset the application variable with the given name
     * @param variableName name of the variable to set/replace
     */
    public unsetVariable(variableName: string): void {
        if (!this.config.variables) this.config.variables = {};
        delete this.getConfigSetter().variables[variableName];
    }

    /**
     * get the application variable with the given name
     * @param variableName name of the variable to retrieve
     * @returns the variable content with given name
     */
    public getVariable(variableName: string): string {
        if (!this.getConfig().variables) this.getConfigSetter().variables = {};
        return this.getConfigSetter().variables[variableName];
    }

    /**
     * get the current selected language
     */
    public getCurrentLang(): string {
        return 'fr';
    }

    /**
     * Get the main Application Div containing the application content.
     */
    public getApplicationDiv(): HTMLDivElement {
        if (!this.applicationDiv) {
            this.applicationDiv = document.createElement('div');
            this.applicationDiv.id = this.getConfig().applicationContainerId || `application-container-${uuidv4()}`;
            this.applicationDiv.classList.add('fullscreen');
            // border-box allow to simply compute desktop layout without taking into account border and padding size
            this.applicationDiv.style.boxSizing = 'border-box';
            // // add the desktop container
            // this.applicationDiv.appendChild(this.getDesktopsContainer())
        }
        return this.applicationDiv;
    }

    /**
     * Retrieve server information for connected server
     * return null if server is not connected
     */
    public getServerInformation(): TpzServerInformation {
        return this.getSessionManager().getCurrentSession()?.getServerInformation();
    }

    /**
     * get the application state of an application
     * @param stateId state id to give to this state
     * @param name name to give to this state
     * @param app application to get its state
     * @returns the application state
     */
    public getApplicationState(stateId: string, name: string): TpzApplicationState {
        const state: TpzApplicationState = {};
        state.id = stateId ? stateId : uuidv4();
        state.name = name;
        state.date = new Date().getTime();
        state.applicationConfig = this.getConfig();
        state.desktopManagerState = this.getDesktopManager().getState();
        state.itemCatalogConfigs = Object.values(this.getItemCatalog().getRegisteredConfigs());
        return state;
    }

    /**
     * set the application state of an application
     * @param state application state to apply
     * @param app application
     */
    private setApplicationState(state: TpzApplicationState): Promise<void> {
        if (!state) return Promise.reject(new Error('illegal application state'));
        if (!state.applicationConfig) throw new Error('Application configuration is null');
        const progressId: string = this.getProgressLoaderManager().addProgress(
            this.getId(),
            `set application state #${state.id}`
        );
        return Promise.resolve()
            .then(() => {
                // copy old application configuration
                const oldApplicationConfig: TpzApplicationConfig = {
                    ...this.getConfig()
                };
                this.getProgressLoaderManager().addProgressDescription(
                    progressId,
                    'Apply new application configuration'
                );
                this.setConfig(state.applicationConfig);
                this.getItemCatalog().clear();
            })
            .then(() => {
                // update addon registry URLs
                this.getProgressLoaderManager().addProgressDescription(progressId, 'Update AddOn registries');
                this.getAddOnManager().setAddOnRegistryURLs(this.getConfig().addOnRegistryURLs);
                return this.getAddOnManager().update();
            })
            .then(() => {
                this.getProgressLoaderManager().addProgressDescription(progressId, 'Update Items catalog');
                this.getItemCatalog().setState(state.itemCatalogConfigs);

                this.getProgressLoaderManager().addProgressDescription(progressId, 'Update Desktop Manager');
                return this.getDesktopManager().setState(state.desktopManagerState);
            })
            .then(() => {
                this.getProgressLoaderManager().addProgressDescription(progressId, 'restart application');
            })
            .finally(() => {
                this.getProgressLoaderManager().endProgress(progressId);
            });
    }

    // /**
    //  * update application when configuration has changed. User application is the one
    //  * stored in the application (this.currentApplicationState)
    //  */
    // private updateApplicationState(state: TpzApplicationState): Promise<void> {
    //     if (!state) return Promise.reject(new Error("illegal application state"));
    //     if (!state.applicationConfig) throw new Error("Application configuration is null");
    //     let progressId: string = this.getProgressLoaderManager().addProgress(this.getId(), "set application state #" + state.id);
    //     return Promise.resolve()
    //         .then(() => {
    //             this.getDesktopManager().removeAllDesktopConfigs();
    //             state.desktopManagerConfig?.forEach((desktopConfig: TpzDesktopConfig) => {
    //                 this.getProgressLoaderManager().addProgressDescription(progressId, "set desktop #" + desktopConfig.id);
    //                 this.getDesktopManager().registerDesktopConfig(desktopConfig);
    //             });
    //         }).then(() => {
    //             // copy old application configuration
    //             let oldApplicationConfig: TpzApplicationConfig = { ...this.getConfig() };
    //             this.getProgressLoaderManager().addProgressDescription(progressId, "Apply new application configuration");
    //             this.setConfig(state.applicationConfig);
    //             this.getProgressLoaderManager().addProgressDescription(progressId, "Clear Desktop Manager");
    //             // clear desktop manager
    //             return this.getDesktopManager().closeAllDesktops();
    //         }).then(() => {
    //             // update addon registry URLs
    //             this.getProgressLoaderManager().addProgressDescription(progressId, "Update AddOn registries");
    //             this.getAddOnManager().setAddOnRegistryURLs(this.getConfig().addOnRegistryURLs);
    //             return this.getAddOnManager().update();
    //         }).then(() => {
    //             this.getProgressLoaderManager().addProgressDescription(progressId, "Update Desktop Manager");
    //             return this.getDesktopManager().update();
    //         }).then(() => {
    //             this.getProgressLoaderManager().addProgressDescription(progressId, "restart application");
    //         }).finally(() => {
    //             this.getProgressLoaderManager().endProgress(progressId);
    //         });
    // }

    /**
     * stop application
     */
    public stop(): Promise<void> {
        return Promise.resolve()
            .then(() => this.getDesktopManager().stop())
            .then(() => this.getSessionManager().stop())
            .then(() => this.getLibraryManager().stop())
            .then(() => this.getAddOnManager().stop())
            .then(() => {
                //
            });
    }
    /**
     * Restart application. start() & stop()
     */
    public restart(): Promise<void> {
        return this.stop().then(() => this.start());
    }
    /**
     * Start application. This method should not be overloaded.
     * Overload preStart(), doStart() and postStart()
     */
    public start(): Promise<void> {
        const progressId: string = this.getProgressLoaderManager().addProgress(
            this,
            `starting application ${this.getId()}`
        );
        this.fireEvent(this.getId(), TpzApplicationEventType.APPLICATION_PRESTARTING, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
        this.getProgressLoaderManager().addProgressDescription(progressId, 'pre-starting');
        return this.preStart()
            .then(() => {
                this.fireEvent(this.getId(), TpzApplicationEventType.APPLICATION_STARTING, null, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
                this.getProgressLoaderManager().addProgressDescription(progressId, 'starting');
                return this.doStart();
            })
            .then(() => {
                this.fireEvent(this.getId(), TpzApplicationEventType.APPLICATION_POSTSTARTING, null, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
                this.getProgressLoaderManager().addProgressDescription(progressId, 'post-starting');
                return this.postStart();
            })
            .then(() => {
                this.getProgressLoaderManager().addProgressDescription(progressId, 'application started');
                this.fireEvent(this.getId(), TpzApplicationEventType.APPLICATION_STARTED, null, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
            })
            .finally(() => {
                this.getProgressLoaderManager().endProgress(progressId);
            });
    }

    /**
     * Load the jspanel library which defines a jsPanel global variable (old school :/)
     * It also load css and all jsPanel extensions
     * To create a new jsPanel you should
     * - import the jsPanel definition type => import \{JsPanel\} from "jspanel-core"
     *      import \{ ApplicationConfigurationEditorViewFactory \} from './items/instances/application-configuration/application-configuration-view';
     *      import \{ DesktopContainerFactory \} from './items/instances/desktop-container/desktop-container-item';
     * - tel typescript global variable 'jsPanel' exist => declare const jsPanel: JsPanel;
     * - use global variable => jsPanel.create...
     */
    private loadJsPanelLibrary(): Promise<void> {
        return (
            Promise.resolve()
                .then(() => LoadHelper.loadCSS(TpzApplication.JSPANEL_CSS_LOCATION))
                // load main jspanel first
                .then(() => LoadHelper.loadJsScripts([TpzApplication.JSPANEL_MAIN_LOCATION], this.getLogger()))
                .then(() =>
                    // then load all extensions (main has to be previously loaded)
                    LoadHelper.loadJsScripts(
                        [
                            TpzApplication.JSPANEL_EXT_MODAL_LOCATION,
                            TpzApplication.JSPANEL_EXT_TOOLTIP_LOCATION,
                            TpzApplication.JSPANEL_EXT_HINT_LOCATION,
                            TpzApplication.JSPANEL_EXT_LAYOUT_LOCATION,
                            TpzApplication.JSPANEL_EXT_CONTEXTMENU_LOCATION,
                            TpzApplication.JSPANEL_EXT_DOCK_LOCATION
                        ],
                        this.getLogger()
                    )
                )
        );
    }

    /**
     * Load the JSONEditor library using webpack dynamic chunk splitting mechanism
     * It also load css
     */
    private loadJsonEditorLibrary(): Promise<void> {
        return Promise.resolve()
            .then(() => LoadHelper.loadCSS(TpzApplication.JSONEDITOR_CSS_LOCATION))
            .then(() => LoadHelper.loadCSS(TpzApplication.JSONEDITOR_TOPAZ_CSS_LOCATION))
            .then(() => LoadHelper.loadJsScripts([TpzApplication.JSONEDITOR_JS_LOCATION], this.getLogger()));
    }

    /**
     * Get a promise over a newly created JSON Editor.
     * At first call JSONEditor library may be loaded.
     * use JSONEditor::set(json:any) method to set editor content
     * @param parent: div ni which jsonEditor may be loaded
     * @param argOptions JSONEditor Options can be given in argument. If ommited, a default configuration is used
     * @returns a newly created Editor
     */
    public async createJSONEditor(
        parent: HTMLDivElement,
        argOptions: Readonly<JSONEditorOptions> = null
    ): Promise<JSONEditorType> {
        const options: JSONEditorOptions = argOptions ?? {
            mode: 'tree',
            modes: ['code', 'form', 'text', 'tree', 'view', 'preview'] // allowed modes
        };
        // JSONEditor declares a global variable called JSONEditor which is a function creating an object
        // if JSON Editor library is not already loaded, try to load it first
        if (!JSONEditor) {
            return this.loadJsonEditorLibrary().then(() => {
                if (!JSONEditor) {
                    // if still not loaded, throws an error
                    throw new Error(`An error occurred loading JSONEditor: ${TpzApplication.JSONEDITOR_JS_LOCATION}`);
                }
                return new JSONEditor(parent, options);
            });
        }
        return new JSONEditor(parent, options);
    }

    /**
     * Load the external configuration if configFileURL is set in configuration.
     * It loop over all key/values from file and set them this.config object
     *  no check or validation is done...
     */
    private loadExternalConfiguration(): Promise<void> {
        if (!this.getConfig().configFileURL) return Promise.resolve();
        return Promise.resolve().then(() => {
            let configFileURL: string = this.getConfig().configFileURL;
            const origin = window.location.origin;
            //if config file URL is a relative one append server origin
            if (!configFileURL.startsWith(`http`)) {
                configFileURL = `${origin}/${this.getConfig().configFileURL}`;
            }
            this.getLogger().info(`Load external config File ${configFileURL}`);
            return fetch(configFileURL)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error fetching ${configFileURL}: ${response.status}`);
                    }
                    return response.json();
                })
                .then((json: any) => {
                    // loop over all key/values from file and set them this.config object
                    // TODO: check/validate keys and values. It should be a validation method which may be overloaded by child classes
                    Object.keys(json).forEach((key: string) => {
                        (this.config as any)[key] = json[key];
                        this.getLogger().info(
                            `Set config.${key} = ${json[key]} from external configuration file ${configFileURL}`
                        );
                    });
                    console.info('Configuration loaded from server');
                    return null;
                })
                .catch((reason: Error) => {
                    console.error(`Error loading External applciation configuration file: ${configFileURL}`, reason);
                    return null;
                });
        });
    }

    /**
     * first method launched by start() method
     * - Load CSS
     */
    protected preStart(): Promise<void> {
        // TODO: following lines removed due to performances issues... To be investigated
        // // add a default exception logger if something untracked appens
        // window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
        //     me.getLogger()?.error("uncaught exception: " + errorMsg);
        //     me.getLogger()?.error("url: " + url);
        //     me.getLogger()?.error("at line: " + lineNumber);
        //     return false;
        // }
        const progressId: string = this.getProgressLoaderManager().addProgress(this, 'pre-starting detail');
        return (
            Promise.resolve()
                // append application div
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'create application div');
                    document.body.appendChild(this.getApplicationDiv());
                })
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'load JsPanel library');
                    return this.loadJsPanelLibrary();
                })
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'load Json Editor library');
                    return this.loadJsonEditorLibrary();
                })
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'load external configuration');
                    return this.loadExternalConfiguration();
                })
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'start Library manager');
                    return this.getLibraryManager().start();
                })
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'start add-ons manager');
                    return this.getAddOnManager().start();
                })
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'start session manager');
                    return this.getSessionManager().start();
                })
                // Item catalog start is already done in the application constructor
                // .then(() => this.getItemCatalog().start())
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'start desktop manager');
                    return this.getDesktopManager().start();
                })
                .then(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'load CSS');
                    return this.loadCss();
                })
                .catch((error: Error) => {
                    this.getLogger().error(`Error starting Add-On Manager...: ${error.message}`);
                })
                .finally(() => {
                    this.getProgressLoaderManager().addProgressDescription(progressId, 'start registered plugins');
                    this.getPluginManager()
                        .startAllPlugins()
                        .finally(() => {
                            this.getProgressLoaderManager().endProgress(progressId);
                        });
                })
        );
    }

    /**
     * method launched by start() method after preStart()
     */
    protected doStart(): Promise<void> {
        return Promise.resolve();
    }

    /**
     */
    protected postStart(): Promise<void> {
        const progressId: string = this.getProgressLoaderManager().addProgress(this, 'post-starting detail');
        return Promise.resolve()
            .then(() => {
                // try connecting to server
                this.getProgressLoaderManager().addProgressDescription(progressId, 'connect to server');
                return this.connectToServer().catch((reason: any) => {
                    this.getLogger().error('Error connecting to server', reason);
                });
            })
            .then(() => {
                // load addons
                this.getProgressLoaderManager().addProgressDescription(progressId, 'load add-ons');
                return this.loadAddOns();
            })
            .then(() => null)
            .finally(() => {
                this.getProgressLoaderManager().endProgress(progressId);
            });
    }

    /**
     * launch a connection request for the client (this application) to the backend
     * the backend URL is defined in the application configuration
     * Backend returns a session Information (TpzClientSessionInformation) which contains
     * @returns
     */
    public connectToServer(): Promise<TpzClientSession> {
        const connectService: string = this.getConfig()?.connectService || '/connect';
        if (!this.getConfig().backendURL) {
            this.getLogger().warn('backendURL field of application configuration is not set. Do not connect');
            return Promise.resolve(null);
        }
        const backendConnectURL: string = `${this.getConfig().backendURL}${connectService}`;
        this.getLogger().info(`Initiate FrontEnd connection to Server ${backendConnectURL}`);
        return LoadHelper.fetchValidRequestTopazProtocol(backendConnectURL)
            .then((sessionInformation: TpzClientSessionInformation) => {
                this.getLogger().info(
                    `Connection to ${backendConnectURL} returns session ${JSON.stringify(sessionInformation)}`
                );
                const session: TpzClientSession = this.getSessionManager().getOrCreateSession(sessionInformation);
                // replace current session in session manager
                this.getSessionManager().setCurrentSession(session);
                // start session and return promise on session
                return session.start().then(() => {
                    return session;
                });
            })
            .catch((reason: Error) => {
                this.sendNotification('ERROR', `server connection error: ${reason.message}`);
                this.fireEvent(
                    this.getId(),
                    TpzApplicationEventType.SERVER_CONNECTION_ERROR,
                    { url: backendConnectURL, error: reason },
                    [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
                );
                throw new Error(`Request to connection service failed (${backendConnectURL}): ${reason.message}`);
            });
    }

    /**
     * Backend disconnection
     */
    public disconnectFromServer(): void {
        const session: TpzClientSession = this.getSessionManager().getCurrentSession();
        if (session) session.disconnectSession();
        this.getSessionManager().setCurrentSession(null);
    }

    /**
     * Backend connection check
     */
    public isServerConnected(): boolean {
        return this.getSessionManager().getCurrentSession() != null;
    }

    /**
     * get Application CSS
     */
    public getCss(): string[] {
        return this.getConfig().css || [];
    }

    /**
     * Load all CSS files. Return a Promise.all on all loaders
     * (resolved when all are resolved)
     */
    protected loadCss(): Promise<void> {
        if (!this.getCss()) return Promise.resolve();
        return LoadHelper.loadCSSs(this.getCss(), this.getLogger());
    }

    /**
     * get Application AddOn Ids specified in application config
     */
    public getAddOnIds(): string[] {
        return this.getConfig().addOnIds || [];
    }

    /**
     * Load all Add-ons registered in TpzApplication config (config.addOnIds).
     * Return a Promise.all on all loaders
     * (resolved when all are resolved)
     */
    protected loadAddOn(addOnId: string): Promise<void> {
        return this.getAddOnManager()
            .loadAddOnById(addOnId)
            .then(() => null);
    }

    /**
     * Load all Add-ons registered in TpzApplication config (config.addOnIds).
     * Return a Promise.all on all loaders
     * (resolved when all are resolved)
     */
    protected loadAddOns(): Promise<void[]> {
        if (this.getAddOnIds()) {
            const progressId: string = this.getProgressLoaderManager().addProgress(this, 'load AddOns');
            const loaders: Promise<void>[] = [];
            this.getAddOnIds().forEach((addOnId: string) => {
                this.getProgressLoaderManager().addProgressDescription(progressId, `load ${addOnId}`);
                loaders.push(this.loadAddOn(addOnId));
            });
            return Promise.all(loaders).finally(() => this.getProgressLoaderManager().endProgress(progressId));
        }
        return Promise.resolve([]);
    }

    /**
     * Fires an event containing a notification
     * @param type notification type
     * @param message notification message
     */
    public sendNotification(type: string, message: string): void {
        const notification: NotificationMessage = {
            type: type,
            message: message
        };
        // log notification
        this.getLogger().log(this.getLogger().getLevelFromName(type), `NOTIFICATION: ${message}`);
        this.fireEvent(this.getId(), TpzApplicationEventType.NOTIFICATION, notification, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * Fire an application event
     * Event is timestamped with current time
     * @param sourceId event source identifier
     * @param type event type
     * @param content event content (must match event type to be consistent)
     * @param categories event categorization
     * @param time event creation time (or current time if null)
     */
    public fireEvent(
        sourceId: string,
        type: string,
        content: any,
        categories: string[] = [],
        time: number = null
    ): Promise<void> {
        return this.getEventManager().trigger({
            source: sourceId,
            date: time ? time : new Date().getTime(),
            type: type,
            categories: categories,
            content: content
        });
    }

    /**
     * Internationalization Manager manages application internationalization, translatio, and languages
     */
    public getI18N(): I18NManager {
        if (!this.internationalizationManager) {
            this.internationalizationManager = new I18NManager(this, 'i18n-manager');
            this.internationalizationManager.setLanguage('fr');
        }
        return this.internationalizationManager;
    }

    /**
     * Plugin Manager manages application plugins
     */
    public getPluginManager(): TpzPluginManager {
        if (!this.pluginManager) {
            this.pluginManager = new TpzPluginManager(this);
        }
        return this.pluginManager;
    }

    /**
     * AddOns Manager manages application add-ons
     */
    public getAddOnManager(): TpzAddOnManager {
        if (!this.addonManager) {
            this.addonManager = new TpzAddOnManager(this, 'addon-manager');
            if (this.getConfig().addOnRegistryURLs) {
                this.addonManager.setAddOnRegistryURLs(this.getConfig().addOnRegistryURLs);
            }
        }
        return this.addonManager;
    }

    /**
     *
     * Plugin Manager delegation of FactoryManager registerFactory
     */
    public registerItemFactory(factory: ItemFactory): boolean {
        if (!this.getFactoryManager().registerItemFactory(factory)) return false;
        this.fireEvent(this.getId(), TpzApplicationEventType.ITEM_FACTORY_REGISTERED, { factory: factory }, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
        return true;
    }

    /**
     *
     * Plugin Manager delegation of FactoryManager registerFactory
     * If any of the factories in input don't register properly then allRegistered is false.
     */
    public registerItemFactories(factories: ItemFactory[]): boolean {
        let allRegistered = true;
        factories.forEach((factory: ItemFactory) => {
            if (!this.registerItemFactory(factory)) {
                allRegistered = false;
            }
        });
        return allRegistered;
    }

    /**
     * Plugin Manager delegation of FactoryManager unregisterItemFactory
     */
    public unregisterItemFactory(factoryId: string): boolean {
        throw new Error('Factory Manager unregister not yet implemented');
    }

    /**
     * Register a new Library in library manager
     * @param config
     */
    public registerLibrary(config: TpzLibraryDescriptor): boolean {
        return this.getLibraryManager().registerLibrary(config);
    }

    /**
     * Try to load a registered library by its ID
     * Delegate getLibrary method to LibraryManager object
     */
    public getLibrary(libraryId: string): Promise<void> {
        return this.getLibraryManager().getLibrary(libraryId);
    }

    /**
     * Library manager manages javascript libraries loading
     */
    public getLibraryManager(): LibraryManager {
        if (!this.libraryManager) {
            this.libraryManager = new LibraryManager(this, 'library-manager');
        }
        return this.libraryManager;
    }

    /**
     * Progress Loader manager manages progress bars
     */
    public getProgressLoaderManager(): ProgressLoaderManager {
        if (!this.progressLoaderManager) {
            this.progressLoaderManager = new ProgressLoaderManager(this, {
                progressClass: 'progress-loader'
            });
            this.progressLoaderManager.setLogger(this.getLogger());
        }
        return this.progressLoaderManager;
    }

    /**
     * Session manager lazy getter
     */
    public getSessionManager(): TpzSessionManager {
        if (!this.sessionManager) {
            this.sessionManager = new TpzSessionManager(this, 'session-manager');
        }
        return this.sessionManager;
    }

    /**
     * Event Manager manages application events
     */
    public getEventManager(): EventManager<TpzApplicationEvent> {
        if (!this.eventManager) {
            this.eventManager = new EventManager<TpzApplicationEvent>();
            this.eventManager.register(this.getId(), this.onApplicationEvent.bind(this));
        }
        return this.eventManager;
    }

    /**
     * Factory Manager lazy getter
     */
    public getFactoryManager(): ItemFactoryManager {
        return this.getItemCatalog()?.getFactoryManager();
    }

    /**
     * Item getter
     */
    public getItemCatalog(): ItemCatalog {
        if (!this.itemCatalog) {
            this.itemCatalog = new ItemCatalog(this, 'item-catalog');
            this.itemCatalog.start();
        }
        return this.itemCatalog;
    }

    /**
     * Desktop Manager manages desktops in application
     */
    public getDesktopManager(): TpzDesktopManager {
        if (!this.desktopManager) {
            this.desktopManager = new TpzDesktopManager(this, 'desktop-manager');
        }
        return this.desktopManager;
    }

    // boolean serverConnectionError Getter
    public isServerConnectionError(): boolean {
        return this.serverConnectionError;
    }

    // boolean serverConnectionError Setter
    public setServerConnectionError(value: boolean): void {
        this.serverConnectionError = value;
    }

    /**
     * Event Handler. This method can be Overloaded but do not forget to call super.onEvent if
     * the event is not Handled in your implementation !
     *
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        switch (event.type) {
            case TpzApplicationEventType.SET_USER_CREDENTIALS:
                TpzApplicationEventType.checkEvent(event, 'authToken');
                this.setAuthToken(event.content.authToken);
                break;
            case TpzApplicationEventType.SET_APPLICATION_STATE_REQUEST:
                TpzApplicationEventType.checkEvent(event, 'applicationState');
                this.setApplicationState(event.content.applicationState);
                break;
        }
        if (this.getConfig().logEvents) {
            this.getLogger()?.debug(
                `Application Core - #${this.getId()} Event Received - Type = ${event.type} date = ${event.date}: ${
                    event.source
                }`
            );
            this.getLogger()?.debug(`Event content = ${TpzApplication.customStringify(event.content)}`);
        }
        return true;
    }

    /**
     * call JSON.stringify but limit output size and handle circular objects
     * @param v Object to stringify
     * @returns stringified object
     */
    private static customStringify(v: any): string {
        const MAX_CHAR: number = 100;
        try {
            const str: string = JSON.stringify(v, null, 2);
            if (str.length > MAX_CHAR) return `${str.substring(0, MAX_CHAR)}...`;
            return str;
        } catch (reason: any) {
            return `[Circular event content] - cannot convert to JSON string: ${(reason as Error).message}`;
        }
    }

    /**
     * wait for an event condition before launching callback
     * @param eventType expected event type. If null all events are considered
     * @param condition condition to be waited for. If null it only
     * @param timeout time after which condition is not resolved, it throws an Error
     * @param identifier a human readable action description (used in error message)
     * @return fulfilled promise when event is received and conditions are verified
     */
    public waitForEvent(
        eventType: string,
        condition: (event: TpzApplicationEvent) => boolean,
        identifier: string,
        timeout: number = 10000 // default value is 10 seconds
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const callbackWrapperId: string = uuidv4();
            const callbackTimeoutId = setTimeout(() => {
                reject(
                    `Condition '${identifier}' has not been fulfilled for ${
                        timeout / 1000
                    } seconds. Stop waiting for it...`
                );
                // unregister listener
                this.getEventManager().unregister(callbackWrapperId);
                // clear timeout
                clearTimeout(callbackTimeoutId);
            }, timeout);

            // function to register in application eventManager
            const callbackWrapper: (event: TpzApplicationEvent) => boolean = (event: TpzApplicationEvent) => {
                if (!event) return false;
                try {
                    if (eventType && eventType !== event.type) {
                        return false;
                    }
                    if (condition && !condition(event)) {
                        return false;
                    }
                    // positive condition evaluation
                    // unregister listener
                    this.getEventManager().unregister(callbackWrapperId);
                    // clear timeout
                    clearTimeout(callbackTimeoutId);
                    // then fulfill promise
                    resolve();
                    return true;
                } catch (reason: any) {
                    // if an error occur during condition evaluation, stop waitFor
                    // unregister listener
                    this.getEventManager().unregister(callbackWrapperId);
                    // clear timeout
                    clearTimeout(callbackTimeoutId);
                    reject(`An error occured evaluating waitFor confition: ${(reason as Error).message}`);
                    return false;
                }
            };
            this.getEventManager().register(callbackWrapperId, callbackWrapper);
        });
    }

    /**
     * Application Logger getter
     */
    public getLogger(): Logger {
        if (!this.logger) {
            this.setLogger(LoggerHelper.createBufferedConsoleLogger(`${this.getId()}-logger`, 500));
        }
        return this.logger;
    }

    /**
     * Application Logger getter
     * Fires an event type TpzApplicationEventType.LOGGER_CHANGED
     * with content \{ logger: logger \}
     */
    public setLogger(logger: Logger): void {
        if (this.logger == logger) return;
        this.logger = logger;
        this.fireEvent(this.getId(), TpzApplicationEventType.LOGGER_CHANGED, { logger: logger }, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    // /**
    //  * Get the Application div containing all desktop
    //  * @returns
    //  */
    // public getDesktopsContainer(): HTMLDivElement {
    //     if (!this.desktopsContainer) {
    //         this.desktopsContainer = TpzApplicationUI.createDiv({});
    //         this.desktopsContainer.id = this.getConfig().applicationContainerId + "-desktop";
    //     }
    //     return this.desktopsContainer;
    // }

    /**
     * Set the Authorization token for the currently logged user
     * @param token  the token
     */
    private setAuthToken(token: string): void {
        this.getConfigSetter().authToken = token;
    }

    /**
     * When this token is available it shall be used
     * to define the Authorization header of all HTTP requests
     */
    private getAuthToken(): string {
        return this.getConfig().authToken;
    }

    /**
     * Add elements to all topaz application request
     * @param init incoming request parameters
     * @returns the refined request with topaz application data
     */
    private refineRequest(init: RequestInit): RequestInit {
        let init2: RequestInit = {};
        if (init) init2 = { ...init };
        // add token to request headers
        const token: string = this.getAuthToken();
        if (!init2.headers) init2.headers = {};
        if (token) {
            init2.headers = {
                ...init2.headers,
                credentials: 'include',
                withCredentials: 'true',
                Authorization: `Bearer ${token}`
            };
        }
        // set error management if response is a redirection (usefull for keycloak when not connected)
        init2.redirect = 'manual';
        return init2;
    }

    /**
     * Basic fetch Request returning the Response object
     * Response status is checked. if status is not ok an error is thrown
     * credential:'include' field is added to init object
     * @param url request URL address
     * @param init request parameters
     * @returns the response object
     */
    public fetchTopazServer(url: string, init: RequestInit = null): Promise<Response> {
        return LoadHelper.fetchRequest(url, this.refineRequest(init));
    }

    /**
     *  fetch Request returning the TpzProtocolResponse
     * Check if the received response is a valid TpzProtocolResponse
     * No check is done on the response status
     * @param url request URL address
     * @param init request parameters
     * @returns the response object
     */
    public fetchTopazServerWithProtocol(url: string, init: RequestInit = null): Promise<TpzProtocolResponse> {
        return LoadHelper.fetchRequestTopazProtocol(url, this.refineRequest(init));
    }

    /**
     * Basic fetch Request returning the the TpzProtocolResponse
     * if the status is "error", it throws an error with the "error" field
     * if the status is "ok", it returns the "content" field
     * @param url request URL address
     * @param init request parameters
     * @returns the response object
     */
    public fetchTopazServerWithProtocolValid(url: string, init: RequestInit = null): Promise<any> {
        return LoadHelper.fetchValidRequestTopazProtocol(url, this.refineRequest(init));
    }
}
