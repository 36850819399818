/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzPlugin, TpzPluginConfig, defaultTpzPluginConfig } from '../../tpz-plugin-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { deepCopy } from '../../../tools/deep-copy';

const SPLASH_SCREEN_PLUGIN_TYPE: string = 'SplashScreenPluginType'; // plugin type

/**
 *  Plugin configuration
 */
export interface SplashScreenPluginConfig extends TpzPluginConfig {
    splashId?: string; // id of the main div container
    textElements?: string[]; // text elements to display
    duration?: number; // splashscreen duration in milliseconds
    closeOnClick?: boolean; // if true, the splashscreen is stopped on user click
    videoURL?: string; // background video
    videoLoop?: boolean; // loop video
}

const defaultSplashScreenPluginConfig: SplashScreenPluginConfig = {
    ...defaultTpzPluginConfig,
    id: 'SplashScreen',
    type: SPLASH_SCREEN_PLUGIN_TYPE,
    splashId: 'splash',
    textElements: ['Powered by Topaz'],
    duration: 5000,
    closeOnClick: false,
    videoURL: null,
    videoLoop: true
};

/** */
export class SplashScreenPlugin extends TpzPlugin {
    public static readonly SPLASH_SCREEN_PLUGIN_TYPE: string = SPLASH_SCREEN_PLUGIN_TYPE; // plugin type

    private containerDiv: HTMLDivElement = null;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: SplashScreenPluginConfig) {
        super(deepCopy(defaultSplashScreenPluginConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): SplashScreenPluginConfig {
        return super.getConfig() as SplashScreenPluginConfig;
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTING:
                TpzApplicationEventType.checkEvent(event, null);
                this.insertSplashScreen();
                window.setTimeout(() => {
                    this.removeSplashScreen();
                }, this.getConfig().duration);
                return true;
            case TpzApplicationEventType.APPLICATION_STARTED:
                TpzApplicationEventType.checkEvent(event, null);
                return true;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Insert splashscreen in DOM
     */
    private insertSplashScreen(): void {
        this.containerDiv = document.createElement('div');
        this.containerDiv.id = this.getConfig().splashId;

        // text content
        const contentDiv: HTMLDivElement = document.createElement('div');
        this.containerDiv.appendChild(contentDiv);
        this.getConfig().textElements?.forEach((textElement: string) => {
            const span: HTMLSpanElement = document.createElement('span');
            span.innerText = textElement;
            contentDiv.appendChild(span);
        });
        if (this.getConfig().closeOnClick) {
            this.containerDiv.addEventListener('click', this.removeSplashScreen.bind(this));
        }

        // video content
        if (this.getConfig().videoURL) {
            const video: HTMLVideoElement = document.createElement('video');
            video.autoplay = true;
            video.loop = true;
            const videoSource: HTMLSourceElement = document.createElement('source');
            videoSource.src = this.getConfig().videoURL;
            this.containerDiv.appendChild(video);
            video.appendChild(videoSource);
        }
        this.containerDiv.appendChild(contentDiv);
        document.body.appendChild(this.containerDiv);
    }

    /**
     * Insert splashscreen in DOM
     */
    private removeSplashScreen(): void {
        if (!this.containerDiv || !document.body) return;
        if (document.body.contains(this.containerDiv)) document.body.removeChild(this.containerDiv);
    }
}
