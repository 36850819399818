/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoadHelper } from '../tools/loader';
import { TpzApplicationComponent } from '../tpz-application-component';
import { TpzApplication } from '../tpz-application-core';
import { TpzClientSessionInformation, TpzServerInformation } from './server';
import { TpzClientSession } from './session';

/**
 * Topaz Application Session Manager
 * Only one session is managed at a time.
 * Old sessions are stored
 */
export class TpzSessionManager extends TpzApplicationComponent {
    public static readonly WEBSOCKET_CONNECTED_EVENT = 'WEBSOCKET_CONNECTED_EVENT';
    public static readonly WEBSOCKET_DISCONNECTED_EVENT = 'WEBSOCKET_DISCONNECTED_EVENT';

    private currentSession: TpzClientSession = null;
    private readonly storedSessions: { [id: string]: TpzClientSession } = {};

    // private config: TpzSessionManagerConfig = defaultTpzSessionManagerConfig;

    /**
     * Constructor
     */
    constructor(app: TpzApplication, id: string) {
        super(app, id);
    }

    /**
     * set Client session. Null to invalidate current session
     */
    public setCurrentSession(session: TpzClientSession): void {
        if (session && !session.getId()) throw new Error('Client Session has invalid ID: ' + JSON.stringify(session));
        if (this.currentSession === session) return;
        if (this.currentSession) {
            this.currentSession.stop();
        }
        if (session) {
            this.storedSessions[session.getId()] = session;
            // set session name in page title (DEBUG)
            document.title = session.getId();
        }
        this.currentSession = session;
    }

    /**
     * Client session getter
     * @returns session or null
     */
    public getCurrentSession(): TpzClientSession {
        return this.currentSession;
    }

    /**
     * Helper method for getClientSession().getSessionId()
     * @return session Id or null if session is not opened
     */
    public getCurrentSessionId(): string {
        if (!this.getCurrentSession()) return null;
        return this.getCurrentSession().getSessionId();
    }

    /**
     * Retrieve session with given Id or Create a new one based on session Id
     * @param sessionInformation session information used to create a new session
     */
    public getOrCreateSession(sessionInformation: TpzClientSessionInformation): TpzClientSession {
        if (!sessionInformation) {
            throw new Error('Unable to get or create session in SessionManager with a null session information');
        }
        const sessionId: string = sessionInformation.sessionId;
        if (!sessionId) {
            throw new Error(
                'Unable to get or create session in SessionManager with a null session Id. Session Information = ' +
                    JSON.stringify(sessionInformation)
            );
        }
        // try to retrieve existing session
        let session: TpzClientSession = this.storedSessions[sessionId];
        if (!session) {
            // create a new session
            session = new TpzClientSession(this.getApplication(), sessionInformation);
            this.getLogger().info('Create a new Session #' + sessionId);
        } else {
            this.getLogger().info('Reuse Session #' + sessionId + ' (reconnexion)');
        }
        return session;
    }

    /**
     * send a server information request
     */
    public static fetchServerInformation(serverInfoURL: string): Promise<TpzServerInformation> {
        if (!serverInfoURL) {
            return Promise.reject(new Error('Session server information URL not defined'));
        }

        return LoadHelper.fetchValidRequestTopazProtocol(serverInfoURL)
            .then((content: any) => {
                const serverInformation: TpzServerInformation = content as TpzServerInformation;
                // FIXME: check serverinformation format
                return serverInformation;
            })
            .catch((error: Error) => {
                throw new Error('Error retrieving server informations from ' + serverInfoURL + ': ' + error);
            });
    }
}
