/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// import '../../../../../../../node_modules/jsoneditor/dist/jsoneditor.css';
import JSONEditor from 'jsoneditor'; // typings only
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { TpzApplicationCommander } from '../../../tpz-application-commander';
import { TabPanel } from '../../../tools/tab-panel';
import { TpzApplicationState } from '../../../tpz-application-state';
import { LibraryManagerEditor } from '../../../libraries/library-manager-editor';
import { AddOnsManagerEditor } from '../../../addons/addons-manager-editor';
import { ItemCatalogEditor } from '../../../items/item-catalog-editor';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { uuidv4 } from '../../../tools/uuid';
import { deepCopy } from '../../../tools/deep-copy';

// item type (used in default configuration and Item Instance constructor)
const APPLICATION_CONFIGURATION_EDITOR_VIEW_TYPE: string = 'ApplicationConfigurationEditorViewType';

/**
 * Application Configuration View Configuration
 */
export type ApplicationConfigurationEditorViewConfig = TpzViewConfig;

/**
 * Default View Configuration
 */
export const defaultApplicationConfigurationEditorViewConfig: ApplicationConfigurationEditorViewConfig = {
    ...defaultTpzViewConfig,
    type: APPLICATION_CONFIGURATION_EDITOR_VIEW_TYPE,
    css: ['css/json-editor.css', 'css/application-config-editor.css', 'css/tab-panel.css'].concat(
        defaultTpzViewConfig.css
    )
};

/**
 * Item Parameter View displaying parameters associated with config values.
 * Config values can be interactively modified in UI to control
 */
export class ApplicationConfigurationEditorView extends TpzView {
    static readonly APPLICATION_CONFIGURATION_EDITOR_VIEW_TYPE: string = APPLICATION_CONFIGURATION_EDITOR_VIEW_TYPE;

    private mainContainer: HTMLDivElement = null;
    private topPanelDiv: HTMLDivElement = null;
    private bottomPanelDiv: HTMLDivElement = null;
    private tabPanel: TabPanel = null;
    private jsonEditorPanelDiv: HTMLDivElement = null;
    private libraryManagerEditor: LibraryManagerEditor = null;
    private addOnsManagerEditor: AddOnsManagerEditor = null;
    private itemCatalogEditor: ItemCatalogEditor = null;
    private importExportPanelDiv: HTMLDivElement = null;
    private importDropDiv: HTMLDivElement = null;
    private exportDragDiv: HTMLDivElement = null;

    private applyButton: HTMLButtonElement = null;
    private updateButton: HTMLButtonElement = null;
    private closeButton: HTMLButtonElement = null;
    private editor: Promise<JSONEditor> = null;
    private editedConfig: TpzApplicationState = null;

    /** Constructor */
    constructor(config: ApplicationConfigurationEditorViewConfig, application: TpzApplication) {
        super(
            deepCopy(defaultApplicationConfigurationEditorViewConfig, config),
            ApplicationConfigurationEditorView.APPLICATION_CONFIGURATION_EDITOR_VIEW_TYPE,
            application
        );
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): ApplicationConfigurationEditorViewConfig {
        return super.getConfig() as ApplicationConfigurationEditorViewConfig;
    }

    /**
     * Create Clock UI in view container Div
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!parent) return false;
        parent.appendChild(this.getMainContainer());
        return true;
    }

    /**
     * Main container Div lazy getter
     */
    private getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({ classes: 'application-state-editor' });
            this.mainContainer.appendChild(this.getTopPanel());
            this.mainContainer.appendChild(this.getBottomPanel());
        }
        return this.mainContainer;
    }

    /**
     * Top Panel Div lazy getter
     */
    private getTopPanel(): HTMLDivElement {
        if (!this.topPanelDiv) {
            this.topPanelDiv = TpzApplicationUI.createDiv({ classes: 'top-panel' });
            this.topPanelDiv.appendChild(this.getTabPanel().getUI());
        }
        return this.topPanelDiv;
    }

    /**
     * Left Panel Div lazy getter
     */
    private getBottomPanel(): HTMLDivElement {
        if (!this.bottomPanelDiv) {
            this.bottomPanelDiv = TpzApplicationUI.createDiv({ classes: 'bottom-panel' });
            this.bottomPanelDiv.appendChild(this.getUpdateButton());
            this.bottomPanelDiv.appendChild(this.getApplyButton());
            this.bottomPanelDiv.appendChild(this.getCloseButton());
        }
        return this.bottomPanelDiv;
    }

    /**
     * Tab Panel Div lazy getter
     */
    private getTabPanel(): TabPanel {
        if (!this.tabPanel) {
            this.tabPanel = new TabPanel();
            this.tabPanel.addTab('json', this.getJsonEditorPanelDiv());
            this.tabPanel.addTab('libraries', this.getLibraryManagerEditor().getUI());
            this.tabPanel.addTab('add-ons', this.getAddOnsManagerEditor().getUI());
            this.tabPanel.addTab('items', this.getItemCatalogEditor().getUI());
            this.tabPanel.addTab('Import/Export', this.getImportExportDiv());
            this.tabPanel.openTab(this.getJsonEditorPanelDiv().id);
        }
        return this.tabPanel;
    }

    /**
     * Json Editor Div lazy getter
     */
    private getImportExportDiv(): HTMLDivElement {
        if (!this.importExportPanelDiv) {
            this.importExportPanelDiv = TpzApplicationUI.createDiv({});
            this.importExportPanelDiv.appendChild(this.getExportDragDiv());
            this.importExportPanelDiv.appendChild(this.getImportDropDiv());
        }
        return this.importExportPanelDiv;
    }

    /**
     * Json Editor Div lazy getter
     */
    private getImportDropDiv(): HTMLDivElement {
        if (!this.importDropDiv) {
            this.importDropDiv = TpzApplicationUI.createDiv({});
            this.importDropDiv.style.width = '128px';
            this.importDropDiv.style.height = '128px';
            this.importDropDiv.style.border = '1px solid white';
            this.importDropDiv.style.display = 'inline-block';
            this.importDropDiv.style.backgroundColor = 'yellow';
            this.importDropDiv.innerHTML = 'DROP';
            this.importDropDiv.addEventListener('drop', (event: DragEvent) => this.onImportDrop(event));
            this.importDropDiv.addEventListener('dragover', (event: DragEvent) => this.allowDrop(event));
        }
        return this.importDropDiv;
    }

    /**
     * Json Editor Div lazy getter
     */
    private getExportDragDiv(): HTMLDivElement {
        if (!this.exportDragDiv) {
            this.exportDragDiv = TpzApplicationUI.createDiv({});
            this.exportDragDiv.style.width = '128px';
            this.exportDragDiv.style.height = '128px';
            this.exportDragDiv.style.border = '1px solid white';
            this.exportDragDiv.style.display = 'inline-block';
            this.exportDragDiv.style.backgroundColor = 'green';
            this.exportDragDiv.innerHTML = 'DRAG';
            this.exportDragDiv.addEventListener('click', (event: MouseEvent) =>
                this.copyApplicationStateToClipboard(event)
            );
        }
        return this.exportDragDiv;
    }

    /**
     * Json Editor Div lazy getter
     */
    private getJsonEditorPanelDiv(): HTMLDivElement {
        if (!this.jsonEditorPanelDiv) {
            this.jsonEditorPanelDiv = TpzApplicationUI.createDiv({});
            this.jsonEditorPanelDiv.style.height = '100%';
            this.updateConfigEditor();
        }
        return this.jsonEditorPanelDiv;
    }

    /**
     * Library Manager Editor lazy getter
     */
    private getLibraryManagerEditor(): LibraryManagerEditor {
        if (!this.libraryManagerEditor) {
            this.libraryManagerEditor = new LibraryManagerEditor(this.getApplication());
        }
        return this.libraryManagerEditor;
    }

    /**
     * Add-Ons Manager Editor lazy getter
     */
    private getAddOnsManagerEditor(): AddOnsManagerEditor {
        if (!this.addOnsManagerEditor) {
            this.addOnsManagerEditor = new AddOnsManagerEditor(this.getApplication());
        }
        return this.addOnsManagerEditor;
    }

    /**
     * Item catalog Editor lazy getter
     */
    private getItemCatalogEditor(): ItemCatalogEditor {
        if (!this.itemCatalogEditor) {
            this.itemCatalogEditor = new ItemCatalogEditor(this.getApplication());
        }
        return this.itemCatalogEditor;
    }

    /**
     * get Apply Button
     */
    public getApplyButton(): HTMLButtonElement {
        if (!this.applyButton) {
            this.applyButton = TpzApplicationUI.createButton({ label: 'Apply', classes: ['ok-button'] });
            this.applyButton.addEventListener('click', () => this.apply());
        }
        return this.applyButton;
    }

    /**
     * get Apply Button
     */
    public getUpdateButton(): HTMLButtonElement {
        if (!this.updateButton) {
            this.updateButton = TpzApplicationUI.createButton({ label: 'Update', classes: ['update-button'] });
            this.updateButton.addEventListener('click', () => this.update());
        }
        return this.updateButton;
    }

    /**
     * get Cancel Button
     */
    public getCloseButton(): HTMLButtonElement {
        if (!this.closeButton) {
            this.closeButton = TpzApplicationUI.createButton({ label: 'Close', classes: ['cancel-button'] });
            this.closeButton.addEventListener('click', () => this.close());
        }
        return this.closeButton;
    }

    /**
     * Change currently edited configuration
     * @param itemId item to be edited identifier
     */
    private updateConfigEditor(): void {
        const appState: TpzApplicationState = this.getApplication().getApplicationState(
            'edited-application-state',
            'edited-application-state'
        );
        this.getJsonEditor().then((editor: JSONEditor) => {
            editor.set(appState);
            this.editedConfig = { ...appState };
        });
    }

    /**
     * Action performed when apply button is clicked
     */
    private apply(): void {
        this.getJsonEditor().then((editor: JSONEditor) => {
            try {
                const editedConfig: any = editor.get();
                TpzApplicationCommander.setApplicationState(this.getApplication(), editedConfig);
            } catch (reason: any) {
                this.getApplication().sendNotification('ERROR', `invalid JSON: ${(reason as Error).message}`);
                this.getLogger().error('invalid JSON', reason);
            }
        });
    }

    /**
     * Get a promise over JsonEditor object
     */
    private getJsonEditor(): Promise<JSONEditor> {
        if (!this.editor) {
            this.editor = this.getApplication()
                .createJSONEditor(this.getJsonEditorPanelDiv())
                .catch((reason: Error) => {
                    // do not cache falsy promise
                    this.editor = null;
                    // rethrow error
                    throw reason;
                });
        }
        return this.editor;
    }
    /**
     * Action performed when cancel button is clicked
     */
    private close(): void {
        this.getParentWindow().closeWindowAndRemoveFromDesktops();
    }

    /**
     * Action performed when update button is clicked
     */
    private update(): void {
        this.updateConfigEditor();
        this.getAddOnsManagerEditor().updateUI();
        this.getLibraryManagerEditor().updateUI();
        this.getItemCatalogEditor().updateUI();
    }

    /** Remove Graphics components */
    public invalidateUI(): void {
        this.mainContainer = null;
    }

    /**
     * Action to perform when a file or a text is dropped
     * @param event
     */
    private onImportDrop(event: DragEvent): void {
        event.preventDefault();
        const isFiles = event.dataTransfer.types.includes('Files');
        if (!isFiles) {
            this.getImportDropDiv().innerHTML = `${event.dataTransfer.types.join(
                ', '
            )} type is not allowed ('Files' expected)`;
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return;
        }
        if (!event.dataTransfer?.items) {
            this.getImportDropDiv().innerHTML = 'event.dataTransfer.items is not defined...';
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return;
        }
        if (event.dataTransfer?.items.length != 1) {
            this.getImportDropDiv().innerHTML = `only one file is allowed (not ${event.dataTransfer?.items.length})`;
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return;
        }
        // Use DataTransferItemList interface to access the file
        const dataItem: DataTransferItem = event.dataTransfer.items[0];
        // If dropped items aren't files, reject them
        if (dataItem.kind != 'file') {
            this.getImportDropDiv().innerHTML = `item should be of type 'file' (not ${dataItem.kind})`;
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return;
        }
        if (dataItem.type != 'application/json') {
            this.getImportDropDiv().innerHTML = `allow only MIME file type 'application/json' (not ${dataItem.type})`;
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return;
        }
        const file: File = dataItem.getAsFile();
        if (!file) {
            this.getImportDropDiv().innerHTML = 'cannot get item as file...';
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return;
        }

        this.getImportDropDiv().innerHTML = `drop file ${file.name}`;

        const reader: FileReader = new FileReader();
        reader.onload = () => {
            TpzApplicationCommander.setApplicationState(this.getApplication(), JSON.parse(reader.result as string));
            (event.target as HTMLElement).style.backgroundColor = 'green';
        };
        reader.readAsText(file);
        (event.target as HTMLElement).style.backgroundColor = 'green';
    }

    /**
     * Copy the application state to the clipboard
     * @param event
     */
    private copyApplicationStateToClipboard(event: MouseEvent): void {
        const id: string = `dragged ${uuidv4()}`;
        const state: TpzApplicationState = this.getApplication().getApplicationState(id, id);
        navigator.clipboard.writeText(JSON.stringify(state, null, 2));
        this.getExportDragDiv().innerHTML = 'Application State copied to clipboard';
    }

    /**
     * Action to perform when a file or a text is dropped
     * @param event
     */
    private allowDrop(event: DragEvent): boolean {
        event.preventDefault();
        const isFiles = event.dataTransfer.types.includes('Files');
        if (!isFiles) {
            this.getImportDropDiv().innerHTML = `${event.dataTransfer.types.join(
                ', '
            )} type is not allowed ('Files' expected)`;
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return false;
        }
        if (!event.dataTransfer?.items) {
            this.getImportDropDiv().innerHTML = 'event.dataTransfer.items is not defined...';
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return false;
        }
        if (event.dataTransfer?.items.length != 1) {
            this.getImportDropDiv().innerHTML =
                'only one file is allowed (not ' + event.dataTransfer?.items.length + ')';
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return false;
        }
        // Use DataTransferItemList interface to access the file
        const dataItem: DataTransferItem = event.dataTransfer.items[0];
        // If dropped items aren't files, reject them
        if (dataItem.kind != 'file') {
            this.getImportDropDiv().innerHTML = "item should be of type 'file' (not " + dataItem.kind + ')';
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return false;
        }
        if (dataItem.type != 'application/json') {
            this.getImportDropDiv().innerHTML =
                "allow only MIME file type 'application/json' (not " + dataItem.type + ')';
            (event.target as HTMLElement).style.backgroundColor = 'red';
            return false;
        }

        this.getImportDropDiv().innerHTML = 'drop JSON file here';
        (event.target as HTMLElement).style.backgroundColor = 'green';
        return true;
    }

    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        switch (event.type) {
            case TpzApplicationEventType.ADDON_STARTED:
            case TpzApplicationEventType.ADDON_STOPPED:
                this.addOnsManagerEditor?.updateUI();
        }
        return super.onApplicationEvent(event);
    }
}

/**
 * Factory handling EchartsView creation
 */
export class ApplicationConfigurationEditorViewFactory extends TpzApplicationFactory {
    private static readonly ITEM_PARAMETER_VIEW_FACTORY_TYPE: string = 'ApplicationConfigurationEditorViewFactoryType';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(ApplicationConfigurationEditorViewFactory.ITEM_PARAMETER_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(
            ApplicationConfigurationEditorView.APPLICATION_CONFIGURATION_EDITOR_VIEW_TYPE,
            this.createApplicationConfigurationEditorView.bind(this),
            defaultApplicationConfigurationEditorViewConfig
        );
    }

    /** CesiumView creator function */
    private createApplicationConfigurationEditorView(
        config: ApplicationConfigurationEditorViewConfig
    ): Promise<ApplicationConfigurationEditorView> {
        return Promise.resolve(new ApplicationConfigurationEditorView(config, this.getApplication()));
    }
}

/**
 * Helper class for item parameter view
 */
export class ApplicationConfigurationEditorViewHelper {
    /**
     * Create the parameter view associated with the given item instance.
     * Register the configuration in item Catalog
     */
    public static createApplicationConfigurationEditorViewConfig(
        application: TpzApplication
    ): ApplicationConfigurationEditorViewConfig {
        //add the ParameterView Factory to factory manager
        application.registerItemFactory(new ApplicationConfigurationEditorViewFactory(application));
        if (!application) return null;
        const config: ApplicationConfigurationEditorViewConfig = {
            id: application.getId() + '-configuration-editor',
            type: ApplicationConfigurationEditorView.APPLICATION_CONFIGURATION_EDITOR_VIEW_TYPE
        };
        return config;
    }
}
