/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { ItemCatalog } from '../../tpz-catalog/tpz-catalog-core';
import { ItemConfig } from '../../tpz-catalog/tpz-item-config';
import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { TpzApplicationUI } from '../tpz-application-ui';
import { ItemFactory } from '../../tpz-catalog/tpz-item-factory';

/**
 * Item Catalog Editor displays UI informations
 */
export class ItemCatalogEditor {
    private readonly application: TpzApplication = null;
    private mainContainer: HTMLDivElement = null;
    private itemFactoriesDiv: HTMLDivElement = null;
    private allreadyCreatedInstancesDiv: HTMLDivElement = null;
    private registeredItemConfigsDiv: HTMLDivElement = null;

    /**
     * Library Manager Editor Constructor
     * @param app application used to fire events (may be we should not give the full application but only the event)
     */
    constructor(app: TpzApplication) {
        this.application = app;
    }

    /**
     * parent application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * UI main element
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * @returns the item catalog being edited
     */
    private getItemCatalog(): ItemCatalog {
        return this.application.getItemCatalog();
    }

    /**
     * Main container lazy getter
     */
    private getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({});
            const libBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({ legend: 'Factories' });
            libBloc.appendChild(this.getItemFactoriesDiv());
            this.mainContainer.appendChild(libBloc);
            const descBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({
                legend: 'Already created instances'
            });
            descBloc.appendChild(this.getAllreadyCreatedInstancesDiv());
            this.mainContainer.appendChild(descBloc);
            const serverBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({
                legend: 'Registered items configurations'
            });
            serverBloc.appendChild(this.getRegisteredItemConfigsDiv());
            this.mainContainer.appendChild(serverBloc);
            this.updateUI();
        }
        return this.mainContainer;
    }

    /**
     * Update UI content
     */
    public updateUI(): void {
        this.updateItemFactoriesDiv();
        this.updateAllreadyCreatedInstancesDiv();
        this.updateRegisteredItemConfigsDiv();
    }

    /**
     * Registered Libraries loading container lazy getter
     */
    private getItemFactoriesDiv(): HTMLDivElement {
        if (!this.itemFactoriesDiv) {
            this.itemFactoriesDiv = TpzApplicationUI.createDiv({});
            this.updateItemFactoriesDiv();
        }
        return this.itemFactoriesDiv;
    }

    /**
     * Item instances container lazy getter
     */
    private getAllreadyCreatedInstancesDiv(): HTMLDivElement {
        if (!this.allreadyCreatedInstancesDiv) {
            this.allreadyCreatedInstancesDiv = TpzApplicationUI.createDiv({});
            this.updateAllreadyCreatedInstancesDiv();
        }
        return this.allreadyCreatedInstancesDiv;
    }

    /**
     * update item factories
     * @returns
     */
    private updateItemFactoriesDiv(): Promise<void> {
        return Promise.resolve().then(() => {
            this.getItemFactoriesDiv().innerHTML = null;
            this.getItemCatalog()
                .getFactoryManager()
                .getRegisteredFactories()
                .forEach((factory: ItemFactory) => {
                    const typesDiv: HTMLDivElement = TpzApplicationUI.createDiv();
                    factory.getHandledTypes().forEach((handledType: string) => {
                        typesDiv.appendChild(
                            TpzApplicationUI.createParagraph({ text: 'handled type: ' + handledType })
                        );
                    });
                    const nameP: string = 'name: ' + factory.getName() + ' type: ' + factory.getType();
                    this.getItemFactoriesDiv().appendChild(TpzApplicationUI.createCollapsible(nameP, typesDiv));
                });
        });
    }

    /**
     * Retrieve the library descriptor from library Id and display descriptor in getRegisteredLibrariesDiv()
     * @returns
     */
    private updateAllreadyCreatedInstancesDiv(): Promise<void> {
        return Promise.resolve().then(() => {
            this.getAllreadyCreatedInstancesDiv().innerHTML = null;
            this.getItemCatalog()
                .getAllAlreadyCreatedInstances()
                .forEach((item: ItemInstance) => {
                    this.getAllreadyCreatedInstancesDiv().appendChild(
                        TpzApplicationUI.createCollapsible(
                            item.getId(),
                            TpzApplicationUI.createPre({
                                text: JSON.stringify(item.getConfig(), null, 2)
                            })
                        )
                    );
                });
        });
    }

    /**
     * Registered Item configs container lazy getter
     */
    private getRegisteredItemConfigsDiv(): HTMLDivElement {
        if (!this.registeredItemConfigsDiv) {
            this.registeredItemConfigsDiv = TpzApplicationUI.createDiv({});
            this.updateRegisteredItemConfigsDiv();
        }
        return this.registeredItemConfigsDiv;
    }

    /**
     * List all registered item configs
     */
    private updateRegisteredItemConfigsDiv(): Promise<void> {
        return Promise.resolve().then(() => {
            this.getRegisteredItemConfigsDiv().innerHTML = null;
            Object.values(this.getItemCatalog().getRegisteredConfigs()).forEach((config: ItemConfig) => {
                const factory: ItemFactory = this.getItemCatalog()
                    .getFactoryManager()
                    .getHandlingItemFactory(config.type);
                const div: HTMLDivElement = TpzApplicationUI.createDiv();
                if (factory) {
                    div.appendChild(
                        TpzApplicationUI.createParagraph({
                            text: 'type ' + config.type + ' is handled by factory ' + factory.getName()
                        })
                    );
                } else {
                    div.appendChild(
                        TpzApplicationUI.createParagraph({
                            text: 'type ' + config.type + ' is not handled by any factory...'
                        })
                    );
                }
                div.appendChild(TpzApplicationUI.createPre({ text: JSON.stringify(config, null, 2) }));
                this.getRegisteredItemConfigsDiv().appendChild(TpzApplicationUI.createCollapsible(config.id, div));
            });
        });
    }
}
