/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { TpzApplicationEvent } from '../../tpz-application-event';

/**
 * Sync Events Categories
 */
export class ServerSyncPluginEventType {
    // public static readonly SERVER_CONNECTED_EVENT = "SERVER_CONNECTED_EVENT"; // null
    // public static readonly SERVER_DISCONNECTED_EVENT = "SERVER_DISCONNECTED_EVENT"; // null
    // public static readonly SERVER_CONNECTION_PROBLEM_EVENT = "SERVER_CONNECTION_PROBLEM_EVENT"; // { errorEvent: Event }

    // Sync mode
    public static readonly MASTER_MODE_REQUEST: string = 'MASTER_MODE_REQUEST'; // { sessionId: string, mode: boolean }
    public static readonly SLAVE_MODE_REQUEST: string = 'SLAVE_MODE_REQUEST'; // { sessionId: string, mode: boolean }
    public static readonly SET_MASTER_MODE: string = 'SET_MASTER_MODE'; // { sessionId: string, mode: boolean }
    public static readonly SET_SLAVE_MODE: string = 'SET_SLAVE_MODE'; // { sessionId: string, mode boolean }
    public static readonly MASTER_MODE_ON: string = 'MASTER_MODE_ON'; // { sessionId: string }
    public static readonly MASTER_MODE_OFF: string = 'MASTER_MODE_OFF'; // { sessionId: string }
    public static readonly SLAVE_MODE_ON: string = 'SLAVE_MODE_ON'; // { sessionId: string }
    public static readonly SLAVE_MODE_OFF: string = 'SLAVE_MODE_OFF'; // { sessionId: string }

    // SYNC_STATE is used for the state of the sync plugin: master & slave mode values
    public static readonly SYNC_STATE: string = 'SYNC_STATE'; // { sessionId: string, masterMode: boolean, slaveMode: boolean}
    public static readonly SYNC_STATE_REQUEST: string = 'SYNC_STATE_REQUEST'; // { sessionId: string }

    // application state is the full state of the application
    public static readonly SYNCHRONIZE_APPLICATION_STATE: string = 'SYNCHRONIZE_APPLICATION_STATE'; // { sessionId: string, applicationState: TpzApplicationState }
    public static readonly SYNCHRONIZE_APPLICATION_STATE_REQUEST: string = 'SYNCHRONIZE_APPLICATION_STATE_REQUEST'; // { sessionId: string }
}

/**
 * Sync Events Categories
 */
export class ServerSyncPluginEventCategory {
    public static readonly EVENT_SYNCHRONIZATION_CATEGORY = 'EVENT_SYNCHRONIZATION';
    public static readonly ALREADY_SYNCHRONIZED_CATEGORY = 'ALREADY_SYNCHRONIZED';
    public static readonly SERVER_CONNECTION_CATEGORY = 'SERVER_CONNECTION';
}

/*
 * OutGoing and Incoming Messages type (sent or received to/from the server)
 *
 */
export class ServerSyncPluginMessageType {
    // !!!!  If changes are made, remember to change them in ToPaZ BackEnd TOO !!!!!!!!!!
    public static readonly SYNCHRONIZATION_MESSAGE: string = 'SYNCHRONIZATION'; //  { masterSessionId: sessionId, event: TpzEvent }
    public static readonly MASTER_MODE_REQUEST_MESSAGE: string = 'MASTER_MODE_REQUEST'; // { sessionId: string, mode: boolean }
    public static readonly SET_MASTER_MODE_MESSAGE: string = 'SET_MASTER_MODE'; // { sessionId: string, mode: boolean }
}

/**
 * Event fired when server is connected
 */
export interface ServerSyncPluginConnectedEvent extends TpzApplicationEvent {}

/**
 * Event fired when server is disconnected
 */
export interface ServerSyncPluginDisconnectedEvent extends TpzApplicationEvent {}

/**
 * Event fired when a problem occurs with server connection
 */
export interface ServerSyncPluginConnectionProblemEvent extends TpzApplicationEvent {
    readon: string;
}

// /**
//  * Event used to synchronize events between clients (via server)
//  */
// export interface SynchronizeEvent extends TpzApplicationEvent {
//     eventType: string,
//     eventContent: any,
// }
