/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { DateTimeView, DateTimeViewConfig, defaultDateTimeViewConfig } from './datetime-view';

/**
 * DateTime factory
 */
export class DateTimeViewFactory extends TpzApplicationFactory {
    public static readonly DATE_TIME_FACTORY_TYPE = 'DateTimeFactory';

    /**
     * Constructor
     */
    constructor(application: TpzApplication) {
        super(DateTimeViewFactory.DATE_TIME_FACTORY_TYPE, application);
        this.addHandledItem(
            DateTimeView.DATETIME_VIEW_TYPE,
            this.createDateTimeView.bind(this),
            defaultDateTimeViewConfig
        );
    }

    /** DateTime creator function */
    private createDateTimeView(config: DateTimeViewConfig): Promise<DateTimeView> {
        return Promise.resolve(new DateTimeView(config, this.getApplication()));
    }
}
