/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application/tpz-application-core';
import { AccessorEventType } from './tpz-access-event';
import {
    AccessorValueImportExport,
    DataConvertFromString,
    DataConvertToString,
    AccessorCategoryType
} from './tpz-access-types';
import { ValueAccessor, ValueAccessorConfig, defaultValueAccessorConfig } from './tpz-access-value';
import { deepCopy } from '../tpz-application/tools/deep-copy';
import { AccessorType } from './tpz-access-types';

export const WEBSOCKET_ACCESSOR_CATEGORY: string = 'WebSocketAccessor';
/**
 * WebSocket data accessor configuration
 */
export interface WebSocketAccessorConfig<DataType> extends ValueAccessorConfig<DataType> {
    wsURL?: string; // websocket url (complete "ws://host:port/Service" or local "/Service")
    wsProtocol?: 'ws' | 'wss';
    wsHost?: string;
    wsPort?: number;
    autoconnect?: boolean; // automatic reconnection if disconnected
}

// default config
export const defaultWebSocketAccessorConfig: WebSocketAccessorConfig<any> = {
    ...defaultValueAccessorConfig,
    // type is not defined for abstract classes
    wsURL: 'undefined.url',
    wsProtocol: 'ws',
    wsHost: 'undefined.server',
    wsPort: 9999,
    autoconnect: false
};

/**
 * use a websocket to get data from a server.
 * TODO: implement discussion protocol
 * Currently no protocol is defined, data are only received from the server. It is not possible
 * to request data by sending order to the server. It should exist
 * ERRORS:
 * WEBSOCKET_ERROR: details are 'Websocket.Event' type
 * RESPONSE_TYPE_ERROR: details are 'any' type (websocket received data)
 */
export abstract class WebSocketAccessor<DataType> extends ValueAccessor<DataType> {
    private static readonly WEBSOCKET_DEFAULT_PROTOCOL: string = 'ws';
    private static readonly WEBSOCKET_DEFAULT_PORT: string = '8099';
    private static readonly WEBSOCKET_DEFAULT_HOST: string = 'localhost';

    public static readonly WEBSOCKET_ERROR: string = 'WEBSOCKET_ERROR';
    public static readonly RESPONSE_TYPE_ERROR: string = 'RESPONSE_TYPE_ERROR';
    private ws: WebSocket = null;
    private closeRequest: boolean = false;

    /**
     * Abstract Websocket constructor
     * @param config accessor configuration
     * @param type accessor type ID
     * @param app application in which the accessor is in use
     * @param fromString function converting String to Value
     * @param toString function converting value to string
     */
    constructor(
        config: WebSocketAccessorConfig<DataType>,
        type: string,
        app: TpzApplication,
        fromString: DataConvertFromString<DataType>,
        toString: DataConvertToString<DataType>
    ) {
        super(deepCopy(defaultWebSocketAccessorConfig, config), type, app, fromString, toString);
        this.addCategory(AccessorCategoryType.WEBSOCKET_ACCESSOR_CATEGORY_TYPE);
        // set default value for autoconnect
        if (typeof config.autoconnect === 'undefined') {
            this.setAutoConnect(true);
        }
        // this.setWebSocketURL(config.wsURL);
    }
    /**
     * Set autoconnect accessor configuration
     * @param autoConnect value to be set (true/false)
     */
    public setAutoConnect(autoConnect: boolean) {
        const config: WebSocketAccessorConfig<DataType> = deepCopy(this.getConfig());
        config.autoconnect = autoConnect;
        this.applyConfig(config);
    }

    /**
     * close websocket
     * @returns a promise fulfilled when disconnected or rejection
     */
    public closeWebSocket(): Promise<void> {
        return new Promise((resolve) => {
            if (this.ws) {
                this.closeRequest = true;
                // console.log("Firing: AccessorEventType.DISCONNECTING");
                this.fireApplicationEvent(AccessorEventType.DISCONNECTING, null);
                if (this.ws.readyState === WebSocket.OPEN) {
                    this.ws.close();
                }
            }
            return resolve();
        });
    }

    /**
     * Return the websocket current state.
     * @returns websocket state or undefined if ws is null.
     */
    public getWebSocketState(): number {
        return this.ws?.readyState;
    }

    /**
     * Open websocket using wsURL.
     * if config.wsURL startsWith '/': it constructs URL from protocol + host + port + wsURL
     * else use directly the wsURL
     */
    public openWebSocket(): Promise<WebSocket> {
        return new Promise((resolve, reject) => {
            if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
                return resolve(this.ws);
            }
            if (!this.getConfig().wsURL) {
                return reject('Accessor ' + this.getId() + ' Cannot open a web socket with an invalid URL');
            }
            // protocol
            let wsProtocol: string = this.getConfig().wsProtocol;
            if (!wsProtocol) {
                // if not in a browser
                if (typeof location !== 'undefined') {
                    wsProtocol = location.protocol === 'https:' ? 'wss' : 'ws';
                } else {
                    wsProtocol = WebSocketAccessor.WEBSOCKET_DEFAULT_PROTOCOL;
                }
            }
            // port
            let wsPort: string = String(this.getConfig().wsPort);
            if (!this.getConfig().wsPort) {
                // if not in a browser
                if (typeof location !== 'undefined') {
                    wsPort = String(location.port);
                } else {
                    wsPort = WebSocketAccessor.WEBSOCKET_DEFAULT_PORT;
                }
            }
            // host
            let wsHost: string = this.getConfig().wsHost;
            if (!wsHost) {
                if (typeof location !== 'undefined') {
                    wsHost = location.hostname;
                } else {
                    wsHost = WebSocketAccessor.WEBSOCKET_DEFAULT_HOST;
                }
            }
            // relative url => concat local host
            let wsURL: string = this.getConfig().wsURL;
            if (wsURL.startsWith('/')) {
                wsURL = wsProtocol + '://' + wsHost + ':' + wsPort + wsURL;
            }
            console.info('Connect WebSocket at ' + wsURL);
            // FIXME: add error management...

            // console.log("Firing: AccessorEventType.CONNECTING");
            this.fireApplicationEvent(AccessorEventType.CONNECTING, null);
            this.ws = new WebSocket(wsURL);
            this.ws.onopen = this.openCallback.bind(this);
            this.ws.onerror = this.errorCallback.bind(this);
            this.ws.onclose = this.closeCallback.bind(this);
            this.ws.onmessage = this.messageReceivedCallback.bind(this);

            return resolve(this.ws);
        });
    }

    /**
     * Set WebSocket URL. Call openWebSocket() method to really open it.
     * @param wsURL
     */
    public setWebSocketURL(wsURL: string): void {
        const config: WebSocketAccessorConfig<DataType> = deepCopy(this.getConfig());
        config.wsURL = wsURL;
        this.applyConfig(config);
    }

    /**
     * Tells if the last websocket exchange has been correctly finished
     */
    public isRunning(): boolean {
        if (!super.isRunning) return false;
        return this.getWebSocketState() === WebSocket.OPEN;
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): WebSocketAccessorConfig<any> {
        return super.getConfig() as WebSocketAccessorConfig<any>;
    }

    /**
     * Start accessor by opening web socket
     */
    public doStart(): Promise<void> {
        return this.openWebSocket().then(() => {
            return super.doStart();
        });
    }

    /**
     * Stop accessor by closing web socket
     */
    public doStop(): Promise<void> {
        return this.closeWebSocket().then(() => {
            return super.doStop();
        });
    }

    public requestUpdate(): boolean {
        if (!this.isRunning()) return false;
        // TODO: If the protocol handles it, we may send a request to the server...
        this.getLogger().info('A Request should be sent to the websocket server. Implement protocol...');
        return true;
    }

    /**
     * Websocket Open callback
     * @param event
     */
    private openCallback(): void {
        //        console.log("Websocket " + this.getId() + " connection opened");
        // console.log("Firing: AccessorEventType.CONNECTED");
        this.fireApplicationEvent(AccessorEventType.CONNECTED, null);
        if (this.closeRequest) {
            this.closeWebSocket();
        }
    }

    /**
     * Websocket Close callback
     * @param event
     */
    private closeCallback(): void {
        this.ws.onopen = null;
        this.ws.onerror = null;
        this.ws.onclose = null;
        this.ws.onmessage = null;
        this.closeRequest = false;
        // delete this.ws;

        //    console.log("Websocket " + this.getId() + " connection closed");
        // console.log("Firing: AccessorEventType.DISCONNECTED");
        this.fireApplicationEvent(AccessorEventType.DISCONNECTED, null);
        // reopen a websocket connection to reconnect if server goes available one day...
        if (this.getConfig().autoconnect && this.getWebSocketState() === WebSocket.CLOSED) this.openWebSocket();
    }

    /**
     * Method called when the WebSocket sends a valid response.
     * This method must be overridden to convert websocket string response to specific object
     * @param receivedValue string value read in websocket stream
     */
    public abstract convertValue(receivedValue: string): DataType;

    /**
     * Callback called on websocket data reception
     * @param event
     */
    private messageReceivedCallback(event: MessageEvent): void {
        const me: WebSocketAccessor<DataType> = this;
        try {
            this.setValue(me.convertValue(event.data));
            //                this.fireItemEvent(AccessorEventType.DATA, null);
        } catch (error) {
            me.addError([WebSocketAccessor.RESPONSE_TYPE_ERROR, event]);
        }
    }

    /**
     * WebSocket error callback
     * @param event
     */
    private errorCallback(event: Event): void {
        //        console.log("Websocket " + this.getId() + " error received");
        this.addError([WebSocketAccessor.WEBSOCKET_ERROR, event]);
        this.closeWebSocket();
    }
}

/**
 * Websocket accessor for Strings
 */
export type StringWebSocketAccessorConfig = WebSocketAccessorConfig<string>;

// default web socket config
export const defaultStringWebSocketAccessorConfig: StringWebSocketAccessorConfig = {
    ...defaultWebSocketAccessorConfig,
    type: AccessorType.STRING_WEBSOCKET_ACCESSOR_TYPE
};

export class StringWebSocketAccessor extends WebSocketAccessor<string> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app topaz application in which this accessor is used
     */
    constructor(config: StringWebSocketAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultStringWebSocketAccessorConfig, config),
            AccessorType.STRING_WEBSOCKET_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringString,
            AccessorValueImportExport.convertToStringString
        );
    }

    /**
     * Simply return the received string
     * @param receivedValue string value read in websocket stream
     */
    public convertValue(receivedValue: string): string {
        return receivedValue;
    }
}

/**
 * Websocket accessor for Strings
 */
export type NumberWebSocketAccessorConfig = WebSocketAccessorConfig<number>;

// default web socket config
export const defaultNumberWebSocketAccessorConfig: WebSocketAccessorConfig<number> = {
    ...defaultWebSocketAccessorConfig,
    type: AccessorType.NUMBER_WEBSOCKET_ACCESSOR_TYPE
};

/**
 * Websocket accessor for a single number
 */
export class NumberWebSocketAccessor extends WebSocketAccessor<number> {
    constructor(config: NumberWebSocketAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultNumberWebSocketAccessorConfig, config),
            AccessorType.NUMBER_WEBSOCKET_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringNumber,
            AccessorValueImportExport.convertToStringNumber
        );
    }

    /**
     * Simply return the received string
     * @param receivedValue string value read in websocket stream
     */
    public convertValue(receivedValue: string): number {
        return Number(receivedValue);
    }
}
