/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemConfig } from '../../tpz-catalog/tpz-item-config';

/**
 * DesktopConfig contains a full description of all modules information:
 * - Desktop name
 * - All Specific Configurations used to create the module (module type and parameters)
 */
export interface TpzDesktopConfig extends ItemConfig {
    name?: string;
}

/**
 * default desktop configuration
 */
export const defaultTpzDesktopConfig: TpzDesktopConfig = {
    id: 'unnamed',
    name: 'default desktop'
};
