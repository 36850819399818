/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../../tpz-log/tpz-log-core';
import { uuidv4 } from './uuid';

/**
 * Response from topaz server using ToPaZ protocol
 */
export interface TpzProtocolResponse {
    status: 'ok' | 'error';
    type?: 'json' | 'string';
    content?: any;
    error?: string;
    trace?: string[];
}

/******************************* LAZY LOADING ***************************************/

/** Static class with helper functions */
export class LoadHelper {
    // list of loaders
    private static readonly loaders: { [source: string]: Promise<any> } = {};

    /**
     * javascript lazy loading function. Returns a Promise
     * insert script into dom document as <SCRIPT> tag
     * @param src script source
     * @param scriptHTMLElementId HTMLScriptElement id value. a random ID is generated if null
     */
    public static loadJsScript(src: string, scriptHTMLElementId: string = null): Promise<HTMLScriptElement> {
        if (!src) return Promise.reject(new Error('Unable to load script with an empty source'));
        let existingPromise: Promise<any> = LoadHelper.loaders[src];
        if (!existingPromise) {
            // return an empty promise if no source given or if source is already loading
            // if (!src || sourcesCurrentlyLoading.indexOf(src) >= 0)
            //   return new Promise(function (resolve: ScriptLoadedCallback, reject: ScriptErrorCallback) { });
            // sourcesCurrentlyLoading.push(src);
            existingPromise = new Promise(
                (resolve: (scriptElement: HTMLScriptElement) => void, reject: (reason: any) => void) => {
                    const scriptElement: HTMLScriptElement = document.createElement('script');
                    scriptElement.src = src;
                    scriptElement.id = scriptHTMLElementId ? scriptHTMLElementId : uuidv4();
                    // add a timeout
                    const TIMEOUT: number = 10000;
                    const timeoutId: any = setTimeout(() => {
                        reject(new Error('Loading script timeout after ' + TIMEOUT / 1000 + ' seconds: ' + src));
                    }, TIMEOUT);
                    scriptElement.onload = () => {
                        clearTimeout(timeoutId);
                        resolve(scriptElement);
                    };
                    scriptElement.onerror = (event: string | Event) => {
                        clearTimeout(timeoutId);
                        reject(new Error('Unable to load script from ' + src));
                    };
                    document.head.appendChild(scriptElement);
                }
            );
            LoadHelper.loaders[src] = existingPromise;
        }
        return existingPromise.finally(() => {
            //
        });
    }

    // /**
    //  * chain promises sequentially
    //  * @param promisesIterator
    //  * @returns
    //  */
    // private static async executeSequentially(promisesIterator: IterableIterator<Promise<any>>): Promise<void> {
    //   if (!promisesIterator) return Promise.resolve(null);
    //   let pSeq: Promise<any> = Promise.resolve();
    //   for (const p of promisesIterator) {
    //     await p.then(() => {
    //       console.warn("end of loadJsScript iterator promise [executeSequentially method]");
    //     });
    //   }
    //   return Promise.resolve().then(() => {
    //     console.warn("end of executeSequentially ** all loaders are termnated ** [inside method]");
    //   });
    // }

    /**
     * load all scripts given in arguments
     * scripts are loaded in SEQUENCE (from array order)
     * @param scriptURLs string[] containing scripts URLs
     * @returns
     */
    public static async loadJsScripts(scriptURLs: string[], logger: Logger): Promise<void> {
        let scriptURLIndex = 0;
        while (scriptURLIndex < scriptURLs.length) {
            const promise = LoadHelper.loadJsScript(scriptURLs[scriptURLIndex]);
            await promise; // execute promise sequentially
            scriptURLIndex = scriptURLIndex + 1;
        }
    }

    // {

    //     // define generator function generating loader promises
    //     function* scriptLoaderIterator(scriptURLs: string[]): Generator<Promise<HTMLScriptElement>> {
    //       let scriptURLIndex = 0;
    //       while (scriptURLIndex < scriptURLs.length) {
    //         let promise = LoadHelper.loadJsScript(scriptURLs[scriptURLIndex]).finally(() => {
    //           console.warn("end of execute loadJsScript " + scriptURLs[scriptURLIndex] + " [loadJsScripts method]");
    //         });
    //         yield promise; // yield new promise
    //         scriptURLIndex = scriptURLIndex + 1;
    //       }
    //     }
    //     // load sequentially all loader promises
    //     return LoadHelper.executeSequentially(scriptLoaderIterator(scriptURLs)).finally(() => {
    //       console.warn("end of execute sequentially from loadJsScript [inside loadJsScripts]");
    //     });

    // return LoadHelper.loadJsScriptsSequenciallyRec(scriptURLs, logger);
    // let loadJsPromise: Promise<void> = new Promise((resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) => {

    //   scriptURLs.forEach(async (scriptFile: string) => {
    //     console.warn("waiting for script to load " + scriptFile);
    //     await LoadHelper.loadJsScript(scriptFile)
    //       .then((el: HTMLScriptElement) => {
    //         console.warn("script loaded " + scriptFile);
    //       })
    //       .catch((reason: any) => {
    //         logger.error("Error loading script File " + scriptFile, reason);
    //       });
    //     console.warn("script load awaited" + scriptFile);
    //     resolve();
    //   });
    // });
    // return loadJsPromise;
    // }
    // static loadJsScriptsSequenciallyRec(scriptURLs: string[], logger: Logger): Promise<void> {
    //   if (!scriptURLs || scriptURLs.length <= 0) return Promise.resolve();
    //   console.warn("Start loading first script " + scriptURLs[0] + " on " + scriptURLs.length);
    //   return LoadHelper.loadJsScript(scriptURLs[0])
    //     .catch((reason: any) => {
    //       logger.error("Error loading script File " + scriptURLs[0], reason);
    //     })
    //     .then(() => {
    //       console.warn("Start loading others " + scriptURLs.slice(1) + " scripts");
    //       return LoadHelper.loadJsScriptsSequenciallyRec(scriptURLs.slice(1), logger);
    //     });

    // }

    /**
     * load all css style sheets given in arguments
     * scripts are loaded in PARALLEL
     * @param cssURLs string[] containing css URLs
     * @returns
     */
    public static loadCSSs(cssURLs: string[], logger: Logger): Promise<void> {
        if (!cssURLs || cssURLs.length === 0) return Promise.resolve();
        const promises: Promise<HTMLLinkElement>[] = cssURLs.map((cssURL: string) => {
            // load css
            return (
                LoadHelper.loadCSS(cssURL)
                    // and manage error if ever one arises
                    .catch((reason: any) => {
                        logger.error('Error loading cssURL ' + cssURLs, reason);
                        return null;
                    })
            );
        });
        return Promise.all(promises).then(() => null);
    }

    /**
     * CSS lazy loading function. Returns a Promise
     * insert css into dom document as meta <LINK> tag if it does not already exist
     * @param src
     */
    public static loadCSS(src: string, id: string = null): Promise<HTMLLinkElement> {
        if (!src) return Promise.reject(new Error('Unable to load a null CSS'));
        let existingPromise: Promise<any> = LoadHelper.loaders[src];
        if (!existingPromise) {
            // add stylesheet async
            const loadCssPromise: Promise<HTMLLinkElement> = new Promise(
                (resolve: (value: HTMLLinkElement) => void, reject: (reason: any) => void) => {
                    const cssElement: HTMLLinkElement = document.createElement('link');
                    cssElement.type = 'text/css';
                    cssElement.rel = 'stylesheet';
                    if (id) cssElement.id = id;
                    cssElement.href = src;
                    // console.log("Check style sheet " + src);
                    // check if a style sheet has already been loaded, then disable it
                    const styleSheetList: StyleSheetList = document.styleSheets;
                    for (const i in styleSheetList) {
                        //console.log("Check " + styleSheetList[i].href + " against " + cssElement.href);
                        if (styleSheetList[i].href === cssElement.href) {
                            styleSheetList[i].disabled = true;
                        }
                    }
                    //console.log("finally add css " + src + " into document.head");
                    document.head.appendChild(cssElement);
                    resolve(cssElement);
                }
            );
            // check if the given url exists
            existingPromise = fetch(src)
                .then((response: Response) => {
                    if (response.ok) return loadCssPromise;
                    else {
                        throw new Error(
                            'CSS ' +
                                src +
                                ' does not exist (HTTP error ' +
                                response.status +
                                ' / ' +
                                response.statusText
                        );
                    }
                })
                .catch((reason) => {
                    throw new Error('CSS ' + src + ' does not exist ' + reason);
                });
            LoadHelper.loaders[src] = existingPromise;
        }
        return existingPromise;
    }

    /**
     * Basic fetch Request returning the Response object
     * Response status is checked. if status is not ok an error is thrown
     * credential:'include' field is added to init object
     * @param url request URL address
     * @param init request parameters
     * @returns the response object
     */
    public static fetchRequest(url: string, init: RequestInit = null): Promise<Response> {
        // force credential to 'include' in order to send cookies
        if (!init) init = {};

        // // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DEBUG

        // init.credentials = 'include';
        // // "" | "no-referrer" | "no-referrer-when-downgrade" | "origin" |
        // "origin-when-cross-origin" | "same-origin" | "strict-origin" | "strict-origin-when-cross-origin" | "unsafe-url";
        // init.referrerPolicy = "origin";
        // init.referrer = "no-referrer";
        // init.redirect = "manual";
        // init.mode = "cors";

        //    console.info("fetchRequest init : " + JSON.stringify(init))
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DEBUG

        // send the request
        return fetch(url, init).then((response: Response) => {
            if (response.ok) return response;
            throw new Error(url + ' response returned status ' + response.status + ' (' + response.statusText + ')');
        });
    }

    /**
     *  fetch Request returning the TpzProtocolResponse
     * Check if the received response is a valid TpzProtocolResponse
     * No check is done on the response status
     * @param url request URL address
     * @param init request parameters
     * @returns the response object
     */
    public static fetchRequestTopazProtocol(url: string, init: RequestInit = null): Promise<TpzProtocolResponse> {
        return LoadHelper.fetchRequest(url, init)
            .then((response: Response) => {
                return response.json();
            })
            .then((json: any) => {
                // check response is not null
                if (json === null) throw new Error(url + ' response is not a JSON object');
                // check response contain a .status of type "string" within values ("ok" & "error")
                if (!json.status) {
                    throw new Error(
                        url +
                            " response is not a valid TpzResponse object containing a'status' field : " +
                            JSON.stringify(json)
                    );
                }
                if (typeof json.status !== 'string') {
                    throw new Error(
                        url +
                            " response is not a valid TpzResponse object containing a'status' field of type string. typeof 'status' = " +
                            typeof json.status
                    );
                }
                if (json.status !== 'ok' && json.status !== 'error') {
                    throw new Error(
                        url +
                            " response is not a valid TpzResponse object. 'status' field MUST be 'ok' or 'error' " +
                            json.status
                    );
                }
                if (json.status === 'ok' && !json.content) {
                    throw new Error(
                        url +
                            " response is not a valid TpzResponse object. 'status' field === 'ok' but 'content' is not defined " +
                            JSON.stringify(json)
                    );
                }
                if (json.status === 'error' && !json.error) {
                    throw new Error(
                        url +
                            " response is not a valid TpzResponse object. 'status' field === 'error' but 'error' is not defined " +
                            JSON.stringify(json)
                    );
                }
                // certify that this object is a TpzProtocolResponse
                return json as TpzProtocolResponse;
            });
    }

    /**
     * Basic fetch Request returning the the TpzProtocolResponse
     * if the status is "error", it throws an error with the "error" field
     * if the status is "ok", it returns the "content" field
     * @param url request URL address
     * @param init request parameters
     * @returns the response object
     */
    public static fetchValidRequestTopazProtocol(url: string, init: RequestInit = null): Promise<any> {
        return LoadHelper.fetchRequestTopazProtocol(url, init).then((response: TpzProtocolResponse) => {
            if (response.status === 'error') throw new Error(url + ' returns a logical error: ' + response.error);
            if (response.status === 'ok') return response.content;
            throw new Error(
                url + " response should contain 'ok' or 'error' in status field (not " + response.status + ')'
            );
        });
    }
}
