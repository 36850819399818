/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzMenuElement } from '../menu/tpz-menu';
import { TpzApplication } from '../tpz-application-core';
import { TpzDesktop } from '../desktop/tpz-desktop-core';
import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { ItemConfig } from '../../tpz-catalog/tpz-item-config';
import { ItemFactory } from '../../tpz-catalog/tpz-item-factory';
import {
    ItemParameterViewConfig,
    ItemParameterViewHelper
} from '../items/instances/item-parameter/item-parameter-view';
import { TpzApplicationCommander } from '../tpz-application-commander';
import { uuidv4 } from '../tools/uuid';
import { JsPanel, JsPanelOptions } from '../jspanel-core';
import { deepCopy } from '../tools/deep-copy';
import { defaultTpzWindowConfig, TpzWindowConfig } from './tpz-window-config';
import { TpzWindowEventTypes } from './tpz-window-event';
import { TpzApplicationCategories, TpzApplicationTypes } from '../tpz-application-types';
import { TpzApplicationUI } from '../tpz-application-ui';

declare const jsPanel: JsPanel;

/**
 * Default options for jsPanels
 */
const TPZ_WINDOW_JSPANEL_OPTIONS: JsPanelOptions = {
    headerLogo: 'images/icons/menu.svg',
    headerControls: {
        size: 'xl'
    },
    //    headerToolbar: '<span> header toolbar </span>',
    theme: {
        //bgPanel: 'url("img/trianglify-primary.svg") right bottom',
        bgPanel: 'rgba(29, 29, 27, 0.7);',
        bgContent: 'rgba(29, 29, 27, 0.7);',
        colorContent: 'white'
    }
};

/**
 * Floating window Item based on JsPanel.
 */
export class TpzWindow extends ItemInstance {
    private static readonly WINDOW_ACTIVE_MENU_CLASS: string = 'active-module-menu';
    private static readonly WINDOW_INACTIVE_MENU_CLASS: string = 'inactive-module-menu';

    private panel: JsPanel = null;
    private parent: HTMLDivElement = null; // container in which JsPanel is inserted
    private contentDiv: HTMLDivElement = null; // content inserted into JsPanel, which will receive item visualizations
    private logoMenuDiv: HTMLDivElement = null;
    private menuHeaderDiv: HTMLDivElement = null;
    private menuHeaderLogo: HTMLImageElement = null;
    private menuHeaderTitle: HTMLSpanElement = null;
    private readonly menuElements: TpzMenuElement[] = [];
    private menuRemovalId: any = null; // setTimeout identifier for closing the logo menu div

    constructor(config: TpzWindowConfig, application: TpzApplication) {
        super(deepCopy(defaultTpzWindowConfig, config), TpzApplicationTypes.TPZ_WINDOW_TYPE, application);
        this.addCategory(TpzApplicationCategories.TPZ_WINDOW_CATEGORY);
        this.addLogoMenuElement({
            id: `${this.getId()}-refresh-light`,
            name: 'refresh - light',
            attributes: { i18n: 'ModuleMenuRefresh' },
            action: this.refreshCallback.bind(this)
        });
        this.addLogoMenuElement({
            id: `${this.getId()}-refresh-hard`,
            name: 'refresh - hard',
            attributes: { i18n: 'ModuleMenuForceRefresh' },
            action: this.forceRefreshCallback.bind(this)
        });
        this.addLogoMenuElement({
            id: `${this.getId()}-parameters`,
            name: 'parameters',
            attributes: { i18n: 'ModuleParameters' },
            action: this.displayParameterWindow.bind(this)
        });
    }

    /**
     * get desktop in which this item is plugged. throws an exception if no or multiple parents
     * @returns the unique parent if it exists, null if no parents or an error if multiple
     */
    private getParentDesktop(): TpzDesktop {
        const parents: ItemInstance[] = this.getManagedParents();
        if (!parents || parents.length == 0) {
            return null;
        }
        if (parents.length > 1) {
            throw new Error(
                `window #${this.getId()} has multiple parents: (${parents.map((item) => item.getId()).join(', ')})`
            );
        }
        const parent: ItemInstance = parents[0];
        if (!parent) throw new Error(`window #${this.getId()} has one single null parent`);
        if (!parent.containsCategory(TpzApplicationCategories.TPZ_DESKTOP_CATEGORY)) {
            throw new Error(
                `window #${this.getId()} has one single parent #${parent.getId()} which is not a desktop... categories = ${parent
                    .getCategories()
                    .join(', ')}`
            );
        }
        return parent as TpzDesktop;
    }

    /**
     * function called just after JsPanel creation. This method may be overidden.
     * Default behaviour is just returning true
     */

    public onPanelCreation(_jsPanel: JsPanel): boolean {
        return true;
    }

    /**
     * Log full explanation why an item cannot be instanciated
     * @param viewId
     * @returns
     */
    private logNoItemReason(viewId: string): void {
        this.getLogger()?.error(`Cannot launch view #${viewId} referenced in window #${this.getId()}`);
        // add some user help in order to understand why this view cannot be displayed
        // check if a valid configuration exists
        const viewConfig: ItemConfig = this.getApplication().getItemCatalog().getRegisteredConfigById(viewId);
        if (!viewConfig) {
            this.getLogger()?.error(
                'No Item Configuration registered in application catalog. Check id spelling or add a valid configuration'
            );
            return;
        }
        this.getLogger()?.info(`View Config = ${JSON.stringify(viewConfig)}`);
        // check if a factory handles this config type
        const factory: ItemFactory = this.getApplication()
            .getItemCatalog()
            .getFactoryManager()
            .getHandlingItemFactory(viewConfig.type);
        if (!factory) {
            this.getLogger()?.error(
                `No factory handles type ${viewConfig.type}. Register a factory or check type spelling`
            );
            this.getLogger()?.info('Registered factories:');
            this.getApplication()
                .getItemCatalog()
                .getFactoryManager()
                .getRegisteredFactories()
                .forEach((factory: ItemFactory) => {
                    this.getLogger()?.info(
                        `- ${factory.getType()} handling types : ${factory.getHandledTypes().join(', ')}`
                    );
                });
            return;
        }
        this.getLogger()?.info(`Type factory = ${factory.getType()}`);
        // check instance
        factory.createInstance(viewConfig).then((instance: ItemInstance) => {
            if (!instance) {
                this.getLogger()?.error(
                    `Factory ${factory.getType()} handles type ${
                        viewConfig.type
                    } but returns a null instance. Internal error !!`
                );
                return;
            }
            this.getLogger()?.info(
                `Factory type ${factory.getType()} returns a valid instances for item type ${
                    viewConfig.type
                }. There should not be an error at this point.... :(`
            );
        });
    }

    /** JsPanelOptions getter */
    public getJsPanelOptions(): JsPanelOptions {
        if (!this.getConfig().jsPanelOptions) return {};
        return this.getConfig().jsPanelOptions;
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): TpzWindowConfig {
        return super.getConfig() as TpzWindowConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: TpzWindowConfig): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.title !== newConfig?.title) {
            if (config?.jsPanelOptions) {
                config.jsPanelOptions.headerTitle = `<span i18n='${this.getI18nTitleId()}'>${config?.title}</span>`;
            }
            changes = true;
        }
        // apply position
        if (config?.left !== newConfig?.left || config?.top !== newConfig?.top) {
            if (this.panel) {
                this.panel.reposition({
                    my: 'left-top',
                    at: 'left-top',
                    offsetX: newConfig?.left,
                    offsetY: newConfig?.top
                });
            }
        }
        // apply size
        if (config?.width !== newConfig?.width || config?.height !== newConfig?.height) {
            if (this.panel) {
                this.panel.resize({ width: newConfig?.width, height: newConfig?.height });
            }
        }
        if (
            config?.jsPanelOptions !== newConfig?.jsPanelOptions ||
            config?.logoIcon !== newConfig?.logoIcon ||
            config?.i18nTitleId !== newConfig?.i18nTitleId ||
            config?.frontZIndex !== newConfig?.frontZIndex ||
            config?.classes !== newConfig?.classes ||
            config?.updateOnResize !== newConfig?.updateOnResize ||
            config?.menuRemovalTime !== newConfig?.menuRemovalTime
        ) {
            // this.invalidateUI();
            this.updateUI();
            changes = true;
        }

        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }

    /** I18N Title ID getter */
    public getI18nTitleId(): string {
        if (!this.getConfig().i18nTitleId) return '';
        return this.getConfig().i18nTitleId;
    }

    /**
     * Create the JsPanel
     */
    private createjsPanel(container: HTMLDivElement): boolean {
        const me: TpzWindow = this;
        const windowConfig: TpzWindowConfig = me.getConfig();
        // check container validity
        if (!container) {
            throw new Error(`Cannot start window ${me.getId()} / ${me.getType()}. Given container is null`);
        }
        if (!container.id) {
            container.id = uuidv4();
            this.getLogger().warn(
                `Starting window ${me.getId()}. Container ID undefined, set it to random id #${container.id}`
            );
        }
        if (!document.getElementById(container.id)) {
            throw new Error(
                `Cannot start window ${me.getId()} / ${me.getType()}. Container #${container.id} is not in DOM`
            );
        }

        if (!jsPanel || !jsPanel.create) {
            throw new Error(
                `JsPanel library has not been loaded. Something wrong happens !  Please call your administrator.`
            );
        }
        // copy given options
        const options: JsPanelOptions = { ...TPZ_WINDOW_JSPANEL_OPTIONS, ...me.getJsPanelOptions() };
        if (windowConfig.logoIcon) options.headerLogo = windowConfig.logoIcon;
        // set container & content
        if (me.getJsPanelOptions() && me.getJsPanelOptions().content) {
            this.getLogger()?.warn(
                `jspanel id #${me.getId()} has set option 'container' which will not be used. Parent 'container' parameter will be used instead`
            );
        }
        this.parent = container;
        // set stored position and size stored in window config at window creation
        // if window config position is set, use those values instead of JsPanelOptions position
        if (windowConfig.left !== undefined && windowConfig.top !== undefined) {
            options.position = `${windowConfig.left} ${windowConfig.top}`;
        }
        // if window config size is set, use those values instead of JsPanelOptions size
        if (windowConfig.width !== undefined && windowConfig.height !== undefined) {
            options.panelSize = `${windowConfig.width} ${windowConfig.height}`;
        }
        // set ID
        options.id = me.getId();

        // fill window position
        // options.position = me.getPosition();
        // // fill window size
        // if (me.getJsPanelOptions().panelSize) {
        //     this.getLogger()?.warn("jspanel id #" + me.getId() + " has set option 'panelSize' which will not be used. Position is defined using cs-moduleConfig.size");
        // }
        // options.panelSize = me.getSize();
        // // set header title
        // if (me.getJsPanelOptions().headerTitle) {
        //     this.getLogger()?.warn("jspanel id #" + me.getId() + " has set option 'headerTitle' which will not be used. header title is defined using cs-moduleConfig.title and cs-moduleConfig.i18nTitleId");
        // }
        options.headerTitle = `<span i18n='${this.getI18nTitleId()}'>${me.getTitle()}</span>`;
        // on creation callback
        options.callback = [];
        if (me.getJsPanelOptions().callback) {
            options.callback = options.callback.concat(me.getJsPanelOptions().callback);
        }
        options.callback.push((panel: JsPanel) => {
            // stores panel
            me.panel = panel;
            // replace default content by content container
            const windowContent: HTMLElement = me.getContentDiv();
            panel.content.appendChild(windowContent);

            // Add actions on the header logo mouseover
            const logo: HTMLElement = panel.headerlogo;
            if (logo) {
                logo.addEventListener('click', this.onLogoClick.bind(this));
                logo.addEventListener('mouseover', this.onLogoOver.bind(this));
            }
            // add double click on title bar action (maximization / windowifiction)
            panel.titlebar.addEventListener('dblclick', () => {
                switch (panel.status) {
                    case 'normalized':
                        panel.smallify();
                        break;
                    case 'maximized':
                        panel.normalize();
                        break;
                    case 'minimized':
                        panel.normalize();
                        break;
                    case 'smallified':
                        panel.unsmallify();
                        break;
                    case 'smallifiedmax':
                        panel.maximize();
                        break;
                }
            });
            me.onPanelCreation(panel);
        });

        // resizeit.stop() callback is used to store position when GUI is user-moved
        const defaultResizeitStopFunction = (panel: JsPanel, size: { width: any; height: any }, event: any) => {
            me.storeSizeInConfig(size.width, size.height);
            // (panel.options.position as unknown as any).minTop = '0';
            // (panel.options.position as unknown as any).minLeft = '0';
            if (windowConfig.updateOnResize) this.updateUI();
        };
        options.resizeit = me.getJsPanelOptions().resizeit ?? {};
        options.resizeit.stop = options.resizeit.stop ?? [];
        options.resizeit.stop.push(defaultResizeitStopFunction);

        // dragit.stop() callback is used to store position when GUI is user-moved
        const defaultDragitStopFunction = (panel: JsPanel, position: any, event: any) => {
            // (panel.options.position as unknown as any).minTop = '0';
            // (panel.options.position as unknown as any).minLeft = '0';
            console.warn(`drag position: ${JSON.stringify(position)}`);
            console.warn(`panel position: ${JSON.stringify(panel.options.position)}`);
            me.storePositionInConfig(position.left, position.top);
        };
        options.dragit = me.getJsPanelOptions().dragit ?? { containment: 5 }; // containement keeps window in screen
        // options.dragit = me.getJsPanelOptions().dragit ?? {};
        options.dragit.stop = options.dragit.stop ?? [];
        options.dragit.stop.push(defaultDragitStopFunction);

        options.onstatuschange = [];
        if (me.getJsPanelOptions().onstatuschange) {
            options.onstatuschange = options.onstatuschange.concat(me.getJsPanelOptions().onstatuschange);
        }
        options.onstatuschange.push((panel: JsPanel, status: string) => {
            switch (status) {
                case 'normalized':
                    me.getConfig().jsPanelOptions.setStatus = 'normalized';
                    me.fireApplicationEvent(TpzWindowEventTypes.WINDOW_NORMALIZED, { itemId: me.getId() });
                    break;
                case 'maximized':
                    me.getConfig().jsPanelOptions.setStatus = 'maximized';
                    me.fireApplicationEvent(TpzWindowEventTypes.WINDOW_MAXIMIZED, { itemId: me.getId() });
                    break;
                case 'minimized':
                    me.getConfig().jsPanelOptions.setStatus = 'minimized';
                    me.fireApplicationEvent(TpzWindowEventTypes.WINDOW_MINIMIZED, { itemId: me.getId() });
                    break;
                case 'smallified':
                    me.getConfig().jsPanelOptions.setStatus = 'smallified';
                    me.fireApplicationEvent(TpzWindowEventTypes.WINDOW_SMALLIFIED, { itemId: me.getId() });
                    break;
                case 'smallifiedmax':
                    me.getConfig().jsPanelOptions.setStatus = 'smallifiedmax';
                    me.fireApplicationEvent(TpzWindowEventTypes.WINDOW_SMALLIFIED, { itemId: me.getId() });
                    break;
                default:
                    this.getLogger().warn(`JsPanel set unhandled state ${me.getJsPanel().status}`);
                    break;
            }
        });
        // use modify function to get the position conversion to valued x & y number
        // options.position.modify = (pos: any) => {
        //     console.warn(`--------------------------------- modify() method pos = ${JSON.stringify(pos)}`);
        //     // apply new Configuration
        //     me.storePositionInConfig(pos.left, pos.top);
        //     return pos;
        // };
        // onclosed() callback is used to store config state and launch application event
        const defaultClosedFunction = () => {
            this.closeWindowAndRemoveFromDesktops();
        };
        // FIXME: loose onClosed user defined function... we should chained them in this case
        // if (me.getJsPanelOptions().onclosed)
        //     options.onclosed = [ me.getJsPanelOptions().onclosed, defaultClosedFunction ] ;
        options.onclosed = defaultClosedFunction;

        // assign panel to parent container
        if (this.parent?.id) options.container = `#${this.parent.id}`;

        // check if the panel already exists
        const existingElement = document.getElementById(options.id);
        if (existingElement) {
            this.getLogger()?.error(`Panel ID ${options.id} already exists !`);
            options.id = `${options.id}-${uuidv4()}`;
            this.getLogger()?.error(`Replace window ID by ${options.id}`);
        }

        // create panel
        if (!jsPanel.create(options)) return false;
        // set classes
        if (windowConfig.classes) {
            windowConfig.classes.forEach((cl) => (this.panel as unknown as HTMLElement).classList?.add(cl));
        }

        // fire creation event
        me.fireApplicationEvent(TpzWindowEventTypes.WINDOW_CREATED, { id: this.getId() });

        if (windowConfig.frontZIndex) {
            // listen to fronted event and set z-index with user defined value
            document.addEventListener('jspanelfronted', function (event: any) {
                if (event.detail === me.getId()) {
                    me.setZIndex();
                }
            });
        }
        return true;
    }

    /**
     * Close window and remove it from its desktop
     */
    public closeWindowAndRemoveFromDesktops(): void {
        this.getManagedParents().forEach((parent: ItemInstance) => {
            TpzApplicationCommander.removeChildItemById(this.getApplication(), parent.getId(), this.getId());
        });
    }

    /**
     * Set Zindex if defined. It allows to force a window as background or foreground
     */
    private setZIndex(): void {
        // HACK: access to non exposed JsPanel data using cast
        // Change zIndex. default zIndex order is computed by JsPanel from 100 to 100+number of JsPanel
        if (this.getConfig().frontZIndex && this.panel && (this.panel as any).style) {
            ((this.panel as any).style as CSSStyleDeclaration).zIndex = `${this.getConfig().frontZIndex}`;
        }
    }

    /**
     * JsPanel Non-Lazy getter
     */
    public getJsPanel(): JsPanel {
        return this.panel;
    }

    /**
     * Update User Interface only
     */
    public updateUI(): boolean {
        if (!this.panel) return false;
        this.panel.resize();
        this.getManagedChildren().forEach((childItem: ItemInstance) => childItem.requestUpdate(false));
        return true;
    }

    /**
     * content div lazy getter
     */
    public getContentDiv(): HTMLDivElement {
        if (!this.contentDiv) {
            this.contentDiv = TpzApplicationUI.createDiv();
            this.contentDiv.style.width = '100%';
            this.contentDiv.style.height = '100%';
        }
        return this.contentDiv;
    }

    /**
     * Create the JsPanel and insert it into desktop
     * @param parentItem paret item which should be Desktop
     */
    public doPlugInParent(parentItem: ItemInstance): Promise<void> {
        return Promise.resolve()
            .then(() => {
                if (!parentItem) throw new Error('parentItem must be defined for window plug');
                if (!parentItem.containsCategory(TpzApplicationCategories.TPZ_DESKTOP_CATEGORY)) {
                    throw new Error(
                        `parentItem must be a desktop when window starts. Item = ${parentItem.getId()}. Categories = ${parentItem
                            .getCategories()
                            .join(', ')}. It MUST contain categorie ${TpzApplicationCategories.TPZ_DESKTOP_CATEGORY}`
                    );
                }
                if (this.isPluggedInto(parentItem)) return;
                const desktop: TpzDesktop = parentItem as TpzDesktop;
                const desktopContainer: HTMLDivElement = desktop.getMainContainerDiv();
                if (!desktopContainer) {
                    throw new Error(`Cannot retrieve Desktop Container Div from desktop #${desktop.getName()}`);
                }
                if (!this.createjsPanel(desktopContainer)) {
                    throw new Error(`Cannot Start JsPanel Window #${this.getId()}`);
                }
                this.setZIndex();
            })
            .then(() => super.doPlugInParent(parentItem));
    }

    /**
     * Removes window from parent (which should be a desktop)
     * @param parent
     */
    public doUnplugFromParent(parent: ItemInstance): Promise<void> {
        return Promise.resolve()
            .then(() => {
                // close panel
                if (this.panel) this.panel.close();
            })
            .then(() => super.doUnplugFromParent(parent));
    }

    /*
     * stop the window engine.
     * Can be overridden
     */
    public postStop(): Promise<void> {
        return super.postStop().then(() => {
            this.panel = null;
        });
    }

    /** Set window position */
    private storePositionInConfig(x: number, y: number): void {
        const config: TpzWindowConfig = this.getConfig();
        console.warn(`reposition ${x} ${y}`);
        // config.jsPanelOptions.position = `left-top ${x}px ${y}px`;
        config.left = x;
        config.top = y;
        TpzApplicationCommander.updateItemConfig(this.getApplication(), config);
    }

    /** Set window size */
    private storeSizeInConfig(width: number, height: number): void {
        console.warn(`resize ${width} ${height}`);
        const config: TpzWindowConfig = this.getConfig();
        // config.jsPanelOptions.panelSize = `${width}px ${height}px`;
        config.width = width;
        config.height = height;
        TpzApplicationCommander.updateItemConfig(this.getApplication(), config);
    }

    /** Set window title */
    public setTitle(title: string): void {
        const config = this.getConfig();
        config.title = title;
        TpzApplicationCommander.updateItemConfig(this.getApplication(), config);
    }

    /**
     * return the HTMLElement JsPanel container parent or null if none
     */
    public getContainer(): HTMLDivElement {
        return this.parent;
    }

    /**
     * title getter (from this.config)
     */
    public getTitle(): string {
        return this.getConfig().title;
    }

    /**
     * Add an element to the window menu
     */
    public addLogoMenuElement(element: TpzMenuElement): boolean {
        this.menuElements.push(element);
        return true;
    }

    /**
     * Module Menu Div Lazy Getter
     */
    private getLogoMenuDiv(): HTMLDivElement {
        // if a removal delay is in progress, stop it and return current non removed div
        if (this.menuRemovalId) {
            window.clearTimeout(this.menuRemovalId);
            this.menuRemovalId = null;
        }
        if (!this.logoMenuDiv) {
            this.createWindowMenu();
            this.logoMenuDiv.style.display;
        }
        return this.logoMenuDiv;
    }

    /**
     * Create the logoMenu HTML Element, this method can be called to refresh menu creation
     * if some elements were added/removed
     */
    private createWindowMenu(): boolean {
        const me: TpzWindow = this;

        if (!this.panel?.content) return false;
        if (this.logoMenuDiv) {
            this.panel?.content?.removeChild(this.logoMenuDiv);
        }
        // create the main div
        this.logoMenuDiv = document.createElement('div');
        this.logoMenuDiv.id = `${this.getId()}-menu`;
        this.logoMenuDiv.classList.add('module-menu');
        // hide menu when leaving menu div
        this.logoMenuDiv.onmouseleave = () => {
            me.hideWindowMenu();
        };
        // if menu div is reentered before disappearing, ask to redisplay it
        this.logoMenuDiv.onmouseenter = () => {
            me.displayWindowMenu();
        };
        this.logoMenuDiv.style.position = 'absolute';

        // add menu header (logo and title)
        this.menuHeaderDiv = document.createElement('div');
        this.menuHeaderDiv.classList.add('module-menu-header');
        this.menuHeaderDiv.addEventListener('click', (evt) => {
            me.hideWindowMenu();
        });
        // menu header logo
        this.menuHeaderLogo = document.createElement('img');
        if (this.getConfig().logoIcon) this.menuHeaderLogo.src = this.getConfig().logoIcon;
        this.menuHeaderLogo.classList.add('module-menu-header-logo');
        this.menuHeaderDiv.appendChild(this.menuHeaderLogo);
        // menu header title
        this.menuHeaderTitle = document.createElement('span');
        this.menuHeaderTitle.innerText = me.getTitle();
        this.menuHeaderTitle.classList.add('module-menu-header-title');
        this.menuHeaderDiv.appendChild(this.menuHeaderTitle);

        me.logoMenuDiv.appendChild(this.menuHeaderDiv);

        const ul: HTMLUListElement = document.createElement('ul');
        // fill all menu elements as LI in UL
        if (this.menuElements) {
            this.menuElements.forEach((element: TpzMenuElement) => {
                const li: HTMLLIElement = document.createElement('li');
                li.innerText = element.name;
                li.onclick = (evt) => {
                    if (element.action) element.action(evt);
                    me.hideWindowMenu();
                };
                // set attributes if defined
                if (element.attributes) {
                    for (const key in element.attributes) {
                        li.setAttribute(key, element.attributes[key]);
                    }
                }
                ul.appendChild(li);
            });
        }
        this.logoMenuDiv.appendChild(ul);
        document.body.appendChild(this.logoMenuDiv);
        return true;
    }

    /**
     * Callback called when the "refresh" menu element is clicked from the menu
     */
    private refreshCallback(): void {
        this.getLogger()?.debug(`light refresh of ${this.getId()} requested`);
        this.requestUpdate(false);
    }

    /**
     * Callback called when the "force refresh" menu element is clicked from the menu
     */
    private forceRefreshCallback(): void {
        this.getLogger()?.debug(`hard refresh of ${this.getId()} requested`);
        this.requestUpdate(true);
    }

    /**
     * Callback called when the "parameter" menu element is clicked from the menu
     */
    private displayParameterWindow(): boolean {
        const parameterViewConfig: ItemParameterViewConfig = ItemParameterViewHelper.createItemParameterViewConfig(
            this.getApplication(),
            this.getConfig()
        );
        TpzApplicationCommander.displayItemNewWindowInActiveDesktopByConfig(
            this.getApplication(),
            parameterViewConfig,
            true,
            `${parameterViewConfig.id}-window`
        );
        return true;
    }

    // /**
    //  * method called during the parameter window generation.
    //  * This method should be overloaded
    //  * @param parameterModule
    //  */
    // protected initParameterModule(parameterModule: CSParameterModule<any>): boolean {
    //     const paramElement: HTMLElement = TBSParameterFactory.createDisplayersParametersUI(this.getDisplayers());
    //     if (!parameterModule.getUI()) return false;
    //     parameterModule.getUI().appendChild(paramElement);
    //     return true;
    // }

    /**
     * Hide window menu popup
     * The menu is removed from the DOM after few seconds
     */
    private hideWindowMenu(): void {
        if (!this.logoMenuDiv) return;
        this.logoMenuDiv.classList.remove(TpzWindow.WINDOW_ACTIVE_MENU_CLASS);
        this.logoMenuDiv.classList.add(TpzWindow.WINDOW_INACTIVE_MENU_CLASS);
        // remove this logo menu div after config.menuRemovalTime (in milliseconds)
        if (this.menuRemovalId) {
            window.clearTimeout(this.menuRemovalId);
            this.menuRemovalId = null;
        }
        this.menuRemovalId = window.setTimeout(() => {
            if (document.body && this.logoMenuDiv) document.body.removeChild(this.logoMenuDiv);
            this.logoMenuDiv = null;
        }, this.getConfig().menuRemovalTime);
    }

    /**
     * Display a window menu popup at given position
     */
    private displayWindowMenu(): void {
        if (!this.panel || !this.panel?.headerlogo) return;
        // if a removal delay is in progress, stop it and return current non removed div
        if (this.menuRemovalId) {
            window.clearTimeout(this.menuRemovalId);
            this.menuRemovalId = null;
        }

        this.getLogoMenuDiv().style.display = 'visible';
        this.getLogoMenuDiv().style.position = 'absolute';
        this.getLogoMenuDiv().style.left = `${this.panel.headerlogo.getBoundingClientRect().left}px`;
        this.getLogoMenuDiv().style.top = `${this.panel.headerlogo.getBoundingClientRect().top}px`;
        this.getLogoMenuDiv().classList.remove(TpzWindow.WINDOW_INACTIVE_MENU_CLASS);
        this.getLogoMenuDiv().classList.add(TpzWindow.WINDOW_ACTIVE_MENU_CLASS);
    }

    /**
     * Action performed when the mouse pointer clicks on header logo
     * @param event mouse event
     */
    private onLogoOver(event: MouseEvent): void {
        this.displayWindowMenu();
    }

    /**
     * Action performed when the mouse pointer goes over header logo (C2 logo)
     * @param event mouse event
     */
    private onLogoClick(event: MouseEvent): void {
        //
    }

    /**
     * Request update launch a request update on all view desktop
     * @param force
     */
    public requestUpdate(force: boolean): boolean {
        // launch a requestUpdate on all managed children
        this.getManagedChildren()?.forEach((childrenItem: ItemInstance) => {
            childrenItem.requestUpdate(force);
        });
        // try to start/restart all children
        this.getConfig().childrenIds?.forEach((childrenId: string) => {
            this.getItemCatalog()
                .getOrCreateInstanceById(childrenId)
                .then((item: ItemInstance) => item.plugInParent(this));
        });
        this.updateUI();
        return super.requestUpdate(force);
    }
}
