/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Messages to send to Server using Topaz Protocol
 */
export class ToServerMessageProtocol {
    public static readonly PING_MESSAGE: string = 'PING';
    public static readonly PONG_MESSAGE: string = 'PONG';
    public static readonly CLIENT_IDENTIFICATION_MESSAGE: string = 'CLIENT_IDENTIFICATION'; // { clientId: string }
    public static readonly SYNCHRONIZE_EVENT_MESSAGE: string = 'SYNCHRONIZE_EVENT'; // message contains only an event (no defined interface)
}

/**
 * Message sent to Server PING
 */
export interface ToServerPingMessage {
    clientEmitTime: number;
}

/**
 * Message sent to Server PONG
 */
export interface ToServerPongMessage {
    serverEmitTime: number;
    clientResponseTime: number;
}
