/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { defaultTpzPluginConfig, TpzPlugin, TpzPluginConfig } from '../../tpz-plugin-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzClientSession } from '../../../server/session';
import { deepCopy } from '../../../tools/deep-copy';
import { JsPanel } from '../../../jspanel-core';
import { TpzApplicationUI, ModalWindow } from '../../../tpz-application-ui';

//FIXME: are we sure of this declare ??!!
declare const jsPanel: JsPanel;

const SERVER_CONNECTION_PLUGIN_TYPE: string = 'ServerConnectionPluginType';

/**
 *  Plugin configuration
 */
export interface ServerConnectionPluginUIConfig extends TpzPluginConfig {
    divId?: string;
    parentId?: string;
    connectedClass?: string;
}

export const defaultServerConnectionPluginUIConfig: ServerConnectionPluginUIConfig = {
    ...defaultTpzPluginConfig,
    id: 'server-connection-plugin-ui',
    type: SERVER_CONNECTION_PLUGIN_TYPE,
    divId: 'server-connection-plugin-ui',
    parentId: 'menu-id',
    connectedClass: 'connected'
};

/**
 * Server Connection plugin
 */
export class ServerConnectionPluginUI extends TpzPlugin {
    public static readonly SERVER_CONNECTION_PLUGIN_TYPE: string = SERVER_CONNECTION_PLUGIN_TYPE; // plugin type

    private contentDiv: HTMLDivElement = null;
    private modalPrompt: ModalWindow = null;
    private promptServerURLDiv: HTMLDivElement = null;
    private promptServerURLInput: HTMLInputElement = null;
    private logoDiv: HTMLDivElement = null;
    private checkedServerURL: string = null;
    private checkedServerURLTimeout: any = null;
    private static CHECK_DELAY: number = 1000;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: ServerConnectionPluginUIConfig) {
        super(deepCopy(defaultServerConnectionPluginUIConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): ServerConnectionPluginUIConfig {
        return super.getConfig() as ServerConnectionPluginUIConfig;
    }

    /**
     * Launch a request to the given URL to check it's reachability
     * @param url
     */
    public checkURL(url: string, delay: number): void {
        if (this.checkedServerURLTimeout != null) {
            clearTimeout(this.checkedServerURLTimeout);
            this.checkedServerURLTimeout = null;
        }
        this.checkedServerURL = url;
        if (!this.checkedServerURL) {
            this.getModalPrompt().setMessage('error', 'No URL');
            return;
        }
        this.checkedServerURLTimeout = setTimeout(() => this.launchCheckURL(), delay);
    }

    /**
     * fetch stored URL
     */
    private launchCheckURL(): void {
        if (!this.checkedServerURL) {
            this.getModalPrompt().setMessage('error', 'No URL');
            return;
        }
        this.getModalPrompt().setMessage('info', 'Check URL in progress');
        fetch(this.checkedServerURL + '/info')
            .then((response: Response) => {
                if (!response.ok) {
                    this.getModalPrompt().setMessage('error', 'Invalid response code ' + response.statusText);
                    return null;
                }
                return response.json();
            })
            .then((json: any) => {
                this.getModalPrompt().setMessage('ok', JSON.stringify(json, null, 2));
            })
            .catch((reason: Error) => {
                this.getModalPrompt().setMessage(
                    'error',
                    'something wrong happens retrieving server information: \n' + reason.message
                );
            });
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.SERVER_DISCONNECTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.getModalPrompt().addMessage('warn', 'Server disconnected');
                this.updateUI();
                break;
            case TpzApplicationEventType.SERVER_CONNECTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.getModalPrompt().addMessage('ok', 'Server connected');
                this.updateUI();
                break;
            case TpzApplicationEventType.WEBSOCKET_CONNECTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.getModalPrompt().addMessage('ok', 'Websocket connected');
                this.updateUI();
                break;
            case TpzApplicationEventType.WEBSOCKET_DISCONNECTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.getModalPrompt().addMessage('warn', 'Websocket disconnected');
                this.updateUI();
                break;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Action to perform when plugin plugs in
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then(() => {
            this.insertDiv();
            return this;
        });
    }

    /**
     * Action to perform when plugin unplugs
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onUnplug().then(() => {
            this.removeDiv();
            return this;
        });
    }

    /**
     * Return menu parent or body if not defined
     */
    public getParent(): HTMLElement {
        if (this.getConfig().parentId) {
            const parent: HTMLElement = document.getElementById(this.getConfig().parentId);
            if (!parent) {
                this.getLogger()?.error(
                    'Menu plugin ' + this.getId() + ' cannot be added to inexisting parent ' + this.getConfig().parentId
                );
                return null;
            }
            return parent;
        }
        return document.body;
    }

    /**
     * Insert Menu in DOM
     */
    private insertDiv(): void {
        this.getParent()?.appendChild(this.getContentDiv());
    }

    /**
     * Remove Menu from DOM
     */
    private removeDiv(): void {
        if (this.contentDiv) this.getParent()?.removeChild(this.contentDiv);
    }

    /**
     * div content lazy getter
     */
    public getContentDiv(): HTMLDivElement {
        if (!this.contentDiv) {
            this.contentDiv = document.createElement('div');
            this.contentDiv.innerHTML = '';
            this.contentDiv.classList.add('server-connection');
            this.contentDiv.addEventListener('click', () => this.getModalPrompt().open());
            this.contentDiv.appendChild(this.getLogoDiv());
        }
        return this.contentDiv;
    }

    /**
     * logo div lazy getter (contained in contentDiv)
     */
    public getLogoDiv(): HTMLDivElement {
        if (!this.logoDiv) {
            this.logoDiv = document.createElement('div');
            this.logoDiv.id = this.getConfig().divId;
        }
        return this.logoDiv;
    }

    /**
     * Modal window lazy getter
     * @returns
     */
    public getModalPrompt(): ModalWindow {
        if (!this.modalPrompt) {
            this.modalPrompt = new ModalWindow('Backend URL', 'backend-url-modal-window-prompt-title', [
                { id: 'connect', text: 'Connect', i18n: 'connect-i18n' },
                { id: 'disconnect', text: 'Disconnect', i18n: 'disconnect-i18n' },
                { id: 'test', text: 'Test', i18n: 'test-connect-i18n' },
                { id: 'close', text: 'Close', i18n: 'close-connect-i18n' }
            ]);
            this.modalPrompt.onOpen = (win: ModalWindow) => {
                win.getTopDiv().appendChild(this.getPromptServerURLUI());
            };
            this.modalPrompt.onClickButton = (win: ModalWindow, buttonId: string) => {
                if (buttonId == 'disconnect') {
                    this.getApplication().disconnectFromServer();
                }
                if (buttonId == 'connect') {
                    this.getApplication().setBackendURL(this.getPromptServerURLInput().value);
                    this.getApplication()
                        .connectToServer()
                        .then((session: TpzClientSession) => {
                            this.modalPrompt.setMessage(
                                'ok',
                                'Client session ' + session.getSessionId() + ' connected'
                            );
                            this.modalPrompt.addMessage('info', 'Server URL = ' + session.getServerURL());
                            this.modalPrompt.addMessage(
                                'info',
                                'Session info = ' + JSON.stringify(session.getServerInformation(), null, 2)
                            );
                        })
                        .catch((reason: Error) => {
                            this.modalPrompt.addMessage('error', 'Connection Error: ' + reason.message);
                        });
                    return false;
                }
                if (buttonId == 'test') {
                    // check url
                    this.checkURL(this.getPromptServerURLInput().value, 0);
                    // do not close window (return false)
                    return false;
                }
                if (buttonId == 'close') {
                    // cancel: close window (return true)
                    return true;
                }
                // do not close window (return false)
                return false;
            };
        }
        return this.modalPrompt;
    }

    /**
     * UI asking server url
     */
    private getPromptServerURLUI(): HTMLDivElement {
        if (!this.promptServerURLDiv) {
            this.promptServerURLDiv = TpzApplicationUI.createDiv();
            this.promptServerURLDiv.appendChild(TpzApplicationUI.createLabel({ label: 'Server URL' }));
            this.promptServerURLDiv.appendChild(this.getPromptServerURLInput());
        }
        return this.promptServerURLDiv;
    }

    /**
     * UI asking server url
     */
    private getPromptServerURLInput(): HTMLInputElement {
        if (!this.promptServerURLInput) {
            this.promptServerURLInput = TpzApplicationUI.createTextInput({
                value: this.getApplication().getConfig().backendURL
            });
            this.promptServerURLInput.addEventListener('click', (evt: MouseEvent) => evt.preventDefault());
            this.promptServerURLInput.addEventListener('change', (evt: Event) =>
                this.checkURL(this.getPromptServerURLInput().value, ServerConnectionPluginUI.CHECK_DELAY)
            );
        }
        return this.promptServerURLInput;
    }

    /**
     * Update the plugin UI in order to reflect connexion state
     */
    public updateUI(): void {
        const session: TpzClientSession = this.getApplication().getSessionManager().getCurrentSession();
        if (session?.isWebSocketConnected()) {
            this.getContentDiv().classList.add(this.getConfig().connectedClass);
            if (this.modalPrompt) {
                this.modalPrompt.getButton('connect')?.setAttribute('disabled', '');
                this.modalPrompt.getButton('disconnect')?.removeAttribute('disabled');
            }
        } else {
            this.getContentDiv().classList.remove(this.getConfig().connectedClass);
            if (this.modalPrompt) {
                this.modalPrompt.getButton('disconnect')?.setAttribute('disabled', '');
                this.modalPrompt.getButton('connect')?.removeAttribute('disabled');
            }
        }
    }
}
