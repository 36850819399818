/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/** JsPanel specific Event types */
export class TpzWindowEventTypes {
    public static readonly WINDOW_CREATED = 'WINDOW CREATED';
    public static readonly WINDOW_CLOSED = 'WINDOW CLOSED';
    public static readonly WINDOW_MINIMIZED = 'WINDOW MINIMIZED';
    public static readonly WINDOW_MAXIMIZED = 'WINDOW MAXIMIZED';
    public static readonly WINDOW_DISPLAYED = 'WINDOW DISPLAYED';
    public static readonly WINDOW_ICONIFIED = 'WINDOW ICONIFIED';
    public static readonly WINDOW_NORMALIZED = 'WINDOW NORMALIZED';
    public static readonly WINDOW_SMALLIFIED = 'WINDOW SMALLIFIED';
}
