/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoadHelper } from '../tools/loader';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationComponent } from '../tpz-application-component';
import { TpzApplicationEventCategory, TpzApplicationEventType } from '../tpz-application-event';
import { TpzLibraryDescriptor } from './library-descriptor';

/**
 * Library Manager configuration
 */
export interface LibraryManagerConfig {
    librariesServerURLs?: string[];
}

/**
 * Default configuration for library manager
 */
export const defaultLibraryManagerConfig: LibraryManagerConfig = {
    librariesServerURLs: []
};

/**
 * Library Manager. It manages libraries loading.
 * Use registerLibrary() and getLibrary() to load a new library.
 * It can connect to a library manager server
 */
export class LibraryManager extends TpzApplicationComponent {
    private readonly availableLibDescriptors: { [id: string]: TpzLibraryDescriptor } = {};
    private readonly loaders: { [id: string]: Promise<void> } = {};
    private readonly config: LibraryManagerConfig = defaultLibraryManagerConfig;

    /**
     * Constructor
     */
    constructor(app: TpzApplication, id: string) {
        super(app, id);
        this.config = defaultLibraryManagerConfig;
    }

    /**
     * library manager configuration getter
     */
    public getConfig(): LibraryManagerConfig {
        return this.config;
    }

    /**
     * Add a new Server into library manager configuration
     * @param librariesServerURL
     */
    public registerServer(librariesServerURL: string): void {
        if (!this.getConfig().librariesServerURLs) this.getConfig().librariesServerURLs = [];
        if (!this.getConfig().librariesServerURLs.includes(librariesServerURL)) {
            this.getConfig().librariesServerURLs.push(librariesServerURL);
            this.getApplication().fireEvent(
                this.getId(),
                TpzApplicationEventType.LIBRARY_SERVER_REGISTERED,
                { libraryServerURL: librariesServerURL },
                [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
            );
        }
    }

    /**
     * get registered servers (from library manager configuration)
     * @return an array of librariesServer URL
     */
    public getRegisteredServers(): string[] {
        return this.getConfig().librariesServerURLs;
    }

    /**
     * read a server
     * @param serverURL
     */
    public fetchServerLibraryDescriptors(url: string): Promise<TpzLibraryDescriptor[]> {
        if (!url) return Promise.resolve([]);
        return this.getApplication()
            .fetchTopazServer(url)
            .then((response: Response) => {
                if (!response.ok) {
                    throw new Error('Unable to fetch libraries server ' + url + ': status = ' + response.statusText);
                }
                return response.json();
            })
            .then((jsonResponse: any) => {
                const libDescriptors: TpzLibraryDescriptor[] = jsonResponse;
                return libDescriptors;
            });
    }

    /**
     * fetch given server url and register available libraries
     * @param url server URL to fetch
     */
    private registerServerLibraries(url: string): Promise<void> {
        return this.fetchServerLibraryDescriptors(url).then((libDescriptors: TpzLibraryDescriptor[]) => {
            libDescriptors.forEach((libDescriptor: TpzLibraryDescriptor) => {
                this.registerLibrary(libDescriptor);
            });
        });
    }

    /**
     * fetch all servers registered in config.libraryServerURLs and register available libraries
     */
    private registerAllServersLibraries(): Promise<void> {
        const promises: Promise<void>[] = [];
        this.getConfig().librariesServerURLs?.forEach((url) => {
            promises.push(
                this.registerServerLibraries(url).catch((reason: any) => {
                    this.getLogger().error('An error occurred fetching libraries server ' + url);
                })
            );
        });
        return Promise.all(promises).then(() => null);
    }
    /**
     * Register a library into this manager. Library is not loaded until a getLibrary() is called
     */
    public registerLibrary(libDescriptor: TpzLibraryDescriptor): boolean {
        if (!libDescriptor) {
            this.getLogger()?.error('Library registration has no configuration');
            return false;
        }
        if (!libDescriptor.id) {
            this.getLogger()?.error('Invalid ID in library registration. config = ' + JSON.stringify(libDescriptor));
            return false;
        }
        // if libraryURL is not set, use the local document URL
        if (!libDescriptor.libraryURL) {
            libDescriptor.libraryURL = document.location.origin + document.location.pathname;
            const indexIndex: number = libDescriptor.libraryURL.indexOf('index.html');
            if (indexIndex >= 0) libDescriptor.libraryURL = libDescriptor.libraryURL.substring(0, indexIndex);
        }
        this.getLogger().debug('Registering Library descriptor #' + libDescriptor.id);
        this.availableLibDescriptors[libDescriptor.id] = libDescriptor;
        // (re)initialize loader
        this.loaders[libDescriptor.id] = null;
        this.getApplication().fireEvent(
            this.getId(),
            TpzApplicationEventType.LIBRARY_REGISTERED,
            { libraryId: libDescriptor.id },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
        return true;
    }

    /**
     * Get the given library, load it if not already done. Internally calls loadLibrary()
     * If the library is already loaded: returns immediately with a resolved promise
     * If the library loading is in progress: returns with the existing loading promise
     * @param id library id to be loaded
     */
    public getLibrary(id: string): Promise<void> {
        if (!id) {
            this.getLogger()?.error('Invalid ID in library getter');
            return Promise.reject(null);
        }
        // try to retrieve previous loader
        let loader: Promise<any> = this.loaders[id];
        if (loader) return loader;
        // create a new loader
        loader = this.loadLibraryById(id).catch((reason: Error) => {
            // do not store rejected promise
            delete this.loaders[id];
            // rethrow exception not to be lost
            throw reason;
        });
        this.loaders[id] = loader;
        return loader;
    }

    /**
     * Starts component
     * It fetches registered servers
     */
    public start(): Promise<void> {
        return super.start().then(() => {
            this.registerAllServersLibraries();
        });
    }

    /**
     * Stop component
     */
    public stop(): Promise<void> {
        return super.stop();
    }

    /**
     * Get the given library configuration if registered
     * @param id library id to be retrieved
     */
    public getLibraryDescriptor(id: string): TpzLibraryDescriptor {
        return this.availableLibDescriptors[id];
    }

    /**
     * Get the all available library keys
     */
    public getAvailableLibraryKeys(): string[] {
        if (!this.availableLibDescriptors) return [];
        return Object.keys(this.availableLibDescriptors);
    }

    /**
     * Load one library by loading all script files. No check is done if loading is still done or in progress
     */
    private loadLibraryById(id: string): Promise<void> {
        if (!id) {
            this.getLogger()?.error('Invalid ID in library getter');
            return Promise.reject(new Error('Invalid ID in library getter'));
        }
        const config: TpzLibraryDescriptor = this.availableLibDescriptors[id];
        if (!config) {
            this.getLogger()?.error(
                'Registered libraries: [' + Object.keys(this.availableLibDescriptors).join(', ') + ']'
            );
            this.getLogger()?.error('Library ID ' + id + ' is not registered and cannot be retrieved.');
            return Promise.reject(new Error('Library ID ' + id + ' is not registered and cannot be retrieved.'));
        }

        // load javascript (config resources key="js")
        // load style sheets (config resources key="css")
        if (!config?.resources) {
            this.getLogger().error('library #' + id + ' has no resources ! config = ' + JSON.stringify(config));
            return Promise.resolve();
        }
        this.getLogger().debug('load scripts from library #' + id);
        const loadPromises: Promise<void>[] = [];
        //load js resources
        if (config.resources['js']) {
            const absoluteURLs: string[] = config.resources['js'].map((url) =>
                this.getAbsoluteURLResource(url, config)
            );
            loadPromises.push(
                LoadHelper.loadJsScripts(absoluteURLs, this.getApplication().getLogger()).finally(() => {
                    //
                })
            );
        }
        // load css resources
        if (config.resources['css']) {
            const absoluteURLs: string[] = config.resources['css'].map((url) =>
                this.getAbsoluteURLResource(url, config)
            );
            loadPromises.push(LoadHelper.loadCSSs(absoluteURLs, this.getApplication().getLogger()));
        }

        const progressLoaderLibrariesId: string = this.getApplication()
            .getProgressLoaderManager()
            ?.addProgress(null, `load library ${id}`);
        return Promise.all(loadPromises)
            .then(() => null)
            .finally(() => {
                this.getApplication().getProgressLoaderManager().endProgress(progressLoaderLibrariesId);
                this.getApplication().sendNotification('INFO', `${id} library loaded`);
            });
    }

    /**
     * convert library resource URL to absolute path
     * @param url given url (relative or absolute)
     * @param config library config
     * @return absolute URL
     */
    public getAbsoluteURLResource(url: string, config: TpzLibraryDescriptor): any {
        if (!url) return null;
        if (!config) return url;
        if (url.startsWith('http')) return url;
        return `${config.libraryURL}/${url}`;
    }
}
