/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../../tpz-log/tpz-log-core';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventType, TpzApplicationEventCategory } from '../tpz-application-event';

/**
 * Extension Descriptor reflects the JSON object received from the server in order to instanciate it
 */
export interface TpzAddOnDescriptor {
    id: string;
    version: string;
    lang: string;
    name: { [lang: string]: string }; // Map lang => Name
    nameI18N?: string; // name internationalization identifier
    description?: { [lang: string]: string }; // Map lang => description
    descriptionI18N?: string; // description internationalization identifier
    thumbnail?: string; // refernce to a thumbnail file or base64 image
    libraryName: string;
    className: string;
    script?: string;
    scriptURL?: string;
    addOnURL: string; // this field is not part of the descriptor, it is filled with the descriptor URL at creation (loading time)
}

/**
 * An Add-On is a small piece of code (javascript) which is send from the server to the client
 * in order to enrich a TpzApplication with new features
 */
export abstract class TpzAddOn {
    public static TPZ_ADDON_FILENAME: string = 'tpz-addon.json';
    private descriptor: TpzAddOnDescriptor = null;
    private application: TpzApplication = null;

    /**
     * Pseudo Unique ID generator
     */
    public static uuidv4(): string {
        // start with a letter because CSS3 query selector cannot handle IDs beginning with a number
        return 'axxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    /**
     * AddOn default constructor
     */
    constructor(descriptor: TpzAddOnDescriptor) {
        this.setDescriptor(descriptor);
    }

    /**
     * @returns the extension identifier
     */
    public getId(): string {
        return this.descriptor.id;
    }

    /**
     * Descriptor describes the Extension State and configuration
     * @returns the extension descriptor
     */
    public getDescriptor(): TpzAddOnDescriptor {
        return this.descriptor;
    }

    /**
     * set the Extension Descriptor (State and configuration)
     */
    private setDescriptor(descriptor: TpzAddOnDescriptor): void {
        this.descriptor = descriptor;
    }

    /**
     * Get application. Null if addon is not started
     * @returns
     */
    protected getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * Get Application Logger. Delegated to Application
     */
    public getLogger(): Logger {
        return this.getApplication()?.getLogger();
    }

    /**
     * Fire an event to the application
     * @param type event type
     * @param content event content
     * @param categories event categories (to avoid propagating event add TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY)
     */
    public fireEvent(type: string, content: any, categories: string[]) {
        this.getApplication()?.fireEvent(this.getId(), type, content, categories);
    }

    /**
     * method launching the Add-On
     * @param app ToPaZ application in which Add-On is plugged
     */
    public start(app: TpzApplication): Promise<void> {
        if (!app) throw new Error('An AddOn must be started within a valid application');
        // set application
        this.application = app;
        // register into application event manager
        app.getEventManager().register(this.getId(), (event: TpzApplicationEvent) => this.onApplicationEvent(event));
        return this.onStart()
            .then(() => {
                this.fireEvent(TpzApplicationEventType.ADDON_STARTED, { addOnId: this.getId() }, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
                this.getApplication().sendNotification('INFO', 'add-on ' + this.getId() + ' started');
            })
            .catch((reason: any) => {
                this.getApplication()
                    .getLogger()
                    .error('An error occurred starting add-on #' + this.getId(), reason);
                this.fireEvent(TpzApplicationEventType.ADDON_START_ERROR, { addOnId: this.getId() }, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
                this.getApplication().sendNotification('ERROR', 'add-on ' + this.getId() + ' error');
            });
    }

    /** User method called during start() method */
    public onStart(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Method called when stopping Add-On
     */
    public stop(): Promise<void> {
        return this.onStop()
            .then(() => {
                this.fireEvent(TpzApplicationEventType.ADDON_STOPPED, { addOnId: this.getId() }, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
            })
            .finally(() => {
                // unregister add-on
                this.getApplication().getEventManager().unregister(this.getId());
                this.application = null;
            })
            .catch((reason: any) => {
                this.getApplication()
                    .getLogger()
                    .error('An error occurred stopping add-on #' + this.getId(), reason);
                this.fireEvent(TpzApplicationEventType.ADDON_STOP_ERROR, { addOnId: this.getId() }, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
            });
    }

    /** User method called during stop() method */
    public onStop(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Application event Handler
     * @param event received event
     * @returns true if event has been treated/handled
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        return false;
    }
}
