/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Simple interface used by JSChart data = ChartPoint
export interface XY {
    x: number;
    y: number;
}

export class AccessData {
    public static readonly UNKNOWN_DATATYPE = 'unknown';
    public static readonly BOOLEAN_DATATYPE = 'boolean';
    public static readonly ANY_DATATYPE = 'any';
    public static readonly STRING_DATATYPE = 'string';
    public static readonly STRING_ARRAY_DATATYPE = 'string[]';
    public static readonly NUMBER_DATATYPE = 'number';
    public static readonly NUMBER_ARRAY_DATATYPE = 'number[]';
    public static readonly NUMBER2D_DATATYPE = '[number,number]';
    public static readonly NUMBER2D_ARRAY_DATATYPE = '[number,number][]';
    public static readonly XY_DATATYPE = '{x,y}';
    public static readonly XY_ARRAY_DATATYPE = '{x,y}[]';
}
