/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application/tpz-application-core';
import { AccessorType, AccessorValueImportExport } from './tpz-access-types';
import { defaultValueAccessorConfig, ValueAccessor, ValueAccessorConfig } from './tpz-access-value';
import { AccessData, XY } from './tpz-data';
import { deepCopy } from '../tpz-application/tools/deep-copy';

/**
 * Generic conversion function from a source to a destination
 */
export type DataConverter<SrcType, DstType> = (src: SrcType) => DstType;

/** Converter accessor. output data type must be set with CSData.XXX */
export interface ConverterAccessorConfig extends ValueAccessorConfig<any> {
    outputDataType?: string;
}

// default config
export const defaultConverterAccessorConfig: ConverterAccessorConfig = {
    ...defaultValueAccessorConfig,
    outputDataType: AccessData.UNKNOWN_DATATYPE
};

/** Abstract converter class intended to be extended with convertInput method implementation */
export abstract class ConverterAccessor extends ValueAccessor<any> {
    /**
     * Constructor
     * @param config accessor configuration
     * @param type accessor type
     * @param app application in which this accessor is used
     */
    constructor(config: ConverterAccessorConfig, type: string, app: TpzApplication) {
        super(
            deepCopy(defaultConverterAccessorConfig, config),
            type,
            app,
            AccessorValueImportExport.convertFromStringDefault,
            AccessorValueImportExport.convertToStringDefault
        );
    }

    /** request update */
    public requestUpdate(force: boolean): boolean {
        if (!this.isRunning()) return false;
        // update linked accessor
        this.getAccessor().then((inputAccessor: ValueAccessor<any>) => {
            inputAccessor.requestUpdate(force);
        });
        this.updateData();
        return super.requestUpdate(force);
    }

    /** convert input accessor value and set it as current value */
    protected updateData(): void {
        this.getAccessor().then((inputAccessor: ValueAccessor<any>) => {
            this.setValue(this.convertInput(inputAccessor));
        });
    }

    /** convert accessor input to another format */
    protected abstract convertInput(inputAccessor: ValueAccessor<any>): any;

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): ConverterAccessorConfig {
        return super.getConfig() as ConverterAccessorConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: ConverterAccessorConfig = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.outputDataType !== newConfig?.outputDataType) {
            // nothing to be done
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }
}

/** Default converter configuration */
export interface DefaultConverterAccessorConfig extends ConverterAccessorConfig {
    outputDataType?: string;
    accessorId?: string; // accessor to be converted
}

/** Default types converter from accessor data type to given data type in configuration */
export class DefaultConverterAccessor extends ConverterAccessor {
    /**
     * constructor
     * @param config accessor configuration
     * @param app application using this accessor
     */
    constructor(config: DefaultConverterAccessorConfig, app: TpzApplication) {
        super(config, AccessorType.DEFAULT_CONVERTER_ACCESSOR_TYPE, app);
    }

    /** main switch which converts any known types  */
    protected convertInput(inputAccessor: ValueAccessor<any>): any {
        if (!inputAccessor) return null;
        const inputDataType: string = inputAccessor.getConfig().dataType;
        const inputValue: any = inputAccessor.getValue();
        switch (inputDataType) {
            case AccessData.STRING_DATATYPE:
                return this.convertString(inputValue as string);
            case AccessData.STRING_ARRAY_DATATYPE:
                return this.convertStringArray(inputValue as string[]);
            case AccessData.NUMBER_DATATYPE:
                return this.convertNumber(inputValue as number);
            case AccessData.NUMBER_ARRAY_DATATYPE:
                return this.convertNumberArray(inputValue as number[]);
            case AccessData.NUMBER2D_DATATYPE:
                return this.convertNumber2D(inputValue as [number, number]);
            case AccessData.NUMBER2D_ARRAY_DATATYPE:
                return this.convertNumber2DArray(inputValue as [number, number][]);
            case AccessData.XY_DATATYPE:
                return this.convertXY(inputValue as XY);
            case AccessData.XY_ARRAY_DATATYPE:
                return this.convertXYArray(inputValue as XY[]);
            case AccessData.ANY_DATATYPE:
                return this.convertAny(inputValue as any);
        }
        return null;
    }

    /** Convert input type: string to any known type */
    private convertString(value: string): any {
        const outputDataType: string = this.getConfig().outputDataType;
        switch (outputDataType) {
            case AccessData.STRING_DATATYPE:
                return value;
            case AccessData.STRING_ARRAY_DATATYPE:
                return [value];
            case AccessData.NUMBER_DATATYPE:
                return Number(value);
            case AccessData.NUMBER_ARRAY_DATATYPE:
                return [Number(value)];
            case AccessData.NUMBER2D_DATATYPE:
                return [0, Number(value)];
            case AccessData.NUMBER2D_ARRAY_DATATYPE:
                return [[0, Number(value)]];
            case AccessData.XY_DATATYPE:
                return { x: 0, y: Number(value) };
            case AccessData.XY_ARRAY_DATATYPE:
                return [{ x: 0, y: Number(value) }];
            case AccessData.ANY_DATATYPE:
                return value;
        }
        return null;
    }

    /** Convert input type: string-array to any known type */
    private convertStringArray(value: string[]): any {
        const outputDataType: string = this.getConfig().outputDataType;
        switch (outputDataType) {
            case AccessData.STRING_DATATYPE:
                return value.join(',');
            case AccessData.STRING_ARRAY_DATATYPE:
                return value;
            case AccessData.NUMBER_DATATYPE:
                return Number(value[0]);
            case AccessData.NUMBER_ARRAY_DATATYPE:
                return value.map((s) => Number(s));
            case AccessData.NUMBER2D_DATATYPE:
                return [Number(value[0]), Number(value[1])];
            case AccessData.NUMBER2D_ARRAY_DATATYPE:
                return value.map((s) => [0, Number(s)]);
            case AccessData.XY_DATATYPE:
                return { x: Number(value[0]), y: Number(value[1]) };
            case AccessData.XY_ARRAY_DATATYPE:
                return value.map(() => {
                    return { x: Number(value[0]), y: Number(value[1]) };
                });
            case AccessData.ANY_DATATYPE:
                return value;
        }
        return null;
    }

    /** Convert input type: number to any known type */
    private convertNumber(value: number): any {
        const outputDataType: string = this.getConfig().outputDataType;
        switch (outputDataType) {
            case AccessData.STRING_DATATYPE:
                return String(value);
            case AccessData.STRING_ARRAY_DATATYPE:
                return [String(value)];
            case AccessData.NUMBER_DATATYPE:
                return value;
            case AccessData.NUMBER_ARRAY_DATATYPE:
                return [value];
            case AccessData.NUMBER2D_DATATYPE:
                return [0, value];
            case AccessData.NUMBER2D_ARRAY_DATATYPE:
                return [[0, value]];
            case AccessData.XY_DATATYPE:
                return { x: 0, y: String(value) };
            case AccessData.XY_ARRAY_DATATYPE:
                return [{ x: 0, y: String(value) }];
            case AccessData.ANY_DATATYPE:
                return value;
        }
        return null;
    }

    /** Convert input type: number-array to any known type */
    private convertNumberArray(value: number[]): any {
        const outputDataType: string = this.getConfig().outputDataType;
        switch (outputDataType) {
            case AccessData.STRING_DATATYPE:
                return value.map((v) => Number(v)).join(',');
            case AccessData.STRING_ARRAY_DATATYPE:
                return value.map((v) => Number(v));
            case AccessData.NUMBER_DATATYPE:
                return value[0];
            case AccessData.NUMBER_ARRAY_DATATYPE:
                return value;
            case AccessData.NUMBER2D_DATATYPE:
                return [value[0], value[1]];
            case AccessData.NUMBER2D_ARRAY_DATATYPE:
                return value.map((v) => [0, v]);
            case AccessData.XY_DATATYPE:
                return { x: value[0], y: value[1] };
            case AccessData.XY_ARRAY_DATATYPE:
                return value.map((v) => {
                    return { x: 0, y: v };
                });
            case AccessData.ANY_DATATYPE:
                return value;
        }
        return null;
    }

    /** Convert input type: [number, number] to any known type */
    private convertNumber2D(value: [number, number]): any {
        const outputDataType: string = this.getConfig().outputDataType;
        switch (outputDataType) {
            case AccessData.STRING_DATATYPE:
                return `[ ${value[0]},${value[1]} ]`;
            case AccessData.STRING_ARRAY_DATATYPE:
                return [String(value[0]), String(value[1])];
            case AccessData.NUMBER_DATATYPE:
                return value[0];
            case AccessData.NUMBER_ARRAY_DATATYPE:
                return [value[0], value[1]];
            case AccessData.NUMBER2D_DATATYPE:
                return value;
            case AccessData.NUMBER2D_ARRAY_DATATYPE:
                return [value];
            case AccessData.XY_DATATYPE:
                return { x: value[0], y: value[1] };
            case AccessData.XY_ARRAY_DATATYPE:
                return [{ x: value[0], y: value[1] }];
            case AccessData.ANY_DATATYPE:
                return value;
        }
        return null;
    }

    /** Convert input type: [number, number]-array to any known type */
    private convertNumber2DArray(value: [number, number][]): any {
        const outputDataType: string = this.getConfig().outputDataType;
        switch (outputDataType) {
            case AccessData.STRING_DATATYPE:
                return value.map((v) => `[ ${v[0]},${v[1]} ]`).join(',');
            case AccessData.STRING_ARRAY_DATATYPE:
                return value.map((v) => '[' + String(v[0]) + ',' + String(v[1]) + ']');
            case AccessData.NUMBER_DATATYPE:
                return [value[0]];
            case AccessData.NUMBER_ARRAY_DATATYPE:
                return value.map((v) => v[0]);
            case AccessData.NUMBER2D_DATATYPE:
                return value[0];
            case AccessData.NUMBER2D_ARRAY_DATATYPE:
                return value;
            case AccessData.XY_DATATYPE:
                return { x: value[0][0], y: value[0][1] };
            case AccessData.XY_ARRAY_DATATYPE:
                return value.map((v) => {
                    return { x: v[0], y: v[1] };
                });
            case AccessData.ANY_DATATYPE:
                return value;
        }
        return null;
    }

    private convertXY(value: XY): any {
        return this.convertNumber2D([value.x, value.y]);
    }

    private convertXYArray(value: XY[]): any {
        return this.convertNumber2DArray(value.map((v) => [v.x, v.y] as [number, number]));
    }

    private convertAny(value: any): any {
        return value;
    }

    /**
     * getConfig getter specialization
     */
    public getConfig(): DefaultConverterAccessorConfig {
        return super.getConfig() as DefaultConverterAccessorConfig;
    }
}
