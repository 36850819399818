/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzPlugin, TpzPluginConfig, defaultTpzPluginConfig } from '../../tpz-plugin-core';
import { MenuToolbar } from './plugin-menu-toolbar';
import { deepCopy } from '../../../tools/deep-copy';

const MENU_PLUGIN_TYPE: string = 'MenuPluginType';

/**
 *  Plugin configuration
 */
export interface MenuPluginConfig extends TpzPluginConfig {
    css?: string[]; // text elements to display
    menuId?: string; // div container id
    menuContainerClass?: string; // menu container class
    parentId?: string; // parent id in which menu is inserted
}

export const defaultMenuPluginConfig: MenuPluginConfig = {
    ...defaultTpzPluginConfig,
    id: 'Menu',
    type: MENU_PLUGIN_TYPE,
    menuId: 'TopMenu',
    css: ['css/menu.css'],
    menuContainerClass: 'menu'
};

/**
 * Abstract class for menu plugins
 */
export abstract class MenuPlugin extends TpzPlugin {
    public static readonly MENU_PLUGIN_TYPE: string = MENU_PLUGIN_TYPE; // plugin type

    private mainContainer: HTMLDivElement = null;
    private menuContainer: HTMLDivElement = null;
    private toolbar: MenuToolbar = null;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: MenuPluginConfig) {
        super(deepCopy(defaultMenuPluginConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): MenuPluginConfig {
        return super.getConfig() as MenuPluginConfig;
    }

    /**
     * action to perform when plugin is added
     */
    public onPlug(): Promise<TpzPlugin> {
        this.getApplication().getEventManager().register(this.getId(), this.onApplicationEvent.bind(this));
        this.insertMenuContainer();
        return super.onPlug();
    }

    /**
     * action to perform when plugin is removed
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onUnplug().then(() => {
            this.removeMenuContainer();
            this.getApplication().getEventManager().unregister(this.getId());
            return this;
        });
    }

    /** Toolbar getter */
    public getMenuToolbar(): MenuToolbar {
        if (!this.toolbar) {
            this.toolbar = new MenuToolbar();
        }
        return this.toolbar;
    }

    /**
     * Return menu parent or body if not defined
     */
    public getParent(): HTMLElement {
        if (this.getConfig().parentId) {
            const parent: HTMLElement = document.getElementById(this.getConfig().parentId);
            if (!parent) {
                this.getLogger()?.error(
                    'Menu plugin ' +
                        this.getId() +
                        ' cannot be connected to inexisting parent ' +
                        this.getConfig().parentId
                );
                return null;
            }
            return parent;
        }
        return document.body;
    }

    /**
     * Insert Menu in DOM
     */
    private insertMenuContainer(): void {
        this.getParent()?.appendChild(this.getMainContainer());
    }

    /**
     * Remove Menu from DOM
     */
    private removeMenuContainer(): void {
        if (this.mainContainer) this.getParent()?.removeChild(this.mainContainer);
    }

    /**
     * main container lazy getter
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.id = this.getConfig().menuId;
            this.mainContainer.classList.add(this.getConfig().menuContainerClass);
            this.mainContainer.appendChild(this.getMenuContainer());
            this.mainContainer.appendChild(this.getMenuToolbar().getUI());
        }
        return this.mainContainer;
    }

    /**
     * menu container lazy getter
     */
    public getMenuContainer(): HTMLDivElement {
        if (!this.menuContainer) {
            this.menuContainer = document.createElement('div');
            this.menuContainer.id = this.getConfig().menuId;
            this.menuContainer.classList.add(this.getConfig().menuContainerClass);
            this.createMenuContent();
        }
        return this.menuContainer;
    }

    /**
     * Action to perform when an application event is received
     * @param event received application event
     * @returns true if the event has been treated
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.update();
                break;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * update Menu by creating all menus elements (UL & LI)
     */
    public update(): Promise<void> {
        return super.update().then(() => {
            this.createMenuContent();
        });
    }
    /**
     * Method to overload creating the menu content. This method is called in menu container creation
     * Use this.getMenuContainer().appendChild() method to insert elements in menu
     */
    public abstract createMenuContent(): void;
}
