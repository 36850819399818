/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { UserLoginPlugin } from './plugin-user-login';
import { TpzPlugin, TpzPluginConfig, defaultTpzPluginConfig } from '../../tpz-plugin-core';
import {
    TpzApplicationEvent,
    TpzApplicationEventCategory,
    TpzApplicationEventType
} from '../../../tpz-application-event';
import { UserLoginPluginEventType } from './user-login-event';
import { TpzApplication } from '../../../tpz-application-core';
import { SimpleMenuPlugin } from '../menu/plugin-simple-menu';
import { TpzMenuElement } from '../../../menu/tpz-menu';
import { SimpleMenuHelper } from '../menu/plugin-simple-menu-helper';
import { deepCopy } from '../../../tools/deep-copy';

const USER_MENU_UI_PLUGIN_TYPE: string = 'UserMenuPluginUIType';

/**
 * Connection UI configuration
 */
export interface UserMenuPluginUIConfig extends TpzPluginConfig {
    menuPluginId?: string; // menu id in which a menu item should be added
}

/**
 * default values Connection UI configuration
 */
export const defaultUserMenuPluginUIConfig: UserMenuPluginUIConfig = {
    ...defaultTpzPluginConfig,
    type: USER_MENU_UI_PLUGIN_TYPE,
    menuPluginId: null
};

/**
 * Connection UI. It is linked to an observed UserLoginPlugin
 * It adds a button in a parent to connect/disconnect to server
 * A tooltip gives the client informations
 * This panel switches between two differents div: statusPanel and authFormPanel
 */
export class UserMenuPluginUI extends TpzPlugin {
    public static readonly USER_MENU_UI_PLUGIN_TYPE: string = USER_MENU_UI_PLUGIN_TYPE; // plugin type
    private menuPlugin: SimpleMenuPlugin = null;
    private lastApplicationStateId: string = null;

    /**
     * Constructor
     * @param config Connection UI configuration
     */
    constructor(config?: UserMenuPluginUIConfig | TpzApplication) {
        super(deepCopy(defaultUserMenuPluginUIConfig, config));
    }

    /**
     * get user menu id
     */
    public getUserMenuId(): string {
        return this.getId() + '-menu';
    }

    /**
     * Config getter specialization
     */
    public getConfig(): UserMenuPluginUIConfig {
        return super.getConfig() as UserMenuPluginUIConfig;
    }

    /**
     * basic user id getter.
     * @returns user id
     */
    public getUserId(): string {
        return this.getApplication().getVariable(UserLoginPlugin.USER_ID_VARIABLE);
    }

    /**
     * basic user name getter.
     * @returns user name
     */
    public getUserName(): string {
        return this.getApplication().getVariable(UserLoginPlugin.USER_NAME_VARIABLE);
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source == this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.updateUI();
                break;
            case UserLoginPluginEventType.USER_CONNECTED_EVENT:
            case UserLoginPluginEventType.USER_DISCONNECTED_EVENT:
            case UserLoginPluginEventType.USER_DETAILS_EVENT:
                TpzApplicationEventType.checkEvent(event, null);
                this.updateUI();
                break;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * get the associated MenuPlugin
     * @return the plugin instance or null if non existant
     */
    public getMenuPlugin(): SimpleMenuPlugin {
        if (!this.menuPlugin) {
            const item: TpzPlugin = this.getApplication()
                .getPluginManager()
                .getPluginById(this.getConfig().menuPluginId);
            if (!item) {
                this.getLogger()?.warn(
                    'user menu Plugin UI #' +
                        this.getId() +
                        ' cannot retrieves associated Menu plugin #' +
                        this.getConfig().menuPluginId
                );
                return null;
            }
            // TODO: Check if plugin is of type Simple menu Plugin
            this.menuPlugin = item as SimpleMenuPlugin;
        }
        return this.menuPlugin;
    }

    /**
     * Update UI content
     * @returns
     */
    public update(): Promise<void> {
        return super.update().then(() => {
            this.updateUI();
        });
    }

    /**
     *
     * @returns
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then((plugin: TpzPlugin) => {
            this.getLogger().info('plugin user-login-ui has been plugged');
            this.updateUI();
            return plugin;
        });
    }

    /**
     * add or replace the user menu item for a connected user
     */
    private addOrReplaceUserMenu(): void {
        const menuPlugin: SimpleMenuPlugin = this.getMenuPlugin();
        if (!menuPlugin) {
            throw new Error(
                'user menu Plugin UI #' +
                    this.getId() +
                    ' cannot retrieves associated Menu plugin #' +
                    this.getConfig().menuPluginId
            );
        }
        const userMenuElement: TpzMenuElement = {
            id: this.getUserMenuId(),
            name: 'User Menu',
            i18n: this.getUserMenuId() + '-i18n',
            subElements: [
                {
                    id: this.getId() + '-disconnect',
                    name: 'disconnect',
                    action: (ev: MouseEvent) => {
                        this.requestUserDisconnect();
                    }
                },
                {
                    id: this.getId() + '-save',
                    name: 'save application state',
                    action: (ev: MouseEvent) => {
                        this.requestSaveApplicationState();
                    }
                },
                {
                    id: this.getId() + '-load',
                    name: 'load application state',
                    action: (ev: MouseEvent) => {
                        this.requestLoadApplicationState();
                    }
                }
            ]
        };
        SimpleMenuHelper.addOrReplaceMenuElementToArray(userMenuElement, menuPlugin.getRootElements());
    }

    /**
     * add or replace the user menu item for a connected user
     */
    private removeUserMenu(): void {
        const menuPlugin: SimpleMenuPlugin = this.getMenuPlugin();
        if (!menuPlugin) {
            throw new Error(
                'user menu Plugin UI #' +
                    this.getId() +
                    ' cannot retrieves associated Menu plugin #' +
                    this.getConfig().menuPluginId
            );
        }
        const userMenuElementId: string = this.getUserMenuId();
        SimpleMenuHelper.removeMenuElementByIdFromArray(userMenuElementId, menuPlugin.getRootElements());
    }

    /**
     *
     * Insert UI in the parent defined by 'parentId' in configuration
     */
    public updateUI(): void {
        // we consider that user is connected when the userid is set...
        if (!this.getUserId()) {
            this.removeUserMenu();
        } else {
            this.addOrReplaceUserMenu();
        }
        this.getMenuPlugin()?.update();
    }

    /**
     * Fires an event to request the user to disconnect
     */
    private requestUserDisconnect(): void {
        this.fireEvent(UserLoginPluginEventType.REQUEST_USER_DISCONNECT_EVENT, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * Fires an event to request saving the current application state
     */
    private requestSaveApplicationState(): void {
        this.lastApplicationStateId = 'debug-state';
        this.fireEvent(
            UserLoginPluginEventType.REQUEST_SAVE_APPLICATION_STATE_EVENT,
            { id: this.lastApplicationStateId, name: 'quick save' },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
    }

    /**
     * Fires an event to request loading a specific application state
     */
    private requestLoadApplicationState(): void {
        this.lastApplicationStateId = 'debug-state';
        if (!this.lastApplicationStateId) {
            this.getLogger().error('There is no last application state available');
            return;
        }
        this.fireEvent(
            UserLoginPluginEventType.REQUEST_LOAD_APPLICATION_STATE_EVENT,
            { id: this.lastApplicationStateId },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
    }
}
