/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ProgressLoader, ProgressLoaderConfig } from './progress-loader';
import { TpzApplication } from '../tpz-application-core';
import { Logger } from '../../tpz-log/tpz-log-core';
import {
    ProgressLoaderFactory,
    BodyProgressLoaderFactory,
    HtmlContainerProgressLoaderFactory
} from './progress-loader-factory';
import { TpzApplicationEvent } from '../tpz-application-event';
import { deepCopy } from '../tools/deep-copy';
import { uuidv4 } from '../tools/uuid';

/**
 * Progress Loader Manager configuration
 */
export interface ProgressLoaderManagerConfig {
    progressClass: string;
}

/**
 * Progress Loader default configuration
 */
export const defaultProgressManagerConfig: ProgressLoaderManagerConfig = {
    progressClass: 'progress-loader'
};

/**
 * Progress Loader Manager (Progress Loader Manager)
 */
export class ProgressLoaderManager {
    private readonly progressLoaders: { [id: string]: ProgressLoader } = {};
    private readonly config: ProgressLoaderManagerConfig = null;
    private readonly application: TpzApplication = null;
    private static readonly MAX_LINE_NUMBER: number = 5;
    private logger: Logger = null;
    private readonly defaultFactory: ProgressLoaderFactory = null;
    private factories: ProgressLoaderFactory[] = [];

    /**
     * constructor
     */
    constructor(application: TpzApplication, config: ProgressLoaderManagerConfig) {
        this.config = deepCopy(defaultProgressManagerConfig, config);
        this.application = application;
        this.factories = [new HtmlContainerProgressLoaderFactory()];
        this.defaultFactory = new BodyProgressLoaderFactory();
    }

    /**
     * Add a progress laoder factory to the list of handled factories
     */
    public registerFactory(factory: ProgressLoaderFactory): void {
        this.factories.push(factory);
    }

    /**
     * Application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * Config getter specialization
     */
    public getConfig(): ProgressLoaderManagerConfig {
        return this.config;
    }

    /**
     * set the logger to be used
     */
    public setLogger(logger: Logger): void {
        this.logger = logger;
    }

    /**
     * Logger getter
     */
    public getLogger(): Logger {
        return this.logger;
    }

    /**
     * Add a progress loader.
     * Progress Loader is indeterminated until a setProgress is set
     * @param source HTML element in which this loader will take place. If null 'document.body' is used
     * @param title text action description
     * @return the progress loader ID
     */
    public addProgress(source: any, title: string): string {
        const id: string = uuidv4();
        let loader: ProgressLoader = this.progressLoaders[id];
        if (!loader) {
            let factory: ProgressLoaderFactory = this.getFactory(source);
            if (!factory) {
                this.getLogger()?.warn(
                    `Cannot create progress loader titled ${title}. No associated factory with source type. Use default`
                );
                factory = this.getDefaultFactory();
            }
            const loaderConfig: ProgressLoaderConfig = {
                id: id,
                type: null,
                title: title,
                maxDescriptionLines: ProgressLoaderManager.MAX_LINE_NUMBER,
                progressClass: this.getConfig().progressClass
            };
            loader = factory.createProgressLoader(source, loaderConfig);
            if (!loader) {
                this.getLogger()?.error(
                    `Cannot create progress loader type ${loaderConfig.type}. Associated factory returned null`
                );
                return null;
            }
            this.progressLoaders[id] = loader;
            loader.start();
            this.getLogger().debug(`progress loader #${id} started: ${title}`);
        }
        return id;
    }

    /**
     * get the default progress loader factory
     */
    private getDefaultFactory(): ProgressLoaderFactory {
        return this.defaultFactory;
    }

    /**
     * get the default progress loader factory
     */
    private getFactory(source: any): ProgressLoaderFactory {
        // loop over all registered factories in reverse order and return the first one which is able to handle this configuration
        for (let factoryIndex = this.factories.length - 1; factoryIndex >= 0; factoryIndex--) {
            const factory: ProgressLoaderFactory = this.factories[factoryIndex];
            if (factory.handleConfig(source)) return factory;
        }
        return this.getDefaultFactory();
    }

    /**
     * clear all registered factories
     */
    public clearRegisteredFactories(): void {
        this.factories = [];
    }

    /**
     * Set a progression value. If indeterminate is true, it is automatically set to false.
     * if progression is set to >= 100. progress is automatically removed
     * @param id progress loader ID
     * @param progress progression value (between 0 & 100)
     */
    public setProgress(id: string, progress: number): void {
        if (!id) return;
        const loader: ProgressLoader = this.progressLoaders[id];
        if (!loader) return;
        loader.setProgress(progress);
    }

    /**
     * Add a description line to progress loader
     * @param id progress loader ID
     * @param description description to add
     */
    public addProgressDescription(id: string, description: string): void {
        if (!id) return;
        const loader: ProgressLoader = this.progressLoaders[id];
        if (!loader) return;
        loader.addDescription(description);
    }

    /**
     * Set the description line of progress loader
     * @param id progress loader ID
     * @param description description to be set
     */
    public setProgressDescription(id: string, description: string): void {
        if (!id) return;
        const loader: ProgressLoader = this.progressLoaders[id];
        if (!loader) return;
        loader.setDescription(description);
    }

    /**
     * remove action from progress
     * @param id progress loader ID
     */
    public endProgress(id: string): void {
        const loader: ProgressLoader = this.progressLoaders[id];
        if (!loader) {
            this.getLogger().error(`Unable to end progress loader #${id}`);
            this.getLogger().error(
                `Existing loaders: ${Object.values(this.progressLoaders)
                    .map((loader: ProgressLoader) => loader.getId())
                    .join(', ')}`
            );
            return;
        }

        loader.stop().then(() => {
            // check if loader hasn't been restarted during close time
            if (!loader.isDisplayed()) {
                this.getLogger().debug(`progress loader #${id} terminated`);
                delete this.progressLoaders[id];
            }
        });
    }

    /**
     * action to perform when plugin is removed.
     */
    public endAllProgress(): void {
        Object.keys(this.progressLoaders).forEach((loaderId: string) => this.endProgress(loaderId));
    }

    /**
     * React to application events. This method can be overloaded
     * Default behaviour is: do nothing
     */
    protected onEvent(_: TpzApplicationEvent): boolean {
        return false;
    }
}
