/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzAddOnHelper } from '../../../addons/addons-helper';
import { TpzAddOn, TpzAddOnDescriptor } from '../../../addons/addons-core';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { uuidv4 } from '../../../tools/uuid';
import { TpzApplicationCommander } from '../../../tpz-application-commander';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { deepCopy } from '../../../tools/deep-copy';
import { TpzApplicationCategories } from '../../../tpz-application-types';

//FIXME create an AddOnAccessor from RemoteAccessor
// FIXME this involves a complete refactoring of TpzAccess...

// item type (used in default configuration and Item Instance constructor)
const ADDON_SELECT_VIEW_TYPE: string = 'AddonSelectType';

/**
 * TpzAddOnSelectView configuration
 */
export interface TpzAddOnSelectViewConfig extends TpzViewConfig {
    addonRegistryURL?: string;
}

/**
 * Default HTML displayer configuration
 */
export const defaultTpzAddOnSelectViewConfig: TpzAddOnSelectViewConfig = {
    ...defaultTpzViewConfig,
    type: ADDON_SELECT_VIEW_TYPE,
    addonRegistryURL: 'http://localhost/addons/registry',

    containerClasses: ['addon-select-container'].concat(defaultTpzViewConfig.containerClasses),
    css: ['css/addons-select-view.css'].concat(defaultTpzViewConfig.css)
};

/**
 * This view presents all available addons and allows the user to select a set of addons
 */
export class TpzAddOnSelectView extends TpzView {
    public static readonly ADDON_SELECT_VIEW_TYPE = ADDON_SELECT_VIEW_TYPE;

    private static readonly ADDON_DESCRIPTOR_CLASS: string = 'addon-descriptor';
    private static readonly SELECTED_CLASS: string = 'selected';
    private static readonly DESCRIPTOR_ID_ATTRIBUTE: string = 'addon-id';

    private descriptors: { [id: string]: TpzAddOnDescriptor } = {}; // map between descriptor IDs and descriptors

    private mainContainer: HTMLDivElement = null; // Div Element containing the entire UI
    private thumbnailContainer: HTMLDivElement = null; // Div Element containing All container
    private toolbarContainer: HTMLDivElement = null; // Div Element containing All toolbar elements
    private selectAllButton: HTMLButtonElement = null;
    private updateButton: HTMLButtonElement = null;
    private deselectAllButton: HTMLButtonElement = null;
    private loadButton: HTMLButtonElement = null;

    /** Constructor */
    constructor(config: TpzAddOnSelectViewConfig, application: TpzApplication) {
        super(
            deepCopy(defaultTpzAddOnSelectViewConfig, config),
            TpzAddOnSelectView.ADDON_SELECT_VIEW_TYPE,
            application
        );
    }

    /**
     * config getter specialization
     * @returns item readonly configuration
     */
    public getConfig(): TpzAddOnSelectViewConfig {
        return super.getConfig() as TpzAddOnSelectViewConfig;
    }

    /**
     * apply changes made on userConfig to stateConfig
     * @param newConfig
     * @returns
     */
    public applyConfig(newConfig: TpzAddOnSelectViewConfig): boolean {
        if (!newConfig) return false;
        if (this.getConfig()?.addonRegistryURL !== newConfig?.addonRegistryURL) {
            this.descriptors = null;
        }
        return super.applyConfig(newConfig);
    }

    /**
     * get AddOn Selector main container
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.appendChild(this.getThumbnailContainer());
            this.mainContainer.appendChild(this.getToolbarContainer());
        }
        return this.mainContainer;
    }

    /**
     * get AddOn Thumbnail container (child of main container)
     */
    public getThumbnailContainer(): HTMLDivElement {
        if (!this.thumbnailContainer) {
            this.thumbnailContainer = document.createElement('div');
            this.thumbnailContainer.classList.add('thumbnails');
            this.generateThumbnails();
        }
        return this.thumbnailContainer;
    }

    /**
     * select all AddOns
     */
    public selectAll(): void {
        const container: HTMLDivElement = this.thumbnailContainer;
        if (!container) return;
        const addonElements: HTMLCollectionOf<HTMLElement> = container.getElementsByClassName(
            TpzAddOnSelectView.ADDON_DESCRIPTOR_CLASS
        ) as HTMLCollectionOf<HTMLElement>;
        Array.from(addonElements)?.forEach((elt: HTMLElement) => {
            elt.classList.add(TpzAddOnSelectView.SELECTED_CLASS);
        });
        this.updateSelectionCount();
    }

    /**
     * deselect all AddOns
     */
    public deselectAll(): void {
        const container: HTMLDivElement = this.thumbnailContainer;
        if (!container) return;
        const addonElements: HTMLCollectionOf<HTMLElement> = container.getElementsByClassName(
            TpzAddOnSelectView.ADDON_DESCRIPTOR_CLASS
        ) as HTMLCollectionOf<HTMLElement>;
        Array.from(addonElements)?.forEach((elt: HTMLElement) => {
            elt.classList.remove(TpzAddOnSelectView.SELECTED_CLASS);
        });
        this.updateSelectionCount();
    }

    /**
     * get selected add on IDs (elements with SELECTED_CLASS attribute )
     */
    public getSelectedAddOnIds(): string[] {
        const container: HTMLDivElement = this.thumbnailContainer;
        if (!container) return [];
        const addonElements: HTMLCollectionOf<HTMLElement> = container.getElementsByClassName(
            TpzAddOnSelectView.SELECTED_CLASS
        ) as HTMLCollectionOf<HTMLElement>;
        return Array.from(addonElements)?.map((elt: HTMLElement) =>
            elt.getAttribute(TpzAddOnSelectView.DESCRIPTOR_ID_ATTRIBUTE)
        );
    }

    /**
     * load selected AddOns
     */
    private loadSelectedAddOns(): void {
        const selectedAddOnIDs: string[] = this.getSelectedAddOnIds();
        selectedAddOnIDs?.forEach((addOnId: string) => {
            this.loadAddOnById(addOnId)
                .then(() => {
                    this.getApplication().sendNotification('INFO', 'Add-On #' + addOnId + ' loaded');
                })
                .catch((error: Error) => {
                    this.getLogger().error('An error occurred loading add-On #' + addOnId, error);
                });
        });
    }

    /**
     * load one AddOn by ID
     */
    private loadAddOnById(addOnId: string): Promise<TpzAddOn> {
        return this.getApplication().getAddOnManager().loadAddOnById(addOnId);
    }

    /**
     * select all AddOns button
     */
    public getSelectAllButton(): HTMLButtonElement {
        if (!this.selectAllButton) {
            this.selectAllButton = TpzApplicationUI.createButton({ label: 'Select All' });
            this.selectAllButton.addEventListener('click', () => this.selectAll());
        }
        return this.selectAllButton;
    }

    /**
     * select all AddOns button
     */
    public getUpdateButton(): HTMLButtonElement {
        if (!this.updateButton) {
            this.updateButton = TpzApplicationUI.createButton({ label: 'Update', classes: ['update-button'] });
            this.updateButton.addEventListener('click', () => {
                this.getApplication()
                .getAddOnManager()
                .update();
                this.updateUI();
            });
        }
        return this.updateButton;
    }

    /**
     * select all AddOns button
     */
    public getDeselectAllButton(): HTMLButtonElement {
        if (!this.deselectAllButton) {
            this.deselectAllButton = TpzApplicationUI.createButton({ label: 'Deselect All' });
            this.deselectAllButton.addEventListener('click', () => this.deselectAll());
        }
        return this.deselectAllButton;
    }

    /**
     * load all selected AddOns button
     */
    public getLoadButton(): HTMLButtonElement {
        if (!this.loadButton) {
            this.loadButton = TpzApplicationUI.createButton({ label: 'Load selected', classes: ['ok-button'] });
            this.updateSelectionCount();
            this.loadButton.addEventListener('click', () => {
                this.loadSelectedAddOns();
                TpzApplicationCommander.closeParentWindow(this.getApplication(), this);
            });
        }
        return this.loadButton;
    }

    /**
     * get AddOn Toolbar container (child of main container)
     */
    public getToolbarContainer(): HTMLDivElement {
        if (!this.toolbarContainer) {
            this.toolbarContainer = document.createElement('div');
            this.toolbarContainer.classList.add('toolbar');
            this.toolbarContainer.appendChild(this.getSelectAllButton());
            this.toolbarContainer.appendChild(this.getDeselectAllButton());
            this.toolbarContainer.appendChild(this.getLoadButton());
            this.toolbarContainer.appendChild(this.getUpdateButton());
        }
        return this.toolbarContainer;
    }

    /**
     * get AddOn Selector main container
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Invalidate UI components
     */
    public override invalidateUI(): void {
        this.mainContainer = null;
    }

    /**
     * Creation method when item is started
     * @param parent parent HTML Element in which view is inserted
     * @returns true if correctly created
     */
    public override createUI(parent: HTMLDivElement): boolean {
        if (!parent) throw new Error('parent of #' + this.getId() + ' is not defined');
        const mainContainer: HTMLDivElement = this.getMainContainer();
        if (!parent.contains(mainContainer)) parent.appendChild(mainContainer);
        return true;
    }

    public override preStart(): Promise<void> {
        return super.preStart()
            .then(()=> this.getApplication().getAddOnManager().update());
    }
    /**
     * update UI content by requesting addons
     */
    public override updateUI(): void {

        // const progressId: string = this.getApplication()
        //     .getProgressLoaderManager()
        //     .addProgress(this, 'update addons registry');
            // this.getApplication().getProgressLoaderManager().addProgressDescription(progressId, 'regenerate UI');
            this.generateThumbnails();
            this.updateSelectionCount();
            super.updateUI();
            // this.getApplication().getProgressLoaderManager().endProgress(progressId);

    }

    /**
     * Update elements containing selection count
     */
    private updateSelectionCount(): void {
        this.getLoadButton().innerText = 'Load selected (' + this.getSelectedAddOnIds().length + ')';
    }

    /**
     * generate all thumbnails and add them to thumbnail container
     */
    private generateThumbnails(): void {
        const container: HTMLDivElement = this.thumbnailContainer;
        if (!container) return;
        container.innerHTML = '';
        this.descriptors = this.getApplication().getAddOnManager().getDescriptors();
        if (!this.descriptors || Object.keys(this.descriptors).length === 0) {
            container.innerHTML = '...No Add-Ons...';
            return;
        }
        Object.values(this.descriptors).forEach((descriptor: TpzAddOnDescriptor) => {
            container.appendChild(this.createAddOnDescriptorHTMLElement(descriptor));
        });
    }

    /**
     * Create an HTML Element descripbing the given AddOn descriptor
     * @param descriptor addon descriptor
     */
    private createAddOnDescriptorHTMLElement(descriptor: TpzAddOnDescriptor): HTMLElement {
        const div: HTMLDivElement = document.createElement('div');
        div.classList.add(TpzAddOnSelectView.ADDON_DESCRIPTOR_CLASS);
        div.style.backgroundImage = 'url("' + TpzAddOnHelper.getAbsoluteAddOnThumbnailURL(descriptor) + '"';
        div.setAttribute(TpzAddOnSelectView.DESCRIPTOR_ID_ATTRIBUTE, descriptor.id);
        const lang: string = this.getApplication().getCurrentLang();
        const title: string =
            descriptor.name[lang] ?? descriptor.name[descriptor.lang] ?? Object.values(descriptor.name)[0];
        const description: string =
            descriptor.description[lang] ??
            descriptor.description[descriptor.lang] ??
            Object.values(descriptor.description)[0];
        const pTitle: HTMLParagraphElement = TpzApplicationUI.createParagraph({ text: title, classes: 'addon-title' });
        div.appendChild(pTitle);
        const pDescription: HTMLParagraphElement = TpzApplicationUI.createParagraph({
            text: description,
            classes: 'addon-description'
        });
        div.appendChild(pDescription);
        div.addEventListener('click', () => {
            div.classList.toggle(TpzAddOnSelectView.SELECTED_CLASS);
            this.updateSelectionCount();
        });
        return div;
    }

    /**
     * Action to perform when an application event is received
     * @param event application event
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            case TpzApplicationEventType.ADDONS_REGISTRY_UPDATED:
                TpzApplicationEventType.checkEvent(event, null);
                this.updateUI();
                break;
            case TpzApplicationEventType.CHANGE_LANGUAGE:
                TpzApplicationEventType.checkEvent(event, 'lang');
                this.getLogger().error('Item selection internationalization is not yet implemented');
                break;
        }
        return super.onApplicationEvent(event);
    }
}

/**
 * Factory handling HTMLDisplayer creation
 */
export class TpzAddOnSelectViewFactory extends TpzApplicationFactory {
    private static readonly ADDON_SELECT_VIEW_FACTORY_TYPE: string = 'TpzAddOnSelectViewFactory';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(TpzAddOnSelectViewFactory.ADDON_SELECT_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(
            TpzAddOnSelectView.ADDON_SELECT_VIEW_TYPE,
            this.createAddOnSelectView.bind(this),
            defaultTpzAddOnSelectViewConfig
        );
        this.addCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY);
    }

    /** CesiumDisplayer creator function */
    private createAddOnSelectView(config: TpzAddOnSelectViewConfig): Promise<TpzAddOnSelectView> {
        return Promise.resolve(new TpzAddOnSelectView(config, this.getApplication()));
    }
}

/**
 * Helper class on addon selection
 */
export class TpzAddOnSelectHelper {
    /**
     * Create and Start an AddOn selector Window with the given Config. It contains only one view type AddOnSelectView
     * This view and window is automatically started in the current desktop.
     */
    static createSelectAddOnWindow(app: TpzApplication): void {
        if (!app) return null;
        app.registerItemFactory(new TpzAddOnSelectViewFactory(app));
        const addOnSelectViewConfig: TpzAddOnSelectViewConfig = {
            id: 'addon-select-' + uuidv4(), // override ID
            type: TpzAddOnSelectView.ADDON_SELECT_VIEW_TYPE
        };

        TpzApplicationCommander.displayItemNewWindowInActiveDesktopByConfig(
            app,
            addOnSelectViewConfig,
            true,
            'window-addon-select-' + uuidv4()
        );
    }
}
