/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Roller, RollerConfig, RollerEventType, RollerEvent, defaultRollerConfig } from './roller';
import { RangeRollerColumn, ListRollerColumn } from './roller-col';
import { deepCopy } from '../../../tpz-application/tools/deep-copy';

/**
 * Roller date configuration
 */
export interface RollerDateConfig extends RollerConfig {
    yearMin?: number;
    yearMax?: number;
    hours?: boolean;
    minutes?: boolean;
    seconds?: boolean;
}

/**
 * Roller date default configuration
 */
export const defaultRollerDateConfig: RollerDateConfig = {
    ...defaultRollerConfig,
    yearMin: 1970,
    yearMax: 2050,
    hours: true,
    minutes: true,
    seconds: true
};

/**
 * Creates an roller input for dates
 */
export class RollerDate extends Roller {
    private daynameCol: ListRollerColumn = null;
    private dayCol: RangeRollerColumn = null;
    private monthCol: ListRollerColumn = null;
    private yearCol: RangeRollerColumn = null;
    private hourCol: RangeRollerColumn = null;
    private minCol: RangeRollerColumn = null;
    private secCol: RangeRollerColumn = null;

    /**
     * Constructor
     * @param config date roller config
     */
    constructor(config: RollerDateConfig) {
        super(deepCopy(defaultRollerDateConfig, config));
        this.getEventManager().register(this.getId(), this.onRollerEvent.bind(this));
        this.addColumns([this.getDaynameCol(), this.getDayCol(), this.getMonthCol(), this.getYearCol()]);
        if (this.getConfig().hours) this.addColumn(this.getHoursCol());
        if (this.getConfig().minutes) this.addColumn(this.getMinutesCol());
        if (this.getConfig().seconds) this.addColumn(this.getSecondsCol());
        this.setDate(new Date(150000));
    }

    /**
     * Dayname Roller column getter
     */
    private getDaynameCol(): ListRollerColumn {
        if (!this.daynameCol) {
            this.daynameCol = new ListRollerColumn({
                values: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
                cellClasses: ['roller-cell', 'roller-day'],
                interactive: false
            });
        }
        return this.daynameCol;
    }

    /**
     * Day Roller column getter
     */
    private getDayCol(): RangeRollerColumn {
        if (!this.dayCol) {
            this.dayCol = new RangeRollerColumn({
                min: 1,
                max: 31
            });
        }
        return this.dayCol;
    }

    /**
     * Month Roller column getter
     */
    private getMonthCol(): ListRollerColumn {
        if (!this.monthCol) {
            this.monthCol = new ListRollerColumn({
                values: [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Août',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre'
                ]
            });
        }
        return this.monthCol;
    }

    /**
     * Year Roller column getter
     */
    private getYearCol(): RangeRollerColumn {
        if (!this.yearCol) {
            this.yearCol = new RangeRollerColumn({
                min: 1970,
                max: 2030,
                inc: 1
            });
        }
        return this.yearCol;
    }

    /**
     * Hour Roller column getter
     */
    private getHoursCol(): RangeRollerColumn {
        if (!this.hourCol) {
            this.hourCol = new RangeRollerColumn({
                min: 0,
                max: 23,
                inc: 1
            });
        }
        return this.hourCol;
    }

    /**
     * Hour Roller column getter
     */
    private getMinutesCol(): RangeRollerColumn {
        if (!this.minCol) {
            this.minCol = new RangeRollerColumn({
                min: 0,
                max: 59,
                inc: 1
            });
        }
        return this.minCol;
    }

    /**
     * Hour Roller column getter
     */
    private getSecondsCol(): RangeRollerColumn {
        if (!this.secCol) {
            this.secCol = new RangeRollerColumn({
                min: 0,
                max: 59,
                inc: 1
            });
        }
        return this.secCol;
    }

    /**
     * Config roller date specialization
     */
    public getConfig(): RollerDateConfig {
        return super.getConfig() as RollerDateConfig;
    }

    /**
     * On Column Event
     * @param event
     */
    private onRollerEvent(event: RollerEvent): boolean {
        switch (event.type) {
            case RollerEventType.COLUMN_CHANGED:
                {
                    const nbDaysInMonth = this.getDaysInMonth(this.readMonth(), this.readYear());
                    if (this.getDayCol().getSelectedIndex() >= nbDaysInMonth) {
                        this.getDayCol().setSelectedIndex(nbDaysInMonth - 1);
                    }
                    this.getDayCol().setRange(1, nbDaysInMonth);
                    this.setDate(this.readDate());
                    this.daynameCol.setSelectedIndex((this.readDate().getDay() - 1) % 7); // getDay() 0 = Sunday 1 = Monday
                }
                return true;
        }
        return false;
    }

    /**
     * Return the number of days in the given month
     */
    private getDaysInMonth(month: number, year: number) {
        return new Date(year, month + 1, 0).getDate();
    }

    /**
     * set date
     */
    public setDate(date: Date): void {
        let changed: boolean = false;
        if (this.getYearCol().getSelectedValue() !== '' + date.getUTCFullYear()) {
            this.getYearCol().setSelectedValue('' + date.getUTCFullYear());
            changed = true;
        }
        if (this.getMonthCol().getSelectedIndex() !== date.getUTCMonth()) {
            this.getMonthCol().setSelectedIndex(date.getUTCMonth());
            changed = true;
        }
        if (this.getDayCol().getSelectedIndex() !== date.getUTCDate() - 1) {
            this.getDayCol().setSelectedIndex(date.getUTCDate() - 1);
            changed = true;
        }
        if (this.getConfig().hours) {
            if (this.getHoursCol().getSelectedIndex() !== date.getUTCHours()) {
                this.getHoursCol().setSelectedIndex(date.getUTCHours());
                changed = true;
            }
        }
        if (this.getConfig().minutes) {
            if (this.getMinutesCol().getSelectedIndex() !== date.getUTCMinutes()) {
                this.getMinutesCol().setSelectedIndex(date.getUTCMinutes());
                changed = true;
            }
        }
        if (this.getConfig().seconds) {
            if (this.getSecondsCol().getSelectedIndex() !== date.getUTCSeconds()) {
                this.getSecondsCol().setSelectedIndex(date.getUTCSeconds());
                changed = true;
            }
        }
        if (changed) this.getEventManager().trigger({ type: 'DATE_CHANGED' });
    }

    /**
     * Return the selected year
     */
    public readYear(): number {
        if (!this.getYearCol()) return null;
        try {
            return Number.parseInt(this.getYearCol().getSelectedValue());
        } catch (error) {
            return null;
        }
    }

    /**
     * Return the selected month
     * (jan = 0, feb = 1, ..., dec = 11)
     */
    public readMonth(): number {
        if (!this.getMonthCol()) return null;
        try {
            return this.getMonthCol().getSelectedIndex();
        } catch (error) {
            return null;
        }
    }

    /**
     * Return the selected day
     */
    public readDay(): number {
        if (!this.getDayCol()) return null;
        try {
            return this.getDayCol().getSelectedIndex() + 1;
        } catch (error) {
            return null;
        }
    }

    /**
     * Return the selected hours
     */
    public readHours(): number {
        if (!this.getHoursCol()) return null;
        try {
            return this.getHoursCol().getSelectedIndex();
        } catch (error) {
            return null;
        }
    }

    /**
     * Return the selected minutes
     */
    public readMinutes(): number {
        if (!this.getMinutesCol()) return null;
        try {
            return this.getMinutesCol().getSelectedIndex();
        } catch (error) {
            return null;
        }
    }

    /**
     * Return the selected minutes
     */
    public readSeconds(): number {
        if (!this.getSecondsCol()) return null;
        try {
            return this.getSecondsCol().getSelectedIndex();
        } catch (error) {
            return null;
        }
    }

    /**
     * Return the selected year
     */
    public readDayName(): string {
        return this.daynameCol.getSelectedValue();
    }

    /**
     * Return the selected date
     */
    public readDate(): Date {
        const date = new Date(0);
        const year: number = this.readYear();
        if (Number.isFinite(year)) date.setUTCFullYear(year);
        const month: number = this.readMonth();
        if (Number.isFinite(month)) date.setUTCMonth(month);
        const day: number = this.readDay();
        if (Number.isFinite(day)) date.setUTCDate(day);
        const hours: number = this.readHours();
        if (Number.isFinite(hours)) date.setUTCHours(hours);
        const minutes: number = this.readMinutes();
        if (Number.isFinite(minutes)) date.setUTCMinutes(minutes);
        const seconds: number = this.readSeconds();
        if (Number.isFinite(seconds)) date.setUTCSeconds(seconds);
        return date;
    }
}
