/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { uuidv4 } from '../tools/uuid';
import {
    IncomingPingMessageContent,
    OutgoingPingMessageContent,
    OutgoingPongMessageContent
} from './messages/ping-pong-message-content';
import { TpzClientOutgoingMessage } from './server';
import { TpzClientSession } from './session';
import { ToServerMessageProtocol } from './to-server-protocol';
import { OutgoingConnectionInfoMessageContent as OutgoingClientidentificationMessageContent } from './messages/connection-info-message-content';

export class TpzMessageHelper {
    public static createClientOutgoingMessage(
        app: TpzApplication,
        type: string,
        messageContent: any
    ): TpzClientOutgoingMessage {
        const sessionId: string = app.getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            throw new Error(
                'Cannot create an outgoing message with a non connected client session. Connect to server first.'
            );
        }
        return {
            sessionId: app.getSessionManager().getCurrentSessionId(),
            type: type,
            content: messageContent,
            time: new Date().getTime()
        };
    }

    /**
     * Message content type PING emitted by the client
     * @param pingId ping identification
     * @param sessionId
     * @returns
     */
    public static createPingOutgoingMessageContent(app: TpzApplication): OutgoingPingMessageContent {
        const sessionId: string = app.getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            throw new Error(
                'Cannot create a ping message with a non connected client session. Connect to server first.'
            );
        }
        return {
            pingId: uuidv4(),
            sessionId: sessionId,
            clientEmissionTime: new Date().getTime()
        };
    }

    /**
     * Message content type PONG emitted by the client
     * @param app topaz application
     */
    public static createPongOutgoingMessageContent(
        app: TpzApplication,
        pingMessageContent: IncomingPingMessageContent
    ): OutgoingPongMessageContent {
        const sessionId: string = pingMessageContent.sessionId;
        if (!sessionId) {
            throw new Error(
                'Cannot create a pong message with a non connected client session. Connect to server first.'
            );
        }
        return {
            pingId: pingMessageContent.pingId,
            sessionId: sessionId,
            clientEmissionTime: new Date().getTime()
        };
    }

    /**
     * Message type PONG emitted by the client
     * @param app topaz application
     * @returns
     */
    public static createPongOutgoingMessage(
        app: TpzApplication,
        pingMessageContent: IncomingPingMessageContent
    ): TpzClientOutgoingMessage {
        const messageContent: OutgoingPongMessageContent = TpzMessageHelper.createPongOutgoingMessageContent(
            app,
            pingMessageContent
        );
        return TpzMessageHelper.createClientOutgoingMessage(app, ToServerMessageProtocol.PONG_MESSAGE, messageContent);
    }

    /**
     * Message type PING emitted by the client
     * @param app topaz application
     * @returns
     */
    public static createPingOutgoingMessage(app: TpzApplication): TpzClientOutgoingMessage {
        const messageContent: OutgoingPingMessageContent = TpzMessageHelper.createPingOutgoingMessageContent(app);
        return TpzMessageHelper.createClientOutgoingMessage(app, ToServerMessageProtocol.PING_MESSAGE, messageContent);
    }

    /**
     * Message content type CONNECTION_INFO emitted by the client
     * @param app topaz application
     */
    public static createClientIdentificationOutgoingMessageContent(
        app: TpzApplication,
        session: TpzClientSession
    ): OutgoingClientidentificationMessageContent {
        if (!app) {
            throw new Error(
                'Cannot create a pong message with a non connected client session. Connect to server first.'
            );
        }
        return {
            clientId: app.getId()
        };
    }

    /**
     * Creates an outgoing message to send connection information (first thrown message)
     * @param app topaz application
     * @param session session to send information of
     */
    public static createClientIdentificationOutgoingMessage(
        app: TpzApplication,
        session: TpzClientSession
    ): TpzClientOutgoingMessage {
        const messageContent: OutgoingClientidentificationMessageContent =
            TpzMessageHelper.createClientIdentificationOutgoingMessageContent(app, session);
        return TpzMessageHelper.createClientOutgoingMessage(
            app,
            ToServerMessageProtocol.CLIENT_IDENTIFICATION_MESSAGE,
            messageContent
        );
    }
}
