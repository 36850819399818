/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventManager } from '../../../tpz-event/tpz-event-core';

/**
 * Event trigger by knobs
 */
export interface KnobEvent {
    type: string;
}

/**
 * Knob event types for columns and knobs
 */
export class KnobEventType {
    public static readonly VALUE_CHANGED = 'COLUMN_CHANGED';
    public static readonly ROLLER_CHANGED = 'ROLLER_CHANGED';
}

/**
 * List knob configuration
 */
export interface KnobConfig {
    nbTicks?: number;
    tickSize?: number;
    radius?: number;
    minValue?: number;
    maxValue?: number;
    minAngle?: number;
    maxAngle?: number;
    invertY?: boolean;
    sensibility?: number;
    containerClasses?: string[];
    knobClasses?: string[];
    ticksClasses?: string[];
}

/**
 * Default configuration
 */
const defaultKnobConfig: KnobConfig = {
    nbTicks: 10,
    tickSize: 10,
    radius: 50,
    minValue: 0,
    maxValue: 100,
    minAngle: -135,
    maxAngle: +135,
    invertY: false,
    sensibility: 0.5,
    containerClasses: ['knob-container'],
    knobClasses: ['knob-button'],
    ticksClasses: ['knob-ticks']
};

/**
 * Knob is a button going from a min value to a max value&
 */
export class Knob {
    public static readonly LIGHTEN_TICK_CLASS: string = 'activetick';

    private readonly config: KnobConfig = null;
    private container: HTMLDivElement = null;
    private knobDiv: HTMLDivElement = null;
    private ticksDiv: HTMLDivElement = null;
    private ticks: HTMLDivElement[] = null;

    private eventManager: EventManager<KnobEvent> = null;

    private value: number = 0; // value contained in knob
    private clickX: number = null;
    private clickY: number = null;
    private clickValue: number = null;

    /**
     * List Knob constructor
     * @param parent input element to fill with
     * @param values
     */
    constructor(config: KnobConfig) {
        const me: Knob = this;
        this.config = { ...defaultKnobConfig, ...config };
        this.value = (me.getConfig().minValue + me.getConfig().maxValue) / 2;
    }

    /**
     * Config knob getter
     */
    public getConfig(): KnobConfig {
        return this.config;
    }

    /**
     * Get Event Manager
     * @param column
     */
    public getEventManager(): EventManager<KnobEvent> {
        if (!this.eventManager) {
            this.eventManager = new EventManager<KnobEvent>();
        }
        return this.eventManager;
    }

    /**
     * Lazy getter of the main DIV container
     */
    public getUI(): HTMLDivElement {
        const me: Knob = this;
        if (!this.container) {
            this.container = document.createElement('div');
            this.config?.containerClasses?.forEach((clazz) => me.container.classList.add(clazz));
            this.container.appendChild(this.getKnobDiv());
            this.container.appendChild(this.getTicksDiv());
            this.container.style.width = this.getConfig().radius + 'px';
            this.container.style.height = this.getConfig().radius + 'px';
            this.updateUI();
        }
        return this.container;
    }

    /**
     * Update UI
     * change graphics to  this.value
     */
    public updateUI(): void {
        if (!Number.isFinite(this.value)) return;
        const alpha: number = (this.value - this.config.minValue) / (this.config.maxValue - this.config.minValue);
        const nbLightenTicks = Math.round(alpha * this.config.nbTicks);
        // lit ticks
        if (this.ticks) {
            for (let tickIndex = 0; tickIndex < this.getConfig().nbTicks; tickIndex++) {
                const tick: HTMLDivElement = this.ticks[tickIndex];
                if (tickIndex <= nbLightenTicks) tick.classList.add(Knob.LIGHTEN_TICK_CLASS);
                else tick.classList.remove(Knob.LIGHTEN_TICK_CLASS);
            }
        }
        if (this.knobDiv) {
            // turn knob
            const knobAngle: number =
                this.getConfig().minAngle + alpha * (this.getConfig().maxAngle - this.getConfig().minAngle);
            this.knobDiv.style.transform = 'rotate(' + knobAngle + 'deg)';
        }
    }

    /**
     * Lazy getter of the Roller DIV
     */
    private getKnobDiv(): HTMLDivElement {
        const me: Knob = this;
        if (!this.knobDiv) {
            this.knobDiv = document.createElement('div');
            this.config?.knobClasses?.forEach((clazz) => me.knobDiv.classList.add(clazz));

            this.knobDiv.addEventListener('wheel', (e: WheelEvent) => {
                e.preventDefault();
                const deltaY = me.getConfig().invertY ? e.deltaY : -e.deltaY;
                // get previous tick value
                const deltaValue = deltaY / me.getConfig().sensibility;
                let currentTickIndex =
                    ((me.getValue() - me.getConfig().minValue) / (me.getConfig().maxValue - me.getConfig().minValue)) *
                    me.getConfig().nbTicks;
                // increment or decrement tick index
                currentTickIndex = deltaY > 0 ? currentTickIndex + 1 : currentTickIndex - 1;
                const newValue =
                    me.getConfig().minValue +
                    (me.getConfig().maxValue - me.getConfig().minValue) * (currentTickIndex / me.getConfig().nbTicks);
                me.setValue(newValue);
            });

            this.knobDiv.addEventListener('pointerdown', function (e: PointerEvent) {
                me.clickX = e.pageX;
                me.clickY = e.pageY;
                me.clickValue = me.getValue();
                e.preventDefault();
                me.knobDiv.setPointerCapture(e.pointerId);
            });

            this.knobDiv.addEventListener('pointerup', function (e: PointerEvent) {
                me.knobDiv.releasePointerCapture(e.pointerId);
                const deltaY = e.pageY - me.clickY;
                const deltaValue = -deltaY * me.getConfig().sensibility;
                const newValue = me.clickValue + deltaValue;
                me.setValue(newValue, true);
                me.clickX = null;
                me.clickY = null;
                me.clickValue = null;
            });

            this.knobDiv.addEventListener('pointermove', function (e: PointerEvent) {
                if (me.clickX != null && me.clickY != null) {
                    const deltaY = e.pageY - me.clickY;
                    const deltaValue = -deltaY * me.getConfig().sensibility;
                    const newValue = me.clickValue + deltaValue;
                    me.setValue(newValue, false);
                }
            });
        }
        return this.knobDiv;
    }

    /**
     * Lazy getter of the Roller DIV
     */
    private getTicksDiv(): HTMLDivElement {
        const me: Knob = this;
        if (!this.ticksDiv) {
            this.ticksDiv = document.createElement('div');
            this.config?.ticksClasses?.forEach((clazz) => me.ticksDiv.classList.add(clazz));
            this.ticks = [];
            for (let tickIndex = 0; tickIndex < this.getConfig().nbTicks; tickIndex++) {
                const tick: HTMLDivElement = this.createTick(tickIndex);
                this.ticks.push(tick);
                this.ticksDiv.appendChild(tick);
            }
        }
        return this.ticksDiv;
    }

    /**
     * Lazy getter of the Roller DIV
     */
    private createTick(index: number): HTMLDivElement {
        const me: Knob = this;
        const tick: HTMLDivElement = document.createElement('div');
        const alpha = index / (this.config.nbTicks - 1);
        const angle = this.config.minAngle + (this.config.maxAngle - this.config.minAngle) * alpha;
        tick.style.transform = 'rotate(' + angle + 'deg)';
        return tick;
    }

    /**
     * get knob value
     */
    public getValue(): number {
        return this.value;
    }

    /**
     * set knob value
     * @param value
     */
    public setValue(value: number, trigger: boolean = true): boolean {
        value = Math.min(Math.max(this.config.minValue, value), this.config.maxValue);
        if (value === this.value) return false;
        this.value = value;
        this.updateUI();
        this.getEventManager().trigger({ type: KnobEventType.VALUE_CHANGED });

        return true;
    }
}
