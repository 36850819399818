/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import {
    SimulatedTimeDescriptor,
    SimulatedTimeEventType,
    SimulatedTimeHelper,
    SimulatedTimeRunner
} from '../../../time/simulated-time';
import { TpzApplication } from '../../../tpz-application-core';
import { TimeHandler } from '../../../time/time-handler';
import { deepCopy } from '../../../tools/deep-copy';

/******************************************* DISPLAYER *******************************/

// item type (used in default configuration and Item Instance constructor)
const CLOCK_VIEW_ANALOG_TYPE: string = 'ClockViewAnalogType';

/**
 * Displayer Configuration
 */
export interface ClockViewAnalogConfig extends TpzViewConfig {
    timezone?: number; // timezone in hours
    location?: string; // time location name (Paris, Moscow, ...)
    timeUpdateRate?: number; // time runner update rate (in milliseconds)
}

/**
 * Default Displayer Configuration
 */
export const defaultClockViewAnalogConfig: ClockViewAnalogConfig = Object.freeze({
    ...defaultTpzViewConfig,
    type: CLOCK_VIEW_ANALOG_TYPE,
    // containerClasses: ["analog-clock"].concat(defaultTpzViewConfig.containerClasses),
    css: ['css/analog-clock.css'].concat(defaultTpzViewConfig.css),
    timezone: 1,
    location: 'paris', // time location name (Paris, Moscow, ...)
    timeUpdateRate: 1000
});

/**
 * Clock Displayer displays analog time
 */
export class ClockViewAnalog extends TpzView {
    static readonly CLOCK_VIEW_ANALOG_TYPE: string = CLOCK_VIEW_ANALOG_TYPE;
    static readonly DEFAULT_TIMERUNNER_UPDATE_RATE: number = 1000; // time runner update rate in milliseconds

    private readonly timeHandler: TimeHandler = null; // simulated time descriptor

    private clockDiv: HTMLDivElement = null;
    private locationDiv: HTMLDivElement = null;
    private utcDiv: HTMLDivElement = null;
    private hourHandDiv: HTMLDivElement = null;
    private minuteHandDiv: HTMLDivElement = null;
    private secondHandDiv: HTMLDivElement = null;
    private hour1Div: HTMLDivElement = null;
    private hour2Div: HTMLDivElement = null;
    private hour3Div: HTMLDivElement = null;
    private hour4Div: HTMLDivElement = null;
    private hour5Div: HTMLDivElement = null;
    private hour12Div: HTMLDivElement = null;

    /** Constructor */
    constructor(config: ClockViewAnalogConfig, application: TpzApplication) {
        super(deepCopy(defaultClockViewAnalogConfig, config), ClockViewAnalog.CLOCK_VIEW_ANALOG_TYPE, application);
        this.timeHandler = new TimeHandler(this, this.onTimeHandlerChanged.bind(this), this.getConfig().timeUpdateRate);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): ClockViewAnalogConfig {
        return super.getConfig() as ClockViewAnalogConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: ClockViewAnalogConfig = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.timezone !== newConfig?.timezone) {
            this.invalidateUI();
            changes = true;
        }
        if (config?.location !== newConfig?.location) {
            this.invalidateUI();
            changes = true;
        }
        if (config?.timeUpdateRate !== newConfig?.timeUpdateRate) {
            this.setTimeUpdateRate(newConfig.timeUpdateRate);
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }

    /**
     * Convert an integer number to string.
     * Fill with 0s to match given length (in characters count)
     */
    private static strpad(number: number, length: number): string {
        let str = '' + number;
        while (str.length < length) str = '0' + str;
        return str;
    }

    /**
     * Create Clock UI in view container Div
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!this.clockDiv) {
            this.clockDiv = document.createElement('div');
            this.clockDiv.classList.add('analog-clock');
            parent.appendChild(this.clockDiv);
        }
        if (!this.hour1Div) {
            this.hour1Div = document.createElement('div');
            this.hour1Div.classList.add('hour1');
            this.clockDiv.appendChild(this.hour1Div);
        }
        if (!this.hour2Div) {
            this.hour2Div = document.createElement('div');
            this.hour2Div.classList.add('hour2');
            this.clockDiv.appendChild(this.hour2Div);
        }
        if (!this.hour3Div) {
            this.hour3Div = document.createElement('div');
            this.hour3Div.classList.add('hour3');
            this.clockDiv.appendChild(this.hour3Div);
        }
        if (!this.hour4Div) {
            this.hour4Div = document.createElement('div');
            this.hour4Div.classList.add('hour4');
            this.clockDiv.appendChild(this.hour4Div);
        }
        if (!this.hour5Div) {
            this.hour5Div = document.createElement('div');
            this.hour5Div.classList.add('hour5');
            this.clockDiv.appendChild(this.hour5Div);
        }
        if (!this.hour12Div) {
            this.hour12Div = document.createElement('div');
            this.hour12Div.classList.add('hour12');
            this.clockDiv.appendChild(this.hour12Div);
        }
        if (!this.hourHandDiv) {
            this.hourHandDiv = document.createElement('div');
            this.hourHandDiv.classList.add('hourHand');
            this.clockDiv.appendChild(this.hourHandDiv);
        }
        if (!this.minuteHandDiv) {
            this.minuteHandDiv = document.createElement('div');
            this.minuteHandDiv.classList.add('minuteHand');
            this.clockDiv.appendChild(this.minuteHandDiv);
        }
        if (!this.secondHandDiv) {
            this.secondHandDiv = document.createElement('div');
            this.secondHandDiv.classList.add('secondHand');
            this.clockDiv.appendChild(this.secondHandDiv);
        }
        if (!this.locationDiv) {
            this.locationDiv = document.createElement('div');
            this.locationDiv.classList.add('clock-label');
            this.clockDiv.appendChild(this.locationDiv);
        }
        if (!this.utcDiv) {
            this.utcDiv = document.createElement('div');
            this.utcDiv.classList.add('clock-utc');
            this.clockDiv.appendChild(this.utcDiv);
        }

        this.displayClock();
        return true;
    }

    /**
     * Set the timerHandler update rate
     * @param updateRate time rate Time Handler self update (in milliseconds)
     */
    public setTimeUpdateRate(updateRate: number): void {
        this.timeHandler?.setTimeUpdateRate(updateRate);
    }

    /** Remove Graphics components */
    public invalidateUI(): void {
        this.locationDiv = null;
        this.utcDiv = null;
        this.hourHandDiv = null;
        this.minuteHandDiv = null;
        this.secondHandDiv = null;
        this.hour1Div = null;
        this.hour2Div = null;
        this.hour3Div = null;
        this.hour4Div = null;
        this.hour5Div = null;
        this.hour12Div = null;
        this.clockDiv = null;
        super.invalidateUI();
    }

    /**
     * Display the clock content in HTML mainContainer
     */
    private displayClock(time: number = null): boolean {
        if (!this.getViewContainer()) return false;
        if (!time) {
            const simulatedTimeDescriptor: SimulatedTimeDescriptor = this.getTimeDescriptor();
            if (!simulatedTimeDescriptor) return false;
            time = SimulatedTimeHelper.getSimulatedTime(simulatedTimeDescriptor);
        }
        const date: Date = new Date(time);
        // add timezone if set
        const tz: number = this.getTimeZone() ?? 0;
        if (Number.isFinite(tz)) {
            date.setTime(date.getTime() + tz * 3600 * 1000);
        }
        // use referenceDate at the beginning of the current day
        // the goal is to avoid big numbers (big angles) that 'transform' can't handle correctly
        // starting at the beginning of the day works
        const referenceDate: Date = new Date();
        referenceDate.setUTCHours(0);
        referenceDate.setUTCMinutes(0);
        referenceDate.setUTCSeconds(0);
        // display hour hand
        if (this.hourHandDiv) {
            const hourRotationDeg: number = ((date.getUTCHours() + date.getUTCMinutes() / 60) / 12) * 360 - 90;
            this.hourHandDiv.style.transform = 'rotate(' + hourRotationDeg + 'deg)';
        }
        // display minute hand
        if (this.minuteHandDiv) {
            const minuteHandDeg: number =
                ((date.getUTCHours() * 60 + date.getUTCMinutes() + date.getUTCSeconds() / 60) / 60) * 360 - 90;
            this.minuteHandDiv.style.transform = 'rotate(' + minuteHandDeg + 'deg)';
        }
        // display second hand
        if (this.secondHandDiv) {
            const secondHandDeg: number =
                (Math.ceil((date.getTime() - referenceDate.getTime()) / 1000) / 60) * 360 - 90;
            this.secondHandDiv.style.transform = 'rotate(' + secondHandDeg + 'deg)';
        }
        // display location
        if (this.locationDiv && Number.isFinite(tz)) {
            this.locationDiv.innerText = this.getTimeLocation() ?? '';
        }
        // display location
        if (this.utcDiv && Number.isFinite(tz)) {
            this.utcDiv.innerText = 'UTC ' + (tz > 0 ? '+' : '') + ClockViewAnalog.strpad(tz, 2);
        }
        return true;
    }

    /**
     * get time location (from config)
     * @returns
     */
    public getTimeLocation(): string {
        return this.getConfig()?.location;
    }

    /**
     * get time zone (from config)
     * @returns
     */
    public getTimeZone(): number {
        return this.getConfig()?.timezone;
    }

    /**
     * get time descriptor (from timeHandler)
     * @returns
     */
    public getTimeDescriptor(): SimulatedTimeDescriptor {
        if (!this.timeHandler) return null;
        return this.timeHandler.getTimeDescriptor();
    }

    /**
     * Start Clock displayer by creating an interval function requesting clock value
     */
    public doStart(): Promise<void> {
        const me: ClockViewAnalog = this;
        return super.doStart().then(() => {
            this.timeHandler?.start();
        });
    }

    /**
     * Stop interval function
     */
    public doStop(): Promise<void> {
        return super.doStop().then(() => {
            this.timeHandler.stop();
        });
    }

    /**
     * Request do nothing for clocks. Launch an event to request for new Time Descriptor
     * If a TimeManager plugin exists, the response will be received by event
     */
    public requestUpdate(force: boolean = false): boolean {
        this.getApplication().fireEvent(this.getId(), SimulatedTimeEventType.REQUEST_TIME_DESCRIPTOR, null, []);
        return true;
    }

    /**
     * React to time handler events.
     */
    public onTimeHandlerChanged(time: number, live: boolean): void {
        if (live) this.displayClock(time);
        else this.displayClock();
    }

    /**
     * Update UI
     */
    public updateUI(): void {
        this.displayClock();
        super.updateUI();
    }
}
