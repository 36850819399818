/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Accessor, AccessorFactory } from './tpz-access-core';
import { AccessorEventType } from './tpz-access-event';
import { ValueAccessorConfig, ValueAccessor, defaultValueAccessorConfig } from './tpz-access-value';
import {
    AccessorValueImportExport,
    DataConvertFromString,
    DataConvertToString,
    AccessorType
} from './tpz-access-types';
import { AccessData } from './tpz-data';
import { TpzApplication } from '../tpz-application/tpz-application-core';
import { deepCopy } from '../tpz-application/tools/deep-copy';
import { AccessorCategoryType } from './tpz-access-types';

function evolutionFunction(value: number, increment: number, jitterFactor: number, min: number, max: number): number {
    // add increment and loop over range
    value = value + increment;
    if (increment > 0 && value > max) {
        return min;
    }
    if (increment < 0 && value < min) {
        return max;
    }

    if (jitterFactor !== 0) {
        // jitter value by a ranfom factor
        let randomFactor: number = (Math.random() * 2 - 1) * (max - min) * jitterFactor;
        if (value + randomFactor <= min) randomFactor = -randomFactor;
        if (value + randomFactor >= max) randomFactor = -randomFactor;
        value = value + randomFactor;
    }
    // clamp final value
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
}

// evolution function for EvolutiveTestValue
//export type ValueEvolutionFunction<DataType> = (data: DataType, t: number) => DataType;

/*
let s1EvolutionFunction: ValueEvolutionFunction<[number, number]> = function (data: [number, number], t: number) {
    let v: number = data[1] + 2 * Math.random() - 1;
    return [Math.ceil(new Date().getTime() / 10), v < -10 || v > 10 ? 0 : v];
}

let v1NumberEvolutionFunction: ValueEvolutionFunction<number> = function (data: number, t: number) {
    let v: number = data + 2 * Math.random() - 1;
    return v < -10 || v > 10 ? 0 : v;
}

let v1Time2DEvolutionFunction: ValueEvolutionFunction<[number, number]> = function (data: [number, number], t: number) {
    let v0: number = data[0] + t;
    let v1: number = data[1] + 2 * Math.random() - 1;
    return [v0, v1 < -10 || v1 > 10 ? 0 : v1];
}
*/

/**
 * Evolutive test accessor configuration
 */
export interface EvolutiveTestAccessorConfig<DataType> extends ValueAccessorConfig<DataType> {
    delai?: number; // update delai in milliseconds
}

/**
 * Default Evolutive test accessor configuration
 */
export const defaultEvolutiveTestAccessorConfig: EvolutiveTestAccessorConfig<any> = {
    ...defaultValueAccessorConfig,
    // type is not defined for abstract classes
    delai: 100
};

////////////////////////////////////////////////// TEST PURPOSE ACCESSORs /////////////////////////////////////

export abstract class EvolutiveTestAccessor<DataType> extends ValueAccessor<DataType> {
    private intervalId: number = undefined;

    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param type accessor type
     * @param app topaz application in which this accessor is used
     * @param valueFromString conversion method from String to Value
     * @param valueToString conversion method from Value to String
     */
    constructor(
        config: EvolutiveTestAccessorConfig<DataType>,
        type: string,
        app: TpzApplication,
        valueFromString: DataConvertFromString<DataType>,
        valueToString: DataConvertToString<DataType>
    ) {
        super(deepCopy(defaultEvolutiveTestAccessorConfig, config), type, app, valueFromString, valueToString);
        this.addCategory(AccessorCategoryType.EVOLUTIVE_TEST_ACCESSOR_CATEGORY_TYPE);
    }

    /** Start accessor */
    public doStart(): Promise<void> {
        return super.doStart();
    }

    /** Stop accessor */
    public doStop(): Promise<void> {
        if (this.intervalId) {
            window.clearInterval(this.intervalId);
        }
        this.intervalId = null;
        return super.stop();
    }

    /** get config specialization */
    public getConfig(): EvolutiveTestAccessorConfig<DataType> {
        return super.getConfig() as EvolutiveTestAccessorConfig<DataType>;
    }

    /**
     * Data are sent continuously using interval function.
     */
    public requestUpdate(): boolean {
        if (!this.isRunning()) {
            return false;
        }
        if (!this.intervalId) {
            this.intervalId = window.setInterval(this.updateData.bind(this), this.getConfig().delai);
            this.updateData();
        }
        return true;
    }

    protected abstract updateData(): void;
}

/**
 *  Evolutive test accessor configuration
 */
export interface EvolutiveTestNumberAccessorConfig extends EvolutiveTestAccessorConfig<number> {
    increment?: number;
    jitterFactor?: number;
    minValue?: number;
    maxValue?: number;
}

/**
 * Default Evolutive test accessor configuration
 */
export const defaultEvolutiveTestNumberAccessorConfig: EvolutiveTestNumberAccessorConfig = {
    ...defaultEvolutiveTestAccessorConfig,
    type: AccessorType.NUMBER_EVOLUTIVE_TEST_ACCESSOR_TYPE,
    increment: 1,
    jitterFactor: 0.1,
    minValue: -100,
    maxValue: 100
};

/** One real value */
export class EvolutiveTestNumberAccessor extends EvolutiveTestAccessor<number> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app topaz application in which this accessor is used
     */
    constructor(config: EvolutiveTestNumberAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultEvolutiveTestNumberAccessorConfig, config),
            AccessorType.NUMBER_EVOLUTIVE_TEST_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringNumber,
            AccessorValueImportExport.convertToStringNumber
        );
        if (config && config.dataType !== AccessData.NUMBER_DATATYPE) {
            this.getLogger().error(
                `EvolutiveTestNumberAccessorConfig #${this.getId()} must Be ${
                    AccessData.NUMBER_DATATYPE
                }. There is an error in your configuration. Force this type`
            );
        }
        this.setDataType(AccessData.NUMBER_DATATYPE);
    }

    /** get config specialization */
    public getConfig(): EvolutiveTestNumberAccessorConfig {
        return super.getConfig() as EvolutiveTestNumberAccessorConfig;
    }

    protected updateData(): void {
        let value: number = this.getValue();
        value = evolutionFunction(
            value,
            this.getConfig().increment,
            this.getConfig().jitterFactor,
            this.getConfig().minValue,
            this.getConfig().maxValue
        );
        this.setValue(value);
    }
}

/**
 * Default Evolutive test accessor configuration
 */
export interface EvolutiveTestNumber2DAccessorConfig extends EvolutiveTestAccessorConfig<[number, number]> {
    incrementX?: number;
    incrementY?: number;
    jitterFactorX?: number;
    jitterFactorY?: number;
    minValueX?: number;
    maxValueX?: number;
    minValueY?: number;
    maxValueY?: number;
}

/**
 * Default Evolutive test accessor configuration
 */
export const defaultEvolutiveTestNumber2DAccessorConfig: EvolutiveTestNumber2DAccessorConfig = {
    ...defaultEvolutiveTestAccessorConfig,
    type: AccessorType.NUMBER2D_EVOLUTIVE_TEST_ACCESSOR_TYPE,
    incrementX: 1,
    incrementY: 1,
    jitterFactorX: 0.1,
    jitterFactorY: 0.1,
    minValueX: -100,
    maxValueX: 100,
    minValueY: -100,
    maxValueY: 100
};

/** array of two real values */
export class EvolutiveTestNumber2DAccessor extends EvolutiveTestAccessor<[number, number]> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app topaz application in which this accessor is used
     */
    constructor(config: EvolutiveTestNumber2DAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultEvolutiveTestNumber2DAccessorConfig, config),
            AccessorType.NUMBER2D_EVOLUTIVE_TEST_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringNumber2D,
            AccessorValueImportExport.convertToStringNumber2D
        );
        if (config && config.dataType !== AccessData.NUMBER2D_DATATYPE) {
            this.getLogger().error(
                `EvolutiveTestNumberAccessorConfig #${this.getId()} must Be ${
                    AccessData.NUMBER2D_DATATYPE
                }. There is an error in your configuration. Force this type`
            );
        }
        this.setDataType(AccessData.NUMBER2D_DATATYPE);
        this.applyConfig();
    }

    /**
     * config getter specialization
     */
    public getConfig(): EvolutiveTestNumber2DAccessorConfig {
        return super.getConfig() as EvolutiveTestNumber2DAccessorConfig;
    }

    protected updateData(): void {
        let valueX: number = this.getValue()[0];
        let valueY: number = this.getValue()[1];

        valueX = evolutionFunction(
            valueX,
            this.getConfig().incrementX,
            this.getConfig().jitterFactorX,
            this.getConfig().minValueX,
            this.getConfig().maxValueX
        );
        valueY = evolutionFunction(
            valueY,
            this.getConfig().incrementY,
            this.getConfig().jitterFactorY,
            this.getConfig().minValueY,
            this.getConfig().maxValueY
        );
        this.setValue([valueX, valueY]);
    }
}

/**
 * Default Evolutive test accessor configuration
 */
export interface EvolutiveTestNumberArrayAccessorConfig extends EvolutiveTestAccessorConfig<[number, number]> {
    count?: number; // number of values in array
    increment?: number;
    jitterFactor?: number;
    minValue?: number;
    maxValue?: number;
}

/**
 * Default Evolutive test accessor configuration
 */
export const defaultEvolutiveTestNumberArrayAccessorConfig: EvolutiveTestNumberArrayAccessorConfig = {
    ...defaultEvolutiveTestAccessorConfig,
    type: AccessorType.NUMBERARRAY_TEST_EVOLUTIVE_ACCESSOR_TYPE,
    count: 10,
    increment: 1,
    jitterFactor: 0.1,
    minValue: -100,
    maxValue: 100
};

/** array of N real values */
export class EvolutiveTestNumberArrayAccessor extends EvolutiveTestAccessor<number[]> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app topaz application in which this accessor is used
     */
    constructor(config: EvolutiveTestNumberArrayAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultEvolutiveTestNumberArrayAccessorConfig, config),
            AccessorType.NUMBERARRAY_TEST_EVOLUTIVE_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringNumberArray,
            AccessorValueImportExport.convertToStringNumberArray
        );
        if (config && config.dataType !== AccessData.NUMBER_ARRAY_DATATYPE) {
            this.getLogger().error(
                `EvolutiveTestNumberAccessorConfig #${this.getId()} must Be ${
                    AccessData.NUMBER_ARRAY_DATATYPE
                }. There is an error in your configuration. Force this type`
            );
        }
        this.setDataType(AccessData.NUMBER_ARRAY_DATATYPE);
        this.applyConfig();
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): EvolutiveTestNumberArrayAccessorConfig {
        return super.getConfig() as EvolutiveTestNumberArrayAccessorConfig;
    }

    protected updateData(): void {
        const values: number[] = [];
        const previousValues: number[] = this.getValue();
        for (let index = 0; index < this.getConfig().count; index++) {
            let valueIndex: number = 0;
            if (previousValues && previousValues.length < index) {
                valueIndex = this.getValue()[index];
            }
            valueIndex = evolutionFunction(
                valueIndex,
                this.getConfig().increment,
                this.getConfig().jitterFactor,
                this.getConfig().minValue,
                this.getConfig().maxValue
            );
            values.push(valueIndex);
        }
        this.setValue(values);
    }
}

/**
 * Evolutive test accessor configuration
 */
export interface EvolutiveTestSeries2DAccessorConfig extends EvolutiveTestAccessorConfig<[number, number][]> {
    incrementX?: number;
    incrementY?: number;
    scaleFactorX?: number;
    scaleFactorY?: number;
    minValueX?: number;
    maxValueX?: number;
    minValueY?: number;
    maxValueY?: number;
}

/**
 * Default Evolutive test accessor configuration
 */
export const defaultEvolutiveTestSeries2DAccessorConfig: EvolutiveTestSeries2DAccessorConfig = {
    ...defaultEvolutiveTestAccessorConfig,
    type: AccessorType.SERIES2D_TEST_EVOLUTIVE_ACCESSOR_TYPE,
    incrementX: 1,
    incrementY: 1,
    scaleFactorX: 0.1,
    scaleFactorY: 0.1,
    minValueX: -100,
    maxValueX: 100,
    minValueY: -100,
    maxValueY: 100
};

/** Array of 2D real values */
export class EvolutiveTestSeries2DAccessor extends EvolutiveTestAccessor<[number, number][]> {
    public static valueFromString: DataConvertFromString<[number, number][]> = (value: string): [number, number][] => {
        if (!value) {
            return [];
        }
        const vs: any = JSON.parse(value);
        const valueArray: [number, number][] = [];
        for (const v of vs) {
            valueArray.push([Number(v[0]), Number(v[1])]);
        }
        return valueArray;
    };

    public static valueToString: DataConvertToString<[number, number][]> = (value: [number, number][]): string => {
        if (!value) {
            return '[]';
        }
        return JSON.stringify(value);
    };

    // public static equalValue( v1: [number, number][], v2: [number, number][] ): boolean {
    //     if ( v1 == null && v2 == null ) return true;
    //     if ( v1 !== null && v2 == null ) return false;
    //     if ( v1 == null && v2 !== null ) return false;
    //     if (v1.length !== v2.length) return false;
    //     for (let index = 0; index < v1.length; index++) {
    //         if ( !CSAccessorValueImportExport.equalValueNumber2D( v1[index], v2[index] ) ) return false;
    //     }
    //     return true;    }

    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app topaz application in which this accessor is used
     */
    constructor(config: EvolutiveTestSeries2DAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultEvolutiveTestSeries2DAccessorConfig, config),
            AccessorType.SERIES2D_TEST_EVOLUTIVE_ACCESSOR_TYPE,
            app,
            EvolutiveTestSeries2DAccessor.valueFromString,
            EvolutiveTestSeries2DAccessor.valueToString
        );
        if (config && config.dataType !== AccessData.NUMBER2D_ARRAY_DATATYPE) {
            this.getLogger().error(
                `EvolutiveTestNumberAccessorConfig #${this.getId()} must Be ${
                    AccessData.NUMBER2D_ARRAY_DATATYPE
                }. There is an error in your configuration. Force this type`
            );
        }
        this.setDataType(AccessData.NUMBER2D_ARRAY_DATATYPE);
        this.applyConfig();
    }

    /**
     * Config getter specialization
     */
    public getConfig(): EvolutiveTestSeries2DAccessorConfig {
        return super.getConfig() as EvolutiveTestSeries2DAccessorConfig;
    }

    protected updateData(): void {
        const values: [number, number][] = [];
        this.getValue().forEach((value) => {
            let valueX: number = value[0];
            let valueY: number = value[1];

            valueX = evolutionFunction(
                valueX,
                this.getConfig().incrementX,
                this.getConfig().scaleFactorX,
                this.getConfig().minValueX,
                this.getConfig().maxValueX
            );
            valueY = evolutionFunction(
                valueY,
                this.getConfig().incrementY,
                this.getConfig().scaleFactorY,
                this.getConfig().minValueY,
                this.getConfig().maxValueY
            );
            value[0] = valueX;
            value[1] = valueY;
            values.push([valueX, valueY]);
        });
        this.setValue(values);
        this.fireItemEvent(AccessorEventType.DATA, null);
    }
}

//////////////////////////////////////////// Test Accessors Default Factory /////////////////////////////////////
/**
 * Test accessor factory
 */
export class TestAccessorFactory extends AccessorFactory {
    public static readonly TEST_ACCESSOR_FACTORY_TYPE: string = 'TestAccessorFactory';
    private readonly application: TpzApplication = null; // application to give to item constructors

    /**
     * constructor
     * @param app application to give to item constructors
     */
    constructor(app: TpzApplication) {
        super(TestAccessorFactory.TEST_ACCESSOR_FACTORY_TYPE);
        this.application = app;
        this.addCategory(AccessorCategoryType.ACCESSOR_CATEGORY_TYPE);
        this.addHandledItem(
            AccessorType.NUMBER_EVOLUTIVE_TEST_ACCESSOR_TYPE,
            this.createEvolutiveTestNumberAccessor.bind(this),
            defaultEvolutiveTestNumberAccessorConfig
        );
        this.addHandledItem(
            AccessorType.NUMBER2D_EVOLUTIVE_TEST_ACCESSOR_TYPE,
            this.createEvolutiveTestNumber2DAccessor.bind(this),
            defaultEvolutiveTestNumber2DAccessorConfig
        );
        this.addHandledItem(
            AccessorType.SERIES2D_TEST_EVOLUTIVE_ACCESSOR_TYPE,
            this.createEvolutiveTestSeries2DAccessor.bind(this),
            defaultEvolutiveTestSeries2DAccessorConfig
        );
        this.addHandledItem(
            AccessorType.NUMBERARRAY_TEST_EVOLUTIVE_ACCESSOR_TYPE,
            this.createEvolutiveTestNumberArrayAccessor.bind(this),
            defaultEvolutiveTestNumberArrayAccessorConfig
        );
    }

    /**
     * Application getter
     * @returns the stored application
     */
    private getApplication(): TpzApplication {
        return this.application;
    }

    /**  EvolutiveTestNumberAccessor creator function */
    private createEvolutiveTestNumberAccessor(
        config: EvolutiveTestNumberAccessorConfig
    ): Promise<EvolutiveTestNumberAccessor> {
        return Promise.resolve(new EvolutiveTestNumberAccessor(config, this.getApplication()));
    }

    /** EvolutiveTestNumber2DAccessor  creator function */
    private createEvolutiveTestNumber2DAccessor(
        config: EvolutiveTestNumber2DAccessorConfig
    ): Promise<EvolutiveTestNumber2DAccessor> {
        return Promise.resolve(new EvolutiveTestNumber2DAccessor(config, this.getApplication()));
    }

    /** EvolutiveTestSeries2DAccessor  creator function */
    private createEvolutiveTestSeries2DAccessor(
        config: EvolutiveTestSeries2DAccessorConfig
    ): Promise<EvolutiveTestSeries2DAccessor> {
        return Promise.resolve(new EvolutiveTestSeries2DAccessor(config, this.getApplication()));
    }

    /** EvolutiveTestNumberArrayAccessor  creator function */
    private createEvolutiveTestNumberArrayAccessor(
        config: EvolutiveTestNumberArrayAccessorConfig
    ): Promise<EvolutiveTestNumberArrayAccessor> {
        return Promise.resolve(new EvolutiveTestNumberArrayAccessor(config, this.getApplication()));
    }

    // /** Handled types of instance creation */
    // public getHandledTypes(): string[] {
    //     return [
    //         EvolutiveTestNumberAccessor.TEST_EVOLUTIVE_NUMBER_TYPE,
    //         EvolutiveTestNumber2DAccessor.TEST_EVOLUTIVE_NUMBER2D_TYPE,
    //         EvolutiveTestSeries2DAccessor.TEST_EVOLUTIVE_SERIES2D_TYPE,
    //         EvolutiveTestNumberArrayAccessor.TEST_EVOLUTIVE_NUMBERARRAY_TYPE
    //     ];
    // }

    // /** instance creation factory function */
    // public createInstance(config: AccessorConfig): Accessor<any> {
    //     switch (config.type) {
    //         case EvolutiveTestNumberAccessor.TEST_EVOLUTIVE_NUMBER_TYPE:
    //             return new EvolutiveTestNumberAccessor(config as EvolutiveTestNumberAccessorConfig);
    //         case EvolutiveTestNumber2DAccessor.TEST_EVOLUTIVE_NUMBER2D_TYPE:
    //             return new EvolutiveTestNumber2DAccessor(config as EvolutiveTestNumber2DAccessorConfig);
    //         case EvolutiveTestSeries2DAccessor.TEST_EVOLUTIVE_SERIES2D_TYPE:
    //             return new EvolutiveTestSeries2DAccessor(config as EvolutiveTestSeries2DAccessorConfig);
    //         case EvolutiveTestNumberArrayAccessor.TEST_EVOLUTIVE_NUMBERARRAY_TYPE:
    //             return new EvolutiveTestNumberArrayAccessor(config as EvolutiveTestNumberArrayAccessorConfig);
    //     }
    //     return null;
    // }
}
