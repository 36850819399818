/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * ToPaz basic accessors types
 */
export class AccessorType {
    public static readonly QUEUED_ACCESSOR_TYPE: string = 'QueuedAccessorType';
    public static readonly DEFAULT_CONVERTER_ACCESSOR_TYPE: string = 'DefaultConverterAccessorType';
    public static readonly STRING_VALUE_ACCESSOR_TYPE: string = 'StringValueAccessorType';
    public static readonly NUMBER_STATIC_VALUE_ACCESSOR_TYPE: string = 'NumberStaticValueAccessorType';
    public static readonly STRING_STATIC_VALUE_ACCESSOR_TYPE: string = 'StringStaticValueAccessorType';
    public static readonly GENERIC_STATIC_VALUE_ACCESSOR_TYPE: string = 'GenericStaticValueAccessorType';
    public static readonly STRING_WEBSOCKET_ACCESSOR_TYPE: string = 'StringWebsocketAccessorType';
    public static readonly NUMBER_WEBSOCKET_ACCESSOR_TYPE: string = 'NumberWebsocketAccessorType';
    public static readonly NUMBER_DISCRETIZER_ACCESSOR_TYPE: string = 'NumberDiscretizerAccessorType';
    public static readonly STRING_REMOTE_ACCESSOR_TYPE: string = 'RemoteAccessorType';
    public static readonly REMOTE_ACCESSOR_TYPE: string = 'StringRemoteAccessorType';
    public static readonly JSON_REMOTE_ACCESSOR_TYPE: string = 'JsonRemoteAccessorType';
    public static readonly NUMBER_EVOLUTIVE_TEST_ACCESSOR_TYPE: string = 'NumberEvolutiveTestAccessorType';
    public static readonly NUMBER2D_EVOLUTIVE_TEST_ACCESSOR_TYPE: string = 'Number2DEvolutiveTestAccessorType';
    public static readonly NUMBERARRAY_TEST_EVOLUTIVE_ACCESSOR_TYPE: string = 'NumberArrayEvolutiveTestAccessorType';
    public static readonly SERIES2D_TEST_EVOLUTIVE_ACCESSOR_TYPE: string = 'Series2DEvolutiveTestAccessorType';
}

/**
 * TopaZ basic accessor categories types
 */
export class AccessorCategoryType {
    public static readonly ACCESSOR_CATEGORY_TYPE = 'AccessorCategoryType';
    public static readonly WEBSOCKET_ACCESSOR_CATEGORY_TYPE = 'WebsocketAccessorCategoryType';
    public static readonly VALUE_ACCESSOR_CATEGORY_TYPE = 'ValueAccessorCategoryType';
    public static readonly STATIC_ACCESSOR_CATEGORY_TYPE = 'StaticAccessorCategoryType';
    public static readonly DISCRETIZER_ACCESSOR_CATEGORY_TYPE = 'DiscretizerAccessorCategoryType';
    public static readonly EVOLUTIVE_TEST_ACCESSOR_CATEGORY_TYPE = 'EvolutiveTestAccessorCategoryType';
}

// Data convert Function: convert DataType to 'string'
export type DataConvertToString<DataType> = (v: DataType) => string;
// Data convert Function: convert 'string' to DataType
export type DataConvertFromString<DataType> = (exportedV: string) => DataType;
// Data Equal Function (NOT NEEDED anymore since comparison are all done with exported values (strings))
//convert type DataEqualFunction<DataType> = ( v1: DataType, v2: DataType) => boolean;

/**
 * Default convert import/export functions
 */
export class AccessorValueImportExport {
    public static readonly NULL: string = 'NULL';

    /** convert Number */
    public static convertFromStringNumber: DataConvertFromString<number> = (value: string): number => {
        return Number(value);
    };
    /** convert Number */
    public static convertToStringNumber: DataConvertToString<number> = (value: number): string => {
        return JSON.stringify(value);
    };

    /** convert Void */
    public static convertFromStringDefault: DataConvertFromString<any> = (value: string): any => {
        return JSON.parse(value);
    };
    /** convert Void */
    public static convertToStringDefault: DataConvertToString<any> = (value: any): string => {
        return JSON.stringify(value);
    };

    /** convert String */
    public static convertFromStringString: DataConvertFromString<string> = (value: string): string => {
        if (!value) return AccessorValueImportExport.NULL;
        return value;
    };
    /** convert String */
    public static convertToStringString: DataConvertToString<string> = (value: string): string => {
        if (!value) return null;
        if (value === AccessorValueImportExport.NULL) return null;
        return value;
    };

    /** convert Date */
    public static convertFromStringDate: DataConvertFromString<Date> = (value: string): Date => {
        if (!value) return null;
        if (value === AccessorValueImportExport.NULL) return null;
        return new Date(Number(value));
    };
    /** convert Date */
    public static convertToStringDate: DataConvertToString<Date> = (value: Date): string => {
        if (!value) return AccessorValueImportExport.NULL;
        return String(value.getTime());
    };

    /** convert Dictionary */
    public static convertFromStringDictionary: DataConvertFromString<any> = (value: string): any => {
        if (!value) return null;
        if (value === AccessorValueImportExport.NULL) return null;
        return JSON.parse(value);
    };
    /** convert disctionary */
    public static convertToStringDictionary: DataConvertToString<any> = (value: any): string => {
        if (!value) return AccessorValueImportExport.NULL;
        return JSON.stringify(value);
    };

    /** convert [number, number] */
    public static convertFromStringNumber2D: DataConvertFromString<[number, number]> = (
        value: string
    ): [number, number] => {
        if (!value) return null;
        if (value === AccessorValueImportExport.NULL) return null;
        const v: string = JSON.parse(value);
        return [Number(v[0]), Number(v[1])];
    };
    /** convert [number, number] */
    public static convertToStringNumber2D: DataConvertToString<[number, number]> = (
        value: [number, number]
    ): string => {
        if (!value) return AccessorValueImportExport.NULL;
        return JSON.stringify(value);
    };

    /** convert number [] */
    public static convertFromStringNumberArray: DataConvertFromString<number[]> = (value: any): number[] => {
        return AccessorValueImportExport.convertFromStringGenericArray(
            value,
            AccessorValueImportExport.convertFromStringNumber
        );
    };
    /** convert number[] */
    public static convertToStringNumberArray: DataConvertToString<number[]> = (value: number[]): string => {
        return AccessorValueImportExport.convertToStringGenericArray(
            value,
            AccessorValueImportExport.convertToStringNumber
        );
    };

    /** convert string [] */
    public static convertFromStringStringArray: DataConvertFromString<string[]> = (value: any): string[] => {
        return AccessorValueImportExport.convertFromStringGenericArray(
            value,
            AccessorValueImportExport.convertFromStringString
        );
    };
    /** convert string[] */
    public static convertToStringStringArray: DataConvertToString<string[]> = (value: string[]): string => {
        return AccessorValueImportExport.convertToStringGenericArray(
            value,
            AccessorValueImportExport.convertToStringString
        );
    };

    /** convert dictionary array */
    public static convertFromStringDictionaryArray: DataConvertFromString<any[]> = (value: string): any[] => {
        return AccessorValueImportExport.convertFromStringGenericArray(
            value,
            AccessorValueImportExport.convertFromStringDictionary
        );
    };
    /** convert dictionary array */
    public static convertToStringDictionaryArray: DataConvertToString<any[]> = (value: any[]): string => {
        return AccessorValueImportExport.convertToStringGenericArray(
            value,
            AccessorValueImportExport.convertToStringDictionary
        );
    };

    /** convert Dictionary array */
    // public static convertToStringArray: DataConvertToString<DataType[]> = (values: DataType[], exportFunction: DataConvertToString<DataType>): string => {
    public static convertToStringGenericArray<DataType>(
        values: DataType[],
        toString: DataConvertToString<DataType>
    ): string {
        if (!values) return AccessorValueImportExport.NULL;
        const valueArray: string[] = [];
        values.forEach((value: DataType) => {
            valueArray.push(toString(value));
        });
        return JSON.stringify(valueArray);
    }
    /** convert disctionary array */
    //        public static convertFromStringArray<DataType>(value: string, importFunction: DataConvertFromString<DataType>): DataType[] {
    public static convertFromStringGenericArray<DataType>(
        value: string,
        fromString: DataConvertFromString<DataType>
    ): DataType[] {
        if (!value) return null;
        if (value === AccessorValueImportExport.NULL) return null;
        const objectArray: DataType[] = [];
        const jsonArray: string[] = JSON.parse(value);
        jsonArray.forEach((object) => {
            objectArray.push(fromString(object));
        });
        return objectArray;
    }

    // /** equal string[] */
    // public static equalValueStringArray( v1: string[], v2: string[] ): boolean {
    //     return CSAccessorValueImportExport.equalValueArray( v1, v2, CSAccessorValueImportExport.equalValueString );
    // }

    // /** equal dictionary */
    // public static equalValueDictionaryArray( v1: any[], v2: any[] ): boolean {
    //     return CSAccessorValueImportExport.equalValueArray( v1, v2, CSAccessorValueImportExport.equalValueDictionary );
    // }
    // /** equal [number, number] */
    // public static equalValueNumber2D(v1: [number, number], v2: [number, number]): boolean {
    //     if (v1 == null && v2 == null) return true;
    //     if (v1 == null && v2 != null) return false;
    //     if (v1 != null && v2 == null) return false;
    //     return v1[0] == v2[0] && v1[1] == v2[1];
    // };
    // /** equal number[] */
    // public static equalValueNumberArray(v1: number[], v2: number[]): boolean {
    //     return CSAccessorValueImportExport.equalValueArray(v1, v2, CSAccessorValueImportExport.equalValueNumber);
    // }
    // /** convert Number */
    // public static equalValueNumber( v1: number, v2: number ): boolean {
    //     return v1 === v2;
    // };
    // /** equal String */
    // public static equalValueString( v1: string, v2: string ): boolean {
    //     if ( v1 == null && v2 == null ) return true;
    //     if ( v1 == null && v2 != null ) return false;
    //     if ( v1 != null && v2 == null ) return false;
    //     return v1 == v2;
    // };
    // /** equal disctionary array */
    // public static equalValueArray<DataType>( v1: DataType[], v2: DataType[], equalFunction: DataEqualFunction<DataType> ): boolean {
    //     if ( v1 == null && v2 == null ) return true;
    //     if ( v1 != null && v2 == null ) return false;
    //     if ( v1 == null && v2 != null ) return false;
    //     if (v1.length != v2.length) return false;
    //     for (let index = 0; index < v1.length; index++) {
    //         if ( !equalFunction( v1[index], v2[index] ) ) return false;
    //     }
    //     return true;
    // };
    // /** equal Date */
    // public static equalValueDate( v1: Date, v2: Date ): boolean {
    //     if ( v1 == null && v2 == null ) return true;
    //     if ( v1 == null && v2 != null ) return false;
    //     if ( v1 != null && v2 == null ) return false;
    //     return v1.getTime() == v2.getTime();
    // };

    // /** equal Dictionary */
    // public static equalValueDictionary( v1: any, v2: any ): boolean {
    //     if ( v1 == null && v2 == null ) return true;
    //     if ( v1 == null && v2 != null ) return false;
    //     if ( v1 != null && v2 == null ) return false;
    //     return JSON.stringify(v1) == JSON.stringify(v2);
    // };
}
