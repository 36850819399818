/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AccessorEvent } from '../../../../tpz-access/tpz-access-event';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { deepCopy } from '../../../tools/deep-copy';
import { ValueAccessor } from '../../../../tpz-access/tpz-access-value';
import { TpzApplicationCategories } from '../../../tpz-application-types';

// item type (used in default configuration and Item Instance constructor)
const LABELED_VIEW_TYPE: string = 'LabeledViewType';

/**
 * Labeled displayer configuration
 */
export interface LabeledViewConfig extends TpzViewConfig {
    label?: string; // accessor id
    displayLabel?: boolean;
    decimalPrecision?: number;
}

/**
 * Default Labeled displayer configuration
 */
export const defaultLabeledViewConfig: LabeledViewConfig = {
    ...defaultTpzViewConfig,
    type: LABELED_VIEW_TYPE,
    id: undefined,
    label: 'Value',
    displayLabel: true,
    decimalPrecision: 5
};

/**
 * Display an accessor in an INPUT HTML tag with a label.
 * If label is null, use the accessor name
 */
export class LabeledView extends TpzView {
    private mainContainer: HTMLDivElement = null;
    private labelSpan: HTMLSpanElement = null;
    private valueInput: HTMLInputElement = null;
    private readonly valueToString: (v: any, precision?: number) => string = null;

    public static readonly LABELED_VIEW_TYPE = LABELED_VIEW_TYPE;

    /** Constructor */
    constructor(
        config: LabeledViewConfig,
        application: TpzApplication,
        valueToString: (v: any) => string = JSON.stringify
    ) {
        super(deepCopy(defaultLabeledViewConfig, config), LabeledView.LABELED_VIEW_TYPE, application);
        this.valueToString = valueToString;
    }

    /** Label getter */
    public getLabel(): string {
        return this.getConfig().label;
    }

    /**
     * If any accessor changes, request an update
     * @param event
     */
    public onAccessorChange(event: AccessorEvent): boolean {
        this.updateUI();
        return true;
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): LabeledViewConfig {
        return super.getConfig() as LabeledViewConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: LabeledViewConfig = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (
            config?.label !== newConfig?.label ||
            config?.displayLabel !== newConfig?.displayLabel ||
            config?.decimalPrecision !== newConfig?.decimalPrecision
        ) {
            this.invalidateUI();
            this.updateUI();
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }
    /**
     * Request update
     * @param force
     */
    public updateRequest(force: boolean): boolean {
        return super.requestUpdate(force);
    }

    /**
     * Generate UI
     * @param parent parent to be inserted in
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!parent) throw new Error('parent is not defined');
        if (!parent.contains(this.getMainContainer())) parent.appendChild(this.getMainContainer());
        return true;
    }

    /**
     * Input label getter
     */
    public getDisplayLabel(): HTMLSpanElement {
        if (!this.labelSpan) {
            // TODO: use TpzApplicationUI to create label
            this.labelSpan = TpzApplicationUI.createLabel({ label: this.getLabel() });
            this.mainContainer.appendChild(this.labelSpan);
        }
        return this.labelSpan;
    }

    /**
     * Text Input getter
     */
    public getInputValue(): HTMLSpanElement {
        if (!this.valueInput) {
            this.valueInput = TpzApplicationUI.createTextInput({ value: '' });
            this.mainContainer.appendChild(this.valueInput);
        }

        return this.valueInput;
    }

    /**
     * Main UI Container getter
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('topaz-container');
            this.mainContainer.classList.add('topaz-value-display');

            if (!this.getConfig().displayLabel) {
                this.mainContainer.appendChild(this.getDisplayLabel());
            }

            // FIXME: handle different type of inputs
            this.mainContainer.appendChild(this.getInputValue());
            this.updateUI();
        }
        return this.mainContainer;
    }

    /**
     * Generic UI getter
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Delete UI elements
     */
    public invalidateUI(): void {
        this.mainContainer = null;
        this.valueInput = null;
        this.labelSpan = null;
    }

    /**
     * update UI
     */
    public updateUI(): void {
        this.getAccessor()?.then((accessor: ValueAccessor<any>) => {
            const value: number = accessor.getValue();
            if (!this.valueInput) return;
            if (value) this.valueInput.value = this.valueToString(value, this.getConfig().decimalPrecision);
            else this.valueInput.value = 'null';
            const name: string = accessor.getId();
            if (name && this.labelSpan) this.labelSpan.textContent = name;
        });
        super.updateUI();
    }

    public static defaultValueToString(v: any, decimalPrecision: number): string {
        if (['number', 'Number'].includes(typeof v)) return (v as number).toFixed(decimalPrecision);
        return JSON.stringify(v);
    }
}

/**
 * Factory handling LabeledView creation
 */
export class LabeledViewFactory extends TpzApplicationFactory {
    private static readonly LABELED_VIEW_FACTORY_TYPE: string = 'LabeledViewFactory';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(LabeledViewFactory.LABELED_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(LabeledView.LABELED_VIEW_TYPE, this.createLabeledView.bind(this), defaultLabeledViewConfig);
        this.addCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY);
    }

    /** LabeledView creator function */
    private createLabeledView(config: LabeledViewConfig): Promise<LabeledView> {
        return Promise.resolve(
            new LabeledView(config, this.getApplication(), (v: any) =>
                LabeledView.defaultValueToString(v, config.decimalPrecision)
            )
        );
    }
}
