/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Generic Event management.
 * LiteEventClass is able to register/unregister "listeners" = "handler functions"
 * it can "trigger" events = call listener"s handlers
 */

export type EventCallback<EventType> = (data: EventType) => boolean;

/**
 * Event Manager class
 */
export class EventManager<EventType> {
    // multiple callbacks can be assigned to a single ID
    // when the ID is unregistered all associated callbacks are removed at once
    private callbacks: { [id: string]: EventCallback<EventType>[] } = {};
    private cycleCount: number = 0;
    private maxCycleCount: number = 1000;

    /**
     * Constructor
     */
    constructor() {
        this.callbacks = {};
    }

    /**
     * Set the maximum number of simultaneous events
     * @param maxCount
     */
    public setMaxCycleCount(maxCount: number): void {
        this.maxCycleCount = maxCount;
    }

    /**
     * checks if the cycle count is below the max cycle count and is incremented
     * @returns
     */
    private incrementCycleCount(): boolean {
        if (this.cycleCount >= this.maxCycleCount) {
            return false;
        }
        this.cycleCount++;
        return true;
    }

    /**
     * check if it can decrement the cycle count
     */
    private decrementCycleCount(): void {
        if (this.cycleCount <= 0) {
            throw new Error('Cycle count can not be decremented ! ' + this.cycleCount + ' <= 0 !! ');
        }
        this.cycleCount--;
    }

    /**
     * Register a new message listener
     * @param callback message listener
     */
    public register(id: string, callback: EventCallback<EventType>): boolean {
        if (!id) throw new Error('Illegal argument registering event callback with a null ID');
        if (!this.callbacks) this.callbacks = {};
        if (!this.callbacks[id]) this.callbacks[id] = [];
        this.callbacks[id].push(callback);
        return true;
    }

    /**
     * Unregister a message listener
     * @param callback message listener
     */
    public unregister(id: string): boolean {
        if (!this.callbacks) return false;
        if (!this.callbacks[id]) return false;
        // check callback number (predicate)
        // let callbackCount = this.callbacks.length;
        delete this.callbacks[id];
        // // FIXME DEBUG: removing a non existing callback should be an error
        // if (this.callbacks.length != callbackCount - 1) {
        //     console.error("Ask to remove a callback which doesn't exist in event manager: " + this.callbacks.length + " != " + callbackCount + "-1");
        // }
        return true;
    }

    // safely handles circular references
    private stringify(obj: any, indent = 2): string {
        let cache: any[] = [];
        const retVal = JSON.stringify(
            obj,
            (key, value) =>
                typeof value === 'object' && value !== null
                    ? cache.includes(value)
                        ? undefined // Duplicate reference found, discard key
                        : cache.push(value) && value // Store value in our collection
                    : value,
            indent
        );
        cache = null;
        return retVal;
    }

    /**
     * fire a new event to all listener's callbacks
     * @param event event to fire
     */
    public trigger(event: EventType): Promise<void> {
        if (!event) return Promise.resolve(null);

        // // DEBUG
        // if ((event as any)['type'])
        //     console.warn(
        //         `Firing event type ${(event as any)['type']} content = ${this.stringify((event as any)['content'])}`
        //     );
        // // else console.warn(`Firing event ${this.stringify(event as any)}`);
        // // DEBUG

        if (this.getAllListenerIds().length === 0) return Promise.resolve();
        try {
            if (!this.incrementCycleCount()) {
                console.error(`cyclic event: ${JSON.stringify(event)}`);
                console.trace();
                Promise.reject(
                    new Error(
                        `Max Cyclic ${JSON.stringify(event)} recursive triggering reached: ${
                            this.maxCycleCount
                        }. Stop cycle. Events are not treated anymore. Use resetTriggering()`
                    )
                );
            }
            const promises: Promise<void>[] = [];
            Object.entries(this.callbacks).forEach(([id, callbacks]) => {
                callbacks.forEach((callback: EventCallback<EventType>) => {
                    // NOTE: we use setTimeout(0) instead of Promise intentionally
                    // setTimeout has a higher 'priority' than promises (https://www.youtube.com/watch?v=cCOL7MC4Pl0)
                    // setTimeout defines a mAcrotask, Promises define mIcrotasks
                    // Macrotasks are resolve before microtasks
                    const callbackPromise: Promise<void> = Promise.resolve()
                        .then(() => {
                            callback(event);
                        })
                        .catch((reason: any) => {
                            throw reason;
                        });
                    promises.push(callbackPromise);
                });
            });

            return Promise.all(promises).then(() => {
                this.decrementCycleCount();
                return null;
            });
        } catch (error) {
            console.error(error);
            this.decrementCycleCount(); // decrementation of the cycle count if there is an error;
            throw error;
        }
    }

    /**
     * Reset cyclecount inner data. This method can be called if a "Max Cyclic events recursive triggering reached" error
     * as rised. This allows events to be fired again. But if cycle is reached there may be an infinite loop somewhere...
     */
    public resetTriggering(): void {
        this.cycleCount = 0;
    }
    /**
     * Get the list a listener's callbacks for a given listener
     */
    public getCallBacksById(id: string): EventCallback<EventType>[] {
        return this.callbacks[id];
    }

    /**
     * Get the list a listener's callbacks for a given listener
     */
    public getAllListenerIds(): string[] {
        return Object.keys(this.callbacks);
    }
}
