/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemEvent } from '../tpz-catalog/tpz-catalog-core';

/**
 * Accessor specific events
 */
export interface AccessorEvent extends ItemEvent {
    type: string;
    sourceId?: string;
    content?: any;
}

//////////////////////////////////////////// Generic Accessor ///////////////////////////////////////////////
/**
 * Generic class which gives access to data.
 * Implementations can use Direct access, DB reading, WebSocket connection, etc.
 * Accessors must implement their own AccessorConfig in order to be created using
 * an AccessorFactory
 */

export class AccessorEventType {
    public static readonly CREATION: string = 'ACCESSOR_CREATION'; // Accessor Creation
    public static readonly DATA: string = 'ACCESSOR_DATA'; // new Data stored
    public static readonly CONNECTING: string = 'ACCESSOR_CONNECTING'; // Accessor connecting
    public static readonly DISCONNECTING: string = 'ACCESSOR_DISCONNECTING'; // Accessor disconnecting
    public static readonly ERROR: string = 'ACCESSOR_ERROR';
    public static readonly DISCONNECTED: string = 'ACCESSOR_DISCONNECTED'; // Accessor disconnected
    public static readonly CONNECTED: string = 'ACCESSOR_CONNECTED'; // Accessor connected
    public static readonly HARD_UPDATE_REQUESTED: string = 'HARD_UPDATE_REQUESTED'; // {itemId: string }
    public static readonly LIGHT_UPDATE_REQUESTED: string = 'LIGHT_UPDATE_REQUESTED'; // {itemId: string }
}
