/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { GenericItemFactory } from '../tpz-catalog/tpz-item-factory';
import { ItemCatalog } from '../tpz-catalog/tpz-catalog-core';
import { TpzApplication } from './tpz-application-core';
import { TpzCatalogCategories } from '../tpz-catalog/tpz-catalog-types';

/**
 * ToPaZ Application Factory
 */
export abstract class TpzApplicationFactory extends GenericItemFactory {
    private readonly application: TpzApplication = null;

    /**
     * Constructor
     * @param displayerCatalog displayer catalog
     */
    constructor(factoryType: string, application: TpzApplication) {
        super(factoryType);
        this.application = application;
        this.addCategory(TpzCatalogCategories.TPZ_ITEM_CATEGORY);
    }

    /**
     * Application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /** Application Catalog getter */
    public getCatalog(): ItemCatalog {
        return this.application?.getItemCatalog();
    }
}
