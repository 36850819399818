/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemFactory } from './tpz-item-factory';
import { ItemConfig } from './tpz-item-config';
import { ItemInstance } from './tpz-item-core';
import { ItemFactoryManager } from './tpz-catalog-manager';
import { TpzAddOn } from '../tpz-application/addons/addons-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../tpz-application/tpz-application-event';
import { TpzApplication } from '../tpz-application/tpz-application-core';
import { TpzApplicationComponent } from '../tpz-application/tpz-application-component';
import { deepCopy, deepEquals } from '../tpz-application/tools/deep-copy';
import { uuidv4 } from '../tpz-application/tools/uuid';

/**
 * Catalogable event interface: contains type, source and a generic content
 */
export interface ItemEvent {
    type: string;
    sourceId?: string;
    content?: any;
}

/**
 * ItemInstance is a runnable element which can be in different states
 */
export enum ItemInstanceState {
    UNKNOWN = 'UNKNOWN', // unstable state
    INITIALIZING = 'INITIALIZING', // first initialization before anything (constructor)
    INITIALIZED = 'INITIALIZED', // first initialization before anything (ready for start)
    STARTING = 'STARTING', // start() method has been called
    RUNNING = 'RUNNING', // start() method is finished, instance is active
    PAUSING = 'PAUSING', // pause() method has been called
    PAUSED = 'PAUSED', // pause() method is finished, instance is inactive
    STOPPING = 'STOPPING', // stop() method has been called.
    STOPPED = 'STOPPED', // stop() method is finished, instance is inactive
    STARTING_ERROR = 'STARTING_ERROR' // method start() returns an error
}

/**
 * Default events for catalogable objects
 */
export class ItemEventType {
    public static readonly STATE_CHANGED = 'CATALOGABLE EVENT STATE CHANGED';
    public static readonly ERROR_OCCURRED = 'CATALOGABLE ERROR OCCURRED';
}

/**
 * A Catalog manages object instances. It uses a FactoryFactory to create instances.
 * Object configuration are registered in this catalog and are used to generate instances (if factories can handle such configuration)
 * - ItemInstance: created object instances
 * - ItemConfig: configuration used to generate instances
 * - CatalogFactory: factories handling instance generation from config
 * A FactoryManager is a collection of factories that are able to handle some configuration objects to create instances
 **/
export class ItemCatalog extends TpzApplicationComponent {
    // registered configuration are used to generate instances
    // configuration are the only exported elements
    private registeredConfigs: { [id: string]: ItemConfig } = {};
    // generatedInstances is the storage of created instances from registered configs
    private generatedInstances: { [id: string]: ItemInstance } = {};
    // generatedInstances is the storage of created instances from registered configs
    private promisedInstances: { [id: string]: Promise<ItemInstance> } = {};
    // factory manager is the class able to create instances
    private factoryManager: ItemFactoryManager = null;

    /**
     * CatalogInstance needs a catalogFactory to create instances
     */
    constructor(app: TpzApplication, id: string) {
        super(app, id);
        this.clear();
    }

    /**
     * update the full content of this item catalog
     * TODO: We may use a ItemCatalogState instead of an array of configs to simplify further evolution...
     * @param itemCatalogConfigs
     */
    public setState(itemCatalogConfigs: ItemConfig[]) {
        this.clear();
        itemCatalogConfigs?.forEach((itemConfig: ItemConfig) => {
            this.registerInstanceConfig(itemConfig);
        });
    }

    /**
     * clear all registered configuration and associated caches
     */
    public clear(): void {
        this.registeredConfigs = {};
        this.generatedInstances = {};
        this.promisedInstances = {};
    }

    /**
     * factory manager lazy getter
     */
    public getFactoryManager(): ItemFactoryManager {
        if (!this.factoryManager) {
            this.factoryManager = new ItemFactoryManager();
            this.factoryManager.setLogger(this.getLogger());
        }
        return this.factoryManager;
    }

    /**
     * Get all registered items configurations
     */
    public getRegisteredConfigs(): { [id: string]: ItemConfig } {
        return this.registeredConfigs;
    }

    /**
     * Get all registered configurations Ids
     */
    public getRegisteredConfigIds(): string[] {
        if (!this.registeredConfigs) return null;
        return Object.keys(this.registeredConfigs);
    }

    /**
     * Get a registered instance configuration by its Id
     */
    public getRegisteredConfigById(id: string): ItemConfig {
        return this.registeredConfigs[id];
    }

    /**
     * Returns a COPY of the requested item configuration which is CURRENTLY in use
     *
     * - if an instance is already created: returns the instance configuration
     * - else if an instance is in creation, wait for the creation then returns its configuration
     * - else if a configuration is registered in catalog: returns this one
     * - else returns null
     * (this method should not be confuse with getRegisteredItemById() which looks only in REGISTERED configurations)
     * @param itemId requested item id
     * @returns configuration currently in use or null if not found
     */
    public getCurrentConfig(itemId: string): Promise<ItemConfig> | null {
        const item: ItemInstance = this.getAlreadyCreatedInstance(itemId);
        if (item) {
            return Promise.resolve(item.getConfig());
        }
        const itemInCreation: Promise<ItemInstance> = this.getCreatedOrInProgressInstance(itemId);
        if (itemInCreation) {
            return itemInCreation.then((item: ItemInstance) => item.getConfig());
        }
        const config: ItemConfig = this.getRegisteredConfigById(itemId);
        if (config) return Promise.resolve(config);
        return null;
    }

    /**
     * Register an instance which has been created without using the factory mechanism
     * @param item item instance to be added to registered instance
     * @returns true/false
     */
    public registerExistingInstance(item: ItemInstance): boolean {
        if (!item) return false;
        const config: ItemConfig = item.getConfig();
        if (!config) throw new Error('Cannot register an existing instance which has no configuration');
        if (!config.id) throw new Error('Cannot register an existing instance which has no ID');
        // register configuration
        this.registerInstanceConfig(config);
        // add instance to already generated instances
        this.generatedInstances[config.id] = item;
        return true;
    }

    /**
     * Register a new instance configuration or update config if already in catalog
     * fires ITEM_CONFIG_REGISTERED or ITEM_CONFIG_UPDATED
     * id must be set
     * @param config instance configuration to register/update
     * @returns true if a modification has been done in catalog (if true an event has been fired)
     */
    private registerInstanceConfig(config: ItemConfig): boolean {
        if (!config) return false;
        if (!config.id) throw new Error(`Cannot register item configuration with no ID: ${JSON.stringify(config)}`);
        if (!config.type) {
            throw new Error(`Cannot register item #${config.id} configuration with no TYPE: ${JSON.stringify(config)}`);
        }
        const existingConfig: ItemConfig = this.registeredConfigs[config.id];
        let update: boolean = false;
        if (existingConfig) {
            // if existing config is equal, do nothing, just exit
            if (deepEquals(config, existingConfig)) {
                return false;
            }
            update = true;
        } else {
            update = false;
        }
        this.registeredConfigs[config.id] = deepCopy(config);
        const error: string = this.checkConfigLoop(config.id);
        if (error) throw new Error(error);
        this.getLogger().debug(`registering item #${config.id} / ${config.type}`);
        this.fireEvent(
            update
                ? TpzApplicationEventType.CATALOG_ITEM_CONFIG_UPDATED
                : TpzApplicationEventType.ITEM_CONFIG_REGISTERED,
            { itemConfig: this.registeredConfigs[config.id] },
            []
        );
        return true;
    }

    /**
     * Check if given Item id dependency tree contains cycles
     * @param itemId
     */
    private checkConfigLoop(itemId: string): string {
        if (!itemId) return null;
        const itemConfig: ItemConfig = this.getRegisteredConfigById(itemId);
        if (!itemConfig) return null;
        return this.checkConfigLoopRec([itemConfig]);
    }

    /**
     * Check if given Item id dependency tree contains cycles
     * @param itemId
     */
    private checkConfigLoopRec(itemChildBranch: ItemConfig[]): string {
        // if branch length is 0 or 1 => ok
        if (!itemChildBranch || itemChildBranch.length <= 1) return null;
        const rootItemConfig: ItemConfig = itemChildBranch[0];
        if (!rootItemConfig) return 'Root Item is null';
        const lastItemConfig: ItemConfig = itemChildBranch[itemChildBranch.length - 1];
        if (!lastItemConfig) return 'last Item is null';
        // check for loops
        if (lastItemConfig.id == rootItemConfig.id) {
            return `A loop exists in configuration items: ${itemChildBranch.map((x) => x.id).join(' -> ')}`;
        }
        if (!lastItemConfig.childrenIds) return null;
        for (let i = 0; i < lastItemConfig.childrenIds.length; i++) {
            const childId: string = lastItemConfig.childrenIds[i];
            const childConfig: ItemConfig = this.getRegisteredConfigById(childId);
            const error: string = this.checkConfigLoopRec(itemChildBranch.concat(childConfig));
            if (error) return error;
        }
        return null;
    }

    /**
     * Unregister a  configuration by its ID
     */
    private unregisterConfigById(configId: string): boolean {
        if (!configId) return false;
        const config: ItemConfig = this.registeredConfigs[configId];
        if (!config) return false;
        delete this.registeredConfigs[configId];
        delete this.promisedInstances[configId];
        delete this.generatedInstances[configId];
        this.fireEvent(TpzApplicationEventType.ITEM_CONFIG_UNREGISTERED, { itemConfig: config }, []);
        return true;
    }

    /**
     * get registered instances with the given ids
     * If the id is not stored it is created using it's config on factories
     * @param ids instance id array
     */
    public getOrCreateInstancesByIds(ids: string[]): Promise<ItemInstance>[] {
        if (!ids) Promise.reject(new Error('Cannot get Or Create an Instance of a null set of IDs'));
        const promisedInstances: Promise<ItemInstance>[] = [];
        for (const instanceId of ids) {
            const promiseInstance: Promise<ItemInstance> = this.getOrCreateInstanceById(instanceId);
            if (promiseInstance) promisedInstances.push(promiseInstance);
        }
        return promisedInstances;
    }

    /**
     * Register the given config then create the instance
     */
    public getOrCreateInstanceByConfig(config: ItemConfig): Promise<ItemInstance> {
        if (!config) Promise.reject(new Error('Cannot get Or Create an Instance of a null configuration'));
        if (!config.id) {
            config.id = `default-catalog-id-${uuidv4()}`;
        }
        this.registerInstanceConfig(config);
        return this.getOrCreateInstanceById(config.id);
    }

    /**
     * get registered instance with the given id
     * If the id is not stored it is created using it's config on factories
     * @param id instance id
     */
    public getOrCreateInstanceById(id: string): Promise<ItemInstance> {
        if (!id) Promise.reject(new Error('Cannot get Or Create an Instance of a null ID'));
        // try to get registered item
        const instance: ItemInstance = this.generatedInstances[id];
        if (instance) return Promise.resolve(instance);

        // item is not yet created: get registered config
        const config: ItemConfig = this.registeredConfigs[id];
        if (!config) {
            return Promise.reject(
                new Error(
                    `Item configuration #${id} is not in catalog. Available Item IDS: ${Object.keys(
                        this.registeredConfigs
                    ).join(', ')}`
                )
            );
        }
        if (config.id != id) {
            return Promise.reject(
                new Error(
                    `Incoherency found in retrieving item #${id} retrieved item config id is not the right id !! : ${config.id}`
                )
            );
        }

        // look if there is already a creation promise in progress
        let promiseInstance: Promise<ItemInstance> = this.promisedInstances[id];
        if (promiseInstance) return promiseInstance;

        // create item using config
        promiseInstance = this.createInstance(config);
        if (!promiseInstance) return Promise.reject(`Unable to create instance ID #${id}`);
        // add generated item to the registered items
        this.promisedInstances[id] = promiseInstance
            .then((instance: ItemInstance) => {
                if (!instance) throw new Error(`Create instance #${id} returns a null value`);
                // stores instance for further identical calls
                this.generatedInstances[id] = instance;
                return instance;
            })
            .catch((reason: Error) => {
                this.getLogger().error(`An error occurred creating instance #${id}`);
                this.getLogger().error(`Error ${reason.name}: ${reason.message}`, reason);
                throw reason;
            });
        return this.promisedInstances[id];
    }

    /**
     * Get or create all instances from all configs registered in the catalog
     */
    public getOrCreateAllInstances(): Promise<ItemInstance>[] {
        const instances: Promise<ItemInstance>[] = [];
        for (const configId in this.registeredConfigs) {
            const instance: Promise<ItemInstance> = this.getOrCreateInstanceById(configId);
            if (instance) {
                instances.push(instance);
            } else {
                const config: ItemConfig = this.registeredConfigs[configId];
                if (!config) {
                    throw new Error(`config id: ${configId} is not registered`);
                }
                this.getLogger()?.error(`Cannot retrieve/create instance type: ${config.type}`);
                this.getLogger()?.error(`config = ${JSON.stringify(config)}`);
                this.getLogger()?.error(`catalog content: ${this.factoryManager.getInfo().join(', ')}`);
                throw new Error(`Cannot create instance for config id: ${configId}`);
            }
        }
        return instances;
    }

    /**
     * Get a generated instance by its ID (an already created instance).
     * This method does not try to create the instance from the config
     */
    public getAlreadyCreatedInstance(id: string): ItemInstance {
        return this.generatedInstances[id];
    }

    /**
     * Get a generated instance or a promise against a creation in progress by its ID
     * This method does not try to create the instance from the config
     * @id item ID already created or being created
     * @return a promise on instance if in progress, a resolve promise if already created, null if not created or being created
     */
    public getCreatedOrInProgressInstance(id: string): Promise<ItemInstance> {
        // order is important to avoid being trapped between the creation and the storage of the creation
        // begin by the creation in progress
        const inProgressCreationIntstance: Promise<ItemInstance> = this.promisedInstances[id];
        if (inProgressCreationIntstance) return inProgressCreationIntstance;
        const createdInstance: ItemInstance = this.generatedInstances[id];
        if (createdInstance) return Promise.resolve(createdInstance);
        return null;
    }

    /**
     * get all instances that have already been created
     * This method does not try to create instances from configs
     */
    public getAllAlreadyCreatedInstances(): ItemInstance[] {
        return Object.values(this.generatedInstances);
    }

    /**
     * load all add-ons from theyr IDs
     * @param addOnIds add-on ID array
     * @returns a promise on all add-ons load
     */
    private loadAddOns(addOnIds: string[]): Promise<TpzAddOn[]> {
        if (!addOnIds || addOnIds?.length == 0) return Promise.resolve([]);
        // load all registered add-ons
        const progressLoaderId: string = this.getApplication()
            .getProgressLoaderManager()
            .addProgress(null, `load add-on(s) #${addOnIds.join(', #')}`);
        const promises: Promise<TpzAddOn>[] = [];
        addOnIds.forEach((addOnId: string, index: number) => {
            this.getApplication()
                .getProgressLoaderManager()
                .addProgressDescription(progressLoaderId, `loading add-on #${addOnId}`);
            const loadAddOnPromise: Promise<TpzAddOn> = this.getApplication()
                .getAddOnManager()
                .loadAddOnById(addOnId)
                .then(() => {
                    this.getLogger().info(
                        `add-on #${addOnId} loaded from item #${this.getId()} (${index + 1} on ${addOnIds.length})`
                    );
                    return null;
                })
                .catch((reason: any) => {
                    this.getLogger().error(
                        `An error occurred loading add-on #${addOnId} when loading #${this.getId()} add-ons ${addOnIds
                            .map((x) => `#${x}`)
                            .join(', ')}`,
                        reason
                    );
                });
            promises.push(loadAddOnPromise);
        });
        return Promise.all(promises).finally(() => {
            this.getApplication().getProgressLoaderManager().endProgress(progressLoaderId);
        });
    }
    /**
     * Try top create an instance using the first factory handling the given config type
     * If no factory is found, it tries to load addons defined in the addOnIds configuration field
     * It doesn't stores the created instance
     *
     * @param config config used to generate the instance
     */
    private createInstance(config: ItemConfig): Promise<ItemInstance> {
        // try to get factory from Item Factory manager
        if (!config) return Promise.reject(new Error('Cannot create an instance with a null configuration'));
        if (!config.type) {
            return Promise.reject(new Error(`Cannot create an instance with a null item type. item id #${config.id}`));
        }
        const creationPromise: Promise<ItemInstance> = this.createInstanceFromRegisteredFactories(config);
        if (creationPromise) return creationPromise;
        // if not in registered factories, try to load add-ons and retry
        return this.loadAddOns(config.addOnIds).then(() => {
            const creationPromise2: Promise<ItemInstance> = this.createInstanceFromRegisteredFactories(config);
            if (!creationPromise2) {
                this.getFactoryManager()
                    .getRegisteredFactories()
                    .forEach((factory: ItemFactory) => {
                        this.getLogger().warn(
                            `Available Factory ${factory.getName()} handle types [${factory
                                .getHandledTypes()
                                .join(', ')}]`
                        );
                    });
                throw new Error(
                    `Unable to find a valid factory for #${config.id}. type ${config.type}. You may have forgotten to add an addOnIds in config or registering a specific Item Factory`
                );
            }
            return creationPromise2;
        });
    }

    /**
     * Loop over all registered factories and return a promise on instance
     * if multiple factories handle the given config type, the first one is used
     *
     * @param config config used to generate the instance
     * @return null if no factory handle the item type
     */
    private createInstanceFromRegisteredFactories(config: ItemConfig): Promise<ItemInstance> {
        // try to get factory from Item Factory manager
        if (!config) throw new Error('Cannot create an instance with a null configuration');
        const factory: ItemFactory = this.getFactoryManager().getHandlingItemFactory(config.type);
        // do not handle this type
        if (!factory) return null;

        const instancePromise: Promise<ItemInstance> = factory.createInstance(config);
        if (!instancePromise) {
            throw new Error(`Factory ${factory.getType()} handles type ${config.type} but returns a null instance`);
        }
        // check if the returned object is like a Promise (e.g. contains a '.then function)
        // this check prevents addons from returning unchecked objects
        if (typeof instancePromise.then != 'function') {
            throw new Error(
                `Factory ${factory.getType()} handles type ${
                    config.type
                } but returns an object which is not a Promise... API changed between Topaz 0.4.9 and 0.5.0`
            );
        }
        return instancePromise;
    }

    /**
     * Fires an Event 'CATALOG_ITEM_CONFIG_UPDATED' with the new config
     * @param parentConfig
     */
    private fireItemConfigUpdate(itemId: string): boolean {
        if (!itemId) {
            return false;
        }
        const itemConfig: ItemConfig = this.getRegisteredConfigById(itemId);
        if (!itemConfig) {
            this.getLogger().warn(`ConfigUpdate event requested but item #${itemId} is not in catalog...`);
            return false;
        }
        this.fireEvent(TpzApplicationEventType.CATALOG_ITEM_CONFIG_UPDATED, {
            itemConfig: itemConfig
        });
        return true;
    }

    /**
     * start an item from the catalog
     * if a parent is defined, first start parent before starting item
     */
    private startItem(itemId: string): Promise<void> {
        if (!itemId) throw new Error('itemId has to be defined when starting a child');
        return this.getOrCreateInstanceById(itemId).then((item: ItemInstance) => item.start());
    }

    /**
     * stop an item from the catalog
     */
    private stopItem(itemId: string): Promise<void> {
        if (!itemId) throw new Error('itemId has to be defined when asked to stop');
        const itemCreation: Promise<ItemInstance> = this.getCreatedOrInProgressInstance(itemId);
        if (!itemCreation) return Promise.resolve();
        return itemCreation.then((item: ItemInstance) => item.stop());
    }

    /**
     * Event Handler. This method can be Overloaded but do not forget to call super.onEvent if
     * the event is not Handled in your implementation !
     *
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                break;
            case TpzApplicationEventType.ITEM_CONFIG_REGISTER_REQUEST:
                TpzApplicationEventType.checkEvent(event, 'itemConfig');
                this.registerInstanceConfig(event.content.itemConfig);
                break;
            case TpzApplicationEventType.ITEM_CONFIG_UNREGISTER_REQUEST:
                TpzApplicationEventType.checkEvent(event, 'itemId');
                this.unregisterConfigById(event.content.itemId);
                break;
            case TpzApplicationEventType.ITEM_CONFIG_UPDATE_REQUEST:
                TpzApplicationEventType.checkEvent(event, 'itemConfig');
                this.registerInstanceConfig(event.content.itemConfig);
                break;
            case TpzApplicationEventType.INSTANCE_ITEM_CONFIG_UPDATED:
                TpzApplicationEventType.checkEvent(event, 'itemConfig');
                this.registerInstanceConfig(event.content.itemConfig);
                break;
            // case TpzApplicationEventType.ADD_CHILD_ITEM_REQUEST:
            //     TpzApplicationEventType.checkEvent(event, 'parentId', 'childId', 'start');
            //     this.addChildToItemConfig(event.content.parentId, event.content.childId);
            //     break;
            // case TpzApplicationEventType.REMOVE_CHILD_ITEM_REQUEST:
            //     TpzApplicationEventType.checkEvent(event, 'parentId', 'childId', 'stopParents');
            //     this.removeChildFromItemConfig(
            //         event.content.parentId,
            //         event.content.childId,
            //         event.content.stopParents
            //     );
            //     break;
            case TpzApplicationEventType.ITEM_STATE_CHANGED:
                // Save the item configuration each time it state changes
                TpzApplicationEventType.checkEvent(event, 'itemId', 'oldState', 'newState');
                if (event.content.newState === ItemInstanceState.RUNNING) {
                    this.getApplication()
                        .getItemCatalog()
                        .registerInstanceConfig(
                            this.getApplication()
                                .getItemCatalog()
                                .getAlreadyCreatedInstance(event.content.itemId)
                                .getConfig()
                        );
                }
                break;
            case TpzApplicationEventType.ITEM_START_REQUEST:
                TpzApplicationEventType.checkEvent(event, 'itemId');
                this.startItem(event.content.itemId);
                break;
            case TpzApplicationEventType.ITEM_STOP_REQUEST:
                TpzApplicationEventType.checkEvent(event, 'itemId');
                this.stopItem(event.content.itemId);
                break;
        }
        return super.onApplicationEvent(event);
    }
}
