/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { Logger } from '../../tpz-log/tpz-log-core';
import {
    SimulatedTimeDescriptor,
    SimulatedTimeEventType,
    SimulatedTimeHelper,
    SimulatedTimeRunner
} from './simulated-time';
import { TpzApplicationEventType } from '../tpz-application-event';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationEvent } from '../tpz-application-event';

/******************************************* DISPLAYER *******************************/

// item type (used in default configuration and Item Instance constructor)
const CLOCK_PROCESS_TYPE: string = 'ClockProcessType';

export type TimeChangedCallback = (time: number, live: boolean) => void;

/**
 * A time based object manages a time runner connected to an item
 */
export class TimeHandler {
    public static readonly TIMERUNNER_UPDATE_RATE: number = 1000; // time runner update rate in milliseconds

    private timeDescriptor: SimulatedTimeDescriptor = null; // simulated time descriptor
    private timeRunner: SimulatedTimeRunner = null; // time runner synchronized with time manager

    private readonly parentItem: ItemInstance = null;
    private readonly timezone: number = 0;
    private readonly location: string = 'Unknown';
    private readonly onTimeChanged: TimeChangedCallback = null;
    private readonly updateRate: number = TimeHandler.TIMERUNNER_UPDATE_RATE;

    /** Constructor */
    constructor(parentItem: ItemInstance, onTimeChanged: TimeChangedCallback, updateRate: number) {
        if (!parentItem) throw new Error('Illegal constructor parameter. Time handler parent cannot be null');
        this.parentItem = parentItem;
        this.updateRate = updateRate;
        this.parentItem.getApplication().getEventManager().register(this.getId(), this.onApplicationEvent.bind(this));
        this.onTimeChanged = onTimeChanged;
    }

    /**
     *
     * @returns id getter
     */
    public getId(): string {
        return this.parentItem.getId() + '-timehandler';
    }

    /**
     * Application getter (delegated method from item Parent)
     */
    public getApplication(): TpzApplication {
        return this.parentItem.getApplication();
    }

    /**
     * Logger getter (delegated method from item Parent)
     */
    public getLogger(): Logger {
        return this.parentItem.getLogger();
    }

    /**
     * Set the timerHandler update rate
     * @param updateRate time rate Time Handler self update (in milliseconds)
     */
    public setTimeUpdateRate(updateRate: number): void {
        this.getTimeRunner().setIntervalRate(updateRate);
    }

    /**
     * Get the currently stored time.
     */
    public getTimeDescriptor(): SimulatedTimeDescriptor {
        return this.timeDescriptor;
    }

    /**
     * Set a new Time Manager
     * @param timeManager
     */
    public setTimeDescriptor(timeDescriptor: SimulatedTimeDescriptor): void {
        if (SimulatedTimeHelper.timeDescriptorEquals(this.timeDescriptor, timeDescriptor)) return;
        this.timeDescriptor = { ...timeDescriptor };
        this.getTimeRunner().setTimeDescriptor(this.timeDescriptor);
        if (this.onTimeChanged) this.onTimeChanged(SimulatedTimeHelper.getSimulatedTime(this.timeDescriptor), false);
    }

    /**
     * Time Runner Id getter
     */
    private getTimeRunnerId(): string {
        return this.getId() + '-time-runner';
    }

    /**
     * Time Runner Lazy getter
     */
    private getTimeRunner(): SimulatedTimeRunner {
        if (!this.timeRunner) {
            this.timeRunner = SimulatedTimeHelper.createTimeRunner(
                this.getTimeRunnerId(),
                this.getApplication(),
                this.updatedTime.bind(this),
                this.updateRate,
                this.getTimeDescriptor()
            );
        }
        return this.timeRunner;
    }

    /**
     * time update only fires an event (for viewers to update UI)
     */
    private updatedTime(): void {
        if (this.onTimeChanged) this.onTimeChanged(SimulatedTimeHelper.getSimulatedTime(this.timeDescriptor), false);
    }

    /**
     * Start Clock displayer by creating an interval function requesting clock value
     */
    public start(): void {
        this.requestUpdate();
    }

    /**
     * Stop interval function
     */
    public stop(): void {
        this.timeRunner?.stopRunner();
        this.timeRunner = null;
        this.timeDescriptor = null;
    }

    /**
     * Request do nothing for clocks. Launch an event to request for new Time Descriptor
     * If a TimeManager plugin exists, the response will be received by event
     */
    public requestUpdate(force: boolean = false): boolean {
        this.getApplication().fireEvent(this.getId(), SimulatedTimeEventType.REQUEST_TIME_DESCRIPTOR, null, []);
        return true;
    }

    /**
     * React to application events.
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        // time management events are already ingested by Time Runner
        if (!event) return true;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case SimulatedTimeEventType.REBASE_SIMULATED_TIME:
                TpzApplicationEventType.checkEvent(event, 'timeDescriptor');
                this.setTimeDescriptor(event.content.timeDescriptor as SimulatedTimeDescriptor);
                return true;
            case SimulatedTimeEventType.LIVE_SIMULATED_TIME:
                {
                    TpzApplicationEventType.checkEvent(event, 'liveTime');
                    const time: number = event.content.liveTime as number;
                    if (this.onTimeChanged) this.onTimeChanged(time, true);
                }
                return true;
        }
        return false;
    }
}
