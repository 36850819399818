/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplicationUI } from '../../../tpz-application-ui';
import { ItemConfig } from '../../../../tpz-catalog/tpz-item-config';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { uuidv4 } from '../../../tools/uuid';
import JSONEditor from 'jsoneditor';
import { TpzApplicationCommander } from '../../../tpz-application-commander';
import { deepCopy } from '../../../tools/deep-copy';

// item type (used in default configuration and Item Instance constructor)
const ITEM_PARAMETER_VIEW_TYPE: string = 'ItemParameterViewType';

/**
 * Displayer Configuration
 */
export interface ItemParameterViewConfig extends TpzViewConfig {
    itemId?: string;
}

/**
 * Default Displayer Configuration
 */
export const defaultItemParameterViewConfig: ItemParameterViewConfig = {
    ...defaultTpzViewConfig,
    type: ITEM_PARAMETER_VIEW_TYPE,
    itemId: null,
    css: ['css/json-editor.css', 'css/item-parameter.css'].concat(defaultTpzViewConfig.css)
};

/**
 * Iterm Parameter View displaying parameters associated with config values .
 * Config values can be interactively modified in UI to control
 */
export class ItemParameterView extends TpzView {
    static readonly ITEM_PARAMETER_VIEW_TYPE: string = ITEM_PARAMETER_VIEW_TYPE;

    private mainContainer: HTMLDivElement = null;
    private topPanelDiv: HTMLDivElement = null;
    private bottomPanelDiv: HTMLDivElement = null;
    private leftPanelDiv: HTMLDivElement = null;
    private rightPanelDiv: HTMLDivElement = null;
    private treePanelDiv: HTMLDivElement = null;
    private applyButton: HTMLButtonElement = null;
    private closeButton: HTMLButtonElement = null;
    private editor: Promise<JSONEditor> = null;
    private editedConfig: ItemConfig = null;
    private editedItemId: string = null;

    /** Constructor */
    constructor(config: ItemParameterViewConfig, application: TpzApplication) {
        super(
            deepCopy(defaultItemParameterViewConfig, config),
            ItemParameterView.ITEM_PARAMETER_VIEW_TYPE,
            application
        );
    }

    /**
     * config getter specialization
     */
    public getConfig(): ItemParameterViewConfig {
        return super.getConfig() as ItemParameterViewConfig;
    }

    /**
     * Get the item instance associated with this parameter view
     */
    public getItemConfig(): ItemConfig {
        return this.getApplication().getItemCatalog().getRegisteredConfigById(this.getConfig().itemId);
    }

    /**
     * Create Clock UI in view container Div
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!parent) return false;
        parent.appendChild(this.getMainContainer());
        return true;
    }

    /**
     * Main container Div lazy getter
     */
    private getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({ classes: 'item-parameter' });
            const itemConfig: ItemConfig = this.getItemConfig();
            this.mainContainer.appendChild(this.getTopPanel());
            this.mainContainer.appendChild(this.getBottomPanel());
        }
        return this.mainContainer;
    }

    /**
     * Top Panel Div lazy getter
     */
    private getTopPanel(): HTMLDivElement {
        if (!this.topPanelDiv) {
            this.topPanelDiv = TpzApplicationUI.createDiv({ classes: 'top-panel' });
            this.topPanelDiv.appendChild(this.getLeftPanel());
            this.topPanelDiv.appendChild(this.getRightPanel());
        }
        return this.topPanelDiv;
    }

    /**
     * Left Panel Div lazy getter
     */
    private getBottomPanel(): HTMLDivElement {
        if (!this.bottomPanelDiv) {
            this.bottomPanelDiv = TpzApplicationUI.createDiv({ classes: 'bottom-panel' });
            this.bottomPanelDiv.appendChild(this.getApplyButton());
            this.bottomPanelDiv.appendChild(this.getCloseButton());
        }
        return this.bottomPanelDiv;
    }

    /**
     * Left Panel Div lazy getter
     */
    private getLeftPanel(): HTMLDivElement {
        if (!this.leftPanelDiv) {
            this.leftPanelDiv = TpzApplicationUI.createDiv({ classes: 'left-panel' });
            this.leftPanelDiv.appendChild(this.getTreePanel());
        }
        return this.leftPanelDiv;
    }

    /**
     * Right Panel Div lazy getter
     */
    private getRightPanel(): HTMLDivElement {
        if (!this.rightPanelDiv) {
            this.rightPanelDiv = TpzApplicationUI.createDiv({ classes: 'right-panel' });
            this.getJsonEditor().then((editor: JSONEditor) => editor.set(''));
        }
        return this.rightPanelDiv;
    }

    /**
     * Tree Panel Div lazy getter
     */
    private getTreePanel(): HTMLDivElement {
        if (!this.treePanelDiv) {
            this.treePanelDiv = TpzApplicationUI.createDiv({ classes: 'cs-tree' });
            this.treePanelDiv.id = this.getId() + '-tree-div';
            this.updateTree();
        }
        return this.treePanelDiv;
    }

    /*
     * get Play Button
     */
    public getApplyButton(): HTMLButtonElement {
        if (!this.applyButton) {
            this.applyButton = TpzApplicationUI.createButton({ label: 'Apply' });
            this.applyButton.classList.add('ok-button');
            this.applyButton.addEventListener('click', this.apply.bind(this));
        }
        return this.applyButton;
    }

    /*
     * get Cancel Button
     */
    public getCloseButton(): HTMLButtonElement {
        if (!this.closeButton) {
            this.closeButton = TpzApplicationUI.createButton({ label: 'Close' });
            this.closeButton.classList.add('cancel-button');
            this.closeButton.addEventListener('click', this.close.bind(this));
        }
        return this.closeButton;
    }

    /**
     * update the left panel tree
     */
    private updateTree(): void {
        const itemConfig: ItemConfig = this.getItemConfig();
        this.getTreePanel().innerHTML = '';
        this.addTreeChildren(this.getTreePanel(), this.getConfig().itemId, 0);
    }

    /**
     * Add all childrenIds and accessor Ids to the displayed children tree
     * @param parent HTMLElement in which elements should be added
     * @param itemId Item Id displaying its tree
     * @param level node level (number of parent to root node)
     * @returns
     */
    private addTreeChildren(parent: HTMLElement, itemId: string, level: number): void {
        if (!parent) return;
        if (!itemId) return;

        const sublist: HTMLElement = Tree.addChildElement(parent, itemId, itemId, level, () =>
            this.openConfigEditor(itemId)
        );

        const config: ItemConfig = this.getApplication().getItemCatalog().getRegisteredConfigById(itemId);
        if (!config) {
            this.getApplication().sendNotification(
                'ERROR',
                'item id #' + itemId + ' is not registered in item catalog'
            );
        }

        config.childrenIds?.forEach((childId: string) => {
            this.addTreeChildren(sublist, childId, level + 1);
        });

        // not very beautiful cast. Should we add accessorIds in ItemConfig ?
        (config as any).accessorIds?.forEach((childId: string) => {
            this.addTreeChildren(sublist, childId, level + 1);
        });
    }

    /**
     * Get a promise over JsonEditor object
     */
    private getJsonEditor(): Promise<JSONEditor> {
        if (!this.editor) {
            this.editor = this.getApplication()
                .createJSONEditor(this.getRightPanel())
                .catch((reason: Error) => {
                    // do not cache falsy promise
                    this.editor = null;
                    // rethrow error
                    throw reason;
                });
        }
        return this.editor;
    }

    /**
     * Change currently edited configuration
     * @param itemId item to be edited identifier
     */
    private openConfigEditor(itemId: string): void {
        const itemConfig: ItemConfig = this.getApplication().getItemCatalog().getRegisteredConfigById(itemId);
        if (!itemConfig) {
            this.getApplication().sendNotification(
                'ERROR',
                'item id #' + itemId + ' is not registered in item catalog'
            );
            this.editedItemId = null;
            this.editedConfig = null;
        } else {
            this.editedItemId = itemConfig.id;
            this.editedConfig = { ...itemConfig }; // copy edited configuration
        }
        this.getJsonEditor().then((editor: JSONEditor) => {
            editor.set(itemConfig);
        });
    }

    /**
     * get the current edited item id or null if none
     * @returns
     */
    public getEditedItemId(): string {
        return this.editedItemId;
    }

    /**
     * Action performed when apply button is clicked
     */
    private apply(): void {
        const itemId: string = this.getEditedItemId();
        if (!itemId) return;
        this.getJsonEditor().then((editor: JSONEditor) => {
            try {
                const editedConfig: any = editor.get();
                TpzApplicationCommander.updateItemConfig(this.getApplication(), editedConfig);
                // if config is not changed, ITEM_CONFIG_CHANGE_REQUEST event has no effect. Fire an updateRequest in all cases
                // but if changes occurs, two requestUpdate are done...
                TpzApplicationCommander.requestUpdate(this.getApplication(), this.editedItemId);
            } catch (reason: any) {
                this.getApplication().sendNotification('ERROR', 'invalid JSON: ' + reason);
                this.getLogger().error('invalid JSON', reason);
            }
        });
    }

    /**
     * Action performed when cancel button is clicked
     */
    private close(): void {
        TpzApplicationCommander.closeParentWindow(this.getApplication(), this);
    }

    /** Remove Graphics components */
    public invalidateUI(): void {
        this.mainContainer = null;
        super.invalidateUI();
    }
}

/**
 * Factory handling EchartsView creation
 */
export class ItemParameterViewFactory extends TpzApplicationFactory {
    private static readonly ITEM_PARAMETER_VIEW_FACTORY_TYPE: string = 'ItemParameterViewFactoryType';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(ItemParameterViewFactory.ITEM_PARAMETER_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(
            ItemParameterView.ITEM_PARAMETER_VIEW_TYPE,
            this.createItemParameterView.bind(this),
            defaultItemParameterViewConfig
        );
    }

    /** CesiumView creator function */
    private createItemParameterView(config: ItemParameterViewConfig): Promise<ItemParameterView> {
        return Promise.resolve(new ItemParameterView(config, this.getApplication()));
    }
}

/**
 * Helper class for item parameter view
 */
export class ItemParameterViewHelper {
    /**
     * Create the parameter view associated with the given item instance.
     * Register the configuration in item Catalog
     */
    public static createItemParameterViewConfig(
        application: TpzApplication,
        itemConfig: ItemConfig
    ): ItemParameterViewConfig {
        //add the ParameterView Factory to factory manager
        application.registerItemFactory(new ItemParameterViewFactory(application));
        if (!itemConfig || !application) return null;
        const config: ItemParameterViewConfig = {
            id: itemConfig.id + '-parameter',
            type: ItemParameterView.ITEM_PARAMETER_VIEW_TYPE,
            itemId: itemConfig.id
        };
        return config;
    }
}

class Tree {
    /**
     * Add a real node element (root, node or leaf)
     * @param parentElement
     * @param name
     * @param id
     * @param level
     * @param onClick
     * @returns
     */
    public static addTreeElement(
        parentElement: HTMLOListElement,
        name: string,
        id: string,
        level: number,
        onClick: () => void
    ): HTMLLIElement {
        if (!parentElement) return null;
        if (!id) id = parentElement.id ? parentElement.id + '-' + id : uuidv4();
        const li: HTMLLIElement = document.createElement('li');
        li.classList.add('cs-tree-node');
        li.classList.add('cs-tree-node-level-' + level);
        li.id = id ? id : uuidv4();
        // add label
        const label: HTMLLabelElement = document.createElement('label');
        label.addEventListener('click', () => onClick());
        label.innerHTML = '';
        for (let i = 0; i < level; i++) label.innerHTML = label.innerHTML + '\xa0';
        label.innerHTML = label.innerHTML + name;
        // label.setAttribute("for", input.id);
        // add elements in id
        li.appendChild(label);
        // add li to ol
        parentElement.appendChild(li);
        return li;
    }

    /**
     * Add an Item as node in the tree
     * @param parentElement parent HTMLElement in which node should be added
     * @param name displayed name
     * @param id HTML ID of the generated Node
     * @param level node level (number of parent to root node)
     * @param onClick Action to perform when node is clicked
     * @returns
     */
    public static addChildElement(
        parentElement: HTMLElement,
        name: string,
        id: string,
        level: number,
        onClick: () => void
    ): HTMLLIElement {
        if (!parentElement) return null;
        if (!id) id = parentElement.id ? parentElement.id + '-' + id : uuidv4();
        // retrieve ol sublist
        let ol: HTMLOListElement = null;
        const olChildren: NodeListOf<HTMLOListElement> = parentElement.querySelectorAll(':scope > ol');
        if (olChildren.length > 1) {
            throw new Error(
                'parentElement #' +
                    parentElement.id +
                    ' contains more than one ol sublist... (' +
                    Array.from(olChildren)
                        .map((x) => x.id)
                        .join(', ') +
                    ')'
            );
        }
        if (olChildren.length === 1) ol = olChildren.item(0);
        // if ol doesn't exist, creates it
        if (!ol) {
            if (parentElement.tagName === 'LI') {
                // add input to be able to open <ol>
                const input: HTMLInputElement = document.createElement('input');
                input.setAttribute('type', 'checkbox');
                input.setAttribute('checked', '');
                input.id = parentElement.id + '-' + id + '-checkbox';
                parentElement.appendChild(input);
            }
            // add <ol>
            ol = document.createElement('ol');
            ol.classList.add('cs-tree');
            ol.classList.add('cs-tree-level-' + level);
            ol.id = parentElement.id + '-ol';
            parentElement.appendChild(ol);
        }
        // add element as child (in ol)
        return Tree.addTreeElement(ol, name, id, level, onClick);
    }

    // /**
    //  * Creates a tree root element with given id
    //  * @param name
    //  * @param id
    //  * @returns
    //  */
    // public static createTreeRoot(id: string = null): HTMLOListElement {
    //     let ol: HTMLOListElement = document.createElement('ol');
    //     ol.classList.add("cs-tree");
    //     ol.id = id ? id : uuidv4();
    //     return ol;
    // }
}
