/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ValueAccessorConfig, ValueAccessor, defaultValueAccessorConfig } from './tpz-access-value';
import {
    AccessorValueImportExport,
    DataConvertFromString,
    DataConvertToString,
    AccessorType
} from './tpz-access-types';
import { TpzApplication } from '../tpz-application/tpz-application-core';
import { deepCopy } from '../tpz-application/tools/deep-copy';
import { AccessorCategoryType } from './tpz-access-types';

// default number static value
export const defaultStaticValueAccessorConfig: ValueAccessorConfig<any> = {
    ...defaultValueAccessorConfig,
    type: AccessorType.GENERIC_STATIC_VALUE_ACCESSOR_TYPE
};

/**
 * Static value accessor is a single value non modifiable aceessor
 */
export class StaticValueAccessor<DataType> extends ValueAccessor<DataType> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     */
    constructor(
        config: ValueAccessorConfig<DataType>,
        type: string,
        app: TpzApplication,
        importFunction: DataConvertFromString<DataType> = AccessorValueImportExport.convertFromStringDefault,
        exportFunction: DataConvertToString<DataType> = AccessorValueImportExport.convertToStringDefault
    ) {
        super(deepCopy(defaultStaticValueAccessorConfig, config), type, app, importFunction, exportFunction);
        this.addCategory(AccessorCategoryType.STATIC_ACCESSOR_CATEGORY_TYPE);
    }

    /**
     * Request accessor to update its value. Since this accessor can change only using setvalue()
     * the request is valid only if force is true
     */
    public requestUpdate(force: boolean = false): boolean {
        if (!this.isRunning()) return false;
        if (force) this.setValue(this.getValue(), true);
        return true;
    }
}

// default number static value
export const defaultStaticValueAccessorNumberConfig: ValueAccessorConfig<number> = {
    ...defaultStaticValueAccessorConfig,
    type: AccessorType.NUMBER_STATIC_VALUE_ACCESSOR_TYPE
};

/** Static value type number */
export class StaticValueAccessorNumber extends StaticValueAccessor<number> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     */
    constructor(config: ValueAccessorConfig<number>, app: TpzApplication) {
        super(
            deepCopy(defaultStaticValueAccessorNumberConfig, config),
            AccessorType.NUMBER_STATIC_VALUE_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringNumber,
            AccessorValueImportExport.convertToStringNumber
        );
    }
}

// default string static value
export const defaultStaticValueAccessorStringConfig: ValueAccessorConfig<string> = {
    ...defaultStaticValueAccessorConfig,
    type: AccessorType.STRING_STATIC_VALUE_ACCESSOR_TYPE
};

/** Static value type string */
export class StaticValueAccessorString extends StaticValueAccessor<string> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app topaz application in which this accessor is used
     */
    constructor(config: ValueAccessorConfig<string>, app: TpzApplication) {
        super(
            deepCopy(defaultStaticValueAccessorStringConfig, config),
            AccessorType.STRING_STATIC_VALUE_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertToStringString,
            AccessorValueImportExport.convertFromStringString
        );
    }
}
