/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../../tpz-log/tpz-log-core';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationEventCategory, TpzApplicationEventType } from '../tpz-application-event';
import { TpzPlugin } from './tpz-plugin-core';

/**
 * Manages a set of plugins
 */
export class TpzPluginManager {
    private readonly plugins: { [id: string]: TpzPlugin } = {};
    private readonly pluginStarters: { [id: string]: Promise<TpzPlugin> } = {};
    private readonly application: TpzApplication = null;

    /**
     * Constructor
     */
    constructor(app: TpzApplication) {
        this.application = app;
    }

    /**
     * application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * add a plugin to manager
     * @param plugin plugin to add
     */
    public addPlugin(plugin: TpzPlugin, autoStart: boolean = false): boolean {
        if (!plugin) return false;
        this.plugins[plugin.getId()] = plugin;
        if (autoStart) this.startPlugin(plugin.getId());
        return true;
    }

    /**
     * Logger getter (getApplication.getLogger())
     */
    public getLogger(): Logger {
        return this.getApplication().getLogger();
    }

    /**
     * get all plugins
     */
    public getPlugins(): TpzPlugin[] {
        return Object.values(this.plugins);
    }

    /**
     * get the plugin with given id
     */
    public getPluginById(id: string): TpzPlugin {
        return this.plugins[id];
    }

    /**
     * get all plugin Ids
     */
    public getPluginIds(): string[] {
        return Object.keys(this.plugins);
    }

    /**
     * get all dependencies the given plugin Id has on other plugins
     * @param id plugin id to get plugin ids it depends on
     */
    public getParentDependencies(id: string): string[] {
        const plugin: TpzPlugin = this.getPluginById(id);
        if (!plugin) throw new Error('getDependenciesOn(): Unknown plugin ' + id);
        return plugin.getConfig()?.pluginDependencies;
    }

    /**
     * get all plugins which have direct dependency on the given plugin id
     * @param id plugin id to get plugin ids that have dependency on
     */
    public getChildDependencies(id: string): string[] {
        const dependencies: string[] = [];
        this.getPlugins().forEach((plugin: TpzPlugin) => {
            if (plugin.getConfig().pluginDependencies.indexOf(id)) dependencies.push(plugin.getId());
        });
        return dependencies;
    }

    /**
     * Start a plugin by its id
     * @param id plugin to start id
     */
    public startPlugin(id: string): Promise<TpzPlugin> {
        const plugin: TpzPlugin = this.getPluginById(id);
        if (!plugin) throw new Error('StartPlugin: Unknown plugin ' + id);
        // try to retrieve cached starter
        let pluginStarter: Promise<TpzPlugin> = this.pluginStarters[id];
        if (pluginStarter) {
            return pluginStarter;
        }
        // start all plugins this plugin depends on (to be started before this one)
        const dependLoaders: Promise<TpzPlugin>[] = [];
        const parentIds: string[] = this.getParentDependencies(id);
        parentIds?.forEach((dependId: string) => {
            try {
                dependLoaders.push(this.startPlugin(dependId));
            } catch (error) {
                // catch exception in order to continue trying to start children anyway...
                this.getApplication()
                    .getLogger()
                    .error(
                        'Starting plugin ' + id + ' depends on plugin ' + dependId + ' which cannot be started',
                        error
                    );
            }
        });
        // when all dependent plugins are started, plug this one
        pluginStarter = Promise.all(dependLoaders)
            .then(() => {
                return plugin.plug(this.getApplication());
            })
            .then((plugin: TpzPlugin) => {
                this.getApplication().fireEvent(
                    this.getApplication().getId(),
                    TpzApplicationEventType.PLUGIN_STARTED,
                    { plugin: plugin },
                    [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
                );
                return plugin;
            });
        // stores plugin starter in cache
        this.pluginStarters[id] = pluginStarter;
        return pluginStarter;
    }

    /**
     * Stop a plugin by its id
     * @param id plugin to start id
     */
    public stopPlugin(id: string): Promise<TpzPlugin> {
        const plugin: TpzPlugin = this.getPluginById(id);
        if (!plugin) throw new Error('StopPlugin: Unknown plugin ' + id);
        // stop all plugins depending on this (to be stopped before this one)
        const dependLoaders: Promise<TpzPlugin>[] = [];
        const childIds: string[] = this.getChildDependencies(id);
        childIds?.forEach((dependId: string) => {
            try {
                dependLoaders.push(this.stopPlugin(dependId));
            } catch (error) {
                // catch exception in order to continue trying to start children anyway...
                this.getApplication()
                    .getLogger()
                    .error(
                        'Stopping plugin ' + id + ' depends on plugin ' + dependId + ' which cannot be stopped',
                        error
                    );
            }
        });
        // when all dependent plugins are stopped, unplug this one
        return Promise.all(dependLoaders).then(() => {
            this.getApplication().fireEvent(
                this.getApplication().getId(),
                TpzApplicationEventType.PLUGIN_STOPPED,
                { plugin: plugin },
                [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
            );
            plugin.unplug();
            return plugin;
        });
    }

    /**
     * Start all plugins
     */
    public startAllPlugins(): Promise<TpzPlugin[]> {
        const promises: Promise<TpzPlugin>[] = this.getPlugins()?.map((plugin: TpzPlugin) => {
            return this.startPlugin(plugin.getId());
        });
        return Promise.all(promises);
    }
}
