/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * config object reflects the exact instance state at any time.
 * It is used to generate an instance using a factory handling its 'type'
 */
export interface ItemConfig {
    id?: string; // instance id
    type?: string; // instance type (used by factories to be handled correctly)
    maxErrorCount?: number; // max number of messages stored into internal logger
    logUnhandledEvents?: boolean; // log a message for each unhandled message
    debug?: boolean; // debug mode (default is false)
    autoStart?: boolean; // does this instance has to be started (::start()) directly at creation
    stopParent?: boolean; // try to stop parent if this item is stopped
    childrenIds?: string[]; // launched items Ids
    // storedItemIds?: string[], // item stored but not used
    addOnIds?: string[]; // list of AddOn IDs which should be loaded to create this object
    libraryIds?: string[]; // automatically loaded libraries
    accessorIds?: string[]; // accessors are also children of this item, but we need to know they are accessors
    tags?: string[]; // user defined values used to tag objects
    css?: string[]; // automatically loaded css files
}

/**
 * Deafult item configuration
 */
export const defaultItemConfig: ItemConfig = Object.freeze({
    id: undefined,
    type: undefined,
    maxErrorCount: 200,
    logUnhandledEvents: false,
    debug: false,
    autoStart: false,
    stopParent: false,
    childrenIds: [],
    // storedItemIds: [],
    addOnIds: [],
    libraryIds: [],
    accessorIds: [],
    tags: [],
    css: []
});
