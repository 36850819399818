/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../../../../tpz-log/tpz-log-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { defaultTpzPluginConfig, TpzPlugin, TpzPluginConfig } from '../../tpz-plugin-core';
import { LoggerUI } from './plugin-logger-ui';
import { deepCopy } from '../../../tools/deep-copy';

const LOGGER_PLUGIN_TYPE: string = 'LoggerPluginType';

/**
 *  Plugin configuration
 */
export interface LoggerPluginConfig extends TpzPluginConfig {
    css?: string[]; // text elements to display
    divId?: string; // div container id
    divClasses?: string[]; // div classes
    parentId?: string; // div ID in which the logger UI has to be inserted
    icon?: string; // logger icon added into toolbar
}

/** Default Logger Plugin configuration */
export const defaultLoggerPluginConfig: LoggerPluginConfig = {
    ...defaultTpzPluginConfig,
    id: 'Logger',
    type: LOGGER_PLUGIN_TYPE,
    divId: 'logger',
    css: ['css/logger.css'].concat(defaultTpzPluginConfig.css),
    divClasses: ['logger-plugin'],
    icon: 'images/icons/log.png'
};

/**
 * The logger plugin adds a button in 'parentId' which displays a div with all logs
 * Plugin is listening to the application logger and fills its window each new log
 */
export class LoggerPlugin extends TpzPlugin {
    public static readonly LOGGER_PLUGIN_TYPE: string = LOGGER_PLUGIN_TYPE; // plugin type

    private loggerUI: LoggerUI = null;
    private iconContainer: HTMLDivElement = null;
    private unseenContainer: HTMLDivElement = null;
    private unseenErrorContainer: HTMLDivElement = null;
    private icon: HTMLImageElement = null;
    private loggerContainer: HTMLDivElement = null;
    private closeContainer: HTMLDivElement = null;
    private searchContainer: HTMLDivElement = null;
    private searchInput: HTMLInputElement = null;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: LoggerPluginConfig) {
        super(deepCopy(defaultLoggerPluginConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): LoggerPluginConfig {
        return super.getConfig() as LoggerPluginConfig;
    }

    /**
     * Logger UI lazy getter
     */
    public getLoggerUI(): LoggerUI {
        if (!this.loggerUI) {
            this.loggerUI = this.createLoggerUI();
        }
        return this.loggerUI;
    }

    /**
     * create the Logger UI
     */
    private createLoggerUI(): LoggerUI {
        const ui: LoggerUI = new LoggerUI(this.getApplication());
        ui.setUnseenUpdateCallback(() => this.onUnseenUpdate(this.loggerUI));
        return ui;
    }

    /**
     * Action to perform when the loggerUI update is fired
     */
    private onUnseenUpdate(loggerUI: LoggerUI): void {
        if (!loggerUI) throw new Error('loggerUI is not set in update callback');
        const count: number = loggerUI.getUnseenCount(Logger.WARN);
        this.getUnseenContainer().innerText = count ? String(count) : '';
        const countError: number = loggerUI.getUnseenCountFiltered(Logger.ERROR);
        this.getUnseenErrorContainer().innerText = countError ? String(countError) : '';
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source == this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.LOGGER_CHANGED:
                TpzApplicationEventType.checkEvent(event, null);
                this.updateUI();
                return true;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Update user interface to reflect config changes
     */
    public invalidateUI(): void {
        this.icon = null;
        this.iconContainer = null;
    }

    /**
     * Update user interface to reflect config changes
     */
    public updateUI(): void {
        this.createUI();
    }

    /**
     * Action to perform when pluging plugin
     * creates the LoggerUI to listen to application logger
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then(() => {
            this.createUI();
            return this;
        });
    }

    /**
     * Action to perform when pluging plugin
     */
    public onUnplug(): Promise<TpzPlugin> {
        this.removeUI();
        return super.onUnplug();
    }

    /**
     * get Logger Icons Container
     */
    public getIconContainer(): HTMLElement {
        if (!this.iconContainer) {
            this.iconContainer = TpzApplicationUI.createDiv({ classes: ['plugin-logger-ui'] });
            // icon element
            this.icon = TpzApplicationUI.createImage(this.getConfig().icon, {});
            this.icon.addEventListener('click', this.onIconClick.bind(this));
            this.iconContainer.appendChild(this.icon);
            // add info/debug log count icon
            this.iconContainer.appendChild(this.getUnseenContainer());
            // add error log count icon
            this.iconContainer.appendChild(this.getUnseenErrorContainer());
        }
        return this.iconContainer;
    }

    /**
     * get Logger count Icon of unseen logs Container
     */
    public getUnseenContainer(): HTMLDivElement {
        if (!this.unseenContainer) {
            this.unseenContainer = TpzApplicationUI.createDiv({ classes: ['unseen'] });
            this.unseenContainer.innerText = '0';
        }
        return this.unseenContainer;
    }

    /**
     * get Logger count Icon of unseen errors Container
     */
    public getUnseenErrorContainer(): HTMLDivElement {
        if (!this.unseenErrorContainer) {
            this.unseenErrorContainer = TpzApplicationUI.createDiv({ classes: ['unseen-error'] });
            this.unseenErrorContainer.innerText = '0';
        }
        return this.unseenErrorContainer;
    }

    /**
     * get main Logger Container
     */
    public getLoggerContainer(): HTMLDivElement {
        if (!this.loggerContainer) {
            this.loggerContainer = TpzApplicationUI.createDiv({});
            this.loggerContainer.id = this.getConfig().divId;
            this.getConfig().divClasses?.forEach((className) => this.loggerContainer.classList.add(className));
            this.loggerContainer.tabIndex = -1;
            this.loggerContainer.appendChild(this.getSearchContainer());
            this.loggerContainer.appendChild(this.getLoggerUI().getUI());
            this.loggerContainer.addEventListener('click', this.removeUnseen.bind(this));
            this.loggerContainer.appendChild(this.getCloseContainer());
        }
        return this.loggerContainer;
    }

    /**
     * get the search wrapper container
     */
    public getSearchContainer(): HTMLDivElement {
        if (!this.searchContainer) {
            this.searchContainer = TpzApplicationUI.createDiv({ classes: ['has-search'] });
            // search icon container
            const searchIcon = TpzApplicationUI.createSpan({ classes: ['input-wrapper'] });
            // append search icon
            this.searchContainer.appendChild(searchIcon);
            // append search input element
            this.searchContainer.appendChild(this.getSearchInput());
        }
        return this.searchContainer;
    }

    /**
     * Get the search input container
     */
    private getSearchInput() {
        if (!this.searchInput) {
            this.searchInput = TpzApplicationUI.createTextInput({ id: 'logSearch', value: 'Enter your search' });
            this.searchInput.type = 'search';
            this.searchInput.placeholder = 'Filter (e.g. info, debug, ...)';
            this.searchInput.autocomplete = 'on';
            this.searchInput.addEventListener('keyup', this.onSearchKeyup.bind(this));
        }
        return this.searchInput;
    }

    /**
     * The action to perform when user enter the searched text
     * @param e the keyup event
     */
    public onSearchKeyup(e: KeyboardEvent): void {
        const filter = this.searchInput.value.toLowerCase();
        const liItems = Array.from(this.getLoggerUI().getUI().getElementsByTagName('li'));

        liItems.forEach((el) => {
            const level = el.getElementsByClassName('level')[0].innerHTML;
            const date = el.getElementsByClassName('date')[0].innerHTML;
            const content = el.getElementsByClassName('content')[0].innerHTML;

            if (
                level.toLowerCase().includes(filter) ||
                level.toLowerCase().indexOf(filter) != -1 ||
                date.toLowerCase().includes(filter) ||
                date.toLowerCase().indexOf(filter) != -1 ||
                content.toLowerCase().includes(filter) ||
                content.toLowerCase().indexOf(filter) != -1
            ) {
                el.style.display = 'list-item';
            } else {
                el.style.display = 'none';
            }
        });
    }

    /**
     * get close button Container
     */
    public getCloseContainer(): HTMLDivElement {
        if (!this.closeContainer) {
            this.closeContainer = TpzApplicationUI.createDiv({ classes: ['close'] });
            this.closeContainer.addEventListener('click', this.onIconClick.bind(this));
        }
        return this.closeContainer;
    }

    /**
     * Action performed when unseen messages have been seen
     */
    private removeUnseen(): void {
        this.getLoggerUI().clearUnseenMessages();
    }

    /**
     * action to perform when logger icon is clicked
     */
    public onIconClick(event: MouseEvent): void {
        this.getLoggerContainer().classList.toggle('visible');
    }

    /**
     * Remove LoggerUI from DOM
     */
    private removeUI(): void {
        if (!this.iconContainer) return;

        const parentDiv: HTMLElement = document.getElementById(this.getConfig().parentId);
        if (!parentDiv) {
            const errorMessage: string =
                'Logger plugin ID ' +
                this.getId() +
                ' cannot remove its UI from unknown HTMLElement #' +
                this.getConfig().parentId;
            this.getLogger().error(errorMessage);
            throw new Error(errorMessage);
            return;
        }
        parentDiv.removeChild(this.iconContainer);
        if (this.loggerContainer) document.body.removeChild(this.loggerContainer);
    }
    /**
     * Insert LoggerUI in DOM.
     * Icon is inserted in parent Div
     * Logger container is inserted in body (TODO: set a second parent ?)
     */
    private createUI(): void {
        const parentDiv: HTMLElement = document.getElementById(this.getConfig().parentId);
        if (!parentDiv) {
            const errorMessage: string =
                'Logger plugin ID ' +
                this.getId() +
                ' cannot add its UI in unknown HTMLElement #' +
                this.getConfig().parentId;
            this.getLogger().error(errorMessage);
            throw new Error(errorMessage);
            return;
        }
        document.body.appendChild(this.getLoggerContainer());

        parentDiv.appendChild(this.getIconContainer());
    }
}
