/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventManager } from '../tpz-event/tpz-event-core';
import { Appender, ConsoleAppender } from './tpz-log-appender';
import { LoggerHelper } from './tpz-log-helper';

/**
 * Log messages encapsulation
 */
export interface LoggerMessage {
    date: number;
    level: number;
    content: string;
}

/*
Logger minimal implementation
*/
export class Logger {
    private readonly appenders: Appender[] = [];
    public levelNames: { [level: number]: string } = Logger.DEFAULT_LEVEL_NAMES;
    private readonly id: string = null;
    private running: boolean = false;
    private eventManager: EventManager<LoggerMessage> = null;

    //    private eventManager: LiteEvent<LoggerMessage>

    /**
     * Constructor
     */
    constructor(id: string) {
        this.id = id;
        this.start();
    }

    /**
     * event manager lazy getter
     */
    public getEventManager(): EventManager<LoggerMessage> {
        if (!this.eventManager) {
            this.eventManager = new EventManager<LoggerMessage>();
        }
        return this.eventManager;
    }

    /**
     * start logging incoming messages
     */
    public start(): void {
        this.running = true;
    }

    /**
     * stop logging incoming messages.
     * Caution: any incoming message will be lost !
     */
    public stop(): void {
        this.running = false;
    }

    /**
     * ID getter
     */
    public getId(): string {
        return this.id;
    }

    /** Add an appender to this logger */
    public addAppender(appender: Appender): boolean {
        if (!appender) return false;
        this.appenders.push(appender);
        return true;
    }

    /** Remove an appender from this logger */
    public removeAppender(appender: Appender): boolean {
        if (!appender) return false;
        // look for the element in this array
        const appenderIndex: number = this.appenders.indexOf(appender);
        if (appenderIndex == -1) return false;
        // remove the element from the array
        this.appenders.splice(appenderIndex, 1);
        return true;
    }

    /**
     * get appenders. return the default one if none has been added
     */
    public getAppenders(): Appender[] {
        // default appender
        if (this.appenders.length == 0) return [new ConsoleAppender()];
        return this.appenders;
    }

    /**
     * Add a log message and send an event
     * @param level
     * @param content
     */
    public log(level: number, content: any): boolean {
        // if logger has been stopped, do not append log
        if (!this.running) return false;
        // create a log message
        const message: LoggerMessage = {
            date: new Date().getTime(),
            level: level,
            content: content
        };
        // append message to all appenders
        this.getAppenders().forEach((appender: Appender) => {
            appender.addLogMessage(message);
        });
        this.getEventManager().trigger(message);
        return true;
    }

    /**
     * Helper Log function => addLogMessage(Logger.DEBUG ...)
     */
    public debug(message: string): void {
        this.log(Logger.DEBUG, message);
    }
    /**
     * Helper Log function => addLogMessage(Logger.INFO ...)
     */
    public info(message: string): void {
        this.log(Logger.INFO, message);
    }
    /**
     * Helper Log function => addLogMessage(Logger.WARN ...)
     */
    public warn(message: string): void {
        this.log(Logger.WARN, message);
    }
    /**
     * Helper Log function => addLogMessage(Logger.ERROR ...)
     */
    public error(message: string, error: any = null): void {
        this.log(Logger.ERROR, message);
        if (!error) return;
        this.log(Logger.ERROR, LoggerHelper.getErrorMessageFromObject(error));
    }

    /**
     * Helper Log function => addLogMessage(Logger.CRITICAL ...)
     */
    public critical(message: string): void {
        this.log(Logger.CRITICAL, message);
    }

    /**
     * Convert levels to strings. Known levels are:
     */
    public getLevelAsString(level: number): string {
        if (!level) return Logger.INVALID_LEVEL;
        const levelName: string = this.levelNames[level];
        if (!levelName) return Logger.UNKNOWN_LEVEL;
        return levelName;
    }

    /**
     * get the level number matching level name
     * @param name level name used to find level number
     * @returns level number or Logger.ALWAYS if not in name list
     */
    public getLevelFromName(name: string): number {
        if (!name) return Logger.ALWAYS;
        for (const [level, levelName] of Object.entries(this.levelNames)) {
            if (name == levelName) return Number.parseInt(level);
        }
        this.log(Logger.ALWAYS, 'Unable to find level ' + name);
        return Logger.ALWAYS;
    }

    // /**
    //  * Event Manager lazy getter
    //  */
    // public getEventManager(): LiteEvent<LoggerMessage> {
    //     if (!this.eventManager) {
    //         this.eventManager = new LiteEvent();
    //     }
    //     return this.eventManager;
    // }

    ///////////////////////////////////////////// STATIC ///////////////////////////////////
    public static readonly ALWAYS: number = 0;
    public static readonly DEBUG: number = 10;
    public static readonly INFO: number = 20;
    public static readonly WARN: number = 30;
    public static readonly ERROR: number = 40;
    public static readonly CRITICAL: number = 50;
    private static readonly INVALID_LEVEL: string = 'invalid-level';
    private static readonly UNKNOWN_LEVEL: string = 'unknown-level';

    // FIXME: use constants (compilation error when using them)
    public static readonly DEFAULT_LEVEL_NAMES: { [level: number]: string } = {
        [Logger.ALWAYS]: 'ALWAYS',
        [Logger.DEBUG]: 'DEBUG',
        [Logger.INFO]: 'INFO',
        [Logger.WARN]: 'WARN',
        [Logger.ERROR]: 'ERROR',
        [Logger.CRITICAL]: 'CRITICAL'
    };

    public static defaultLoggerId: string = null;
    public static readonly DEFAULT_LOGGER_ID = 'default-logger';

    // initialized in init
    private static loggers: { [loggerId: string]: Logger } = {};

    /**
     * Initialize all static members
     */
    public static initialize(): void {
        Logger.loggers = {};
        Logger.defaultLoggerId = Logger.DEFAULT_LOGGER_ID;
    }

    /** get the name associated with default levels */
    public static getDefaultLevelName(level: number): string {
        if (level in Logger.DEFAULT_LEVEL_NAMES) return Logger.DEFAULT_LEVEL_NAMES[level];
        return Logger.UNKNOWN_LEVEL;
    }

    /**
     * Helper Log function => addLogMessage(Logger.DEBUG ...)
     */
    public static debug(message: string, loggerId: string = null): void {
        Logger.log(Logger.DEBUG, message, loggerId);
    }
    /**
     * Helper Log function => addLogMessage(Logger.INFO ...)
     */
    public static info(message: string, loggerId: string = null): void {
        Logger.log(Logger.INFO, message, loggerId);
    }
    /**
     * Helper Log function => addLogMessage(Logger.WARN ...)
     */
    public static warn(message: string, loggerId: string = null): void {
        Logger.log(Logger.WARN, message, loggerId);
    }
    /**
     * Helper Log function => addLogMessage(Logger.ERROR ...)
     */
    public static error(message: string, loggerId: string = null): void {
        Logger.log(Logger.ERROR, message, loggerId);
    }
    /**
     * Helper Log function => addLogMessage(Logger.CRITICAL ...)
     */
    public static critical(message: string, loggerId: string = null): void {
        Logger.log(Logger.CRITICAL, message, loggerId);
    }

    /**
     * Register a logger in global logger access
     */
    public static registerLogger(logger: Logger): boolean {
        if (Logger.loggers[logger.getId()]) return false;
        Logger.loggers[logger.getId()] = logger;
        return true;
    }

    /**
     * Retrieve a logger with given Id or create a default one
     * If no Id is given, use Logger.defaultLoggerId
     * */
    public static getLogger(loggerId: string = null): Logger {
        // get default logger
        if (!loggerId) {
            loggerId = Logger.defaultLoggerId;
        }
        const logger: Logger = Logger.loggers[loggerId];
        // if the requested logger does not exist: create a new one
        if (!logger) {
            this.loggers[loggerId] = new Logger(loggerId);
        }
        return Logger.loggers[loggerId];
    }

    /**
     * add a log message to a logger id at a given level
     * If logger id does not exist , create one
     * @param level log level
     * @param content log content
     * @param loggerId logger id (DEFAULT LOGGER by default)
     */
    public static log(level: number, content: string, loggerId: string = null): void {
        const logger: Logger = this.getLogger(loggerId);
        logger.log(level, content);
    }

    /**
     * set default logger which will be used when non logger Id is provided
     * @param Logger
     */
    public static setDefaultLogger(logger: Logger): void {
        if (!logger) return;
        Logger.defaultLoggerId = logger.getId();
        Logger.registerLogger(logger);
    }

    /**
     * get default logger
     */
    public static getDefaultLogger(): Logger {
        return Logger.getLogger();
    }
}

Logger.initialize();
