/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzApplicationCommander } from '../../../tpz-application-commander';
import { TpzDesktopConfig } from '../../../desktop/tpz-desktop-config';
import { deepCopy } from '../../../tools/deep-copy';
import { TpzApplicationCategories, TpzApplicationTypes } from '../../../tpz-application-types';

// item type (used in default configuration and Item Instance constructor)
const NEW_DESKTOP_VIEW_TYPE: string = 'NewTpzViewType';

/**
 * new desktop view configuration
 */
export interface NewTpzViewConfig extends TpzViewConfig {
    desktopId?: string;
}

/**
 * Default New dekstop displayer configuration
 */
export const defaultNewTpzViewConfig: NewTpzViewConfig = {
    ...defaultTpzViewConfig,
    type: NEW_DESKTOP_VIEW_TYPE,
    desktopId: 'New-Desktop'
};

/**
 * Display an accessor in an INPUT HTML tag with a label.
 * If label is null, use the accessor name
 */
export class NewTpzView extends TpzView {
    private mainContainer: HTMLDivElement = null;
    private titleSpan: HTMLSpanElement = null;
    private singleSpan: HTMLSpanElement = null;
    private openSpan: HTMLSpanElement = null;
    private valueInput: HTMLInputElement = null;
    private singleDesktopCheckBox: HTMLInputElement = null;
    private openDesktopCheckBox: HTMLInputElement = null;
    private createButton: HTMLButtonElement = null;
    private cancelButton: HTMLButtonElement = null;

    public static readonly NEW_DESKTOP_VIEW_TYPE = NEW_DESKTOP_VIEW_TYPE;

    /** Constructor */
    constructor(config: NewTpzViewConfig, application: TpzApplication) {
        super(deepCopy(defaultNewTpzViewConfig, config), NewTpzView.NEW_DESKTOP_VIEW_TYPE, application);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): NewTpzViewConfig {
        return super.getConfig() as NewTpzViewConfig;
    }

    /**
     * Generate UI
     * @param parent parent to be inserted in
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!parent) throw new Error('parent is not defined');
        if (!parent.contains(this.getMainContainer())) parent.appendChild(this.getMainContainer());
        return true;
    }

    /**
     * Input label getter
     */
    public getTitleSpan(): HTMLSpanElement {
        if (!this.titleSpan) {
            this.titleSpan = TpzApplicationUI.createLabel({ label: 'new desktop ID' });
        }
        return this.titleSpan;
    }

    /**
     * Open Checkbox labal getter
     */
    public getOpenSpan(): HTMLSpanElement {
        if (!this.openSpan) {
            this.openSpan = TpzApplicationUI.createLabel({ label: 'open Desktop' });
        }
        return this.openSpan;
    }

    /**
     * Single Chackbox labal getter
     */
    public getSingleSpan(): HTMLSpanElement {
        if (!this.singleSpan) {
            this.singleSpan = TpzApplicationUI.createLabel({ label: 'open Desktop as single desktop' });
        }
        return this.singleSpan;
    }

    /**
     * Text Input getter
     */
    public getInputValue(): HTMLInputElement {
        if (!this.valueInput) {
            this.valueInput = TpzApplicationUI.createTextInput({ value: this.getConfig().desktopId });
        }
        return this.valueInput;
    }

    /**
     * checkbox 'open single' getter
     */
    public getSingleCheckBox(): HTMLInputElement {
        if (!this.singleDesktopCheckBox) {
            this.singleDesktopCheckBox = TpzApplicationUI.createCheckBox({ checked: true });
        }
        return this.singleDesktopCheckBox;
    }

    /**
     * checkbox 'open desktop' getter
     */
    public getOpenCheckBox(): HTMLInputElement {
        if (!this.openDesktopCheckBox) {
            this.openDesktopCheckBox = TpzApplicationUI.createCheckBox({ checked: true });
        }
        return this.openDesktopCheckBox;
    }

    /**
     * Create the new desktop using UI inputs
     */
    private createDesktop(): void {
        // create (register) desktop
        const desktopId: string = this.getInputValue().value;

        // create config and register new desktop
        const desktopConfig: TpzDesktopConfig = {
            id: desktopId,
            type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
            name: desktopId,
            childrenIds: []
        };
        TpzApplicationCommander.registerItem(this.getApplication(), desktopConfig)
            .then(() => {
                return this.getApplication().waitForEvent(
                    TpzApplicationEventType.ITEM_CONFIG_REGISTERED,
                    (event) => event?.content?.itemConfig?.id == desktopConfig.id,
                    'new desktop open waiting for item registration'
                );
            })
            .then(() => {
                if (!this.getOpenCheckBox().checked) {
                    return null;
                }
                // open desktop if requested
                if (this.getSingleCheckBox().checked) {
                    // open as single
                    return TpzApplicationCommander.openSingleDesktop(this.getApplication(), desktopId);
                } else {
                    // add desktop
                    return TpzApplicationCommander.addDesktop(this.getApplication(), desktopId);
                }
            });
    }

    /**
     * 'create' button lazy getter
     */
    public getCreateButton(): HTMLButtonElement {
        if (!this.createButton) {
            this.createButton = TpzApplicationUI.createButton({ label: 'create', classes: ['ok-button'] });
            this.createButton.addEventListener('click', (event: Event) => this.createDesktop());
        }
        return this.createButton;
    }

    /**
     * cancel Button lazy getter
     */
    public getCancelButton(): HTMLButtonElement {
        if (!this.cancelButton) {
            this.cancelButton = TpzApplicationUI.createButton({ label: 'cancel', classes: ['cancel-button'] });
            this.cancelButton.addEventListener('click', (event: Event) => {
                this.closeItem();
            });
        }
        return this.cancelButton;
    }

    /**
     * Close Item (and potentially enclosing window)
     */
    private closeItem(): void {
        this.getParentWindow().closeWindowAndRemoveFromDesktops();
    }

    /**
     * Main UI Container getter
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({});
            this.mainContainer.appendChild(TpzApplicationUI.createDiv({}, this.getTitleSpan(), this.getInputValue()));
            this.mainContainer.appendChild(
                TpzApplicationUI.createDiv({}, this.getSingleCheckBox(), this.getSingleSpan())
            );
            this.mainContainer.appendChild(TpzApplicationUI.createDiv({}, this.getOpenCheckBox(), this.getOpenSpan()));
            this.mainContainer.appendChild(
                TpzApplicationUI.createDiv({}, this.getCreateButton(), this.getCancelButton())
            );
            this.updateUI();
        }
        return this.mainContainer;
    }

    /**
     * Generic UI getter
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Delete UI elements
     */
    public invalidateUI(): void {
        this.mainContainer = null;
        this.titleSpan = null;
        this.singleSpan = null;
        this.openSpan = null;
        this.valueInput = null;
        this.singleDesktopCheckBox = null;
        this.openDesktopCheckBox = null;
        this.createButton = null;
        this.cancelButton = null;
        super.invalidateUI();
    }

    /**
     * update UI
     */
    public updateUI(): void {
        super.updateUI();
    }
}

/**
 * Factory handling NewTpzView creation
 */
export class NewTpzViewFactory extends TpzApplicationFactory {
    private static readonly LABELED_VIEW_FACTORY_TYPE: string = 'NewTpzViewFactory';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(NewTpzViewFactory.LABELED_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(
            NewTpzView.NEW_DESKTOP_VIEW_TYPE,
            this.createNewTpzView.bind(this),
            defaultNewTpzViewConfig
        );
        this.addCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY);
    }

    /** NewTpzView creator function */
    private createNewTpzView(config: NewTpzViewConfig): Promise<NewTpzView> {
        return Promise.resolve(new NewTpzView(config, this.getApplication()));
    }
}
