/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplicationEventType } from '../tpz-application/tpz-application-event';
import { TpzApplication } from '../tpz-application/tpz-application-core';
import { TpzApplicationComponent } from '../tpz-application/tpz-application-component';
import { I18NDictionary, I18NDictionaryManager } from './tpz-i18n-core';

/**
 * Internationalization manager
 */
export class I18NManager extends TpzApplicationComponent {
    public static readonly DEFAULT_GROUP_ID = 'default';
    public static readonly I18N_ATTRIBUTE: string = 'i18n';

    private currentLanguage: string = null;
    private readonly manager: I18NDictionaryManager = null;

    /**
     * Static constructor/initializer
     */
    public constructor(app: TpzApplication, id: string) {
        super(app, id);
        this.manager = new I18NDictionaryManager();
        this.currentLanguage = 'fr';
    }

    /**
     * Get the current language
     */
    public getLanguage(): string {
        return this.currentLanguage;
    }

    /**
     * Set the current language
     */
    public setLanguage(lang: string): void {
        if (this.currentLanguage === lang) return;
        this.currentLanguage = lang;
        this.fireEvent(TpzApplicationEventType.CHANGE_LANGUAGE, { lang: lang });
    }

    /**
     * Get a current dictionary
     */
    public getCurrentDictionaries(): I18NDictionary[] {
        return this.manager.getDictionariesByLanguage(this.getLanguage());
    }

    /**
     * add dictionaries in Internationalization
     * @param dict dictionary array resource
     */
    public addDictionaries(dicts: I18NDictionary[]): boolean {
        return this.manager.addDictionaries(dicts);
    }

    /**
     * add dictionary in Internationalization manager
     * @param dict dictionary resource
     */
    public addDictionary(dict: I18NDictionary): boolean {
        return this.manager.addDictionary(dict);
    }

    /**
     * get all dictionaries associated to a given language
     * @param lang language
     * @return undefined if no dictionaries were added
     */
    public getDictionariesByLanguage(lang: string): I18NDictionary[] {
        return this.manager.getDictionariesByLanguage(lang);
    }

    /**
     * get a dictionary associated to a given language and a given groupId
     * @param lang language
     */
    public getDictionaryByLanguageAndGroup(
        lang: string,
        groupId: string = I18NManager.DEFAULT_GROUP_ID
    ): I18NDictionary {
        return this.manager.getDictionaryByLanguageAndGroup(lang, groupId);
    }
}
