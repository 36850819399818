/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../tpz-log/tpz-log-core';
import { uuidv4 } from './tools/uuid';
import { TpzApplication } from './tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventCategory, TpzApplicationEventType } from './tpz-application-event';
/**
 * A component is a piece of code related to an application
 */
export class TpzApplicationComponent {
    private id: string = 'noid';
    private readonly application: TpzApplication = null;
    private running: boolean = false;

    /**
     * constructor of this component
     * @param app application in which component is used
     */
    constructor(app: TpzApplication, id: string) {
        this.application = app;
        this.id = id;
    }

    /**
     * Id setter
     * @param id new component ID
     */
    public setId(id: string): void {
        this.id = id;
    }

    /**
     * Id getter. if not is defined a new one is created
     * @returns the component ID
     */
    public getId(): string {
        if (!this.id) this.id = uuidv4();
        return this.id;
    }

    /**
     * related application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * Logger getter (Application.getLogger() )
     */
    public getLogger(): Logger {
        return this.getApplication().getLogger();
    }

    /**
     * return if this component is started/running
     */
    public isRunning(): boolean {
        return this.running;
    }

    /**
     * Fires an application event (delegated to application)
     * @param type event type
     * @param content event content
     */
    public fireEvent(
        type: string,
        content: any,
        categories: string[] = [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
    ): void {
        this.getApplication()?.fireEvent(this.getId(), type, content, categories);
    }

    /**
     * Starts component
     * It registers this component among the application (onApplicationEvent method)
     */
    public start(): Promise<void> {
        // // this line is intentionnally outside promise in order to do it as quick as possible
        // // for item catalog, some event were lost
        if (!this.isRunning()) {
            this.getApplication()?.getEventManager().register(this.getId(), this.onApplicationEvent.bind(this));
            this.running = true;
        }
        return Promise.resolve().then(() => {
            // listening to the event manager is done outside this promise. If inside, some events are lost
        });
    }

    /**
     * Stop component
     * It unregisters this component from the application
     */
    public stop(): Promise<void> {
        return Promise.resolve().then(() => {
            this.getApplication()?.getEventManager().unregister(this.getId());
            this.running = false;
        });
    }

    /**
     * Action to perform when an application event is received
     * @param event application event
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.start();
                return true;
            case TpzApplicationEventType.APPLICATION_STOPPING:
                TpzApplicationEventType.checkEvent(event, null);
                this.stop();
                return true;
        }
        return false;
    }
}
