/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoadHelper } from '../tools/loader';
import { TpzAddOn, TpzAddOnDescriptor } from './addons-core';

/**
 * static helper methods for Add-Ons
 */
export class TpzAddOnHelper {
    /**
     * tells if the given object is an addon descriptor. Check mandatory fields:
     * - name
     * - script or scriptURL (at least one must be defined)
     * - libraryName
     * - className
     * @param x object to be checked
     * @returns if the object reflects a TpzAddOnDescriptor object
     */
    public static isTpzDescriptor(x: any): boolean {
        if (!x) return false;
        if (x['name'] === undefined) return false;
        if (x['script'] === undefined && x['scriptURL'] === undefined) return false;
        if (x['libraryName'] === undefined) return false;
        if (x['className'] === undefined) return false;
        return true;
    }

    /**
     * Return true if the given URL is a relative one.
     * An URL is not relative if it starts with a '/' character
     */
    public static isRelativeURL(url: string): boolean {
        return !url.startsWith('/');
    }

    /**
     * replace the last part of an URL
     * @param baseURL
     * @returns
     */
    public static getURLPath(baseURL: string): string {
        return baseURL?.replace(/[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/, '');
    }

    /**
     * replace the last part of an URL and add the relative URL
     * @param baseURL the URL containing the full path to a resource
     * @param relativeURL the filename or relative path to filename to be appended
     * @returns
     */
    public static replaceLastPartURL(baseURL: string, relativeURL: string): string {
        if (!relativeURL) return baseURL;
        return TpzAddOnHelper.joinURLs(TpzAddOnHelper.getURLPath(baseURL), relativeURL);
    }

    /**
     * join a base URL to a relative URL => baseURL + '/' + relativeURL
     * @param baseURL the URL containing the begining of the path
     * @param relativeURL the filename or relative path to filename to be appended to the base URL
     * @returns
     */
    public static joinURLs(baseURL: string, relativeURL: string): string {
        if (!relativeURL) return baseURL;
        if (!baseURL) return relativeURL;
        return (baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')).replace(/\/\.\//, '/');
    }

    /**
     * method used to load the addon from its descriptor.
     * It sets the addon code from descriptor.script if not null
     * or load code from scriptURL. Both script ans loadScript cannot be filled together
     * AddOn is loaded but NOT started
     * @param urlSource This URL is used as base URL if some elements are described relatively
     * @param addOnDescriptor addon descriptor
     * @returns a promise on loaded Addon
     */
    public static loadTpzAddOnByDescriptor(addOnDescriptor: TpzAddOnDescriptor): Promise<TpzAddOn> {
        if (!addOnDescriptor) return Promise.reject(new Error('Cannot load AddOn with a null descriptor'));
        let scriptURL: string = addOnDescriptor.scriptURL;
        // append script location to relative script paths
        if (TpzAddOnHelper.isRelativeURL(scriptURL)) {
            scriptURL = TpzAddOnHelper.joinURLs(addOnDescriptor.addOnURL, scriptURL);
        }
        return LoadHelper.loadJsScript(scriptURL).then((scriptTag: HTMLScriptElement) => {
            // instantiate the addon object
            // an 'addOnDescriptor' variable refers to the current variable which is in the current scope
            const addonCreation: string = `new ${addOnDescriptor.libraryName}.${addOnDescriptor.className}(addOnDescriptor)`;
            const x: TpzAddOn = eval(addonCreation);
            if (!x) return Promise.reject(new Error(`Unable to create Add-On using line: ${addonCreation}`));
            return x;
        });
    }

    /**
     * method used to load the addon descriptor from an URL containing the add-on.
     * URL can represent the descriptor URL or the directory URL. If it is a directory url, "tpz-addon.json" is added
     * @param addOnURL addon descriptor file URL
     * @returns a promise on the addon descriptor
     */
    public static loadTpzAddOnDescriptorFromURL(addOnURL: string): Promise<TpzAddOnDescriptor> {
        const request: RequestInit = {
            method: 'GET',
            headers: new Headers(),
            mode: 'cors',
            cache: 'default'
        };
        if (!addOnURL.endsWith(TpzAddOn.TPZ_ADDON_FILENAME)) {
            addOnURL = TpzAddOnHelper.joinURLs(addOnURL, TpzAddOn.TPZ_ADDON_FILENAME);
        }
        return LoadHelper.fetchRequest(addOnURL, request).then((response: Response) => {
            return response.json().then((responseJsonAny: any) => {
                if (!TpzAddOnHelper.isTpzDescriptor(responseJsonAny)) {
                    throw new Error('requested URL is not a valid addon descriptor');
                }

                const addOnDescriptor: TpzAddOnDescriptor = responseJsonAny as TpzAddOnDescriptor;
                // fill descriptor source URL (removing descriptor filename)
                addOnDescriptor.addOnURL = response.url;

                if (addOnDescriptor.addOnURL.endsWith(`/${TpzAddOn.TPZ_ADDON_FILENAME}`)) {
                    // remove descriptor filename
                    addOnDescriptor.addOnURL = addOnDescriptor.addOnURL.substring(
                        0,
                        addOnDescriptor.addOnURL.length - TpzAddOn.TPZ_ADDON_FILENAME.length
                    );
                }

                // fill thumbnail
                if (addOnDescriptor.thumbnail) {
                    addOnDescriptor.thumbnail = TpzAddOnHelper.getAbsoluteAddOnThumbnailURL(addOnDescriptor);
                }
                return addOnDescriptor;
            });
        });
    }

    /**
     * convert thumbnail URL to an absolute one if necessary
     * @param addOnDescriptor add-on descriptor
     */
    public static getAbsoluteAddOnThumbnailURL(addOnDescriptor: TpzAddOnDescriptor): string {
        if (!addOnDescriptor) return null;
        if (!addOnDescriptor.thumbnail) return null;
        // return the thumbnail URL if it is already an absolute path
        if (addOnDescriptor.thumbnail.startsWith('http')) return addOnDescriptor.thumbnail;
        // concat addonURL and thumbnail url if relative
        return `${addOnDescriptor.addOnURL}${addOnDescriptor.thumbnail}`;
    }

    /**
     * method used to load the addon from an URL containing the descriptor.
     * URL is the descriptor URL. It sets the addon code from descriptor.script if not null
     * or load code from scriptURL. Both script ans loadScript cannot be filled together
     * URL can represent the descriptor URL or the directory URL. If it is a directory url, "tpz-addon.json" is added
     * @param addOnURL addon descriptor file URL
     * @returns a promise on loaded Addon
     */
    public static loadTpzAddOnFromURL(addOnURL: string): Promise<TpzAddOn> {
        return TpzAddOnHelper.loadTpzAddOnDescriptorFromURL(addOnURL).then((addonDescriptor: TpzAddOnDescriptor) => {
            return TpzAddOnHelper.loadTpzAddOnByDescriptor(addonDescriptor);
        });
    }
}
