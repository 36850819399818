/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventManager } from '../../../tpz-event/tpz-event-core';
import { RollerColumn } from './roller-col';
import { uuidv4 } from '../../tools/uuid';

/**
 * Event trigger by rollers
 */
export interface RollerEvent {
    type: string;
}

/**
 * Roller event types for columns and rollers
 */
export class RollerEventType {
    public static readonly COLUMN_CHANGED = 'COLUMN_CHANGED';
    public static readonly ROLLER_CHANGED = 'ROLLER_CHANGED';
    public static readonly OK_ACTION = 'OK_ACTION';
    public static readonly CANCEL_ACTION = 'CANCEL_ACTION';
}

/**
 * List roller configuration
 */
export interface RollerConfig {
    containerClasses?: string[];
    columnClasses?: string[];
    cellClasses?: string[];
    panelClasses?: string[];
    glassClasses?: string[];
}

/**
 * Default configuration
 */
export const defaultRollerConfig: RollerConfig = {
    containerClasses: ['roller-container'],
    columnClasses: ['roller-column'],
    cellClasses: ['roller-cell'],
    panelClasses: ['roller-panel'],
    glassClasses: ['roller-glass']
};

/**
 * ListRoller object is a div containing a roll list containing items that can be selected
 * using a roll user interaction (drag mouse up and down)
 */
export class Roller {
    private readonly config: RollerConfig = null;
    private container: HTMLDivElement = null;
    private rollerDiv: HTMLDivElement = null;
    private rollerPanel: HTMLDivElement = null;
    private rollerGlass: HTMLDivElement = null;
    private rollerToolsPanel: HTMLDivElement = null;
    private rollerOkButton: HTMLDivElement = null;
    private rollerCancelButton: HTMLDivElement = null;
    private readonly columns: RollerColumn[] = [];
    private readonly id: string = null;

    private eventManager: EventManager<RollerEvent> = null;

    /**
     * List Roller constructor
     * @param parent input element to fill with
     * @param values
     */
    constructor(config: RollerConfig) {
        this.config = { ...defaultRollerConfig, ...config };
        this.id = uuidv4();
    }

    /**
     * ID getter. ID is  generated at constructor time
     */
    public getId(): string {
        return this.id;
    }
    /**
     * Config roller getter
     */
    public getConfig(): RollerConfig {
        return this.config;
    }

    /**
     * Get Event Manager
     * @param column
     */
    public getEventManager(): EventManager<RollerEvent> {
        if (!this.eventManager) {
            this.eventManager = new EventManager<RollerEvent>();
        }

        return this.eventManager;
    }

    /**
     * Add a roller column
     * @param column
     */
    public addColumn(column: RollerColumn): void {
        if (this.columns.indexOf(column) != -1) return;
        this.columns.push(column);
        // listen to all columns onChange and trigger roller onChange if any column triggers its own onChange
        column.getEventManager().register(this.getId(), this.onColumnEvent.bind(this));
    }

    /**
     * Coumn getter
     */
    public getColumns(): RollerColumn[] {
        return this.columns;
    }

    /**
     * Column value getter
     */
    public getColumnValues(): string[] {
        return this.columns.map((col) => col.getSelectedValue());
    }

    /**
     * On Column Event
     * @param event
     */
    private onColumnEvent(event: RollerEvent): boolean {
        switch (event.type) {
            case RollerEventType.COLUMN_CHANGED:
                this.getEventManager().trigger({ type: RollerEventType.COLUMN_CHANGED });
                return true;
        }
        return false;
    }

    /**
     * Add all columns
     * @param columns
     */
    public addColumns(columns: RollerColumn[]) {
        const me: Roller = this;
        columns?.forEach((col: RollerColumn) => me.addColumn(col));
    }

    /**
     * Lazy getter of the main DIV container
     */
    public getUI(): HTMLDivElement {
        const me: Roller = this;
        if (!this.container) {
            this.container = document.createElement('div');
            this.config?.containerClasses?.forEach((clazz) => me.container.classList.add(clazz));
            this.container.appendChild(this.getRollerDiv());
        }
        return this.container;
    }

    /**
     * Lazy getter of the Roller DIV
     */
    private getRollerDiv(): HTMLDivElement {
        if (!this.rollerDiv) {
            this.rollerDiv = document.createElement('div');
            this.rollerDiv.appendChild(this.getRollerPanel());
            this.rollerDiv.appendChild(this.getRollerToolsPanel());
        }
        return this.rollerDiv;
    }

    /**
     * Lazy getter of the Roller panel
     */
    private getRollerPanel(): HTMLDivElement {
        const me: Roller = this;
        if (!this.rollerPanel) {
            this.rollerPanel = document.createElement('div');
            this.config?.panelClasses?.forEach((clazz) => me.rollerPanel.classList.add(clazz));

            this.columns?.forEach((col: RollerColumn) => {
                this.rollerPanel.appendChild(col.getUI());
            });
            this.rollerPanel.appendChild(this.getRollerGlass());
        }
        return this.rollerPanel;
    }
    /**
     * Lazy getter of the Roller tools div
     */
    private getRollerToolsPanel(): HTMLDivElement {
        if (!this.rollerToolsPanel) {
            this.rollerToolsPanel = document.createElement('div');
            this.rollerToolsPanel.classList.add('roller-tools');
            this.rollerToolsPanel.appendChild(this.getOkButton());
            this.rollerToolsPanel.appendChild(this.getCancelButton());
        }
        return this.rollerToolsPanel;
    }

    /**
     * Lazy getter of the Roller ok button
     */
    private getOkButton(): HTMLDivElement {
        const me: Roller = this;
        if (!this.rollerOkButton) {
            this.rollerOkButton = document.createElement('div');
            this.rollerOkButton.classList.add('roller-ok-button');
            this.rollerOkButton.addEventListener('click', () => {
                me.getEventManager().trigger({ type: RollerEventType.OK_ACTION });
            });
        }
        return this.rollerOkButton;
    }

    /**
     * Lazy getter of the Roller cancel button
     */
    private getCancelButton(): HTMLDivElement {
        const me: Roller = this;
        if (!this.rollerCancelButton) {
            this.rollerCancelButton = document.createElement('div');
            this.rollerCancelButton.classList.add('roller-cancel-button');
            this.rollerCancelButton.addEventListener('click', () => {
                me.getEventManager().trigger({ type: RollerEventType.CANCEL_ACTION });
            });
        }
        return this.rollerCancelButton;
    }

    /**
     * Lazy getter of the Roller Glass
     */
    private getRollerGlass(): HTMLDivElement {
        const me: Roller = this;
        if (!this.rollerGlass) {
            this.rollerGlass = document.createElement('div');
            this.config?.glassClasses?.forEach((clazz) => me.rollerGlass.classList.add(clazz));
        }
        return this.rollerGlass;
    }
}
