/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { deepCopy } from '../../../tpz-application/tools/deep-copy';

/** Value editor options  */
export interface TpzValueEditorOptions<ValueType> {
    onBeforeValueChanged?: (value: ValueType) => ValueType;
    onValueChanged?: () => void;
    liveUpdate?: boolean;
}

/** default values for Value editor options */
const defaultTpzValueEditorOptions: TpzValueEditorOptions<any> = {
    liveUpdate: false
};

/** base class of all value editors */
export abstract class TpzValueEditor<ValueType> {
    private setValueFunction: (v: ValueType) => void = null;
    private getValueFunction: () => ValueType = null;
    private compareValueFunction: (v1: ValueType, v2: ValueType) => boolean = null;
    private readonly obj: any = null; // object containing the property to modify
    private readonly property: string = null; // property name to modify in the given object
    private readonly options: TpzValueEditorOptions<ValueType> = {};

    /**
     *  constructor
     * - obj is the object containing the property to edit
     * - property is the name of the field in the object (obj.property will be modified)
     * - set: setter function of type ValueType
     * - get: getter function of type ValueType
     * - set: comparison function of two object typed ValueType
     */
    constructor(
        obj: any,
        property: string,
        set: (v: ValueType) => void,
        get: () => ValueType,
        eq: (v1: ValueType, v2: ValueType) => boolean,
        options: TpzValueEditorOptions<ValueType>
    ) {
        this.setValueFunction = set || this.setPrimitiveObject.bind(this);
        this.getValueFunction = get || this.getPrimitiveObject.bind(this);
        this.compareValueFunction = eq;
        this.obj = obj;
        this.property = property;
        this.options = deepCopy(defaultTpzValueEditorOptions, options);
    }

    private setPrimitiveObject(v: any): void {
        if (typeof this.getObj() === 'undefined' || this.getObj() == null) return;
        this.getObj()[this.getPropertyName()] = v;
    }

    private getPrimitiveObject(): any {
        if (typeof this.getObj() === 'undefined' || this.getObj() == null) return null;
        return this.getObj()[this.getPropertyName()];
    }

    /** get editor options */
    public getOptions(): TpzValueEditorOptions<ValueType> {
        return this.options;
    }

    /** set setter value function */
    public setSetter(setter: (v: ValueType) => void): void {
        this.setValueFunction = setter;
    }

    /** set getter value function */
    public setGetter(getter: () => ValueType): void {
        this.getValueFunction = getter;
    }

    /** set compare value function */
    public setCompare(compare: (v1: ValueType, v2: ValueType) => boolean): void {
        this.compareValueFunction = compare;
    }

    /** object getter */
    public getObj(): any {
        return this.obj;
    }

    /** property getter */
    public getPropertyName(): string {
        return this.property;
    }

    /** value getter */
    public getValue(): ValueType {
        return this.getValueFunction();
    }

    /** value setter */
    public setValue(value: ValueType): boolean {
        if (this.options.onBeforeValueChanged) value = this.options.onBeforeValueChanged(value);
        if (this.compareValueFunction(this.getValueFunction(), value)) {
            return false;
        }
        // set the value
        this.setValueFunction(value);
        // update UI
        this.updateUI();
        if (this.options.onValueChanged) this.options.onValueChanged();
        return true;
    }

    /** value comparison */
    public compare(value1: ValueType, value2: ValueType): boolean {
        if (typeof value1 === 'undefined' && typeof value2 === 'undefined') return true;
        if (typeof value1 === 'undefined' && typeof value2 !== 'undefined') return false;
        if (typeof value1 !== 'undefined' && typeof value2 === 'undefined') return false;
        if (value1 === null && value2 === null) return true;
        if (value1 !== null && value2 === null) return false;
        if (value1 === null && value2 !== null) return false;
        return this.compareValueFunction(value1, value2);
    }

    /** Value Editor type */
    public abstract getType(): string;

    /** value type getter */
    public abstract getValueType(): string;

    // /** value getter */
    // public abstract getUIValue(): ValueType;

    // /** value setter */
    // public abstract setUIValue(value: ValueType): boolean;

    /** create UI */
    public abstract createUI(): HTMLElement;

    /** update UI content */
    public abstract updateUI(): boolean;
}

/** TpzValueEditor factory */
export abstract class TpzValueEditorFactory {
    public abstract create(type: string, obj: any, propertyName: string): TpzValueEditor<any>;
}
