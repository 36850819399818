/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger, LoggerMessage } from '../../../../tpz-log/tpz-log-core';
import { DateFormat } from '../../../tools/dateformat';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';

/**
 * window managing logs.
 * It creates a
 */
export class LoggerUI {
    public static readonly LOGGER_MODULE_TYPE: string = 'LoggerModuleType';
    private mainContainer: HTMLDivElement = null;
    private ulElement: HTMLUListElement = null;
    private readonly loggers: Logger[] = []; // listened loggers
    private readonly application: TpzApplication = null;
    private unseenMessages: { [type: number]: number } = {};
    private unseenCallback: (loggerUI: LoggerUI) => void = null; // callback on unseen update

    /**
     * constructor
     */
    constructor(application: TpzApplication) {
        this.application = application;
        this.addLogger(this.getApplication().getLogger());
    }

    /**
     * Get this logger Id.
     * !!!! only one loggher UI per Application allowed, due to the Id computation !!!!
     */
    private getId(): string {
        return 'logger-ui-' + this.getApplication()?.getId();
    }
    /**
     * Application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * Application event callback.
     * @param event received application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        switch (event.type) {
            case TpzApplicationEventType.LOGGER_CHANGED:
                TpzApplicationEventType.checkEvent(event, null);
                // if the application event is changed, listen to the new logger
                this.addLogger(event.content.logger);
                return true;
        }
        return false;
    }

    /**
     * Add a logger to listen to
     * @param logger logger to listen and display messages
     */
    public addLogger(logger: Logger): void {
        this.loggers.push(logger);
        logger.getEventManager().register(this.getId(), this.onLoggerMessage.bind(this));
    }

    /**
     * Logger event callback
     */
    public onLoggerMessage(message: LoggerMessage): boolean {
        this.addListLineUI(message);
        return true;
    }

    /**
     * type getter
     */
    public getType(): string {
        return LoggerUI.LOGGER_MODULE_TYPE;
    }

    /**
     * get the list element containing all logger lines
     */
    private getULElement(): HTMLUListElement {
        if (!this.ulElement) {
            this.ulElement = document.createElement('ul');
            this.ulElement.classList.add('logger-list');
        }
        return this.ulElement;
    }

    /**
     * get the main container div
     */
    private getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('logger-container');
            this.mainContainer.appendChild(this.getULElement());
        }
        return this.mainContainer;
    }

    /**
     * Sum only the unseen messages with low severity level (lower than ERROR)
     * @param maxLevel : the max level to be compared with the message level
     */
    public getUnseenCount(maxLevel: number): number {
        let sum: number = 0;
        for (const [type, number] of Object.entries(this.unseenMessages)) {
            if (Number(type) <= maxLevel) {
                sum += number;
            }
        }
        return sum;
    }

    /**
     * Sum only unseen messages with high severitu level (higher thar ERROR)
     * @param minLevel: the min level to be compared with the message level
     */
    public getUnseenCountFiltered(minLevel: number): number {
        let sum: number = 0;
        for (const [type, number] of Object.entries(this.unseenMessages)) {
            if (Number(type) >= minLevel) {
                sum += number;
            }
        }
        return sum;
    }

    /**
     * get all unseen messages detail
     */
    public getUnseenMessages(): { [id: number]: number } {
        return this.unseenMessages;
    }

    /** Set unseen update calbbcak */
    public setUnseenUpdateCallback(cb: () => void) {
        this.unseenCallback = cb;
    }

    /**
     * Clear all unseen messages
     */
    public clearUnseenMessages(): void {
        this.unseenMessages = {};
        this.updateUnseenUI();
    }

    /**
     * Format date to string
     * @param date date to be formatted
     */
    public formatDate(date: Date): string {
        return DateFormat.format(date, 'ddd mmm dd yyyy HH:MM:ss');
    }

    /**
     * Format level to string
     * @param level level number to be formatted
     */
    public formatLevel(level: number): string {
        return Logger.DEFAULT_LEVEL_NAMES[level];
    }

    /** add message in UI */
    private addMessageLine(message: LoggerMessage, parent: HTMLElement): void {
        if (!message) return;
        if (!parent) throw new Error('Cannot add message line in null parent element (' + message.content + ')');
        parent.classList.add('level-' + this.formatLevel(message.level));
        parent.innerHTML =
            "<span class='date'>" +
            this.formatDate(new Date(message.date)) +
            '</span>' +
            "<span class='level'>" +
            this.formatLevel(message.level) +
            '</span>' +
            "<span class='content'>" +
            message.content +
            '</span>';
    }

    /**
     * add a UI Line. Update the unseen count
     */
    private addListLineUI(message: LoggerMessage): void {
        if (!message) return;
        // increment unseen messages
        this.incrementUnseenMessages(message.level);
        const liElement: HTMLLIElement = document.createElement('li');
        this.addMessageLine(message, liElement);
        this.getULElement().insertAdjacentElement('afterbegin', liElement);
    }

    /**
     * Manages the unseen messages
     * @param level new message level
     */
    private incrementUnseenMessages(level: number) {
        if (typeof this.unseenMessages[level] == 'undefined') {
            this.unseenMessages[level] = 1;
        } else {
            this.unseenMessages[level] = this.unseenMessages[level] + 1;
        }
        this.updateUnseenUI();
    }

    /**
     * Update UI for unseen messages
     */
    private updateUnseenUI(): void {
        if (this.unseenCallback) this.unseenCallback(this);
    }

    /**
     * create Main User Interface
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Invalidate LoggerModule UI
     */
    public invalidateUI(): void {
        this.ulElement = null;
    }
}
