/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { defaultTpzPluginConfig, TpzPlugin, TpzPluginConfig } from '../../tpz-plugin-core';
import { TpzApplicationEvent } from '../../../tpz-application-event';
import { TpzApplicationCommander } from '../../../tpz-application-commander';
import { TpzApplicationState } from '../../../tpz-application-state';
import { uuidv4 } from '../../../tools/uuid';
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { deepCopy } from '../../../tools/deep-copy';

const STATE_IO_PLUGIN_TYPE: string = 'StateIOPluginUIType';

/**
 *  Plugin configuration
 */
export interface StateIOPluginUIConfig extends TpzPluginConfig {
    parentId?: string;
    allowDropClass?: string;
    disallowDropClass?: string;
}

export const defaultStateIOPluginUIConfig: StateIOPluginUIConfig = {
    ...defaultTpzPluginConfig,
    id: 'state-io-plugin-ui',
    type: STATE_IO_PLUGIN_TYPE,
    parentId: 'menu-id',
    allowDropClass: 'allow',
    disallowDropClass: 'disallow'
};

/**
 * State Input/Output plugin
 * click on the icon to copy application state to clipboard
 * drag a .json file on icon to load application state
 */
export class StateIOPluginUI extends TpzPlugin {
    public static readonly STATE_IO_PLUGIN_TYPE: string = STATE_IO_PLUGIN_TYPE; // plugin type
    private contentDiv: HTMLDivElement = null;
    private logoDiv: HTMLDivElement = null;
    private lastDroppableItem: DataTransferItem = null; // store droppable item to avoid multiple notification
    private lastDroppableItemTimeout: any = null; // timeout to remove lastDroppableItem
    private static readonly LAST_DROPPABLE_ITEM_TIMEOUT: number = 1000;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: StateIOPluginUIConfig) {
        super(deepCopy(defaultStateIOPluginUIConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): StateIOPluginUIConfig {
        return super.getConfig() as StateIOPluginUIConfig;
    }

    /**
     * get logoDiv class name when drop is allowed
     */
    public getAllowClassName(): string {
        return this.getConfig().allowDropClass || 'allow';
    }

    /**
     * get logoDiv class name when drop is refused
     */
    public getDisallowClassName(): string {
        return this.getConfig().disallowDropClass || 'disallow';
    }

    /**
     * Set UI when drop is allowed
     */
    private allowUI(): void {
        this.getLogoDiv().classList.add(this.getAllowClassName());
        this.getLogoDiv().classList.remove(this.getDisallowClassName());
    }

    /**
     * Set UI when drop is refused
     */
    private disallowUI(): void {
        this.getLogoDiv().classList.add(this.getDisallowClassName());
        this.getLogoDiv().classList.remove(this.getAllowClassName());
    }

    /**
     * Set UI when nor allow or disallow is selected
     */
    private classicUI(): void {
        this.getLogoDiv().classList.remove(this.getDisallowClassName());
        this.getLogoDiv().classList.remove(this.getAllowClassName());
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        // switch (event.type) {
        //     case TpzApplicationEventType.:
        //         TpzApplicationEventType.checkEvent(event, null);
        //         this.updateUI();
        //         break;
        // }
        return super.onApplicationEvent(event);
    }

    /**
     * Action to perform when plugin plugs in
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then(() => {
            this.insertDiv();
            return this;
        });
    }

    /**
     * Action to perform when plugin unplugs
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onUnplug().then(() => {
            this.removeDiv();
            return this;
        });
    }

    /**
     * Return menu parent or body if not defined
     */
    public getParent(): HTMLElement {
        if (this.getConfig().parentId) {
            const parent: HTMLElement = document.getElementById(this.getConfig().parentId);
            if (!parent) {
                this.getLogger()?.error(
                    'StateIO plugin ' +
                        this.getId() +
                        ' cannot be added to inexisting parent ' +
                        this.getConfig().parentId
                );
                return null;
            }
            return parent;
        }
        return document.body;
    }

    /**
     * Insert Menu in DOM
     */
    private insertDiv(): void {
        this.getParent()?.appendChild(this.getContentDiv());
    }

    /**
     * Remove Menu from DOM
     */
    private removeDiv(): void {
        if (this.contentDiv) this.getParent()?.removeChild(this.contentDiv);
    }

    /**
     * div content lazy getter
     */
    public getContentDiv(): HTMLDivElement {
        if (!this.contentDiv) {
            this.contentDiv = document.createElement('div');
            this.contentDiv.innerHTML = '';
            this.contentDiv.classList.add('state-io-plugin-ui');
            this.contentDiv.addEventListener('click', (event: MouseEvent) =>
                this.copyApplicationStateToClipboard(event)
            );
            this.contentDiv.addEventListener('dragenter', (event: DragEvent) => this.allowDropNotification(event));
            this.contentDiv.addEventListener('dragover', (event: DragEvent) => this.allowDrop(event));
            this.contentDiv.addEventListener('drop', (event: DragEvent) => this.drop(event));
            this.contentDiv.appendChild(this.getLogoDiv());
        }
        return this.contentDiv;
    }

    /**
     * logo div lazy getter (contained in contentDiv)
     */
    public getLogoDiv(): HTMLDivElement {
        if (!this.logoDiv) {
            this.logoDiv = TpzApplicationUI.createDiv();
        }
        return this.logoDiv;
    }

    /**
     * Check if currently dragged element can be dropped
     * @param event drag event containing dragged element
     */
    private allowDrop(event: DragEvent): boolean {
        event.preventDefault();
        const isFiles = event.dataTransfer.types.includes('Files');
        if (!isFiles) {
            this.disallowUI();
            return false;
        }
        if (!event.dataTransfer?.items) {
            this.disallowUI();
            return false;
        }
        if (event.dataTransfer?.items.length !== 1) {
            this.disallowUI();
            return false;
        }
        // Use DataTransferItemList interface to access the file
        const dataItem: DataTransferItem = event.dataTransfer.items[0];
        // If dropped items aren't files, reject them
        if (dataItem.kind !== 'file') {
            this.disallowUI();
            return false;
        }
        if (dataItem.type !== 'application/json') {
            this.disallowUI();
            return false;
        }
        this.allowUI();
        return true;
    }

    /**
     * Check if currently dragged element can be dropped
     * @param event drag event containing dragged element
     */
    private allowDropNotification(event: DragEvent): boolean {
        event.preventDefault();
        const isFiles = event.dataTransfer.types.includes('Files');
        if (!isFiles) {
            this.disallowUI();
            return false;
        }
        if (!event.dataTransfer?.items) {
            this.disallowUI();
            return false;
        }
        if (event.dataTransfer?.items.length !== 1) {
            this.disallowUI();
            return false;
        }
        // Use DataTransferItemList interface to access the file
        const dataItem: DataTransferItem = event.dataTransfer.items[0];
        // If dropped items aren't files, reject them
        if (dataItem.kind !== 'file') {
            this.getApplication().sendNotification(
                'ERROR',
                "item should be of type 'file' (not " + dataItem.kind + ')'
            );
            this.disallowUI();
            return false;
        }
        if (dataItem.type !== 'application/json') {
            this.getApplication().sendNotification(
                'ERROR',
                "allow only MIME file type 'application/json' (not " + dataItem.type + ')'
            );
            this.disallowUI();
            return false;
        }
        if (this.lastDroppableItemTimeout) clearTimeout(this.lastDroppableItemTimeout);
        this.lastDroppableItemTimeout = setTimeout(() => {
            this.lastDroppableItem = null;
            this.lastDroppableItemTimeout = null;
            this.classicUI();
        }, StateIOPluginUI.LAST_DROPPABLE_ITEM_TIMEOUT);

        this.getApplication().sendNotification(
            'INFO',
            'last = ' + this.lastDroppableItem + ' item = ' + dataItem + ' !=? ' + (this.lastDroppableItem !== dataItem)
        );
        if (this.lastDroppableItem !== dataItem) {
            this.lastDroppableItem = dataItem;
            this.getApplication().sendNotification('INFO', 'Drop this state file to load application state');
        }
        this.allowUI();
        return true;
    }

    /**
     * Check if currently dragged element can be dropped
     * @param event drag event containing dragged element
     */
    private drop(event: DragEvent): void {
        if (!this.allowDrop(event)) {
            this.getLogger().error('drop not allowed');
            return;
        }
        const file: File = event?.dataTransfer?.items[0]?.getAsFile();
        if (!file) {
            this.getLogger().error('cannot get item as file...');
            this.disallowUI();
            return;
        }
        const reader: FileReader = new FileReader();
        reader.onload = () => {
            TpzApplicationCommander.setApplicationState(this.getApplication(), JSON.parse(reader.result as string));
            this.classicUI();
        };
        reader.readAsText(file);
    }

    /**
     * Copy the application state to the clipboard
     * @param event
     */
    private copyApplicationStateToClipboard(event: MouseEvent): void {
        const id: string = 'dragged' + uuidv4();
        const state: TpzApplicationState = this.getApplication().getApplicationState(id, id);
        this.copyToClipboard(JSON.stringify(state, null, 2))
            .then(() => {
                this.getApplication().sendNotification(
                    'INFO',
                    'Application State copied to clipboard as Text. You can paste it into a .json file'
                );
            })
            .catch((reason: any) => {
                this.getApplication().sendNotification(
                    'WARNING',
                    'Application State NOT copied to clipboard: ' + reason
                );
            });
    }

    /**
     * Copy text to clipboard using navigator.clipboard or a trick if undefined.
     * in unsecured environnment, clipboard is not allowed
     * @param textToCopy
     * @returns
     */
    private copyToClipboard(textToCopy: string): Promise<void> {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) => {
                // here the magic happens
                document.execCommand('copy') ? resolve() : reject();
                textArea.remove();
            });
        }
    }
    /**
     * Update the plugin UI
     */
    public updateUI(): void {
        //
    }
}
