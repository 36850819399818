/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../../tpz-log/tpz-log-core';
import { LoadHelper } from '../tools/loader';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationEvent } from '../tpz-application-event';
import { deepCopy } from '../tools/deep-copy';

/**
 *  Plugin configuration
 */
export interface TpzPluginConfig {
    id?: string; // plugin id
    type?: string; // plugin type
    pluginDependencies?: string[]; // plugin IDs which should be launched before this one
    css?: string[]; // list of css to be loaded at startup
}

/**
 * Default values for plugins
 */
export const defaultTpzPluginConfig: TpzPluginConfig = {
    id: undefined,
    type: undefined,
    pluginDependencies: [],
    css: []
};

/**
 * Plugins are topaz application extensions
 */
export abstract class TpzPlugin {
    private application: TpzApplication = null;
    private readonly config: TpzPluginConfig = null;
    private running: boolean = false;
    private loader: Promise<TpzPlugin> = null; // promise created on plug call
    private unloader: Promise<TpzPlugin> = null; // promise created on unplug call

    /**
     * Constructor
     */
    constructor(config: TpzPluginConfig) {
        if (!config.id) {
            throw new Error('Plugin configuration should have a valid ID');
        }
        if (!config.type) {
            throw new Error(`Plugin configuration #${config.id} should have its type defined`);
        }
        this.config = deepCopy(defaultTpzPluginConfig, config);
    }

    /**
     * Configuration getter
     */
    public getConfig(): TpzPluginConfig {
        return this.config;
    }

    /**
     * Get logger (use the application logger)
     */
    public getLogger(): Logger {
        return this.getApplication()?.getLogger();
    }

    /**
     * Fires an event (use the application logger)
     */
    public fireEvent(type: string, content: any, categories: string[], time?: number): void {
        this.getApplication()?.fireEvent(this.getId(), type, content, categories, time);
    }

    /**
     * Return true if plugin is loaded
     */
    public getRunning(): boolean {
        return this.running;
    }

    /**
     * get Application CSS
     */
    public getCss(): string[] {
        return this.getConfig().css;
    }

    /**
     * Load all CSS files. Return a Promise.all on all loaders
     * (resolved when all are resolved)
     */
    public loadCss(): Promise<void> {
        const me: TpzPlugin = this;
        if (!this.getCss()) return Promise.resolve();
        return LoadHelper.loadCSSs(this.getCss(), this.getLogger());
    }

    /**
     * Id getter
     */
    public getId(): string {
        return this.getConfig()?.id;
    }

    /**
     * Application getter. The application is set when the pulgin is plugged
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * Application setter. The application is set when the pulgin is plugged
     */
    protected setApplication(app: TpzApplication): void {
        this.application = app;
    }

    /**
     * plug this plugin in application.
     * @param app application in which the plugin is added
     */
    public plug(app: TpzApplication): Promise<TpzPlugin> {
        const me: TpzPlugin = this;
        // if it is running return immediately
        if (this.running) return Promise.resolve(this);
        // if it is loading
        if (this.loader) {
            if (this.getApplication() !== app) {
                throw new Error('Plugin is already plugged in another application ' + this.getApplication().getId());
            }
            return this.loader;
        }
        this.setApplication(app);
        this.getApplication().getEventManager().register(this.getId(), this.onApplicationEvent.bind(this));
        // load css, but plug plugin even if css returns an error
        this.loader = this.loadCss()
            .then(() => null)
            .finally(() => {
                return this.onPlug().then((plugin: TpzPlugin) => {
                    plugin.running = true;
                    plugin.loader = null;
                    this.getLogger()?.info('Plugin #' + this.getId() + ' plugged into application ' + app.getId());
                    return plugin;
                });
            });
        return this.loader;
    }

    /**
     * Unplug this plugin from application.
     * @param app application from which the plugin is removed
     */
    public unplug(): Promise<TpzPlugin> {
        if (this.unloader) return this.unloader;
        // if there is a loader, first finish loader before unloading
        if (this.loader) {
            return this.loader.then((plugin: TpzPlugin) => {
                this.loader = null;
                return this.unplug();
            });
        }
        // if unloader is in progress return it
        if (this.unloader) return this.unloader;
        // launch unplugger promise
        return this.onUnplug().then((plugin: TpzPlugin) => {
            this.running = false;
            this.unloader = null;
            this.getApplication()?.getEventManager().unregister(this.getId());
            this.setApplication(null);
            return this;
        });
    }

    /**
     * action to perform when plugin is added.
     */
    protected onPlug(): Promise<TpzPlugin> {
        return Promise.resolve(this);
    }

    /**
     * action to perform when plugin is removed.
     */
    protected onUnplug(): Promise<TpzPlugin> {
        return Promise.resolve(this);
    }

    /**
     * React to application events. This method can be overloaded
     * Default behaviour is: do nothing
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        return false;
    }

    /**
     * update Plugin. This method can be overloaded
     * Default behaviour is: do nothing
     */
    public update(): Promise<void> {
        return Promise.resolve();
    }
}
