/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventManager } from '../../tpz-event/tpz-event-core';
import { TpzApplicationEventType } from '../tpz-application-event';
import { TpzApplicationCommander } from '../tpz-application-commander';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationEvent } from '../tpz-application-event';

/**
 * Description of a simulated time
 * realStartTime is the Date().getTime() when the simulated time started
 * simulatedStartTime is the simulated time start
 * simulatedTimeFactor is the number of seconds a real second represent in simulated time
 *
 * current simulated time = ( new Date().getTime() - realStartTime) * simulatedTimeFactor + simulatedTimeStart
 */
export interface SimulatedTimeDescriptor {
    realStartTime: number;
    simulatedStartTime: number; // null value is equal to NOW
    simulatedTimeFactor: number;
    rangeStartTime: number;
    rangeEndTime: number;
    defaultRangeTime: number; // if rangeStart/End are null use this for computation (in milliseconds)
    playing: boolean;
}

/** events managed by this simulated time plugin */
export class SimulatedTimeEventType {
    public static readonly REQUEST_TIME_DESCRIPTOR = 'REQUEST_TIME_DESCRIPTOR'; // external elements can ask for a new time content: null
    public static readonly REBASE_SIMULATED_TIME: string = 'REBASE_SIMULATED_TIME'; //send a new valid time descriptor content: { timeDescriptor: TimeDescriptor }
    public static readonly LIVE_SIMULATED_TIME: string = 'LIVE_SIMULATED_TIME'; //send a new valid time descriptor content: { liveTime: number }
    public static readonly REQUEST_REBASE_SIMULATED_TIME: string = 'REQUEST_REBASE_SIMULATED_TIME'; // external elements can request to change the simulated time content: { timeDescriptor: TimeDescriptor }
}

/**
 * Event fired by TimeRunner
 */
export interface TimeRunnerEvent {
    type: string;
    content: any;
}

/** events managed by a time runner */
export class TimeRunnerEventType {
    public static readonly RUNNER_STATE_CHANGED: string = 'RUNNER_STATE_CHANGED'; // external elements can request to change the simulated time content: { oldState: SimulatedTimeRunnerState, newState: SimulatedTimeRunnerState}
}

/**
 * Runner states
 */
export enum TimeRunnerState {
    UNKNOWN = 'UNKNOWN',
    ACTIVE = 'ACTIVE',
    PAUSED = 'PAUSED',
    INACTIVE = 'INACTIVE'
}
/**
 * A simulated time runner is a process which handles how time is refreshed
 * It generates an javascript interval function launching a callback periodically
 * It listens to Application Event in order to reflect time mangement changes
 * No need to start or stop this Runner, it is automatically synchronized with Application
 * using Application Events
 */
export class SimulatedTimeRunner {
    public static readonly MIN_REFRESH_RATE: number = 10; // min allowed refresh rate

    private readonly id: string = 'no-id-time-runner';
    private eventManager: EventManager<any> = null;
    private readonly application: TpzApplication = null;
    private timeDescriptor: SimulatedTimeDescriptor = null;
    private interval: any = null;
    private intervalRate: number = 500; // time interval rate in milliseconds
    private callbackInProgress: boolean = false; // true if the callback has been launched and is in progress
    private callbackRejected: boolean = false; // true if a callback launch has been rejected due to progress
    private callback: any = null;
    private state: TimeRunnerState = TimeRunnerState.UNKNOWN;

    // private timeDescriptor: SimulatedTimeDescriptor = null;

    /**
     * Runner Constructor. Do not launch the runner. you should call
     * - requestUpdate (which ask for a new time descriptor)
     * - setTimeDescriptor (which launches the runner if time descriptor is running)
     * @param eventManager  Plugin Managing Time listened for events
     */
    constructor(id: string, application: TpzApplication, callback: any, rate: number = 500) {
        this.id = id;
        this.application = application;
        this.intervalRate = rate;
        this.callback = callback;
        this.startRunner();
    }

    // /**
    //  * get the runner state
    //  */
    // public getState(): TimeRunnerState {
    //     return this.state;
    // }

    /**
     * get the application
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * get the id
     */
    public getId(): string {
        return this.id;
    }

    /**
     * set the runner state. This state tells if the runner is active or not.
     * if doesn't tell if the runner is running !
     */
    private setState(state: TimeRunnerState): void {
        if (this.state === state) return;
        const changeStateEventContent: any = { oldState: this.state, newState: state };
        this.state = state;
        this.getEventManager().trigger({
            type: TimeRunnerEventType.RUNNER_STATE_CHANGED,
            content: changeStateEventContent
        });
    }

    /**
     * get the runner state. This state tells if the runner is active or not.
     * if doesn't tell if the runner is running !
     */
    public getState(): TimeRunnerState {
        return this.state;
    }

    /**
     * Set interval callback
     */
    public setCallback(callback: any): void {
        this.callbackInProgress = false;
        this.stopTimeLoop();
        this.callback = callback;
        this.startTimeLoop();
    }

    /**
     * Set interval rate (in milliseconds)
     */
    public setIntervalRate(rate: number): void {
        if (!rate) return;
        if (rate <= 0) throw new Error(`TimeRunner update rate ${rate} is invalid`);

        this.stopTimeLoop();
        // do not allow rate less than a minimum (MIN_REFRESH_RATE in milliseconds)
        this.intervalRate = Math.max(SimulatedTimeRunner.MIN_REFRESH_RATE, rate);
        this.startTimeLoop();
    }

    /**
     * Event Manager lazy getter
     */
    public getEventManager(): EventManager<TimeRunnerEvent> {
        if (!this.eventManager) {
            this.eventManager = new EventManager();
        }
        return this.eventManager;
    }

    /**
     * Time Descriptor getter
     */
    public getTimeDescriptor(): SimulatedTimeDescriptor {
        return this.timeDescriptor;
    }

    /**
     * get the current simulated time.
     * Simulated time depends on current real time if running
     */
    public getSimulatedTime(): number {
        return SimulatedTimeHelper.getSimulatedTime(this.getTimeDescriptor());
    }

    /**
     * Time Descriptor setter
     * @param timeDescriptor new Time descriptor to set
     * @returns true if time has been changed, false if identical
     */
    public setTimeDescriptor(timeDescriptor: SimulatedTimeDescriptor): boolean {
        if (SimulatedTimeHelper.timeDescriptorEquals(this.timeDescriptor, timeDescriptor)) return false;
        this.timeDescriptor = { ...timeDescriptor };

        this.stopTimeLoop(); // if a runner is running, stop it cause time descriptor has changed
        this.startTimeLoop(); // try to start time loop
        return true;
    }

    /**
     * This method send an event to get the current time descriptor
     */
    public requestUpdate(): void {
        this.getApplication().fireEvent(this.getId(), SimulatedTimeEventType.REQUEST_TIME_DESCRIPTOR, null, []);
    }

    /**
     * Stop the runner
     */
    public stopRunner(): void {
        this.stopTimeLoop();
        this.getApplication().getEventManager()?.unregister(this.getId());
        this.setState(TimeRunnerState.INACTIVE);
    }

    /**
     * Start or update runner.
     * Multiple start() calls are equivalent to update()
     */
    public startRunner(): void {
        this.getApplication().getEventManager()?.register(this.getId(), this.onApplicationEvent.bind(this));
        this.setState(TimeRunnerState.ACTIVE);
        this.startTimeLoop();
    }

    /**
     * Start or update runner.
     * Multiple start() calls are equivalent to update()
     */
    private startTimeLoop(): void {
        // start time loop only if the runner is currently running
        if (this.getState() != TimeRunnerState.ACTIVE) return;
        const timeDescriptor: SimulatedTimeDescriptor = this.getTimeDescriptor();
        if (timeDescriptor && timeDescriptor.playing && timeDescriptor.simulatedTimeFactor != 0) {
            // if the time loop hasn't been stopped ! stop it
            if (this.interval) {
                this.stopTimeLoop();
            }
            this.callbackInProgress = false;
            this.interval = window.setInterval(this.callbackLauncher.bind(this), this.intervalRate);
        }
    }

    /**
     * Stop time if it is currently looping
     */
    private stopTimeLoop(): void {
        if (this.interval) {
            window.clearInterval(this.interval);
            this.interval = null;
            this.callbackInProgress = false;
        }
    }

    /**
     * tells if the runner is currently looping (calling callback regularly)
     */
    public isLooping(): boolean {
        return this.interval;
    }

    /**
     * Callback launcher which launches a new callback only if the previous one is not launched
     */
    private callbackLauncher(): void {
        if (!this.callback) return;
        if (this.callbackInProgress) {
            this.callbackRejected = true;
            return;
        }
        try {
            this.callbackInProgress = true;
            this.callbackRejected = false;
            this.callback();
        } finally {
            this.callbackInProgress = false;
            // if a callback launch has been requested relaunch it in case of something changed
            // during previous call
        }
    }

    /**
     * Listening to Time Manager events
     * @param event time manager event
     */
    private onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case SimulatedTimeEventType.REBASE_SIMULATED_TIME:
                TpzApplicationEventType.checkEvent(event, 'timeDescriptor');
                this.setTimeDescriptor(event.content.timeDescriptor);
                return true;
        }
        return false;
    }
}

/**
 * Helper function for simulated time management
 */
export class SimulatedTimeHelper {
    /**
     * Compare two TimeDescriptor objects and return true if they are equal
     * @param t1 first time descriptor
     * @param t2 second time descriptor
     */
    public static timeDescriptorEquals(t1: SimulatedTimeDescriptor, t2: SimulatedTimeDescriptor): boolean {
        if (!t1 && !t2) return true;
        if ((t1 && !t2) || (!t1 && t2)) return false;
        return (
            t1.realStartTime === t2.realStartTime &&
            t1.simulatedStartTime === t2.simulatedStartTime &&
            t1.simulatedTimeFactor === t2.simulatedTimeFactor &&
            t1.rangeStartTime === t2.rangeStartTime &&
            t1.rangeEndTime === t2.rangeEndTime &&
            t1.playing === t2.playing
        );
    }

    /**
     * Get the simulated start time of a time Descriptor.
     * If not set compute the time range medium value
     * or now if range is not defined too
     */
    public static getSimulatedStartTime(timeDescriptor: SimulatedTimeDescriptor): number {
        if (!timeDescriptor) return null;
        // if startTime is defined, return it
        if (Number.isFinite(timeDescriptor.simulatedStartTime)) return timeDescriptor.simulatedStartTime;
        // if range Time is defined, return the middle range time
        const rangeStartTime: number = SimulatedTimeHelper.getSimulatedStartTimeRange(timeDescriptor);
        const rangeEndTime: number = SimulatedTimeHelper.getSimulatedEndTimeRange(timeDescriptor);
        if (Number.isFinite(rangeStartTime) && Number.isFinite(rangeEndTime)) {
            return (rangeStartTime + rangeEndTime) / 2;
        }
        // or return the current time
        const now: number = new Date().getTime();
        return now;
    }

    /**
     * Return the current time descriptor (with a time factor = 1 and playing = true)
     */
    public static createNowTimeDescriptor(defaultTimeRange: number): SimulatedTimeDescriptor {
        const nowTime: number = new Date().getTime();
        const nowDescriptor: SimulatedTimeDescriptor = {
            realStartTime: nowTime,
            simulatedStartTime: null,
            simulatedTimeFactor: 1,
            rangeStartTime: null,
            rangeEndTime: null,
            defaultRangeTime: defaultTimeRange,
            playing: true
        };
        return nowDescriptor;
    }

    /**
     * Return the current time descriptor (with a time factor = 1 and playing = true)
     */
    public static createNewDateTimeDescriptor(simulatedTime: number): SimulatedTimeDescriptor {
        const nowDescriptor: SimulatedTimeDescriptor = {
            realStartTime: null,
            simulatedStartTime: simulatedTime,
            simulatedTimeFactor: 1,
            rangeStartTime: null,
            rangeEndTime: null,
            defaultRangeTime: null,
            playing: false
        };
        return nowDescriptor;
    }

    /**
     * Compute the current time n milliseconds from a time descriptor
     * @param desc Time descriptor
     */
    public static getSimulatedTime(desc: SimulatedTimeDescriptor): number {
        if (!desc) return null;
        const now: number = new Date().getTime();
        // if no simulation time return NOW;
        if (desc.simulatedStartTime === null) return now;
        // if timeFactor is 0: stay at simulated start time
        if (desc.simulatedTimeFactor === 0 || !desc.playing) return desc.simulatedStartTime;
        // compute elapsed time: real elapsed time * time factor + simulated time
        return (now - desc.realStartTime) * desc.simulatedTimeFactor + desc.simulatedStartTime;
    }

    /**
     * Compute the time range in milliseconds from a time descriptor
     * @param timeDescriptor Time descriptor
     */
    public static getSimulatedStartTimeRange(timeDescriptor: SimulatedTimeDescriptor): number {
        if (!timeDescriptor) return null;
        // if rangeStartTime is defined, return it
        const rangeStartTime: number = timeDescriptor.rangeStartTime;
        if (Number.isFinite(rangeStartTime)) return timeDescriptor.rangeStartTime;
        // compute the simulatedStartTime - defaultRange / 2
        if (Number.isFinite(timeDescriptor.simulatedStartTime) && Number.isFinite(timeDescriptor.defaultRangeTime)) {
            return timeDescriptor.simulatedStartTime - timeDescriptor.defaultRangeTime / 2;
        }

        // return undefined
        return undefined;
    }

    /**
     * Compute the time range in milliseconds from a time descriptor
     * @param timeDescriptor Time descriptor
     */
    public static getSimulatedEndTimeRange(timeDescriptor: SimulatedTimeDescriptor): number {
        if (!timeDescriptor) return null;
        // if rangeEndTime is defined, return it
        const rangeEndTime: number = timeDescriptor.rangeEndTime;
        if (Number.isFinite(rangeEndTime)) return timeDescriptor.rangeEndTime;
        // compute the simulatedStartTime - defaultRange / 2
        if (Number.isFinite(timeDescriptor.simulatedStartTime) && Number.isFinite(timeDescriptor.defaultRangeTime)) {
            return timeDescriptor.simulatedStartTime + timeDescriptor.defaultRangeTime / 2;
        }

        // return undefined
        return undefined;
    }

    /**
     * create a Time Runner at a given rate
     */
    public static createTimeRunner(
        id: string,
        application: TpzApplication,
        callback: any,
        rate: number,
        timeDescriptor: SimulatedTimeDescriptor
    ): SimulatedTimeRunner {
        const timeRunner: SimulatedTimeRunner = new SimulatedTimeRunner(id, application, callback, rate);
        if (timeDescriptor) {
            timeRunner.setTimeDescriptor(timeDescriptor);
        } else {
            TpzApplicationCommander.fireSimpleEvent(
                application,
                application.getId(),
                SimulatedTimeEventType.REQUEST_TIME_DESCRIPTOR,
                null
            );
        }
        return timeRunner;
    }
}
