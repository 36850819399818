/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { LibraryManager } from './library-manager';
import { TpzLibraryDescriptor } from './library-descriptor';
import { TpzApplicationUI } from '../tpz-application-ui';

/**
 * Library Manager Editor displays UI informations
 */
export class LibraryManagerEditor {
    private readonly application: TpzApplication = null;
    private mainContainer: HTMLDivElement = null;
    private registeredLibrariesLoadingDiv: HTMLDivElement = null;
    private updateRegisteredLibrariesLoading: boolean = false;
    private registeredLibrariesDescriptorDiv: HTMLDivElement = null;
    private updateRegisteredLibrariesDescriptor: boolean = false;
    private registeredServersDiv: HTMLDivElement = null;
    private updateRegisteredServers: boolean = false;

    /**
     * Library Manager Editor Constructor
     * @param app application used to fire events (may be we should not give the full application but only the event)
     */
    constructor(app: TpzApplication) {
        this.application = app;
    }

    /**
     * parent application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * UI main element
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * @returns the library manager being edited
     */
    private getLibraryManager(): LibraryManager {
        return this.application.getLibraryManager();
    }

    /**
     * Main container lazy getter
     */
    private getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({});
            const libBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({ legend: 'Loading' });
            libBloc.appendChild(this.getRegisteredLibrariesLoadingDiv());
            this.mainContainer.appendChild(libBloc);
            const descBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({ legend: 'Descriptors' });
            descBloc.appendChild(this.getRegisteredLibrariesDescriptorDiv());
            this.mainContainer.appendChild(descBloc);
            const serverBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({ legend: 'Servers' });
            serverBloc.appendChild(this.getRegisteredServersDiv());
            this.mainContainer.appendChild(serverBloc);
            this.updateUI();
        }
        return this.mainContainer;
    }

    /**
     * Update UI content
     */
    public updateUI(): void {
        this.updateRegisteredLibrariesLoadingDiv();
        this.updateRegisteredLibrariesDescriptorDiv();
        this.updateRegisteredServersDiv();
    }

    /**
     * Registered Libraries loading container lazy getter
     */
    private getRegisteredLibrariesLoadingDiv(): HTMLDivElement {
        if (!this.registeredLibrariesLoadingDiv) {
            this.registeredLibrariesLoadingDiv = TpzApplicationUI.createDiv({});
            this.updateRegisteredLibrariesLoadingDiv();
        }
        return this.registeredLibrariesLoadingDiv;
    }

    /**
     * Registered Libraries descriptor container lazy getter
     */
    private getRegisteredLibrariesDescriptorDiv(): HTMLDivElement {
        if (!this.registeredLibrariesDescriptorDiv) {
            this.registeredLibrariesDescriptorDiv = TpzApplicationUI.createDiv({});
            this.updateRegisteredLibrariesDescriptorDiv();
        }
        return this.registeredLibrariesDescriptorDiv;
    }

    /**
     * Fill the RegisteredLibrariesDiv with libraries loading correcteness or error
     * @returns
     */
    private updateRegisteredLibrariesLoadingDiv(): Promise<void> {
        if (this.updateRegisteredLibrariesLoading) return Promise.resolve();
        this.updateRegisteredLibrariesLoading = true;
        return Promise.resolve()
            .then(() => {
                this.getRegisteredLibrariesLoadingDiv().innerHTML = null;
                this.getLibraryManager()
                    .getAvailableLibraryKeys()
                    .forEach((libId: string) => {
                        this.getLibraryManager()
                            .getLibrary(libId)
                            .then(() => {
                                const libURL: string = this.getLibraryManager().getLibraryDescriptor(libId).libraryURL;
                                this.getRegisteredLibrariesLoadingDiv().appendChild(
                                    TpzApplicationUI.createParagraph({
                                        text: libId + ': Correctly loaded from ' + libURL
                                    })
                                );
                            })
                            .catch((reason: any) => {
                                this.getRegisteredLibrariesLoadingDiv().appendChild(
                                    TpzApplicationUI.createParagraph({ text: libId + ': ' + reason })
                                );
                            });
                    });
            })
            .finally(() => {
                this.updateRegisteredLibrariesLoading = false;
            });
    }

    /**
     * Retrieve the library descriptor from library Id and display descriptor in getRegisteredLibrariesDiv()
     * @returns
     */
    private updateRegisteredLibrariesDescriptorDiv(): Promise<void> {
        if (this.updateRegisteredLibrariesDescriptor) return Promise.resolve();
        this.updateRegisteredLibrariesDescriptor = true;
        return Promise.resolve()
            .then(() => {
                this.getRegisteredLibrariesDescriptorDiv().innerHTML = null;
                this.getLibraryManager()
                    .getAvailableLibraryKeys()
                    .forEach((libId: string) => {
                        const descriptor: TpzLibraryDescriptor = this.getLibraryManager().getLibraryDescriptor(libId);
                        if (descriptor) {
                            this.getRegisteredLibrariesDescriptorDiv().appendChild(
                                TpzApplicationUI.createCollapsible(
                                    libId,
                                    TpzApplicationUI.createPre({
                                        text: JSON.stringify(descriptor, null, 2)
                                    })
                                )
                            );
                        } else {
                            this.getRegisteredLibrariesDescriptorDiv().appendChild(
                                TpzApplicationUI.createParagraph({
                                    text: libId + ': no descriptor available'
                                })
                            );
                        }
                    });
            })
            .finally(() => {
                this.updateRegisteredLibrariesDescriptor = false;
            });
    }

    /**
     * Main container lazy getter
     */
    private getRegisteredServersDiv(): HTMLDivElement {
        if (!this.registeredServersDiv) {
            this.registeredServersDiv = TpzApplicationUI.createDiv({});
            this.updateRegisteredServersDiv();
        }
        return this.registeredServersDiv;
    }

    /**
     * List all library servers and their descriptors
     */
    private updateRegisteredServersDiv(): Promise<void> {
        if (this.updateRegisteredServers) return Promise.resolve();
        this.updateRegisteredServers = true;
        return Promise.resolve()
            .then(() => {
                this.getRegisteredServersDiv().innerHTML = null;
                this.getLibraryManager()
                    .getRegisteredServers()
                    .forEach((serverURL: string) => {
                        const serverDiv: HTMLDivElement = TpzApplicationUI.createDiv({});
                        this.getRegisteredServersDiv().appendChild(
                            TpzApplicationUI.createCollapsible(serverURL, serverDiv)
                        );
                        this.getLibraryManager()
                            .fetchServerLibraryDescriptors(serverURL)
                            .then((libDescriptors: TpzLibraryDescriptor[]) => {
                                libDescriptors.forEach((libDescriptor: TpzLibraryDescriptor) => {
                                    serverDiv.appendChild(TpzApplicationUI.createParagraph({ text: libDescriptor.id }));
                                });
                            })
                            .catch((reason: any) => {
                                serverDiv.appendChild(
                                    TpzApplicationUI.createParagraph({
                                        text: serverURL + ' Error Retrieving server descriptor: ' + reason
                                    })
                                );
                            });
                    });
            })
            .finally(() => {
                this.updateRegisteredServers = false;
            });
    }
}
