/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { TpzProcess } from '../../../desktop/tpz-process';
import { ClockViewDigital, ClockViewDigitalConfig, defaultClockViewDigitalConfig } from './clock-view-digital';
import { defaultClockViewAnalogConfig, ClockViewAnalog, ClockViewAnalogConfig } from './clock-view-analog';
import { TpzApplicationCategories } from '../../../tpz-application-types';

/**
 * ClockProcess factory
 */
export class ClockFactory extends TpzApplicationFactory {
    public static readonly CLOCK_FACTORY_TYPE = 'ClockProcessFactory';

    /**
     * Constructor
     */
    constructor(application: TpzApplication) {
        super(ClockFactory.CLOCK_FACTORY_TYPE, application);
        this.addHandledItem(
            ClockViewDigital.CLOCK_VIEW_DIGITAL_TYPE,
            this.createClockViewDigital.bind(this),
            defaultClockViewDigitalConfig
        );
        this.addHandledItem(
            ClockViewAnalog.CLOCK_VIEW_ANALOG_TYPE,
            this.createClockViewAnalog.bind(this),
            defaultClockViewAnalogConfig
        );
        this.addCategory(TpzApplicationCategories.TPZ_PROCESS_CATEGORY);
    }

    /** Digital Clock View creator function */
    private createClockViewDigital(config: ClockViewDigitalConfig): Promise<ClockViewDigital> {
        return Promise.resolve(new ClockViewDigital(config, this.getApplication()));
    }

    /** Analogic Clock View creator function */
    private createClockViewAnalog(config: ClockViewAnalogConfig): Promise<ClockViewAnalog> {
        return Promise.resolve(new ClockViewAnalog(config, this.getApplication()));
    }
}
