/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzPlugin, TpzPluginConfig, defaultTpzPluginConfig } from '../../plugins/tpz-plugin-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../tpz-application-event';
import { ServerSyncPluginEventType } from './sync-event';
import { ModalWindow, TpzApplicationUI } from '../../tpz-application-ui';
import { TpzApplicationCommander } from '../../tpz-application-commander';

////////////
// REFACTORING
// this class may be useless since server connexion is done in session manager
// but it may be the base for choosing "master" or "slave"

const SERVER_SYNC_CONNECTION_UI_PLUGIN_TYPE: string = 'SERVER_SYNC_CONNECTION_UI_PLUGIN_TYPE';

/**
 * Connection UI configuration
 */
export interface ServerSyncConnectionUIPluginConfig extends TpzPluginConfig {
    syncIcon?: string; // synchrnization UI icon location
    parentId?: string; // parent where UI will be inserted
}

/**
 * default values Connection UI configuration
 */
export const defaultServerSyncConnectionUIPluginConfig: ServerSyncConnectionUIPluginConfig = {
    ...defaultTpzPluginConfig,
    type: SERVER_SYNC_CONNECTION_UI_PLUGIN_TYPE,
    syncIcon: 'images/icons/sync.png',
    css: defaultTpzPluginConfig.css.concat('css/sync.css')
};

/**
 * Connection UI. It is linked to an observed ServerSyncPlugin
 * It adds a button in a parent to connect/disconnect to server
 * A tooltip gives the client informations
 */
export class ServerSyncConnectionUIPlugin extends TpzPlugin {
    public static readonly SERVER_SYNC_CONNECTION_UI_PLUGIN_TYPE: string = SERVER_SYNC_CONNECTION_UI_PLUGIN_TYPE;
    private static readonly TICK_ATTR: string = 'mode';

    // private connectionContainer: HTMLDivElement = null;
    // private connectionButton: HTMLInputElement = null;
    // private connectionIcon: HTMLImageElement = null;

    private mainContainer: HTMLDivElement = null;
    private modalPrompt: ModalWindow = null;

    private syncStateContainer: HTMLDivElement = null;
    private masterTickDiv: HTMLDivElement = null;
    private masterCheckbox: HTMLInputElement = null;
    private masterRequestButton: HTMLButtonElement = null;
    private slaveCheckbox: HTMLInputElement = null;
    private slaveTickDiv: HTMLDivElement = null;
    private slaveRequestButton: HTMLButtonElement = null;
    private syncRequestButton: HTMLButtonElement = null;
    private syncIcon: HTMLImageElement = null;

    private masterMode: boolean = null;
    private slaveMode: boolean = null;

    /**
     * Constructor
     * @param config Connection UI configuration
     */
    constructor(config: ServerSyncConnectionUIPluginConfig) {
        super({ ...defaultServerSyncConnectionUIPluginConfig, ...config });
        if (!this.getConfig().parentId) {
            this.getLogger()?.error("ServerSyncConnectionUIPlugin must set 'parentId' in configuration");
        }
    }

    /**
     * Config specialization
     */
    public getConfig(): ServerSyncConnectionUIPluginConfig {
        return super.getConfig() as ServerSyncConnectionUIPluginConfig;
    }

    /**
     * Add UI on start
     * @param app ToPaZ application
     */
    public onStart(): Promise<void> {
        return Promise.resolve().then(() => {
            this.createUI();
        });
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source == this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                {
                    TpzApplicationEventType.checkEvent(event, null);
                    this.createUI();
                }
                break;
            case ServerSyncPluginEventType.MASTER_MODE_ON:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.masterMode = true;
                        this.updateUI();
                    }
                }
                break;
            case ServerSyncPluginEventType.MASTER_MODE_OFF:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.masterMode = false;
                        this.updateUI();
                    }
                }
                break;
            case ServerSyncPluginEventType.SLAVE_MODE_ON:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.slaveMode = true;
                        this.updateUI();
                    }
                }
                break;
            case ServerSyncPluginEventType.SLAVE_MODE_OFF:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.slaveMode = false;
                        this.updateUI();
                    }
                }
                break;
            case ServerSyncPluginEventType.SYNC_STATE:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId', 'masterMode', 'slaveMode');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.updateUI();
                    }
                }
                break;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * when started, this plugin adds its UI in parent
     * @returns
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then((plugin: TpzPlugin) => {
            this.createUI();
            this.requestSyncState();
            return plugin;
        });
    }

    /**
     * when stopped, this plugin removes its UI from parent
     * @returns
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onPlug().then((plugin: TpzPlugin) => {
            this.removeUI();
            return plugin;
        });
    }

    /**
     * Change UI to match synchronization state
     */
    private updateUI(): void {
        this.getMasterCheckbox().checked = this.masterMode;
        this.getMasterRequestButton().innerHTML = this.masterMode ? 'Revoke Master' : 'Request Master';
        if (this.masterMode === null) {
            this.getMasterTickDiv().removeAttribute(ServerSyncConnectionUIPlugin.TICK_ATTR);
        } else {
            this.getMasterTickDiv().setAttribute(ServerSyncConnectionUIPlugin.TICK_ATTR, `${this.masterMode}`);
        }

        this.getSlaveCheckbox().checked = this.slaveMode;
        this.getSlaveRequestButton().innerHTML = this.slaveMode ? 'Revoke Slave' : 'Request Slave';
        if (this.slaveMode === null) {
            this.getSlaveTickDiv().removeAttribute(ServerSyncConnectionUIPlugin.TICK_ATTR);
        } else {
            this.getSlaveTickDiv().setAttribute(ServerSyncConnectionUIPlugin.TICK_ATTR, `${this.slaveMode}`);
        }
    }

    /**
     * Insert UI in the parent defined by 'parentId' in configuration
     */
    private createUI(): void {
        let parent: HTMLElement = document.getElementById(this.getConfig().parentId);
        if (!parent) {
            this.getLogger()?.warn(
                'ServerSyncPluginUI#' +
                    this.getId() +
                    ' Cannot retrieve parent element Id #' +
                    this.getConfig().parentId +
                    '. use body...'
            );
            parent = document.body;
        }
        parent.appendChild(this.getContainer());
    }

    /**
     * Remove UI from parent defined by 'parentId' in configuration
     */
    private removeUI(): void {
        let parent: HTMLElement = document.getElementById(this.getConfig().parentId);
        if (!parent) {
            this.getLogger()?.warn(
                'ServerSyncPluginUI#' +
                    this.getId() +
                    ' Cannot retrieve parent element Id #' +
                    this.getConfig().parentId +
                    '. use body...'
            );
            parent = document.body;
        }
        if (parent.contains(this.getContainer())) parent.removeChild(this.getContainer());
    }

    /**
     * Sync Plugin UI  main container lazy getter
     */
    private getContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({});
            this.mainContainer.appendChild(this.getSyncIcon());
            this.mainContainer.appendChild(this.getMasterTickDiv());
            this.mainContainer.appendChild(this.getSlaveTickDiv());
        }
        return this.mainContainer;
    }

    /**
     * master Tick Div lazy getter (small mark on container icon)
     */
    private getMasterTickDiv(): HTMLDivElement {
        if (!this.masterTickDiv) {
            this.masterTickDiv = TpzApplicationUI.createDiv({ classes: ['sync-plugin-master-tick'] });
            this.masterTickDiv.addEventListener('click', this.triggerMasterMode.bind(this));
            TpzApplicationUI.tooltip(this.masterTickDiv, 'Master Mode');
        }
        return this.masterTickDiv;
    }

    /**
     * slave Tick Div lazy getter (small mark on container icon)
     */
    private getSlaveTickDiv(): HTMLDivElement {
        if (!this.slaveTickDiv) {
            this.slaveTickDiv = TpzApplicationUI.createDiv({ classes: ['sync-plugin-slave-tick'] });
            this.slaveTickDiv.addEventListener('click', this.triggerSlaveMode.bind(this));
            TpzApplicationUI.tooltip(this.slaveTickDiv, 'Slave Mode');
        }
        return this.slaveTickDiv;
    }

    // /**
    //  * Sync Plugin UI connection UI lazy getter
    //  */
    // private getConnectionIcon(): HTMLImageElement {
    //     if (!this.connectionIcon) {
    //         this.connectionIcon = document.createElement('img');
    //         this.connectionIcon.src = this.getConfig().connectOnIcon;
    //         this.connectionIcon.addEventListener('click', this.connectClick.bind(this));
    //     }
    //     return this.connectionIcon;
    // }

    /**
     * Action performed when connection icon is clicked
     * @param ev click mouse event
     */
    public connectClick(ev: MouseEvent): any {
        throw new Error('Not yet implemented');
    }

    /**
     * Sync Plugin UI connection UI lazy getter
     */
    private getSyncIcon(): HTMLImageElement {
        if (!this.syncIcon) {
            this.syncIcon = document.createElement('img');
            this.syncIcon.src = this.getConfig().syncIcon;
            this.syncIcon.addEventListener('click', this.toggleDisplay.bind(this));
        }
        return this.syncIcon;
    }

    /**
     * Action performed when login icon is clicked on
     * @param ev click mouse event
     */
    public toggleDisplay(ev: MouseEvent): void {
        this.getModalWindow().open();
    }

    /**
     * Lazy getter of modal window with master/slave requests buttons
     */
    private getModalWindow(): ModalWindow {
        if (!this.modalPrompt) {
            this.modalPrompt = new ModalWindow('Synchronization requests', 'sync-modal-window-prompt-title', [
                { id: 'close', text: 'Close', i18n: 'close-sync-modal-i18n' }
            ]);
            this.modalPrompt.onOpen = (win: ModalWindow) => {
                win.getTopDiv().appendChild(this.getPanelContainer());
            };
            this.modalPrompt.onClickButton = (win: ModalWindow, buttonId: string) => {
                if (buttonId == 'close') {
                    return true;
                }
                // do not close window (return false)
                return false;
            };
        }
        return this.modalPrompt;
    }

    /**
     * Sync Plugin UI  main container lazy getter
     */
    private getPanelContainer(): HTMLDivElement {
        if (!this.syncStateContainer) {
            this.syncStateContainer = TpzApplicationUI.createDiv({ classes: ['sync-plugin-ui'] });
            // master
            const stateContainer: HTMLDivElement = TpzApplicationUI.createDiv();

            stateContainer.appendChild(this.getMasterCheckbox());
            stateContainer.appendChild(TpzApplicationUI.createLabel({ label: 'master' }));
            stateContainer.appendChild(this.getSlaveCheckbox());
            stateContainer.appendChild(TpzApplicationUI.createLabel({ label: 'slave' }));
            //master
            const masterContainer: HTMLDivElement = TpzApplicationUI.createDiv();
            masterContainer.appendChild(this.getMasterRequestButton());
            //slave
            const slaveContainer: HTMLDivElement = TpzApplicationUI.createDiv();
            slaveContainer.appendChild(this.getSlaveRequestButton());
            // full synchronization
            const syncContainer: HTMLDivElement = TpzApplicationUI.createDiv();
            syncContainer.appendChild(this.getSyncRequestButton());

            this.syncStateContainer.appendChild(stateContainer);
            this.syncStateContainer.appendChild(masterContainer);
            this.syncStateContainer.appendChild(slaveContainer);
            this.syncStateContainer.appendChild(syncContainer);
        }
        return this.syncStateContainer;
    }

    /**
     * Sync Plugin UI master checkbox lazy getter
     */
    private getMasterCheckbox(): HTMLInputElement {
        if (!this.masterCheckbox) {
            this.masterCheckbox = TpzApplicationUI.createCheckBox({ checked: false, disabled: true });
        }
        return this.masterCheckbox;
    }

    /**
     * Sync Plugin UI slave checkbox lazy getter
     */
    private getSlaveCheckbox(): HTMLInputElement {
        if (!this.slaveCheckbox) {
            this.slaveCheckbox = TpzApplicationUI.createCheckBox({ checked: false, disabled: true });
        }
        return this.slaveCheckbox;
    }

    /**
     * Sync Plugin UI request to be master button lazy getter
     */
    private getMasterRequestButton(): HTMLButtonElement {
        if (!this.masterRequestButton) {
            this.masterRequestButton = TpzApplicationUI.createButton({ label: 'Request Master' });
            this.masterRequestButton.addEventListener('click', this.triggerMasterMode.bind(this));
        }
        return this.masterRequestButton;
    }

    /**
     * Sync Plugin UI request a full synchronization button lazy getter
     */
    private getSyncRequestButton(): HTMLButtonElement {
        if (!this.syncRequestButton) {
            this.syncRequestButton = TpzApplicationUI.createButton({ label: 'Synchronize' });
            this.syncRequestButton.addEventListener('click', this.requestSynchronization.bind(this));
        }
        return this.syncRequestButton;
    }
    /**
     * Sync Plugin UI request to be slave button lazy getter
     */
    private getSlaveRequestButton(): HTMLButtonElement {
        if (!this.slaveRequestButton) {
            this.slaveRequestButton = TpzApplicationUI.createButton({ label: 'Request Slave' });
            this.slaveRequestButton.addEventListener('click', this.triggerSlaveMode.bind(this));
        }
        return this.slaveRequestButton;
    }

    /**
     * Action performed when FULL SYNCHRONIZATION request button is clicked
     * @param ev click mouse event
     */
    private requestSynchronization(_: MouseEvent): void {
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        TpzApplicationCommander.fireSimpleEvent(
            this.getApplication(),
            this.getId(),
            ServerSyncPluginEventType.SYNCHRONIZE_APPLICATION_STATE_REQUEST,
            {
                sessionId: sessionId
            }
        );
    }

    /**
     * Action performed when MASTER_MODE request button is clicked
     * @param ev click mouse event
     */
    private triggerMasterMode(_: MouseEvent): void {
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        TpzApplicationCommander.fireSimpleEvent(
            this.getApplication(),
            this.getId(),
            ServerSyncPluginEventType.MASTER_MODE_REQUEST,
            { sessionId: sessionId, mode: !this.masterMode }
        );
    }

    /**
     * Action performed when SLAVE_MODE request button is clicked
     * @param ev click mouse event
     */
    private triggerSlaveMode(_: MouseEvent): void {
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        TpzApplicationCommander.fireSimpleEvent(
            this.getApplication(),
            this.getId(),
            ServerSyncPluginEventType.SLAVE_MODE_REQUEST,
            { sessionId: sessionId, mode: !this.slaveMode }
        );
    }

    /**
     * Request the server the state
     * @param ev click mouse event
     */
    private requestSyncState(): void {
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (!sessionId) return;
        TpzApplicationCommander.fireSimpleEvent(
            this.getApplication(),
            this.getId(),
            ServerSyncPluginEventType.SYNC_STATE_REQUEST,
            { sessionId: sessionId }
        );
    }
}
