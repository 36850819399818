/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AccessorEvent, AccessorEventType } from '../../../../tpz-access/tpz-access-event';
import { EChartOption, ECharts } from 'echarts';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { ItemEvent } from '../../../../tpz-catalog/tpz-catalog-core';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { deepCopy } from '../../../tools/deep-copy';
import { ValueAccessor } from '../../../../tpz-access/tpz-access-value';
import { TpzApplicationCategories } from '../../../tpz-application-types';

// echarts library Id
const ECHARTS_LIBRARY_ID = 'echart-4.9.0';
// echarts library file to be loaded
const ECHARTS_LIBRARY_FILE = 'libs/echarts-4.9.0/dist/echarts.js';

// item type (used in default configuration and Item Instance constructor)
const ECHARTS_VIEW_TYPE: string = 'EChartsViewType';

/**
 * EchartsView configuration
 */
export interface EChartsViewConfig extends TpzViewConfig {
    containerId?: string;
    chartOptions?: EChartOption;
}

/**
 * Default HTML view configuration
 */
export const defaultEChartsViewConfig: EChartsViewConfig = {
    ...defaultTpzViewConfig,
    type: ECHARTS_VIEW_TYPE,
    css: defaultTpzViewConfig.css.concat(['css/echarts.css']),
    chartOptions: {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: [
                'Direct',
                'Marketing',
                'Search Engine',
                'Email',
                'Union Ads',
                'Video Ads',
                'Baidu',
                'Google',
                'Bing',
                'Others'
            ]
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                selectedMode: 'single',
                radius: [0, '30%'],
                label: {
                    position: 'inner',
                    fontSize: 14
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 1548, name: 'Search Engine' },
                    { value: 775, name: 'Direct' },
                    { value: 679, name: 'Marketing', selected: true }
                ]
            },
            {
                name: 'Access From',
                type: 'pie',
                radius: ['45%', '60%'],
                labelLine: {
                    length: 30
                },
                label: {
                    formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                    backgroundColor: '#F6F8FC',
                    borderColor: '#8C8D8E',
                    borderWidth: 1,
                    borderRadius: 4,
                    rich: {
                        a: {
                            color: '#6E7079',
                            lineHeight: 22,
                            align: 'center'
                        },
                        hr: {
                            borderColor: '#8C8D8E',
                            width: '100%',
                            borderWidth: 1,
                            height: 0
                        },
                        b: {
                            color: '#4C5058',
                            fontSize: 14,
                            fontWeight: 'bold',
                            lineHeight: 33
                        },
                        per: {
                            color: '#fff',
                            backgroundColor: '#4C5058',
                            padding: [3, 4],
                            borderRadius: 4
                        }
                    }
                },
                data: [
                    { value: 1048, name: 'Baidu' },
                    { value: 335, name: 'Direct' },
                    { value: 310, name: 'Email' },
                    { value: 251, name: 'Google' },
                    { value: 234, name: 'Union Ads' },
                    { value: 147, name: 'Bing' },
                    { value: 135, name: 'Video Ads' },
                    { value: 102, name: 'Others' }
                ]
            }
        ]
    }
};

/**
 * Display charts data in a view using ECharts library
 */
export class EChartsView extends TpzView {
    private mainContainer: HTMLDivElement = null;
    private chart: ECharts = null;
    private dataReady: boolean = true;

    public static readonly ECHARTS_VIEW_TYPE = ECHARTS_VIEW_TYPE;

    /** Constructor */
    constructor(config: EChartsViewConfig, application: TpzApplication) {
        super(deepCopy(defaultEChartsViewConfig, config), EChartsView.ECHARTS_VIEW_TYPE, application);
        this.getApplication().registerLibrary({
            id: ECHARTS_LIBRARY_ID,
            resources: { js: [ECHARTS_LIBRARY_FILE] }
        });
    }

    /** Library Getter */
    protected getEChartsLibrary(): Promise<void> {
        return this.getApplication().getLibrary(ECHARTS_LIBRARY_ID);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): EChartsViewConfig {
        return super.getConfig() as EChartsViewConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: EChartsViewConfig = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.chartOptions !== newConfig?.chartOptions) {
            this.chart?.setOption(config?.chartOptions);
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }
    /**
     * Create UI and insert main container into parent
     * @param parent
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!parent) {
            throw new Error('parent is not defined');
        }
        parent.style.position = 'absolute';
        parent.style.width = '100%';
        parent.style.height = '100%';
        const mainContainer: HTMLDivElement = this.getMainContainer();
        if (parent.contains(mainContainer)) {
            return false;
        }
        parent.appendChild(mainContainer);
        this.requestUpdate();
        return true;
    }

    /**
     * get HTML view main container
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            if (this.getConfig().containerId) {
                this.mainContainer.id = this.getConfig().containerId;
            }
            this.mainContainer.classList.add('echarts-container');
            this.mainContainer.innerHTML = '';
            this.createChart();
        }
        return this.mainContainer;
    }

    /**
     * Creates the chart instance and stores it into this.chart variable
     */
    private createChart(): Promise<ECharts> {
        return this.getEChartsLibrary().then((echarts: any) => {
            if (!(window as any)['echarts']) {
                throw new Error('Error loading echart library: Echarts namespace is not declared');
            }
            this.chart = null;
            //create ECharts on DOM element
            // get Library constructor by from 'global'. This Hack is due to webpack renaming 'ECharts' library
            // and so cannot be accessed via echarts label
            // GlobalEChartsInitFunction points directly to window.Tabulator (which is loaded in memory by loadScript)
            const GlobalEChartsInitFunction = (window as any)['echarts']['init'] as any;
            this.chart = GlobalEChartsInitFunction(this.getMainContainer());
            //            this.chart = echarts.init(this.getMainContainer());
            // use configuration item and data specified to show chart
            this.chart?.setOption(this.getConfig().chartOptions);
            this.setData();
            return this.chart;
        });
    }

    /** Get Echarts instance */
    public getChart(): ECharts {
        if (!this.chart) {
            this.createChart();
        }
        return this.chart;
    }
    /**
     * Callback when accessors have changed
     * @param event accessor changes event
     */
    protected onAccessorChange(event: AccessorEvent): boolean {
        switch (event.type) {
            case AccessorEventType.DATA: {
                this.setData();
                // explicitly DO NOT call super.onAccessorChange()
                // setData() already does an updateUI() which is the default behaviour of
                // super.onAccessorChange() on AccessorEventType.DATA event
                return true;
            }
        }
        return super.onAccessorChange(event);
    }

    // /**
    //  * action to perform when accessors change: Set all accessors as eChart series
    //  *
    //  */
    // public onItemEvent(event: ItemEvent): boolean {
    //     if (!this.chart) return false;
    //     switch (event.type) {
    //         case AccessorEventType.DATA:
    //             {
    //                 this.setData();
    //             }
    //             break;
    //     }
    //     return super.onItemEvent(event);
    // }
    /**
     * Set all series from accessors
     */
    private setData(): void {
        // use a setTimeout to force chart.setOption & chart.resize() being in the same queue...
        // ECharts bug ?
        // https://www.programmerall.com/article/33902009269/
        // setTimeout(() => {
        // check if data changes is already in progress

        if (!this.chart || !this.dataReady) {
            return;
        }
        try {
            // protect data change concurrency
            this.dataReady = false;
            // assign all accessors to series in order
            for (let accessorIndex = 0; accessorIndex < this.getAccessorIds().length; accessorIndex++) {
                const accessorId: string = this.getAccessorIds()[accessorIndex];
                if (!accessorId) {
                    this.getLogger().error('item #' + this.getId() + ' accessor Ids list contains a null value.');
                    this.getLogger().error(
                        'item #' + this.getId() + ' accessor Ids = ' + this.getAccessorIds().join(', ')
                    );
                } else {
                    this.getAccessorById(accessorId).then((accessor: ValueAccessor<any>) => {
                        this.setSeriesData(accessorIndex, accessor.getValue());
                    });
                }
            }
        } finally {
            this.dataReady = true;
            this.updateUI();
        }
    }

    /**
     * Change One Series data or create it if not present
     * @param seriesIndex series index
     * @param data series data
     */
    private setSeriesData(seriesIndex: number, data: any): void {
        if (!data) {
            return;
        }
        if (!this.chart) {
            return;
        }
        const chartOptions: EChartOption = this.chart.getOption();
        if (!chartOptions) {
            return;
        }
        if (!chartOptions.series) {
            chartOptions.series = [];
        }
        if (!chartOptions.series[seriesIndex]) {
            chartOptions.series[seriesIndex] = {};
        }
        if (Array.isArray(data)) {
            chartOptions.series[seriesIndex].data = data;
        } else if (typeof data === 'number') {
            // type 'number' is checked but it may be valid for any other type than Array...
            chartOptions.series[seriesIndex].data = [data];
        } else {
            this.getLogger().warn('data type ' + typeof data + ' not handled in EChart series...');
        }
        try {
            this.chart.setOption(chartOptions, true);
        } catch (reason: any) {
            this.getLogger().error('Error setting chart options');
        }
    }

    /**
     * get HTML view main container
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Update UI components only
     */
    public updateUI(): void {
        if (this.chart && this.dataReady) {
            this.chart.resize();
        } else {
            this.getLogger().warn(
                'skip updating echart #' + this.getId() + ' data ready = ' + this.dataReady + ' chart = ' + this.chart
            );
        }
        super.updateUI();
    }

    /**
     * Invalidate UI components
     */
    public invalidateUI(): void {
        this.mainContainer = null;
        this.chart = null;
        this.dataReady = true;
    }

    /** Request Update when tabulator library is ready */
    public requestUpdateWithECharts(tabulator: any): void {
        this.updateUI();
    }

    /**
     * Update HTML View by reloading htmlContent
     * @param force reload even if already loaded
     */
    public requestUpdate(force: boolean = false): boolean {
        const me: EChartsView = this;
        if (!me.mainContainer) {
            return false;
        }
        this.getEChartsLibrary().then((echarts: any) => {
            this.requestUpdateWithECharts(echarts);
        });
        return true;
    }
}

/**
 * Factory handling EchartsView creation
 */
export class EChartsViewFactory extends TpzApplicationFactory {
    private static readonly ECHARTS_VIEW_FACTORY_TYPE: string = 'EChartsViewFactoryType';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(EChartsViewFactory.ECHARTS_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(EChartsView.ECHARTS_VIEW_TYPE, this.createEChartsView.bind(this), defaultEChartsViewConfig);
        this.addCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY);
    }

    /** CesiumView creator function */
    private createEChartsView(config: EChartsViewConfig): Promise<EChartsView> {
        return Promise.resolve(new EChartsView(config, this.getApplication()));
    }
}
