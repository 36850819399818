/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * tag interface for exchanged message contents
 */
export type MessageContent = any;

/**
 * Message send from this application to the server via websocket
 * If some changed are made in this OutgoingMessage definition,
 * DO NOT forget to change TpzServerHelper.getInvalidOutgoingMessageReasons() method
 */
export interface TpzClientOutgoingMessage {
    sessionId: string; // source id of this message
    type: string; // type of message
    content: MessageContent; // message content
    time: number; // message creation time (as long value)
}

/**
 * Message received from the server via websocket
 * If some changed are made in this OutgoingMessage definition,
 * DO NOT forget to change TpzServerHelper.getInvalidIncomingMessageReasons() method
 */
export interface TpzServerIncomingMessage {
    sessionId: string; // source id of this message
    type: string; // type of message
    content: MessageContent; // message content
    time: number; // message creation time (as long value)
}

/**
 * Server information is received by the server
 * when calling server information service
 */
export interface TpzServerInformation {
    name: string; // server name
    serverURL: string; // server URL
    connectURL: string; // connection URL
    websocketURL: string; // websocketURL
    addOnRegistryURL: string; // addon registry URL
    userIdsURL: string; // list of user ids URL
    userStateSetURL: string; // set user state URL. Pattern {userId} has to be replaced
    userStateGetURL: string; // get user state URL. Pattern {userId} and {statId} have to be replaced
    userDataSummaryURL: string; // get user data URL. Pattern {userId} has to be replaced
    keycloakURL: string; // keycloak URL
    keycloakRealm: string; // keycloak Realm
    keycloakClientId: string; //keycloak client ID
}

/**
 * Session information retrieved from the server
 */
export interface TpzClientSessionInformation {
    sessionId?: string; // session ID generated by the server
    userId?: string; // user id (non null if connected)
    serverInformationURL?: string; // server information URL
}

export class TpzServerHelper {
    /**
     * Returns a reason why the given object is not a valid outgoing message
     * @param putativeMessage message to check
     * @returns an array of strings explaining why the object is not an outgoing message. Returns null if message is ok
     */
    public static getInvalidOutgoingMessageReasons(putativeMessage: any): string[] {
        if (!putativeMessage) return ['Outgoing message is null'];
        const reasons: string[] = [];

        if (putativeMessage['sessionId'] === undefined) reasons.push('sessionId is not defined');
        if (typeof putativeMessage.sessionId !== 'string') reasons.push('sessionId should be a string');
        if (putativeMessage.sessionId === '') reasons.push('sessionId should be a non empty string');

        if (putativeMessage['type'] === undefined) reasons.push('type is not defined');
        if (typeof putativeMessage.type !== 'string') reasons.push('type should be a string');
        if (putativeMessage.type === '') reasons.push('type should be a non empty string');

        if (putativeMessage['time'] === undefined) reasons.push('time is not defined');
        if (typeof putativeMessage.time !== 'number') reasons.push('time should be a number: ' + putativeMessage.time);
        if (putativeMessage.time < 0) reasons.push('time should be a positive number: ' + putativeMessage.time);

        return reasons.length === 0 ? null : reasons;
    }

    /**
     * check if given object is a valid outgoing message
     * @param putativeMessage object to be checked
     * @returns true if it is valid, false if not
     */
    public static getValidOutgoingMessage(putativeMessage: any): TpzClientOutgoingMessage {
        if (TpzServerHelper.getInvalidOutgoingMessageReasons(putativeMessage)) return null;
        return putativeMessage as TpzClientOutgoingMessage;
    }

    /**
     * Returns a reason why the given object is not a valid outgoing message
     * @param putativeMessage message to check
     * @returns an array of strings explaining why the object is not an outgoing message. Returns null if message is ok
     */
    public static getInvalidIncomingMessageReasons(putativeMessage: any): string[] {
        if (!putativeMessage) return ['Outgoing message is null'];
        const reasons: string[] = [];

        if (putativeMessage['sessionId'] === undefined) reasons.push('sessionId is not defined');
        if (typeof putativeMessage.sessionId !== 'string') reasons.push('sessionId should be a string');
        if (putativeMessage.sessionId === '') reasons.push('sessionId should be a non empty string');

        if (putativeMessage['type'] === undefined) reasons.push('type is not defined');
        if (typeof putativeMessage.type !== 'string') reasons.push('type should be a string');
        if (putativeMessage.type === '') reasons.push('type should be a non empty string');

        if (putativeMessage['time'] === undefined) reasons.push('time is not defined');
        if (typeof putativeMessage.time !== 'number') reasons.push('time should be a number: ' + putativeMessage.time);
        if (putativeMessage.time < 0) reasons.push('time should be a positive number: ' + putativeMessage.time);

        return reasons.length === 0 ? null : reasons;
    }

    /**
     * check if given object is a valid outgoing message
     * @param putativeMessage object to be checked
     * @returns true if it is valid, false if not
     */
    public static getValidIncomingMessage(putativeMessage: any): TpzServerIncomingMessage {
        if (TpzServerHelper.getInvalidIncomingMessageReasons(putativeMessage)) return null;
        return putativeMessage as TpzServerIncomingMessage;
    }

    /**
     * This method check if a message content contains given arguments
     * @param message message content to be checked
     * @param args list of mandatory elements
     * @returns nothing, it throws exception
     * @throws an error if an argument is missing
     */
    public static checkMessageContent(
        message: TpzServerIncomingMessage | TpzClientOutgoingMessage,
        ...args: string[]
    ): void {
        let nbArgs: number = 0;
        // count only non null fields
        if (args) {
            args.forEach((arg: string) => {
                if (arg) nbArgs++;
            });
        }
        // check if message is well formed
        if (!message) throw new Error('Message should be defined');
        if (!message.type) throw new Error('Message should have at list a defined type');
        // check if the content is a dictionnary
        if (message.content && message.content.constructor !== Object) {
            throw new Error(
                message.type + ' Message content should be a dictionary. it is a ' + typeof message.content
            );
        }
        // check no args => content is null or empty dictionary
        if (!args || nbArgs === 0) {
            if (message.content === null) return;
            if (Object.keys(message.content).length !== 0) {
                throw new Error(
                    message.type +
                        ' Message content should not contain fields: ' +
                        Object.keys(message.content).join(', ')
                );
            }
            return;
        }
        // check args count
        if ((!message.content && nbArgs !== 0) || (message.content && Object.keys(message.content).length !== nbArgs)) {
            throw new Error(
                message.type +
                    ' Message content should contain fields: ' +
                    args.map((x) => (x ? "'" + x + "'" : 'null')).join(', ') +
                    ' but contains: ' +
                    Object.keys(message.content)
                        .map((x) => (x ? "'" + x + "'" : 'null'))
                        .join(', ')
            );
        }
        // check args
        args?.forEach((arg: string) => {
            // take care:
            // - (null === undefined): true
            // - (typeof null === "undefined"): false
            if (arg && typeof message.content[arg] === 'undefined') {
                throw new Error(
                    'message ' +
                        message.type +
                        " should contain field '" +
                        arg +
                        "'. Content = " +
                        Object.keys(message.content)
                            .map((x) => (x ? "'" + x + "'" : 'null'))
                            .join(', ')
                );
            }
        });
    }
}
