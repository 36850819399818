/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger } from '../tpz-log/tpz-log-core';
import { LoggerHelper } from '../tpz-log/tpz-log-helper';
import { ItemFactory } from './tpz-item-factory';

/**
 * ItemFactory Manager contains all factories able to create instances
 */
export class ItemFactoryManager {
    // catalog factory used to create instances from configurations
    private readonly registeredFactories: { [factoryType: string]: ItemFactory } = {};
    private itemTypeFactory: { [itemType: string]: ItemFactory } = null;
    private handledItemTypes: string[] = null;
    // factory map is a dictionary type => ItemFactory
    private logger: Logger = null;

    /**
     * Constructor
     */
    constructor() {
        this.registeredFactories = {};
        this.handledItemTypes = null;
        this.itemTypeFactory = null;
    }

    /** logger getter */
    public getLogger(): Logger {
        if (!this.logger) {
            this.setLogger(LoggerHelper.createBufferedConsoleLogger('factory-manager', 500));
        }
        return this.logger;
    }

    /** logger setter */
    public setLogger(logger: Logger): void {
        this.logger = logger;
    }

    /**
     * Force handled types recomputation at next getHandledTypes() call
     * same for factoryMap
     */
    private invalidateHandledTypes(): void {
        this.handledItemTypes = null;
        this.itemTypeFactory = null;
    }

    /**
     * Get all handled types (as the merge of all factories handled types)
     */
    public getHandledTypes(): string[] {
        // if registered types are already computed just return it
        if (this.handledItemTypes) return this.handledItemTypes;
        // compute registered types as the merge of all factories registered types
        this.handledItemTypes = [];
        for (const factory of this.getRegisteredFactories()) {
            this.handledItemTypes = this.handledItemTypes.concat(factory.getHandledTypes());
        }
        return this.handledItemTypes;
    }

    /**
     * Get all registered factories. A filter can be applied to retrieved only a subpart of factories
     * If filter is null or unset, all factories are returned
     */
    public getRegisteredFactories(filter: (factory: ItemFactory) => boolean = null): ItemFactory[] {
        // if filter is not defined, return all registered factories
        if (!filter) return Object.values(this.registeredFactories);
        // filter factories
        return Object.values(this.registeredFactories).filter(filter);
    }

    /**
     * Check if a factory is registered
     */
    public isRegisteredFactory(factoryType: string): boolean {
        return this.registeredFactories[factoryType] != null;
    }

    /**
     * register a new ItemFactory
     * @param factory instance factory to register
     */
    public registerItemFactory(factory: ItemFactory): boolean {
        if (!factory) return false;
        if (factory.getHandledTypes().length == 0) {
            this.getLogger()?.warn('You register factory ' + factory.getType() + ' which handles nothing...');
            return false;
        }
        // check if this factory is already registered
        if (this.isRegisteredFactory(factory.getType())) {
            return false;
        }
        // for each handled type of the candidate factory: check if it
        // doesn't overlap with another factory which handle the same kind of type
        // it is not considered as an error but may be a user mistake. WARN.
        factory.getHandledTypes().forEach((type) => {
            const dualFactory: ItemFactory = this.getHandlingItemFactory(type);
            if (dualFactory) {
                this.getLogger()?.warn(
                    'Register factory ' +
                        factory.getType() +
                        ' conflict with factory ' +
                        dualFactory.getType() +
                        ' on type ' +
                        type
                );
            }
        });
        this.invalidateHandledTypes();
        this.registeredFactories[factory.getType()] = factory;
        return true;
    }

    /**
     * display this item manager as string
     */
    public getInfo(): string[] {
        return [
            'handled types: ',
            ...this.getHandledTypes(),
            'Registered factories : ',
            ...this.getRegisteredFactories().map((f) => f.getType())
        ];
    }

    /**
     * Get all registered categories
     */
    public getRegisteredCategories(): string[] {
        // compute categories
        let categories: string[] = [];
        this.getRegisteredFactories()?.forEach((factory: ItemFactory) => {
            categories = categories.concat(factory.getCategories());
        });
        // remove duplicates
        //        return [...new Set(categories)];  // this line should be valid but does not match Typescript checking...
        return Array.from(new Set(categories));
    }

    /**
     * Return true is any registered factory handles this instance type
     * @param type instance type
     */
    public isHandledType(type: string): boolean {
        return this.getHandlingItemFactory(type) != null;
    }

    /**
     * Lazy getter of the mapping between item type and factory handling this item type
     */
    public getFactoryMap(): { [itemType: string]: ItemFactory } {
        if (!this.itemTypeFactory) {
            this.itemTypeFactory = {};
            // loop into created map if this search has already been done (speedup factory search)
            // loop over all factories
            this.getRegisteredFactories().forEach((factory: ItemFactory) => {
                // loop over all handled item types (for each factory)
                factory.getHandledTypes().forEach((itemType: string) => {
                    // check if type is already handled
                    if (this.itemTypeFactory[itemType]) {
                        this.getLogger()?.critical('This should not happen !!');
                        this.getLogger()?.error('Two factories handle type ' + itemType);
                    }
                    this.itemTypeFactory[itemType] = factory;
                });
            });
        }
        return this.itemTypeFactory;
    }

    /**
     * Return the factory handling the instance type or null if none
     * @param type instance type
     */
    public getHandlingItemFactory(type: string): ItemFactory {
        return this.getFactoryMap()[type];
    }
}
