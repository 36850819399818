/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application/tpz-application-core';
import { ItemInstance } from '../tpz-catalog/tpz-item-core';
import { Accessor, AccessorConfig } from './tpz-access-core';
import { AccessorValueImportExport, AccessorType, AccessorCategoryType } from './tpz-access-types';
import { defaultValueAccessorConfig, ValueAccessor, ValueAccessorConfig } from './tpz-access-value';
import { deepCopy } from '../tpz-application/tools/deep-copy';

/////////////////////////////////// DISCRETIZER VALUE ACCESSOR ///////////////////////////////

export type DiscretizerAccessorConfig = AccessorConfig;

// default config
export const defaultDiscretizerAccessorConfig: DiscretizerAccessorConfig = {
    ...defaultValueAccessorConfig
    // type is not defined for abstract classes
};

/**
 * This is an abstract class
 * The discretizer accessor takes a continuous value accessor as input and creates a discretized acessor
 * Discrtization type is defined by implementing method discretize()
 */
export abstract class DiscretizerAccessor<
    DataType,
    //disable @typescript-eslint/no-unused-vars
    AccessorType extends Accessor<DataType>
> extends ValueAccessor<DataType> {
    /**
     * Accessor constructor
     * @param config Accessor configuration
     * @param type Item/Accessor type/name
     * @param app TpzApplication in which the accessor in used
     */
    constructor(config: DiscretizerAccessorConfig, type: string, app: TpzApplication) {
        super(
            deepCopy(defaultDiscretizerAccessorConfig, config),
            type,
            app,
            AccessorValueImportExport.convertFromStringDefault,
            AccessorValueImportExport.convertToStringDefault
        );
        this.addCategory(AccessorCategoryType.DISCRETIZER_ACCESSOR_CATEGORY_TYPE);
        this.getLogger().warn(
            'It seems that Discretizer accessor does listen to its input accessor change... Check implementation and write tests...'
        );
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): DiscretizerAccessorConfig {
        return super.getConfig() as DiscretizerAccessorConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: DiscretizerAccessorConfig = null): boolean {
        const changes: boolean = false;
        // const config = this.getConfig();
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }

    /** Action to be performed on accessor change */
    private onInputAccessorChange(): boolean {
        this.getAccessor().then((accessor: ValueAccessor<any>) => this.setValue(this.discretize(accessor)));
        return true;
    }

    /** Start converter */
    public doStart(): Promise<void> {
        return super.doStart().then(() => {
            this.requestUpdate(true);
        });
    }

    /** method to be implemented in extended classes */
    protected abstract discretize(accessor: ValueAccessor<any>): any;
}

/////////////////////////////////// DISCRETIZER VALUE ACCESSOR ///////////////////////////////

export interface NumberDiscretizerAccessorMinMaxCellConfig extends DiscretizerAccessorConfig {
    min?: number;
    max?: number;
    value?: number;
}

// default config
export const defaultNumberDiscretizerAccessorConfig: NumberDiscretizerAccessorMinMaxCellConfig = {
    ...defaultDiscretizerAccessorConfig,
    type: AccessorType.NUMBER_DISCRETIZER_ACCESSOR_TYPE,
    min: 0,
    max: 100,
    value: 1
};

export interface NumberDiscretizerAccessorConfig extends DiscretizerAccessorConfig, ValueAccessorConfig<string> {
    defaultValue?: string;
    cells?: NumberDiscretizerAccessorMinMaxCellConfig[];
}

/**
 * Convert numbers to strings using cells ranges defined in configuration
 */
export class NumberDiscretizerAccessor extends DiscretizerAccessor<string, ValueAccessor<string>> {
    /**
     * Discretizor Accessor constructor
     * @param config Accessor configuration
     * @param type Item/Accessor type/name
     * @param app TpzApplication in which the accessor in used
     */
    constructor(config: NumberDiscretizerAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultNumberDiscretizerAccessorConfig, config),
            AccessorType.NUMBER_DISCRETIZER_ACCESSOR_TYPE,
            app
        );
    }

    /**
     * Config specialization getter
     */
    public getConfig(): NumberDiscretizerAccessorConfig {
        return super.getConfig() as NumberDiscretizerAccessorConfig;
    }

    /**
     * Discretize value using defined number cells
     * @param event
     */
    protected discretize(accessor: ValueAccessor<any>): any {
        if (!accessor) return null;
        const accessorValue: number = accessor.getValue();
        let discreteValue: number = null;
        this.getConfig().cells.forEach((cell) => {
            if (discreteValue === null && accessorValue >= cell.min && accessorValue < cell.max) {
                discreteValue = cell.value;
            }
        });
        return discreteValue;
    }
}
