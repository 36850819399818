/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { deepCopy } from '../tools/deep-copy';
import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { defaultItemConfig, ItemConfig } from '../../tpz-catalog/tpz-item-config';
import { TpzApplicationCategories } from '../tpz-application-types';

/**
 * DesktopProcess configuration
 */
export interface TpzProcessConfig extends ItemConfig {
    itemIds?: string[]; // listened items
}

/**
 * DesktopProcess configuration
 */
export const defaultTpzProcessConfig: TpzProcessConfig = {
    ...defaultItemConfig,
    itemIds: []
};

/**
 * Processes are business modules doing something, they have no GUI.
 */
export class TpzProcess extends ItemInstance {
    /**
     * constructor
     */
    constructor(config: TpzProcessConfig, type: string, application: TpzApplication) {
        super(deepCopy(defaultTpzProcessConfig, config), type, application);
        this.addCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY);
    }

    /**
     * Getter specialization
     */
    public getConfig(): ItemConfig {
        return super.getConfig() as TpzProcessConfig;
    }
}
