/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { I18NDictionary } from './tpz-i18n-core';
import { I18N } from './tpz-i18n-static';

interface IODictionaryEntry {
    text?: string;
    icon?: string;
}

interface IODictionary {
    lang: string;
    groupId?: string;
    entries?: { [key: string]: IODictionaryEntry };
}
/*

***************** JSON Dictionary Sample *******************

    const dictsFileContent: any[] = [
        {
            "lang": langFR,
            "groupId": dict1Group1Id,
            "entries": {
                "key1-D1G1": {
                    text: "key1-fr"
                },
                "key2-D1G1": {
                    text: "key2-fr"
                }
            }

        },
        {
            "lang": langFR,
            "groupId": dict1Group1Id,
            "entries": {
                "key1-D1G1": {
                    text: "key1-fr"
                },
                "key3-D1G1": {
                    text: "key3-fr"
                }
            }

        },
        {
            "lang": langFR,
            "groupId": dict1Group2Id,
            "entries": {
                "key1-D2G2": {
                    text: "key1-fr"
                },
                "key3-D2G2": {
                    text: "key2-fr"
                }
            }

        }
    ];

*/

/**
 * Input/Output of I18N dictionaries
 */
export class I18NIO {
    /**
     * Read a JSON Dictionary. File may contain one dictionary or an array of dictionaries:
     * \{DICT\} or [\{DICT\}, \{DICT\}, \{DICT\}, ...]
     * @param jsonAsString
     */
    private static importDictionaryFromObject(object: any): I18NDictionary {
        if (!object) return null;
        if (!object.lang) throw new Error("imported object does not contain the mandatory field 'lang'");

        const ioDict: IODictionary = object;

        // create dictionary
        const dict: I18NDictionary = {
            lang: ioDict.lang,
            groupId: ioDict.groupId ?? I18N.DEFAULT_GROUP_ID,
            entries: {}
        };
        // add all entries
        if (ioDict.entries) {
            for (const [key, ioEntry] of Object.entries(ioDict.entries)) {
                dict.entries[key] = {
                    key: key,
                    text: ioEntry.text,
                    icon: ioEntry.icon
                };
            }
        }
        return dict;
    }

    /**
     * Read a JSON Dictionary. File may contain one dictionary or an array of dictionaries:
     * \{DICT\} or [\{DICT\}, \{DICT\}, \{DICT\}, ...]
     * @param jsonAsString
     */
    public static importDictionaryFromJSONDict(jsonAsString: string): I18NDictionary[] {
        const dicts: I18NDictionary[] = [];
        const ioObject: any = JSON.parse(jsonAsString);
        if (Array.isArray(ioObject)) {
            // file contains an array of dictionaries
            ioObject.forEach((object: any) => {
                const dict = I18NIO.importDictionaryFromObject(object);
                if (dict) dicts.push(dict);
            });
        } else {
            // file contains only one dictionary
            const dict = I18NIO.importDictionaryFromObject(ioObject);
            if (dict) dicts.push(dict);
        }
        return dicts;
    }
}
