/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemInstance } from './tpz-item-core';
import { ItemConfig } from './tpz-item-config';
import { deepCopy } from '../tpz-application/tools/deep-copy';

/**
 * A ItemFactory is a generic class which is able to create object instances
 * using Catalogable configs
 */
export abstract class ItemFactory {
    /**
     * get this FACTORY type. Do not confuse with Item handled types
     */
    public abstract getType(): string;

    /**
     * get this FACTORY name (for display purpose).
     * If not overloaded, default value is the factory type
     */
    public getName(): string {
        return this.getType();
    }

    /**
     * get this FACTORY internationalization ID used for the name (for display purpose).
     * If not overloaded, default value is the factory type + "-i18n"
     */
    public getNameI18N(): string {
        return `${this.getType()}-i18n`;
    }

    /**
     * get this factory categories
     */
    public abstract getCategories(): string[];

    /**
     * Item types that can be created by this factory.
     */
    public abstract getHandledTypes(): string[];

    /**
     * Create an item instance with the given config.
     * Config type must be in handled types of this factory
     * @param config
     */
    public abstract createInstance(config: Readonly<ItemConfig>): Promise<ItemInstance>;

    /**
     * get a default configuration object for handled types
     */
    public abstract getDefaultConfig(type: string): ItemConfig;
}

/**
 * Default factory storing objects and objects creator function in a list
 */
export class GenericItemFactory extends ItemFactory {
    private readonly type: string = 'untyped';
    private readonly types: string[] = [];
    private readonly constructors: { [type: string]: (config: Readonly<ItemConfig>) => Promise<ItemInstance> } = {};
    private readonly defaultConfigs: { [type: string]: ItemConfig } = {};
    private readonly categories: string[] = [];

    /**
     * Constructor
     * @param factoryType
     */
    constructor(factoryType: string) {
        super();
        this.type = factoryType;
    }

    /**
     * add a new handled item in this factory
     * @param type item type
     * @param constructor
     * @param defaultConfig
     */
    public addHandledItem(
        type: string,
        constructor: (config: Readonly<ItemConfig>) => Promise<ItemInstance>,
        defaultConfig: Readonly<ItemConfig>
    ): void {
        if (!type) return;
        if (this.types.indexOf(type) != -1) {
            throw Error(`Item type ${type} is already handled by factory ${this.getType()}`);
        }
        if (!constructor) {
            throw Error(`Invalid Item constructor function for item type ${type} in factory ${this.getType()}`);
        }
        if (!defaultConfig) {
            throw Error(`Invalid Item default config for item type ${type} in factory ${this.getType()}`);
        }
        if (defaultConfig.type && type != defaultConfig.type) {
            throw Error(
                `Default configuration of object type ${type} contains a different mandatory type ${
                    defaultConfig.type
                } in factory ${this.getType()}`
            );
        }

        // // check defaultConfig creation
        // try {
        //     let instance: ItemInstance = constructor(defaultConfig);
        //     if (!instance)
        //         throw Error("Unable to create item type " + type + " from factory " + this.getType() + " : returned instance is null");
        // } catch (error) {
        //     throw Error("An error occured testing item creation type " + type + " from factory " + this.getType() + " : " + error.message);
        // }

        this.types.push(type);
        this.constructors[type] = constructor;
        this.defaultConfigs[type] = deepCopy(defaultConfig);
        this.defaultConfigs[type].type = type; // force type to be coherent with id
    }

    /**
     * categories getter
     */
    public getCategories(): string[] {
        return this.categories;
    }

    /**
     * Add a category to the category list
     * @param category
     */
    public addCategory(category: string): void {
        this.categories.push(category);
    }

    /**
     * factory type getter
     */
    public getType(): string {
        return this.type;
    }

    /**
     * handled item types getter
     */
    public getHandledTypes(): string[] {
        return this.types;
    }

    /**
     * Create item instance using given configuration
     * @param config item configuration used to create instance (type must be set)
     */
    public createInstance(config: Readonly<ItemConfig>): Promise<ItemInstance> {
        if (!config?.type || typeof this.constructors[config.type] == 'undefined') return null;
        return this.constructors[config.type](config);
    }

    /**
     * get default configuration for given item type
     * @param type item type
     */
    public getDefaultConfig(type: string): ItemConfig {
        return this.defaultConfigs[type];
    }
}
