/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Time Helper functions
 */

export class TimeHelper {
    /**
     * Convert an integer number to string.
     * Fill with 0s to match given length (in characters count)
     */
    private static strpad(number: number, length: number): string {
        let str = '' + number;
        while (str.length < length) str = '0' + str;
        return str;
    }

    /**
     * Convert a time (expressed in milliseconds) to a default human readable format
     * @param time
     */
    public static defaultDateFormat(time: number): string {
        if (!time) return 'null time';
        const date = new Date(time);
        const dateFormat: string =
            date.getUTCFullYear() +
            '-' +
            TimeHelper.strpad(date.getUTCMonth() + 1, 2) +
            '-' +
            TimeHelper.strpad(date.getUTCDate(), 2) +
            'T' +
            TimeHelper.strpad(date.getUTCHours(), 2) +
            ':' +
            TimeHelper.strpad(date.getUTCMinutes(), 2) +
            ':' +
            TimeHelper.strpad(date.getUTCSeconds(), 2);
        return dateFormat;
    }
}
