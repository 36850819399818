/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Define All topaz item types used in topaz
 */
export class TpzApplicationTypes {
    public static readonly TPZ_WINDOW_TYPE: string = 'TpzWindowType';
    public static readonly TPZ_VIEW_TYPE: string = 'TpzViewType';
    public static readonly TPZ_DESKTOP_TYPE: string = 'TpzDesktopType';
    public static readonly DESKTOP_CONTAINER_HSPLIT_TYPE: string = 'DesktopContainerHSplitType';
}

/**
 * Define All topaz item categories used in topaz
 */
export class TpzApplicationCategories {
    public static readonly TPZ_WINDOW_CATEGORY: string = 'TpzWindowCategory';
    public static readonly TPZ_VIEW_CATEGORY: string = 'TpzViewCategory';
    public static readonly TPZ_PROCESS_CATEGORY: string = 'TpzProcessCategory';
    public static readonly TPZ_DESKTOP_CATEGORY: string = 'TpzDesktopCategory';
    public static readonly TPZ_DESKTOP_CONTAINER_CATEGORY: string = 'DesktopContainerItem';
}
