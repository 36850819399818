/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { TpzDesktopConfig } from '../desktop/tpz-desktop-config';
import { TpzWindowConfig, defaultTpzWindowConfig } from './tpz-window-config';
import { uuidv4 } from '../tools/uuid';
import { ItemConfig } from '../../tpz-catalog/tpz-item-config';
import { TpzApplicationTypes } from '../tpz-application-types';

/**
 * Helper functions relatives to windows
 */
export class TpzWindowHelper {
    /**
     * Create a new default window configuration
     */
    public static createNewClassicWindowConfig(id: string = null, title: string = null): TpzWindowConfig {
        const windowConfig: TpzWindowConfig = {
            ...defaultTpzWindowConfig,
            id: id ?? uuidv4(),
            title: title ?? id ?? 'autogenerated-window-' + uuidv4(),
            type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
            childrenIds: []
        };
        return windowConfig;
    }

    /**
     * Create a new system window configuration
     */
    public static createNewSystemWindowConfig(id: string = null, title: string = null): TpzWindowConfig {
        const windowConfig: TpzWindowConfig = TpzWindowHelper.createNewClassicWindowConfig(id, title);
        if (!windowConfig.classes) windowConfig.classes = [];
        windowConfig.classes.push('system-window');
        return windowConfig;
    }

    /**
     * Get the list of windows from a desktop configuration
     * @param desktopConfig desktop configuration containing windows
     * @return null if desktop is invalid, empty is no windows or an array of windows configurations
     */
    public static getWindowListFromDesktopConfig(
        desktopConfig: TpzDesktopConfig,
        app: TpzApplication
    ): TpzWindowConfig[] {
        if (!desktopConfig) return null;
        const windowConfigs: TpzWindowConfig[] = [];
        // loop over all items and check if they are windows
        desktopConfig.childrenIds?.forEach((itemId: string) => {
            const itemConfig: ItemConfig = app.getItemCatalog().getRegisteredConfigById(itemId);
            if (itemConfig && itemConfig?.type === TpzApplicationTypes.TPZ_WINDOW_TYPE) {
                windowConfigs.push(itemConfig as TpzWindowConfig);
            }
        });
        return windowConfigs;
    }

    // /**
    //  * Creates a parameter window module associated with an existing module
    //  * @param moduleId
    //  * @param application
    //  */
    // static createParameterModule(moduleId: string, application: TpzApplication): TpzModuleParameter {
    //     if (!moduleId) {
    //         throw new Error("Parameter module must define a parameterized module");
    //     }
    //     let parameterDisplayerConfig: TpzModuleParameterConfig = {
    //         id: moduleId + "-parameters",
    //         itemId: moduleId,
    //         type: TpzModuleParameter.MODULE_PARAMETER_TYPE,
    //     };
    //     application.getItemCatalog().registerInstanceConfig(parameterDisplayerConfig);
    //     return application.getItemCatalog().getOrCreateInstanceById(moduleId) as TpzModuleParameter;
    // }

    // /**
    //  * Create a window containing the given view and add it to current desktop
    //  * @param parameterView
    //  * @param applicaiton Topaz Application
    //  */
    // public static addWindowToCurrentDesktop(itemId: string, windowConfig: TpzWindowConfig, application: TpzApplication): boolean {
    //     if (!itemId || !application) return false;
    //     let currentDesktop: TpzDesktop = application.getDesktopManager().getActiveDesktop();
    //     if (!currentDesktop) {
    //         application.getLogger()?.warn("There is no Current Desktop defined. Unable to display parameters for item #" + itemId);
    //         return false;
    //     }
    //     let enhancedWindowConfig: TpzWindowConfig = {
    //         id: itemId + "-window",
    //         ...windowConfig,
    //         type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
    //         childrenIds: [itemId],
    //     }
    //     // check if itemId is registered in catalog
    //     if (!application.getItemCatalog().getRegisteredConfig(itemId)) {
    //         application.getLogger()?.error("Item ID #" + itemId + " to be displayed in a desktop window is not registered in item catalog");
    //         return false;
    //     }
    //     // // add window to application catalog (Already done in desktop.addItem)
    //     // application.getItemCatalog().registerInstanceConfig(enhancedWindowConfig);
    //     // add window to desktop and automatically start(display) it
    //     TpzApplicationCommander.addDesktopItem(application, currentDesktop.getId(), enhancedWindowConfig, true);
    //     return true;
    // }
}
