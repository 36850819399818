/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoadHelper } from '../tpz-application/tools/loader';
import { I18N } from './tpz-i18n-static';

/**
 * An entry of an internationalization dictionary.
 * This entry must be assigned to a key to be accessible
 */
export type I18NDictionaryEntry = {
    key: string;
    text?: string;
    icon?: string;
};

/**
 * A dictionary is composed of a language, an optional group and keys associated to Dictionary entries
 */
export interface I18NDictionary {
    // dictionary language
    lang: string;
    // dictionary grouping ID
    groupId: string;
    // associate a key with a dictionary entry
    entries: { [id: string]: I18NDictionaryEntry };
}

/**
 * A dictionary Group is composed of a set of dictionaries with the same language
 */
export type I18NDictionaryGroup = { [groupId: string]: I18NDictionary };

/**
 * Dictionary manager manages a collection of dictionaries with languages and groups
 */
export class I18NDictionaryManager {
    private readonly dictionaryGroupsByLang: { [lang: string]: I18NDictionaryGroup } = {};

    /**
     * add dictionary in Internationalization manager
     * @param dict dictionary resource
     */
    public addDictionary(dict: I18NDictionary): boolean {
        if (!dict) {
            I18N.getLogger()?.error('Cannot import null dictionary');
            return false;
        }
        if (!dict.lang) {
            I18N.getLogger()?.error('Cannot import a dictionary with a null language');
            return false;
        }
        const lang: string = dict.lang;
        // get all groups for the given lang
        let group: I18NDictionaryGroup = this.dictionaryGroupsByLang[lang];
        if (!group) {
            // if no dictionaryGroup exist for this language: create a new one
            group = this.dictionaryGroupsByLang[lang] = {};
        }
        if (!group[dict.groupId]) {
            // create a new group
            group[dict.groupId] = {
                lang: dict.lang,
                groupId: dict.groupId,
                entries: dict.entries
            };
        } else {
            // merge dictionary into group dictionaries
            group[dict.groupId].entries = { ...dict.entries, ...group[dict.groupId].entries };
        }
        return true;
    }

    /**
     * add dictionaries in Internationalization manager
     * @param dict dictionary array resource
     */
    public addDictionaries(dicts: I18NDictionary[]): boolean {
        if (!dicts) {
            I18N.getLogger()?.error('Cannot import null dictionaries');
            return false;
        }
        let ok: boolean = true;
        dicts.forEach((dict: I18NDictionary) => {
            ok = this.addDictionary(dict);
        });
        return ok;
    }

    /**
     * get all dictionaries associated to a given language
     * @param lang language
     * @return undefined if no dictionaries were added
     */
    public getDictionariesByLanguage(lang: string): I18NDictionary[] {
        if (!this.dictionaryGroupsByLang[lang]) return null;
        return Object.values(this.dictionaryGroupsByLang[lang]);
    }

    /**
     * get a dictionary associated to a given language and a given groupId
     * @param lang language
     */
    public getDictionaryByLanguageAndGroup(lang: string, groupId: string = I18N.DEFAULT_GROUP_ID): I18NDictionary {
        const dicts: I18NDictionary[] = this.getDictionariesByLanguage(lang);
        if (!dicts) return null;
        for (let index = 0; index < dicts.length; index++) {
            if (dicts[index].groupId === groupId) return dicts[index];
        }
        return null;
    }

    /**
     * Async dictionary loading and set language as current. Use importDictionary
     * if you need to load it without setting language as current
     * @param lang language identifier
     * @param dictionaryResource path to distant dictionary resource
     */
    public importDictionaryURL(lang: string, dictionaryURL: string): Promise<void> {
        return LoadHelper.fetchRequest(dictionaryURL).then((response: Response): Promise<void> => {
            return response.json().then<any>((content: I18NDictionary | I18NDictionary[]) => {
                if (Array.isArray(content)) this.addDictionaries(content as I18NDictionary[]);
                this.addDictionary(content as I18NDictionary);
                return null;
            });
        });
    }

    // /**
    //  * Async dictionary loading and set language as current. Use importDictionary
    //  * if you need to load it without setting language as current
    //  * @param lang language identifier
    //  * @param dictionaryResource path to distant dictionary resource
    //  */
    // public loadDictionaryJSON(lang: string, dictionaryString: string): Promise<I18NDictionary> {
    //     const me: I18N = this;
    //     return this.importDictionary(lang, dictionaryURL).then((dict: I18NDictionary) => {
    //         me.setLanguage(lang);
    //         return dict;
    //     });
    // }

    // /**
    //  * Set a dictionary entry for a language and a group
    //  */
    // public setDictionaryEntry(lang: string, groupId: string, key: string, entry: I18NDictionaryEntry): boolean {
    //     if (!lang) {
    //         I18N.getLogger()?.error("I18N.setDictionaryEntry: lang is null");
    //         return false;
    //     }
    //     groupId = groupId ?? I18N.DEFAULT_GROUP_ID;
    //     if (!key) {
    //         I18N.getLogger()?.error("I18N.setDictionaryEntry: key is null");
    //         return false;
    //     }
    //     // get all dictionaries for the given language
    //     let dicts: I18NDictionary[] = this.getDictionariesByLanguage(lang);
    //     if (!dicts) {
    //         // create a dictionary for the given language
    //         dicts = this.dictionaries[lang] = [];
    //     }
    //     // get the
    //     // delete key if entry is null
    //     if (!entry) {
    //         delete dict[key];
    //     } else {
    //         dict[key] = entry;
    //     }
    //     return true;
    // }

    /**
     * translate current DOM with given language
     * if groupId is not set or null: use all groups
     */
    public translate(lang: string, groupIds: string | string[] = null): boolean {
        if (!lang) {
            I18N.getLogger()?.warn('I18N.translate(): lang is null');
            return false;
        }
        // if groupIds not set, translate using all groups
        if (!groupIds) return this.translate(lang, this.getGroupIds(lang));
        // if groupIds is an array translate all given groups
        if (Array.isArray(groupIds)) {
            let ok: boolean = true;
            (groupIds as string[]).forEach((groupId) => (ok = this.translate(lang, groupId)));
            return ok;
        }
        // groupIds is a single group
        const dict: I18NDictionary = this.getDictionaryByLanguageAndGroup(lang, groupIds as string);
        if (!dict) {
            I18N.getLogger()?.warn("I18N.translate(): lang / group doesn't exist");
            return false;
        }
        // loop over all entries
        this.translateDictionary(dict);

        return true;
    }

    // /**
    //  * set the current dictionary (apply translation to DOM elements)
    //  */
    // public translateFullLanguage(lang: string): boolean {
    //     if (!lang) {
    //         I18N.getLogger()?.error("I18N.setLanguage: lang is null");
    //         return false;
    //     }
    //     let dict: I18NDictionary = this.dictionaryGroupsByLang[lang];
    //     if (!dict) {
    //         I18N.getLogger()?.error("I18N.setLanguage(): no dictionary for language " + lang);
    //         return false;
    //     }
    //     I18N.currentLanguage = lang;
    //     // apply translation
    //     I18N.translateDictionary(dict);
    //     return true;
    // }

    /**
     * get all available languages (currently stored)
     */
    public getLanguages(): string[] {
        return Object.keys(this.dictionaryGroupsByLang);
    }

    /**
     * get all group Ids for a language
     * @return null if lang is not defined or doesn't exist in stored dictionaries.
     */
    public getGroupIds(lang: string): string[] {
        if (!lang) {
            I18N.getLogger()?.warn('I18N.getAvailableGroupIds(): lang is null');
            return [];
        }
        const groups: I18NDictionaryGroup = this?.dictionaryGroupsByLang[lang];
        if (!groups) return [];
        return Object.keys(groups);
    }

    /**
     * take all elements with an attribute 'i18n' and verify
     * if an entry exists in the dictionary
     */
    public checkTranslation(dict: I18NDictionary): void {
        const tags: NodeListOf<HTMLElement> = document.querySelectorAll('[i18n]');
        tags.forEach(function (el: HTMLElement): void {
            const key: string = el.getAttribute(I18N.I18N_ATTRIBUTE);
            if (key && key !== '') {
                const entry: I18NDictionaryEntry = dict.entries[key];
                if (!entry) {
                    I18N.getLogger()?.warn("[I18N check] language dictionary does not contain entry '" + key + "'");
                }
            }
        });
    }

    // take all elements with an attribute 'i18n' and look into the current dictionary to replace their
    // innerHTML by the dictionary value
    public translateDictionary(dict: I18NDictionary) {
        const tags: NodeListOf<HTMLElement> = document.querySelectorAll('[i18n]');
        tags.forEach(function (el: HTMLElement): void {
            const key: string = el.getAttribute(I18N.I18N_ATTRIBUTE);
            if (key && key !== '') {
                const entry: I18NDictionaryEntry = dict.entries[key];
                if (entry) {
                    // change text innerHTML ('text' component)
                    if (entry.text) el.innerHTML = key in dict ? entry.text : 'I18N-' + key + '-I18N';
                    // change icon entry: element must have an 'src' attribute
                    if (entry.icon) {
                        if (typeof (el as any).src !== 'undefined') {
                            (el as any).src = entry.icon;
                        } else {
                            I18N.getLogger()?.warn(
                                "[I18N] internationalization key '" +
                                    key +
                                    "' is defined but element has no 'src' property"
                            );
                        }
                    }
                    // } else {
                    // I18N.getLogger()?.warn("[I18N] language dictionary does not contain entry '" + key + "'")
                }
            }
        });
    }
}
