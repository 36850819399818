/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

//import * as xhr2 from "xhr2";
import { TpzApplication } from '../tpz-application/tpz-application-core';
import {
    AccessorValueImportExport,
    DataConvertFromString,
    DataConvertToString,
    AccessorType
} from './tpz-access-types';
import { ValueAccessor, ValueAccessorConfig, defaultValueAccessorConfig } from './tpz-access-value';
import { deepCopy } from '../tpz-application/tools/deep-copy';
import { Accessor } from './tpz-access-core';

/**
 * Remote Accessor
 * FIXME: Use TpzLoad Library
 */

export type RequestMethod = 'POST' | 'GET';

export interface RemoteAccessorConfig<DataType> extends ValueAccessorConfig<DataType> {
    method?: RequestMethod;
    url?: string;
    params?: { [key: string]: string }; // URL parameters (url?key=value&...)
    header?: { [key: string]: string }; //
    data?: any; // data send (POST method only)
}

// default config
export const defaultRemoteAccessorConfig: RemoteAccessorConfig<any> = {
    ...defaultValueAccessorConfig,
    type: AccessorType.REMOTE_ACCESSOR_TYPE,
    method: 'GET',
    url: 'http://unavailable.url',
    params: {},
    header: {},
    data: null
};

/**
 * Accessor using an AJAX request to get its value.
 * It uses the METHOD and URL defined in config
 * Errors:
 * type REQUEST_STATUS_ERROR: detail is of type XMLHttpRequest
 * type NETWORK_ERROR: detail is of type
 * type RESPONSE_TYPE_ERROR: request OK but an error occured converting response for setValue(response)
 */
export class RemoteAccessor<DataType> extends ValueAccessor<DataType> {
    public static readonly REMOTE_ERROR: string = 'REMOTE_ERROR';
    public static readonly RESPONSE_TYPE_ERROR: string = 'RESPONSE_TYPE_ERROR';
    public static readonly REQUEST_STATUS_ERROR: string = 'REQUEST_STATUS_ERROR';
    public static readonly NETWORK_ERROR: string = 'NETWORK_ERROR';

    private readonly lastAvailability: boolean = false; // last ajax request status

    /**
     * Accessor Constructor
     * @param config accessor configuration
     */
    constructor(
        config: RemoteAccessorConfig<DataType>,
        type: string,
        app: TpzApplication,
        importFunction: DataConvertFromString<DataType>,
        exportFunction: DataConvertToString<DataType>
    ) {
        super(deepCopy(defaultRemoteAccessorConfig, config), type, app, importFunction, exportFunction);
        this.addCategory(AccessorType.REMOTE_ACCESSOR_TYPE);
    }

    /**
     * get accessor availability. It uses last ajax request response to answer
     */
    public isRunning(): boolean {
        if (!super.isRunning) return false;
        return this.lastAvailability;
    }

    /**
     * get Config specialization
     */
    public getConfig(): RemoteAccessorConfig<DataType> {
        return super.getConfig() as RemoteAccessorConfig<DataType>;
    }

    /**
     * Convert a dictionary to an URI string (key=value&key=value&...)
     */
    private dictToURI(dict: any): string {
        if (!dict) return '';
        const str = [];
        for (const p in dict) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(dict[p]));
        }
        return str.join('&');
    }

    /**
     * Method called when the AJAX request send a valid response.
     * This method may be overridden to handle specific responses
     * Default behaviour is to call 'setValue(request.response) as DataType'
     * @param request request containing the response
     */

    public handleResponse(request: XMLHttpRequest): void {
        if (!request || !request.response) return;
        this.setValue(this.importValue(request.response));
    }

    /**
     * Launch a new AJAX request
     */
    public requestUpdate(): boolean {
        if (!this.isRunning()) return false;
        const me: RemoteAccessor<DataType> = this;

        // const headers = new Headers()
        // // headers.append('Content-Type', 'plain/text')
        // fetch(new Request('https://celestrak.com/NORAD/elements/tle-new.txt'), {
        //   method: 'GET',
        //   mode: 'no-cors',
        // })
        //   .then(response => {
        //     response.text().then(text => {
        //       console.error('TXT = ', text)
        //     })
        //     response.json().then(json => {
        //       console.error('JSON = ', json)
        //     })
        //   })
        //   .catch(
        //     err => {
        //       console.error('WTF?', err)
        //     })

        // Refactor to use fetch
        const request = new XMLHttpRequest();
        request.onload = function () {
            if (request.status === 200) {
                try {
                    me.handleResponse(request);
                } catch (error) {
                    me.addError([RemoteAccessor.RESPONSE_TYPE_ERROR, `request status = ${request.status}`]);
                    me.addError([RemoteAccessor.RESPONSE_TYPE_ERROR, request.response]);
                }
            } else {
                me.addError([RemoteAccessor.REQUEST_STATUS_ERROR, request]);
            }
        };

        // erroneous AJAX connection
        request.onerror = function (e: any) {
            me.addError(e);
            me.addError([RemoteAccessor.NETWORK_ERROR, request]);
        };

        // Request construction POST or GET
        const config: RemoteAccessorConfig<DataType> = this.getConfig();
        let url: string = config.url;
        // construct URL using params if params are set
        const params: string = this.dictToURI(config.params);
        if (params) url = url + '?' + params;
        request.open(config.method, url, true);
        if (config.header) {
            for (const key in config.header) {
                request.setRequestHeader(key, config.header[key]);
            }
        }
        request.send(config.data);
        return true;
    }

    /** url getter and setters  */
    public getURL(): string {
        return this.getConfig().url;
    }
    /**
     * Set URL in config. Use applyConfig() to really apply changes
     * @param url request URL to use
     */
    public setURL(url: string): void {
        const config: RemoteAccessorConfig<DataType> = deepCopy(this.getConfig());
        config.url = url;
        this.applyConfig(config);
    }
    /** method getter and setters  */
    public getMethod(): RequestMethod {
        return this.getConfig().method;
    }
    /**
     * Set method in config. Use applyConfig() to really apply changes
     * @param method request method to set
     */
    public setMethod(method: RequestMethod): void {
        const config: RemoteAccessorConfig<DataType> = deepCopy(this.getConfig());
        config.method = method;
        this.applyConfig(config);
    }
}

/**
 * RemoteAccessor without data conversion
 */
export type StringRemoteAccessorConfig = RemoteAccessorConfig<string>;

// default remote socket config
export const defaultStringRemoteAccessorConfig: StringRemoteAccessorConfig = {
    ...defaultRemoteAccessorConfig,
    type: AccessorType.STRING_REMOTE_ACCESSOR_TYPE
};

/**
 * Accessor using an AJAX request to get its value.
 */
export class StringRemoteAccessor extends RemoteAccessor<string> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app application in which this accessor is used
     */
    constructor(config: StringRemoteAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultStringRemoteAccessorConfig, config),
            AccessorType.STRING_REMOTE_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringString,
            AccessorValueImportExport.convertToStringString
        );
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): StringRemoteAccessorConfig {
        return super.getConfig() as StringRemoteAccessorConfig;
    }
}

// item type (used in default configuration and Item Instance constructor)

/**
 * RemoteAccessor JSON data conversion
 */
export type JsonRemoteAccessorConfig = RemoteAccessorConfig<string>;

// default remote socket config
export const defaultJsonRemoteAccessorConfig: JsonRemoteAccessorConfig = {
    ...defaultRemoteAccessorConfig,
    type: AccessorType.JSON_REMOTE_ACCESSOR_TYPE
};

/**
 * Accessor using an AJAX request to get its value.
 */
export class JsonRemoteAccessor extends RemoteAccessor<string> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app application in which this accessor is used
     */
    constructor(config: JsonRemoteAccessorConfig, app: TpzApplication) {
        super(
            deepCopy(defaultJsonRemoteAccessorConfig, config),
            AccessorType.JSON_REMOTE_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertFromStringDictionary,
            AccessorValueImportExport.convertToStringDictionary
        );
    }

    /**
     * get Config specialization
     */
    public getConfig(): JsonRemoteAccessorConfig {
        return super.getConfig() as JsonRemoteAccessorConfig;
    }
}
