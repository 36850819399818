/**
 * this function compare two arrays. Elements are compared in order using !== operator
 * @param a1 first array to compare
 * @param a2 second array to compare
 * @return true if both arrays are strictly equal (order dependent)
 */
export function arrayEquals(a1: any[], a2: any[]): boolean {
    if (!a1 && !a2) return true;
    if (a1 && !a2) return false;
    if (a2 && !a1) return false;
    if (a1.length !== a2.length) return false;
    // compare elements one by one
    let i: number = a1.length;
    while (i--) {
        if (a1[i] !== a2[i]) return false;
    }
    return true;
}

/**
 * Find the new elements between an old and a new array.
 * @return all elements which are in NEW and not in OLD
 */
export function arrayNewElements<T>(oldArray: T[], newArray: T[]): T[] {
    if (!oldArray && !newArray) {
        return [];
    }
    if (!oldArray) {
        return newArray;
    }
    if (!newArray) {
        return [];
    }
    return newArray.filter((newElement: T) => !oldArray.includes(newElement));
}

/**
 * Find the old elements between an old and a new array
 * @return all elements which are in OLD and not in NEW
 */
export function arrayOldElements<T>(oldArray: T[], newArray: T[]): T[] {
    if (!oldArray && !newArray) {
        return [];
    }
    if (!newArray) {
        return oldArray;
    }
    if (!oldArray) {
        return [];
    }
    return oldArray.filter((oldElement: T) => !newArray.includes(oldElement));
}
