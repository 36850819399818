/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzDesktopConfig } from './tpz-desktop-config';
import { uuidv4 } from '../tools/uuid';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationTypes } from '../tpz-application-types';

/**
 * Helper functions relatives to Desktops
 */
export class TpzDesktopHelper {
    /**
     * Create a new default desktop configuration
     * @desktopId desktop Identifier. If null one is generated
     * @desktopName desktop name. If null a default 'auto generated desktop' is used
     */
    public static createNewDesktopConfig(desktopId: string = null, desktopName: string = null): TpzDesktopConfig {
        // autocreate a desktop if none is currently defined
        const desktopConfig: TpzDesktopConfig = {
            id: desktopId ?? uuidv4(),
            type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
            childrenIds: [],
            name: desktopName ?? 'auto generated desktop'
        };
        return desktopConfig;
    }

    /**
     * Retrieves the list of registered desktops from an application.
     * @param app: ToPaZ application containing the registered desktops
     */
    public static getDesktopList(app: TpzApplication): TpzDesktopConfig[] {
        if (!app) return [];
        return app.getDesktopManager()?.getRegisteredDesktopConfigs();
    }

    // /**
    //  * return the current application desktop or create a new one (and set it as current)
    //  */
    // public static getCurrentOrCreateNewDesktop(app: TpzApplication): TpzDesktop {
    //     if (!app) return null;
    //     // try to retrieve current desktop
    //     let desktop: TpzDesktop = app.getDesktopManager().getActiveDesktop();
    //     if (desktop) return desktop;
    //     // create a new desktop
    //     let desktopConfig: TpzDesktopConfig = {
    //         id: uuidv4(),
    //         name: "auto generated desktop",
    //         itemConfigs: []
    //     };
    //     // register, create and open new desktop
    //     app.getDesktopManager().registerDesktopConfig(desktopConfig);
    //     desktop = app.getDesktopManager().getDesktopById(desktopConfig.id);
    //     TpzApplicationCommander.openSingleDesktop(app, desktopConfig.id);
    //     return desktop;
    // }
}
