/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Creates a deep copy of all given sources.
 * sources are merged into one single object
 * if multiple values override, values are overridden in order
 * (last prevent)
 * @param sources sources to deep copy
 */
export function deepCopy(...sources: any): any {
    if (!sources) return null;
    let copy: any = {};
    sources.forEach((source: any) => {
        copy = { ...copy, ...JSON.parse(JSON.stringify(source)) };
    });
    return copy;
}

/**
 * return if two dictionary objects are strictly equals: all members are recursively deepEquals
 */
export function deepEquals(obj1: any, obj2: any): boolean {
    if (!obj1 && !obj2) return true;
    if (obj1 && !obj2) return false;
    if (!obj1 && obj2) return false;
    try {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    } catch (reason: any) {
        console.error('Unable to convert dictionnaries when checking equality');
        return false;
    }
}
