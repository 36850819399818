/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application/tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../tpz-application/tpz-application-event';
import { ItemConfig, defaultItemConfig } from '../tpz-catalog/tpz-item-config';
import { ItemInstance } from '../tpz-catalog/tpz-item-core';
import { GenericItemFactory } from '../tpz-catalog/tpz-item-factory';
import { AccessorEventType } from './tpz-access-event';
import { deepCopy } from '../tpz-application/tools/deep-copy';
import { AccessorCategoryType } from './tpz-access-types';

/**
 * Accessors common configuration:
 * - id: a unique id (undefined behavior if not unique)
 * - name: optional accessor name (for human readable purpose, but do not replace id)
 * - dataType: kind of data this accessor is managing (used for automatic transformation)
 * - maxErrorCount: number of error generated when accessing accessor
 */
export interface AccessorConfig extends ItemConfig {
    name?: string; // possibility to name the value
    dataType?: string; // DataType returned by the accessor. Arrays are automatically handled. use only inner data types
}

/**
 * Default Accessor configuration
 * It does use the default Item config ! this is because Accessor IS an ItemConfig (loop)
 */
export const defaultAccessorConfig: AccessorConfig = {
    ...defaultItemConfig,
    name: 'unnamed',
    dataType: undefined
};
/**
 * An accessor is an object which gives a value. This value can be static or dynamic,
 * can be retrieved using different manner: static value, computed value, ajax or websocket requests...
 */
export abstract class Accessor<DataType> extends ItemInstance {
    /**
     * Accessor constructor
     * @param config Accessor configuration
     * @param type Item/Accessor type/name
     * @param app TpzApplication in which the accessor in used
     */
    constructor(config: AccessorConfig, type: string, application: TpzApplication) {
        super(deepCopy(defaultAccessorConfig, config), type, application);
        this.addCategory(AccessorCategoryType.ACCESSOR_CATEGORY_TYPE);
    }

    public abstract getValue(): DataType;

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): AccessorConfig {
        return super.getConfig() as AccessorConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: AccessorConfig = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.name !== newConfig?.name) {
            // nothing to be done
            changes = true;
        }
        if (config?.dataType !== newConfig?.dataType) {
            // nothing to be done
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }

    /**
     * Set the dataType config field. ToPaZ Root types can be found in AccessData class
     * @param accessorDataType data type to set in config
     */
    public setDataType(accessorDataType: string): void {
        const config: AccessorConfig = deepCopy(this.getConfig());
        config.dataType = accessorDataType;
        this.applyConfig(config);
    }
    /**
     * launch a requestUpdate on accessor Start
     */
    public postStart(): Promise<void> {
        return super.postStart().then(() => {
            this.requestUpdate(true);
        });
    }

    /**
     * Callback to perform on Application event
     * @param event application event
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STOPPING:
                TpzApplicationEventType.checkEvent(event, null);
                this.stop();
                return true;
            case AccessorEventType.LIGHT_UPDATE_REQUESTED:
            case AccessorEventType.HARD_UPDATE_REQUESTED:
                {
                    TpzApplicationEventType.checkEvent(event, 'itemId');
                    const itemId: string = event.content.itemId;
                    if (this.getId() === itemId) {
                        this.requestUpdate(event.type === AccessorEventType.HARD_UPDATE_REQUESTED);
                    }
                }
                return true;
        }
        return super.onApplicationEvent(event);
    }
}

/**
 * Abstract accessor base class
 */
export abstract class AccessorFactory extends GenericItemFactory {}

// /**
//  * Accessor catalog contains a collection of accessors and knows howto to generate them using their IDs
//  */
// export class AccessorCatalog extends ItemCatalog {
// }
