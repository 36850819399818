/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { isConditionalExpression } from 'typescript';
import { TpzApplicationComponent } from '../tpz-application-component';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../tpz-application-event';
import { TpzAddOn, TpzAddOnDescriptor } from './addons-core';
import { TpzAddOnHelper } from './addons-helper';

/**
 * this class manages a set of add-ons
 * Add-Ons can be registered locally by setting an ID related to an addon URL (where the add-ondescriptor is)
 * They also can be discovered directly by scanning the registry URL (where descriptor files)
 */
// FIXME extends TpzApplicationComponent    containing public start()
// and ser registry address from configuration
export class TpzAddOnManager extends TpzApplicationComponent {
    private addOnDescriptors: { [id: string]: TpzAddOnDescriptor } = {}; // map addon ids to addons URL
    private readonly localAddOns: { [id: string]: string } = {}; // map addon ids to addons url
    private addOnRegistryURLs: string[] = ['http://localhost:8080/addons/registry'];
    private readonly addOnLoaders: { [id: string]: Promise<TpzAddOn> } = {};
    private updateInProgress: boolean = false; // avoid updating multiple times
    private readonly updateDelay: number = 1000; // do not update if time is lower than this delay

    /**
     * AddOn Manager constructor
     */
    public constructor(app: TpzApplication, id: string) {
        super(app, id);
    }

    /**
     * Starts AddOn manager
     */
    public start(): Promise<void> {
        return super.start().then(() => {
            this.update();
        });
    }

    /**
     * Id getter
     */
    public getId(): string {
        return 'addOnManager';
    }

    /**
     * Get a registered AddOn Descriptor by its ID
     */
    public getDescriptorById(addOnId: string): TpzAddOnDescriptor {
        if (!addOnId) return null;
        if (!this.addOnDescriptors) return null;
        return this.addOnDescriptors[addOnId];
    }

    /**
     * Register an add-on by its ID and a related URL
     * Those add-ons can be referenced in (Desktop) Item configuration 'addOnIds'
     * @param addOnId
     * @param addOnURL
     */
    public registerLocalAddOn(addOnId: string, addOnURL: string): boolean {
        if (!addOnId) return false;
        if (!addOnURL) return false;
        this.localAddOns[addOnId] = addOnURL;
        return true;
    }

    /**
     * get the loader associated with an add-on if it exists. null if not
     * The promise may be fullfilled or rejected...
     * @param addOnId
     * @returns
     */
    public getExistingLoader(addOnId: string): Promise<TpzAddOn> {
        return this.addOnLoaders[addOnId];
    }

    /**
     * get locally Registered add-on URLS
     */
    public getLocalAddOnURLs(): { [id: string]: string } {
        return this.localAddOns;
    }

    /**
     * get the URL location associated with a local add-ons ID
     */
    public getLocalAddOnURLById(addOnId: string): string {
        return this.localAddOns[addOnId];
    }

    /**
     * load a registered AddOn by its ID
     * the addon is first searched into local addon (registerLocalAddOn())
     * if not found it looks into the registries.
     * load AND start the loaded addon
     * @param addOnId addon id registered in this manager
     * @returns a promise on loaded Addon
     */
    public loadAddOnById(addOnId: string): Promise<TpzAddOn> {
        const me: TpzAddOnManager = this;
        if (!addOnId) return Promise.reject(new Error('AddOn manager asked to load an undefined add-on ID'));

        // look for existing addon promise loader in cache
        let addOnPromiseLoader: Promise<TpzAddOn> = this.addOnLoaders[addOnId];
        if (addOnPromiseLoader) {
            return addOnPromiseLoader;
        }
        // look in local addons
        const addOnURL: string = this.localAddOns[addOnId];
        if (addOnURL) addOnPromiseLoader = TpzAddOnHelper.loadTpzAddOnFromURL(addOnURL);

        // if no local add-on defined, look into add-on descriptors read from registries
        if (!addOnPromiseLoader) {
            addOnPromiseLoader = TpzAddOnHelper.loadTpzAddOnByDescriptor(this.getDescriptorById(addOnId));
        }

        // if no External loader is found, addon cannot be loaded
        if (!addOnPromiseLoader) {
            throw new Error(
                `No AddOn descriptor registered with ID ${addOnId}. Local AddOns: ${Object.keys(this.localAddOns).join(
                    ', '
                )}. Registries AddOns: ${Object.keys(this.getDescriptors()).join(', ')}`
            );
        }
        // store loader
        this.addOnLoaders[addOnId] = addOnPromiseLoader;
        // return the current loader and start add-on
        const progressLoaderId: string = this.getApplication()
            .getProgressLoaderManager()
            .addProgress(this, `loading add-on #${addOnId}`);
        return addOnPromiseLoader
            .then((addon: TpzAddOn) => {
                if (!addon) throw new Error(`Addon Loader returns a null AddOn object for ID #${addOnId}`);
                this.getApplication()
                    .getProgressLoaderManager()
                    .addProgressDescription(progressLoaderId, `AddOn #${addOnId} loaded. Start it`);
                return addon;
            })
            .then((addon: TpzAddOn) => {
                return addon.start(me.getApplication()).then(() => {
                    return addon;
                });
            })
            .catch((reason: Error) => {
                me.getLogger().error(`Error requesting Add-On ID #${addOnId}`);
                me.getLogger().error(`Request Error ${reason.name} = ${reason.message}`, reason);
                me.getLogger().info(`registries: ${this.getAddOnRegistryURLs().join(', ')}`);
                if (me.getDescriptors()) {
                    me.getLogger().info(
                        `Available addons: ${Object.values(me.getDescriptors())
                            .map((x) => x.libraryName)
                            .join(', ')}`
                    );
                } else me.getLogger().info(`Add-Ons getDescriptors() returns null`);
                throw reason;
            })
            .finally(() => {
                this.getApplication().getProgressLoaderManager().endProgress(progressLoaderId);
            });
    }

    /**
     * get the server base URL for addons registry
     * @returns
     */
    public getAddOnRegistryURLs(): string[] {
        return this.addOnRegistryURLs;
    }

    /**
     * get addons descriptors currently stored.
     * Thoses descriptors are retrieved from remote addOn Registry
     */
    public getDescriptors(): { [id: string]: TpzAddOnDescriptor } {
        return this.addOnDescriptors;
    }

    /**
     * set the server base URL for addons registry
     */
    public setAddOnRegistryURLs(registryURLs: string[]): void {
        this.addOnRegistryURLs = registryURLs;
    }

    /**
     * Add a new add on registry
     * @param registryURL
     * @returns
     */
    public registerServer(registryURL: string): boolean {
        if (!this.addOnRegistryURLs) this.addOnRegistryURLs = [];
        if (!registryURL) return false;
        if (this.addOnRegistryURLs.includes(registryURL)) return false;
        this.addOnRegistryURLs.push(registryURL);
        return true;
    }

    /**
     * register an add on by its description
     * @returns
     */
    private storeAddOnDescriptor(desc: TpzAddOnDescriptor, fireEvent: boolean = true): void {
        if (!this.addOnDescriptors) this.addOnDescriptors = {};
        this.addOnDescriptors[desc.id] = desc;
        if (fireEvent) this.fireEvent(TpzApplicationEventType.ADDONS_REGISTRY_UPDATED, null);
    }

    /**
     * update the add on descriptor in the case of registry URLs changes
     */
    public update(): Promise<void> {
        return this.updateAddOnsDescriptors();
    }

    /**
     * update the add on descriptor in the case of registry URLs changes
     */
    private updateAddOnsDescriptors(): Promise<void> {
        // empty current cache
        this.addOnDescriptors = {};
        return this.updateRegistriesAddOnsDescriptors()
            .catch((reason: any) => {
                this.getLogger().error('Some errors occurred during addon registries update', reason);
            })
            .then(() => {
                this.updateLocalAddOnsDescriptors();
            });
    }

    /**
     * update locally registered add-on descriptors
     */
    private updateLocalAddOnsDescriptors(): Promise<void> {
        return Promise.resolve().then(() => {
            Object.entries(this.getLocalAddOnURLs()).forEach(([addOnId, addOnURL]) => {
                TpzAddOnHelper.loadTpzAddOnDescriptorFromURL(addOnURL)
                    .then((addOnDescriptor: TpzAddOnDescriptor) => {
                        this.storeAddOnDescriptor(addOnDescriptor);
                    })
                    .catch((reason: any) => {
                        this.getLogger().error(`Error retrieving add-on descriptor #${addOnId} from URL ${addOnURL}`);
                    });
            });
        });
    }

    /**
     * get the script URL from local or remote server
     * @param addOnId add id to be retrieved
     */
    public getScriptURLById(addOnId: string): string {
        // look in local addons
        const addOnURL: string = this.localAddOns[addOnId];
        if (addOnURL) return addOnURL;

        const descriptor: TpzAddOnDescriptor = this.getDescriptorById(addOnId);
        if (descriptor) return descriptor.addOnURL;

        return `unknown add-on ID #${addOnId}`;
    }

    /**
     * Read all add-on registry URLs and stores addons descriptors
     */
    private updateRegistriesAddOnsDescriptors(): Promise<void> {
        const registryLoaderPromises: Promise<void>[] = [];
        // avoid updating multiple time concurrently
        if (this.updateInProgress) {
            this.getLogger().warn(`Do not update addons registries, update already in progress`);
            return Promise.resolve(null);
        }
        this.updateInProgress = true;
        const progressId: string = this.getApplication()
            .getProgressLoaderManager()
            .addProgress(this.getId(), 'Updating addons');
        // create a promise for all registries
        this.getAddOnRegistryURLs()?.forEach((addOnRegistryURL: string) => {
            const registryLoaderPromise: Promise<void> = this.updateAddOnsFromRegistry(addOnRegistryURL);
            this.getApplication()
                .getProgressLoaderManager()
                .addProgressDescription(progressId, `updating registry ${addOnRegistryURL}`);
            if (!registryLoaderPromise) return;
            registryLoaderPromises.push(
                registryLoaderPromise
                    .then(() => {
                        this.getApplication()
                            .getProgressLoaderManager()
                            .addProgressDescription(progressId, `registry ${addOnRegistryURL} correctly updated`);
                    })
                    .catch((error: Error) => {
                        // handle catch in order not to stop all promises
                        this.getLogger().error(`An error occurred loading registry ${addOnRegistryURL}`, error);
                        this.getLogger().info('Continue loading other registries...');
                        return null;
                    })
            );
        });
        return Promise.all(registryLoaderPromises)
            .then(() => {
                this.getLogger().info(
                    `All addon registries updated (${registryLoaderPromises.length} registr${
                        registryLoaderPromises.length <= 1 ? 'y' : 'ies'
                    }`
                );
            })
            .catch((error: Error) => {
                this.getLogger().error(`An error occured updating addon registries: ${error.message}`, error);
            })
            .finally(() => {
                this.getApplication().getProgressLoaderManager().endProgress(progressId);
                window.setTimeout(() => {
                    this.updateInProgress = false;
                }, this.updateDelay);
            });
    }

    /**
     * Read the registry addons list and register all addons
     */
    private updateAddOnsFromRegistry(registryURL: string): Promise<void> {
        const me: TpzAddOnManager = this;
        return this.getApplication()
            .fetchTopazServer(registryURL)
            .then((response: Response) => {
                if (!response.ok) {
                    return Promise.reject(
                        new Error(`Cannot retrieve server response for addon registry @${registryURL}`)
                    );
                }
                return response.json();
            })
            .then((jsonResponse: any) => {
                if (!jsonResponse) {
                    return Promise.reject(new Error(`Malformed addon registry response: empty response`));
                }
                if (!Array.isArray(jsonResponse)) {
                    return Promise.reject(
                        new Error(`Malformed addon registry response: Cannot convert to AddOnDescriptor array`)
                    );
                }
                const registryAddOnDescriptors: TpzAddOnDescriptor[] = jsonResponse;
                // register all add ons (do not fire an event each time)
                registryAddOnDescriptors.forEach((desc: TpzAddOnDescriptor) => {
                    me.storeAddOnDescriptor(desc, false);
                });
                // fire event once all are registered
                this.fireEvent(TpzApplicationEventType.ADDONS_REGISTRY_UPDATED, null);
                return null;
            })
            .catch((error: Error) => {
                this.getLogger().error(`failed updating addon registry @${registryURL}`, error);
                throw error;
            });
    }

    /**
     * Action to perform when an application event is received
     * @param event application event
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            // Registries Update is already done in TpzApplication.preStart()
            // case TpzApplicationEventType.APPLICATION_STARTED:
            //     this.updateAddOnsFromRegistries();
            //     break;
            case TpzApplicationEventType.APPLICATION_STOPPING:
                TpzApplicationEventType.checkEvent(event, null);
                this.getApplication().getEventManager().unregister(this.getId());
                break;
        }
        return super.onApplicationEvent(event);
    }
}
