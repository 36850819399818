/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { SimulatedTimeDescriptor, SimulatedTimeEventType, SimulatedTimeHelper } from '../../../time/simulated-time';
import { TpzApplication } from '../../../tpz-application-core';
import { TimeHandler } from '../../../time/time-handler';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { deepCopy } from '../../../tools/deep-copy';

/******************************************* DISPLAYER *******************************/

// item type (used in default configuration and Item Instance constructor)
const CLOCK_VIEW_DIGITAL_TYPE: string = 'ClockViewDigitalType';

/**
 * Displayer Configuration
 */
export interface ClockViewDigitalConfig extends TpzViewConfig {
    timezone?: number; // timezone in hours
    location?: string;
    timeUpdateRate?: number; // time runner update rate (in milliseconds)
}

/**
 * Default Displayer Configuration
 */
export const defaultClockViewDigitalConfig: ClockViewDigitalConfig = Object.freeze({
    ...defaultTpzViewConfig,
    type: CLOCK_VIEW_DIGITAL_TYPE,
    css: ['css/digital-clock.css'].concat(defaultTpzViewConfig.css),
    timezone: 1,
    location: 'paris', // time location name (Paris, Moscow, ...)
    timeUpdateRate: 1000
});

/**
 * Clock Displayer displays digital time
 */
export class ClockViewDigital extends TpzView {
    static readonly CLOCK_VIEW_DIGITAL_TYPE: string = CLOCK_VIEW_DIGITAL_TYPE;
    static readonly DEFAULT_TIMERUNNER_UPDATE_RATE: number = 1000; // time runner update rate in milliseconds

    private readonly timeHandler: TimeHandler = null; // time runner synchronized with time manager

    private timeSpan: HTMLSpanElement = null;
    private dateSpan: HTMLSpanElement = null;
    private locationSpan: HTMLSpanElement = null;
    private clockDiv: HTMLDivElement = null;

    /** Constructor */
    constructor(config: ClockViewDigitalConfig, application: TpzApplication) {
        super(deepCopy(defaultClockViewDigitalConfig, config), ClockViewDigital.CLOCK_VIEW_DIGITAL_TYPE, application);
        this.timeHandler = new TimeHandler(this, this.onTimeHandlerChanged.bind(this), this.getConfig().timeUpdateRate);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): ClockViewDigitalConfig {
        return super.getConfig() as ClockViewDigitalConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: ClockViewDigitalConfig = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.timezone !== newConfig?.timezone) {
            this.invalidateUI();
            changes = true;
        }
        if (config?.location !== newConfig?.location) {
            this.invalidateUI();
            changes = true;
        }
        if (config?.timeUpdateRate !== newConfig?.timeUpdateRate) {
            this.setTimeUpdateRate(newConfig?.timeUpdateRate);
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }

    /**
     * Create Clock UI in view container Div
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!this.clockDiv) {
            this.clockDiv = document.createElement('div');
            this.clockDiv.classList.add('digital-clock');
            parent.appendChild(this.clockDiv);
            if (!this.timeSpan) {
                this.timeSpan = document.createElement('span');
                this.timeSpan.classList.add('clock-time');
                this.clockDiv.appendChild(this.timeSpan);
            }

            if (!this.dateSpan) {
                this.dateSpan = document.createElement('span');
                this.dateSpan.classList.add('clock-date');
                this.clockDiv.appendChild(this.dateSpan);
            }
            if (!this.locationSpan) {
                this.locationSpan = document.createElement('span');
                this.locationSpan.classList.add('clock-location');
                this.clockDiv.appendChild(this.locationSpan);
            }
        }
        this.displayClock();
        return true;
    }

    /** Remove Graphics components */
    public invalidateUI(): void {
        this.timeSpan = null;
        this.dateSpan = null;
        this.locationSpan = null;
        this.clockDiv = null;
        super.invalidateUI();
    }

    /**
     * Set the timerHandler update rate
     * @param updateRate time rate Time Handler self update (in milliseconds)
     */
    public setTimeUpdateRate(updateRate: number): void {
        this.timeHandler?.setTimeUpdateRate(updateRate);
    }

    /**
     * Format a date as Day/Month/Year
     */
    private static toStringDate(date: Date): string {
        if (!date) return 'null';
        // months start at 0 ! (0=jan, 11 = dec)
        return (
            ClockViewDigital.strpad(date.getUTCDate(), 2) +
            '/' +
            ClockViewDigital.strpad(date.getUTCMonth() + 1, 2) +
            '/' +
            ClockViewDigital.strpad(date.getFullYear() % 100, 2)
        );
    }

    /**
     * Format a time as HH:MM:SS
     */
    private static toStringTime(date: Date): string {
        if (!date) return 'null';
        return (
            ClockViewDigital.strpad(date.getUTCHours(), 2) +
            ':' +
            ClockViewDigital.strpad(date.getUTCMinutes(), 2) +
            ':' +
            ClockViewDigital.strpad(date.getUTCSeconds(), 2)
        );
    }

    /**
     * Convert an integer number to string.
     * Fill with 0s to match given length (in characters count)
     */
    private static strpad(number: number, length: number): string {
        let str = '' + number;
        while (str.length < length) str = '0' + str;
        return str;
    }

    /** format date as string ( Day/Month/Year ) */
    private formatDate(date: Date): string {
        return ClockViewDigital.toStringDate(date);
    }

    /** format time as string ( HH:MM:SS ) */
    private formatTime(date: Date): string {
        return ClockViewDigital.toStringTime(date);
    }

    /**
     * Display the clock content in HTML mainContainer
     */
    private displayClock(time: number = null): boolean {
        if (!this.getViewContainer()) return false;
        if (!time) {
            const simulatedTimeDescriptor: SimulatedTimeDescriptor = this.getTimeDescriptor();
            if (!simulatedTimeDescriptor) return false;
            time = SimulatedTimeHelper.getSimulatedTime(simulatedTimeDescriptor);
        }
        const date: Date = new Date(time);

        // add timezone if set
        const tz: number = this.getTimeZone();
        if (Number.isFinite(tz)) {
            date.setTime(date.getTime() + tz * 3600 * 1000);
        }
        // display time
        if (this.timeSpan) {
            this.timeSpan.innerText = this.formatTime(date);
        }
        // display date
        if (this.dateSpan) {
            this.dateSpan.innerText = this.formatDate(date);
        }
        // display location
        if (this.locationSpan && Number.isFinite(tz)) {
            this.locationSpan.innerText =
                this.getTimeLocation() + ' (UTC ' + (tz > 0 ? '+' : '') + ClockViewDigital.strpad(tz, 2) + ')';
        }
        return true;
    }

    /**
     * get time location (from config)
     * @returns
     */
    public getTimeLocation(): string {
        return this.getConfig()?.location;
    }

    /**
     * get time zone (from config)
     * @returns
     */
    public getTimeZone(): number {
        return this.getConfig()?.timezone;
    }

    /**
     * get time descriptor (from timeHandler)
     * @returns
     */
    public getTimeDescriptor(): SimulatedTimeDescriptor {
        if (!this.timeHandler) return null;
        return this.timeHandler.getTimeDescriptor();
    }

    /**
     * Request do nothing for clocks. Launch an event to request for new Time Descriptor
     * If a TimeManager plugin exists, the response will be received by event
     */
    public requestUpdate(force: boolean = false): boolean {
        this.getApplication().fireEvent(this.getId(), SimulatedTimeEventType.REQUEST_TIME_DESCRIPTOR, null, []);
        return true;
    }

    /**
     * Update UI
     */
    public updateUI(): void {
        this.displayClock();
        super.updateUI();
    }

    /**
     * React to time handler events.
     */
    public onTimeHandlerChanged(time: number, live: boolean): void {
        if (live) this.displayClock(time);
        else this.displayClock();
    }
}
