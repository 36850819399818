/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoggerMessage } from './tpz-log-core';

/**
 * Message Filtering class
 */
export abstract class LoggerMessageFilter {
    /**
     * Return true if message is accepted
     * @param message message to filter
     */
    public abstract filter(message: LoggerMessage): boolean;
}

/**
 * Message Filter using a level range
 */
export class LevelRangeMessageFilter extends LoggerMessageFilter {
    private readonly minAcceptedLevel: number;
    private readonly maxAcceptedLevel: number;

    /**
     * Constructor
     * @param minAcceptedLevel minimum level above which message is accepted (inclusive)
     * @param maxAcceptedLevel maximum level under which message is accepted (inclusive)
     */
    constructor(minAcceptedLevel: number = null, maxAcceptedLevel: number = null) {
        super();
        this.minAcceptedLevel = minAcceptedLevel;
        this.maxAcceptedLevel = maxAcceptedLevel;
    }

    /**
     * Filtering method
     * @param message message to filter
     */
    public filter(message: LoggerMessage): boolean {
        if (!message) return false;
        if (this.minAcceptedLevel !== null && message.level < this.minAcceptedLevel) return false;
        if (this.maxAcceptedLevel !== null && message.level > this.maxAcceptedLevel) return false;
        return true;
    }
}

/**
 * Message Filter using a level maximum
 */
export class LevelMaxMessageFilter extends LoggerMessageFilter {
    private readonly maxAcceptedLevel: number;

    /**
     * Constructor
     * @param maxAcceptedLevel maximum level under which message is accepted (inclusive)
     */
    constructor(maxAcceptedLevel: number = null) {
        super();
        this.maxAcceptedLevel = maxAcceptedLevel;
    }

    /**
     * Filtering method
     * @param message message to filter
     */
    public filter(message: LoggerMessage): boolean {
        if (!message) return false;
        if (this.maxAcceptedLevel !== null && message.level > this.maxAcceptedLevel) return false;
        return true;
    }
}
