/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AccessorEvent } from '../../../../tpz-access/tpz-access-event';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { TpzLibraryDescriptor } from '../../../libraries/library-descriptor';
import { TimeEventContent } from '../../../plugins/instances/time-manager/plugin-time-manager';
import { SimulatedTimeDescriptor, SimulatedTimeEventType, SimulatedTimeHelper } from '../../../time/simulated-time';
import { TpzApplication } from '../../../tpz-application-core';
import { deepCopy } from '../../../tools/deep-copy';
import { ValueAccessor } from '../../../../tpz-access/tpz-access-value';

declare let tail: any;

// Openlayers library Id
const DATETIME_LIBRARY_ID = 'tail.DateTime-0.4.13';
// Openlayers library file to be loaded
const DATETIME_LIBRARY_FILE = 'libs/tail.DateTime-0.4.13/js/tail.datetime-full.js';

// item type (used in default configuration and Item Instance constructor)
const DATETIME_VIEW_TYPE: string = 'DateTimeViewType';

// css file
const DATETIME_CSS_FILE: string = 'css/tail.datetime-harx-dark.css';

/****************** DISPLAYER **********************/

/**
 * Specific parameters for this module creation
 */
export type DateTimeViewConfig = TpzViewConfig;
/**
 * default values for DateTime view configuration
 */
export const defaultDateTimeViewConfig: DateTimeViewConfig = {
    ...defaultTpzViewConfig,
    type: DATETIME_VIEW_TYPE,
    libraryIds: [DATETIME_LIBRARY_ID].concat(defaultTpzViewConfig.libraryIds)
};

/**
 * View
 */
export class DateTimeView extends TpzView {
    public static readonly DATETIME_VIEW_TYPE = DATETIME_VIEW_TYPE;
    public static readonly DATE_EVENT_CATEGORY = 'TIME';
    // HTML elements
    private mainContainer: HTMLDivElement = null;
    private readonly ulElement: HTMLUListElement = null;
    private timeDescriptor: SimulatedTimeDescriptor = null; // edited time descriptor

    /**
     * constrcutor
     * @param config
     */
    constructor(config: DateTimeViewConfig, application: TpzApplication) {
        super(deepCopy(defaultDateTimeViewConfig, config), DateTimeView.DATETIME_VIEW_TYPE, application);
        // this.start();
        // register date-time library
        const dateTimeLibraryDescriptor: TpzLibraryDescriptor = {
            id: DATETIME_LIBRARY_ID,
            resources: {
                js: [DATETIME_LIBRARY_FILE],
                css: [DATETIME_CSS_FILE]
            }
        };
        this.getApplication().getLibraryManager().registerLibrary(dateTimeLibraryDescriptor);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): DateTimeViewConfig {
        return super.getConfig() as DateTimeViewConfig;
    }

    /**
     * Callback called when an accessor triggers
     * @param event
     */
    public onAccessorChange(event: AccessorEvent): boolean {
        this.getAccessor().then((accessor: ValueAccessor<any>) => {
            this.setNewFPDate(accessor.getValue());
        });
        return true;
    }

    /**
     * Accessor on UI.
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Accessor on mainContainer
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.innerHTML = "<input type='text' class='tail-datetime-field' />";
            this.getApplication()
                .getLibraryManager()
                .getLibrary(DATETIME_LIBRARY_ID)
                .then(() => {
                    tail.DateTime('.tail-datetime-field', {
                        animate: true, // [0.4.0]          Boolean
                        classNames: false, // [0.3.0]          Boolean, String, Array, null
                        closeButton: true, // [0.4.5]          Boolean
                        dateFormat: 'YYYY-mm-dd', // [0.1.0]          String (PHP similar Date)
                        dateStart: false, // [0.4.0]          String, Date, Integer, False
                        dateRanges: [], // [0.3.0]          Array
                        dateBlacklist: true, // [0.4.0]          Boolean
                        dateEnd: false, // [0.4.0]          String, Date, Integer, False
                        locale: 'en', // [0.4.0]          String
                        position: 'bottom', // [0.1.0]          String
                        rtl: 'auto', // [0.4.1]          String, Boolean
                        startOpen: false, // [0.3.0]          Boolean
                        stayOpen: false, // [0.3.0]          Boolean
                        time12h: false, // [0.4.13][NEW]    Boolean
                        timeFormat: 'HH:ii:ss', // [0.1.0]          String (PHP similar Date)
                        timeHours: true, // [0.4.13][UPD]    Integer, Boolean, null
                        timeMinutes: true, // [0.4.13][UPD]    Integer, Boolean, null
                        timeSeconds: 0, // [0.4.13][UPD]    Integer, Boolean, null
                        timeIncrement: true, // [0.4.5]          Boolean
                        timeStepHours: 1, // [0.4.3]          Integer
                        timeStepMinutes: 5, // [0.4.3]          Integer
                        timeStepSeconds: 5, // [0.4.3]          Integer
                        today: true, // [0.4.0]          Boolean
                        tooltips: [], // [0.4.0]          Array
                        viewDefault: 'days', // [0.4.0]          String
                        viewDecades: true, // [0.4.0]          Boolean
                        viewYears: true, // [0.4.0]          Boolean
                        viewMonths: true, // [0.4.0]          Boolean
                        viewDays: true, // [0.4.0]          Boolean
                        weekStart: 0 // [0.1.0]          String, Integer
                    });
                });
        }
        return this.mainContainer;
    }

    /**
     * Cancel UI.
     */
    public invalidateUI(): void {
        super.invalidateUI();
        if (this.mainContainer && this.mainContainer.parentNode) {
            this.mainContainer.parentNode.removeChild(this.mainContainer);
        }
        this.mainContainer = null;
    }

    /**
     *
     * @param parent
     */
    public createUI(parent: HTMLDivElement): boolean {
        const me: DateTimeView = this;
        if (!parent) return false;

        parent.appendChild(this.getMainContainer());
        return true;
    }

    /**
     * Fires the time description related to current stored time
     */
    private fireTimeDescriptionEvent(): boolean {
        if (!this.getTimeDescriptor()) return false;
        const timeEvent: TimeEventContent = {
            timeDescriptor: this.getTimeDescriptor()
        };
        this.getApplication()
            .getLogger()
            .info('envoi: ' + JSON.stringify(timeEvent, null, 2));
        this.getApplication().fireEvent(this.getId(), SimulatedTimeEventType.REQUEST_REBASE_SIMULATED_TIME, timeEvent, [
            DateTimeView.DATE_EVENT_CATEGORY
        ]);
        return true;
    }

    /**
     * Get time descriptor
     */
    private getTimeDescriptor(): SimulatedTimeDescriptor {
        if (!this.timeDescriptor) {
            this.timeDescriptor = SimulatedTimeHelper.createNowTimeDescriptor(null);
        }
        return this.timeDescriptor;
    }

    /**
     * Set time descriptor
     */
    private setNewFPDate(timestamp: number) {
        if (timestamp != null) {
            return true;
        }
        return false;
    }

    public updateUI(): boolean {
        return true;
    }

    public getDateTimeId(): string {
        return this.getId();
    }

    /**
     * A request update is triggered.
     *
     * @param force: true to force updating
     */
    public requestUpdate(force: boolean = false): boolean {
        return true;
    }
}
