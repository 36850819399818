/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { ItemCatalog } from '../../tpz-catalog/tpz-catalog-core';
import { ItemConfig } from '../../tpz-catalog/tpz-item-config';
import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { TpzDesktop } from '../desktop/tpz-desktop-core';
import { TpzDesktopConfig } from '../desktop/tpz-desktop-config';
import { TpzApplicationCommander } from '../tpz-application-commander';
import { TpzApplicationComponent } from '../tpz-application-component';
import { TpzApplicationEvent, TpzApplicationEventType } from '../tpz-application-event';
import { uuidv4 } from '../tools/uuid';
import {
    defaultDesktopContainerHSplitConfig,
    DesktopContainerFactory,
    DesktopContainerItem,
    DesktopContainerItemConfig
} from './tpz-desktop-container-item';
import { TpzApplicationCategories, TpzApplicationTypes } from '../tpz-application-types';

/**
 * State of the desktop manager
 * It only contains how to create the desktopContainerItem
 */
export interface TpzDesktopManagerState {
    desktopContainerItemConfig: DesktopContainerItemConfig;
    activeDesktopId: string;
}

/**
 * Default state value if none is given
 */
export const defaultTpzDesktopManagerState: TpzDesktopManagerState = {
    desktopContainerItemConfig: defaultDesktopContainerHSplitConfig,
    activeDesktopId: null
};
/**
 * Desktop Manager contains a set of desktops and manages
 * - current Desktop
 * - displayed desktops
 * It contains cached values to optimize data access but the desktop manager state
 * is fully described by its 'state' field which contains
 */
export class TpzDesktopManager extends TpzApplicationComponent {
    public static readonly LOGGER_ID: string = 'desktop-logger';
    public static readonly LOGGER_MAX_ERROR: number = 200;

    private state: TpzDesktopManagerState = defaultTpzDesktopManagerState;
    private defaultDesktopConfig: TpzDesktopConfig = null;
    // private defaultDesktop: TpzDesktop = null;
    private readonly defaultDesktopId: string = 'default-desktop';
    private readonly defaultDesktopName: string = 'Default desktop';

    private desktopContainer: DesktopContainerItem = null;

    /** Constructor */
    constructor(application: TpzApplication, id: string) {
        super(application, id);
    }

    /**
     * Register the default desktop into the item catalog
     */
    private registerDefaultDesktop(): void {
        // register DesktopContainerFactory in case of none is user set and default is used
        // default uses DesktopContainerItemHSplit which is handled by DesktopContainerFactory
        this.getApplication()
            .getItemCatalog()
            .getFactoryManager()
            .registerItemFactory(new DesktopContainerFactory(this.getApplication()));
        TpzApplicationCommander.registerItem(this.getApplication(), this.getDefaultDesktopConfig());
    }

    /**
     * get the default desktop configuration
     */
    private getDefaultDesktopConfig(): TpzDesktopConfig {
        if (!this.defaultDesktopConfig) {
            this.defaultDesktopConfig = {
                id: this.getDefaultDesktopId(),
                type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
                name: this.getDefaultDesktopName(),
                childrenIds: []
            };
        }
        return this.defaultDesktopConfig;
    }

    /**
     * start desktop manager by adding the desktop container
     */
    public start(): Promise<void> {
        this.registerDefaultDesktop();
        return this.createDesktopContainerItem()
            .then(() => super.start())
            .then(() => this.getDesktopContainerItem().start())
            .then(() => this.getDesktopContainerItem().doPlugInParent(null));
    }

    /**
     * stop desktop manager by removing the desktop container
     */
    public stop(): Promise<void> {
        return super
            .stop()
            .then(() => this.getDesktopContainerItem().doUnplugFromParent(null))
            .then(() => this.getDesktopContainerItem().stop());
    }

    /**
     * get the embedded desktop container item
     * The item is not created using the factory creation system, but directly with a new
     * @returns DesktopContainerItem, but can be null if not yet started
     */
    private getDesktopContainerItem(): DesktopContainerItem {
        return this.desktopContainer;
    }

    /**
     * Get default desktop container configuration (HSplit)
     */
    private getDefaultDesktopContainerConfig(): DesktopContainerItemConfig {
        return {
            id: `default-desktop-container-item`,
            type: TpzApplicationTypes.DESKTOP_CONTAINER_HSPLIT_TYPE
        };
    }
    /**
     * get the embedded desktop container ID
     */
    public getDesktopContainerItemId(): string {
        if (this.desktopContainer) {
            return this.desktopContainer.getId();
        }
        return this.getState()?.desktopContainerItemConfig?.id;
    }

    /**
     * Register desktop container Item in Catalog
     */
    private registerDekstopContainerItem(): void {
        if (this.desktopContainer) {
            this.getApplication().getItemCatalog().registerExistingInstance(this.desktopContainer);
        }
    }
    /**
     * create the desktop container Item instance
     * @returns desktop container Item instance
     */
    private createDesktopContainerItem(): Promise<DesktopContainerItem> {
        if (this.desktopContainer) {
            return Promise.resolve(this.desktopContainer);
        }
        let desktopContainerConfig: DesktopContainerItemConfig = this.getState().desktopContainerItemConfig;
        if (!desktopContainerConfig) desktopContainerConfig = this.getDefaultDesktopContainerConfig();
        // create an ID if not defined
        if (!desktopContainerConfig.id) {
            desktopContainerConfig.id = `desktop-container-${uuidv4()}`;
        }
        // check that DesktopContainer Type is defined
        if (!desktopContainerConfig.type) {
            desktopContainerConfig.type = TpzApplicationTypes.DESKTOP_CONTAINER_HSPLIT_TYPE;
            this.getLogger().warn(
                `DesktopContainerItem described in DektopManagerState does not contain its type. Force ${desktopContainerConfig.type} but this may not be intended...`
            );
        }
        // Try to create the desktop container Item using user defined config
        // if an error occurs, then use default configuration
        return this.getApplication()
            .getItemCatalog()
            .getOrCreateInstanceByConfig(desktopContainerConfig)
            .then((item: ItemInstance) => {
                // check if item is a desktopContainer
                if (!item) {
                    throw new Error(
                        `Unable to create Desktop Container Item #${desktopContainerConfig.id} type ${desktopContainerConfig.type}`
                    );
                }
                if (!item.containsCategory(TpzApplicationCategories.TPZ_DESKTOP_CONTAINER_CATEGORY)) {
                    throw new Error(
                        `Referenced Desktop Container Item #${
                            desktopContainerConfig.id
                        } is not a Desktop Container ! Categories = (${item.getCategories().join(', ')})`
                    );
                }
                this.desktopContainer = item as DesktopContainerItem;
                this.registerDekstopContainerItem();
                return this.desktopContainer;
            })
            .catch((reason: Error) => {
                // if an error occurs, try to create the desktop container with the default configuration
                return this.getApplication()
                    .getItemCatalog()
                    .getOrCreateInstanceByConfig(this.getDefaultDesktopContainerConfig());
            })
            .then((item: ItemInstance) => {
                // check if item is a desktopContainer
                if (!item) {
                    throw new Error(
                        `Unable to create Desktop Container Item (DEFAULT CONFIGURATION) #${desktopContainerConfig.id} type ${desktopContainerConfig.type}`
                    );
                }
                if (!item.containsCategory(TpzApplicationCategories.TPZ_DESKTOP_CONTAINER_CATEGORY)) {
                    throw new Error(
                        `Referenced Desktop Container Item (DEFAULT CONFIGURATION) #${
                            desktopContainerConfig.id
                        } is not a Desktop Container ! Categories = (${item.getCategories().join(', ')})`
                    );
                }
                this.desktopContainer = item as DesktopContainerItem;
                this.registerDekstopContainerItem();
                return this.desktopContainer;
            });
        // at this point, do not handle exception, we cannot create neither user defined nor default desktop container => Error
    }

    /**
     * clear desktop manager state
     */
    private clear(): void {
        // BUG: unregister does not exist !!!

        // if (this.desktopContainer) {
        //     this.getItemCatalog().unregister(this.getDesktopContainerItem());
        // }

        this.desktopContainer?.clear();
        // this.defaultDesktop = null;
        this.defaultDesktopConfig = null;
    }

    /**
     * Set a desktop state to replace the current one
     * @param state desktop state to replace the current one
     * @throws error if state is null
     */
    public setState(state: TpzDesktopManagerState): Promise<void> {
        if (!state) {
            throw new Error('desktop state cannot be null');
        }
        return Promise.resolve()
            .then(() => {
                this.clear();
                this.state = state;
                // add mandatory desktop container item in case the desktopContainerItemConfig has changed in state
                this.registerDekstopContainerItem();
                return this.closeAllDesktops();
            })
            .then(() => {
                this.setActiveDesktopById(state.activeDesktopId);
            });
    }

    /**
     * Change the current active desktop. There can be only one active desktop at a time
     * @param activeDesktopId new active desktop
     */
    public setActiveDesktopById(activeDesktopId: string): boolean {
        if (this.state?.activeDesktopId === activeDesktopId) {
            return false;
        }
        // deactivate previous active desktop
        if (this.state?.activeDesktopId) {
            this.getDesktopContainerItem()
                .getDesktopById(this.state.activeDesktopId)
                .then((oldActivatedDesktop: TpzDesktop) => {
                    oldActivatedDesktop.deactivate();
                });
        }
        // activate previous active desktop
        if (activeDesktopId) {
            this.getDesktopContainerItem()
                .getDesktopById(activeDesktopId)
                .then((newActivatedDesktop: TpzDesktop) => {
                    newActivatedDesktop.activate();
                })
                .then(() => {
                    this.state.activeDesktopId = activeDesktopId;
                    this.fireEvent(
                        TpzApplicationEventType.DESKTOP_ACTIVATED,
                        {
                            activatedDesktopId: activeDesktopId,
                            deactivatedDesktopId: this.state?.activeDesktopId ? this.state?.activeDesktopId : null
                        },
                        []
                    );
                });
        }
        return true;
    }

    // /**
    //  * Apply new configuration
    //  * @param newConfig configuration to be applied
    //  */
    // public applyConfig(newConfig: DesktopContainerItemHSplitConfig): boolean {
    //     let changes = false;
    //     const config: DesktopContainerItemHSplitConfig = this.getConfig();
    //     if (newConfig.activeDesktopId !== config.activeDesktopId) {
    //         this.activateDesktop(newConfig.activeDesktopId);
    //         this.fireEvent(
    //             TpzApplicationEventType.DESKTOP_ACTIVATED,
    //             { activatedDesktopId: desktopId, deactivatedDesktopId: activeDesktop?.getId() },
    //             []
    //         );
    //         changes = true;
    //     }

    //     return super.applyConfig(newConfig) && changes;
    // }

    /**
     * get the current desktop state. Create an empty one if null
     */
    public getState(): TpzDesktopManagerState {
        return this.state;
    }

    /**
     * get the active desktop id
     */
    public getActiveDesktopId(): string {
        return this.state?.activeDesktopId;
    }
    /**
     * get the active desktop id
     */
    public getActiveDesktop(): TpzDesktop {
        return this.getDesktopContainerItem()?.getManagedChildById(this.getActiveDesktopId()) as TpzDesktop;
    }

    /**
     * get a displayed desktop
     * @param arg0
     */
    public getDesktopById(desktopId: string): Promise<TpzDesktop> {
        return this.getDesktopContainerItem().getDesktopById(desktopId);
    }

    /**
     * Default desktop id getter
     */
    public getDefaultDesktopId(): string {
        return this.defaultDesktopId;
    }

    /**
     * Default desktop name getter
     */
    public getDefaultDesktopName(): string {
        return this.defaultDesktopName;
    }

    // /**
    //  * get the default desktop. it is used to display windows when no desktop is active
    //  * or when the application needs to display something on top of all other desktops.
    //  * It is managed besides user desktops
    //  */
    // public getDefaultDesktop(): Promise<TpzDesktop> {
    //     if (this.defaultDesktop) return Promise.resolve(this.defaultDesktop);
    //     return this.getDesktopById(this.getDefaultDesktopId()).then((desktop: TpzDesktop) => {
    //         this.defaultDesktop = desktop;
    //         return desktop;
    //     });
    // }

    /**
     * get the collection of registered desktops configurations
     */
    public getRegisteredDesktopConfigs(): TpzDesktopConfig[] {
        return Object.values(this.getItemCatalog().getRegisteredConfigs())
            .filter((itemConfig: ItemConfig) => itemConfig.type == TpzApplicationTypes.TPZ_DESKTOP_TYPE)
            .map((x) => x as TpzDesktopConfig);
    }

    /** get all desktops Ids */
    public getRegisteredDesktopIds(): string[] {
        return this.getRegisteredDesktopConfigs().map((config: TpzDesktopConfig) => config.id);
    }

    /**
     * get a registered desktop configuration using its ID
     * @param desktopId desktop ID to retrieve configuration
     * @returns the registered configuration or null
     */
    public getRegisteredDesktopConfigById(desktopId: string): TpzDesktopConfig {
        if (!desktopId) return null;
        const itemConfig: ItemConfig = this.getItemCatalog().getRegisteredConfigById(desktopId);
        if (!itemConfig) return null;
        if (itemConfig.type != TpzApplicationTypes.TPZ_DESKTOP_TYPE) {
            throw new Error(`The requested desktop #${desktopId} is not a desktop object ! type = ${itemConfig.type}`);
        }
        return itemConfig as TpzDesktopConfig;
    }

    // /**
    //  * Get all displayed Desktops
    //  */
    // public getDisplayedDesktops(): { [id: string]: TpzDesktop } {
    //     if (!this.this.getDisplayedDesktopIds()) return {};
    //     let displayedDesktops: { [id: string]: TpzDesktop } = {};
    //     this.getDisplayedDesktopIds().forEach((desktopId: string) => displayedDesktops[desktopId] = this.getDesktopById(desktopId));
    //     return displayedDesktops;
    // }

    /**
     * Get all displayed Desktops. Displayed desktops are managed children of the ContainerDesktopItem
     */
    public getDisplayedDesktopIds(): string[] {
        return this.getDesktopContainerItem()
            .getManagedChildren()
            .map((item: ItemInstance) => item.getId());
    }

    /**
     * Remove a desktop from the list of displayed desktops (internal method)
     * @param desktopId desktop ID to be removed from displayed desktop
     * @returns true if removed. false elsewhere
     */
    private removeDisplayedDesktop(desktopId2Remove: string): boolean {
        if (!desktopId2Remove) {
            return false;
        }
        TpzApplicationCommander.removeChildItemById(
            this.getApplication(),
            desktopId2Remove,
            this.getDesktopContainerItemId()
        );
        return true;
    }

    /**
     * add a desktop to the list of displayed desktops
     * @param desktop desktop to be added to displayed desktop
     * @param activate activate added desktop if true. Do nothing if false
     * @returns true if added. false elsewhere
     */
    private addDisplayedDesktop(desktopId: string, activate: boolean = true): boolean {
        if (!desktopId) {
            return false;
        }
        TpzApplicationCommander.addChildItemById(this.getApplication(), desktopId, this.getDesktopContainerItemId());
        if (activate) {
            TpzApplicationCommander.activateDesktop(this.getApplication(), desktopId);
        }
        return true;
    }

    // /**
    //  * Reopen all desktops from current state
    //  */
    // public openApplicationDesktops(): void {
    //     // close all desktops
    //     this.closeAllDesktops();
    //     // open desktops from isplayedDesktopIds
    //     this.this.getDisplayedDesktopIds()?.forEach((desktopId: string) => this.addDesktop(desktopId));
    //     // set active desktop from state.activeDesktopId
    //     this.setActiveDesktopById(this.getState().activeDesktopId);
    // }

    /**
     * Remove one desktop
     */
    private closeDesktop(desktopId: string): void {
        if (!desktopId) {
            return;
        }
        TpzApplicationCommander.removeChildItemById(this.getApplication(), this.getDesktopContainerItemId(), desktopId);
    }

    /**
     * Remove all desktops by sending a "close desktop" to all displayed desktops
     */
    private closeAllDesktops(): void {
        TpzApplicationCommander.setChildrenItemByIds(this.getApplication(), this.getDesktopContainerItemId(), []);
    }

    /**
     * Display the given Desktop by stopping all other displayed desktops and starting the requested one
     */
    private openSingleDesktop(desktopId: string): void {
        if (!desktopId) return;
        TpzApplicationCommander.setChildrenItemByIds(this.getApplication(), this.getDesktopContainerItemId(), [
            desktopId
        ]);
    }

    /**
     * Display the requested Desktop by starting the desktop item
     */
    private addDesktop(desktopId: string): void {
        if (!desktopId) return;
        // add desktop to the desktop container
        TpzApplicationCommander.addChildItemById(this.getApplication(), this.getDesktopContainerItemId(), desktopId);
    }

    // /**
    // * create a new desktop using it's ID
    // */
    // private createDesktop(desktopId: string): void {
    //     // register desktop
    //     let desktopConfig: TpzDesktopConfig = {
    //         id: desktopId,
    //         type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
    //         name: desktopId,
    //         childrenIds: []
    //     };
    //     TpzApplicationCommander.registerItem(this.getApplication(), desktopConfig);

    // }

    /**
     * Helper method used to retrieve application itemcatalog
     */
    private getItemCatalog(): ItemCatalog {
        return this.getApplication().getItemCatalog();
    }

    /**
     * Event Handler. This method can be Overloaded but do not forget to call super.onEvent if
     * the event is not Handled in your implementation !
     *
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                break;
            case TpzApplicationEventType.DESKTOP_ADD_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'desktopId');
                    this.addDesktop(event.content.desktopId as string);
                }
                break;
            case TpzApplicationEventType.DESKTOP_SET_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'desktopId');
                    this.openSingleDesktop(event.content.desktopId as string);
                }
                break;
            case TpzApplicationEventType.DESKTOP_CLOSE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'desktopId');
                    this.closeDesktop(event.content.desktopId as string);
                }
                break;
            case TpzApplicationEventType.DESKTOP_CLOSE_ALL_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, null);
                    if (event.content) throw new Error('DESKTOP_STOP_ALL_REQUEST should not have any content');
                    this.closeAllDesktops();
                }
                break;
            case TpzApplicationEventType.WINDOW_RESIZED:
                {
                    TpzApplicationEventType.checkEvent(event, null);
                    this.getDesktopContainerItem().updateDesktopLayout();
                }
                break;
            case TpzApplicationEventType.DESKTOP_ACTIVATE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'desktopId');
                    this.setActiveDesktopById(event.content.desktopId);
                }
                break;
            // case TpzApplicationEventType.DESKTOP_UNDISPLAYED:
            //     {
            //         TpzApplicationEventType.checkEvent(event, 'desktopId');
            //         this.removeDisplayedDesktop(event.content.desktopId);
            //     }
            //     break;
            // case TpzApplicationEventType.DESKTOP_DISPLAYED:
            //     {
            //         TpzApplicationEventType.checkEvent(event, 'desktopId');
            //         this.addDisplayedDesktop(event.content.desktopId);
            //     }
            //     break;
        }
        return super.onApplicationEvent(event);
    }
}
