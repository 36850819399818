/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemFactoryManager } from '../../tpz-catalog/tpz-catalog-manager';
import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { Logger } from '../../tpz-log/tpz-log-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../tpz-application-event';
import { TpzApplicationCommander } from '../tpz-application-commander';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationUI } from '../tpz-application-ui';
import { DesktopContainerItem } from './tpz-desktop-container-item';
import { deepCopy } from '../../tpz-application/tools/deep-copy';
import { TpzApplicationCategories, TpzApplicationTypes } from '../tpz-application-types';
import { defaultTpzDesktopConfig, TpzDesktopConfig } from './tpz-desktop-config';

/**
 * Desktop class containing configs of items
 * it contains a DesktopConfig and use the application ItemCatalog to create instances
 */
export class TpzDesktop extends ItemInstance {
    public static readonly LOGGER_MAX_ERROR: number = 200;

    // private itemCatalog: ItemCatalog = null;
    private mainContainer: HTMLDivElement = null;
    private desktopHeaderDiv: HTMLDivElement = null;
    private desktopHeaderTitleSpan: HTMLSpanElement = null;
    private closeButton: HTMLDivElement = null;
    private parentContainer: DesktopContainerItem = null; // parent in which this desktop is inserted (if ever)
    public static readonly DESKTOP_CLASS: string = 'tpz-desktop';
    public static readonly SELECTED_DESKTOP_CLASS: string = 'selected-desktop';

    /** Constructor */
    constructor(config: TpzDesktopConfig, application: TpzApplication) {
        super(deepCopy(defaultTpzDesktopConfig, config), TpzApplicationTypes.TPZ_DESKTOP_TYPE, application);
        if (!this.getConfig()) throw new Error('Desktop config cannot be null in TpzDesktop Constructor');
        this.addCategory(TpzApplicationCategories.TPZ_DESKTOP_CATEGORY);
    }

    /**
     * Set desktop Name
     * @param name desktop name
     */
    public setName(name: string): void {
        const config = this.getConfig();
        config.name = name;
        TpzApplicationCommander.updateItemConfig(this.getApplication(), config);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): TpzDesktopConfig {
        return super.getConfig() as TpzDesktopConfig;
    }

    /**
     * Desktop name getter
     */
    public getName(): string {
        return this.getConfig()?.name;
    }

    /**
     * Desktop ID getter
     */
    public getId(): string {
        return this.getConfig()?.id;
    }

    /**
     * Logger getter (from application)
     */
    public getLogger(): Logger {
        return this.getApplication().getLogger();
    }

    /**
     * ContainerDiv lazy getter
     */
    public getMainContainerDiv(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({ classes: ['tpz-desktop'] });
            this.mainContainer.classList.add(TpzDesktop.DESKTOP_CLASS);
            this.mainContainer.addEventListener('click', () =>
                TpzApplicationCommander.activateDesktop(this.getApplication(), this.getId())
            );
            this.mainContainer.id = this.getId();
            this.mainContainer.appendChild(this.getDesktopHeaderDiv());
        }
        return this.mainContainer;
    }

    /**
     * Desktop Header Div lazy getter
     */
    public getDesktopHeaderDiv(): HTMLDivElement {
        if (!this.desktopHeaderDiv) {
            this.desktopHeaderDiv = TpzApplicationUI.createDiv({ classes: ['tpz-desktop-header'] });
            this.desktopHeaderDiv.appendChild(this.getCloseButton());
            this.desktopHeaderDiv.appendChild(this.getHeaderTitleSpan());
        }
        return this.desktopHeaderDiv;
    }

    /**
     * desktop close button lazy getter
     */
    public getHeaderTitleSpan(): HTMLSpanElement {
        if (!this.desktopHeaderTitleSpan) {
            this.desktopHeaderTitleSpan = TpzApplicationUI.createSpan({ classes: ['tpz-desktop-title'] });
            this.desktopHeaderTitleSpan.innerHTML = this.getName();
        }
        return this.desktopHeaderTitleSpan;
    }

    /**
     * desktop close button lazy getter
     */
    public getCloseButton(): HTMLDivElement {
        if (!this.closeButton) {
            this.closeButton = TpzApplicationUI.createDiv({ classes: ['tpz-desktop-close'] });
            this.closeButton.addEventListener('click', () => {
                TpzApplicationCommander.closeDesktop(this.getApplication(), this.getId());
            });
        }
        return this.closeButton;
    }

    /**
     * set desktop as activated. do not fire event
     */
    public activate(): void {
        this.getMainContainerDiv()?.classList.add(TpzDesktop.SELECTED_DESKTOP_CLASS);
    }

    /**
     * set desktop as non activated. do not fire event
     */
    public deactivate(): void {
        this.getMainContainerDiv()?.classList.remove(TpzDesktop.SELECTED_DESKTOP_CLASS);
    }

    /**
     * Factory Manager getter
     */
    public getFactoryManager(): ItemFactoryManager {
        return this.getApplication()?.getFactoryManager();
    }

    /**
     * Export desktop configuration
     */
    public exportConfig(): TpzDesktopConfig {
        return this.getConfig();
    }

    /**
     *
     * @returns if this desktop is currently displayed or not
     */
    public isDisplayed(): boolean {
        return this.parentContainer != null;
    }

    /**
     * undisplay a Desktop from DesktopContainerItem
     */
    public doUnplugFromParent(parent: ItemInstance): Promise<void> {
        if (!parent) return Promise.resolve();
        return Promise.resolve().then(() => {
            try {
                if (!parent.containsCategory(TpzApplicationCategories.TPZ_DESKTOP_CONTAINER_CATEGORY)) {
                    throw new Error(
                        `Desktops should only be unplugged from ${
                            TpzApplicationCategories.TPZ_DESKTOP_CONTAINER_CATEGORY
                        } elements. Desktop #${this.getId()} type = ${parent.getType()} categories = ${parent
                            .getCategories()
                            .join(', ')}`
                    );
                }
                this.parentContainer?.getUI()?.removeChild(this.getMainContainerDiv());
                this.parentContainer = null;
                this.fireApplicationEvent(TpzApplicationEventType.DESKTOP_UNDISPLAYED, { desktopId: this.getId() }, []);
            } catch (reason: any) {
                this.getLogger().warn(
                    `Desktop Id cannot be removed from parent #${parent.getId()}, it is not one of its children error = ${
                        (reason as Error).message
                    }`
                );
            }
        });
    }

    /**
     * Display a Desktop in a DesktopContainerItem.
     */
    public doPlugInParent(parent: ItemInstance): Promise<void> {
        if (!parent) throw new Error(`Desktop #${this.getId()} should be inserted in a valid desktop container`);
        return super.doPlugInParent(parent).then(() => {
            try {
                if (!parent.containsCategory(TpzApplicationCategories.TPZ_DESKTOP_CONTAINER_CATEGORY)) {
                    throw new Error(
                        `Desktops should only be plugged into ${
                            TpzApplicationCategories.TPZ_DESKTOP_CONTAINER_CATEGORY
                        } elements. Desktop #${this.getId()} type = ${parent.getType()} categories = ${parent
                            .getCategories()
                            .join(', ')}`
                    );
                }
                const desktopContainer: DesktopContainerItem = parent as DesktopContainerItem;
                // add desktop if not already added
                if (desktopContainer.getUI()?.contains(this.getMainContainerDiv())) return;
                desktopContainer.getUI()?.appendChild(this.getMainContainerDiv());
                this.parentContainer = desktopContainer;
                this.getLogger()?.debug(`Displaying desktop ${this.getId()}`);
                this.fireApplicationEvent(TpzApplicationEventType.DESKTOP_DISPLAYED, { desktopId: this.getId() }, []);
            } catch (reason: any) {
                this.getLogger().warn(
                    `Desktop Id cannot be added from parent #${parent.getId()}, error = ${(reason as Error).message}`
                );
            }
        });
    }

    /**
     * update this desktop by plugging all referenced children
     * @param force
     * @returns
     */
    public requestUpdate(force: boolean): boolean {
        this.getLogger().warn(
            `updating desktop #${this.getId()}. Referenced children: ${this.getChildrenItemIds().join(', ')}`
        );

        return true;
    }

    /**
     * Application event handler
     * @param event received event
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        // switch (event.type) {
        // }
        return super.onApplicationEvent(event);
    }
}
