/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import JSONEditor from 'jsoneditor';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { TpzDesktopHelper } from '../../../desktop/tpz-desktop-helper';
import { TpzWindowHelper } from '../../../window/tpz-window-helper';
import { TpzApplicationCommander } from '../../../tpz-application-commander';
import { TpzDesktopConfig } from '../../../desktop/tpz-desktop-config';
import { TpzWindowConfig } from '../../../window/tpz-window-config';
import { TpzWindow } from '../../../window/tpz-window-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { ItemConfig } from '../../../../tpz-catalog/tpz-item-config';
import { ItemInstance } from '../../../../tpz-catalog/tpz-item-core';
import { uuidv4 } from '../../../tools/uuid';
import { deepCopy } from '../../../tools/deep-copy';
import { TpzApplicationCategories } from '../../../tpz-application-types';
import { TpzCatalogCategories } from '../../../../tpz-catalog/tpz-catalog-types';

// item type (used in default configuration and Item Instance constructor)
const JSON_VIEW_EDITOR_TYPE: string = 'JsonEditorViewType';

/**
 * Specific parameters for this JSON editor module creation
 */
export interface ItemEditorViewConfig extends TpzViewConfig {
    itemId?: string; // Item to edit configuration
}

// default module configuration
export const defaultItemEditorViewConfig: ItemEditorViewConfig = {
    ...defaultTpzViewConfig,
    type: JSON_VIEW_EDITOR_TYPE,
    containerClasses: ['json-editor-view'].concat(defaultTpzViewConfig.containerClasses),
    itemId: null
};

/**
 * Topaz View used for displaying a JSON editor
 */
export class ItemEditorView extends TpzView {
    public static readonly JSON_VIEW_EDITOR_TYPE: string = JSON_VIEW_EDITOR_TYPE;
    //public static readonly CSS_MODULE_CLASS: string = CSS_MODULE_CLASS;

    // private mainContainer: HTMLDivElement = null;
    private editorContainer: HTMLDivElement = null;
    private buttonContainer: HTMLDivElement = null;
    private createButton: HTMLButtonElement = null;
    private cancelButton: HTMLButtonElement = null;
    private desktopListComboBox: HTMLSelectElement = null;
    private windowListComboBox: HTMLSelectElement = null;

    //REFACTOR next line
    private editor: Promise<JSONEditor> = null;
    private editedConfig: any = null;
    private static readonly NEW_DESKTOP_ENTRY: string = 'NEW_DESKTOP_ENTRY';
    private static readonly NEW_WINDOW_ENTRY: string = 'NEW_WINDOW_ENTRY';

    /**
     * Constructor
     * @param application parent application
     * @param config displayer configuration
     */
    constructor(config: ItemEditorViewConfig, application: TpzApplication) {
        super(deepCopy(defaultItemEditorViewConfig, config), ItemEditorView.JSON_VIEW_EDITOR_TYPE, application);
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): ItemEditorViewConfig {
        return super.getConfig() as ItemEditorViewConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: ItemEditorViewConfig = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.itemId !== newConfig?.itemId) {
            this.updateUI();
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }
    /**
     * get the edited config
     */
    public getEditedConfig(): any {
        return this.editedConfig;
    }

    /**
     * set the edited config
     */
    public setEditedConfig(editedConfig: any): void {
        this.getJsonEditor().then((editor: JSONEditor) => {
            this.editedConfig = editedConfig;
            editor.set(editedConfig);
        });
    }

    /**
     * Invalidate User Interface
     */
    public invalidateUI(): void {
        this.editorContainer = null;
        this.editor = null;
        super.invalidateUI();
    }

    // /**
    //  * Create the Main user interface
    //  */
    // protected getMainContainer(): HTMLDivElement {
    //     let me = this;
    //     if (!this.mainContainer) {
    //         this.mainContainer = document.createElement("div");
    //         this.mainContainer.id = this.getId();
    //         this.mainContainer.classList.add("item-editor-module");
    //         if (this.getConfig().classes) {
    //             this.getConfig().classes.forEach(cls => this.mainContainer.classList.add(cls));
    //         }
    //         // this.mainContainer.innerHTML = "<h1>" + this.getId() + "</h1><pre>" +
    //         //     JSON.stringify(this.getConfig(), null, 2) + "</pre>";
    //         this.mainContainer.appendChild(this.getEditorContainer());
    //         this.mainContainer.appendChild(this.getButtonContainer());
    //     }
    //     return this.mainContainer;
    // }

    public createUI(parentDiv: HTMLElement): boolean {
        parentDiv.appendChild(this.getEditorContainer());
        parentDiv.appendChild(this.getButtonContainer());
        this.updateUI();
        return true;
    }

    /**
     * Create the Editor container
     */
    protected getEditorContainer(): HTMLDivElement {
        if (!this.editorContainer) {
            this.editorContainer = TpzApplicationUI.createDiv({});
            this.editorContainer.classList.add('json-editor-container');
        }
        return this.editorContainer;
    }

    /**
     * Create the Button container
     */
    protected getButtonContainer(): HTMLDivElement {
        if (!this.buttonContainer) {
            this.buttonContainer = TpzApplicationUI.createDiv({});
            this.buttonContainer.appendChild(this.getCreateButton());
            this.buttonContainer.appendChild(this.getCancelButton());
            this.buttonContainer.appendChild(this.getDesktopListComboBox());
            this.buttonContainer.appendChild(this.getWindowListComboBox());
        }
        return this.buttonContainer;
    }

    /*
     * get Play Button
     */
    public getCreateButton(): HTMLButtonElement {
        if (!this.createButton) {
            this.createButton = TpzApplicationUI.createButton({ label: 'Create' });
            this.createButton.classList.add('ok-button');
            this.createButton.addEventListener('click', this.createItem.bind(this));
        }
        return this.createButton;
    }

    /*
     * get Cancel Button
     */
    public getCancelButton(): HTMLButtonElement {
        if (!this.cancelButton) {
            this.cancelButton = TpzApplicationUI.createButton({ label: 'Cancel' });
            this.cancelButton.classList.add('cancel-button');
            this.cancelButton.addEventListener('click', this.cancelItem.bind(this));
        }
        return this.cancelButton;
    }

    /*
     * get The window list combo box Button
     */
    public getWindowListComboBox(): HTMLSelectElement {
        if (!this.windowListComboBox) {
            this.windowListComboBox = TpzApplicationUI.createComboBox();
            this.updateWindowList();
        }
        return this.windowListComboBox;
    }

    /*
     * get The desktop list combo box Button
     */
    public getDesktopListComboBox(): HTMLSelectElement {
        if (!this.desktopListComboBox) {
            this.desktopListComboBox = TpzApplicationUI.createComboBox();
            this.desktopListComboBox.addEventListener('change', this.updateWindowList.bind(this));
            this.updateDesktopList();
        }
        return this.desktopListComboBox;
    }

    /**
     * Get a promise over JsonEditor object
     */
    private getJsonEditor(): Promise<JSONEditor> {
        if (!this.editor) {
            this.editor = this.getApplication()
                .createJSONEditor(this.getEditorContainer())
                .catch((reason: Error) => {
                    // do not cache falsy promise
                    this.editor = null;
                    // rethrow error
                    throw reason;
                });
        }
        return this.editor;
    }

    /**
     * Callback called when cancel button is clicked
     */
    private cancelItem(): void {
        this.closeParentWindow();
    }

    /**
     * close the window and stop item editor
     */
    private closeParentWindow(): void {
        this.getParentWindow().closeWindowAndRemoveFromDesktops();
    }

    /**
     * Set edited configuration from config.itemId or config.editedConfig
     */
    public updateUI(): void {
        let config: any = this.getEditedConfig();
        // try to fill edited config with itemId if set
        const itemId: string = this.getConfig().itemId;
        if ((!config || config.id !== itemId) && itemId) {
            const itemConfig: ItemConfig = this.getApplication().getItemCatalog().getRegisteredConfigById(itemId);
            if (!itemConfig) {
                this.getLogger().error(`[ItemEditorView] Cannot retrieve configuration for item #${itemId}`);
            } else {
                config = itemConfig;
            }
        }
        this.setEditedConfig(config);
        super.updateUI();
    }

    /**
     *
     * @returns the "type" field of edited config.
     */
    public getEditedConfigType(): string {
        if (!this.editedConfig) return 'unknown';
        return this.editedConfig.type;
    }
    /**
     * Callback called when create button is clicked
     */
    private createItem(): void {
        if (!this.desktopListComboBox) return;
        if (!this.windowListComboBox) return;
        this.getJsonEditor().then((editor: JSONEditor) => {
            try {
                // get config from JSON editor
                this.editedConfig = editor.get();

                // register item configuration
                TpzApplicationCommander.registerItem(this.getApplication(), this.editedConfig);
                const selectedDesktopId: string = this.desktopListComboBox.value;
                const selectedWindowId: string = this.windowListComboBox.value;

                ItemEditorView.plugCreatedItem(
                    this.getApplication(),
                    this.editedConfig,
                    selectedDesktopId,
                    selectedWindowId
                )
                    .then(() => {
                        this.getApplication().sendNotification(
                            'INFO',
                            `Item #${(this.editedConfig as ItemConfig).id} created`
                        );
                    })
                    .catch((reason: Error) => {
                        this.getApplication().sendNotification(
                            'ERROR',
                            `Item #${(this.editedConfig as ItemConfig).id} NOT created: ${reason.message}`
                        );
                    })
                    .finally(() => {
                        this.closeParentWindow();
                    });
            } catch (e: any) {
                this.getLogger().error(`JSON editor returns an error : ${JSON.stringify(e)}`);
            }
        });
    }

    /**
     * Get the created Item Instance and plug it in a desktop or a window depending on its type
     * @param id
     */
    private static plugCreatedItem(
        app: TpzApplication,
        itemConfig: ItemConfig,
        selectedDesktopId: string,
        selectedWindowId: string
    ): Promise<void> {
        TpzApplicationCommander.registerItem(app, itemConfig);
        return app
            .getItemCatalog()
            .getOrCreateInstanceByConfig(itemConfig)
            .then((item: ItemInstance) => {
                if (item.containsCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY)) {
                    return ItemEditorView.plugInNewWindow(app, item as TpzView, selectedDesktopId, selectedWindowId);
                }
                if (item.containsCategory(TpzApplicationCategories.TPZ_WINDOW_CATEGORY)) {
                    return ItemEditorView.plugWindowInDesktop(app, item as TpzWindow, selectedDesktopId);
                }
                if (item.containsCategory(TpzCatalogCategories.TPZ_ITEM_CATEGORY)) {
                    return ItemEditorView.plugItemInDesktop(app, item as ItemInstance, selectedDesktopId);
                }
                app.sendNotification(
                    'ERROR',
                    `Cannot handle Item type ${item.getType()} with categories ${item.getCategories().join(', ')}`
                );
                item.getLogger().error(
                    `ItemEditorView handles only ${TpzApplicationCategories.TPZ_VIEW_CATEGORY}, ${TpzApplicationCategories.TPZ_WINDOW_CATEGORY} or ${TpzCatalogCategories.TPZ_ITEM_CATEGORY} item categories`
                );
            });
    }

    /**
     * Plug the item in a new window in the selected desktop
     * @param item item to plug
     * @returns
     */
    private static plugInNewWindow(
        app: TpzApplication,
        item: TpzView,
        selectedDesktopId: string,
        selectedWindowId: string
    ): void {
        if (!item) throw new Error('Cannot plug null item');
        const itemType: string = item.getType();
        // get selected desktop by desktopId or create a new one
        if (!selectedDesktopId || selectedDesktopId === ItemEditorView.NEW_DESKTOP_ENTRY) {
            // create a new desktop
            selectedDesktopId = `desktop-${itemType}-${uuidv4()}`;
            const desktopConfig: TpzDesktopConfig = TpzDesktopHelper.createNewDesktopConfig(
                selectedDesktopId,
                `desktop-${itemType}`
            );
            // register desktop
            TpzApplicationCommander.registerItem(app, desktopConfig);
        }
        // get selected windowId or create a new one
        if (!selectedWindowId || selectedWindowId === ItemEditorView.NEW_WINDOW_ENTRY) {
            // create a new default desktop
            selectedWindowId = `window-${itemType}-${uuidv4()}`;
            const windowConfig: TpzDesktopConfig = TpzWindowHelper.createNewClassicWindowConfig(
                selectedWindowId,
                `window for ${itemType}`
            );
            windowConfig.childrenIds = [item.getId()]; // add window to desktop (automatically registered)
            TpzApplicationCommander.addChildItemByConfig(app, selectedDesktopId, windowConfig);
        }
        // open Desktop
        TpzApplicationCommander.addDesktop(app, selectedDesktopId);
    }

    /**
     * Simply start the generated instance without pluging it into a window or a desktop
     * @param item item to start
     * @returns
     */
    private static plugItemInDesktop(app: TpzApplication, item: ItemInstance, selectedDesktopId: string): void {
        if (!item) throw new Error('Cannot plug null item');
        const itemType: string = item.getType();
        // get selected desktop by desktopId or create a new one
        if (!selectedDesktopId || selectedDesktopId === ItemEditorView.NEW_DESKTOP_ENTRY) {
            // create a new desktop
            selectedDesktopId = `desktop-${itemType}-${uuidv4()}`;
            const desktopConfig: TpzDesktopConfig = TpzDesktopHelper.createNewDesktopConfig(
                selectedDesktopId,
                `desktop-${itemType}`
            );
            // register desktop
            TpzApplicationCommander.registerItem(app, desktopConfig);
        }
        TpzApplicationCommander.addChildItemById(app, selectedDesktopId, item.getId());
        // open Desktop
        TpzApplicationCommander.addDesktop(app, selectedDesktopId);
    }
    /**
     * Plug the item directly in the selected desktop
     * @param item item to plug
     * @returns
     */
    private static plugWindowInDesktop(app: TpzApplication, item: TpzWindow, selectedDesktopId: string): void {
        if (!item) throw new Error('Cannot plug null item');
        const itemType: string = item.getType();
        // get selected desktop by desktopId or create a new one
        if (!selectedDesktopId || selectedDesktopId === ItemEditorView.NEW_DESKTOP_ENTRY) {
            // create a new desktop
            selectedDesktopId = `desktop-${itemType}-${uuidv4()}`;
            const desktopConfig: TpzDesktopConfig = TpzDesktopHelper.createNewDesktopConfig(
                selectedDesktopId,
                `desktop-${itemType}`
            );
            // register desktop
            TpzApplicationCommander.registerItem(app, desktopConfig);
        }
        TpzApplicationCommander.addChildItemById(app, selectedDesktopId, item.getId());
        // open Desktop
        TpzApplicationCommander.addDesktop(app, selectedDesktopId);
    }

    /**
     * update the content of the desktop combo box
     */
    private updateDesktopList(): void {
        // desktop combo box must have been created
        if (!this.desktopListComboBox) return;
        // store current selected desktop
        let selectedDesktopId: string = this.desktopListComboBox.value;
        if (!selectedDesktopId || selectedDesktopId === '') {
            selectedDesktopId = this.getApplication().getDesktopManager().getActiveDesktopId();
        }
        //empty combo box
        this.desktopListComboBox.innerHTML = '';
        // append first element (create a new desktop)
        this.desktopListComboBox.appendChild(
            TpzApplicationUI.createComboBoxEntry('new desktop', ItemEditorView.NEW_DESKTOP_ENTRY)
        );
        // fill combo box with all desktops
        const desktops: TpzDesktopConfig[] = TpzDesktopHelper.getDesktopList(this.getApplication());
        desktops?.forEach((desktop: TpzDesktopConfig) => {
            this.desktopListComboBox.appendChild(TpzApplicationUI.createComboBoxEntry(desktop.name, desktop.id));
        });
        this.desktopListComboBox.value = selectedDesktopId;
        if (!this.desktopListComboBox.value) this.desktopListComboBox.value = ItemEditorView.NEW_DESKTOP_ENTRY;
        this.updateWindowList();
    }

    /**
     * update the content of the window combo box
     */
    private updateWindowList(): void {
        // window combo box must have been created
        if (!this.windowListComboBox) return;
        // store current selected window
        const selectedWindowValue: string = this.windowListComboBox.value;

        // empty combo box
        this.windowListComboBox.innerHTML = '';
        // append first element
        this.windowListComboBox.appendChild(
            TpzApplicationUI.createComboBoxEntry('new window', ItemEditorView.NEW_WINDOW_ENTRY)
        );

        // retrieve selected desktop
        const selectedDesktopId: string = this.desktopListComboBox.value;
        if (!selectedDesktopId || selectedDesktopId === ItemEditorView.NEW_DESKTOP_ENTRY) return;

        const selectedDesktopConfig: TpzDesktopConfig = this.getApplication()
            .getDesktopManager()
            .getRegisteredDesktopConfigById(selectedDesktopId);
        if (!selectedDesktopConfig) {
            this.getLogger().error(`selected desktop #${selectedDesktopId} has no registered configuration...`);
            return;
        }
        // fill combo box with all windows
        const windows: TpzWindowConfig[] = TpzWindowHelper.getWindowListFromDesktopConfig(
            selectedDesktopConfig,
            this.getApplication()
        );
        windows?.forEach((window: TpzWindowConfig) => {
            const text: string = window.title || window.id; // title or ID if title is not defined
            const option: HTMLOptionElement = TpzApplicationUI.createComboBoxEntry(text, window.id);
            this.windowListComboBox.appendChild(option);
        });
        // reselect if possible
        this.windowListComboBox.value = selectedWindowValue;
        if (!this.windowListComboBox.value) this.windowListComboBox.value = ItemEditorView.NEW_WINDOW_ENTRY;
    }

    /**
     * Application events callback
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event?.type) {
            case TpzApplicationEventType.DESKTOP_ACTIVATED:
                TpzApplicationEventType.checkEvent(event, 'activatedDesktopId', 'deactivatedDesktopId');
                // if (event.content.parentId === this.getApplication().getDesktopManager().getDesktopContainerItemId()) {
                this.updateDesktopList();
                // }
                break;
        }
        return super.onApplicationEvent(event);
    }
}

/**
 * Module View Factory
 * - ItemEditorViewFactory
 */
export class ItemEditorViewFactory extends TpzApplicationFactory {
    private static readonly ITEM_EDITOR_VIEW_FACTORY_TYPE: string = 'ItemEditorViewFactory';

    /**
     * Constructor
     * @param displayerCatalog displayer catalog
     */
    constructor(application: TpzApplication) {
        super(ItemEditorViewFactory.ITEM_EDITOR_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(
            ItemEditorView.JSON_VIEW_EDITOR_TYPE,
            this.createItemEditorView.bind(this),
            defaultItemEditorViewConfig
        );
    }

    /** ItemEditorModule creator function */
    private createItemEditorView(config: ItemEditorViewConfig): Promise<ItemEditorView> {
        return Promise.resolve(new ItemEditorView(config, this.getApplication()));
    }
}
export class ItemEditorViewHelper {
    /**
     * Create an item editor config used to edit another item config
     * @param app  application
     * @param editedConfig item config to be edited
     * @returns
     */
    public static createItemEditorConfig(app: TpzApplication, itemId: string): ItemEditorViewConfig {
        app.registerItemFactory(new ItemEditorViewFactory(app));
        const itemEditorConfig: ItemEditorViewConfig = {
            id: `item-editor-view-${itemId}-${uuidv4()}`, // override ID
            type: ItemEditorView.JSON_VIEW_EDITOR_TYPE,
            itemId: itemId
        };
        return itemEditorConfig;
    }

    /**
     * Create and Start an editor Window with the given Config. It contains only one view type ItemEditorView
     * This view and window is automatically started in the current desktop.
     * to set edited Configuration use editorConfig.config
     * @param app  application
     * @param editedConfig item config to be edited
     */
    public static createItemEditorWindow(app: TpzApplication, itemConfig: ItemConfig): void {
        if (!app || !itemConfig) {
            return;
        }
        if (!itemConfig.id) {
            itemConfig.id = uuidv4();
        }
        const itemId: string = itemConfig.id;
        // register configuration in catalog
        TpzApplicationCommander.registerItem(app, itemConfig);
        // create editor configuration for the editor
        const editorConfig: ItemEditorViewConfig = ItemEditorViewHelper.createItemEditorConfig(app, itemId);
        TpzApplicationCommander.displayItemNewWindowInActiveDesktopByConfig(
            app,
            editorConfig,
            true,
            `window-editor-view-${itemId}-${uuidv4()}`
        );
    }
}
