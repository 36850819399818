/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export class TpzUIHelper {
    /** add classes to element's class list */
    public static addClasses(element: HTMLElement, classes: string[]): HTMLElement {
        if (!element) return element;
        if (classes) classes.forEach((className: string) => element.classList.add(className));
        return element;
    }

    /** add attributes to element */
    public static setAttributes(element: HTMLElement, attributes: { [key: string]: string }): HTMLElement {
        if (!element) return element;
        if (attributes) for (const [key, value] of Object.entries(attributes)) element.setAttribute(key, value);
        return element;
    }

    /** create a simple LABEL element */
    public static createLabel(
        name: string,
        options: { classes?: string[]; attributes?: { [key: string]: string } } = null
    ): HTMLLabelElement {
        const label: HTMLLabelElement = document.createElement('label');
        label.innerText = name;
        if (options) {
            // add classes to class list
            TpzUIHelper.addClasses(label, options.classes);
            TpzUIHelper.setAttributes(label, options.attributes);
        }
        return label;
    }

    /** create a simple SPAN element */
    public static createSpan(
        name: string,
        options: { classes?: string[]; attributes?: { [key: string]: string } } = null
    ): HTMLSpanElement {
        const span: HTMLSpanElement = document.createElement('span');
        span.innerText = name;
        if (options) {
            // add classes to class list
            TpzUIHelper.addClasses(span, options.classes);
            TpzUIHelper.setAttributes(span, options.attributes);
        }
        return span;
    }

    /** create a simple DIV element */
    public static createDiv(
        options: { classes?: string[]; attributes?: { [key: string]: string } } = null
    ): HTMLDivElement {
        const div: HTMLDivElement = document.createElement('div');
        if (options) {
            // add classes to class list
            TpzUIHelper.addClasses(div, options.classes);
            TpzUIHelper.setAttributes(div, options.attributes);
        }
        return div;
    }

    public static createGridTable(
        nbCols: number,
        options: { classes?: string[]; attributes?: { [key: string]: string } } = null
    ): HTMLDivElement {
        const div: HTMLDivElement = document.createElement('div');
        div.style.display = 'grid';
        div.style.gridTemplateColumns = 'auto '.repeat(nbCols).trim();
        if (options) {
            // add classes to class list
            TpzUIHelper.addClasses(div, options.classes);
            TpzUIHelper.setAttributes(div, options.attributes);
        }
        return div;
    }
}
