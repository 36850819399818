/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Logger, LoggerMessage } from '../../../../tpz-log/tpz-log-core';
import { NotificationMessage } from '../../../tpz-application-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import { defaultTpzPluginConfig, TpzPlugin, TpzPluginConfig } from '../../tpz-plugin-core';
import { Notification } from './notification';
import { deepCopy } from '../../../tools/deep-copy';

const LOGGER_NOTIFIER_PLUGIN_TYPE: string = 'LoggerNotifierPluginType'; // plugin type

/**
 * One notification Parameter assoicated with a notification level
 */
export interface NotificationParameter {
    display: boolean;
    autoCloseDelay: number;
}

/**
 *  Plugin configuration
 */
export interface LoggerNotifierPluginConfig extends TpzPluginConfig {
    css?: string[]; // text elements to display
    divId?: string; // div container id
    divClasses?: string[]; // div classes
    maxNotificationCount?: number; // max number of notification open at the same time
    parentId?: string; // DOM element to add notification container
    notificationLevels?: { [level: number]: NotificationParameter };
}

/** Default Logger Plugin configuration */
export const defaultLoggerNotifierPluginConfig: LoggerNotifierPluginConfig = {
    ...defaultTpzPluginConfig,
    id: 'Logger-Notifier',
    type: LOGGER_NOTIFIER_PLUGIN_TYPE,
    divId: 'logger-notification',
    css: null,
    divClasses: ['logger-notification'],
    maxNotificationCount: 5, // max number of notification open at the same time,
    notificationLevels: {
        [Logger.DEBUG]: {
            display: false,
            autoCloseDelay: 5000
        },
        [Logger.INFO]: {
            display: false,
            autoCloseDelay: 5000
        },
        [Logger.WARN]: {
            display: true,
            autoCloseDelay: 10000
        },
        [Logger.ERROR]: {
            display: true,
            autoCloseDelay: 86400
        },
        [Logger.CRITICAL]: {
            display: true,
            autoCloseDelay: 0
        }
    }
};

/**
 * The logger notification plugin displays a temp div with the lasts logs
 */
export class LoggerNotifierPlugin extends TpzPlugin {
    public static readonly LOGGER_NOTIFIER_PLUGIN_TYPE: string = LOGGER_NOTIFIER_PLUGIN_TYPE; // plugin type
    private notificationContainer: HTMLDivElement = null;
    private notifiers: Notification[] = [];

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: LoggerNotifierPluginConfig) {
        super(deepCopy(defaultLoggerNotifierPluginConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): LoggerNotifierPluginConfig {
        return super.getConfig() as LoggerNotifierPluginConfig;
    }

    /**
     * Add a logger to listen to
     */
    public addLogger(logger: Logger): void {
        if (!logger) return;
        logger.getEventManager().register(this.getId(), this.onLoggerMessage.bind(this));
    }

    /**
     * remove a logger to listen to
     */
    public removeLogger(logger: Logger): void {
        if (!logger) return;
        logger.getEventManager().unregister(this.getId());
    }

    /**
     * display a notification
     * @param level
     * @param content
     * @param autoCloseDelay
     */
    public addNotification(level: string, content: string, autoCloseDelay: number): void {
        let notification: Notification = new Notification(this.getNotificationContainer().id);
        this.notifiers.push(notification);
        notification.open(level, content, autoCloseDelay);
        // FIXME: only one is removed (what if more than one has been added at the same time ?)
        // FIXME: when notification are automatically or user closed, they are not removed from this list
        // retrieve notification to remove
        while (this.notifiers.length >= this.getConfig().maxNotificationCount) {
            notification = this.notifiers[0];
            notification.close();
            this.notifiers = this.notifiers.slice(1);
        }
    }

    /**
     * Logger event callback
     */
    public onLoggerMessage(message: LoggerMessage): boolean {
        const display: boolean = this.getConfig().notificationLevels?.[message.level]?.display ?? true;
        if (!display) return true;
        const autoCloseTime: number = this.getConfig().notificationLevels?.[message.level]?.autoCloseDelay ?? 5000;
        this.addNotification(this.getLogger().getLevelAsString(message.level), message.content, autoCloseTime);
        return true;
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.NOTIFICATION:
                {
                    TpzApplicationEventType.checkEvent(event, 'message', 'type');
                    const notification: NotificationMessage = event.content;
                    const autoCloseTime: number =
                        this.getConfig().notificationLevels?.[Logger.INFO]?.autoCloseDelay ?? 5000;
                    this.addNotification(
                        this.getLogger().getLevelAsString(Logger.INFO),
                        notification.message,
                        autoCloseTime
                    );
                }
                break;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Action to perform when pluging plugin
     * creates the LoggerUI to listen to application logger
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then((plugin: TpzPlugin) => {
            this.addLogger(this.getLogger());
            this.insertUI();
            return plugin;
        });
    }

    /**
     * Action to perform when pluging plugin
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onUnplug().then((plugin: TpzPlugin) => {
            this.removeLogger(this.getLogger());
            this.removeUI();
            return plugin;
        });
    }

    /**
     * Remove NotificationUI from DOM
     */
    private removeUI(): void {
        if (!this.notificationContainer) return;

        const parentDiv: HTMLElement = document.getElementById(this.getConfig().parentId);
        if (!parentDiv) {
            const errorMessage: string =
                'Notification plugin ID ' +
                this.getId() +
                ' cannot remove its UI from unknown HTMLElement #' +
                this.getConfig().parentId;
            this.getLogger().error(errorMessage);
            throw new Error(errorMessage);
            return;
        }
        if (this.notificationContainer) parentDiv.removeChild(this.notificationContainer);
    }

    /**
     * Insert NotificationUI in DOM.
     * if parentId is not defined: use application main container
     */
    private insertUI(): void {
        let parentDiv: HTMLElement = document.getElementById(this.getConfig().parentId);
        if (!parentDiv) {
            parentDiv = this.getApplication().getApplicationDiv();
        }
        parentDiv.appendChild(this.getNotificationContainer());
    }

    /**
     * get main Logger Container
     */
    public getNotificationContainer(): HTMLDivElement {
        if (!this.notificationContainer) {
            this.notificationContainer = document.createElement('div');
            this.notificationContainer.id = this.getConfig().divId;
            this.getConfig().divClasses?.forEach((className) => this.notificationContainer.classList.add(className));
            this.notificationContainer.tabIndex = -1;
        }
        return this.notificationContainer;
    }
}
