/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ProgressLoaderManager } from '../tpz-application/progress-loader/progress-loader-manager';
import { TpzApplication } from '../tpz-application/tpz-application-core';
import {
    TpzApplicationEvent,
    TpzApplicationEventCategory,
    TpzApplicationEventType
} from '../tpz-application/tpz-application-event';
import { EventCallback, EventManager } from '../tpz-event/tpz-event-core';
import { BufferedAppender } from '../tpz-log/tpz-log-appender';
import { Logger, LoggerMessage } from '../tpz-log/tpz-log-core';
import { ItemCatalog, ItemEvent, ItemEventType, ItemInstanceState } from './tpz-catalog-core';
import { AccessorEvent, AccessorEventType } from '../tpz-access/tpz-access-event';
import { LoadHelper } from '../tpz-application/tools/loader';
import { LoggerHelper } from '../tpz-log/tpz-log-helper';
import { uuidv4 } from '../tpz-application/tools/uuid';
import { deepCopy, deepEquals } from '../tpz-application/tools/deep-copy';
import { AccessorCategoryType } from '../tpz-access/tpz-access-types';
import { ValueAccessor } from '../tpz-access/tpz-access-value';
import { arrayEquals, arrayNewElements, arrayOldElements } from '../tpz-application/tools/array-tools';
import { TpzApplicationCommander } from '../tpz-application/tpz-application-commander';
import { defaultItemConfig, ItemConfig } from './tpz-item-config';
import { TpzCatalogCategories } from './tpz-catalog-types';

/**
 * A ItemInstance is a generic class which can be created using Item configs
 * FIXME: Error management must be verified and tested !
 * Instance can be started/paused/stopped. State getter: this.getState()
 * Items can be categorized. Multiple category can be added to this object
 * Items fire events thru EventManager: this.getEventManager()
 * Event Helper methods:
 *     public addCallback(callback: EventCallback<ItemEvent>): boolean
 *     public removeCallback(callback: EventCallback<ItemEvent>): boolean;
 *     public fireEvent(event: ItemEvent): void;
 * A progress loader can be added (but may be null as well)
 * NOTE: do not use this.getConfig() in this file cause it creates a copy each time.
 * NOTE: No need here, config belongs to this object
 */
export abstract class ItemInstance {
    private promisedAccessors: { [id: string]: Promise<ValueAccessor<any>> } = {};
    private accessors: { [id: string]: ValueAccessor<any> } = {};

    private readonly application: TpzApplication = null; // parent application
    private categories: string[] = []; // list of categories this item belongs
    // private userConfig: ItemConfig = null; // instance configuration object to be applied (modified by user)
    private config: ItemConfig = null; // instance configuration currently in use (readonly)
    private state: ItemInstanceState = ItemInstanceState.UNKNOWN; // started state
    private eventManager: EventManager<ItemEvent> = null; // lazy getter
    private internalLogger: Logger = null; // lazy getter
    private internalLoggerAppender: BufferedAppender = null; // lazy getter
    private readonly children: { [id: string]: ItemInstance } = {}; // list of items children of this object
    private readonly parents: { [id: string]: ItemInstance } = {}; // list of parents having started this item
    // private items: { [id: string]: ItemInstance } = {}; // visible item configuration map

    /**
     * Constructor
     * @param config Item configuration
     * @param type Item unique type ID (this is not the instance ID)
     * A default configuration is intended to be \{...defaultSuperConfig, ...defaultThisConfig\} in derived classes
     */
    constructor(config: ItemConfig, type: string, app: TpzApplication) {
        if (!app) {
            throw new Error('at item instance creation, application must be defined');
        }
        if (!type) {
            throw new Error('at item instance creation, type must be defined');
        }
        if (!config) {
            const message = `Cannot create an item instance with a null configuration. Instance Object type = ${this.getType()}`;
            this.getLogger()?.error(message);
            throw new Error(message);
        }
        // set application
        this.application = app;
        // set configuration
        this.config = deepCopy(defaultItemConfig, config);
        if (!config.id) {
            this.config.id = uuidv4();
            this.getLogger()?.warn(
                `Item Instance is created with no configuration ID set. generate a random UUID: ${this.getType()}`
            );
        }
        this.addCategory(TpzCatalogCategories.TPZ_ITEM_CATEGORY);
        // NB: setState cannot be used before config is set. because it uses the id to fire an event...
        this.setState(ItemInstanceState.INITIALIZING);
        // check configuration type with this instance type. If they don't match, there as been a problem when choosing the item factory...
        if (config.type && config.type != type) {
            this.getLogger()?.error(
                `Create Accessor type ${type} with config type ${config.type}. Configuration error !`
            );
            this.getLogger()?.error(`There may be an invalid factory. Assume type is the Instance one: ${type}`);
        }
        this.config.type = type;
        this.setState(ItemInstanceState.INITIALIZED);
    }

    // /**
    //  * Return configuration that can be changed by user
    //  * This method should be overloaded by all derived classes in order to reflect
    //  * the object content at any moment.
    //  * One can affect the configuration getConfigSetter().XXX =
    //  * Where getConfig().XXX = is not allowed (readonly)
    //  * Once modifications are made in getConfigSetter(), method applyConfig() has to be called
    //  */
    // public getConfigSetter(): ItemConfig {
    //     if (!this.userConfig && this.stateConfig) {
    //         this.userConfig = deepCopy(this.stateConfig);
    //     }
    //     return this.userConfig;
    // }

    /**
     * Return a COPY of the item configuration.
     * This method should be overloaded by all derived classes to specialize the return type
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state.
     * @returns a readonly copy of the item state
     */
    public getConfig(): ItemConfig {
        return deepCopy(this.config);
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: ItemConfig): boolean {
        //if newConfig is not given, use this.userCOnfig
        if (!newConfig) {
            return false;
        }
        // if ID or type have changed, something bad has append
        if (this.config?.id !== newConfig?.id) {
            // changing id is not allowed, it is used for object creation, object is already created
            console.trace();
            throw new Error(
                `Item config ID Live change is not allowed. Trying to change Object ID from #${this.config?.id} to ##${newConfig?.id}`
            );
        }
        if (this.config?.type !== newConfig?.type) {
            // changing type is not allowed, it is used for object creation, object is already created
            throw new Error(
                `Item config Type Live change is not allowed. Trying to change Object ID from #${this.config?.type} to ##${newConfig?.type}`
            );
        }
        if (deepEquals(this.config, newConfig)) return false;

        // if no previous config or item is not running, just set it
        if (!this.config || !this.isRunning()) {
            this.config = { ...newConfig };
            this.getApplication().fireEvent(this.getId(), TpzApplicationEventType.INSTANCE_ITEM_CONFIG_UPDATED, {
                itemConfig: this.config
            });
            return true;
        }

        // these changes don't affect the item state
        //      - this.stateConfig.categories
        //      - this.stateConfig.maxErrorCount
        //      - this.stateConfig.logUnhandledEvents
        //      - this.stateConfig.debug
        //      - this.stateConfig.autoStart
        //      - this.stateConfig.stopParent
        // changes to handle
        if (!arrayEquals(this.config.childrenIds, newConfig.childrenIds)) {
            // plug new children
            const newChildrenIds: string[] = arrayNewElements(this.config.childrenIds, newConfig.childrenIds);
            newChildrenIds?.forEach((newChildrenId: string) => {
                this.getApplication()
                    .getItemCatalog()
                    .getOrCreateInstanceById(newChildrenId)
                    .then((item: ItemInstance) => {
                        item.plugInParent(this);
                    });
            });
            //unplug old children
            const oldChildrenIds: string[] = arrayOldElements(this.config.childrenIds, newConfig.childrenIds);
            oldChildrenIds?.forEach((oldChildrenId: string) => {
                this.getApplication()
                    .getItemCatalog()
                    .getOrCreateInstanceById(oldChildrenId)
                    .then((item: ItemInstance) => {
                        item.unplugFromParent(this);
                    });
            });
        }
        if (!arrayEquals(this.config.addOnIds, newConfig.addOnIds)) {
            const newAddOnIds: string[] = arrayNewElements(this.config.addOnIds, newConfig.addOnIds);
            newAddOnIds?.forEach((newAddOnId: string) => {
                this.getApplication()
                    .getAddOnManager()
                    .loadAddOnById(newAddOnId)
                    .catch((reason: Error) => {
                        this.getApplication().getLogger().error(`Cannot load AddOn #${newAddOnId}: ${reason?.message}`);
                    });
                const oldAddOnIds: string[] = arrayOldElements(this.config.addOnIds, newConfig.addOnIds);
                if (oldAddOnIds.length != 0) {
                    this.getApplication()
                        .getLogger()
                        .error(`Unload AddOns is not implemented yet ${oldAddOnIds.join(', ')}`);
                }
            });
        }
        if (this.config.libraryIds !== newConfig.libraryIds) {
            const newLibIds: string[] = arrayNewElements(this.config.libraryIds, newConfig.libraryIds);
            newLibIds?.forEach((newLibId: string) => {
                this.getApplication()
                    .getLibraryManager()
                    .getLibrary(newLibId)
                    .catch((reason: Error) => {
                        this.getApplication().getLogger().error(`Cannot load Library #${newLibId}: ${reason?.message}`);
                    });
                const oldLibIds: string[] = arrayOldElements(this.config.libraryIds, newConfig.libraryIds);
                if (oldLibIds.length != 0) {
                    this.getApplication()
                        .getLogger()
                        .error(`Unload Library is not implemented yet ${oldLibIds.join(', ')}`);
                }
            });
        }
        if (this.config.css !== newConfig.css) {
            const newCsss: string[] = arrayNewElements(this.config.css, newConfig.css);
            newCsss?.forEach((newCss: string) => {
                LoadHelper.loadCSS(newCss).catch((reason: Error) => {
                    this.getApplication().getLogger().error(`Cannot load CSS #${newCss}: ${reason?.message}`);
                });
                const oldCsss: string[] = arrayOldElements(this.config.css, newConfig.css);
                if (oldCsss.length != 0) {
                    this.getApplication()
                        .getLogger()
                        .error(`Unload CSS is not implemented yet ${oldCsss.join(', ')}`);
                }
            });
        }
        // finally we copy all fields
        this.config = deepCopy(newConfig);
        this.getApplication().fireEvent(this.getId(), TpzApplicationEventType.INSTANCE_ITEM_CONFIG_UPDATED, {
            itemConfig: this.config
        });
        return true;
    }

    /**
     * Application getter
     * @returns the parent application
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * Catalog getter
     */
    public getItemCatalog(): ItemCatalog {
        return this.getApplication().getItemCatalog();
    }

    /**
     * Progress loader manager loader (helper method of getApplication().getProgressLoaderManager())
     */
    public getProgressLoaderManager(): ProgressLoaderManager {
        return this.getApplication().getProgressLoaderManager();
    }

    /**
     * Set/Unset Debug
     */
    public setDebug(debug: boolean): void {
        const config = this.getConfig();
        config.debug = debug;
        TpzApplicationCommander.updateItemConfig(this.getApplication(), config);
    }

    /**
     * Set Debug
     */
    public getDebug(): boolean {
        return this.config.debug;
    }

    /**
     * categories getter
     */
    public getCategories(): string[] {
        return this.categories;
    }

    /**
     * Add a category to the category list
     * @param category
     */
    public addCategory(category: string): void {
        if (this.categories.indexOf(category) == -1) this.categories.push(category);
    }

    /**
     * return true if this item contains the given category
     */
    public containsCategory(category: string): boolean {
        return this.getCategories().indexOf(category) != -1;
    }

    /**
     * Add an item configuration as available child of this item
     * The child is automatically started if current item is started
     * @param childId child configuration item to add to available items
     */
    public addToChildrenIds(childId: string): boolean {
        if (!childId) throw new Error(`Illegal child item to be added to item #${this.getId()}`);
        if (this.isChildrenItem(childId)) return false;
        const config = this.getConfig();
        // add childId to the children list
        if (!config.childrenIds) {
            config.childrenIds = [childId];
        } else {
            config.childrenIds = config.childrenIds.concat(childId);
        }
        TpzApplicationCommander.updateItemConfig(this.getApplication(), config);
        return true;
    }

    /**
     * Removes an item configuration from available child of this item.
     * Unplug removed item if currently plugged
     * @param childId child configuration item to remove from available items
     */
    public removeFromChildrenIds(childId: string): boolean {
        if (!childId) throw new Error(`Illegal child id to be removed from item #${this.getId()}`);

        const config = this.getConfig();
        // add childId to the children list
        if (!config.childrenIds) {
            return false;
        } else {
            config.childrenIds = config.childrenIds.filter((childrenId: string) => childrenId != childId);
        }
        TpzApplicationCommander.updateItemConfig(this.getApplication(), config);
        return true;
    }

    /**
     * get all available item ids referenced by this item configuration.
     * accessors Ids are concatanated to childrenIds
     */
    public getChildrenItemIds(): string[] {
        // ensure lists are non null
        if (!this.config.childrenIds) {
            this.config.childrenIds = [];
        }
        if (!this.config.accessorIds) {
            this.config.accessorIds = [];
        }
        return Array.from(new Set([...this.config.childrenIds, ...this.config.accessorIds]));
    }

    /**
     * check if given child Id is in the list of children
     * @param childId
     */
    public isChildrenItem(childId: string): boolean {
        if (!this.config.childrenIds) {
            return false;
        }
        return this.config.childrenIds.includes(childId);
    }

    /**
     * Retrieve all Item configuration referenced by available item ids.
     * if an item id is not registered in item catalog. Errors are logged but no
     * exception is thrown.
     * Result is garanteed to be valid item configurations
     */
    public getReferencedItemConfigs(): ItemConfig[] {
        const itemConfigs: ItemConfig[] = [];
        this.getChildrenItemIds().forEach((itemId: string) => {
            const itemConfig: ItemConfig = this.getItemCatalog().getRegisteredConfigById(itemId);
            if (!itemConfig) {
                this.getLogger().error(`item #${itemId} referenced by item #${this.getId()} is not in item catalog`);
                this.getLogger().info(`item #${itemId} referenced Item Ids: ${this.getChildrenItemIds().join(', ')}`);
                this.getLogger().info(
                    `catalog item ids: ${Object.keys(this.getItemCatalog().getRegisteredConfigs()).join(', ')}`
                );
            } else {
                itemConfigs.push(itemConfig);
            }
        });
        return itemConfigs;
    }

    /**
     * Creates an array of promises loading all libraries defined in the item configuration
     * If an error occurs, it prints a log error message but continues loading other libraries
     * @returns a promise.all on all library promises
     */
    private loadLibraries(): Promise<void> {
        const promises: Promise<void>[] = [];
        this.config.libraryIds?.forEach((libId: string) => {
            const promise: Promise<void> = this.getApplication()
                .getLibraryManager()
                .getLibrary(libId)
                .catch((reason: any) => {
                    this.getLogger().error(
                        `An error occurred loading library #${libId} from item #${this.getId()}`,
                        reason
                    );
                    this.addError(`An error occurred loading library #${libId} from item #${this.getId()}`, reason);
                    return null;
                });
            promises.push(promise);
        });
        return Promise.all(promises).then(() => null);
    }

    /**
     * Creates an array of promises loading all CSSs defined in the item configuration
     * If an error occurs, it prints a log error message but continues loading other CSS
     * @returns a promise.all on all library promises
     */
    private loadCSSs(): Promise<void> {
        const promises: Promise<HTMLLinkElement>[] = [];
        this.config.css?.forEach((css: string) => {
            const promise: Promise<HTMLLinkElement> = LoadHelper.loadCSS(css).catch((reason: any) => {
                this.getLogger().error(`An error occurred loading css ${css} from item #${this.getId()}`, reason);
                return null;
            });
            promises.push(promise);
        });
        return Promise.all(promises).then(() => null);
    }

    /**
     * get all items which are using this instance
     */
    public getManagedChildren(): ItemInstance[] {
        return Object.values(this.children);
    }

    /**
     * check if a given item is referencing this instance
     */
    public isManagedChild(item: ItemInstance): boolean {
        if (!item) throw new Error(`Illegal argument checking referencing item on #${this.getId()} is null`);
        return this.children[item.getId()] != undefined;
    }

    /**
     * get a child items by its ID
     */
    public getManagedChildById(childId: string): ItemInstance {
        if (!childId) {
            return null;
        }
        return this.children[childId];
    }
    /**
     * count the number of items referencing this instance
     */
    public countManagedChildren(): number {
        return Object.keys(this.children).length;
    }

    /**
     * add an item to the list of managed children
     * Start listening to this item
     */
    private addManagedChild(child: ItemInstance): boolean {
        if (!child) throw new Error(`Illegal argument adding managed child on #${this.getId()} is null`);
        if (this.isManagedChild(child)) return false;
        this.children[child.getId()] = child;
        // directly listen to managed children
        child.getEventManager().register(this.getId(), this.onItemEvent.bind(this));
        return true;
    }

    /**
     * remove an item from the list of managed children
     * Stop listening to this item
     */
    private removeManagedChild(child: ItemInstance): boolean {
        if (!child) throw new Error(`Illegal argument removing managed child on #${this.getId()} is null`);
        const childId: string = child.getId();
        if (!this.isManagedChild(child)) return false;
        // stop listening to managed children
        child.getEventManager().unregister(this.getId());
        // remove from children list
        delete this.children[childId];
        return true;
    }

    /**
     * remove items which are using this instance
     */
    public removeAllManagedChildren(): boolean {
        this.getManagedChildren().forEach((childItem: ItemInstance) => {
            this.removeManagedChild(childItem);
        });
        return true;
    }

    /**
     * get all items which are using this instance
     */
    public getManagedParents(): ItemInstance[] {
        if (!this.parents) throw new Error('managed parents is not defined');
        return Object.values(this.parents);
    }

    /**
     * get managed parent and check if parent is the single stored value
     * @param itemId
     * @returns
     */
    public getSingleManagedParent(): ItemInstance {
        if (!this.parents) throw new Error(`managed parents of item #${this.getId()} is not defined`);
        if (Object.keys(this.parents).length != 0) {
            throw new Error(`managed parent of item #${this.getId()} is set`);
        }
        if (Object.keys(this.parents).length != 0) {
            throw new Error(
                `managed parent of item #${this.getId()} is not unique as requested (${
                    Object.keys(this.parents).length
                })`
            );
        }
        return Object.values(this.parents)[0];
    }

    /**
     * check if a given item is referencing this instance
     */
    public isManagedParent(item: ItemInstance): boolean {
        if (!item) throw new Error(`Illegal argument checking referencing item on #${this.getId()} is null`);
        return this.parents[item.getId()] != undefined;
    }

    /**
     * count the number of items referencing this instance
     */
    public countManagedParents(): number {
        return Object.keys(this.parents).length;
    }

    /**
     * add an item to the list of managed parents
     * Start listening to this item
     */
    public addManagedParent(parent: ItemInstance): boolean {
        if (!parent) throw new Error(`Illegal argument adding referencing item on #${this.getId()} is null`);
        if (this.isManagedParent(parent)) return false;
        // directly listen to managed parent
        parent.getEventManager().register(this.getId(), this.onItemEvent.bind(this));
        this.parents[parent.getId()] = parent;
        return true;
    }

    /**
     * remove an item from referencing items by its Id
     */
    public removeManagedParent(parent: ItemInstance): boolean {
        if (!parent) throw new Error(`Illegal argument removing referencing item on #${this.getId()} is null`);
        const parentId: string = parent.getId();
        if (!this.isManagedParent(parent)) return false;
        // stop listening to managed parent
        parent.getEventManager().unregister(this.getId());
        delete this.parents[parentId];
        return true;
    }

    /**
     * Tells if this item is already plugged into given parent
     * @param parentItem parent to check
     * @returns true if given item is a managed parent
     */
    public isPluggedInto(parentItem: ItemInstance): boolean {
        return this.getManagedParents().includes(parentItem);
    }

    /**
     * Tells if this item is currently plugged
     * @returns true if given item has at least one managed parent
     */
    public isPlugged(): boolean {
        return this.getManagedParents() && this.getManagedParents().length != 0;
    }

    /**
     * instance is available if it's state is 'RUNNING' or 'STARTING'
     */
    public isRunning(): boolean {
        return this.getState() == ItemInstanceState.RUNNING || this.getState() == ItemInstanceState.STARTING;
    }

    /**
     * get instance state
     */
    public getState(): ItemInstanceState {
        return this.state;
    }

    /**
     * Request an update of this item. It loops over all children and accessors and request an update for each
     * @param force force the update (true = hard update, false = light update)
     * @returns a Promise containing the update
     */
    public requestUpdate(force: boolean): boolean {
        if (force) this.invalidateAccessors();
        this.getManagedChildren()?.forEach((child: ItemInstance) => {
            child.requestUpdate(force);
        });
        this.getAlreadyCreatedAccessors()?.forEach((child: ValueAccessor<any>) => {
            child.requestUpdate(force);
        });
        return true;
    }

    /**
     * Change instance state
     * if state is the same: do nothing
     * on state change, fire an event
     * \{
     * type: ItemEvent.STATE_CHANGED
     * sourceId: this.getId()
     * content: \{
     * previousState: PREVIOUS STATE(ItemInstaceState),
     * newState: NEW STATE(ItemInstaceState)
     *      \}
     * \}
     */
    private setState(newState: ItemInstanceState): boolean {
        if (typeof newState == 'undefined' || newState == null) {
            this.getLogger()?.warn(`Trying to set item instance #${this.getId()} state to null`);
            return false;
        }
        // if state is the same as previous: do nothing
        const previousState: ItemInstanceState = this.getState();
        if (previousState == newState) return false;
        // change state and fire event
        this.state = newState;
        this.getApplication().fireEvent(
            this.getId(),
            TpzApplicationEventType.ITEM_STATE_CHANGED,
            { itemId: this.getId(), oldState: previousState, newState: newState },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
        return true;
    }

    /**
     * Internal logger getter
     */
    public getInternalLogger(): Logger {
        return this.internalLogger;
    }

    /**
     * Remove all accessors from cache
     * TODO: add an invalidateAccessorById() ?
     */
    private invalidateAccessors(): void {
        // remove callbacks
        Object.values(this.accessors)?.forEach((accessor: ValueAccessor<any>) => accessor.removeCallback(this.getId()));
        // empty accessor cache
        this.accessors = {};
    }

    /**
     * get accessor Ids from configuration
     */
    public getAccessorIds(): string[] {
        return this.config.accessorIds;
    }

    /**
     * generate all accessors
     */
    private preloadAccessors(): Promise<void> {
        this.accessors = {};
        const accessorIds: string[] = this.getAccessorIds();
        // if no accessors defined, return a resolved Promise
        if (!accessorIds?.length) return Promise.resolve(null);
        const promises: Promise<void>[] = [];
        const progressLoaderAccessorsId: string = this.getProgressLoaderManager()?.addProgress(
            null,
            `preload accesssors item #${this.getId()}`
        );
        const itemCatalog: ItemCatalog = this.getApplication().getItemCatalog();
        // loop over all accessors ID defined in the item configuration
        // and simply use the getter which creates accessor and link it if not already done
        accessorIds.forEach((accessorId: string) => {
            promises.push(
                this.getAccessorById(accessorId).then((accessor: ValueAccessor<any>) => {
                    return null;
                })
            );
        });
        return Promise.all(promises)
            .then(() => null)
            .finally(() => {
                this.getProgressLoaderManager().endProgress(progressLoaderAccessorsId);
            });
    }

    /**
     * get an accessor. Check if there is only one accessor defined
     */
    public getAccessor(): Promise<ValueAccessor<any>> {
        const accessorIds: string[] = this.getAccessorIds();
        if (!accessorIds) return null;
        if (accessorIds.length == 0) return null;
        if (accessorIds.length > 1) {
            this.getLogger().error(
                `There is more than one accessor defined in #${this.getId()}. getAccessor() will handled only the first of: ${this.getAccessorIds().join(
                    ','
                )}`
            );
        }
        return this.getAccessorById(accessorIds[0]);
    }

    /**
     * get or create an accessor by its ID
     */
    public getAccessorById(accessorId: string): Promise<ValueAccessor<any>> {
        if (!accessorId) return null;
        let accessor: ValueAccessor<any> = this.accessors[accessorId];
        // if accessor is cached, returned it directly
        if (accessor) return Promise.resolve(accessor);

        return this.getApplication()
            .getItemCatalog()
            .getOrCreateInstanceById(accessorId)
            .then((item: ItemInstance) => {
                // check if instance is a value accessor
                // if (!item.containsCategory(ValueAccessor.VALUE_ACCESSOR_ITEM_CATEGORY)) {
                if (!item.containsCategory(AccessorCategoryType.VALUE_ACCESSOR_CATEGORY_TYPE)) {
                    this.getLogger().error(
                        `referenced #${accessorId} categories ${item.getCategories().join(', ')} does not contain ${
                            AccessorCategoryType.VALUE_ACCESSOR_CATEGORY_TYPE
                        }`
                    );
                    throw new Error(
                        `Item #${this.getId()} reference Accessor #${accessorId} which is not an accessor. Type ${item.getType()}`
                    );
                }
                // item is a value accessor. Add it to the cache and return it

                accessor = item as ValueAccessor<any>;
                this.accessors[accessor.getId()] = accessor;
                return accessor;
            });
    }

    /**
     * get all accessors
     */
    public getAccessors(): Promise<ValueAccessor<any>>[] {
        if (!this.getAccessorIds()) return null;
        if (this.getAccessorIds().length == 0) return [];
        return this.getAccessorIds().map((accessorId: string) => this.getAccessorById(accessorId));
    }

    /************************************************************************************* */

    /**
     * get registered accessor with the given id
     * If the id is not stored it is created from catalog
     * @param accessorId accessor id
     */
    public getOrCreateAccessorById(accessorId: string): Promise<ValueAccessor<any>> {
        if (!accessorId) Promise.reject(new Error('Cannot get Or Create an Accessor with a null ID'));

        // look if there is already a creation promise in progress
        const accessor: ValueAccessor<any> = this.accessors[accessorId];
        if (accessor) {
            return Promise.resolve(accessor);
        }

        const promisedAccessor: Promise<ValueAccessor<any>> = this.promisedAccessors[accessorId];
        if (promisedAccessor) {
            return promisedAccessor;
        }

        // create item by id
        const itemPromise: Promise<ItemInstance> = this.getApplication()
            .getItemCatalog()
            .getOrCreateInstanceById(accessorId);

        if (!itemPromise) {
            return Promise.reject(`Unable to create accessor instance ID #${accessorId}`);
        }

        // add generated item to the registered items
        this.promisedAccessors[accessorId] = itemPromise
            .then((instance: ItemInstance) => {
                if (!instance) throw new Error(`Create instance #${accessorId} returns a null value`);
                // check that the instance is a value Accessor
                // if (!instance.containsCategory(ValueAccessor.VALUE_ACCESSOR_ITEM_CATEGORY)) {
                if (!instance.containsCategory('ValueAccessorCategory')) {
                    throw new Error(
                        `Item #${this.getId()} reference accessor Id #${accessorId} which is not an accessor. Categories are ${instance
                            .getCategories()
                            .join(', ')}`
                    );
                }
                // stores accessor for further identical calls
                this.accessors[accessorId] = instance as ValueAccessor<any>;
                return this.accessors[accessorId];
            })
            .catch((reason: Error) => {
                this.getLogger().error(`An error occurred creating instance #${accessorId}`);
                this.getLogger().error(`Error ${reason.name}: ${reason.message}`, reason);
                throw reason;
            });
        return this.promisedAccessors[accessorId];
    }

    /**
     * Get or create all accessors referenced by the accessorIds config field
     */
    public getOrCreateAllAccessors(): Promise<ValueAccessor<any>>[] {
        const promises: Promise<ValueAccessor<any>>[] = [];
        this.getAccessorIds()?.forEach((id: string) => {
            promises.push(this.getOrCreateAccessorById(id));
        });
        return promises;
    }

    /**
     * Get an already generated accessor by its ID.
     * This method does not try to create the accessor if it not already created
     */
    public getAlreadyCreatedAccessor(id: string): ValueAccessor<any> {
        return this.accessors[id];
    }

    /**
     * Get a generated instance or a promise against a creation in progress by its ID
     * This method does not try to create the accessor if it is not already created
     * @id accessor ID already created or being created
     * @return a promise on accessor if in progress, a resolve promise if already created, the in progress promise if being created or null if not (and not asked to be) created
     */
    public getCreatedOrInProgressAccessor(id: string): Promise<ValueAccessor<any>> {
        // order is important to avoid being trapped between the creation and the storage of the creation
        // begin by the creation in progress
        const inProgressCreationAccessor: Promise<ValueAccessor<any>> = this.promisedAccessors[id];
        if (inProgressCreationAccessor) return inProgressCreationAccessor;
        const createdAccessor: ValueAccessor<any> = this.accessors[id];
        if (createdAccessor) return Promise.resolve(createdAccessor);
        return null;
    }

    /**
     * get all accessors that have already been created
     * This method does not try to create accessors if not already created
     */
    public getAlreadyCreatedAccessors(): ValueAccessor<any>[] {
        return Object.values(this.accessors);
    }

    /************************************************************************************* */

    /**
     * Get Instance Logger. return the stored logger if set or the Library logger if not set. Can be null
     */
    public getLogger(): Logger {
        return this.getInternalLogger() ?? this.getApplication().getLogger();
    }

    /**
     * Set instance Logger
     */
    public setLogger(logger: Logger): void {
        this.internalLogger = logger;
    }

    /**
     * return instance id (config.id)
     */
    public getId(): string {
        return this.config?.id;
    }

    /**
     * Return instance type. This string id must be unique since
     * it is used to know which ItemFactory is able to handle this item
     * type creation
     */
    public getType(): string {
        return this.config?.type;
    }

    /**
     * Action to perform when a managed accessor send an event
     * Item-Core level requestUpdate(false) if accessor event type is DATA
     * @param event accessor changes event
     */
    protected onAccessorChange(event: AccessorEvent): boolean {
        switch (event.type) {
            case AccessorEventType.DATA: {
                this.requestUpdate(false);
                return true;
            }
        }
        return false;
    }

    /**
     * Action performed when item is unplugged from a parent, it calls dounplugFromParent() and
     * removes parent from this managed parents and removes this from parent managed children.
     *
     * This method IS NOT INTENDED to be overriden, override dounplugFromParent() !
     */
    public unplugFromParent(parent: ItemInstance): Promise<void> {
        if (!parent) throw new Error(`this #${this.getId()} should be removed from a valid (non null) parent`);
        // if (!parent.isManagedChild(this)) {
        //     throw new Error(
        //         'item #' +
        //             this.getId() +
        //             ' is asked to be unplugged from #' +
        //             parent.getId() +
        //             ' but is not in managed parents'
        //     );
        // }
        // if (!this.isManagedParent(parent)) {
        //     throw new Error(
        //         'item #' +
        //             this.getId() +
        //             ' is asked to be unplugged from #' +
        //             parent.getId() +
        //             ' but is not in managed children'
        //     );
        // }
        return this.doUnplugFromParent(parent).finally(() => {
            if (parent) {
                parent.removeManagedChild(this);
                this.removeManagedParent(parent);
                // stop this item if not referenced anymore
                this.fireApplicationEvent(
                    TpzApplicationEventType.ITEM_UNPLUGGED,
                    { parentId: parent.getId(), childId: this.getId() },
                    []
                );
                // // stop element if it has no child left
                // this.stopIfNotReferenced();
            }
        });
    }

    /**
     * Action performed when item is plugged into a parent, it calls doPlugInParent() and
     * adds parent to this managed parents and adds this to parent managed children.
     *
     * This method IS NOT INTENDED to be overriden, override doPlugInParent() !
     */
    public plugInParent(parent: ItemInstance): Promise<void> {
        const me = this;
        let plugPromise: Promise<void> = Promise.resolve();
        // start item if not already runnig
        if (!this.isRunning()) plugPromise = this.start();
        if (parent.isManagedChild(this) && this.isManagedChild(parent)) {
            return Promise.resolve();
        }
        if (
            (parent.isManagedChild(this) && !this.isManagedParent(parent)) ||
            (!parent.isManagedChild(this) && this.isManagedParent(parent))
        ) {
            throw new Error(
                `Non-consistent state item #${this.getId()}. This case should not appen, but it may be taken into account instead of throwing an error`
            );
        }
        return (
            plugPromise
                // plug this into its parent
                .then(() => {
                    return this.doPlugInParent(parent);
                })
                .finally(() => {
                    if (parent) {
                        parent.addManagedChild(this);
                        this.addManagedParent(parent);
                        this.fireApplicationEvent(
                            TpzApplicationEventType.ITEM_PLUGGED,
                            { parentId: parent.getId(), childId: this.getId() },
                            []
                        );
                        // plug children if they are already started
                        this.getChildrenItemIds().forEach((childId: string) => {
                            const childItem: ItemInstance = this.getApplication()
                                .getItemCatalog()
                                .getAlreadyCreatedInstance(childId);
                            if (childItem?.isRunning()) childItem.plugInParent(me);
                        });
                    }
                })
        );
    }

    /**
     * Action performed when item is plugged to a parent
     * This method HAS TO BE overriden in specific item implementation.
     * This method is called by plugInParent() method
     */
    public doPlugInParent(parent: ItemInstance): Promise<void> {
        return Promise.resolve();
    }

    /**
     * unplug this item from given parent.
     * This method HAS TO BE  overriden in specific item implementation
     * This method is called by unplugFromParent() method
     */
    public doUnplugFromParent(parent: ItemInstance): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Item instances start is done in three parts
     * - preStart()
     * - doStart()
     * - postStart()
     * Do not overload this method but overload pre/do/post/Start()
     */
    public start(): Promise<void> {
        switch (this.getState()) {
            case ItemInstanceState.RUNNING:
            case ItemInstanceState.STARTING:
                return Promise.resolve();
        }
        this.setState(ItemInstanceState.STARTING);
        // catch clauses are inserted between all pre/do/post method() to continue chaining even if an Error is thrown
        // Errors are stored in error item storage
        return this.preStart()
            .then(() => {
                //
            })
            .catch((reason: any) => {
                this.getLogger().error(
                    `An error occured preStarting Item #${this.getId()}. Continue doStart() instead...`,
                    reason
                );
                this.addError(reason);
                this.setState(ItemInstanceState.STARTING_ERROR);
            })
            .then(() => {
                return this.doStart();
            })
            .catch((reason: any) => {
                this.getLogger().error(
                    `An error occured starting Item #${this.getId()}. Continue postStart() instead...`,
                    reason
                );
                this.addError(reason);
                this.setState(ItemInstanceState.STARTING_ERROR);
            })
            .then(() => {
                return this.postStart();
            })
            .catch((reason: any) => {
                this.setState(ItemInstanceState.STARTING_ERROR);
                this.addError(reason);
                this.getLogger().error(`An error occured postStarting Item #${this.getId()}.`, reason);
            })
            .finally(() => {
                this.setState(ItemInstanceState.RUNNING);
            });
    }

    /**
     * main method when starting (between preStart() and postStart())
     * default behaviour is: preloading accessors
     */
    public doStart(): Promise<void> {
        return Promise.resolve().then(() => {
            return this.preloadAccessors();
        });
    }

    /**
     * last method called when starting (after preStart() and doStart())
     * start all children Items
     */
    public postStart(): Promise<void> {
        const me = this;
        const promises: Promise<void>[] = [];
        // start all children

        me.getChildrenItemIds().forEach((childId: string) => {
            promises.push(
                me
                    .getItemCatalog()
                    .getOrCreateInstanceById(childId)
                    .then((child: ItemInstance) => {
                        if (!child) {
                            me.getLogger().error(
                                `Item #${me.getId()} references item #${childId} which is not in catalog`
                            );
                            return Promise.resolve();
                        }
                        me.getLogger().debug(`Starting Item #${childId} dependency of item #${me.getId()}`);
                        return child
                            .start()
                            .then(() => {
                                // if the starting item is already plugged, do plug all children
                                if (me.isPlugged()) {
                                    return child.plugInParent(me);
                                }
                                return null;
                            })
                            .catch((reason: any) => {
                                this.getLogger().error(
                                    `An error occurred starting dependency #${child.getId()} when starting item #${me.getId()}`,
                                    reason
                                );
                            });
                    })
            );
        });
        return Promise.all(promises).then(() => null);
    }

    /**
     * Before starting item: load all libraries, css and accessors
     * first register item to the application event system
     */
    public preStart(): Promise<void> {
        // libraries loading progress bar
        this.getLogger()?.debug(`PreStarting item ${this.getId()}`);
        // load css asynchronously
        return Promise.resolve()
            .then(() =>
                this.getApplication().getEventManager().register(this.getId(), this.onApplicationEvent.bind(this))
            )
            .then(() => this.loadCSSs())
            .catch((reason: any) => {
                this.getLogger().error(
                    `There has been an error prestarting item #${this.getId()} calling loadCSS()`,
                    reason
                );
                this.addError(reason);
            })
            .then(() => this.loadLibraries())
            .catch((reason: any) => {
                this.getLogger().error(
                    `There has been an error prestarting item #${this.getId()} calling loadLibraries()`,
                    reason
                );
                this.addError(reason);
            });
    }

    /**
     * Item instances pause is done in three parts
     * - prePause()
     * - doPause()
     * - postPause()
     * Do not overload this method but overload pre/do/post/Pause()
     */
    public pause(): Promise<void> {
        switch (this.getState()) {
            case ItemInstanceState.PAUSED:
            case ItemInstanceState.PAUSING:
            case ItemInstanceState.STOPPED:
            case ItemInstanceState.STOPPING:
                return Promise.resolve();
        }
        this.setState(ItemInstanceState.PAUSING);
        // catch clauses are inserted between all pre/do/post method() to continue chaining even if an Error is thrown
        // Errors are stored in error item storage
        return this.prePause()
            .then(() => {
                //
            })
            .catch((reason: any) => {
                this.getLogger().error(
                    `An error occured pre-pausing Item #${this.getId()}. Continue doPause() instead...`,
                    reason
                );
                this.addError(reason);
            })
            .then(() => {
                return this.doPause();
            })
            .catch((reason: any) => {
                this.getLogger().error(
                    `An error occured pausing Item #${this.getId()}. Continue posPause() instead...`,
                    reason
                );
                this.addError(reason);
            })
            .then(() => {
                return this.postPause();
            })
            .catch((reason: any) => {
                this.addError(reason);
                this.getLogger().error(`An error occured post-pausing Item #${this.getId()}.`, reason);
            })
            .finally(() => {
                this.setState(ItemInstanceState.PAUSED);
            });
    }

    /**
     * main method when pauseing (between prePause() and postPause())
     * default behaviour is: do nothing
     */
    public doPause(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * first method called when pauseing (before doPause() and postPause())
     * default behaviour is: do nothing
     */
    public prePause(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * last method called when pauseing (after prePause() and doPause())
     * default behaviour is: set State as ItemInstanceState.PAUSED
     */
    public postPause(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Item instances stop is done in three parts
     * - preStart()
     * - doStart()
     * - postStart()
     * Do not overload this method but overload pre/do/post/Stop()
     */
    public stop(): Promise<void> {
        switch (this.getState()) {
            case ItemInstanceState.STOPPED:
            case ItemInstanceState.STOPPING:
                return Promise.resolve();
        }
        this.setState(ItemInstanceState.STOPPING);
        // catch clauses are inserted between all pre/do/post method() to continue chaining even if an Error is thrown
        // Errors are stored in error item storage
        return this.preStop()
            .then(() => {
                //
            })
            .catch((reason: any) => {
                this.getLogger().error(
                    `An error occured pre-stoping Item #${this.getId()}. Continue doStop() instead...`,
                    reason
                );
                this.addError(reason);
            })
            .then(() => {
                return this.doStop();
            })
            .catch((reason: any) => {
                this.getLogger().error(
                    `An error occured stoping Item #${this.getId()}. Continue posStop() instead...`,
                    reason
                );
                this.addError(reason);
            })
            .then(() => {
                return this.postStop();
            })
            .catch((reason: any) => {
                this.addError(reason);
                this.getLogger().error(`An error occured post-stoping Item #${this.getId()}.`, reason);
            })
            .finally(() => {
                this.setState(ItemInstanceState.STOPPED);
            });
    }

    // /**
    //  * stop the current item, remove it from parents and stop parents if they have no more children
    //  * @returns
    //  */
    // public stopAndRemoveParentsIfEmpty(): Promise<void> {
    //     let parents: ItemInstance[] = this.getManagedParents();
    //     return this.stop().then(() => {
    //         let promises: Promise<void>[] = [];
    //         parents?.forEach((parent: ItemInstance) => {
    //             if (parent.countManagedChildren() == 0) {
    //                 promises.push(parent.stopAndRemoveParentsIfEmpty());
    //             }
    //         });
    //         return Promise.all(promises);
    //     }).then(() => null);
    // }

    // /**
    //  * Stop this item only if it has no more references on it
    //  */
    // private stopIfNotReferenced(): Promise<void> {
    //     console.warn(`[stopIfNotReferenced] #${this.getId()} type=` + this.getType());
    //     if (this.countManagedChildren() == 0) {
    //         console.warn('Stopping #${this.getId()}`);
    //         return this.stop();
    //     }
    //     return Promise.resolve();
    // }

    /**
     * main method when stopping (between preStop() and postStop())
     * default behaviour is: stop listening to accessors
     */
    public doStop(): Promise<void> {
        return Promise.resolve().then(() => {
            // stop listening to accessors
            this.getAlreadyCreatedAccessors().forEach((accessor: ValueAccessor<any>) => {
                accessor.removeCallback(this.getId());
            });
        });
    }

    /**
     * first method called when stopping (before doStop() and postStop())
     * default behaviour is: do nothing
     */
    public preStop(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * last method called when stopping (after preStop() and doStop())
     * default behaviour is: remove item from event manager
     */
    public postStop(): Promise<void> {
        return Promise.resolve().finally(() => {
            this.getApplication().getEventManager().unregister(this.getId());
        });
    }

    /**
     * Action to perform when an item is started.
     * if the started item is in the childrenIds => plugs it into this item.
     * @param itemId started item ID
     */
    private itemStartedReceived(itemId: string): void {
        if (!itemId) return;
    }

    /**
     * Action to perform when an item is stopped.
     * if the stopped item is a managed child => unplugs it from this item.
     * if the stopped item is a managed parent => removes it from managed list and stop if non referenced anymore.
     * @param itemId stopped item ID
     */
    private itemStoppedReceived(itemId: string): void {
        if (!itemId) return;
        // try to remove from managed children
        const childrenToUnplug: ItemInstance[] = this.getManagedChildren().filter((item) => item.getId() == itemId);
        if (childrenToUnplug.length > 1) {
            this.getLogger().warn(
                `Item #${itemId}  has been stopped and is ${
                    childrenToUnplug.length
                } times referenced as managed child by #${this.getId()}`
            );
            this.getLogger().warn('All references are removed but something wrong append before...');
        }
        childrenToUnplug.forEach((item) => item.unplugFromParent(this));
        // try to remove from managed parents
        const parentsToRemove: ItemInstance[] = this.getManagedParents().filter((item) => item.getId() == itemId);
        if (childrenToUnplug.length > 1) {
            this.getLogger().warn(
                `Item #${itemId}  has been stopped and is ${
                    parentsToRemove.length
                } times referenced as managed parent by #${this.getId()}`
            );
            this.getLogger().warn('All references are removed but something wrong append before...');
        }
        parentsToRemove.forEach((parent) => this.removeManagedParent(parent));
    }

    /**
     * Action performed when a ITEM_REQUEST_UPDATE is received.
     * @param itemId item to be updated
     * @param force optional argument (default = false)
     */
    private itemRequestUpdateEventReceived(itemId: string, force: boolean = false): void {
        if (!itemId) throw new Error('itemId has to be defined on update request');
        if (itemId != this.getId()) return; // do nothing if the request is not for this
        this.requestUpdate(force);
    }

    /**
     * Action performed when a ITEM_CONFIGURATION_UPDATED is received.
     * apply new configuration to this instance
     * @param newItemConfig item configuration after update
     */
    private itemConfigUpdatedEventReceived(newItemConfig: ItemConfig): void {
        if (!newItemConfig) throw new Error('itemConfig has to be defined when updating item configuration');
        if (!newItemConfig.id) throw new Error('itemConfig ID has to be defined when updating item configuration');
        // do nothing if this configuration ID does not match this instance ID
        if (this.getId() !== newItemConfig.id) {
            return;
        }
        this.applyConfig(newItemConfig);
    }

    /**
     * Do a simple stop()/start()
     */
    public restart(): Promise<void> {
        return this.stop().then(() => this.start());
    }

    // events management (add, remove callbacks, firing events)
    /**
     * Event Manager lazy getter
     */
    public getEventManager(): EventManager<ItemEvent> {
        if (!this.eventManager) {
            this.eventManager = new EventManager<ItemEvent>();
        }
        return this.eventManager;
    }

    /**
     * adds an event callback.
     * Helper method for this.getEventManager().register()
     * @param callback callback to add
     * @return true if added, false in other cases
     */
    public addCallback(id: string, callback: EventCallback<ItemEvent>): boolean {
        return this.getEventManager().register(id, callback);
    }

    /**
     * removes an event callback
     * Helper method for this.getEventManager().unregister()
     * @param callback callback to remove
     * @return true if removed, false in other cases
     */
    public removeCallback(id: string): boolean {
        return this.getEventManager().unregister(id);
    }

    /**
     * Fire an application event. It is broadcasting to all the application
     * fireApplicationEvent fires an event to the whole application
     * fireItemEvent fires an event to items which are specifically listening to this item
     * Event is timestamped with current time
     * source ID is set to the current item ID
     * @param type event type
     * @param content event content (must match event type to be consistent)
     * @param categories event categorization
     * @param time event creation time (or current time if null)
     */
    public fireApplicationEvent(type: string, content: any, categories: string[] = [], time: number = null): void {
        this.getApplication()
            .getEventManager()
            .trigger({
                source: this.getId(),
                date: time ? time : new Date().getTime(),
                type: type,
                categories: categories,
                content: content
            });
    }

    /**
     * Fires a Item Event via the ITEM event manager
     * fireApplicationEvent fires an event to the whole application
     * fireItemEvent fires an event to items which are specifically listening to this item
     * Helper method for this.getEventManager().trigger()
     * @param event event to be fired to listeners
     */
    public fireItemEvent(type: string, content: any): Promise<void> {
        return this.getEventManager().trigger({
            sourceId: this.getId(),
            type: type,
            content: content
        });
    }

    /**
     * Callback called when an error occurred retrieving its value or when
     * a connection is needed and can't be established...
     * Default behaviour is to send an event: DO NOT DO long treatment or
     * synchronous call which can lead to another error (infinite loop).
     * Error are stored and can be accessed through getErrors() method
     */
    public onError(error: any): void {
        const event: ItemEvent = { type: ItemEventType.ERROR_OCCURRED, sourceId: this.getId(), content: error };
        this.getEventManager().trigger(event);
    }

    /********************** Error Management delegation to internal logger */
    /** FIXME: messages have to be filtered to retrieve only ERROR levels */
    /**
     * Add an error in this instance
     * @param content
     */
    public addError(content: any, error: any = null): void {
        this.getInternalLogger()?.log(Logger.ERROR, content);
        if (error) this.getInternalLogger()?.log(Logger.ERROR, LoggerHelper.getErrorMessageFromObject(error));
    }

    // /**
    //  * Get the number of stored errors
    //  */
    // public getErrorCount(): number {
    //     return this.getLoggergetInternalLoggerAppender()?.getMessages().length;
    // }

    // /**
    //  * Get all errors
    //  */
    // public getErrors(): LoggerMessage[] {
    //     return this.getInternalLoggerAppender()?.getMessages();
    // }

    // /**
    //  * Clear all errors
    //  */
    // public cleanErrors(): void {
    //     return this.getInternalLoggerAppender()?.clearMessages();
    // }

    /**
     * handle events coming from the application
     **/
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event['type'] === undefined) {
            this.getLogger().error(
                `Item #${this.getId()} received invalid event (event.type not defined): ${JSON.stringify(event)}`
            );
            return false;
        }
        if (event['source'] == undefined) {
            this.getLogger().error(
                `Item #${this.getId()} received invalid event (event.source not defined): ${JSON.stringify(event)}`
            );
            return false;
        }
        switch (event.type) {
            case TpzApplicationEventType.ITEM_STATE_CHANGED:
                {
                    TpzApplicationEventType.checkEvent(event, 'itemId', 'oldState', 'newState');
                    if (event.content.newState == ItemInstanceState.RUNNING)
                        this.itemStartedReceived(event.content.itemId);
                    if (event.content.newState == ItemInstanceState.STOPPED)
                        this.itemStoppedReceived(event.content.itemId);
                }
                break;

            case TpzApplicationEventType.ITEM_CONFIG_UPDATE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'itemConfig');
                    if (event.source != this.getId()) {
                        this.itemConfigUpdatedEventReceived(event.content.itemConfig);
                    }
                }
                break;
            case TpzApplicationEventType.ITEM_REQUEST_UPDATE:
                {
                    TpzApplicationEventType.checkEvent(event, 'itemId', 'force');
                    this.itemRequestUpdateEventReceived(event.content.itemId, event.content.force);
                }
                break;
            default:
                if (this.config.logUnhandledEvents) {
                    this.getLogger()?.warn(
                        `Application event not handled: ${
                            event.type
                        }  by item #${this.getId()} of type ${this.getType()}`
                    );
                }
        }
        return false;
    }

    /**
     * handle events coming from managed children or parents
     * (used by accessors for example to avoid broadcasting events to all items)
     **/
    public onItemEvent(event: ItemEvent): boolean {
        if (!event || event.sourceId === this.getId()) {
            return false;
        }
        switch (event.type) {
            case AccessorEventType.DATA:
                {
                    // if event comes from a registered accessor, call onAccessorChange
                    if (this.getAccessorIds().includes(event.sourceId)) {
                        this.onAccessorChange(event);
                    }
                }
                break;
        }
        if (this.config.logUnhandledEvents) {
            this.getLogger()?.warn(
                `Item event not handled: ${event.type}  by item #${this.getId()} of type ${this.getType()}`
            );
        }
        return false;
    }
}
