/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzPlugin, TpzPluginConfig, defaultTpzPluginConfig } from '../../tpz-plugin-core';
import {
    TpzApplicationEvent,
    TpzApplicationEventCategory,
    TpzApplicationEventType
} from '../../../tpz-application-event';
import Keycloak from 'keycloak-js';
import { UserLoginPluginEventType } from './user-login-event';
import { LoadHelper } from '../../../tools/loader';
import { TpzApplicationState, TpzApplicationStateHelper } from '../../../tpz-application-state';
import { TpzClientSession } from '../../../server/session';
import { TpzServerInformation } from '../../../server/server';
import { deepCopy } from '../../../tools/deep-copy';

const ONE_HUNDRED_NUMBER = 1000;
const USER_LOGIN_PLUGIN_TYPE: string = 'UserLoginPluginType';

/**
 *  Plugin configuration
 */
export interface UserLoginPluginConfig extends TpzPluginConfig {
    logMessages?: boolean; // log all messages in the application logger
    // authenticate: boolean // flag to enable/disbale authentication of requests
    // keycloakUrl?: string
    // keycloakRealm?: string
    // keycloakClientId?: string
    // websocketUrl?: string
    css?: string[];
}

/**
 * Default plugin configuration
 */
const defaultUserLoginPluginConfig: UserLoginPluginConfig = {
    ...defaultTpzPluginConfig,
    id: 'user-login-plugin',
    type: USER_LOGIN_PLUGIN_TYPE,
    logMessages: false
    // authenticate: true,
    // keycloakUrl: 'http://127.0.0.1:8081/auth',
    // keycloakRealm: 'topaz',
    // keycloakClientId: 'topaz',
    // websocketUrl: 'wss://127.0.0.1:8080/ws',
};

/**
 * User Login handles user session connection.
 */
export class UserLoginPlugin extends TpzPlugin {
    public static readonly USER_LOGIN_PLUGIN_TYPE: string = USER_LOGIN_PLUGIN_TYPE; // plugin type
    public static USER_ID_VARIABLE = 'userId';
    public static USER_NAME_VARIABLE = 'userName';

    private connected: boolean = false;
    private accessToken: string = null;
    private refreshToken: string = null;
    private keycloak: Keycloak.KeycloakInstance = null;
    private tokenRefereshIntervalId: any = null;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: UserLoginPluginConfig) {
        super(deepCopy(defaultUserLoginPluginConfig, config));
        //TODO websocket will be integrated later
        //this.webSocket = this.createWebsocket();
    }

    /**
     * Config getter specialization
     */
    public getConfig(): UserLoginPluginConfig {
        return super.getConfig() as UserLoginPluginConfig;
    }

    /**
     * basic user token setter
     * It sends an application event if requested (USER_DETAILS_EVENT)
     */
    public setUserDetails(userId: string, userName: string, fireEvent: boolean = true): void {
        this.setUserId(userId, false);
        this.setUserName(userName, false);
        if (fireEvent) {
            this.fireEvent(UserLoginPluginEventType.USER_DETAILS_EVENT, null, [
                TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
            ]);
        }
    }

    /**
     * user id setter
     * It sends an application event if requested (USER_DETAILS_EVENT)
     */
    public setUserId(userId: string, fireEvent: boolean = true): void {
        this.getApplication().setVariable(UserLoginPlugin.USER_ID_VARIABLE, userId);
        if (fireEvent) {
            this.fireEvent(UserLoginPluginEventType.USER_DETAILS_EVENT, null, [
                TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
            ]);
        }
    }

    /**
     * user name setter
     * It sends an application event if requested (USER_DETAILS_EVENT)
     */
    public setUserName(name: string, fireEvent: boolean = true): void {
        this.getApplication().setVariable(UserLoginPlugin.USER_NAME_VARIABLE, name);
        if (fireEvent) {
            this.fireEvent(UserLoginPluginEventType.USER_DETAILS_EVENT, null, [
                TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
            ]);
        }
    }

    /**
     * basic user id getter.
     * @returns user id
     */
    public getUserId(): string {
        return this.getApplication().getVariable(UserLoginPlugin.USER_ID_VARIABLE);
    }

    /**
     * basic user name getter.
     * @returns user name
     */
    public getUserName(): string {
        return this.getApplication().getVariable(UserLoginPlugin.USER_NAME_VARIABLE);
    }

    /**
     * basic user access token getter.
     * @returns user access token
     */
    public getAccessToken(): string {
        return this.accessToken;
    }

    /**
     * basic user refresh token getter.
     * @returns user refresh token
     */
    public getRefreshToken(): string {
        return this.refreshToken;
    }

    /**
     * basic user access token setter
     * It sends an application event (SET_USER_CREDENTIALS)
     */
    public setAccessToken(accessToken: string): void {
        if (this.accessToken === accessToken) return;
        this.accessToken = accessToken;
        this.getApplication().fireEvent(
            this.getId(),
            TpzApplicationEventType.SET_USER_CREDENTIALS,
            { authToken: accessToken },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
    }

    /**
     * basic user refresh token setter
     * It sends an application event (SET_USER_CREDENTIALS)
     */
    public setRefreshToken(refreshToken: string): void {
        if (this.refreshToken === refreshToken) return;
        this.refreshToken = refreshToken;
        this.getApplication().fireEvent(
            this.getId(),
            TpzApplicationEventType.SET_USER_CREDENTIALS,
            { authToken: refreshToken },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
    }

    /**
     * Return true if access token is valid
     */
    public isAccessTokenValid(): boolean {
        const accessTokenParsed = this.keycloak.tokenParsed;
        const isAccessTokenValid = accessTokenParsed?.exp * ONE_HUNDRED_NUMBER > Date.now();

        return isAccessTokenValid ? true : false;
    }

    /**
     * Return true if refresh token is valid
     */
    public isRefreshTokenValid(): boolean {
        const refreshTokenParsed = this.keycloak.refreshTokenParsed;
        const isRefreshTokenValid = refreshTokenParsed?.exp * ONE_HUNDRED_NUMBER > Date.now();

        return isRefreshTokenValid ? true : false;
    }

    /**
     * Return true if all tokens are valid
     */
    public areTokensValid(): boolean {
        return this.isAccessTokenValid && this.isRefreshTokenValid ? true : false;
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTING:
                TpzApplicationEventType.checkEvent(event, null);
                break;
            case TpzApplicationEventType.APPLICATION_STARTED:
                TpzApplicationEventType.checkEvent(event, null);
                break;
            case UserLoginPluginEventType.USER_CONNECTED_EVENT:
                TpzApplicationEventType.checkEvent(event, null);
                this.getLogger().info('User #' + this.getUserId() + ' Connected');
                break;
            case UserLoginPluginEventType.USER_DISCONNECTED_EVENT:
                TpzApplicationEventType.checkEvent(event, null);
                this.getLogger().info('User Disconnected');
                break;
            case UserLoginPluginEventType.REQUEST_USER_CONNECT_EVENT:
                TpzApplicationEventType.checkEvent(event, 'username', 'password');
                this.connectUser(event.content.username, event.content.password).catch((reason: Error) => {
                    this.fireEvent(UserLoginPluginEventType.USER_CONNECTION_ERROR_EVENT, { reason: reason.message }, [
                        TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                    ]);
                });
                break;
            case UserLoginPluginEventType.REQUEST_USER_DISCONNECT_EVENT:
                TpzApplicationEventType.checkEvent(event, null);
                this.disconnectUser();
                break;
            case UserLoginPluginEventType.REQUEST_USER_DETAILS_EVENT:
                TpzApplicationEventType.checkEvent(event, null);
                this.fireUserDetails();
                break;
            case TpzApplicationEventType.SERVER_DISCONNECTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.getLogger().info('Server disconnected. Automatically disconnect User');
                this.disconnectUser();
                break;
            case UserLoginPluginEventType.REQUEST_SAVE_APPLICATION_STATE_EVENT:
                TpzApplicationEventType.checkEvent(event, 'id', 'name');
                this.saveApplicationState(event.content.id, event.content.name);
                break;
            case UserLoginPluginEventType.REQUEST_LOAD_APPLICATION_STATE_EVENT:
                TpzApplicationEventType.checkEvent(event, 'id');
                this.loadApplicationState(event.content.id);
                break;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Save the application state to the connected user
     */
    private saveApplicationState(stateId: string, stateName: string): Promise<void> {
        const state: TpzApplicationState = this.getApplication().getApplicationState(stateId, stateName);

        if (!state) return Promise.reject(new Error('Application state cannot be retrieved from application'));
        const session: TpzClientSession = this.getApplication().getSessionManager().getCurrentSession();
        if (!session) return Promise.reject(new Error('Server not connected. Unable to save application state'));
        const serverInfo: TpzServerInformation = this.getApplication().getServerInformation();
        if (!serverInfo) {
            return Promise.reject(new Error('Server has not send its information. Unable to save application state'));
        }
        if (!serverInfo.userStateGetURL) {
            return Promise.reject(
                new Error("Server information has an empty 'userDataGetURL'. Unable to save application state")
            );
        }
        const userId: string = this.getApplication().getVariable('userId');
        if (!userId) {
            return Promise.reject(
                new Error(
                    "Application has no variable named 'userId'. Is user connected ?. Unable to save application state"
                )
            );
        }
        const saveStateURL: string = serverInfo.userStateSetURL.replace('{userId}', userId);
        return TpzApplicationStateHelper.saveApplicationState(
            saveStateURL,
            stateId,
            stateName,
            this.getApplication()
        ).catch((error: Error) => {
            this.getLogger().error('Unable to save user application state', error);
            return null;
        });
    }

    /**
     * Load an application state of the connected user
     */
    private loadApplicationState(stateId: string): Promise<TpzApplicationState> {
        const session: TpzClientSession = this.getApplication().getSessionManager().getCurrentSession();
        if (!session) return Promise.reject(new Error('Server not connected. Unable to save application state'));
        const serverInfo: TpzServerInformation = this.getApplication().getServerInformation();
        if (!serverInfo) {
            return Promise.reject(new Error('Server has not send its information. Unable to save application state'));
        }
        if (!serverInfo.userStateGetURL) {
            return Promise.reject(
                new Error("Server information has an empty 'userDataGetURL'. Unable to save application state")
            );
        }
        const userId: string = this.getApplication().getVariable('userId');
        if (!userId) {
            return Promise.reject(
                new Error(
                    "Application has no variable named 'userId'. Is user connected ?. Unable to save application state"
                )
            );
        }
        const loadStateURL: string = serverInfo.userStateGetURL
            .replace('{userId}', userId)
            .replace('{stateId}', stateId);
        return TpzApplicationStateHelper.loadApplicationState(loadStateURL, this.getApplication()).catch(
            (error: Error) => {
                this.getApplication().sendNotification(
                    'ERROR',
                    'Unable to load user application states. id = ' + stateId
                );
                this.getLogger().error('Error loading application state #' + stateId, error);
                return null;
            }
        );
    }

    /**
     * Return true if user is authenticated
     */
    public isAuthenticated(): boolean {
        return this.connected;
    }
    /**
     * action performed when plugin is Plugged
     */
    public onPlug(): Promise<TpzPlugin> {
        const self = this;
        return super.onPlug().then((plugin: TpzPlugin) => {
            return plugin;
        });
    }
    /**
     * action performed when plugin is unPlugged
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onPlug().then((plugin: TpzPlugin) => {
            return plugin;
        });
    }

    /**
     * Action performed on server CLIENT_ID
     */
    public onUserConnection(connection: Keycloak.KeycloakInstance): void {
        // change application ID
        this.getLogger().info('User ' + connection.userInfo + ' connected');
    }

    /**
     * Refresh token before it expires
     */
    private scheduleRefreshToken() {
        const validity = Math.round(
            this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000
        );
        this.getLogger().info('Token valid for ' + validity + ' seconds');
        const self = this;
        this.tokenRefereshIntervalId = setInterval(() => {
            self.keycloak
                .updateToken(validity)
                .then((refreshed: boolean) => {
                    if (refreshed) {
                        this.getLogger().info('Token refreshed : ' + self.keycloak.token);
                        this.setAccessToken(self.keycloak.token);
                    } else {
                        this.getLogger().info('Token not refreshed');
                    }
                })
                .catch((reason: any) => {
                    this.getLogger().error('Failed to refresh token : ' + reason);
                    this.fireUserDisconnected();
                });
        }, (validity - 60) * 1000);
    }

    /**
     * Get a token from keycloak and init keycloak-js with it.
     * @param userId
     * @param password
     */
    private connectWithKeycloak(userId: string, password: string): Promise<void> {
        const self: UserLoginPlugin = this;
        if (!this.getApplication().isServerConnected()) {
            return Promise.reject(new Error('Server not connected.'));
        }

        if (!this.getApplication().getServerInformation()) {
            return Promise.reject(
                new Error('Server information not set. Cannot reach keycloak server for identification')
            );
        }
        if (!this.getApplication().getServerInformation().keycloakURL) {
            return Promise.reject(
                new Error(
                    'Server information does not contain the keycloak URL. Cannot reach keycloak server for identification'
                )
            );
        }
        if (!this.getApplication().getServerInformation().keycloakRealm) {
            return Promise.reject(
                new Error(
                    'Server information does not contain the keycloak Realm. Cannot reach keycloak server for identification'
                )
            );
        }
        if (!this.getApplication().getServerInformation().keycloakClientId) {
            return Promise.reject(
                new Error(
                    'Server information does not contain the keycloak Client ID. Cannot reach keycloak server for identification'
                )
            );
        }
        // configure Keycloak
        const keycloakURL: string = this.getApplication().getServerInformation().keycloakURL;
        if (!keycloakURL) return Promise.reject(new Error('Server information does not contain keycloak URL'));
        const keycloakRealm: string = this.getApplication().getServerInformation().keycloakRealm;
        if (!keycloakRealm) return Promise.reject(new Error('Server information does not contain keycloak Realm'));
        const keycloakClientId: string = this.getApplication().getServerInformation().keycloakClientId;
        if (!keycloakClientId) {
            return Promise.reject(new Error('Server information does not contain keycloak Client Id'));
        }

        this.keycloak = Keycloak({
            url: keycloakURL,
            realm: keycloakRealm,
            clientId: keycloakClientId
        });
        this.getLogger().debug(
            'Keycloak configured for realm ' + self.keycloak.subject + ' with configuration ' + this.getConfig()
        );

        // Call open id end point to get a token
        const tokenEndPoint = keycloakURL + '/realms/' + keycloakRealm + '/protocol/openid-connect/token';
        const body = new URLSearchParams({
            client_id: keycloakClientId,
            username: userId,
            password: password,
            grant_type: 'password'
        });
        this.getLogger().debug('Trying to login on end point : ' + tokenEndPoint);
        return LoadHelper.fetchRequest(tokenEndPoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        })
            .then((response: Response) => {
                if (!response.ok) {
                    let errMsg = 'Failed to authenticate ' + userId;
                    if (response.statusText) errMsg = errMsg + ' : ' + response.statusText;
                    return Promise.reject(new Error(errMsg));
                }
                return response.json();
            })
            .then((json) => {
                //initialize keycloak with the token
                return self.keycloak.init({
                    onLoad: 'check-sso',
                    token: json.access_token,
                    refreshToken: json.refresh_token,
                    enableLogging: true,
                    checkLoginIframe: false
                });
            })
            .then((authenticated: any) => {
                if (authenticated) {
                    // stores user information in application variables
                    this.getLogger().info(userId + ' authenticated by Keycloak');
                    this.setUserId(userId);
                    this.scheduleRefreshToken();
                    this.connected = true;
                    this.fireUserConnected();
                    this.setAccessToken(this.keycloak.token);
                    this.setRefreshToken(this.keycloak.refreshToken);
                    return this.keycloak.token;
                } else {
                    this.getLogger().error(`Authentication by Keycloak failed`);
                    return Promise.reject(new Error('Failed to initialize keycloak'));
                }
            })
            .then(() => {
                return this.fetchUserInformations();
            })
            .catch((reason: any) => {
                return Promise.reject(new Error('Login Failed. Reason = ' + reason));
            });
    }

    /**
     * launch a connection request for the client (this application) on the backend connection service
     * @param token
     * @returns
     */
    private fetchUserInformations(): Promise<void> {
        const accessToken: string = this.getAccessToken();
        const refreshToken: string = this.getRefreshToken();

        if (!accessToken) throw new Error('Cannot get user information. User Access Token is not set');
        if (!refreshToken) throw new Error('Cannot get user information. User Refresh Token is not set');

        const url: string = this.getApplication().getConfig().backendURL + '/whoami';
        this.getApplication()
            .fetchTopazServer(url, {
                method: 'GET',
                referrerPolicy: 'origin'
            })
            .then((response) => response.text())
            .then((text: string) => {
                this.getLogger().info('Whomai service returned ' + text);
                this.setUserName(text, true);
            })
            .catch((reason) => {
                this.getLogger().error('Request to whoami service failed ' + reason, reason);
            });
        return null;
    }

    /**
     * fires an event USER_CONNECTED
     * @param username connected user name
     */
    public fireUserConnected(): void {
        this.getApplication().fireEvent(this.getId(), UserLoginPluginEventType.USER_CONNECTED_EVENT, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * fires an event USER_DISCONNECTED
     */
    public fireUserDisconnected(): void {
        this.getApplication().fireEvent(this.getId(), UserLoginPluginEventType.USER_DISCONNECTED_EVENT, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * fires an event USER_DETAILS
     */
    public fireUserDetails(): void {
        this.getApplication().fireEvent(this.getId(), UserLoginPluginEventType.USER_DETAILS_EVENT, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * Returns a Promise that will give the base64 encoded token that can
     * be sent in the Authorization header in requests to services
     * @param userId userId
     * @param password password
     */
    public connectUser(userId: string, password: string): Promise<void> {
        return this.connectWithKeycloak(userId, password);
    }

    /**
     * Clears all authentication data related to the current user
     */
    public disconnectUser(): void {
        if (this.tokenRefereshIntervalId != null) {
            clearInterval(this.tokenRefereshIntervalId);
            this.tokenRefereshIntervalId = null;
        }
        this.keycloak?.clearToken();
        if (this.connected) {
            this.getApplication().unsetVariable(UserLoginPlugin.USER_ID_VARIABLE);
            this.getApplication().unsetVariable(UserLoginPlugin.USER_NAME_VARIABLE);
            this.setAccessToken(null);
            this.setRefreshToken(null);
            this.connected = false;
            this.fireUserDisconnected();
            this.getLogger().info('User has been logged out');
        }
    }
}
