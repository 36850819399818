/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { JsPanel } from '../../../jspanel-core';
import { TpzPlugin, TpzPluginConfig, defaultTpzPluginConfig } from '../../tpz-plugin-core';
import {
    TpzApplicationEvent,
    TpzApplicationEventCategory,
    TpzApplicationEventType,
} from '../../../tpz-application-event';
import { UserLoginPluginEventType } from './user-login-event';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationUI, ModalWindow } from '../../../tpz-application-ui';
import { UserLoginPlugin } from './plugin-user-login';
import { deepCopy } from '../../../tools/deep-copy';

const USER_LOGIN_CONNECTION_UI_PLUGIN_TYPE: string = 'UserLoginPluginConnectionUIType';

declare const jsPanel: JsPanel;

/**
 * Connection UI configuration
 */
export interface UserLoginPluginConnectionUIConfig extends TpzPluginConfig {
    loginIcon?: string;
    logoutIcon?: string;
    userLoginPluginId?: string; // id of the observed userLogin plugin
    parentId?: string; // parent where UI will be inserted
    secureMode?: boolean; // secure mode for eyesight display type
}

/**
 * default values Connection UI configuration
 */
export const defaultUserLoginPluginConnectionUIConfig: UserLoginPluginConnectionUIConfig = {
    ...defaultTpzPluginConfig,
    type: USER_LOGIN_CONNECTION_UI_PLUGIN_TYPE,
    loginIcon: '/images/icon/connect-on.png',
    logoutIcon: '/images/icon/connect-off.png',
    userLoginPluginId: null,
    parentId: null,
    secureMode: false,
};

/**
 * Connection UI. It is linked to an observed UserLoginPlugin
 * It adds a button in a parent to connect/disconnect to server
 * A tooltip gives the client informations
 * This panel switches between two differents div: statusPanel and authFormPanel
 */
export class UserLoginPluginConnectionUI extends TpzPlugin {
    public static readonly USER_LOGIN_CONNECTION_UI_PLUGIN_TYPE: string = USER_LOGIN_CONNECTION_UI_PLUGIN_TYPE; // plugin type
    private authPanel: ModalWindow = null;
    private mainContainerDiv: HTMLDivElement = null;

    private connectionContainer: HTMLDivElement = null;
    private loginIcon: HTMLImageElement = null;
    private popup: HTMLDivElement = null;
    private msgContainer: HTMLDivElement = null;
    // private buttonsContainer: HTMLDivElement = null;
    // private connectButton: HTMLButtonElement = null;
    // private disconnectButton: HTMLButtonElement = null;

    private authFormPanel: HTMLDivElement = null;
    // private errorMsgElt: HTMLParagraphElement = null;
    // private statusPanel: HTMLElement = null;

    private displayUserNameInput: HTMLInputElement = null;
    private displayUserIdInput: HTMLInputElement = null;

    private loginInput: HTMLInputElement = null;
    private pwdInput: HTMLInputElement = null;
    private pwdEyesight: HTMLSpanElement = null;
    private pwdInputAndEyesight: HTMLDivElement = null;
    private loginGroup: HTMLDivElement = null;
    private pwdGroup: HTMLDivElement = null;
    private errorGroup: HTMLDivElement = null;

    /**
     * Constructor
     * @param config Connection UI configuration
     */
    constructor(config?: UserLoginPluginConnectionUIConfig | TpzApplication) {
        super(deepCopy(defaultUserLoginPluginConnectionUIConfig, config));
    }

    // /**
    //  * status panel lazy getter
    //  * @returns the status panel
    //  */
    // public getStatusPanel() {
    //     if (!this.statusPanel) {
    //         this.statusPanel = TpzApplicationUI.createDiv({});
    //         this.statusPanel.classList.add('cnx-status-panel');
    //         const tablePanel: HTMLElement = TpzApplicationUI.createDiv({});
    //         tablePanel.classList.add('cnx-status-data-panel');
    //         tablePanel.appendChild(TpzApplicationUI.createLabel({ label: 'user id :' }));
    //         tablePanel.appendChild(this.getDisplayUserIdInput());
    //         tablePanel.appendChild(TpzApplicationUI.createLabel({ label: 'user name :' }));
    //         tablePanel.appendChild(this.getDisplayUserNameInput());
    //         this.statusPanel.appendChild(tablePanel);
    //         this.statusPanel.insertAdjacentElement('beforeend', this.getDisconnectButton());
    //     }
    //     return this.statusPanel;
    // }

    /**
     * basic user id getter.
     * @returns user id
     */
    public getUserId(): string {
        return this.getApplication().getVariable(UserLoginPlugin.USER_ID_VARIABLE);
    }

    /**
     * basic user name getter.
     * @returns user name
     */
    public getUserName(): string {
        return this.getApplication().getVariable(UserLoginPlugin.USER_NAME_VARIABLE);
    }

    /**
     * get the username input (readonly) used to display user information
     */
    private getDisplayUserNameInput(): HTMLInputElement {
        if (!this.displayUserNameInput) {
            this.displayUserNameInput = TpzApplicationUI.createTextInput({ value: '' });
            this.displayUserNameInput.setAttribute('readonly', 'readonly');
        }
        return this.displayUserNameInput;
    }

    /**
     * get the userId input (readonly) used to display user information
     */
    private getDisplayUserIdInput(): HTMLInputElement {
        if (!this.displayUserIdInput) {
            this.displayUserIdInput = TpzApplicationUI.createTextInput({ value: '' });
            this.displayUserIdInput.setAttribute('readonly', 'readonly');
        }
        return this.displayUserIdInput;
    }

    /**
     * Main container div child of the jsPanel
     * @returns the main container panel
     */
    public getMainContainerDiv(): HTMLDivElement {
        if (!this.mainContainerDiv) {
            this.mainContainerDiv = TpzApplicationUI.createDiv({});
            this.updateUI();
        }
        return this.mainContainerDiv;
    }

    /**
     * update UI depending on user connected / not connected
     */
    private updateUI(): void {
        this.getMainContainerDiv().innerHTML = '';
        // we consider that user is connected if user id is set (may not be a good idea)
        if (this.getUserId()) {
            // this.getMainContainerDiv().appendChild(this.getStatusPanel());
            this.getLoginIcon().src = this.getConfig().loginIcon;
            this.getDisplayUserIdInput().value = this.getUserId();
            this.getDisplayUserNameInput().value = this.getUserName();

            this.getAuthModal().getButton('connect').setAttribute('disabled', '');
            this.getAuthModal().getButton('connect').classList.add('unavailable');
            this.getAuthModal().getButton('connect').classList.remove('available');

            this.getAuthModal().getButton('disconnect').removeAttribute('disabled');
            this.getAuthModal().getButton('disconnect').classList.add('available');
            this.getAuthModal().getButton('disconnect').classList.remove('unavailable');
        } else {
            this.getMainContainerDiv().appendChild(this.getAuthFormPanel());
            this.getLoginIcon().src = this.getConfig().logoutIcon;
            this.getDisplayUserIdInput().value = '';
            this.getDisplayUserNameInput().value = '';

            this.getAuthModal().getButton('connect').removeAttribute('disabled');
            this.getAuthModal().getButton('connect').classList.add('available');
            this.getAuthModal().getButton('connect').classList.remove('unavailable');

            this.getAuthModal().getButton('disconnect').setAttribute('disabled', '');
            this.getAuthModal().getButton('disconnect').classList.add('unavailable');
            this.getAuthModal().getButton('disconnect').classList.remove('available');
        }
    }

    /**
     * authorization Form panel lazy getter
     * @returns the auth panel
     */
    public getAuthFormPanel(): HTMLDivElement {
        if (!this.authFormPanel) {
            this.authFormPanel = TpzApplicationUI.createDiv({});
            const form: HTMLElement = document.createElement('form');
            form.classList.add('login-form');
            form.setAttribute('autocomplete', 'off');
            form.appendChild(this.getLoginGroup());
            form.appendChild(this.getPwdGroup());
            // form.appendChild(this.getErrorGroup());
            // form.appendChild(this.getConnectButton());
            this.authFormPanel.appendChild(form);
        }
        return this.authFormPanel;
    }

    /**
     * Config specialization
     */
    public getConfig(): UserLoginPluginConnectionUIConfig {
        return super.getConfig() as UserLoginPluginConnectionUIConfig;
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                TpzApplicationEventType.checkEvent(event, null);
                this.createUI();
                break;
            case UserLoginPluginEventType.USER_CONNECTED_EVENT:
                TpzApplicationEventType.checkEvent(event, null);
                this.clearLoginForm();
                this.getAuthModal().addMessage('ok', 'User connected');
                this.updateUI();
                break;
            case UserLoginPluginEventType.USER_DISCONNECTED_EVENT:
                this.getAuthModal().addMessage('warn', 'User disconnected');
                TpzApplicationEventType.checkEvent(event, null);
                this.updateUI();
                break;
            case UserLoginPluginEventType.USER_CONNECTION_ERROR_EVENT:
                TpzApplicationEventType.checkEvent(event, 'reason');
                this.getAuthModal().addMessage('error', 'User connection error');
                this.getAuthModal().addMessage('error', event.content.reason);
                this.updateUI();
                break;
            case UserLoginPluginEventType.USER_DETAILS_EVENT:
                TpzApplicationEventType.checkEvent(event, null);
                this.updateUI();
                break;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Update UI content
     * @returns
     */
    public update(): Promise<void> {
        return super.update().then(() => {
            this.createUI();
        });
    }

    /**
     * get the type of password visualization (secure mode, if we toggle the visualization or not)
     * If not specified, return false
     */
    public getSecureMode(): boolean {
        return this.getConfig().secureMode ? this.getConfig().secureMode : false;
    }

    /**
     *
     * @returns
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then((plugin: TpzPlugin) => {
            this.getLogger().info('plugin user-login-ui has been plugged');
            this.createUI();
            return plugin;
        });
    }
    /**
     *
     * Insert UI in the parent defined by 'parentId' in configuration
     */
    public createUI(): void {
        let parent: HTMLElement = document.getElementById(this.getConfig().parentId);
        if (!parent) {
            this.getLogger()?.warn(
                'UserLoginPluginConnectionUI#' + this.getId() + ' Cannot retrieve parent element Id. use body...'
            );
            parent = document.body;
        }
        parent.appendChild(this.getContainer());
    }

    /**
     * Sync Plugin UI  main container lazy getter
     */
    private getContainer(): HTMLDivElement {
        if (!this.connectionContainer) {
            this.connectionContainer = TpzApplicationUI.createDiv({});
            this.connectionContainer.appendChild(this.getLoginIcon());
        }
        return this.connectionContainer;
    }

    /**
     * Sync Plugin UI connection UI lazy getter
     */
    private getLoginIcon(): HTMLImageElement {
        if (!this.loginIcon) {
            this.loginIcon = document.createElement('img');
            this.loginIcon.classList.add('login-icon');
            this.loginIcon.src = this.getConfig().logoutIcon;
            this.loginIcon.addEventListener('click', this.loginClick.bind(this));
        }
        return this.loginIcon;
    }

    /**
     * Fires an event to request a user connection
     */
    private requestUserConnect(username: string, password: string): void {
        this.fireEvent(
            UserLoginPluginEventType.REQUEST_USER_CONNECT_EVENT,
            { username: username, password: password },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
    }

    /**
     * Fires an event to request the user to disconnect
     */
    private requestUserDisconnect(): void {
        this.fireEvent(UserLoginPluginEventType.REQUEST_USER_DISCONNECT_EVENT, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY,
        ]);
    }

    /**
     * Action performed when login icon is clicked on
     * @param ev click mouse event
     */
    public loginClick(ev: MouseEvent): void {
        this.getAuthModal()?.open();
    }

    /**
     * Get the user authentication modal
     * @returns the authentication modal
     */
    public getAuthModal(): ModalWindow {
        const self: UserLoginPluginConnectionUI = this;
        if (!this.authPanel) {
            this.authPanel = new ModalWindow('Authentication', 'user-connect-modal-window-prompt-title', [
                { id: 'connect', text: 'Connect', i18n: 'user-connect-i18n' },
                { id: 'disconnect', text: 'Disconnect', i18n: 'user-disconnect-i18n' },
                { id: 'clear', text: 'Clear', i18n: 'user-clear-connect-i18n' },
                { id: 'abort', text: 'Abort', i18n: 'abort-user-connect-i18n' },
            ]);
            this.authPanel.onOpen = (win: ModalWindow) => {
                win.getTopDiv().appendChild(this.getMainContainerDiv());
            };
            this.authPanel.onClickButton = (win: ModalWindow, buttonId: string) => {
                if (buttonId == 'connect') {
                    this.tryToConnect(this.loginInput.value, this.pwdInput.value);
                    return false;
                } else if (buttonId == 'disconnect') {
                    this.requestUserDisconnect();
                    return false;
                } else if (buttonId == 'clear') {
                    this.clearLoginForm();
                    return false;
                }
                // cancel: close window (return true)
                return true;
            };
        }
        return this.authPanel;
    }

    // /**
    //  * Create authentication Panel
    //  */
    // public createAuthModal(): void {
    //     const self: UserLoginPluginConnectionUI = this;
    //     if (!this.authPanel) {
    //         this.authPanel = jsPanel.create({
    //             contentSize: '390 240',
    //             headerTitle: 'Authentication',
    //             position: 'right-top -30 55',
    //             theme: 'primary',
    //             id: 'authModal',
    //             animateOut: 'jsPanelFadeOut',
    //             content: this.getMainContainerDiv(),
    //             callback: [
    //                 (panel: JsPanel) => {
    //                     // /* get all focusable elements within the panel content */
    //                     // const focusableElmts = (panel.content).querySelectorAll('input, button');
    //                     // const first: Element = focusableElmts[0];
    //                     // const last = focusableElmts[focusableElmts.length - 1];
    //                     // /* focus first focusable element */
    //                     // (<HTMLElement>first).focus();
    //                     // /* handler to lock focus within the panel */
    //                     // document.addEventListener('keydown', function (e: any) {
    //                     //     if (e.key === 'Tab') {
    //                     //         if (e.shiftKey)  /* shift + tab */ {
    //                     //             if (document.activeElement === first) {
    //                     //                 (<HTMLElement>last).focus();
    //                     //                 e.preventDefault();
    //                     //             }
    //                     //         } else  /* tab */ {
    //                     //             if (document.activeElement === last) {
    //                     //                 (<HTMLElement>first).focus();
    //                     //                 e.preventDefault();
    //                     //             }
    //                     //         }
    //                     //     }
    //                     // });
    //                 }
    //             ],
    //             onclosed: (): boolean => {
    //                 self.clearLoginForm();
    //                 return (self.authPanel = null);
    //             }
    //         });
    //         this.authPanel.content.classList.add('auth-modal');
    //     }
    // }

    /**
     * Remove all values from the login form
     * (error message, login and password inputs)
     */
    private clearLoginForm() {
        this.getAuthModal().getMiddleDiv().innerHTML = '';
        if (this.loginInput) this.loginInput.value = '';
        if (this.pwdInput) this.pwdInput.value = '';
    }

    /**
     * Creates login elements container
     * @returns loginGroup container
     */
    private getLoginGroup(): HTMLElement {
        if (!this.loginGroup) {
            this.loginGroup = TpzApplicationUI.createDiv({});
            this.loginGroup.classList.add('form-group');
            // Login Label
            const loginLabel: HTMLLabelElement = TpzApplicationUI.createLabel({ label: 'login' });
            loginLabel.setAttribute('for', 'login');

            // Login Input
            this.loginInput = TpzApplicationUI.createTextInput({ value: '' });
            this.loginInput.placeholder = 'Enter login';
            this.loginInput.setAttribute('aria-describedby', 'loginHelp');
            this.loginInput.setAttribute('tabindex', '1');
            this.loginInput.classList.add('inputLogin');

            // Append elements to formGroup Element
            this.loginGroup.appendChild(loginLabel);
            this.loginGroup.appendChild(this.loginInput);
        }
        return this.loginGroup;
    }

    private toggleAttribute(): void {
        if (this.pwdInput.getAttribute('type') === 'password') {
            this.pwdInput.setAttribute('type', 'text');
        } else {
            this.pwdInput.setAttribute('type', 'password');
        }
    }

    /**
     * password elements container lazy getter
     * @returns pwdGroup container
     */
    private getPwdGroup(): HTMLElement {
        if (!this.pwdGroup) {
            this.pwdGroup = TpzApplicationUI.createDiv({});

            this.pwdGroup.classList.add('form-group');

            // Password Label
            const pwdLabel: HTMLLabelElement = TpzApplicationUI.createLabel({ label: 'Password' });
            pwdLabel.setAttribute('for', 'inputPassword');
            // Password Input

            this.pwdInputAndEyesight = TpzApplicationUI.createDiv({ classes: 'input-password-and-eyesight' });

            this.pwdInput = TpzApplicationUI.createPasswordInput({ value: '' });
            this.pwdInput.type = 'password';
            this.pwdInput.classList.add('inputPassword');
            this.pwdInput.classList.add('form-control');
            this.pwdInput.placeholder = 'Password';
            this.pwdInput.setAttribute('tabindex', '2');

            // this.pwdInput.addEventListener('keydown', (event: KeyboardEvent) => {
            //     // when fields are filled by the browser automatically (with saved user/passwd)
            //     // an event 'keydown' is launch but is not a KeyboardEvent. getModifierState method
            //     // does not exist in this case
            //     if (event.getModifierState && event.getModifierState('CapsLock')) {
            //         this.displayLoginErrorMessage('WARNING! Caps lock is ON.');
            //     } else {
            //         this.displayLoginErrorMessage('');
            //     }
            // });

            // create eyesight password icon
            this.pwdEyesight = TpzApplicationUI.createDiv({ classes: ['password-eyesight'] });

            if (this.getSecureMode()) {
                this.pwdEyesight.addEventListener('click', () => {
                    if (!this.pwdInput) return;
                    this.toggleAttribute();
                });
            } else {
                this.pwdEyesight.addEventListener('mouseup', () => {
                    if (!this.pwdInput) return;
                    this.toggleAttribute();
                });
                this.pwdEyesight.addEventListener('mousedown', () => {
                    if (!this.pwdInput) return;
                    this.toggleAttribute();
                });
            }
            //append elements to inputGroup
            this.pwdGroup.appendChild(pwdLabel);
            this.pwdInputAndEyesight.appendChild(this.pwdInput);
            this.pwdInputAndEyesight.appendChild(this.pwdEyesight);
            this.pwdGroup.appendChild(this.pwdInputAndEyesight);
        }
        return this.pwdGroup;
    }

    // /**
    //  * error elements container lazy getter
    //  * @returns container
    //  */
    // private getErrorGroup(): HTMLDivElement {
    //     if (!this.errorGroup) {
    //         this.errorGroup = TpzApplicationUI.createDiv({});
    //         this.errorMsgElt = document.createElement('p');
    //         this.errorMsgElt.id = 'loginErrorMsgElt';
    //         this.errorMsgElt.classList.add('form-text', 'text-muted');
    //         this.errorMsgElt.innerText = '';
    //         this.errorMsgElt.setAttribute('tabindex', '-1');
    //         // Append elements to formGroup Element
    //         this.errorGroup.appendChild(this.errorMsgElt);
    //     }
    //     return this.errorGroup;
    // }

    // /**
    //  * connect button lazy getter
    //  * @returns
    //  */
    // private getConnectButton(): HTMLButtonElement {
    //     const me: UserLoginPluginConnectionUI = this;
    //     if (!this.connectButton) {
    //         this.connectButton = TpzApplicationUI.createButton({ label: 'connect' });
    //         this.connectButton.classList.add('btn-connect');
    //         this.connectButton.setAttribute('tabindex', '3');

    //         this.connectButton.addEventListener('click', (e: Event) => {
    //             this.connectWithFilledFields();
    //         });
    //     }
    //     return this.connectButton;
    // }

    /**
     * Connect using UI filled field
     */
    private tryToConnect(username: string, password: string): void {
        if (username == '') {
            this.getAuthModal().setMessage('error', 'Please fill the user name');
            this.loginInput.focus();
        } else {
            this.getAuthModal().setMessage('info', 'try to connect user ' + username);
            this.requestUserConnect(username, password);
        }
        // in all cases we are waiting not closing the modal window, waiting for connection result
    }
}
