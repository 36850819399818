/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Accessor, AccessorConfig, defaultAccessorConfig } from './tpz-access-core';
import { AccessorEventType } from './tpz-access-event';
import {
    AccessorValueImportExport,
    DataConvertFromString,
    DataConvertToString,
    AccessorCategoryType
} from './tpz-access-types';
import { deepCopy } from '../tpz-application/tools/deep-copy';
import { TpzApplication } from '../tpz-application/tpz-application-core';
import { AccessorType } from './tpz-access-types';

/////////////////////////////////// SINGLE VALUE ACCESSOR ///////////////////////////////

/**
 * Value Accessor config. Initial value can be set
 * value is stored as data type object. Set this value programmatically to fix initial value
 * exportedValue is internally stored with format given by export() method. !! DO NOT SET IT MANUALLY !!
 * hashValue is a condensed version of the value (for exemple a complete file can be condensed as a 128bits string using MD5)
 */
export interface ValueAccessorConfig<DataType> extends AccessorConfig {
    // value?: DataType; // stored value
    useHash?: boolean; //
    exportedValue?: any; // internal use only. value conversion with exportFunction()
}

/**
 * Default value accessor config
 */
export const defaultValueAccessorConfig: ValueAccessorConfig<any> = {
    ...defaultAccessorConfig,
    // type is not defined for abstract classes
    // value: undefined,
    useHash: false,
    exportedValue: undefined
};

/**
 * Single const value accessor.
 * This accessor use import/export of values to string to check if they are identical.
 * setValue() of the same value does nothing until 'force' argument is set to true
 * when a new Value is set, an event is fired: \{sourceId:, type: AccessorEventType.DATA \}
 */
export abstract class ValueAccessor<DataType> extends Accessor<DataType> {
    private readonly valueFromString: DataConvertFromString<DataType> = null;
    private readonly valueToString: DataConvertToString<DataType> = null;
    private value: DataType = undefined; // accessor current value (reflects config.exportedValue) !! 'null' IS a value, only 'undefined' means not set
    private hashValue: string = null; // condensed version of the value (for exemple a complete file can be condensed as a 128bits string using MD5)

    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param type accessor type
     * @param app topaz application in which this accessor is used
     * @param valueFromString conversion method from String to Value
     * @param valueToString conversion method from Value to String
     */
    constructor(
        config: ValueAccessorConfig<DataType>,
        type: string,
        app: TpzApplication,
        valueFromString: DataConvertFromString<DataType>,
        valueToString: DataConvertToString<DataType>
    ) {
        super(deepCopy(defaultValueAccessorConfig, config), type, app);
        this.valueFromString = valueFromString;
        this.valueToString = valueToString;
        this.addCategory(AccessorCategoryType.VALUE_ACCESSOR_CATEGORY_TYPE);
        // if (this.getConfig()?.exportedValue) {
        //     this.setValue( this.valueFromString(this.exportValue))
        //     const valueExport: string = this.valueToString(this.getConfig().value);
        //     if (this.getConfig().value !== this.getConfig().exportedValue) {
        //         this.getLogger().warn(
        //             `value (${this.getConfig().value}) and exportedValue (${
        //                 this.getConfig().exportedValue
        //             }) are both set in '${this.getId()}' accessor but value differ. Use value.`
        //         );
        //         this.setExportedValue(valueExport);
        //     }
        // }

        this.fireApplicationEvent(AccessorEventType.CREATION, null);
    }

    // /**
    //  * check that import( export( value ) ) === value
    //  *
    //  */
    // public checkImportExport(): boolean {
    //     let b: boolean = this.valueFromString( this.valueToString( this.getValue() ) ) == this.getValue();
    //     if (!b) {
    //         this.getErrorManager().addError(new CSError(CSError.ERROR,"IMPORT / EXPORT of " + this.getId() + " is not valid"));
    //         this.getErrorManager().addError(new CSError(CSError.ERROR,"value = " + this.getValue() ) );
    //         this.getErrorManager().addError(new CSError(CSError.ERROR,"toString(value) = " + this.valueToString( this.getValue() ) ) );
    //         this.getErrorManager().addError(new CSError(CSError.ERROR,"import(export(value)) = " + this.valueFromString( this.valueToString( this.getValue() ) ) ) );
    //         this.getErrorManager().addError(new CSError(CSError.ERROR,this.valueFromString( this.valueToString( this.getValue() ) ) + " != " + this.getValue()));
    //     }
    //     return b;
    // }

    /**
     * get Config specialization. Config value is exported if it is not already done
     */
    public getConfig(): ValueAccessorConfig<DataType> {
        return super.getConfig() as ValueAccessorConfig<DataType>;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */
    public applyConfig(newConfig: ValueAccessorConfig<DataType> = null): boolean {
        let changes: boolean = false;
        const config = this.getConfig();
        if (config?.useHash !== newConfig?.useHash) {
            // nothing to do
            changes = true;
        }
        if (config?.exportedValue !== newConfig?.exportedValue) {
            this.value = undefined; // reinitialize accessor value
            changes = true;
        }
        return super.applyConfig(newConfig) && changes; // take care that order is important !
    }

    /**
     * Set exported value field
     * @param newValue
     */
    private setExportedValue(newValue: string): void {
        const config: ValueAccessorConfig<DataType> = deepCopy(this.getConfig());
        config.exportedValue = newValue;
        this.applyConfig(config);
    }

    /**
     * export function: Converts DataType to any 'storable' object (primitive type or dictionary)
     */
    public exportValue(value: DataType): any {
        if (!value) return AccessorValueImportExport.NULL;
        if (this.valueToString) return this.valueToString(value);
        return JSON.stringify(value);
    }

    /**
     * import function: Converts 'Stored' value to DataType object
     */
    public importValue(importedValue: any): DataType {
        if (!importedValue) return null;
        if (importedValue === AccessorValueImportExport.NULL) return null;
        if (this.valueFromString) return this.valueFromString(importedValue);
        return JSON.parse(importedValue);
    }

    /**
     * Check if current hash value is equal to given hash value
     * @param hashValue hashValue to test equality
     */
    public hashEqual(hashValue: string): boolean {
        return this?.hashValue === hashValue;
    }

    /**
     * Check if current value is equal to given value
     * @param value value to test equality
     */
    public valueEqual(value: DataType): boolean {
        return this.getExportedValue() === this.exportValue(value);
    }

    /**
     * Set the inner value associated with a hash value. It sends a notification if the value differs from stored one
     * If force is true => set the value without comparing hashes
     * If useHash is true and hashes are identcal => do not set value as new
     * @param v value to set
     * @param sendNotification send notification if true
     * @param force force to send notification even if value is equal to stored one
     */
    public setValueAndHash(value: DataType, hash: string, force: boolean = false): boolean {
        if (this.getConfig().useHash && this.hashEqual(hash) && !force) {
            return false;
        }
        this.hashValue = hash;
        this.setValue(value);
        // delete exportedValue to synchronize value and exportedValue (lazy getter will do the conversion)
        this.setExportedValue(null);
        return true;
    }

    /**
     * Start method fires an event if there is already a value in config
     */
    public postStart(): Promise<void> {
        return super.postStart().then(() => {
            this.setValue(this.importValue(this.getExportedValue()), true);
            return null;
        });
    }

    /**
     * Set the inner value. It sends a notification if the value differs from stored one.
     *
     * @param v value to set
     * @param sendNotification send notification if true
     * @param force force to send notification even if value is equal to stored one
     */
    public setValue(newValue: DataType, force: boolean = false): boolean {
        const newExportedValue: string = this.exportValue(newValue);
        // if force is not set and exportedValues are identical => do not set the value because they are identical
        if (!force && this.getExportedValue() === newExportedValue) return false;
        // set new value
        delete this.hashValue;
        // const config: ValueAccessorConfig<DataType> = deepCopy(this.getConfig());
        this.value = newValue;
        // this.applyConfig(config);
        this.fireItemEvent(AccessorEventType.DATA, null);
        return true;
    }

    /**
     * Returns immediately the stored value from the last Accessor update.
     * To retrieve the accessor real value, use requestUpdate() method, then if a new value is set, a message will be fired by the accessor
     * If the config.value is not set, first converts the config.exportedValue and stores it in config.value
     * If both are "undefined", returns "undefined"
     */
    public getValue(): DataType {
        // convert exported value to real value
        if (typeof this.value === 'undefined' && typeof this.getConfig().exportedValue !== 'undefined') {
            this.value = this.importValue(this.getConfig().exportedValue);
        }
        return this.value;
    }

    /**
     * Lazy getter of the exported Value. If value is undefined => exportedValue will be undefined too
     */
    public getExportedValue(): string {
        if (typeof this.getConfig().exportedValue === 'undefined') {
            this.setExportedValue(this.valueToString(this.getValue()));
        }
        return this.getConfig().exportedValue;
    }
}

/** Value type string */
export class ValueAccessorString extends ValueAccessor<string> {
    /**
     * Accessor Constructor
     * @param config accessor configuration
     * @param app application in which the accessor is used
     */
    constructor(config: ValueAccessorConfig<string>, app: TpzApplication) {
        super(
            config,
            AccessorType.STRING_VALUE_ACCESSOR_TYPE,
            app,
            AccessorValueImportExport.convertToStringString,
            AccessorValueImportExport.convertFromStringString
        );
    }

    /**
     * ask to request value
     * @param force true to update value even if the value is the same
     */
    public requestUpdate(force: boolean): boolean {
        if (!this.isRunning()) return false;
        this.setValue(this.getValue(), force);
        return true;
    }
}
