/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { BufferedAppender, ConsoleAppender } from './tpz-log-appender';
import { Logger, LoggerMessage } from './tpz-log-core';

export class LoggerHelper {
    /**
     * Create a string message error from an object depending on the object type
     * - string => string itself
     * - Error => error.message
     * - Error (stack only) => error.stack
     * sinon stringify(object)
     * @param error object containing an error which will be converted to a text message
     * @returns text message conversion
     */
    public static getErrorMessageFromObject(error: any): string {
        if (!error) return '';
        if (typeof error === 'string') return error;
        if (error?.message) return error.message;
        if (error?.stack) return JSON.stringify(error.stack, null, 2);
        return JSON.stringify(error, null, 2);
    }

    /**
     * Create a logger with 2 appenders: Console and Buffered
     * @param loggerId logger id (throw error if null or undefined)
     * @param maxCount max buffered messages
     */
    public static createBufferedConsoleLogger(loggerId: string, maxCount: number = 200): Logger {
        if (!loggerId) throw new Error('Invalid logger ID');
        const logger: Logger = new Logger(loggerId);
        logger.addAppender(new ConsoleAppender());
        logger.addAppender(new BufferedAppender(maxCount));
        return logger;
    }

    /**
     * Create a log message
     */
    public static createLogMessage(level: number, message: any): LoggerMessage {
        if (!level || !message) return null;
        return {
            level: level,
            date: new Date().getTime(),
            content: message
        };
    }
}
