/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplicationEvent } from '../../../tpz-application-event';

/**
 * Sync Events Categories
 */
export class UserLoginPluginEventType {
    public static readonly USER_CONNECTED_EVENT = 'USER_CONNECTED_EVENT'; //
    public static readonly USER_CONNECTION_ERROR_EVENT = 'USER_CONNECTION_ERROR_EVENT'; // { reason: string }
    public static readonly USER_DISCONNECTED_EVENT = 'USER_DISCONNECTED_EVENT';
    public static readonly USER_DETAILS_EVENT: string = 'USER_DETAILS_EVENT'; //
    public static readonly WEBSOCKET_CONNECTED_EVENT = 'WEBSOCKET_CONNECTED_EVENT';
    public static readonly WEBSOCKET_DISCONNECTED_EVENT = 'WEBSOCKET_DISCONNECTED_EVENT';
    public static readonly REQUEST_USER_CONNECT_EVENT: string = 'REQUEST_USER_CONNECT_EVENT'; // { username: string, password: string }
    public static readonly REQUEST_USER_DISCONNECT_EVENT: string = 'REQUEST_USER_DISCONNECT_EVENT';
    public static readonly REQUEST_USER_DETAILS_EVENT: string = 'REQUEST_USER_DETAILS_EVENT';
    public static readonly REQUEST_USER_PREFERENCES_EVENT: string = 'REQUEST_USER_PREFERENCES_EVENT';
    public static readonly REQUEST_SAVE_APPLICATION_STATE_EVENT: string = 'REQUEST_SAVE_APPLICATION_STATE_EVENT'; // { id: string, name: string }
    public static readonly REQUEST_LOAD_APPLICATION_STATE_EVENT: string = 'REQUEST_LOAD_APPLICATION_STATE_EVENT'; // { id: string }
    public static readonly USER_PREFERENCES_EVENT: string = 'USER_PREFERENCES_EVENT'; // UserPreferences (or null)
}

/**
 * Sync Events Categories
 */
export class UserLoginPluginEventCategory {
    public static readonly USER_CONNECTION_CATEGORY = 'USER_CONNECTION';
}

/**
 * Event fired when user is connected
 */
export interface UserLoginPluginConnectedEvent extends TpzApplicationEvent {
    userId: string;
}

/**
 * Event fired when user is disconnected
 */
export interface UserLoginPluginDisconnectedEvent extends TpzApplicationEvent {
    userId: string;
}

/**
 * Event fired when a problem occurs with user connection
 */
export interface UserLoginPluginConnectionProblemEvent extends TpzApplicationEvent {
    reason: string;
}
