/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { TpzWindow } from './tpz-window-core';
import { TpzApplication } from '../tpz-application-core';
import { TpzApplicationFactory } from '../tpz-application-factory';
import { TpzWindowConfig, defaultTpzWindowConfig } from './tpz-window-config';
import { TpzApplicationTypes, TpzApplicationCategories } from '../tpz-application-types';

/**
 * Window Factory
 */
export class TpzWindowFactory extends TpzApplicationFactory {
    private static readonly MODULE_WINDOW_FACTORY_TYPE: string = 'TpzWindowFactory';

    /**
     * Constructor
     * @param displayerCatalog displayer catalog
     */
    constructor(application: TpzApplication) {
        super(TpzWindowFactory.MODULE_WINDOW_FACTORY_TYPE, application);
        this.addCategory(TpzApplicationCategories.TPZ_WINDOW_CATEGORY);
        this.addHandledItem(
            TpzApplicationTypes.TPZ_WINDOW_TYPE,
            this.createTpzWindow.bind(this),
            defaultTpzWindowConfig
        );
    }

    /** TpzModuleDisplayer creator function */
    private createTpzWindow(config: TpzWindowConfig): Promise<TpzWindow> {
        return Promise.resolve(new TpzWindow(config, this.getApplication()));
    }
}
