/*CONDITIONS
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationFactory } from '../../../tpz-application-factory';
import { TpzApplicationEvent } from '../../../tpz-application-event';
import { TimeEventContent } from '../../../plugins/instances/time-manager/plugin-time-manager';
import { RollerEventType, RollerEvent } from '../../../ui-widgets/roller-input/roller';
import { Knob, KnobEvent, KnobEventType } from '../../../ui-widgets/knob-input/knob-input';
import { RollerDate } from '../../../ui-widgets/roller-input/roller-date';
//     Knob,
//     KnobEvent,
//     KnobEventType
import { DateFormat } from '../../../tools/dateformat';
import {
    SimulatedTimeDescriptor,
    SimulatedTimeHelper,
    SimulatedTimeRunner,
    SimulatedTimeEventType
} from '../../../time/simulated-time';
import { TpzApplicationHelper } from '../../../tpz-application-helper';
import { TpzView } from '../../../desktop/tpz-view-core';
import { defaultTpzViewConfig, TpzViewConfig } from '../../../desktop/tpz-view-config';
import { deepCopy, deepEquals } from '../../../tools/deep-copy';
import { TpzApplicationEventType } from '../../../tpz-application-event';
import { TpzApplicationUI } from '../../../tpz-application-ui';
import { TpzApplicationCategories } from '../../../tpz-application-types';

// item type (used in default configuration and Item Instance constructor)
const TIMEPICKER_VIEW_TYPE: string = 'TimePickerViewType';

/**
 * describe a choice in the UI combobox for time range selection.
 * Possible choices are described in TimePickerView Configuration
 */
export type TimeRangeSelectionDescriptor = { id: string; label: string; i18n?: string; duration: number };

/**
 * TimePickerView configuration
 */
export interface TimePickerViewConfig extends TpzViewConfig {
    timeRangeSelect?: TimeRangeSelectionDescriptor[]; // description of all time range selection
    selectedTimeRangeId?: string; // default Time range option ID
}

/**
 * Default Time Picker Displayer configuration
 */
export const defaultTimePickerViewConfig: TimePickerViewConfig = {
    ...defaultTpzViewConfig,
    type: TIMEPICKER_VIEW_TYPE,
    timeRangeSelect: [
        { id: 'time-range-select-5m', label: '5 minutes', duration: 300000 },
        { id: 'time-range-select-30m', label: '30 minutes', duration: 1800000 },
        { id: 'time-range-select-1h', label: '1 hour', duration: 3600000 },
        { id: 'time-range-select-6h', label: '6 hours', duration: 21600000 },
        { id: 'time-range-select-1d', label: '1 day', duration: 86400000 },
        { id: 'time-range-select-5d', label: '5 days', duration: 432000000 }
    ],
    selectedTimeRangeId: 'time-range-select-5m'
    //        css: defaultTpzDisplayerConfig.css.concat(["css/time-picker.css"])
};

// /**
//  * Time picker Events
//  */

/**
 * Select a date and time range
 * +- div main-container
 *    +- div time-line-container
 *       +- div time-line
 *          +- div time-line-bar
 *       +- div start-time-marker
 *       +- div end-time-marker
 *    +- div tools-container
 *
 */
export class TimePickerView extends TpzView {
    private mainContainer: HTMLDivElement = null;
    private topDiv: HTMLDivElement = null;
    private centerDiv: HTMLDivElement = null;
    private bottomDiv: HTMLDivElement = null;
    private controlDiv: HTMLDivElement = null;
    private timeLineContainer: HTMLDivElement = null;
    private timeLineDiv: HTMLDivElement = null;
    private timeLineProgressBarDiv: HTMLDivElement = null;
    private startTimeMarkerDiv: HTMLDivElement = null;
    private endTimeMarkerDiv: HTMLDivElement = null;
    private currentTimeInput: HTMLInputElement = null;
    private currentTimeSelector: RollerDate = null;
    private timeFactorLabel: HTMLInputElement = null;
    private runnerStateLabel: HTMLLabelElement = null;
    private startTimeSelector: RollerDate = null;
    private endTimeSelector: RollerDate = null;
    private startTimeLabel: HTMLInputElement = null;
    private timeRangeSelect: HTMLSelectElement = null;
    private endTimeLabel: HTMLInputElement = null;
    private playButton: HTMLButtonElement = null;
    private timeFactorKnob: Knob = null;

    private clickX: number = null;
    private clickY: number = null;
    // private currentTimePercentage: number = null;

    // private startTime: number = null; // time range start
    // // private currentTime: number = null; // current time selected (must be between start and stop range)
    // private endTime: number = null; // end time range
    // private timeFactor: number = 1; // simulated time factor
    private timeDescriptor: SimulatedTimeDescriptor = null; // edited time descriptor
    private timeRunner: SimulatedTimeRunner = null; // time runner playing time

    public static readonly TIMEPICKER_VIEW_TYPE = TIMEPICKER_VIEW_TYPE;
    public static readonly TIME_EVENT_CATEGORY = 'TIME';
    public static readonly TIMERUNNER_UPDATE_RATE: number = 1000; // time runner update rate in milliseconds
    public static readonly TIME_RUNNING_LABEL: string = 'TIME RUNNING';
    public static readonly TIME_STOPPED_LABEL: string = 'TIME STOPPED';
    public static readonly PLAY_BUTTON_PLAY_LABEL: string = 'Play';
    public static readonly PLAY_BUTTON_STOP_LABEL: string = 'Stop';
    public static readonly PLAY_BUTTON_PLAYING_CLASS: string = 'playing';

    /** Constructor */
    constructor(config: TimePickerViewConfig, application: TpzApplication) {
        super(deepCopy(defaultTimePickerViewConfig, config), TimePickerView.TIMEPICKER_VIEW_TYPE, application);
    }

    /**
     * Time Runner Id getter
     */
    public getTimeRunnerId(): string {
        return `${this.getId()}-time-runner`;
    }

    /**
     * Return configuration used to create the object
     * This method should be overloaded by all derived classes in order to reflect
     * the object content at any moment.
     * using getConfig() the instance must be re-instantiated using its factory
     * with the exact same state
     */
    public getConfig(): TimePickerViewConfig {
        return super.getConfig() as TimePickerViewConfig;
    }

    /**
     * Apply a new item configuration if changes have been made
     * do not forget to call super.applyConfig() when overloading this method
     * You should handle any changes of this object configuration (not the inherited fields which are handled by super classes)
     * @param newConfig new configuration to store in Item
     * @return true if some changes applied, false if the configuration are equivalent
     */

    //@override
    public applyConfig(newConfig: TimePickerViewConfig): boolean {
        let changed: boolean = false;
        const oldConfig: TimePickerViewConfig = this.getConfig();
        if (
            !deepEquals(newConfig?.timeRangeSelect, oldConfig?.timeRangeSelect) ||
            newConfig?.selectedTimeRangeId !== oldConfig?.selectedTimeRangeId
        ) {
            this.fillTimeRangeSelect(newConfig?.timeRangeSelect, newConfig?.selectedTimeRangeId);
            changed = true;
        }
        return super.applyConfig(newConfig) && changed;
    }

    /**
     * Start Clock displayer by creating an interval function requesting clock value
     */
    public doStart(): Promise<void> {
        const me = this;
        return super.doStart().then(() => {
            me.requestUpdate();
        });
    }

    /**
     * Stop interval function
     */
    public doStop(): Promise<void> {
        return super.doStop().then(() => {
            //
        });
    }

    /**
     * Create UI and insert main container into parent
     * @param parent
     */
    public createUI(parent: HTMLDivElement): boolean {
        if (!parent) throw new Error('parent is not defined');
        const mainContainer: HTMLDivElement = this.getMainContainer();
        if (!parent.contains(mainContainer)) parent.appendChild(mainContainer);
        return true;
    }

    /**
     * get Time Picker Displayer main container
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv();
            this.mainContainer.appendChild(this.getTopDiv());
            this.mainContainer.appendChild(this.getCenterDiv());
            this.mainContainer.appendChild(this.getBottomDiv());
        }
        return this.mainContainer;
    }

    /**
     * get Top Div of main Container
     */
    private getTopDiv(): HTMLDivElement {
        if (!this.topDiv) {
            this.topDiv = TpzApplicationUI.createDiv({ classes: ['time-picker-top-container'] });
            this.topDiv.appendChild(this.getControlDiv());
            this.topDiv.appendChild(this.getCurrentTimeInput());
            // encapsulate time factor knob and input in a div container
            const timeFactorContainer: HTMLDivElement = TpzApplicationUI.createDiv({
                classes: ['time-factor-container']
            });
            timeFactorContainer.appendChild(this.getTimeFactorKnob().getUI());
            timeFactorContainer.appendChild(this.getTimeFactorInput());
            this.topDiv.appendChild(timeFactorContainer);
        }
        return this.topDiv;
    }

    /**
     * get Center Div of main Container
     */
    private getCenterDiv(): HTMLDivElement {
        if (!this.centerDiv) {
            this.centerDiv = TpzApplicationUI.createDiv({ classes: ['time-picker-center-container'] });
            this.centerDiv.appendChild(this.getTimeLineContainer());
        }
        return this.centerDiv;
    }

    /**
     * get Bottom Div of main Container
     */
    private getBottomDiv(): HTMLDivElement {
        if (!this.bottomDiv) {
            this.bottomDiv = TpzApplicationUI.createDiv({ classes: ['time-picker-bottom-container'] });
            this.bottomDiv.appendChild(this.getStartTimeInput());
            this.bottomDiv.appendChild(this.getTimeRangeSelect());
            this.bottomDiv.appendChild(this.getEndTimeInput());
        }
        return this.bottomDiv;
    }

    /**
     * get Buttons Div of top Div
     */
    private getControlDiv(): HTMLDivElement {
        if (!this.controlDiv) {
            this.controlDiv = TpzApplicationUI.createDiv({ classes: ['time-picker-control-container'] });
            this.controlDiv.appendChild(this.getPlayButton());
            this.controlDiv.appendChild(this.getRunnerStateLabel());
        }
        return this.controlDiv;
    }

    /**
     * get Time Picker Displayer Time Line container
     */
    public getTimeLineContainer(): HTMLDivElement {
        if (!this.timeLineContainer) {
            this.timeLineContainer = TpzApplicationUI.createDiv({ classes: ['time-line-container'] });
            this.timeLineContainer.appendChild(this.getTimeLineDiv());
            this.timeLineContainer.appendChild(this.getStartTimeMarkerDiv());
            this.timeLineContainer.appendChild(this.getEndTimeMarkerDiv());
        }
        return this.timeLineContainer;
    }

    /**
     * compute percentage time on mouse event
     */
    private computeInterpolationFactor(clientX: number): number {
        const rect = this.getTimeLineDiv().getBoundingClientRect();
        const x = clientX - rect.left; //x position within the element.
        let interpolationFactor = (clientX - rect.left) / rect.width;
        interpolationFactor = Math.min(1, Math.max(0, interpolationFactor));
        return interpolationFactor;
    }

    /**
     * compute time as interpolation between start and end time
     */
    private computeInterpolatedTime(interpolationFactor: number): number {
        if (!this.getStartTime() || !this.getEndTime()) return null;
        if (this.getEndTime() - this.getStartTime() === 0) return this.getStartTime();
        const interpolatedTime: number =
            this.getStartTime() + (this.getEndTime() - this.getStartTime()) * interpolationFactor;
        return interpolatedTime;
    }

    /**
     * get Time Line Div
     */
    public getTimeLineDiv(): HTMLDivElement {
        const me: TimePickerView = this;
        if (!this.timeLineDiv) {
            this.timeLineDiv = TpzApplicationUI.createDiv({ classes: ['time-line'] });
            this.timeLineDiv.appendChild(this.getTimeLineProgressBarDiv());
            TpzApplicationUI.tooltip(this.timeLineDiv, 'Time Line (drag or click to change time)', 'time-line-tooltip');

            this.timeLineDiv.addEventListener('pointerdown', function (e: PointerEvent) {
                me.clickX = e.clientX;
                me.clickY = e.clientY;
                // stop runner if live motion is triggered
                me.stopPlaying();
                // set progressBar and current time as displaying live data
                me.getTimeLineProgressBarDiv().classList.add('live');
                me.getCurrentTimeInput().classList.add('live');
                const interpol = me.computeInterpolationFactor(me.clickX);
                e.preventDefault();
                me.timeLineDiv.setPointerCapture(e.pointerId);
                // display live data
                me.getTimeLineProgressBarDiv().style.width = Math.min(100, interpol * 100) + '%';
                TimePickerView.displayDate(me.getCurrentTimeInput(), me.computeInterpolatedTime(interpol));
            });

            this.timeLineDiv.addEventListener('pointerup', function (e: PointerEvent) {
                // unset progressBar and current time as displaying live data
                me.getTimeLineProgressBarDiv().classList.remove('live');
                me.getCurrentTimeInput().classList.remove('live');
                me.timeLineDiv.releasePointerCapture(e.pointerId);
                const interpol = me.computeInterpolationFactor(e.clientX);
                me.setCurrentTime(me.computeInterpolatedTime(interpol));
                me.clickX = null;
                me.clickY = null;
            });

            this.timeLineDiv.addEventListener('pointermove', function (e: PointerEvent) {
                if (me.clickX != null && me.clickY != null) {
                    const interpol = me.computeInterpolationFactor(e.clientX);
                    // display live data
                    me.getTimeLineProgressBarDiv().style.width = Math.min(100, interpol * 100) + '%';
                    const liveTime: number = me.computeInterpolatedTime(interpol);
                    TimePickerView.displayDate(me.getCurrentTimeInput(), liveTime);
                    me.fireLiveTimeDescriptionEvent(liveTime);
                }
            });
        }
        return this.timeLineDiv;
    }

    /**
     * get Time Line PorgressBarDiv
     */
    public getTimeLineProgressBarDiv(): HTMLDivElement {
        if (!this.timeLineProgressBarDiv) {
            this.timeLineProgressBarDiv = TpzApplicationUI.createDiv({ classes: ['time-line-bar'] });
        }
        return this.timeLineProgressBarDiv;
    }

    /**
     * get Start Time Marker Div
     */
    public getStartTimeMarkerDiv(): HTMLDivElement {
        const me: TimePickerView = this;
        if (!this.startTimeMarkerDiv) {
            this.startTimeMarkerDiv = TpzApplicationUI.createDiv({ classes: ['time-marker', 'start-time-marker'] });
            TpzApplicationUI.tooltip(this.startTimeMarkerDiv, 'Start time marker', 'start-time-marker-tooltip');
            this.startTimeMarkerDiv.addEventListener('click', (e: MouseEvent) => this.displayStartTimeSelector(e));
        }
        return this.startTimeMarkerDiv;
    }

    /**
     * get End Time Marker Div
     */
    public getEndTimeMarkerDiv(): HTMLDivElement {
        const me: TimePickerView = this;
        if (!this.endTimeMarkerDiv) {
            this.endTimeMarkerDiv = TpzApplicationUI.createDiv({ classes: ['time-marker', 'end-time-marker'] });
            TpzApplicationUI.tooltip(this.endTimeMarkerDiv, 'End time marker', 'end-time-marker-tooltip');
            this.endTimeMarkerDiv.addEventListener('click', this.displayEndTimeSelector.bind(this));
        }
        return this.endTimeMarkerDiv;
    }

    /*
     * get Play Button
     */
    public getPlayButton(): HTMLButtonElement {
        const me: TimePickerView = this;
        if (!this.playButton) {
            this.playButton = TpzApplicationUI.createButton({ label: 'Play', classes: ['time-play'] });
            TpzApplicationUI.tooltip(this.playButton, 'Play/Pause time runner', 'time-play-tooltip');
            this.playButton.addEventListener('click', this.togglePlaying.bind(this));
        }
        return this.playButton;
    }

    /**
     * get Runner State
     */
    public getRunnerStateLabel(): HTMLLabelElement {
        const me: TimePickerView = this;
        if (!this.runnerStateLabel) {
            this.runnerStateLabel = TpzApplicationUI.createLabel({ label: 'Stopped', classes: ['runner-state'] });
            TpzApplicationUI.tooltip(this.runnerStateLabel, 'Time runner state', 'time-runner-state-tooltip');
        }
        return this.runnerStateLabel;
    }

    /**
     * get Time Label
     */
    public getCurrentTimeInput(): HTMLInputElement {
        const me: TimePickerView = this;
        if (!this.currentTimeInput) {
            this.currentTimeInput = TpzApplicationUI.createTextInput({
                value: 'undefined',
                classes: ['time-line-label']
            });
            this.currentTimeInput.addEventListener('click', (e: MouseEvent) => this.displayCurrentTimeSelector(e));
            TpzApplicationUI.tooltip(this.currentTimeInput, 'Current time', 'current-time-tooltip');
            this.updateUI();
        }
        return this.currentTimeInput;
    }

    /**
     * update the current time visualization
     */
    private updateCurrentTime(): void {
        TimePickerView.displayDate(this.getCurrentTimeInput(), this.getCurrentSimulatedTime());
        const timeRange: number = this.getEndTime() - this.getStartTime();
        if (
            Number.isFinite(timeRange) &&
            timeRange > 0 &&
            Number.isFinite(this.getCurrentSimulatedTime()) &&
            Number.isFinite(this.getStartTime())
        ) {
            const newPercentage: number = ((this.getCurrentSimulatedTime() - this.getStartTime()) / timeRange) * 100;

            this.getTimeLineProgressBarDiv().style.width = Math.min(100, newPercentage) + '%';
            this.getTimeLineProgressBarDiv().classList.remove('present');
        } else {
            this.getTimeLineProgressBarDiv().style.width = '50%';
            this.getTimeLineProgressBarDiv().classList.add('present');
        }
    }
    /**
     * get Start Time Label
     */
    public getStartTimeInput(): HTMLInputElement {
        if (!this.startTimeLabel) {
            this.startTimeLabel = TpzApplicationUI.createTextInput({
                value: 'undefined',
                classes: ['time-line-label']
            });
            TpzApplicationUI.tooltip(this.startTimeLabel, 'Start time', 'start-time-tooltip');
            this.startTimeLabel.addEventListener('click', (e: MouseEvent) => this.displayStartTimeSelector(e));
            this.updateUI();
        }
        return this.startTimeLabel;
    }

    /**
     * get End Time Label
     */
    public getTimeRangeSelect(): HTMLSelectElement {
        if (!this.timeRangeSelect) {
            this.timeRangeSelect = TpzApplicationUI.createComboBox({ classes: ['time-range-select'] });
            TpzApplicationUI.tooltip(this.timeRangeSelect, 'Select time range', 'time-range-tooltip');
            const optionNone: HTMLOptionElement = new Option('none', '0');

            this.timeRangeSelect.appendChild(optionNone);
            this.changeTimeRangeSelector(null);
            this.timeRangeSelect.addEventListener('change', (e: Event) => this.changeTimeRangeSelector(e));
            this.fillTimeRangeSelect(this.getConfig().timeRangeSelect, this.getConfig().selectedTimeRangeId);
        }
        return this.timeRangeSelect;
    }

    /**
     * fill the UI time range combobox with config time range descriptor
     */
    private fillTimeRangeSelect(
        timeRangeDescriptors: TimeRangeSelectionDescriptor[],
        defaultSelectionId: string
    ): void {
        if (!this.timeRangeSelect) return;
        this.timeRangeSelect.innerHTML = '';
        timeRangeDescriptors?.forEach((range: TimeRangeSelectionDescriptor) => {
            const option: HTMLOptionElement = new Option(range.label, `${range.duration}`);
            if (range.id === defaultSelectionId) {
                option.setAttribute('selected', 'selected');
            }
            this.timeRangeSelect.appendChild(option);
        });
    }

    /**
     * Action to perform when Time Range selector is User modified
     * @param event mouse event user click
     */
    private changeTimeRangeSelector(event: Event): void {
        const currentTime: number = SimulatedTimeHelper.getSimulatedTime(this.getTimeDescriptor());
        if (!currentTime) return;
        let rangeTime: number = 0;
        if (this.getTimeRangeSelect()?.value) {
            try {
                rangeTime = Number.parseInt(this.getTimeRangeSelect()?.value);
            } catch (error) {
                this.getLogger()?.error(
                    'Unable to convert Time Range value to integer: ' + this.getTimeRangeSelect()?.value
                );
            }
        }
        // if time range is not defined, reinitialize it
        if (!rangeTime) {
            this.setStartTime(null);
            this.setEndTime(null);
            return;
        }

        // if time range is defined: set start and end time centered on current Time
        const startTime: number = currentTime - rangeTime / 2;
        this.setStartTime(startTime);
        const endTime: number = currentTime + rangeTime / 2;
        this.setEndTime(endTime);
    }

    /**
     * get End Time Label
     */
    public getEndTimeInput(): HTMLInputElement {
        if (!this.endTimeLabel) {
            this.endTimeLabel = TpzApplicationUI.createTextInput({ value: 'undefined', classes: ['time-line-label'] });
            TpzApplicationUI.tooltip(this.endTimeLabel, 'End time', 'end-time-tooltip');
            this.endTimeLabel.addEventListener('click', (e: MouseEvent) => this.displayEndTimeSelector(e));
            this.updateUI();
        }
        return this.endTimeLabel;
    }

    /**
     * f(x) = e^(x.ln2)-1
     * f(0) = 0, f(1) = 1
     */
    private valueToTimeFactor(value: number): number {
        if (value < 0) return 0;
        return Math.exp(value * Math.LN2) - 1;
    }

    /**
     * f-1(x) = ln(x+1)/ ln2
     * f(0) = 0, f(1) = 1
     */
    private timeFactorToValue(factor: number): number {
        if (factor < 0) return 0;
        return Math.log(factor + 1) / Math.LN2;
    }

    /**
     * get TimeFactor Label
     */
    public getTimeFactorInput(): HTMLInputElement {
        const me: TimePickerView = this;
        if (!this.timeFactorLabel) {
            this.timeFactorLabel = TpzApplicationUI.createTextInput({ value: '1', classes: ['time-line-label'] });
            TpzApplicationUI.tooltip(this.timeFactorLabel, 'Time factor', 'time-factor-tooltip');
            this.timeFactorLabel.addEventListener('focus', () => {
                me.timeFactorLabel.value = '' + me.getTimeFactor();
            });
            this.timeFactorLabel.addEventListener('change', () => {
                const value: number = Number.parseFloat(me.timeFactorLabel.value);
                me.setTimeFactor(value);
                me.timeFactorLabel.blur(); // remove focus
            });
            this.updateUI();
        }
        return this.timeFactorLabel;
    }

    /**
     * Start time getter. If null return the current time
     */
    public getStartTime(): number {
        return SimulatedTimeHelper.getSimulatedStartTimeRange(this.getTimeDescriptor());
    }

    /**
     * End time getter. If null return the end time + time Duration
     */
    public getEndTime(): number {
        return SimulatedTimeHelper.getSimulatedEndTimeRange(this.getTimeDescriptor());
    }

    /**
     * return the time range duration in milliseconds selected in the combobox
     */
    public getTimeRangeDurationFromUI(): number {
        const selectedTimeRangeIndex: number = this.getTimeRangeSelect().selectedIndex;
        if (selectedTimeRangeIndex < 0) return 0;
        return Number(this.getTimeRangeSelect().options[selectedTimeRangeIndex]?.value);
    }

    /**
     * start time setter
     */
    public setStartTime(time: number, fireEvent: boolean = true): void {
        if (this.getStartTime() === time) return;
        this.getTimeDescriptor().rangeStartTime = time;
        const currentTime: number = this.getCurrentSimulatedTime();
        if (currentTime < this.getStartTime()) this.stopPlaying();
        if (fireEvent) this.fireTimeDescriptionEvent();
        this.updateUI();
    }

    /**
     * end time setter
     */
    public setEndTime(time: number, fireEvent: boolean = true): void {
        if (this.getEndTime() === time) return;
        this.getTimeDescriptor().rangeEndTime = time;
        const currentTime: number = this.getCurrentSimulatedTime();
        if (currentTime > this.getEndTime()) this.stopPlaying();
        if (fireEvent) this.fireTimeDescriptionEvent();
        this.updateUI();
    }

    /**
     * Start playing simulated time at current Time. If current time is not set, use Start time
     */
    public startPlaying(): void {
        this.setTimeDescriptor({
            realStartTime: new Date().getTime(),
            simulatedStartTime: this.getCurrentSimulatedTime(),
            simulatedTimeFactor: this.getTimeFactor(),
            rangeStartTime: this.getStartTime(),
            rangeEndTime: this.getEndTime(),
            defaultRangeTime: null,
            playing: true
        });
    }

    /**
     * Callback called when
     */
    public onTimeUpdate(): void {
        this.updateCurrentTime();
        this.updateUI();
    }
    /**
     * return true if simulated time is currently playing
     */
    public isPlaying(): boolean {
        return this.timeRunner?.isLooping();
        //return this.timeDescriptor?.playing;
    }

    /**
     * Start or Stop playing simulated Time
     */
    private togglePlaying(): void {
        if (this.isPlaying()) this.stopPlaying();
        else this.startPlaying();
    }

    /**
     * Stop playing simulated time at current Time. Stores current time
     */
    private stopPlaying(): void {
        // stores state at which time Descriptor is stopped
        this.setTimeDescriptor({
            realStartTime: new Date().getTime(),
            simulatedStartTime: this.getCurrentSimulatedTime(),
            simulatedTimeFactor: this.getTimeFactor(),
            rangeStartTime: this.getStartTime(),
            rangeEndTime: this.getEndTime(),
            defaultRangeTime: null,
            playing: false
        });
    }

    /**
     * Current time getter. If null return the start time
     */
    public getCurrentSimulatedTime(): number {
        return SimulatedTimeHelper.getSimulatedTime(this.timeDescriptor);
    }

    /**
     * Get time descriptor
     */
    private getTimeDescriptor(): SimulatedTimeDescriptor {
        if (!this.timeDescriptor) {
            this.setTimeDescriptor(SimulatedTimeHelper.createNowTimeDescriptor(this.getTimeRangeDurationFromUI()));
        }
        return this.timeDescriptor;
    }

    /**
     * Set time descriptor and update display
     */
    private setTimeDescriptor(timeDescriptor: SimulatedTimeDescriptor, sendEvent: boolean = true): void {
        if (SimulatedTimeHelper.timeDescriptorEquals(this.timeDescriptor, timeDescriptor)) return;
        this.timeDescriptor = { ...timeDescriptor };
        this.getTimeRunner().setTimeDescriptor(this.timeDescriptor);
        if (sendEvent) this.fireTimeDescriptionEvent();
        this.updateUI();
    }

    /**
     * Time Runner Lazy getter
     */
    public getTimeRunner(): SimulatedTimeRunner {
        if (!this.timeRunner) {
            this.timeRunner = SimulatedTimeHelper.createTimeRunner(
                this.getTimeRunnerId(),
                this.getApplication(),
                this.updateUI.bind(this),
                TimePickerView.TIMERUNNER_UPDATE_RATE,
                this.getTimeDescriptor()
            );
        }
        return this.timeRunner;
    }

    /**
     * current time setter
     */
    public setCurrentTime(time: number, fireEvent: boolean = true): void {
        this.stopPlaying();
        this.setTimeDescriptor({
            realStartTime: new Date().getTime(),
            simulatedStartTime: time,
            simulatedTimeFactor: this.getTimeFactor(),
            rangeStartTime: this.getStartTime(),
            rangeEndTime: this.getEndTime(),
            defaultRangeTime: null,
            playing: false
        });
    }

    /**
     * return the time factor
     * Default time factor is 1
     */
    public getTimeFactor(): number {
        if (this.getTimeDescriptor() === null) return 1;
        return this.getTimeDescriptor().simulatedTimeFactor;
    }

    /**
     * time factor setter
     */
    public setTimeFactor(timeFactor: number, fireEvent: boolean = false): void {
        const timeDescriptor: SimulatedTimeDescriptor = this.getTimeDescriptor();
        if (timeDescriptor === null) {
            this.getLogger()?.warn('Cannot set time factor. Time Descriptor is not set...');
            return;
        }
        if (timeDescriptor.simulatedTimeFactor === timeFactor) return;
        this.stopPlaying();
        this.setTimeDescriptor({
            realStartTime: new Date().getTime(),
            simulatedStartTime: SimulatedTimeHelper.getSimulatedTime(timeDescriptor),
            simulatedTimeFactor: timeFactor,
            rangeStartTime: this.getStartTime(),
            rangeEndTime: this.getEndTime(),
            defaultRangeTime: null,
            playing: false
        });
    }

    /**
     * time factor knob lazy getter
     */
    private getTimeFactorKnob(): Knob {
        const me: TimePickerView = this;
        if (!this.timeFactorKnob) {
            this.timeFactorKnob = new Knob({ radius: 16, minValue: 0, maxValue: 10, sensibility: 0.25, nbTicks: 40 });
            TpzApplicationUI.tooltip(
                this.timeFactorKnob.getUI(),
                'Time Factor (drag or wheel to change)',
                'time-factor-tooltip'
            );

            this.timeFactorKnob.getEventManager().register(this.getId(), (event: KnobEvent) => {
                switch (event.type) {
                    case KnobEventType.ROLLER_CHANGED:
                        me.getTimeFactorInput().value = '(' + me.timeFactorKnob.getValue() + ')';
                        break;
                    case KnobEventType.VALUE_CHANGED:
                        me.setTimeFactor(me.valueToTimeFactor(me.timeFactorKnob.getValue()));
                        break;
                }
                return true;
            });
        }
        return this.timeFactorKnob;
    }

    /**
     * Fires the time description related to current stored time
     */
    private fireTimeDescriptionEvent(): boolean {
        if (!this.getTimeDescriptor()) return false;
        const timeEvent: TimeEventContent = {
            timeDescriptor: this.getTimeDescriptor()
        };
        this.getApplication().fireEvent(this.getId(), SimulatedTimeEventType.REQUEST_REBASE_SIMULATED_TIME, timeEvent, [
            TimePickerView.TIME_EVENT_CATEGORY
        ]);
        return true;
    }

    /**
     * Fires the time description related to a live change
     */
    private fireLiveTimeDescriptionEvent(liveTime: number): boolean {
        if (!this.getTimeDescriptor()) return false;
        // replace current time by live time
        this.getApplication().fireEvent(
            this.getId(),
            SimulatedTimeEventType.LIVE_SIMULATED_TIME,
            { liveTime: liveTime },
            [TimePickerView.TIME_EVENT_CATEGORY]
        );
        return true;
    }

    /**
     * Display a date in a label
     */
    private static displayDate(label: HTMLInputElement, time: number): void {
        if (!label) return;
        if (time === null || time === undefined || !Number.isFinite(time)) label.value = '';
        else label.value = DateFormat.format(new Date(time), 'ddd dd mmm yyyy / HH:MM:ss', true);
    }

    /**
     * Update the time picker time range combo box to reflect time range selection
     */
    private updateTimeRangeComboBox(): void {
        const startTime: number = this.getStartTime();
        const endTime: number = this.getEndTime();
        this.getTimeRangeSelect().selectedIndex = -1;
        if (!startTime || !endTime) {
            return;
        }
        const timeRangeDuration: number = endTime - startTime;
        // loop over all option to retrieve if one is matching time
        this.getConfig().timeRangeSelect?.forEach(
            (timeRangeDescriptor: TimeRangeSelectionDescriptor, index: number) => {
                // arbitrary five seconds approximation
                if (Math.abs(timeRangeDescriptor.duration - timeRangeDuration) < 5000) {
                    this.getTimeRangeSelect().selectedIndex = index;
                }
            }
        );
    }

    /**
     * Fill time UI with config time
     */
    //@Override
    public updateUI(): void {
        if (!this.mainContainer) return;
        // change start time: input
        TimePickerView.displayDate(this.getStartTimeInput(), this.getStartTime());
        // change end time: input
        TimePickerView.displayDate(this.getEndTimeInput(), this.getEndTime());
        // change time range combobox selection
        this.updateTimeRangeComboBox();
        // change current time: input & time line
        this.updateCurrentTime();

        // change time factor: Input & Knob
        this.getTimeFactorInput().value = 'x' + this.getTimeFactor();
        this.getTimeFactorKnob().setValue(this.timeFactorToValue(this.getTimeFactor()));

        //update Playing state
        if (this.isPlaying()) {
            if (this.getPlayButton().innerHTML !== TimePickerView.PLAY_BUTTON_STOP_LABEL) {
                this.getPlayButton().innerHTML = TimePickerView.PLAY_BUTTON_STOP_LABEL;
            }
            if (!this.getPlayButton().classList.contains(TimePickerView.PLAY_BUTTON_PLAYING_CLASS)) {
                this.getPlayButton().classList.add(TimePickerView.PLAY_BUTTON_PLAYING_CLASS);
            }
            if (this.getRunnerStateLabel().innerText !== TimePickerView.TIME_RUNNING_LABEL) {
                this.getRunnerStateLabel().innerText = TimePickerView.TIME_RUNNING_LABEL;
            }
        } else {
            if (this.getPlayButton().innerHTML !== TimePickerView.PLAY_BUTTON_PLAY_LABEL) {
                this.getPlayButton().innerHTML = TimePickerView.PLAY_BUTTON_PLAY_LABEL;
            }
            if (this.getPlayButton().classList.contains(TimePickerView.PLAY_BUTTON_PLAYING_CLASS)) {
                this.getPlayButton().classList.remove(TimePickerView.PLAY_BUTTON_PLAYING_CLASS);
            }
            if (this.getRunnerStateLabel().innerText !== TimePickerView.TIME_STOPPED_LABEL) {
                this.getRunnerStateLabel().innerText = TimePickerView.TIME_STOPPED_LABEL;
            }
        }
        // update time range selection
        // this.fillTimeRangeSelect();
        return super.updateUI();
    }

    /**
     * get Time Picker Displayer main container
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Hide the current time input selector panel
     */
    private displayCurrentTimeSelector(event: MouseEvent): void {
        document.body.appendChild(this.getCurrentTimeSelector().getUI());
        this.getCurrentTimeSelector().setDate(new Date(this.getCurrentSimulatedTime()));
        this.getCurrentTimeSelector().getUI().style.left =
            window.screen.width / 2 - this.getCurrentTimeSelector().getUI().clientWidth / 2 + 'px';
        this.getCurrentTimeSelector().getUI().style.top =
            window.screen.height / 2 - this.getCurrentTimeSelector().getUI().clientHeight / 2 + 'px';
    }

    /**
     * Hide the current time input selector panel
     */
    private hideCurrentTimeSelector(): void {
        const ui: HTMLElement = this.getCurrentTimeSelector().getUI();
        if (document.body.contains(ui)) document.body.removeChild(ui);
    }

    /**
     * current time input selector lazy getter
     */
    private getCurrentTimeSelector(): RollerDate {
        const me: TimePickerView = this;
        if (!this.currentTimeSelector) {
            this.currentTimeSelector = new RollerDate({});
            // listen to time selector events
            this.getCurrentTimeSelector()
                .getEventManager()
                .register(this.getId(), (event: RollerEvent) => {
                    switch (event.type) {
                        case RollerEventType.OK_ACTION:
                            this.setCurrentTime(this.getCurrentTimeSelector().readDate().getTime());
                            me.hideCurrentTimeSelector();
                            break;
                        case RollerEventType.CANCEL_ACTION:
                            me.hideCurrentTimeSelector();
                            break;
                    }
                    return true;
                });
        }
        return this.currentTimeSelector;
    }

    /**
     * Hide the start time input selector panel
     */
    private displayStartTimeSelector(event: MouseEvent): void {
        document.body.appendChild(this.getStartTimeSelector().getUI());
        let startTime: number = this.getStartTime();
        if (!Number.isFinite(startTime)) {
            startTime =
                SimulatedTimeHelper.getSimulatedStartTime(this.getTimeDescriptor()) -
                this.getTimeRangeDurationFromUI() / 2;
        }
        this.getStartTimeSelector().setDate(new Date(startTime));

        this.getStartTimeSelector().getUI().style.left =
            window.screen.width / 2 - this.getStartTimeSelector().getUI().clientWidth / 2 + 'px';
        this.getStartTimeSelector().getUI().style.top =
            window.screen.height / 2 - this.getStartTimeSelector().getUI().clientHeight / 2 + 'px';
    }

    /**
     * Hide the start time input selector panel
     */
    private hideStartTimeSelector(): void {
        const ui: HTMLElement = this.getStartTimeSelector().getUI();
        if (document.body.contains(ui)) document.body.removeChild(ui);
    }

    /**
     * start time input selector lazy getter
     */
    private getStartTimeSelector(): RollerDate {
        const me: TimePickerView = this;
        if (!this.startTimeSelector) {
            this.startTimeSelector = new RollerDate({});
            // listen to time selector events
            this.getStartTimeSelector()
                .getEventManager()
                .register(this.getId(), (event: RollerEvent) => {
                    switch (event.type) {
                        case RollerEventType.OK_ACTION:
                            this.setStartTime(this.getStartTimeSelector().readDate().getTime());
                            me.hideStartTimeSelector();
                            break;
                        case RollerEventType.CANCEL_ACTION:
                            me.hideStartTimeSelector();
                            break;
                    }
                    return true;
                });
        }
        return this.startTimeSelector;
    }

    /**
     * end time input selector lazy getter
     */
    private getEndTimeSelector(): RollerDate {
        if (!this.endTimeSelector) {
            this.endTimeSelector = new RollerDate({});
            const me: TimePickerView = this;
            // listen to time selector events
            this.getEndTimeSelector()
                .getEventManager()
                .register(this.getId(), (event: RollerEvent) => {
                    switch (event.type) {
                        case RollerEventType.OK_ACTION:
                            this.setEndTime(this.getEndTimeSelector().readDate().getTime());
                            me.hideEndTimeSelector();
                            break;
                        case RollerEventType.CANCEL_ACTION:
                            me.hideEndTimeSelector();
                            break;
                    }
                    return true;
                });
        }
        return this.endTimeSelector;
    }

    /**
     * Display the end time input selector panel
     * @param event mouse event user click
     */
    private displayEndTimeSelector(event: MouseEvent): void {
        document.body.appendChild(this.getEndTimeSelector().getUI());
        let endTime: number = this.getEndTime();
        if (!Number.isFinite(endTime)) {
            endTime =
                SimulatedTimeHelper.getSimulatedStartTime(this.getTimeDescriptor()) +
                this.getTimeRangeDurationFromUI() / 2;
        }
        this.getEndTimeSelector().setDate(new Date(endTime));

        this.getEndTimeSelector().getUI().style.left =
            window.screen.width / 2 - this.getEndTimeSelector().getUI().clientWidth / 2 + 'px';
        this.getEndTimeSelector().getUI().style.top =
            window.screen.height / 2 - this.getEndTimeSelector().getUI().clientHeight / 2 + 'px';
    }

    /**
     * Hide the start end input selector panel
     */
    private hideEndTimeSelector(): void {
        const ui: HTMLElement = this.getEndTimeSelector()?.getUI();
        if (document.body.contains(ui)) document.body.removeChild(ui);
    }

    /**
     * Invalidate UI components
     */
    public invalidateUI(): void {
        this.topDiv = null;
        this.centerDiv = null;
        this.bottomDiv = null;
        this.controlDiv = null;
        this.timeLineContainer = null;
        this.timeLineDiv = null;
        this.timeLineProgressBarDiv = null;
        this.startTimeMarkerDiv = null;
        this.endTimeMarkerDiv = null;
        this.currentTimeInput = null;
        this.currentTimeSelector = null;
        this.timeFactorLabel = null;
        this.runnerStateLabel = null;
        this.startTimeSelector = null;
        this.endTimeSelector = null;
        this.startTimeLabel = null;
        this.endTimeLabel = null;
        this.playButton = null;
        this.timeFactorKnob = null;
        if (this.timeRunner) this.timeRunner.stopRunner();
        this.timeRunner = null;
        super.invalidateUI();
    }

    /**
     * Update Time picker date/time
     * @param force reload even if already loaded
     */
    public requestUpdate(force: boolean = false): boolean {
        const me: TimePickerView = this;
        // display current Time Descriptor
        this.updateUI();
        return true;
    }

    /**
     * Listen to Application events
     * @param event application event
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        //This Time Picker receives events from itself
        switch (event.type) {
            case SimulatedTimeEventType.REBASE_SIMULATED_TIME:
                {
                    TpzApplicationEventType.checkEvent(event, 'timeDescriptor');
                    const timeEvent: TimeEventContent = event.content as TimeEventContent;
                    if (timeEvent?.timeDescriptor) this.setTimeDescriptor(timeEvent?.timeDescriptor, false);
                }
                break;
        }
        return super.onApplicationEvent(event);
    }
}

/**
 * Factory handling TimePickerView creation
 */
export class TimePickerViewFactory extends TpzApplicationFactory {
    private static readonly TIMEPICKER_VIEW_FACTORY_TYPE: string = 'TimePickerViewFactory';

    /** Constructor */
    constructor(application: TpzApplication) {
        super(TimePickerViewFactory.TIMEPICKER_VIEW_FACTORY_TYPE, application);
        this.addHandledItem(
            TimePickerView.TIMEPICKER_VIEW_TYPE,
            this.createTimePickerView.bind(this),
            defaultTimePickerViewConfig
        );
        this.addCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY);
    }

    /** TimePickerView creator function */
    private createTimePickerView(config: TimePickerViewConfig): Promise<TimePickerView> {
        return Promise.resolve(new TimePickerView(config, this.getApplication()));
    }
}
