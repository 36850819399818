/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ProgressLoader, ProgressLoaderConfig } from './progress-loader';
import { BodyProgressLoader, BodyProgressLoaderConfig } from './progress-loader-body';
import { HtmlProgressLoaderConfig, HtmlProgressLoader } from './progress-loader-htmlelement';

/**
 * Abstract class for all ProgressLoader factories
 * Factories are able to create ProgressLoader instances
 */
export abstract class ProgressLoaderFactory {
    public abstract createProgressLoader(source: any, loaderConfig: ProgressLoaderConfig): ProgressLoader;
    public abstract handleConfig(source: any): boolean;
}

/**
 * Progress Loader using a display in the 'body' tag of the HTML document
 */
export class BodyProgressLoaderFactory extends ProgressLoaderFactory {
    /**
     * Create the progress loader
     * @param loaderConfig
     */
    public createProgressLoader(source: any, loaderConfig: ProgressLoaderConfig): ProgressLoader {
        return new BodyProgressLoader(source, loaderConfig as BodyProgressLoaderConfig);
    }

    /**
     * Body Progress Loader is able to handle any type of sources
     * @param source
     */
    public handleConfig(source: HTMLElement): boolean {
        return source !== null;
    }
}

/**
 * Progress Loader using a display in the 'body' tag of the HTML document
 */
export class HtmlContainerProgressLoaderFactory extends ProgressLoaderFactory {
    /**
     * Create the progress loader
     * @param loaderConfig
     */
    public createProgressLoader(source: any, loaderConfig: ProgressLoaderConfig): ProgressLoader {
        return new HtmlProgressLoader(source as HTMLElement, loaderConfig as HtmlProgressLoaderConfig);
    }

    /**
     * Body Progress Loader is able to handle any type of sources
     * @param source
     */
    public handleConfig(source: HTMLElement): boolean {
        if (source == null) return false;
        return typeof source['appendChild'] !== 'undefined';
    }
}
