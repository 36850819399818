/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplicationUI } from '../../../tpz-application-ui';

export class Notification {
    private mainContainer: HTMLDivElement = null;
    private contentContainer: HTMLDivElement = null;
    private dateContainer: HTMLDivElement = null;
    private startTime: number = null;
    private readonly parentId: string = null;
    private timeoutCloseId: any = null;
    private closeContainer: HTMLDivElement = null;

    public static readonly NOTIFICATION_HIDE: string = 'hidden';

    /**
     * Constructor
     */
    constructor(parentId: string) {
        this.parentId = parentId;
    }

    /**
     * open a notification and display it
     * @param type
     * @param content
     */
    public open(type: string, content: string, autoCloseDelay: number = 5000): void {
        this.startTime = new Date().getTime();
        // show div
        const parent: HTMLElement = document.getElementById(this.parentId);
        if (parent && this.getMainContainer()) {
            this.getMainContainer().classList.add(Notification.NOTIFICATION_HIDE);
            parent.appendChild(this.getMainContainer());
            if (this.timeoutCloseId) this.close();
            if (autoCloseDelay > 0) this.timeoutCloseId = window.setTimeout(this.close.bind(this), autoCloseDelay);
            this.setContent(type, content);
            this.getMainContainer().classList.remove(Notification.NOTIFICATION_HIDE);
        }
    }

    /**
     * close a notification
     */
    public close(): void {
        const me = this;
        if (!this.mainContainer) return;
        // delete element at the end of the transition
        this.mainContainer.classList.add(Notification.NOTIFICATION_HIDE);
        if (this.timeoutCloseId) {
            window.clearTimeout(this.timeoutCloseId);
            this.timeoutCloseId = null;
        }
        window.setTimeout(() => {
            const parent: HTMLElement = document.getElementById(me.parentId);
            if (me.mainContainer && parent?.contains(me.mainContainer)) parent.removeChild(me.mainContainer);
        }, 1000);
    }

    /**
     * get close button Container
     */
    public getCloseContainer(): HTMLDivElement {
        if (!this.closeContainer) {
            this.closeContainer = TpzApplicationUI.createDiv({ classes: ['close'] });
            this.closeContainer.innerHTML = 'X';
            this.closeContainer.addEventListener('click', this.close.bind(this));
        }
        return this.closeContainer;
    }

    /**
     * Content container lazy getter
     */
    private getContentContainer(): HTMLDivElement {
        if (!this.contentContainer) {
            this.contentContainer = TpzApplicationUI.createDiv({ classes: ['content'] });
        }
        return this.contentContainer;
    }

    /**
     * Date container lazy getter
     */
    private getDateContainer(): HTMLDivElement {
        if (!this.dateContainer) {
            this.dateContainer = TpzApplicationUI.createDiv({ classes: ['date'] });
        }
        return this.dateContainer;
    }

    /**
     * Main container lazy getter
     */
    private getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv(
                { classes: [Notification.NOTIFICATION_HIDE] },
                this.getDateContainer(),
                this.getContentContainer(),
                this.getCloseContainer()
            );
        }
        return this.mainContainer;
    }

    /**
     * Display the notification div in parent
     */
    public setContent(type: string, content: string): void {
        this.getContentContainer().classList.add(type);
        this.getContentContainer().innerHTML = content;
        const date: Date = new Date(this.startTime);
        this.getDateContainer().innerHTML =
            '[' + date.getHours() + ':' + date.getMinutes() + '.' + date.getSeconds() + ']';
    }
}
