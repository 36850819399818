/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemFactory } from '../../tpz-catalog/tpz-item-factory';
import { TpzApplication } from '../tpz-application-core';
import { TpzAddOn, TpzAddOnDescriptor } from './addons-core';

/**
 * An AddOn Item adds an item factory into Application factory manager
 * This factory allows to generate a new Item type from the application
 * It doesn't generate the Item Instance, it only offers the abaility to generate it
 */
export abstract class TpzAddOnItem extends TpzAddOn {
    /**
     * Id
     */
    constructor(descriptor: TpzAddOnDescriptor) {
        super(descriptor);
    }

    /**
     * Starting an ItemAddon adds a factory to the application factory manager
     * @param app
     */
    public onStart(): Promise<void> {
        const app: TpzApplication = this.getApplication();
        if (!app) throw new Error('Application instance must be non null when starting TpzAddOnItem #' + this.getId());
        return super.onStart().finally(() => {
            app.registerItemFactory(this.getItemFactory(this.getApplication()));
        });
    }

    /**
     * Factory provided by this plugin
     */
    public abstract getItemFactory(app: TpzApplication): ItemFactory;
}

/**
 * An AddOn Item adds an item factory into Application factory manager
 * This factory allows to generate a new Item type from the application
 * It doesn't generate the Item Instance, it only offers the abaility to generate it
 */
export abstract class TpzAddOnItems extends TpzAddOn {
    /**
     * Id
     */
    constructor(descriptor: TpzAddOnDescriptor) {
        super(descriptor);
    }

    /**
     * Starting an ItemAddon adds some factories to the application factory manager
     * @param app
     */
    public onStart(): Promise<void> {
        const app: TpzApplication = this.getApplication();
        if (!app) throw new Error('Application instance must be non null when starting TpzAddOnItem #' + this.getId());
        return super.start(app).then(() => {
            app.registerItemFactories(this.getItemFactories(app));
        });
    }

    /**
     * Factory provided by this plugin
     */
    public abstract getItemFactories(app: TpzApplication): ItemFactory[];
}
