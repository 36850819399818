/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { OutgoingPingMessageContent } from '../server/messages/ping-pong-message-content';
import { TpzApplicationComponent } from '../tpz-application-component';
import { TpzApplicationEvent, TpzApplicationEventCategory, TpzApplicationEventType } from '../tpz-application-event';
import { FromServerMessageProtocol } from './from-server-protocol';
import { TpzMessageHelper } from './message-helper';
import {
    TpzClientSessionInformation,
    TpzServerHelper,
    TpzServerIncomingMessage,
    TpzServerInformation,
    TpzClientOutgoingMessage
} from './server';
import { TpzSessionManager } from './session-manager';

export interface PingPongResult {
    sessionId: string;
    pingId: string;
    clientEmissionTime: number;
    serverEmissionTime: number;
    clientReceptionTime: number;
}

export interface ReconnectionRetry {
    numberOfRetries: number;
    delay: number;
}

/**
 * Client Session Configuration
 */
export interface TpzClientSessionConfig {
    pingPongDelay: number; // (ms) time after receiving a PONG response before resending a PING
    pingPongSlowResponse: number; // (ms) timeout for slow response warning notification (time between ping and pong response)
    pingPongResponseTimeout: number; // (ms) time after which the session will be closed due to connection loss
    pingPongResultMaxCount: number; // max number of stored ping-pong results
    reconnectionRetries?: ReconnectionRetry[];
}

/**
 * Default client session configuration
 */
const defaultTpzClientSessionConfig: TpzClientSessionConfig = {
    pingPongDelay: 30000,
    pingPongSlowResponse: 5000,
    pingPongResponseTimeout: 10000,
    pingPongResultMaxCount: 100,
    reconnectionRetries: [
        { numberOfRetries: 10, delay: 1000 },
        { numberOfRetries: 5, delay: 10000 }
    ]
};

/**
 * Client Session
 */
export class TpzClientSession extends TpzApplicationComponent {
    private readonly sessionInformation: TpzClientSessionInformation = null;
    private connectSessionPromise: Promise<void> = null; // protect from connecting twice when connection is in progress
    private serverInformation: TpzServerInformation = null;
    private pingPongTimerId: any = null; // ping pong timer ID
    private slowPingPongTimerId: any = null; // ping pong timer ID for slow connection
    private timeoutPingPongTimerId: any = null; // ping pong timer ID for timeout connection
    private config: TpzClientSessionConfig = defaultTpzClientSessionConfig;
    private webSocket: WebSocket = null;
    private readonly sentPings: { [id: string]: OutgoingPingMessageContent } = {}; // pings waiting for a response
    private pingPongResults: PingPongResult[] = null; // cyclic array with pingPongResultCounter has current pointer in array
    private pingPongResultCounter: number = 0; // first free cell in pingPongResults

    private reconnectionMajor: number = null;
    private reconnectionMinor: number = null;
    private reconnectionRetryTimeoutId: any = null;

    /**
     * Constructor
     * @param info client session information
     */
    constructor(app: TpzApplication, info: TpzClientSessionInformation) {
        super(app, info?.sessionId);
        if (!app) throw new Error('Cannot create a new client session with no Application');
        if (!info) throw new Error('Cannot create a new client session with no Client information');
        if (!info.sessionId) throw new Error('Cannot create a new client session with an invalid session Id');
        this.sessionInformation = info;
    }

    /**
     * information getter
     */
    public getSessionInformation(): TpzClientSessionInformation {
        return this.sessionInformation;
    }

    /**
     * session configuration getter
     */
    public getConfig(): TpzClientSessionConfig {
        return this.config;
    }

    /**
     * session configuration getter
     */
    public setConfig(config: TpzClientSessionConfig): void {
        if (!config) throw new Error('Do not allow a null session configuration');
        this.config = config;
    }

    /**
     * information getter
     */
    public getServerInformation(): TpzServerInformation {
        return this.serverInformation;
    }

    /**
     * session ID getter. Helper method (this.getSessionInformation()?.sessionId)
     */
    public getSessionId(): string {
        return this.getSessionInformation()?.sessionId;
    }

    /**
     * User ID getter. Helper method (this.getSessionInformation()?.USERId)
     */
    public getUserId(): string {
        return this.getSessionInformation()?.userId;
    }

    /**
     * Server URL getter. Helper method (this.getSessionInformation()?.serverURL)
     */
    public getServerURL(): string {
        return this.getServerInformation()?.serverURL;
    }

    /**
     * User data get service URL getter. Helper method (this.getServerInformation()?.userDataGetURL)
     */
    public getUserDataGetURL(): string {
        return this.getServerInformation()?.userStateGetURL;
    }

    /**
     * User data set service URL getter. Helper method (this.getServerInformation()?.userDataSetURL)
     */
    public getUserDataSetURL(): string {
        return this.getServerInformation()?.userStateSetURL;
    }

    /**
     * User data summary service URL getter. Helper method (this.getServerInformation()?.userDataSummaryURL)
     */
    public getUserDataSummaryURL(): string {
        return this.getServerInformation()?.userDataSummaryURL;
    }

    /**
     * Send a message to the server containing the session information (mainly the ID)
     */
    private sendClientIdentificationMessage(): void {
        const clientidentificationMessage: TpzClientOutgoingMessage =
            TpzMessageHelper.createClientIdentificationOutgoingMessage(this.getApplication(), this);
        this.sendMessageToServer(clientidentificationMessage);
    }

    /**

    /**
     * Start the ping pong timers
     */
    private startPingPongProcess(): void {
        this.getLogger().debug(`program ping event in ${this.getConfig().pingPongDelay / 1000}s.`);
        this.programPingMessage();
    }

    /**
     * Terminate the ping pong timers
     */
    private terminatePingPongProcess(): void {
        if (this.pingPongTimerId) window.clearTimeout(this.pingPongTimerId);
        this.pingPongTimerId = null;
        if (this.slowPingPongTimerId) window.clearTimeout(this.slowPingPongTimerId);
        this.slowPingPongTimerId = null;
        if (this.timeoutPingPongTimerId) window.clearTimeout(this.timeoutPingPongTimerId);
        this.timeoutPingPongTimerId = null;
    }

    /**
     * Program a ping message to be send
     */
    private programPingMessage(): void {
        // check if a ping is already programmed
        if (this.pingPongTimerId) return;
        // check websocket connection
        if (!this.isWebSocketConnected()) return;
        this.pingPongTimerId = window.setTimeout(() => {
            this.pingPongTimerId = null;
            this.sendPingMessage();
        }, this.getConfig().pingPongDelay);
    }

    /**
     * send immediately a ping via websocket and wait for a pong response.
     */
    public sendPingMessage(): void {
        const pingMessage: TpzClientOutgoingMessage = TpzMessageHelper.createPingOutgoingMessage(this.getApplication());
        try {
            this.sendMessageToServer(pingMessage);
            this.sentPings[pingMessage.content.pingId] = pingMessage.content;
            // set slow ping pong event
            if (this.slowPingPongTimerId) window.clearTimeout(this.slowPingPongTimerId);
            this.slowPingPongTimerId = window.setTimeout(
                () => this.slowConnectionCallback(),
                this.getConfig().pingPongSlowResponse
            );
            // set timeout event
            if (this.timeoutPingPongTimerId) window.clearTimeout(this.timeoutPingPongTimerId);
            this.timeoutPingPongTimerId = window.setTimeout(
                () => this.timeoutConnectionCallback(),
                this.getConfig().pingPongResponseTimeout
            );
        } catch (reason: any) {
            this.getLogger().error(`An error occured sending ping message from session #${this.getId()}`, reason);
        }
    }

    /**
     * Action performed when slow connection time is reached
     */
    private slowConnectionCallback(): void {
        this.getApplication().sendNotification(
            'WARN',
            `Server seems to be slow... It hasn't responded to last PING for ${
                this.getConfig().pingPongSlowResponse / 1000
            }s.`
        );
    }

    /**
     * Action performed when timeout connection time is reached
     */
    private timeoutConnectionCallback(): void {
        this.getApplication().sendNotification(
            'ERROR',
            `Server hasn't responded to last PING for ${this.getConfig().pingPongResponseTimeout / 1000}s.`
        );
        this.getApplication().setServerConnectionError(true);
        if (this.getApplication().getConfig().autoConnect) {
            this.reconnectionToServer(this.getConfig().reconnectionRetries);
        } else {
            this.getApplication().sendNotification('WARN', "Client doesn't want to autoConnect to server");
            this.getApplication().sendNotification('ERROR', 'Connection to server is definitely lost');
        }
    }

    /**
     * Trying to reconnect to websocket several times, defined by the param reconnectionRetries
     * @param reconnectionRetries
     */
    private reconnectionToServer(reconnectionRetries: ReconnectionRetry[]): void {
        // checking if reconnections major and minor are existing
        if (!this.reconnectionMajor) this.reconnectionMajor = 0;
        if (!this.reconnectionMinor) this.reconnectionMinor = 0;

        const majorRetry: number = this.reconnectionMajor;
        const minorRetry: number = this.reconnectionMinor;

        const tryingReconnection = () => {
            if (this.getApplication().isServerConnected()) {
                this.getApplication().sendNotification('INFO', 'Session is connected');
                this.startPingPongProcess();
                this.getApplication().setServerConnectionError(false);
                if (this.reconnectionRetryTimeoutId) clearTimeout(this.reconnectionRetryTimeoutId);
                this.reconnectionMajor = null;
                this.reconnectionMinor = null;
                this.reconnectionRetryTimeoutId = null;
                return;
            }

            this.reconnectionMinor++;
            if (reconnectionRetries[majorRetry]) {
                if (minorRetry >= reconnectionRetries[majorRetry].numberOfRetries) {
                    this.reconnectionMinor = 0;
                    this.reconnectionMajor++;
                }
            }
            if (majorRetry >= reconnectionRetries.length) {
                this.getApplication().sendNotification('ERROR', 'Connection to session is definitely lost');
                return;
            } else {
                this.connectSession();
                this.connectWebSocket(this.webSocket.url);
                this.reconnectionRetryTimeoutId = timeoutToClear;
                timeoutToClear = setTimeout(() => {
                    tryingReconnection();
                }, reconnectionRetries[majorRetry].delay);
            }
        };
        let timeoutToClear: any;
        if (this.getApplication().isServerConnectionError()) {
            timeoutToClear = setTimeout(() => tryingReconnection(), reconnectionRetries[majorRetry].delay);
        }
    }

    /**
     * start this session
     */
    public start(): Promise<void> {
        return super.start().then(() => {
            this.connectSession();
        });
    }

    /**
     * close this session
     */
    public stop(): Promise<void> {
        return super.stop().then(() => {
            if (this.pingPongTimerId) window.clearTimeout(this.pingPongTimerId);
            this.pingPongTimerId = null;
        });
    }

    /**
     * Action performed when websocket is closed
     */
    private onWebSocketDisconnection(event: CloseEvent): void {
        this.getLogger().info('Websocket closed. Disconnect client and user');
        if (event.wasClean) {
            this.getLogger().info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            this.getLogger().warn(`Websocket Connection to server ${this.getServerURL()} died`);
        }
        this.fireWebSocketDisconnected();
        this.webSocket = null;
    }

    /**
     * send the "websocket connection established" event
     */
    private fireWebSocketConnected(): void {
        this.getApplication().sendNotification('INFO', `websocket connected to ${this.getServerURL()}`);
        this.getApplication().fireEvent(this.getId(), TpzApplicationEventType.WEBSOCKET_CONNECTED, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * send the "websocket disconnection" event
     */
    private fireWebSocketDisconnected(): void {
        this.getApplication().sendNotification('INFO', `websocket disconnected from ${this.getServerURL()}`);
        this.getApplication().fireEvent(this.getId(), TpzApplicationEventType.WEBSOCKET_DISCONNECTED, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * send the "server connection established" event
     */
    private fireServerConnected(): void {
        this.getApplication().sendNotification('INFO', `server connected ${this.getServerURL()}`);
        this.getApplication().fireEvent(this.getId(), TpzApplicationEventType.SERVER_CONNECTED, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * send the "connection closed" event
     */
    private fireServerDisconnected(): void {
        this.getApplication().sendNotification('INFO', `server disconnected ${this.getServerURL()}`);
        this.getApplication().fireEvent(this.getId(), TpzApplicationEventType.SERVER_DISCONNECTED, null, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * Connect web socket
     * @param token
     * @returns
     */
    private connectWebSocket(webSocketURL: string): Promise<void> {
        const me = this;

        return new Promise((resolve, reject) => {
            if (!webSocketURL) {
                me.getLogger().error('Session Information is not set, cannot retrieve websocket URL');
                reject;
            }

            me.webSocket = new WebSocket(webSocketURL);
            me.webSocket.onopen = (ev: Event) => {
                me.getLogger().info(`[open] Connection established with ${webSocketURL}. Event type = ${ev.type}`);
                this.sendClientIdentificationMessage();
                me.fireWebSocketConnected();
                resolve();
            };
            me.webSocket.onmessage = (ev: MessageEvent) => this.receiveMessageFromServer(ev);
            me.webSocket.onclose = (ev: CloseEvent) => this.onWebSocketDisconnection(ev);
            me.webSocket.onerror = function (ev: Event) {
                reject(new Error('Error connecting to websocket ' + webSocketURL + '. ' + ev));
            };
        });
    }

    /**
     * Return true if web socket is connected
     */
    public isWebSocketConnected(): boolean {
        return this.webSocket && this.webSocket.readyState === WebSocket.OPEN;
    }

    /**
     * Fetch server information then connect to the websocket
     */
    public connectSession(): Promise<void> {
        if (this.connectSessionPromise) return this.connectSessionPromise;
        if (!this.getSessionInformation()) throw new Error('SessionInformation must be set before connecting session');
        const serverInformationURL: string = this.getSessionInformation().serverInformationURL;
        if (!serverInformationURL) throw new Error('SessionInformation has no valid server information URL');

        // fetch server information
        this.connectSessionPromise = TpzSessionManager.fetchServerInformation(
            this.getSessionInformation()?.serverInformationURL
        )
            .then((serverInformation: TpzServerInformation) => {
                this.serverInformation = serverInformation;
                this.fireEvent(TpzApplicationEventType.SERVER_INFORMATION, { serverInformation: serverInformation }, [
                    TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                ]);
                this.getApplication().setServerConnectionError(false);
                this.fireServerConnected();
                return this.connectWebSocket(serverInformation.websocketURL);
            })
            .then(() => {
                this.startPingPongProcess();
            });
        return this.connectSessionPromise;
    }

    /**
     * close the connection with the server
     * @returns
     */
    public disconnectSession(): void {
        this.connectSessionPromise = null;
        if (!this.webSocket) return;
        if (this.isWebSocketConnected()) {
            const appConfig = this.getApplication().getConfig();
            this.terminatePingPongProcess();
            this.webSocket.close(1000, 'Disconnection requested by front-end application'); // 1000 is the "normal" close code
            this.getLogger().info('Server disconnection requested');
            this.getApplication().disconnectFromServer();
            this.getApplication().setConfig({ ...appConfig, autoConnect: false });
        } else {
            this.getLogger().info('Websocket is already disconnected');
        }
    }

    /**
     * Send a message to Server via websocket
     * @param message message to be sent
     */
    private sendMessageToServer(message: TpzClientOutgoingMessage): void {
        if (!message) throw new Error('Asked to send a null message to server');
        // check message validity
        const messageInvalidityReasons: string[] = TpzServerHelper.getInvalidOutgoingMessageReasons(message);
        if (messageInvalidityReasons) {
            throw new Error(
                'Asked to send an invalid message to server: [' + messageInvalidityReasons.join(', ') + ']'
            );
        }
        if (!this.webSocket) throw new Error('websocket is invalid. Connect server First');
        // check websocket connection
        if (!this.isWebSocketConnected()) throw new Error('websocket is not connected: ' + this.webSocket.url);
        // send message
        try {
            this.webSocket.send(JSON.stringify(message));
            this.fireEvent(TpzApplicationEventType.SERVER_MESSAGE_SENT, null, [
                TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
            ]);
        } catch (reason: any) {
            this.getLogger().error('An error occurred sending server message type ' + message.type);
        }
    }

    /**
     * TreatReceived message from Server via websocket
     * @param message received message
     */
    private receiveMessageFromServer(messageEvent: MessageEvent): void {
        if (!messageEvent) {
            this.getLogger().error('Received a null message from server');
            return;
        }
        const messageAsString: string = messageEvent.data;
        if (!messageAsString) {
            this.getLogger().error('Received a null message data from server');
            return;
        }
        let message: string = null;
        try {
            message = JSON.parse(messageAsString);
        } catch (reason: any) {
            this.getLogger().error('Cannot convert message received from server to a JSON object...', reason);
            this.getLogger().error('Message = ' + messageAsString);
            throw new Error('Cannot convert message received from server to a JSON object...');
        }
        this.getApplication().sendNotification(
            'INFO',
            'Message received from server: ' + JSON.stringify(message) + ' in session #' + this.getSessionId()
        );
        // check message validity
        const validMessage: TpzServerIncomingMessage = TpzServerHelper.getValidIncomingMessage(message);
        if (!validMessage) {
            const messageInvalidityReasons: string[] = TpzServerHelper.getInvalidIncomingMessageReasons(message);
            if (messageInvalidityReasons) {
                throw new Error(
                    'Received an invalid message from server: [' + messageInvalidityReasons.join(', ') + ']'
                );
            }
        }
        // handle PONG message
        switch (validMessage.type) {
            case FromServerMessageProtocol.PONG_MESSAGE:
                {
                    this.handlePongResponse(validMessage);
                }
                break;
            default:
                {
                    // fires an event containing received message
                    this.fireEvent(TpzApplicationEventType.SERVER_MESSAGE_RECEIVED, { message: message }, [
                        TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
                    ]);
                }
                break;
        }
    }

    /**
     * Action to perform when a valid PONG is received
     */
    private handlePongResponse(pongMessage: TpzServerIncomingMessage): void {
        const clientReceptionTime: number = new Date().getTime();
        const pingSessionId: string = pongMessage.content.sessionId;
        const pingId: string = pongMessage.content.pingId;
        const serverEmissionTime: number = pongMessage.content.serverEmissionTime;
        if (!pingSessionId) throw new Error("Malformed PONG message. 'content' does not contain 'sessionId' field");
        if (!pingId) throw new Error("Malformed PONG message. 'content' does not contain 'pingId' field");
        if (!serverEmissionTime) {
            throw new Error("Malformed PONG message. 'content' does not contain 'serverEmissionTime' field");
        }
        // check session Id
        const currentSessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (pingSessionId != currentSessionId) {
            this.getLogger().warn(
                'PONG MESSAGE received from server in session #' +
                    currentSessionId +
                    ' with a containing session id #' +
                    pingSessionId +
                    ' ... Does the session changed meanwhile ?'
            );
        }
        // clear response timers
        if (this.slowPingPongTimerId) window.clearTimeout(this.slowPingPongTimerId);
        if (this.timeoutPingPongTimerId) window.clearTimeout(this.timeoutPingPongTimerId);
        // store the ping-pong result
        this.storePongResponse(pingId, serverEmissionTime, clientReceptionTime);
        // reprogram a further ping
        this.programPingMessage();
    }

    /**
     * Stores PING-PONG result
     * @param pingId ping ID (used to retrieve time at which the client sends its PING MESSAGE)
     * @param serverEmissionTime time at which the server send its PONG response
     * @param clientReceptionTime time at which the client receives the server PONG response
     */
    private storePongResponse(pingId: string, serverEmissionTime: number, clientReceptionTime: number): void {
        const initialPing: OutgoingPingMessageContent = this.sentPings[pingId];
        if (!initialPing) {
            this.getLogger().error(
                'PONG message #' + pingId + " received from server but wasn't initiated from this client..."
            );
            return;
        }
        this.addPingPongResult({
            pingId: initialPing.pingId,
            sessionId: initialPing.sessionId,
            clientEmissionTime: initialPing.clientEmissionTime,
            serverEmissionTime: serverEmissionTime,
            clientReceptionTime: clientReceptionTime
        });
    }

    /**
     * get the max number of stored ping-pong results (from config)
     */
    public getPingPongResultsMaxCount(): number {
        const pingPongResultMaxCount: number = this.getConfig().pingPongResultMaxCount;
        if (!pingPongResultMaxCount || pingPongResultMaxCount < 0) {
            return defaultTpzClientSessionConfig.pingPongResultMaxCount;
        }
        return pingPongResultMaxCount;
    }

    /**
     * Add a ping pong result (fixed size array)
     * @param pingPongResult ping-pong result to be stored
     */
    private addPingPongResult(pingPongResult: PingPongResult): void {
        if (!pingPongResult) throw new Error('Invalid Ping-Pong Result');
        const pingPongResultMaxCount: number = this.getPingPongResultsMaxCount();
        if (!this.pingPongResults) this.pingPongResults = Array(pingPongResultMaxCount).fill(null);
        this.pingPongResults[this.pingPongResultCounter] = pingPongResult;
        this.pingPongResultCounter = this.pingPongResultCounter + 1;
        if (this.pingPongResultCounter >= pingPongResultMaxCount) this.pingPongResultCounter = 0;
        this.fireEvent(TpzApplicationEventType.PING_PONG_RESULTS_UPDATED, { pingPongResult: pingPongResult }, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * get all received ping pongs.
     * recreate an raw ordered array from cyclic array
     */
    public getPingPongResults(): PingPongResult[] {
        if (!this.pingPongResults[this.pingPongResultCounter]) {
            // no cycle has been made, just return the array from 0 to currentposition
            return this.pingPongResults.slice(0, this.pingPongResultCounter);
        }
        // recompose the array in two parts
        return this.pingPongResults
            .slice(this.pingPongResultCounter)
            .concat(this.pingPongResults.slice(0, this.pingPongResultCounter));
    }

    /**
     * Treat Application Events
     * @param event application event to be treated
     * @returns true if handled
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        switch (event.type) {
            case TpzApplicationEventType.SEND_SERVER_MESSAGE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'message');
                    // TODO check if Message is well formed
                    // Create a MessageManager and register methods to check messages
                    // DO NOT use static verification as add-ons or plugin may add messages with their own functionalities...
                    this.sendMessageToServer(event.content.message);
                }
                break;
        }
        return super.onApplicationEvent(event);
    }
}
