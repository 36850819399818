/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzPlugin, TpzPluginConfig, defaultTpzPluginConfig } from '../../tpz-plugin-core';
import { TpzApplicationEvent, TpzApplicationEventType } from '../../../tpz-application-event';
import {
    SimulatedTimeDescriptor,
    SimulatedTimeHelper,
    SimulatedTimeRunner,
    SimulatedTimeEventType
} from '../../../time/simulated-time';
import { deepCopy } from '../../../tools/deep-copy';

const TIME_MANAGER_PLUGIN_TYPE: string = 'TimeManagerPluginType';

/**
 * Events fired for time changes
 */
export interface TimeEventContent {
    timeDescriptor: SimulatedTimeDescriptor;
}

/**
 * Time Manager Events categories
 */
export class TimeManagerEventCategory {
    public static readonly TIME_CATEGORY: string = 'TIME';
}

/**
 *  Plugin configuration
 */
export interface TimeManagerPluginConfig extends TpzPluginConfig {
    timeDescriptor?: SimulatedTimeDescriptor;
    refreshRate?: number; // refresh rate in real time expressed in milliseconds
}

/**
 * Default plugin configuration
 */
export const defaultTimeManagerPluginConfig: TimeManagerPluginConfig = {
    ...defaultTpzPluginConfig,
    id: 'TimeManager',
    type: TIME_MANAGER_PLUGIN_TYPE,
    refreshRate: 0, // fire a NEW_DATE event rate. if 0: never
    timeDescriptor: SimulatedTimeHelper.createNowTimeDescriptor(7200 * 1000), // 7200 * 1000 = 2hours in milliseconds
    css: defaultTpzPluginConfig.css
};

/**
 * Time Manager Plugin manages a current date/time and live evolution
 * using a refresh rate.
 * refreshRate is expressed in real time (milliseconds)
 * simulatedTimeFactor is the duration factor between real time and simulated time.
 * 1: real time and simulated time are sync, 2: simulated time is 2 times faster than real time
 */
export class TimeManagerPlugin extends TpzPlugin {
    public static readonly TIME_MANAGER_PLUGIN_TYPE: string = TIME_MANAGER_PLUGIN_TYPE; // plugin type
    private timeRunner: SimulatedTimeRunner = null; // process calling time callback regularily based on time Descriptor
    private static readonly TIMERUNNER_UPDATE_RATE: number = 1000;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: TimeManagerPluginConfig) {
        super(deepCopy(defaultTimeManagerPluginConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): TimeManagerPluginConfig {
        return super.getConfig() as TimeManagerPluginConfig;
    }

    /**
     * set simulation time
     * @param timeDescriptor time descriptor
     */
    public setTimeDescriptor(timeDescriptor: SimulatedTimeDescriptor): void {
        if (SimulatedTimeHelper.timeDescriptorEquals(this.getTimeDescriptor(), timeDescriptor)) return;
        this.getConfig().timeDescriptor = { ...timeDescriptor };
        this.getTimeRunner().setTimeDescriptor(this.getTimeDescriptor());
        this.sendTimeDescriptor();
    }

    /**
     * get the simulated time descriptor
     */
    public getTimeDescriptor(): SimulatedTimeDescriptor {
        return this.getConfig().timeDescriptor;
    }

    /**
     * Fires an event containing current time descriptor
     */
    private sendTimeDescriptor(): void {
        const rebaseTimeEvent: TimeEventContent = {
            timeDescriptor: this.getTimeDescriptor()
        };
        // restart start time to the current real time with current simulated time
        this.getLogger()?.debug(
            'fire TimeManagerPluginEvents.REBASE_SIMULATED_TIME ' + JSON.stringify(rebaseTimeEvent)
        );
        this.getApplication().fireEvent(
            this.getId(),
            SimulatedTimeEventType.REBASE_SIMULATED_TIME,
            rebaseTimeEvent,
            []
        );
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source === this.getId()) return false;
        switch (event.type) {
            case SimulatedTimeEventType.REBASE_SIMULATED_TIME:
            case SimulatedTimeEventType.REQUEST_REBASE_SIMULATED_TIME:
                TpzApplicationEventType.checkEvent(event, 'timeDescriptor');
                // rebase received by another time manager
                if (!event.content?.timeDescriptor) {
                    this.getLogger()?.warn(
                        'Receive a ' +
                            event.type +
                            ' but content does not contain a timeDescriptor : ' +
                            JSON.stringify(event.content)
                    );
                } else {
                    this.setTimeDescriptor((event.content as TimeEventContent).timeDescriptor);
                }
                break;
            case SimulatedTimeEventType.REQUEST_TIME_DESCRIPTOR:
                TpzApplicationEventType.checkEvent(event, null);
                this.sendTimeDescriptor();
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Action to perform when plugin plugs in
     */
    public onPlug(): Promise<TpzPlugin> {
        this.getTimeRunner().setTimeDescriptor(this.getTimeDescriptor());
        return super.onPlug();
    }

    /**
     * Action to perform when plugin unplugs
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onUnplug().then((plugin: TpzPlugin) => {
            this.timeRunner?.stopRunner();
            return plugin;
        });
    }

    /**
     * Time Runner Id getter
     */
    public getTimeRunnerId(): string {
        return this.getId() + '-time-runner';
    }

    /**
     * Time Runner Lazy getter
     */
    public getTimeRunner(): SimulatedTimeRunner {
        if (!this.timeRunner) {
            this.timeRunner = SimulatedTimeHelper.createTimeRunner(
                this.getTimeRunnerId(),
                this.getApplication(),
                this.updateTime.bind(this),
                TimeManagerPlugin.TIMERUNNER_UPDATE_RATE,
                this.getTimeDescriptor()
            );
        }
        return this.timeRunner;
    }

    /**
     * update something ?
     * If something has to be done each time is changing: do it there
     * FIXME: is this method necessary ? This plugin should have nothing to do when time is changing....
     */
    private updateTime(): void {
        // nothing to be done, time is simply going
    }

    /**
     * get current simulated time
     * current simulated time = ( new Date().getTime() - realStartTime) * simulatedTimeFactor + simulatedTimeStart
     */
    public getCurrentSimulatedTime(): number {
        return SimulatedTimeHelper.getSimulatedTime(this.getTimeDescriptor());
    }
}
