/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ServerSyncPluginEventCategory, ServerSyncPluginEventType, ServerSyncPluginMessageType } from './sync-event';
import { defaultTpzPluginConfig, TpzPlugin, TpzPluginConfig } from '../../plugins/tpz-plugin-core';
import { TpzApplicationCommander } from '../../tpz-application-commander';
import {
    TpzApplicationEvent,
    TpzApplicationEventCategory,
    TpzApplicationEventHelper,
    TpzApplicationEventType
} from '../../tpz-application-event';
import { TpzServerHelper, TpzServerIncomingMessage } from '../server';
import { TpzApplicationState } from '../../tpz-application-state';

const SERVER_SYNC_PLUGIN_TYPE: string = 'SERVER_SYNC_PLUGIN_TYPE';

/**
 * Sync Plugin configuration
 */
export interface ServerSyncPluginConfig extends TpzPluginConfig {}

/**
 * default values for server sync configuration
 */
export const defaultServerSyncPluginConfig: ServerSyncPluginConfig = {
    ...defaultTpzPluginConfig,
    type: SERVER_SYNC_PLUGIN_TYPE,

    css: defaultTpzPluginConfig.css.concat('css/sync.css')
};

/**
 * Server Sync plugin is used to manage the synchronization between multiple ToPaZ FrontEnd connected to the same server
 * in MASTER mode: all events are send to the server then dispatched to all slaves
 * in SLAVE mode: all MASTER events are received from the server
 */
export class ServerSyncPlugin extends TpzPlugin {
    private master: boolean = null;
    private slave: boolean = null;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: ServerSyncPluginConfig) {
        super({ ...defaultServerSyncPluginConfig, ...config });
    }

    /**
     * Config getter specialization
     */
    public getConfig(): ServerSyncPluginConfig {
        return super.getConfig() as ServerSyncPluginConfig;
    }

    /**
     * Add factory to application factory manager
     * @param app
     */
    public onStart(): Promise<void> {
        return Promise.resolve().then(() => {
            this.master = false;
            this.slave = false;
            this.loadCss();
            this.fireSyncState();
        });
    }

    /**
     * return true if this application is in MASTER mode
     */
    public isMaster(): boolean {
        return this.master;
    }

    /**
     * return true if this application is in SLAVE mode
     */
    public isSlave(): boolean {
        return this.slave;
    }

    /**
     * Request MASTER mode
     */
    public requestMaster(mode: boolean): void {
        this.fireEvent(ServerSyncPluginEventType.MASTER_MODE_REQUEST, { mode: mode }, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * Request SLAVE mode
     */
    public requestSlave(mode: boolean): void {
        this.fireEvent(ServerSyncPluginEventType.SLAVE_MODE_REQUEST, { mode: mode }, [
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        ]);
    }

    /**
     * Treat a change of master mode. Send a message MASTER_MODE_REQUEST_MESSAGE to the server
     * and wait for server answer
     * if mode is the same as the existing one: do nothing
     * if mode is true: send a request to the server
     * if mode is false: stop master mode immediately
     * @param mode true/false
     */
    private treatMasterModeRequest(mode: boolean): void {
        if (mode == this.isMaster()) return;
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            this.getApplication()
                .getLogger()
                .warn(
                    'Cannot request MASTER mode without a valid client session. Please connect to the server and re-launch request.'
                );
            return;
        }
        if (mode) {
            TpzApplicationCommander.sendServerMessage(
                this.getApplication(),
                ServerSyncPluginMessageType.MASTER_MODE_REQUEST_MESSAGE,
                { sessionId: sessionId, mode: mode }
            );
        } else {
            this.setMasterMode(false);
        }
    }

    /**
     * Treat a request of slave mode.
     * if mode is the same as the existing one: do nothing
     * if mode is true: start slave mode immediately
     * if mode is false: stop slave mode immediately
     * @param mode true/false
     */
    private treatSlaveModeRequest(mode: boolean): void {
        if (mode === this.isSlave()) return;
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            this.getApplication()
                .getLogger()
                .warn(
                    'Cannot request SLAVE mode without a valid client session. Please connect to the server and re-launch request.'
                );
            return;
        }
        this.setSlaveMode(mode);
    }

    /**
     * Change MASTER mode (sent by the server)
     */
    private setMasterMode(masterMode: boolean): void {
        if (masterMode === this.isMaster()) return;
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            this.getApplication()
                .getLogger()
                .warn(
                    'Cannot set MASTER mode without a valid client session. Please connect to the server and re-launch request.'
                );
            return;
        }
        this.master = masterMode;
        if (this.isMaster()) {
            this.getApplication().sendNotification('INFO', 'MASTER mode ON');
            this.fireEvent(ServerSyncPluginEventType.MASTER_MODE_ON, { sessionId: sessionId }, []);
            // disable slave when master on
            this.setSlaveMode(false);
        } else {
            this.getApplication().sendNotification('INFO', 'MASTER mode OFF');
            this.fireEvent(ServerSyncPluginEventType.MASTER_MODE_OFF, { sessionId: sessionId }, []);
        }
    }

    /**
     * Change SLAVE mode (sent by the server)
     */
    private setSlaveMode(slaveMode: boolean): void {
        if (slaveMode === this.isSlave()) return;
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            this.getApplication().getLogger().error('Cannot set SLAVE mode without a valid client session');
            return;
        }
        this.slave = slaveMode;
        if (this.isSlave()) {
            this.getApplication().sendNotification('INFO', 'SLAVE mode ON');
            this.fireEvent(ServerSyncPluginEventType.SLAVE_MODE_ON, { sessionId: sessionId }, []);
            // disable master when slave on
            this.setMasterMode(false);
        } else {
            this.getApplication().sendNotification('INFO', 'SLAVE mode OFF');
            this.fireEvent(ServerSyncPluginEventType.SLAVE_MODE_OFF, { sessionId: sessionId }, []);
        }
    }

    /**
     * Send a synchronization to the server
     * @param event
     */
    private fireSynchronizationEvent(event: TpzApplicationEvent): void {
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            this.getApplication().getLogger().error('Cannot send SYNC event without a valid client session');
            return;
        }
        TpzApplicationCommander.sendServerMessage(
            this.getApplication(),
            ServerSyncPluginMessageType.SYNCHRONIZATION_MESSAGE,
            { masterSessionId: sessionId, event: event }
        );
    }

    /**
     * Sends the current state of this plugin state (master and slave modes)
     */
    private fireSyncState(): void {
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();
        if (!sessionId) {
            this.getApplication().getLogger().error('Cannot fire SYNC state without a valid client session');
            return;
        }
        TpzApplicationCommander.fireSimpleEvent(
            this.getApplication(),
            this.getId(),
            ServerSyncPluginEventType.SYNC_STATE,
            { sessionId: sessionId, masterMode: this.isMaster(), slaveMode: this.isSlave() },
            TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY
        );
    }

    /**
     * treat synchronization messages: Redispatch the event contained in the message
     * flag event with ALREADY_SYNCHRONIZED (to avoid looping synchronized events)
     * @param message Synchronization message
     */
    private treatSynchronizationMessage(message: TpzServerIncomingMessage): void {
        if (message.type != ServerSyncPluginMessageType.SYNCHRONIZATION_MESSAGE) return;
        // perform action only in slave mode
        if (!this.isSlave()) return;
        // check that message content contains an 'event' field
        TpzServerHelper.checkMessageContent(message, 'event', 'masterSessionId');
        // check if source is not ourself
        if (message.content.masterSessionId == this.getApplication().getSessionManager().getCurrentSessionId()) return;
        // check that message content.event is a valid event
        const messageContentEvent: any = message.content.event;
        const event: TpzApplicationEvent = TpzApplicationEventHelper.getValidEvent(messageContentEvent);
        if (!event) {
            const invalidityReasons: String[] = TpzApplicationEventHelper.getInvalidEventReasons(messageContentEvent);
            throw new Error(
                'Incoming ' +
                    ServerSyncPluginMessageType.SYNCHRONIZATION_MESSAGE +
                    ' server message content does not contain a valid event: [' +
                    invalidityReasons.join(', ') +
                    ']'
            );
        }
        // redispatch event and flag it with ALREADY_SYNCHRONIZED (to avoid looping synchronized events)
        let categories: string[] = event.categories;
        if (!categories) categories = [ServerSyncPluginEventCategory.ALREADY_SYNCHRONIZED_CATEGORY];
        else {
            if (!categories.includes(ServerSyncPluginEventCategory.ALREADY_SYNCHRONIZED_CATEGORY)) {
                categories.concat(ServerSyncPluginEventCategory.ALREADY_SYNCHRONIZED_CATEGORY);
            }
        }
        this.getApplication().fireEvent(event.source, event.type, event.content, categories, event.date);
    }

    /**
     * Handle messages received from server.
     * If message is a synchronization message and this application is in slave mode: redispatch the event contained in synchronization message
     */
    private handleServerMessage(message: any): void {
        if (!message) return;
        // check message content validity
        const validMessage: TpzServerIncomingMessage = TpzServerHelper.getValidIncomingMessage(message);
        if (!validMessage) {
            const invalidityReasons: String[] = TpzServerHelper.getInvalidIncomingMessageReasons(message);
            throw new Error('Incoming server message is not valid: [' + invalidityReasons.join(', ') + ']');
        }
        switch (validMessage.type) {
            case ServerSyncPluginMessageType.SYNCHRONIZATION_MESSAGE:
                this.treatSynchronizationMessage(validMessage);
                break;
            case ServerSyncPluginMessageType.SET_MASTER_MODE_MESSAGE:
                TpzServerHelper.checkMessageContent(validMessage, 'sessionId', 'mode');
                if (validMessage.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                    this.setMasterMode(true);
                }
                break;
        }
        // handles only SYNCHRONIZATION messages
        if (validMessage.type != ServerSyncPluginMessageType.SYNCHRONIZATION_MESSAGE) return;
    }

    /**
     * send a message containing the application state
     */
    private sendApplicationState(): boolean {
        if (!this.isMaster()) return false;
        const sessionId: string = this.getApplication().getSessionManager().getCurrentSessionId();

        const applicationState: TpzApplicationState = this.getApplication().getApplicationState(
            sessionId,
            'sync-state'
        );
        if (!applicationState) return false;
        const applicationStateEvent: TpzApplicationEvent = {
            source: this.getApplication().getId(),
            date: new Date().getTime(),
            type: ServerSyncPluginEventType.SYNCHRONIZE_APPLICATION_STATE,
            categories: [],
            content: {
                sessionId: sessionId,
                applicationState: applicationState
            }
        };
        this.fireSynchronizationEvent(applicationStateEvent);
        return true;
    }

    /**
     * Application event actions
     * @param event application event
     * @returns true if treated
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source == this.getId()) return false;
        // in this plugin, events are synchronized for application flagged as 'dispatcher'
        // events containing category 'APPLICATION_INTERNAL_CATEGORY' are not dispatched
        // events containing category 'ALREADY_SYNCHRONIZED_CATEGORY' are not dispatched
        if (this.isMaster()) {
            const isInternal: boolean =
                event.categories &&
                event.categories.indexOf(TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY) != -1;
            const isSynchronized: boolean =
                event.categories &&
                event.categories.indexOf(ServerSyncPluginEventCategory.ALREADY_SYNCHRONIZED_CATEGORY) != -1;
            if (!isInternal && !isSynchronized) this.fireSynchronizationEvent(event);
        }
        // handle events
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTING:
                {
                    TpzApplicationEventType.checkEvent(event, null);
                }
                break;
            case ServerSyncPluginEventType.SET_MASTER_MODE:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId', 'mode');
                    if (this.getApplication().getSessionManager().getCurrentSessionId() == event.content.sessionId) {
                        this.setMasterMode(event.content.mode);
                    }
                }
                break;
            case ServerSyncPluginEventType.SET_SLAVE_MODE:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId', 'mode');
                    if (this.getApplication().getSessionManager().getCurrentSessionId() == event.content.sessionId) {
                        this.setSlaveMode(event.content.mode);
                    }
                }
                break;
            case ServerSyncPluginEventType.MASTER_MODE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId', 'mode');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.treatMasterModeRequest(event.content.mode);
                    }
                }
                break;
            case ServerSyncPluginEventType.SLAVE_MODE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId', 'mode');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.treatSlaveModeRequest(event.content.mode);
                    }
                }
                break;
            case TpzApplicationEventType.SERVER_MESSAGE_RECEIVED:
                {
                    TpzApplicationEventType.checkEvent(event, 'message');
                    this.handleServerMessage(event.content.message);
                }
                break;
            case ServerSyncPluginEventType.SYNC_STATE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId');
                    if (event.content.sessionId == this.getApplication().getSessionManager().getCurrentSessionId()) {
                        this.fireSyncState();
                    }
                }
                break;
            case ServerSyncPluginEventType.SYNCHRONIZE_APPLICATION_STATE:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId', 'applicationState');
                    // apply received state if slave
                    if (this.isSlave())
                        TpzApplicationCommander.setApplicationState(
                            this.getApplication(),
                            event.content.applicationState
                        );
                }
                break;
            case ServerSyncPluginEventType.SYNCHRONIZE_APPLICATION_STATE_REQUEST:
                {
                    TpzApplicationEventType.checkEvent(event, 'sessionId');

                    // apply received state if slave
                    if (this.isMaster()) {
                        this.sendApplicationState();
                    }
                }
                break;
        }
        return super.onApplicationEvent(event);
    }
}
