/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemConfig } from '../tpz-catalog/tpz-item-config';
import { TpzClientOutgoingMessage } from './server/server';
import { TpzMessageHelper } from './server/message-helper';
import { TpzApplication } from './tpz-application-core';
import { TpzApplicationState } from './tpz-application-state';
import { TpzWindowConfig } from './window/tpz-window-config';
import { TpzApplicationEventCategory, TpzApplicationEventType } from './tpz-application-event';
import { TpzView } from './desktop/tpz-view-core';
import { TpzWindowHelper } from './window/tpz-window-helper';

/**
 * All application commands
 */
export class TpzApplicationCommander {
    /**
     * Send an event requesting an update on given Item.
     * @param app ToPaZ Application
     * @param itemId item to update
     * @param force force update (hard update if true, light update if false). Default is false
     */
    public static requestUpdate(app: TpzApplication, itemId: string, force: boolean = false): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in fireEvent: app is not defined');
        if (!itemId) throw new Error('Illegal argument exception in fireEvent: itemId not defined');
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.ITEM_REQUEST_UPDATE,
            { itemId: itemId, force: force },
            []
        );
    }
    /**
     * Stop given item and also close the parent window in which item is plugged.
     * @param app ToPaZ Application
     * @param item item contained in the window to be closed
     */
    public static closeParentWindow(app: TpzApplication, item: TpzView): Promise<void> {
        return Promise.resolve().then(() => {
            item.getParentWindow()?.closeWindowAndRemoveFromDesktops();
        });
    }

    // public static sendSyncServerMessage(app: TpzApplication, sourceId: string, event: TpzApplicationEvent): void {
    //     TpzApplicationCommander.sendServerMessage(app, sourceId, SERVER_SYNC_MESSAGE, event);
    // }

    /**
     * Fires an event thru the application event manager
     * @param app ToPaZ application
     * @param sourceId event source identifier
     * @param type event type
     * @param eventContent event content
     * @param internal true if internal event (internal event are not broadcasted by the sync plugin)
     */
    public static fireSimpleEvent(
        app: TpzApplication,
        sourceId: string,
        type: string,
        eventContent: any,
        ...categories: string[]
    ): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in fireEvent: app is not defined');
        if (!sourceId) throw new Error('Illegal argument exception in fireEvent: sourceId not defined');
        if (!type) throw new Error('Illegal argument exception in fireEvent: type not defined');
        // event content can be null
        return app.fireEvent(sourceId, type, eventContent, categories);
    }

    public static sendServerMessage(app: TpzApplication, type: string, messageContent: any): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in SendServerMessage: app is not defined');
        if (!type) throw new Error('Illegal argument exception in SendServerMessage: type not defined');
        if (!messageContent) {
            throw new Error('Illegal argument exception in SendServerMessage: messageContent not defined');
        }
        const sourceId: string = app.getSessionManager().getCurrentSession()?.getSessionId();
        if (!sourceId) {
            app.getLogger().error('No session Id defined. Cannot send message to server');
        }
        const outgoingMessage: TpzClientOutgoingMessage = TpzMessageHelper.createClientOutgoingMessage(
            app,
            type,
            messageContent
        );
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.SEND_SERVER_MESSAGE_REQUEST,
            { message: outgoingMessage },
            [TpzApplicationEventCategory.APPLICATION_INTERNAL_CATEGORY]
        );
    }

    // /**
    //  * Register and create an empty desktop. It does not open it
    //  * @param app
    //  * @param desktopId desktop ID to be created
    //  */
    // public static createEmptyDesktop(app: TpzApplication, desktopId: string) {
    //     if (!app) throw new Error("Illegal argument exception in createEmptyDesktop: app is not defined");
    //     if (!desktopId) throw new Error("Illegal argument exception in createEmptyDesktop: desktopId not defined");
    //     app.fireEvent(app.getId(), TpzApplicationEventType.CREATE_DESKTOP_REQUEST, { desktopId: desktopId }, categories);
    // }

    /**
     * Request to start an item
     * @param app application
     * @param itemId item to be started
     */
    public static startItem(app: TpzApplication, itemId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        return app.fireEvent(app.getId(), TpzApplicationEventType.ITEM_START_REQUEST, { itemId: itemId }, categories);
    }

    /**
     * Request to stop an Item
     * @param app application
     * @param itemId item to be stopped
     */
    public static stopItem(app: TpzApplication, itemId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        return app.fireEvent(app.getId(), TpzApplicationEventType.ITEM_STOP_REQUEST, { itemId: itemId }, categories);
    }

    // /**
    //  * Request to plug an item into a parent
    //  * @param app application
    //  * @param childId item to be started
    //  */
    // public static plugItem(
    //     app: TpzApplication,
    //     childId: string,
    //     parentId: string,
    //     ...categories: string[]
    // ): Promise<void> {
    //     if (!app) throw new Error('Illegal argument exception');
    //     return app.fireEvent(
    //         app.getId(),
    //         TpzApplicationEventType.ITEM_PLUG_REQUEST,
    //         { childId: childId, parentId: parentId },
    //         categories
    //     );
    // }

    // /**
    //  * Request to unplug an Item from a parent
    //  * @param app application
    //  * @param childId item to be stopped
    //  * @param parentId parent from which the childId has to be unplugged
    //  */
    // public static unplugItem(
    //     app: TpzApplication,
    //     childId: string,
    //     parentId: string,
    //     ...categories: string[]
    // ): Promise<void> {
    //     if (!app) throw new Error('Illegal argument exception');
    //     return app.fireEvent(
    //         app.getId(),
    //         TpzApplicationEventType.ITEM_UNPLUG_REQUEST,
    //         { childId: childId, parentId: parentId },
    //         categories
    //     );
    // }

    /**
     * Request to pause an item.
     * This fires ITEM_PAUSE_REQUEST event
     * @param app application
     * @param itemId item to be started
     */
    public static pauseItem(app: TpzApplication, itemId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        return app.fireEvent(app.getId(), TpzApplicationEventType.ITEM_PAUSE_REQUEST, { itemId: itemId }, categories);
    }

    /**
     * add an item as child of another one.
     * @param app ToPaZ application
     * @param parentId parent id
     * @param childId child id
     */
    public static addChildItemById(app: TpzApplication, parentId: string, childId: string): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in addChildItemById: app is not defined');
        if (!parentId) throw new Error('Illegal argument exception in addChildItemById: parentId is not defined');
        if (!childId) throw new Error('Illegal argument exception in addChildItemById: childId is not defined');
        const parentConfigPromise: Promise<ItemConfig> = app.getItemCatalog().getCurrentConfig(parentId);
        if (!parentConfigPromise) {
            throw new Error(`Unable to add child #${childId} to inexisting parent #${parentId}`);
        }
        return parentConfigPromise.then((parentConfig: ItemConfig) => {
            // add item as child
            if (!parentConfig.childrenIds) parentConfig.childrenIds = [];
            if (!parentConfig.childrenIds.includes(childId)) {
                parentConfig.childrenIds.push(childId);
            }
            // update config
            return TpzApplicationCommander.updateItemConfig(app, parentConfig);
        });
    }

    /**
     * set the children list of an item.
     * @param app ToPaZ application
     * @param parentId parent id
     * @param childrenIds children ids array
     */
    public static setChildrenItemByIds(app: TpzApplication, parentId: string, childrenIds: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in addChildItemById: app is not defined');
        if (!parentId) throw new Error('Illegal argument exception in addChildItemById: parentId is not defined');
        if (!childrenIds) throw new Error('Illegal argument exception in addChildItemById: childrenIds is not defined');
        const parentConfigPromise: Promise<ItemConfig> = app.getItemCatalog().getCurrentConfig(parentId);
        if (!parentConfigPromise) {
            throw new Error(`Unable to add children #${childrenIds.join(', #')} to inexisting parent #${parentId}`);
        }
        return parentConfigPromise.then((parentConfig: ItemConfig) => {
            // set children
            parentConfig.childrenIds = [...childrenIds];
            // update config
            return TpzApplicationCommander.updateItemConfig(app, parentConfig);
        });
    }

    /**
     * Register item config in catalog and chain it to the given parentId.
     * This registers the given childConfig and call addChildItemById
     * @param app ToPaZ application
     * @param parentId parent ID
     * @param childConfig itemconfiguration to be added to parent
     */
    public static addChildItemByConfig(app: TpzApplication, parentId: string, childConfig: ItemConfig): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in addChildItemByConfig: app is not defined');
        if (!parentId) throw new Error('Illegal argument exception in addChildItemByConfig: parentId is not defined');
        if (!childConfig) {
            throw new Error('Illegal argument exception in addChildItemByConfig: childConfig is not defined');
        }

        // return app
        //     .waitForEvent(
        //         TpzApplicationEventType.ITEM_CONFIG_REGISTERED,
        //         (event) => event?.content?.itemConfig?.id == childConfig.id,
        //         `Registering child config #${childConfig.id} before adding it to parent #${parentId} configuration`
        //     )
        return this.registerItem(app, childConfig).then(() => this.addChildItemById(app, parentId, childConfig.id));
    }

    /**
     * Remove a child from a parent children list
     * @param app ToPaZ application
     * @param parentId parent from which the child will be removed
     * @param childId child to remove from parent
     * @returns
     */
    public static removeChildItemById(app: TpzApplication, parentId: string, childId: string): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in removeChildItemById: app is not defined');
        if (!parentId) throw new Error('Illegal argument exception in removeChildItemById: parentId is not defined');
        if (!childId) throw new Error('Illegal argument exception in removeChildItemById: childId is not defined');
        const parentConfigPromise: Promise<ItemConfig> = app.getItemCatalog().getCurrentConfig(parentId);
        if (!parentConfigPromise) {
            throw new Error(`Unable to remove child #${childId} from inexisting parent #${parentId}`);
        }
        return parentConfigPromise.then((parentConfig: ItemConfig) => {
            // add item as child
            if (!parentConfig.childrenIds) parentConfig.childrenIds = [];
            if (parentConfig.childrenIds.includes(childId)) {
                // remove child from this config
                parentConfig.childrenIds = parentConfig.childrenIds.filter((id: string) => id != childId);
            }
            // update config
            return TpzApplicationCommander.updateItemConfig(app, parentConfig);
        });
    }

    // public static removeAndCloseChildItemById(
    //     app: TpzApplication,
    //     parentId: string,
    //     childId: string,
    //     closeParents: boolean,
    //     ...categories: string[]
    // ): Promise<void> {
    //     if (!app) throw new Error('Illegal argument exception in removeAndCloseChildItemById: app is not defined');
    //     if (!parentId) {
    //         throw new Error('Illegal argument exception in removeAndCloseChildItemById: parentId is not defined');
    //     }
    //     if (!childId) {
    //         throw new Error('Illegal argument exception in removeAndCloseChildItemById: childId is not defined');
    //     }
    //     if (closeParents === null || closeParents === undefined || typeof closeParents === 'undefined') {
    //         throw new Error('Illegal argument exception in removeAndCloseChildItemById: closeParents is not defined');
    //     }

    //     return TpzApplicationCommander.removeChildItemById(app, parentId, childId).then(() =>
    //         TpzApplicationCommander.stopItem(app, childId)
    //     );
    // }

    /**
     * Request to register an item configuration to the item catalog
     * @param app ToPaZ application
     * @param itemConfig item configuration to be registered
     */
    public static registerItem(app: TpzApplication, itemConfig: ItemConfig, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in registerItem: app is not defined');
        if (!itemConfig) throw new Error('Illegal argument exception in registerItem: itemConfig is not defined');
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.ITEM_CONFIG_REGISTER_REQUEST,
            { itemConfig: itemConfig },
            categories
        );
    }

    /**
     * Request to unregister an item configuration from the item catalog
     * @param app ToPaZ application
     * @param itemId item identifier to be unregistered
     */
    public static unregisterItem(app: TpzApplication, itemId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in unregisterItem: app is not defined');
        if (!itemId) throw new Error('Illegal argument exception in unregisterItem: itemId is not defined');
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.ITEM_CONFIG_UNREGISTER_REQUEST,
            { itemId: itemId },
            categories
        );
    }

    // /**
    //  * Request to display an item in a new window in the current active desktop.
    //  * If no desktop is displayed, use default desktop
    //  * @param app
    //  * @param itemConfig
    //  * @param start
    //  */
    // public static displayItemInWindowByConfig(app: TpzApplication, itemConfig: ItemConfig, start: boolean): void {
    //     if (!app) throw new Error("Illegal argument exception in openDefaultDesktopWithItemByConfig: app is not defined");
    //     if (!itemConfig) throw new Error("Illegal argument exception in openDefaultDesktopWithItemByConfig: itemConfig is not defined");
    //     if (start === null || start === undefined || typeof start === "undefined")
    //     throw new Error("Illegal argument exception in openDefaultDesktopWithItemByConfig: start is not defined");
    //     // open default desktop
    //     const activeDesktopId: string = app.getDesktopManager().getActiveDesktopId();
    //     if (!activeDesktopId) {
    //         activeDesktopId = app.getDesktopManager().getDefaultDesktopId();
    //         TpzApplicationCommander.openDesktop(app, activeDesktopId);
    //     }
    //     // add elements to desktop
    //     TpzApplicationCommander.addChildItemByConfig(app, activeDesktopId, itemConfig, start);
    // }

    /**
     * Request to display an item in a new window in the given desktop.
     * Item is registered in catalog, window is started
     * @param app ToPaZ application
     * @param itemConfig item configuration to be registered and added to a window
     * @param windowId window id (itemConfig ID is used if null)
     */
    private static displayItemNewWindowInDesktopByConfig(
        app: TpzApplication,
        itemConfig: ItemConfig,
        systemWindow: boolean,
        desktopId: string,
        windowId: string,
        ...categories: string[]
    ): Promise<void> {
        if (!app) {
            throw new Error('Illegal argument exception in displayItemNewWindowInDesktopByConfig: app is not defined');
        }
        if (!itemConfig) {
            throw new Error(
                'Illegal argument exception in displayItemNewWindowInDesktopByConfig: itemConfig is not defined'
            );
        }
        if (!desktopId) {
            throw new Error(
                'Illegal argument exception in displayItemNewWindowInDesktopByConfig: desktopId is not defined'
            );
        }
        if (!windowId) windowId = itemConfig.id + '-window';

        // generate and add window to desktop
        let windowConfig: TpzWindowConfig = null;
        if (systemWindow) {
            windowConfig = TpzWindowHelper.createNewSystemWindowConfig(
                windowId,
                `${itemConfig.id} (${itemConfig.type}) / system`
            );
        } else {
            windowConfig = TpzWindowHelper.createNewClassicWindowConfig(
                windowId,
                `${itemConfig.id} (${itemConfig.type})`
            );
        }
        // add item as child
        if (!windowConfig.childrenIds) windowConfig.childrenIds = [];

        windowConfig.childrenIds.push(itemConfig.id);

        // register item in catalog

        // .then(() =>
        //     app.waitForEvent(
        //         TpzApplicationEventType.ITEM_CONFIG_REGISTERED,
        //         (event) => event?.content?.itemConfig?.id == itemConfig.id,
        //         `Registering item config ${itemConfig.id} before adding window to desktop`
        //     )
        // )
        return this.registerItem(app, itemConfig)
            .then(() => this.registerItem(app, windowConfig))
            .then(() => this.addChildItemByConfig(app, desktopId, windowConfig));
    }

    /**
     * Request to display an item in a new window in the current active desktop.
     * If no desktop is displayed, use default desktop
     * Item is registered in catalog, window is started
     * @param app ToPaZ application
     * @param itemConfig item configuration to be registered and added to a window
     * @param windowId window id (itemConfig ID is used if null)
     */
    public static displayItemNewWindowInActiveDesktopByConfig(
        app: TpzApplication,
        itemConfig: ItemConfig,
        systemWindow: boolean,
        windowId: string
    ): Promise<void> {
        if (!app) {
            throw new Error(
                'Illegal argument exception in displayItemNewWindowInActiveDesktopByConfig: app is not defined'
            );
        }
        if (!itemConfig) {
            throw new Error(
                'Illegal argument exception in displayItemNewWindowInActiveDesktopByConfig: itemConfig is not defined'
            );
        }
        if (!windowId) {
            windowId = `${itemConfig.id}-window`;
        }
        let activeDesktopId: string = app.getDesktopManager().getActiveDesktopId();
        if (!activeDesktopId) {
            activeDesktopId = app.getDesktopManager().getDefaultDesktopId();
            TpzApplicationCommander.addDesktop(app, activeDesktopId);
        }
        return TpzApplicationCommander.displayItemNewWindowInDesktopByConfig(
            app,
            itemConfig,
            systemWindow,
            activeDesktopId,
            windowId
        );
    }

    // // public static closeEmptyDesktop(app: TpzApplication, desktopId: string): void {
    // //     if (!app) throw new Error("Illegal argument exception");
    // //     if (!desktopId) throw new Error("Illegal argument exception");
    // //     app.fireEvent(app.getId(), TpzApplicationEventType.DESKTOP_STOP_IF_EMPTY_REQUEST, { desktopId: desktopId }, categories);
    // //
    // // }

    // public static addItemInActiveDesktopByConfig(app: TpzApplication, desktopId: string, windowId: string, itemConfig: ItemConfig, start: boolean): void {
    //     if (!app) throw new Error("Illegal argument exception in addItemInWindowByConfig: app is not defined");
    //     if (!desktopId) throw new Error("Illegal argument exception in addItemInWindowByConfig: desktopId is not defined");
    //     if (!windowId) throw new Error("Illegal argument exception in addItemInWindowByConfig: windowId is not defined");
    //     if (!itemConfig) throw new Error("Illegal argument exception in addItemInWindowByConfig: itemConfig is not defined");
    //     if (start === null || start === undefined || typeof start === "undefined") throw new Error("Illegal argument exception in addItemInWindowByConfig: start is not defined");
    //     // add item to the catalog
    //     TpzApplicationCommander.registerItem(app, itemConfig);
    //     // generate and add window to desktop
    //     const windowConfig: TpzWindowConfig = {
    //         id: windowId,
    //         title: windowId,
    //         type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
    //         childrenIds: [itemConfig.id]
    //     };
    //     TpzApplicationCommander.addChildItemByConfig(app, desktopId, windowConfig, start);

    // }

    /**
     * Request to update the application state with the given one
     * @param app ToPaZ application
     * @param state new application state to set
     */
    public static setApplicationState(app: TpzApplication, state: TpzApplicationState): Promise<void> {
        if (!app) throw new Error('Illegal argument exception in setApplicationState: app is not defined');
        if (!state) throw new Error('Illegal argument exception in setApplicationState: state is not defined');
        return app.fireEvent(app.getId(), TpzApplicationEventType.SET_APPLICATION_STATE_REQUEST, {
            applicationState: state
        });
    }

    /**
     * Request to replace the set of current displayed desktop with a single desktop
     * @param app ToPaZ application
     * @param desktopId dekstop Id to add
     */
    public static openSingleDesktop(app: TpzApplication, desktopId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        if (!desktopId) throw new Error('Illegal argument exception');
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.DESKTOP_SET_REQUEST,
            { desktopId: desktopId },
            categories
        );
    }

    /**
     * Request to add a desktop to the current set of displayed desktop
     * @param app ToPaZ application
     * @param desktopId dekstop Id to add
     */
    public static addDesktop(app: TpzApplication, desktopId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        if (!desktopId) throw new Error('Illegal argument exception');
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.DESKTOP_ADD_REQUEST,
            { desktopId: desktopId },
            categories
        );
    }

    /**
     * Request to remove a desktop from the current set of displayed desktop
     * @param app ToPaZ application
     * @param desktopId dekstop Id to add
     */
    public static closeDesktop(app: TpzApplication, desktopId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        if (!desktopId) throw new Error('Illegal argument exception');
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.DESKTOP_CLOSE_REQUEST,
            { desktopId: desktopId },
            categories
        );
    }

    /**
     * Request to remove all displayed desktops from the current set of displayed desktop
     * @param app ToPaZ application
     */
    public static closeAllDesktops(app: TpzApplication, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        return app.fireEvent(app.getId(), TpzApplicationEventType.DESKTOP_CLOSE_ALL_REQUEST, null, categories);
    }

    /**
     * request to activate a desktop (set it as active)
     * @param app application
     * @param desktopId desktop ID to activate
     */
    public static activateDesktop(app: TpzApplication, desktopId: string, ...categories: string[]): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        if (!desktopId) throw new Error('Illegal argument exception');
        return app.fireEvent(
            app.getId(),
            TpzApplicationEventType.DESKTOP_ACTIVATE_REQUEST,
            { desktopId: desktopId },
            categories
        );
    }

    // /**
    //  * request to deactivate a desktop
    //  * @param app application
    //  * @param desktopId desktop ID to deactivate
    //  */
    // public static deactivateDesktop(app: TpzApplication, desktopId: string): void {
    //     if (!app) throw new Error("Illegal argument exception");
    //     if (!desktopId) throw new Error("Illegal argument exception");
    //     app.fireEvent(app.getId(), TpzApplicationEventType.DESKTOP_DEACTIVATE_REQUEST, { desktopId: desktopId }, categories);

    // }

    /**
     * Request to update an item with the given config
     * @param app ToPaZ application
     * @param itemConfig new item configuration
     */
    public static updateItemConfig(app: TpzApplication, itemConfig: ItemConfig): Promise<void> {
        if (!app) throw new Error('Illegal argument exception');
        if (!itemConfig) throw new Error('Illegal argument exception');
        return app.fireEvent(app.getId(), TpzApplicationEventType.ITEM_CONFIG_UPDATE_REQUEST, {
            itemConfig: itemConfig
        });
    }
}
