/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemEvent } from '../../tpz-catalog/tpz-catalog-core';
import { AccessorEventType } from '../../tpz-access/tpz-access-event';
import { ItemInstance } from '../../tpz-catalog/tpz-item-core';
import { TpzApplication } from '../tpz-application-core';
import { TpzWindow } from '../window/tpz-window-core';
import { deepCopy } from '../tools/deep-copy';
import { TpzApplicationEventType } from '../tpz-application-event';
import { uuidv4 } from '../tools/uuid';
import { TpzApplicationCategories } from '../tpz-application-types';
import { defaultTpzViewConfig, TpzViewConfig } from './tpz-view-config';

/**
 * A view is a graphics item which can be inserted in a window.
 */
export abstract class TpzView extends ItemInstance {
    private viewContainer: HTMLDivElement = null;
    private parentWindow: TpzWindow = null; // window in which this viewer is displayed
    private htmlUuid: string = null; // generated unique ID when inserted in the DOM

    /**
     * constructor
     */
    constructor(config: TpzViewConfig, type: string, application: TpzApplication) {
        super(deepCopy(defaultTpzViewConfig, config), type, application);
        this.addCategory(TpzApplicationCategories.TPZ_VIEW_CATEGORY);
    }

    /**
     * Get a generated Id
     */
    public getHtmlId(): string {
        if (!this.htmlUuid) this.htmlUuid = uuidv4();
        return this.htmlUuid;
    }

    /**
     * Getter specialization
     */
    public getConfig(): TpzViewConfig {
        return super.getConfig() as TpzViewConfig;
    }

    /**
     * View Container lazy getter
     */
    public getViewContainer(): HTMLDivElement {
        if (!this.viewContainer && this.parentWindow) {
            const nbViews: number = this.parentWindow?.getConfig().childrenIds.length;
            this.viewContainer = document.createElement('div');
            if (nbViews == 1) {
                this.viewContainer.classList.add('topaz-view-absolute');
            } else {
                this.viewContainer.classList.add('topaz-view-relative');
            }
            this.getConfig().containerClasses?.forEach((containerClass: string) => {
                this.viewContainer.classList.add(containerClass);
            });
        }
        return this.viewContainer;
    }

    /**
     * Create this desktopView UI into given parent
     */
    public abstract createUI(parent: HTMLDivElement): boolean;

    /**
     * Update the view UI content
     */
    public updateUI(): void {
        // fires an update UI event
        this.fireApplicationEvent(TpzApplicationEventType.VIEW_UI_UPDATED, { viewId: this.getId() });
    }

    /**
     * Clear all graphics components (must be chained by calling super.invalidateUI())
     */
    public invalidateUI(): void {
        this.viewContainer = null;
    }

    /**
     * get window in which this item is plugged. throws an exception if no or multiple parents
     */
    public getParentWindow(): TpzWindow {
        const parents: ItemInstance[] = this.getManagedParents();
        if (!parents || parents.length == 0) throw new Error(`view #${this.getId()} has no parents...`);
        if (parents.length > 1) {
            throw new Error(
                `desktop view item #${this.getId()} has multiple parent parents... (${parents
                    .map((item) => item.getId())
                    .join(', ')}`
            );
        }
        const parent: ItemInstance = parents[0];
        if (!parent) throw new Error(`view #${this.getId()} has one single null parent`);
        return parent as unknown as TpzWindow;
    }

    /**
     * plug view in parent window
     */
    public doPlugInParent(parent: ItemInstance): Promise<void> {
        if (!parent) throw new Error(`No parent defined when plugging item #${this.getId()}`);
        return Promise.resolve().then(() => {
            if (!parent.containsCategory(TpzApplicationCategories.TPZ_WINDOW_CATEGORY)) {
                throw new Error(
                    `TpzView items should be plugged into window item type only. View #${this.getId()} type ${this.getType()}. Parent type = ${parent.getType()}`
                );
            }
            const window: TpzWindow = parent as TpzWindow;
            this.displayInWindow(window);
        });
    }

    /**
     * unplug view from parent window
     */
    public doUnplugFromParent(parent: ItemInstance): Promise<void> {
        if (!parent) throw new Error(`No parent defined when unplugging item #${this.getId()}`);
        return Promise.resolve().then(() => {
            if (!parent.containsCategory(TpzApplicationCategories.TPZ_WINDOW_CATEGORY)) {
                throw new Error(
                    `TpzView items should be plugged into window item type only. View #${this.getId()} type ${this.getType()}. Parent type = ${parent.getType()}`
                );
            }
            this.undisplayFromParentWindow();
            this.invalidateUI();
        });
    }

    /**
     * Add this view into a window
     * @param window Window in which this viewer is added
     */
    private displayInWindow(window: TpzWindow): boolean {
        // if same window is already set do nothing
        if (window == this.parentWindow) return true;
        // potentially remove from the window in which it has already been added
        this.undisplayFromParentWindow();
        if (window) {
            this.parentWindow = window;
            window.getContentDiv().appendChild(this.getViewContainer());
            // createUI is called once the view container has been added to the DOM
            // some libraries needs an instance to create UI
            this.createUI(this.getViewContainer());
        } else {
            this.getLogger().warn(`display view #${this.getId()} in null window`);
        }
        return true;
    }

    /**
     * Remove this view from a Window
     */
    private undisplayFromParentWindow(): boolean {
        if (!this.parentWindow) return false;
        if (this.parentWindow.getContentDiv().contains(this.getViewContainer())) {
            this.parentWindow.getContentDiv().removeChild(this.getViewContainer());
        }
        this.parentWindow = null;
        return true;
    }

    /**
     * request Update
     * @param force
     */
    public requestUpdate(force: boolean): boolean {
        // launch request update on all plugged addons
        return super.requestUpdate(force);
    }

    // /**
    //  * By default update UI on Accessor data change
    //  * @param event
    //  * @returns
    //  */
    // public onItemEvent(event: ItemEvent): boolean {
    // switch (event.type) {
    //     case AccessorEventType.DATA:
    //         this.updateUI();
    // }
    //     return super.onItemEvent(event);
    // }
}
