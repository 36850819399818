/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventManager } from '../../../tpz-event/tpz-event-core';
import { RollerEvent, RollerEventType } from './roller';

/**
 * roller configuration
 */
export interface RollerColumnConfig {
    columnClasses?: string[];
    cellClasses?: string[];
    cellHeight?: number;
    loop?: boolean;
    invertY?: boolean;
    interactive?: boolean;
}

/**
 * Default configuration
 */
const defaultRollerColumnConfig: RollerColumnConfig = {
    columnClasses: ['roller-column'],
    cellClasses: ['roller-cell'],
    cellHeight: 30,
    loop: false,
    invertY: false,
    interactive: true
};

/**
 * abstract roller column used by roller object
 */
export abstract class RollerColumn {
    private readonly config: RollerColumnConfig = null;
    private rollerColumn: HTMLDivElement = null;
    private selectedIndex: number = 0;

    private clickMarginTop: number = null;
    private clickY: number = null;

    private eventManager: EventManager<RollerEvent> = null;
    public static readonly SELECTED_CELL_ATTRIBUTE: string = 'selected-cell';

    /**
     * Roller Column constructor
     * @param parent input element to fill with
     */
    constructor(config: RollerColumnConfig) {
        this.config = { ...defaultRollerColumnConfig, ...config };
    }

    /**
     * Config getter
     */
    public getConfig(): RollerColumnConfig {
        return this.config;
    }

    /**
     * Get Event Manager
     * @param column
     */
    public getEventManager(): EventManager<RollerEvent> {
        if (!this.eventManager) {
            this.eventManager = new EventManager<RollerEvent>();
        }
        return this.eventManager;
    }

    /**
     * get selected value
     */
    public getSelectedValue(): string {
        return this.getCells()[this.getSelectedIndex()];
    }

    /**
     * get selected index
     */
    public getSelectedIndex(): number {
        return this.selectedIndex;
    }

    /**
     * Lazy getter of the Roller Column
     */
    protected abstract getCells(): string[];

    /**
     * get the number of cells
     */
    protected getCellsCount(): number {
        return this.getCells().length;
    }

    /**
     * get cell height (in pixels)
     */
    protected getCellHeight(): number {
        return this.getConfig()?.cellHeight ?? 30;
    }

    /**
     * Main UI component
     */
    public getUI(): HTMLDivElement {
        const elt = this.getRollerColumn();
        return elt;
    }

    /**
     * Set current selected value. If value is not in cells return false
     * @param value selected value
     */
    public setSelectedValue(value: string): boolean {
        const index = this.getCells().indexOf(value);
        if (index < 0) return false;
        return this.setSelectedIndex(index);
    }

    /**
     * Set current selected index
     * @param index selected index
     */
    public setSelectedIndex(index: number): boolean {
        if (this.selectedIndex >= 0 && this.selectedIndex < this.getCellsCount()) {
            this.getRollerColumn().children[this.selectedIndex].classList.remove(RollerColumn.SELECTED_CELL_ATTRIBUTE);
        }
        // if loop is true, use modulo on cells count. Else clamp index to 0 count - 1
        let newSelectedIndex: number = 0;
        if (this.getConfig()?.loop) {
            newSelectedIndex = ((index % this.getCellsCount()) + this.getCellsCount()) % this.getCellsCount();
        } else newSelectedIndex = Math.max(0, Math.min(this.getCellsCount() - 1, index));
        this.selectedIndex = newSelectedIndex;
        this.getRollerColumn().children[newSelectedIndex].classList.add(RollerColumn.SELECTED_CELL_ATTRIBUTE, 'true');
        this.getRollerColumn().style.top = '50%';
        this.getRollerColumn().style.marginTop = this.computeMarginTopFromSelectedIndex(this.selectedIndex) + 'px';
        if (newSelectedIndex !== this.selectedIndex) return false;
        if (this.getConfig()?.interactive) this.getEventManager().trigger({ type: RollerEventType.COLUMN_CHANGED });
        return true;
    }

    private computeSelectedIndexFromMarginTop(marginTop: number): number {
        if (!marginTop) return 0;
        return Math.round(-marginTop / this.getCellHeight() - 0.5);
    }

    private computeMarginTopFromSelectedIndex(index: number): number {
        if (index < 0) index = 0;
        if (index >= this.getCellsCount()) index = this.getCellsCount() - 1;
        return -((index + 0.5) * this.getCellHeight());
    }

    /**
     * update column content
     */
    protected updateColumnUI(): void {
        const me: RollerColumn = this;
        me.getRollerColumn().innerHTML = '';
        me.getCells().forEach((value: string) => {
            me.getRollerColumn().appendChild(me.createRollerCell(value));
        });
        // in case of selected index not in the new range
        // me.setSelectedIndex(me.getSelectedIndex());
    }

    /**
     * add event listeners on column
     */
    private addColumnInteraction(): void {
        const me: RollerColumn = this;
        this.rollerColumn.addEventListener('pointerdown', function (e: PointerEvent) {
            me.clickY = e.pageY;
            me.clickMarginTop = Number.parseFloat(me.rollerColumn.style.marginTop) || 0;
            e.preventDefault();
            me.rollerColumn.setPointerCapture(e.pointerId);
            //   console.log(this.clickX + "x" + this.clickY)
        });

        this.rollerColumn.addEventListener('wheel', (e: WheelEvent) => {
            e.preventDefault();
            const deltaY = me.getConfig().invertY ? -e.deltaY : e.deltaY;
            if (deltaY > 0) me.setSelectedIndex(me.selectedIndex + 1);
            else if (deltaY < 0) me.setSelectedIndex(me.selectedIndex - 1);
        });

        this.rollerColumn.addEventListener('pointerup', function (e: PointerEvent) {
            me.clickY = null;
            me.rollerColumn.releasePointerCapture(e.pointerId);
            me.setSelectedIndex(
                me.computeSelectedIndexFromMarginTop(Number.parseFloat(me.rollerColumn.style.marginTop))
            );
        });

        this.rollerColumn.addEventListener('pointermove', function (e: PointerEvent) {
            if (me.clickY != null) {
                const deltaY = e.pageY - me.clickY;
                const currentIndex = me.computeSelectedIndexFromMarginTop(me.clickMarginTop + deltaY);
                if (currentIndex <= 0) {
                    me.rollerColumn.style.marginTop = me.computeMarginTopFromSelectedIndex(0) + 'px';
                } else if (currentIndex >= me.getCellsCount() - 1) {
                    me.rollerColumn.style.marginTop =
                        me.computeMarginTopFromSelectedIndex(me.getCellsCount() - 1) + 'px';
                } else {
                    me.rollerColumn.style.marginTop = me.clickMarginTop + deltaY + 'px';
                }
            }
        });
    }
    /**
     * Lazy getter of the Roller Column
     */
    private getRollerColumn(): HTMLDivElement {
        const me: RollerColumn = this;
        if (!this.rollerColumn) {
            this.rollerColumn = document.createElement('div');
            this.getConfig()?.columnClasses?.forEach((clazz) => me.rollerColumn.classList.add(clazz));
            this.updateColumnUI();
            if (this.getConfig()?.interactive) {
                this.addColumnInteraction();
            }
        }
        return this.rollerColumn;
    }

    /**
     * Create A Roller Cell
     */
    private createRollerCell(value: string): HTMLDivElement {
        const cell: HTMLDivElement = document.createElement('div');
        cell.classList.add('roller-cell');
        this.config?.cellClasses?.forEach((clazz) => cell.classList.add(clazz));
        cell.innerText = value;
        cell.style.height = this.getCellHeight() + 'px';
        return cell;
    }
}

/**
 * roller configuration
 */
export interface ListRollerColumnConfig extends RollerColumnConfig {
    values?: string[];
}

/**
 * Default configuration
 */
const defaultListRollerColumnConfig: ListRollerColumnConfig = {
    ...defaultRollerColumnConfig,
    values: []
};

/**
 * list roller column is an implementation of the abstract column roller
 * using a list of values
 */
export class ListRollerColumn extends RollerColumn {
    /**
     * Roller Column constructor
     * @param parent input element to fill with
     * @param values
     */
    constructor(config: ListRollerColumnConfig) {
        super({ ...defaultListRollerColumnConfig, ...config });
    }

    /**
     * Config getter specialization
     */
    public getConfig(): ListRollerColumnConfig {
        return super.getConfig() as ListRollerColumnConfig;
    }

    /**
     * Lazy getter of the Roller Column
     */
    protected getCells(): string[] {
        return this.getConfig().values;
    }
}

/**
 * roller configuration
 */
export interface RangeRollerColumnConfig extends RollerColumnConfig {
    min?: number;
    max?: number;
    inc?: number;
}

/**
 * Default configuration
 */
const defaultRangeRollerColumnConfig: RangeRollerColumnConfig = {
    ...defaultRollerColumnConfig,
    min: 0,
    max: 9,
    inc: 1
};

/**
 * range roller column is an implementation of the abstract column roller
 * using a range of values
 */
export class RangeRollerColumn extends RollerColumn {
    private static readonly MAX_CELLS = 100;
    private values: string[] = null;

    /**
     * Roller Column constructor
     * @param parent input element to fill with
     * @param values
     */
    constructor(config: RangeRollerColumnConfig) {
        super({ ...defaultListRollerColumnConfig, ...config });
    }

    /**
     * Config getter specialization
     */
    public getConfig(): RangeRollerColumnConfig {
        return super.getConfig() as RangeRollerColumnConfig;
    }

    /**
     * Set range values
     */
    public setRange(min: number, max: number, inc: number = 1): void {
        this.getConfig().min = min;
        this.getConfig().max = max;
        this.getConfig().inc = inc;
        this.values = null; // invalidate values precomputations
        this.updateColumnUI();
    }
    /**
     * Lazy getter of the Roller Column
     */
    protected getCells(): string[] {
        if (!this.values) {
            this.values = [];
            const min = Math.min(this.getConfig().min, this.getConfig().max);
            const max = Math.max(this.getConfig().min, this.getConfig().max);
            const inc = this.getConfig()?.inc ?? 1;
            if (typeof min === 'undefined' || min == null || typeof max === 'undefined' || max == null) return [];
            let x: number = min;
            let count = 0;
            while (min <= x && x <= max && count < RangeRollerColumn.MAX_CELLS) {
                this.values.push(x + '');
                x += inc;
                count = count + 1;
            }
        }
        return this.values;
    }
}
