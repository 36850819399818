/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Messages received from Server using Topaz Protocol
 */
export class FromServerMessageProtocol {
    public static readonly CLIENT_ID_MESSAGE = 'CLIENT_ID';
    public static readonly PING_MESSAGE = 'PING';
    public static readonly PONG_MESSAGE = 'PONG';
    public static readonly SYNCHRONIZE_EVENT_MESSAGE = 'SYNCHRONIZE_EVENT'; // message contains only an event (no defined interface)
}

/**
 * Message received from Server CLIENT_ID
 */
export interface FromServerClientIdMessage {
    id: string;
}

/**
 * Message received from Server PING
 */
export interface FromServerPingMessage {
    serverEmitTime: number;
}

/**
 * Message received from Server PONG
 */
export interface FromServerPongMessage {
    clientEmitTime: number;
    serverResponseTime: number;
}

// /**
//  * Message received from Server SYNCHRONIZATION_EVENT
//  */
// export interface FromServerSyncMessage {
//     oqsieufn opqsiuntcoqsu
// }
