/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplication } from '../tpz-application-core';
import { TpzAddOnManager } from './addons-manager';
import { TpzAddOn, TpzAddOnDescriptor } from './addons-core';
import { TpzApplicationUI } from '../tpz-application-ui';
import { LoggerHelper } from '../../tpz-log/tpz-log-helper';

export class AddOnsManagerEditor {
    private readonly application: TpzApplication = null;
    private mainContainer: HTMLDivElement = null;
    // private serverAddOnsDiv: HTMLDivElement = null;
    private addOnsDiv: HTMLDivElement = null;
    // private loadedAddOnsDiv: HTMLDivElement = null;
    private registeredServersDiv: HTMLDivElement = null;

    /**
     * Library Manager Editor Constructor
     * @param app application used to fire events (may be we should not give the full application but only the event)
     */
    constructor(app: TpzApplication) {
        this.application = app;
    }

    /**
     * UI main element
     */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * parent application getter
     */
    public getApplication(): TpzApplication {
        return this.application;
    }

    /**
     * @returns the addons manager being edited
     */
    private getAddOnsManager(): TpzAddOnManager {
        return this.application.getAddOnManager();
    }

    /**
     * Main container lazy getter
     */
    private getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({});
            const addOnsBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({ legend: 'Local Add-Ons' });
            addOnsBloc.appendChild(this.getAddOnsDiv());
            this.mainContainer.appendChild(addOnsBloc);
            const serverBloc: HTMLFieldSetElement = TpzApplicationUI.createFieldSet({ legend: 'Servers' });
            serverBloc.appendChild(this.getRegisteredServersDiv());
            this.mainContainer.appendChild(serverBloc);
            this.updateUI();
        }
        return this.mainContainer;
    }

    // /**
    //  * Registered add-ons DIV lazy getter
    //  */
    // private getServerAddOnsDiv(): HTMLDivElement {
    //     if (!this.serverAddOnsDiv) {
    //         this.serverAddOnsDiv = TpzApplicationUI.createDiv();
    //     }
    //     return this.serverAddOnsDiv;
    // }

    // /**
    //  * Loaded add-ons DIV lazy getter
    //  */
    // private getLoadedAddOnsDiv(): HTMLDivElement {
    //     if (!this.loadedAddOnsDiv) {
    //         this.loadedAddOnsDiv = TpzApplicationUI.createDiv();
    //     }
    //     return this.loadedAddOnsDiv;
    // }

    /**
     * Local add-ons DIV lazy getter
     */
    private getAddOnsDiv(): HTMLDivElement {
        if (!this.addOnsDiv) {
            this.addOnsDiv = TpzApplicationUI.createDiv();
        }
        return this.addOnsDiv;
    }

    /**
     * update local and server addon div (in parallel)
     */
    public updateUI(): Promise<void> {
        return this.updateAddOnsDiv();
    }

    /**
     * update local addon div
     */
    private updateAddOnsDiv(): Promise<void> {
        return Promise.resolve().then(() => {
            this.getAddOnsDiv().innerHTML = null;
            Object.values(this.getAddOnsManager().getDescriptors()).forEach((addOnDescriptor: TpzAddOnDescriptor) => {
                this.getAddOnsDiv().appendChild(
                    TpzApplicationUI.createCollapsible(
                        addOnDescriptor.id + ' / ' + addOnDescriptor.name,
                        this.createAddOnDescriptorManager(addOnDescriptor)
                    )
                );
            });
        });
    }

    /**
     * Create a div containing a panel managing the given descriptor: Load and state
     * @param parent
     * @param addOnDescriptor
     */
    private createAddOnDescriptorManager(addOnDescriptor: TpzAddOnDescriptor): HTMLDivElement {
        if (!addOnDescriptor) {
            throw new Error(
                'createAddOnDescriptorManager must be valid in addOkAddOnDescriptor #' +
                    this.getAddOnsManager().getId() +
                    ' editor'
            );
        }

        const addOnId: string = addOnDescriptor.id;
        const div: HTMLDivElement = TpzApplicationUI.createDiv();
        div.appendChild(TpzApplicationUI.createParagraph({ text: addOnId }));
        const loadButton: HTMLButtonElement = TpzApplicationUI.createButton({ label: 'load' });
        div.appendChild(loadButton);
        const existingLoader: Promise<TpzAddOn> = this.getAddOnsManager().getExistingLoader(addOnId);
        // loading state
        const loadAddOnStateParagraph: HTMLParagraphElement = TpzApplicationUI.createParagraph({ text: 'Not loaded' });
        if (existingLoader) {
            loadAddOnStateParagraph.innerHTML = 'Pending...';
            existingLoader
                .then(() => {
                    loadAddOnStateParagraph.innerHTML = 'loaded';
                    loadAddOnStateParagraph.classList.add('addon-state-ok');
                    loadAddOnStateParagraph.classList.remove('addon-state-error');
                })
                .catch((reason: any) => {
                    loadAddOnStateParagraph.innerHTML =
                        'loading error: ' + LoggerHelper.getErrorMessageFromObject(reason);
                    loadAddOnStateParagraph.classList.remove('addon-state-ok');
                    loadAddOnStateParagraph.classList.add('addon-state-error');
                });
        }

        div.appendChild(loadAddOnStateParagraph);
        const location: string = this.getAddOnsManager().getScriptURLById(addOnId);
        div.appendChild(TpzApplicationUI.createParagraph({ text: addOnId + ': ' + location }));
        div.appendChild(
            TpzApplicationUI.createCollapsible(
                addOnId,
                TpzApplicationUI.createPre({ text: addOnId + ': ' + JSON.stringify(addOnDescriptor, null, 2) })
            )
        );

        loadButton.addEventListener('click', () => {
            // state will be updated due to ADDON_EVENT
            this.getAddOnsManager().loadAddOnById(addOnDescriptor.id);
        });

        return div;
    }
    // /**
    //  * update server addon div
    //  */
    // private updateServerAddOnsDiv(): Promise<void> {
    //     let me = this;
    //     return Promise.resolve()
    //         .then(() => {
    //             this.getServerAddOnsDiv().innerHTML = null;
    //             Object.keys(this.getAddOnsManager().getDescriptors()).forEach((addOnId: string) => {
    //                 let addOnDescriptor: TpzAddOnDescriptor = this.getAddOnsManager().getDescriptorById(addOnId);
    //                 let location: string = this.getAddOnsManager().getScriptURLById(addOnId);
    //                 this.getServerAddOnsDiv().appendChild(TpzApplicationUI.createParagraph({ text: addOnId + ": " + location }));
    //                 this.getServerAddOnsDiv().appendChild(TpzApplicationUI.createCollapsible(addOnId,
    //                     TpzApplicationUI.createPre({ text: addOnId + ": " + JSON.stringify(addOnDescriptor, null, 2) })));
    //                 this.getAddOnsDiv().appendChild(this.createAddOnDescriptorManager(addOnDescriptor));
    //             });
    //         })
    // }

    /**
     * Main container lazy getter
     */
    private getRegisteredServersDiv(): HTMLDivElement {
        if (!this.registeredServersDiv) {
            this.registeredServersDiv = TpzApplicationUI.createDiv({});
            this.updateRegisteredServersDiv();
        }
        return this.registeredServersDiv;
    }

    private updateRegisteredServersDiv(): Promise<void> {
        return Promise.resolve().then(() => {
            this.getRegisteredServersDiv().innerHTML = null;
            this.getAddOnsManager()
                .getAddOnRegistryURLs()
                .forEach((serverURL: string) => {
                    this.getRegisteredServersDiv().appendChild(TpzApplicationUI.createParagraph({ text: serverURL }));
                });
        });
    }
}
