/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplicationUI } from '../tpz-application-ui';
import { uuidv4 } from './uuid';

/**
 * <div class="tab">
 *   <div>
 *     <button onclick="openTab(event, $PANEL_ID1)">button title for PANEL 1</button>
 *     <button onclick="openTab(event, $PANEL_ID2)">button title for PANEL 2</button>
 *     ...
 *   </div>
 * </div>
 * <div>
 *   <div>
 *     <div id=$PANEL_ID1> panel 1 content </div>
 *     <div id=$PANEL_ID2> panel 2 content </div>
 *     ...
 *   </div>
 * </div>
 */
export class TabPanel {
    private mainContainer: HTMLDivElement = null;
    private headerDiv: HTMLDivElement = null;
    private contentDiv: HTMLDivElement = null;

    /**
     * Set clicked tab button as content of the tab content
     * @param evt click event
     * @param panelId panelId to set as tab content
     */
    public openTab(panelId: string): void {
        const buttonId: string = this.getButtonId(panelId);
        // switch selected element by looping over all tab-button/div-panel
        Array.from(this.getHeaderDiv().children)?.forEach((element: Element) => {
            if (element.tagName !== 'BUTTON') {
                return;
            }
            if (element.id === buttonId) element.classList.add('active');
            else element.classList.remove('active');
        });
        Array.from(this.getContentDiv().children)?.forEach((element: Element) => {
            if ((element as HTMLElement)?.style) {
                (element as HTMLElement).style.display = element.id === panelId ? 'block' : 'none';
            }
        });
    }

    /**
     * get the button ID associated with panelId
     */
    private getButtonId(panelId: string): string {
        if (!panelId) return null;
        return panelId + '-button';
    }

    /**
     * Add a tab: a button in header div and a corresponding content in
     * @param tabName
     * @param content
     */
    public addTab(tabName: string, content: HTMLElement): void {
        if (!tabName) throw new Error('Cannot add tab with empty tab name');
        if (!content) throw new Error('Cannot add tab with empty content');
        const button: HTMLButtonElement = document.createElement('button');
        button.innerHTML = tabName;
        if (!content.id) content.id = uuidv4();
        button.id = this.getButtonId(content.id);
        button.addEventListener('click', (event: Event) => this.openTab(content.id));
        this.getHeaderDiv().appendChild(button);
        this.getContentDiv().appendChild(content);
        this.openTab(content.id);
    }

    /**
     * main UI access
     */
    public getUI(): HTMLDivElement {
        return this.getMainContainer();
    }

    /**
     * Main component
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = TpzApplicationUI.createDiv({ classes: ['tab'] });
            this.mainContainer.appendChild(this.getHeaderDiv());
            this.mainContainer.appendChild(this.getContentDiv());
        }
        return this.mainContainer;
    }

    /**
     * Header div contains buttons for tabs
     */
    private getHeaderDiv(): HTMLDivElement {
        if (!this.headerDiv) {
            this.headerDiv = TpzApplicationUI.createDiv({});
        }
        return this.headerDiv;
    }

    /**
     * Content div contains tabs HTML content synchronized with tab buttons
     */
    private getContentDiv(): HTMLDivElement {
        if (!this.contentDiv) {
            this.contentDiv = TpzApplicationUI.createDiv({});
        }
        return this.contentDiv;
    }
}
