/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemFactory } from '../../../../tpz-catalog/tpz-item-factory';
import { ItemConfig } from '../../../../tpz-catalog/tpz-item-config';
import { TpzDesktop } from '../../../desktop/tpz-desktop-core';
import { ItemEditorViewHelper } from '../../../items/instances/item-editor/item-editor-view';
import { TpzAddOnSelectHelper } from '../../../items/instances/addons-select/addons-select-item';
import { TpzMenuElement } from '../../../menu/tpz-menu';
import { uuidv4 } from '../../../tools/uuid';
import { TpzApplication } from '../../../tpz-application-core';
import { TpzApplicationCommander } from '../../../tpz-application-commander';
import { TpzApplicationEventCategory } from '../../../tpz-application-event';

export class SimpleMenuHelper {
    /**
     * return all inconsistency errors for given menu as array of menu elements
     * @param rootElements all TpzElement to check (recursively)
     * @return the set of inconsistency errors or null if none have been found
     */
    public static getConsistencyErrorsFromArray(rootElements: TpzMenuElement[]): Error[] {
        if (rootElements == null) {
            return [new Error('rootElements are not defined. They should be at least an empty array')];
        }
        const errors: Error[] = [];
        const existingMenuIds: { [id: string]: string } = {};
        rootElements.forEach((menuElement: TpzMenuElement) => {
            SimpleMenuHelper.getConsistencyErrorsFromElementRec(menuElement, null, 'ROOT', errors, existingMenuIds);
        });
        if (errors.length == 0) return null;
        return errors;
    }

    /**
     * return all inconsistency errors for given menu element
     * @param menuElement Menu element to check consistency
     */
    private static getConsistencyErrorsFromElementRec(
        menuElement: TpzMenuElement,
        parentElement: TpzMenuElement,
        path: string,
        errors: Error[],
        existingMenuIds: { [id: string]: string }
    ): void {
        const parentMenuElementName: string = parentElement ? `#${parentElement.id}` : 'ROOT NODE';
        if (!errors) throw new Error('error variable is not declared');
        if (!path) throw new Error('path variable is not declared');
        if (!existingMenuIds) throw new Error('existingMenuIds variable is not declared');
        // menu element MUST be defined
        if (!menuElement) {
            errors.push(new Error(`menu element ${parentMenuElementName} contains a null menu item`));
            return;
        }
        // check element's ID is set
        let currentPath = path;
        if (menuElement.id && menuElement.id != '') {
            currentPath = `${path}>${menuElement.id}`;
        } else {
            currentPath = `${path}> ? `;
            errors.push(new Error(`menu element ${parentMenuElementName} contains a null menu item`));
        }
        // check if ID is already in use
        const existingPath: string = existingMenuIds[menuElement.id];
        if (existingPath) {
            errors.push(
                new Error(`menu element #${menuElement.id} (${currentPath}) is already in use in (${existingPath})`)
            );
        }
        existingMenuIds[menuElement.id] = currentPath;
        menuElement.subElements?.forEach((childMenuElement: TpzMenuElement) => {
            SimpleMenuHelper.getConsistencyErrorsFromElementRec(
                childMenuElement,
                menuElement,
                currentPath,
                errors,
                existingMenuIds
            );
        });
    }

    static getConsistencyErrorsRec(menuElement: TpzMenuElement, errors: Error[]) {
        throw new Error('Method not implemented.');
    }
    /**
     * Check if the given menu is consistent
     */
    public static isConsistent(rootElements: TpzMenuElement[]): boolean {
        const errors: Error[] = SimpleMenuHelper.getConsistencyErrorsFromArray(rootElements);
        return !errors || errors.length == 0;
    }

    /**
     * get the element of an array of menu elements by id
     * @param menuElementId menu element id to be retrieved
     * @param rootElements array of menu elements to look into
     * @returns the element from the array with the given id or null if not in array
     */
    public static getMenuElementByIdFromArray(menuElementId: string, rootElements: TpzMenuElement[]): TpzMenuElement {
        const index: number = SimpleMenuHelper.getMenuElementIndexByIdFromArray(menuElementId, rootElements);
        return SimpleMenuHelper.getMenuElementByIndexFromArray(index, rootElements);
    }

    /**
     * get the element index of an array of menu elements by id
     * @param menuElementId menu element id to be retrieved
     * @param rootElements array of menu elements to look into
     * @returns the element index from the array with the given id or -1 if not in array
     */
    public static getMenuElementIndexByIdFromArray(menuElementId: string, rootElements: TpzMenuElement[]): number {
        if (!menuElementId) return -1;
        if (!rootElements) return -1;
        for (let elementIndex = 0; elementIndex < rootElements.length; elementIndex++) {
            if (rootElements[elementIndex]?.id == menuElementId) return elementIndex;
        }
        return -1;
    }

    /**
     * get the element (child) of a menu element (parent) subElements by id
     * @param menuElementId child menu element name to be retrieved
     * @param parentElement parent menu element to look into
     * @returns the element from the parent sub elements with the given name or null if not in subelements
     */
    public static getMenuElementById(menuElementId: string, parentElement: TpzMenuElement): TpzMenuElement {
        return SimpleMenuHelper.getMenuElementByIdFromArray(menuElementId, parentElement.subElements);
    }

    /**
     * get the element of an array of menu elements by index
     * @param menuElementIndex menu element index in rootElements to be retrieved
     * @param rootElements array of menu elements to look into
     * @returns the element from the array with the given index or null if index out of range
     */
    public static getMenuElementByIndexFromArray(
        menuElementIndex: number,
        rootElements: TpzMenuElement[]
    ): TpzMenuElement {
        if (!rootElements) return null;
        if (menuElementIndex < 0 || menuElementIndex >= rootElements.length) return null;
        return rootElements[menuElementIndex];
    }

    /**
     * remove a menu element from a menu element by its id.
     * This method changes the given rootElements variable
     * @param menuElementId menu element id to remove from root elements
     * @param rootElements rootElements where element should be removed
     * @returns true if removed, false if not present in
     */
    public static removeMenuElementByIdFromArray(menuElementId: string, rootElements: TpzMenuElement[]): boolean {
        if (!menuElementId) return false;
        if (!rootElements) return false;
        const branch: TpzMenuElement[] = SimpleMenuHelper.getMenuElementHierarchyById(rootElements, menuElementId);
        if (!branch) return false;
        if (branch.length == 0) return false;
        // item to remove is the last in branch
        const itemToRemove: TpzMenuElement = branch[branch.length - 1];
        // check consistency
        if (!itemToRemove) throw new Error(`branch's last element id is null...`);
        if (itemToRemove.id != menuElementId) {
            throw new Error(`item to remove id is ${menuElementId} but branch's last element id is ${itemToRemove.id}`);
        }
        if (branch.length == 1) {
            let removed: boolean = false;
            // remove the element without creating a new array
            rootElements.forEach((item: TpzMenuElement, index: number) => {
                if (item == itemToRemove) {
                    rootElements.splice(index, 1);
                    removed = true;
                }
            });
            return removed;
        }
        const parent: TpzMenuElement = branch[branch.length - 2];
        if (!parent) throw new Error('Branch contains null elements...');
        if (!parent.subElements) throw new Error('Parent element in branch cdoes not ontain the requested element...');
        // remove the element without creating a new array
        let removed: boolean = false;
        parent.subElements.forEach((item: TpzMenuElement, index: number) => {
            if (item == itemToRemove) {
                parent.subElements.splice(index, 1);
                removed = true;
            }
        });
        return removed;
    }

    /**
     * remove a menu element from a menu element using its id.
     * This method changes the given rootElements variable
     * @param menuElementId menu element  to remove from root elements
     * @param rootElements rootElements where element should be removed
     * @returns true if removed, false if not present in
     */
    public static removeMenuElementByIdFromElement(menuElementId: string, parentElement: TpzMenuElement): boolean {
        if (!parentElement) return false;
        return SimpleMenuHelper.removeMenuElementByIdFromArray(menuElementId, parentElement.subElements);
    }

    /**
     * add a menu element to an array of menu elements
     * This method modifies the given rootElements variable
     * If the element exists (same id) the existing is replaced without taking the index into account
     * @param menuElement menu element to from parent elements
     * @param menuElements rootElements where element should be added
     * @param index index before which the element is inserted (-1 = at the end = default)
     * @returns true if added, false if not present in
     */
    public static addOrReplaceMenuElementToArray(
        menuElement: TpzMenuElement,
        menuElements: TpzMenuElement[],
        index: number = -1
    ): boolean {
        if (!menuElement) return false;
        if (!menuElements) throw new Error('cannot add or replace element from an invalid menu element set');
        // check if the element already exist
        const existingElementIndex: number = SimpleMenuHelper.getMenuElementIndexByIdFromArray(
            menuElement.id,
            menuElements
        );
        if (existingElementIndex != -1) {
            // replace existing one
            const existingElement: TpzMenuElement = SimpleMenuHelper.getMenuElementByIndexFromArray(
                existingElementIndex,
                menuElements
            );
            return SimpleMenuHelper.replaceMenuElementByIndexToArray(
                { ...existingElement, ...menuElement },
                menuElements,
                existingElementIndex
            );
        }
        // add element at the end
        if (index < 0 || index >= menuElements.length) {
            menuElements.push(menuElement);
            return true;
        }
        // if it does not exist, add it at given index (-1 = the end)
        menuElements.splice(index, 0, menuElement);
        return true;
    }

    /**
     * replace a menu element from a given menu element array at given index
     * @param menuElement new menu element to use in replacement at given index
     * @param menuElements menu element array in which replacement has to be done
     * @param index index of replaced element
     * @returns true if replaced, false if index is invalid
     */
    static replaceMenuElementByIndexToArray(
        menuElement: TpzMenuElement,
        menuElements: TpzMenuElement[],
        index: number
    ): boolean {
        if (!menuElement) return false;
        if (!menuElements) throw new Error('cannot add or replace element from an invalid menu element set');
        if (index < 0 || index >= menuElements.length) return false;
        menuElements[index] = menuElement;
        return true;
    }

    /**
     * add a menu element to a parent menu element.
     * This method modifies the given parentElement variable
     * If the element exists (same id) the existing is replaced without taking the index into account
     * @param menuElement menu element name to from parent elements
     * @param parentElement parent Element where element should be added
     * @param index index before which the element is inserted (-1 = at the end = default)
     * @returns true if added, false if not present in
     */
    public static addOrReplaceMenuElement(
        menuElement: TpzMenuElement,
        parentElement: TpzMenuElement,
        index: number = -1
    ): boolean {
        if (!parentElement) return false;
        return SimpleMenuHelper.addOrReplaceMenuElementToArray(menuElement, parentElement.subElements);
    }

    /**
     * Returns all the menu elements parent of the given element
     * All elements in order are the nodes composing the branch from root to requested node
     * @param menuElementId menu element id
     * @returns All elements in order are the nodes composing the branch from root to requested node or null if not found
     */
    public static getMenuElementHierarchyById(rootElements: TpzMenuElement[], menuElementId: string): TpzMenuElement[] {
        if (!rootElements) return null;
        if (rootElements.length == 0) return [];
        let elementHierarchy: TpzMenuElement[] = null;
        // loop over all root elements
        rootElements.forEach((rootElement: TpzMenuElement) => {
            elementHierarchy = SimpleMenuHelper.getMenuElementHierarchyByIdRec(rootElement, menuElementId, []);
            // check if the branch has been found (stop looping over root nodes)
            if (elementHierarchy) return;
        });
        return elementHierarchy;
    }

    /**
     * Look for menu element (with given id) hierarchy. Recursive method
     * @param menuElement root menuElement
     * @param menuElementId menu element id to look for
     * @param menuHierarchy resulting branch
     */
    private static getMenuElementHierarchyByIdRec(
        menuElement: TpzMenuElement,
        menuElementId: string,
        menuHierarchy: TpzMenuElement[]
    ): TpzMenuElement[] {
        if (!menuElement) return menuHierarchy;
        const currentBranch: TpzMenuElement[] = [...menuHierarchy, menuElement];
        // check if element has been found
        if (menuElement.id == menuElementId) return currentBranch;
        // check if leaf is reached
        if (menuElement.subElements == null || menuElement.subElements.length == 0) return null;
        // loop over sub elements
        let elementHierarchy: TpzMenuElement[] = null;
        menuElement.subElements.forEach((childElement: TpzMenuElement) => {
            elementHierarchy = this.getMenuElementHierarchyByIdRec(childElement, menuElementId, currentBranch);
            // check if the branch has been found (stop looping over brither nodes)
            if (elementHierarchy) return;
        });
        return elementHierarchy;
    }

    /**
     * Creates simple menu elements for all registered factories grouped by categories
     * gives user new item creation ability
     */
    public static createCategorizedFactoriesMenuItem(application: TpzApplication): TpzMenuElement[] {
        const elements: TpzMenuElement[] = [];
        if (!application) return elements;
        // compute categories
        const categories: string[] = application.getFactoryManager().getRegisteredCategories();
        // loop over all categories
        for (const category of categories) {
            elements.push({
                id: category,
                name: category,
                i18n: category,
                subElements: SimpleMenuHelper.createRegisteredFactoriesMenuItem(application, (factory: ItemFactory) => {
                    return factory.getCategories().indexOf(category) != -1;
                })
            });
        }
        return elements;
    }

    /**
     * Creates simple menu elements for all registered factories
     * gives user new item creation ability
     */
    public static createRegisteredFactoriesMenuItem(
        application: TpzApplication,
        filter: (factory: ItemFactory) => boolean = null
    ): TpzMenuElement[] {
        const elements: TpzMenuElement[] = [];
        if (!application) return elements;
        const factories: ItemFactory[] = application.getFactoryManager().getRegisteredFactories(filter);
        if (!factories) return elements;
        factories.forEach((factory: ItemFactory) => {
            elements.push(SimpleMenuHelper.createHandledTypesMenuItem(factory, application));
        });
        return elements;
    }

    /**
     * Creates a simple menu element for all handled types of a factory
     */
    public static createHandledTypesMenuItem(factory: ItemFactory, application: TpzApplication): TpzMenuElement {
        if (!factory) return null;
        return {
            id: factory.getName(),
            name: factory.getName(),
            i18n: factory.getNameI18N(),
            subElements: factory
                .getHandledTypes()
                .map((type: string) => SimpleMenuHelper.createHandledTypeMenuItem(factory, type, application))
        };
    }

    /**
     * Creates a simple menu element for all handled types of a factory
     */
    public static createHandledTypeMenuItem(
        factory: ItemFactory,
        type: string,
        application: TpzApplication
    ): TpzMenuElement {
        if (!factory) throw new Error('Illegal argument factory in createHandledTypeMenuItem() method');
        if (!type) throw new Error('Illegal argument type in createHandledTypeMenuItem() method');
        if (!application) throw new Error('Illegal argument application in createHandledTypeMenuItem() method');
        const newItemConfig: ItemConfig = factory.getDefaultConfig(type);
        newItemConfig.id = `${newItemConfig.type}-${uuidv4()}`; // generated config new ID
        return {
            id: `${factory.getType()}-${type}`,
            name: type,
            i18n: `${type}-i18n`,
            action: () => {
                ItemEditorViewHelper.createItemEditorWindow(application, newItemConfig);
            },
            subElements: null
        };
    }

    /**
     * Creates all simple menu elements for all registered desktop of an application
     * menu element actions call application.displayDesktop()
     */
    public static createRegisteredDesktopMenuItems(app: TpzApplication): TpzMenuElement[] {
        const elements: TpzMenuElement[] = [];
        if (!app) return elements;
        app.getDesktopManager()
            .getRegisteredDesktopIds()
            .forEach((desktopId: string) => {
                elements.push({
                    id: `${desktopId}-items`,
                    name: desktopId,
                    subElements: [
                        {
                            id: `${desktopId}-set`,
                            name: 'set',
                            action: () =>
                                TpzApplicationCommander.openSingleDesktop(
                                    app,
                                    desktopId,
                                    TpzApplicationEventCategory.USER_INTERACTION
                                )
                        },
                        {
                            id: `${desktopId}-add`,
                            name: 'add',
                            action: () =>
                                TpzApplicationCommander.addDesktop(
                                    app,
                                    desktopId,
                                    TpzApplicationEventCategory.USER_INTERACTION
                                )
                        },
                        {
                            id: `${desktopId}-close`,
                            name: 'close',
                            action: () =>
                                TpzApplicationCommander.closeDesktop(
                                    app,
                                    desktopId,
                                    TpzApplicationEventCategory.USER_INTERACTION
                                )
                        }
                    ]
                });
            });

        return elements;
    }

    /**
     * Creates simple menu elements commands for current desktop of an application
     */
    public static createCurrentDesktopRegisteredItemsCommands(app: TpzApplication): TpzMenuElement[] {
        const activeDesktop: TpzDesktop = app?.getDesktopManager()?.getActiveDesktop();
        if (!activeDesktop) return [];
        if (!activeDesktop?.getConfig()?.childrenIds) return [];
        return SimpleMenuHelper.createRegisteredItemsCommands(app, activeDesktop.getReferencedItemConfigs());
    }

    /**
     * Creates all simple menu elements for given items
     */
    public static createRegisteredItemsCommands(app: TpzApplication, itemConfigs: ItemConfig[]): TpzMenuElement[] {
        if (!app) return [];
        const elements: TpzMenuElement[] = [];
        // add commands for all item configurations
        itemConfigs?.forEach((itemConfig: ItemConfig) => {
            elements.push(SimpleMenuHelper.createItemCommands(app, itemConfig));
        });
        return elements;
    }

    /**
     * Creates a menu element for an item commands
     * menu element actions call application.displayDesktop()
     * @param app main application
     * @param itemConfig item to create command menu element
     */
    private static createItemCommands(app: TpzApplication, itemConfig: ItemConfig): TpzMenuElement {
        if (!itemConfig) throw new Error('Illegal argument exception: itemConfig is not defined');
        return {
            id: `${itemConfig.id}-commands`,
            name: itemConfig.id,
            subElements: [
                {
                    id: `${itemConfig.id}-start`,
                    name: 'start',
                    action: () => TpzApplicationCommander.startItem(app, itemConfig.id)
                },
                {
                    id: `${itemConfig.id}-stop`,
                    name: 'stop',
                    action: () => TpzApplicationCommander.stopItem(app, itemConfig.id)
                },
                {
                    id: `${itemConfig.id}-pause`,
                    name: 'pause',
                    action: () => TpzApplicationCommander.pauseItem(app, itemConfig.id)
                },
                {
                    id: `${itemConfig.id}-info`,
                    name: 'info',
                    action: () => {
                        SimpleMenuHelper.displayItemParametersModule(app, itemConfig);
                    }
                }
            ]
        };
    }

    /**
     * Creates a new Item Parameter module
     * @param app main application
     * @param itemConfig itemconfig item configuration to display parameters
     */
    private static displayItemParametersModule(app: TpzApplication, itemConfig: ItemConfig): void {
        ItemEditorViewHelper.createItemEditorWindow(app, itemConfig);
    }

    /**
     * Creates a new window with a select addOn view
     * @param app main application
     */
    public static displaySelectAddOnView(app: TpzApplication): void {
        TpzAddOnSelectHelper.createSelectAddOnWindow(app);
    }
}
