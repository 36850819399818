/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzPlugin, TpzPluginConfig } from '../../tpz-plugin-core';
import { TpzApplicationEvent } from '../../../tpz-application-event';
import { deepCopy } from '../../../tools/deep-copy';

const DIV_CONTENT_PLUGIN_TYPE: string = 'DivContentPluginType';
/**
 *  Plugin configuration
 */
export interface DivContentPluginConfig extends TpzPluginConfig {
    css?: string[]; // text elements to display
    divId?: string; // div container id
    divClasses?: string[]; // div classes
    parentId?: string; // div parent Id (document body if not set)
    style?: CSSStyleDeclaration; // inner style declaration
    content?: string; // div content as string
}

/**
 *  Plugin default configuration
 */
export const defaultDivContentPluginConfig: DivContentPluginConfig = {
    id: 'Content',
    type: DIV_CONTENT_PLUGIN_TYPE,
    divId: 'content',
    css: null,
    divClasses: ['content'],
    style: {} as CSSStyleDeclaration,
    content: ''
};

/**
 * Content plugin adds a div into the main application html page
 */
export class DivContentPlugin extends TpzPlugin {
    public static readonly DIV_CONTENT_PLUGIN_TYPE: string = DIV_CONTENT_PLUGIN_TYPE; // plugin type
    private divContent: HTMLDivElement = null;

    /**
     * Constructor
     * @param config plugin configuration
     */
    constructor(config: DivContentPluginConfig) {
        super(deepCopy(defaultDivContentPluginConfig, config));
    }

    /**
     * Config getter specialization
     */
    public getConfig(): DivContentPluginConfig {
        return super.getConfig() as DivContentPluginConfig;
    }

    /**
     * Application event actions
     * @param event application event
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        if (!event) return false;
        if (event.source == this.getId()) return false;

        return super.onApplicationEvent(event);
    }

    /**
     * Action to perform when plugin plugs in
     */
    public onPlug(): Promise<TpzPlugin> {
        return super.onPlug().then(() => {
            this.insertDiv();
            return this;
        });
    }

    /**
     * Action to perform when plugin unplugs
     */
    public onUnplug(): Promise<TpzPlugin> {
        return super.onUnplug().then(() => {
            this.removeDiv();
            return this;
        });
    }

    /**
     * Return menu parent or body if not defined
     */
    public getParent(): HTMLElement {
        if (this.getConfig().parentId) {
            const parent: HTMLElement = document.getElementById(this.getConfig().parentId);
            if (!parent) {
                this.getLogger()?.error(
                    'Menu plugin ' + this.getId() + ' cannot be added to inexisting parent ' + this.getConfig().parentId
                );
                return null;
            }
            return parent;
        }
        return document.body;
    }

    /**
     * Insert Menu in DOM
     */
    private insertDiv(): void {
        this.getParent()?.appendChild(this.getContentDiv());
    }

    /**
     * Remove Menu from DOM
     */
    private removeDiv(): void {
        if (this.divContent) this.getParent()?.removeChild(this.divContent);
    }

    /**
     * div content lazy getter
     */
    public getContentDiv(): HTMLDivElement {
        if (!this.divContent) {
            this.divContent = document.createElement('div');
            this.divContent.id = this.getConfig().divId;
            // apply style defined in item configuration
            for (let i = 0; i < this.getConfig().style.length; i++) {
                (this.divContent.style as any)[this.getConfig().style[i]] = (this.getConfig().style as any)[
                    this.getConfig().style[i]
                ];
            }
            this.divContent.innerHTML = this.getConfig().content;
            this.divContent.classList.add(...this.getConfig().divClasses);
        }
        return this.divContent;
    }
}
