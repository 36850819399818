/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { JsPanel, JsModalPanel, JsModalPanelOptions, JsPanelTooltipOptions } from './jspanel-core';
import { I18N } from '../tpz-i18n/tpz-i18n-static';
import { uuidv4 } from './tools/uuid';

declare const jsPanel: JsPanel;

export type ClassesDescription = string | string[];
export type AttributesDescription = { [key: string]: string };
export interface CommonDescription {
    id?: string; // single HTML Element ID
    i18n?: string; // single HTML Element i18n (attribute "i18n":value)
    classes?: ClassesDescription; // classes added to a single HTML Element
    attributes?: AttributesDescription; // attributes added to a single HTML Element
}
export interface ParagraphDescription extends CommonDescription {
    text: string;
}
export interface PreDescription extends CommonDescription {
    text: string;
}
export interface SpanDescription extends CommonDescription {
    text?: string;
}
export interface LabelDescription extends CommonDescription {
    label: string;
}
export interface TextInputDescription extends CommonDescription {
    value: string;
}
export interface FieldSetDescription extends CommonDescription {
    legend: string;
}
export interface ButtonDescription extends CommonDescription {
    label: string;
}

export interface ComboBoxEntryDescription extends CommonDescription {
    label: string;
}

export interface DivDescription extends CommonDescription {}
export interface TableDescription extends CommonDescription {}
export interface GridTableDescription extends CommonDescription {}
export interface TableCellDescription extends CommonDescription {
    colspan?: string;
}
export interface CheckBoxDescription extends CommonDescription {
    disabled?: boolean;
    checked?: boolean;
}
export interface ComboBoxDescription extends CommonDescription {
    colspan?: string;
}

export interface ModalConfig {
    mainId: string;
    content: string;
    divClasses?: string[];
}

/**
 * Cell descriptor is composed of a cell HTMLElement and a configuration
 */
export interface TableElementDescriptor {
    element: HTMLElement | HTMLTableRowElement;
    config?: any;
}

export class TpzApplicationUI {
    private static readonly DARK_SCREEN_DIV_ID: string = 'DARK_SCREEN_ID';

    /** add classes to element"s class list */
    public static addClasses(element: HTMLElement, classes: ClassesDescription): HTMLElement {
        if (!element) return element;
        if (classes) {
            // array case
            if (Array.isArray(classes) && classes.length > 0) element.classList.add(...classes);
            // string case
            if (typeof classes === 'string' || classes instanceof String) element.classList.add(classes as string);
        }
        return element;
    }

    /** add attributes to element */
    public static setAttributes(element: HTMLElement, attributes: AttributesDescription): HTMLElement {
        if (!element) return element;
        if (attributes) for (const [key, value] of Object.entries(attributes)) element.setAttribute(key, value);
        return element;
    }

    /**
     * Set common attributes
     * config:
     * - id: string
     * - classes: string | string[]
     * - attributes: \{ [id:string]: string \}
     *
     * @param elt element to be set
     * @param config HTML element configuration
     */
    public static setHTMLElementCommonConfig(elt: HTMLElement, config: CommonDescription = null): void {
        if (!elt || !config) return;
        if (config?.classes) TpzApplicationUI.addClasses(elt, config.classes);
        if (config?.attributes) TpzApplicationUI.setAttributes(elt, config.attributes);
        if (config?.id) elt.id = config.id;
        if (config.i18n) TpzApplicationUI.setAttributes(elt, { i18n: config.i18n });
    }

    /**
     * Create a paragraph (<p>)
     * config:
     * - common
     * - text
     * @param config  HTML element configuration
     */
    public static createSpan(config: SpanDescription = null): HTMLSpanElement {
        const span: HTMLSpanElement = document.createElement('span');
        TpzApplicationUI.setHTMLElementCommonConfig(span, { id: config?.id, classes: config?.classes });
        span.classList.add('tpz-paragraph');
        span.innerText = config?.text ?? '';
        return span;
    }

    /**
     * Create a paragraph (<p>)
     * config:
     * - common
     * - text
     * @param config  HTML element configuration
     */
    public static createParagraph(config: ParagraphDescription = null): HTMLParagraphElement {
        const p: HTMLParagraphElement = document.createElement('p');
        TpzApplicationUI.setHTMLElementCommonConfig(p, { id: config?.id, classes: config?.classes });
        p.classList.add('tpz-paragraph');
        p.innerText = config?.text ?? '';
        return p;
    }

    /**
     * Create a preformatted text element (<pre>)
     * config:
     * - common
     * - text
     * @param config  HTML element configuration
     */
    public static createPre(config: PreDescription = null): HTMLPreElement {
        const p: HTMLPreElement = document.createElement('pre');
        TpzApplicationUI.setHTMLElementCommonConfig(p, { id: config?.id, classes: config?.classes });
        p.classList.add('tpz-pre');
        p.innerText = config?.text ?? '';
        return p;
    }

    /**
     * Create a label
     * config:
     * - label: string
     * @param config HTML element configuration
     */
    public static createLabel(config: LabelDescription = null): HTMLLabelElement {
        const label: HTMLLabelElement = document.createElement('label');
        TpzApplicationUI.setHTMLElementCommonConfig(label, { id: config?.id, classes: config?.classes });
        label.classList.add('tpz-label');
        label.innerText = config?.label ?? '';
        return label;
    }
    /**
     * Create a text input
     * @param config HTML element configuration
     */
    public static createTextInput(config: TextInputDescription = null): HTMLInputElement {
        const input: HTMLInputElement = document.createElement('input');
        TpzApplicationUI.setHTMLElementCommonConfig(input, { id: config?.id, classes: config?.classes });
        input.classList.add('tpz-input');
        input.value = config?.value ?? '';
        input.setAttribute('type', 'text');
        return input;
    }

    /**
     * Create a text input
     * @param config HTML element configuration
     */
    public static createPasswordInput(config: TextInputDescription = null): HTMLInputElement {
        const input: HTMLInputElement = document.createElement('input');
        TpzApplicationUI.setHTMLElementCommonConfig(input, { id: config?.id, classes: config?.classes });
        input.classList.add('tpz-input');
        input.value = config?.value ?? '';
        input.setAttribute('type', 'password');
        return input;
    }

    /** Create a FieldSet bloc with a text legend */
    public static createFieldSet(config: FieldSetDescription = null): HTMLFieldSetElement {
        const fieldset: HTMLFieldSetElement = document.createElement('fieldset');
        if (config?.legend) {
            const legend: HTMLLegendElement = document.createElement('legend');
            legend.innerHTML = config.legend;
            fieldset.appendChild(legend);
        }
        return fieldset;
    }

    /**
     * Create a button
     * @param config HTML element configuration
     */
    public static createButton(config: ButtonDescription = null): HTMLButtonElement {
        const button: HTMLButtonElement = document.createElement('button');
        TpzApplicationUI.setHTMLElementCommonConfig(button, {
            id: config?.id,
            i18n: config.i18n,
            classes: config?.classes,
            attributes: config?.attributes
        });
        button.setAttribute('type', 'button');
        button.classList.add('tpz-button');
        button.innerText = config?.label ?? '';
        return button;
    }

    /**
     * Create a check box
     * @param config HTML element configuration
     */
    public static createCheckBox(config: CheckBoxDescription = null): HTMLInputElement {
        const checkbox: HTMLInputElement = document.createElement('input');
        TpzApplicationUI.setHTMLElementCommonConfig(checkbox, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        checkbox.setAttribute('type', 'checkbox');
        checkbox.checked = config?.checked;
        if (config?.disabled) checkbox.setAttribute('disabled', '');
        checkbox.classList.add('tpz-checkbox');
        return checkbox;
    }

    /**
     * Create a combo box
     * @param config HTML element configuration
     */
    public static createComboBox(config: ComboBoxDescription = null): HTMLSelectElement {
        const select: HTMLSelectElement = document.createElement('select');
        TpzApplicationUI.setHTMLElementCommonConfig(select, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        select.classList.add('tpz-combobox');
        return select;
    }

    /**
     * Create an entry for a combo box
     * @param text text displayed in the combobox
     * @param value inner value returned when an element is selected. If null use the text as value
     */

    public static createComboBoxEntry(
        text: string,
        value: string,
        config: ComboBoxEntryDescription = null
    ): HTMLOptionElement {
        const option: HTMLOptionElement = document.createElement('option');
        TpzApplicationUI.setHTMLElementCommonConfig(option, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        option.innerText = text ?? 'unamed option';
        option.setAttribute('value', value ?? text);

        return option;
    }

    /**
     * Create a collapsible element containing a summary and a detailed content
     */
    public static createCollapsible(
        summary: string,
        detailsContent: HTMLElement,
        config: CommonDescription = null
    ): HTMLDetailsElement {
        const details: HTMLDetailsElement = document.createElement('details');
        TpzApplicationUI.setHTMLElementCommonConfig(details, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        details.innerHTML = `<summary>${summary}</summary>`;
        details.appendChild(detailsContent);
        details.classList.add('tpz-collapsible');
        return details;
    }

    public static createGridTable(nbCols: number, config: GridTableDescription = null): HTMLDivElement {
        const div: HTMLDivElement = document.createElement('div');
        TpzApplicationUI.setHTMLElementCommonConfig(div, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        // TODO: not a good idea to set style using javascript (at least padding...)
        div.style.display = 'grid';
        div.style.gridTemplateColumns = 'auto '.repeat(nbCols).trim();
        div.style.textAlign = 'left';
        div.style.padding = '0px 5px 0px 10px';
        return div;
    }

    /**
     * Create a table
     * @param rows row element Descriptor
     */
    public static createTable(config: TableDescription, rows: TableElementDescriptor[] = null): HTMLTableElement {
        const table: HTMLTableElement = document.createElement('table');
        TpzApplicationUI.setHTMLElementCommonConfig(table, {
            classes: config?.classes,
            attributes: config?.attributes
        });
        rows?.forEach((row: TableElementDescriptor) => {
            table.appendChild(row.element);
        });
        return table;
    }

    // /**
    //  * Create a header
    //  * @param config HTML element configuration
    //  */
    // public static createTableHeader(cells: TableElementDescriptor[]): HTMLTableHeaderCellElement {
    //     let header: HTMLTableHeaderCellElement = document.createElement("th");
    //     cells?.forEach((descriptor: TableElementDescriptor) => { header.appendChild(TpzApplicationUI.createTableCell(descriptor.config, descriptor.element)); });
    //     return header;
    // }

    /**
     * Create a table row <tr><td>Element 1</td><td>Element 2</td>...</tr>
     * @param config HTML element configuration
     */
    public static createTableRow(rowDescriptors: TableElementDescriptor[]): HTMLTableRowElement {
        const row: HTMLTableRowElement = document.createElement('tr');
        rowDescriptors?.forEach((descriptor: TableElementDescriptor) => {
            row.appendChild(TpzApplicationUI.createTableCell(descriptor.config, descriptor.element));
        });

        return row;
    }

    /**
     * Create a table cell => <td>Element</td>
     * @param config HTML element configuration
     */
    public static createTableCell(config: TableCellDescription, element: HTMLElement = null): HTMLTableCellElement {
        const cell: HTMLTableCellElement = document.createElement('td');
        if (config?.colspan) cell.setAttribute('colspan', config?.colspan);
        TpzApplicationUI.setHTMLElementCommonConfig(cell, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        cell.appendChild(element);
        return cell;
    }

    /**
     * Create a Div. If children are given, it adds them as children
     * @param config HTML element configuration
     * @param children HTML elements appended as children into the newly created div
     */
    public static createDiv(config: DivDescription = null, ...children: HTMLElement[]): HTMLDivElement {
        const div: HTMLDivElement = document.createElement('div');
        TpzApplicationUI.setHTMLElementCommonConfig(div, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        children?.forEach((child: HTMLElement) => div.appendChild(child));
        return div;
    }

    /**
     * Create a IMG html tag.
     * @param configsrc image source
     * @param config HTML element configuration
     */
    public static createImage(imgSrc: string, config: DivDescription = null): HTMLImageElement {
        const img: HTMLImageElement = document.createElement('img');
        img.src = imgSrc;
        TpzApplicationUI.setHTMLElementCommonConfig(img, {
            id: config?.id,
            classes: config?.classes,
            attributes: config?.attributes
        });
        return img;
    }

    /**
     * Add a tooltip to the given element
     * @param element element which tooltip is assigned
     * @param tooltipContent tooltip content displayed when tooltip is popped up
     * @param i18nId tooltip internationalization id
     */
    static tooltip(element: HTMLElement, tooltipContent: string, i18nId: string = null) {
        if (!element) {
            throw new Error(`Tooltip can only be applied to valid elements: ${tooltipContent}`);
        }
        // tooltip element needs an id
        if (!element.id) {
            element.id = `tooltip-${uuidv4()}`;
        }
        // create tooltip content
        const tooltipContentHTMLElement: HTMLParagraphElement = TpzApplicationUI.createParagraph({
            text: tooltipContent
        });
        if (i18nId) {
            tooltipContentHTMLElement.setAttribute(I18N.I18N_ATTRIBUTE, i18nId);
        }
        const options: JsPanelTooltipOptions = {
            target: element,
            delay: 100,
            content: tooltipContentHTMLElement,
            mode: 'default',
            connector: true,
            // position: { my: 'center-top', at: 'center-bottom' },
            theme: 'dark filleddark',
            position: { at: 'center-bottom', minTop: 0, minLeft: 0 },
            contentSize: 'auto',
            header: false,
            onfronted: [
                // keep on top of all other elements
                (panel: JsPanel, _: any) => {
                    console.log(`front : tooltip id: #${(panel as unknown as HTMLElement).id}`);
                    (panel as unknown as HTMLElement).style.zIndex = '50000';
                }
            ]
            // callback: [
            //     (panel: JsPanel) => {
            //         console.log(`cb : tooltip id: #${(panel as unknown as HTMLElement).id}`);
            //         // reposition where there is space
            //         const rect: DOMRect = (panel as unknown as HTMLElement).getBoundingClientRect();
            //         const screenWidth: number = screen.width;
            //         const screenHeight: number = screen.height;
            //         // comute distance to the screen edges
            //         const dLeft: number = rect.left;
            //         const dTop: number = rect.top;
            //         const dRight: number = screenWidth - rect.right;
            //         const dBottom: number = screenHeight - rect.bottom;
            //         if (dLeft >= dTop && dLeft >= dRight && dLeft >= dBottom) {
            //             jsPanel.tooltip.reposition(panel, { my: 'right-center', at: 'left-center' });
            //         } else if (dRight >= dTop && dRight >= dLeft && dRight >= dBottom) {
            //             jsPanel.tooltip.reposition(panel, { my: 'left-center', at: 'right-center' });
            //         } else if (dTop >= dRight && dTop >= dLeft && dTop >= dBottom) {
            //             jsPanel.tooltip.reposition(panel, { my: 'center-bottom', at: 'center-top' });
            //         } else {
            //             jsPanel.tooltip.reposition(panel, { my: 'center-top', at: 'center-bottom' });
            //         }
            //     }
            // ]
        };
        jsPanel.tooltip.create(options);
    }
}

/**
 * Button descriptor for modal windows
 * id: ID passed to the onClickedButton method
 * text: label displayed in the button
 * i18n: value set to the button "i18n" attribute
 */
export interface ModalWindowButtonDescriptor {
    id: string;
    text: string;
    i18n: string;
}

/**
 * Modal class containing three parts: Top, Middle and Bottom
 * Buttom Div is filled with given Modal Buttons
 * Create a new modal class with: modal = new ModalWindow(...)
 * Display the modal window with: modal.open()
 *
 * the 'onOpen()' method is called once the window is created and displayed
 * the onClickButton() method is called each time a button is clicked
 * onClickButton( win: ModalWindow, buttonId: string ) buttonId is the 'id' field of the clicked button
 *
 * to insert your UI:
 * - modal=new ModalWindow(...)
 * - modal.onOpen = (w) => w.getMiddleDiv().appendChild(...)
 * - modal.open()
 */
export class ModalWindow {
    public onClickButton: (modalWindow: ModalWindow, buttonId: string) => boolean = null;
    public onOpen: (modalWindow: ModalWindow) => void = null;

    private panel: JsModalPanel = null; // jsPanel modal window
    private panelOptions: JsModalPanelOptions = null;
    private containerDiv: HTMLDivElement = null;
    private topDiv: HTMLDivElement = null;
    private middleDiv: HTMLDivElement = null;
    private bottomDiv: HTMLDivElement = null;
    private title: string = 'Modal Window';
    private titleI18N: string = 'modal-window-title';
    private buttonDescriptors: { [id: string]: ModalWindowButtonDescriptor } = {};
    private defaultButtonDescriptors: ModalWindowButtonDescriptor[] = [
        { id: 'ok', text: 'ok', i18n: 'ok-button' },
        { id: 'cancel', text: 'cancel', i18n: 'cancel-button' }
    ];
    private buttons: { [id: string]: HTMLButtonElement } = {};

    /**
     * constructor
     * @param title (optional) window title (default = "Modal Window")
     * @param buttonDescriptors (optional) window buttons to displays (default = "ok" & "cancel")
     */
    constructor(
        title: string = null,
        titleI18N: string = null,
        buttonDescriptors: ModalWindowButtonDescriptor[] = null
    ) {
        if (title) {
            this.title = title;
        }
        if (titleI18N) {
            this.titleI18N = titleI18N;
        }
        // set default buttons if needed
        if (!buttonDescriptors) {
            buttonDescriptors = this.defaultButtonDescriptors;
        }
        if (buttonDescriptors) {
            buttonDescriptors.forEach((buttonDescriptor: ModalWindowButtonDescriptor) => {
                this.addButtonDescriptor(buttonDescriptor);
            });
        }
        this.panelOptions = {
            // theme: 'info filleddark',
            headerTitle: this.getTitle(),
            content: this.getContainerDiv(),
            closeOnBackdrop: false,
            callback: [
                () => {
                    if (this.onOpen) this.onOpen(this);
                }
            ]
        };
    }

    /**
     * add a button descriptor to the buttons descriptors list
     * @param buttonDescriptor
     */
    public addButtonDescriptor(buttonDescriptor: ModalWindowButtonDescriptor): void {
        if (!buttonDescriptor) {
            return;
        }
        if (!this.buttonDescriptors) this.buttonDescriptors = {};
        this.buttonDescriptors[buttonDescriptor.id] = buttonDescriptor;
    }

    /**
     * display the modal window
     */
    public open(): JsPanel {
        // check if modal extension has been added
        if (!jsPanel.modal) throw new Error('jsPanel modal extension has not been loaded...');
        this.panel = jsPanel.modal.create(this.getModalPanelOptions());
        return this.panel;
    }

    /**
     * close modal window
     */
    public close(): void {
        this.panel?.close();
    }

    /**
     * get panel options used to create the new window
     */
    public getModalPanelOptions(): JsModalPanelOptions {
        return this.panelOptions;
    }

    /**
     * get panel options used to create the new window
     */
    public setPanelOptions(options: JsModalPanelOptions): void {
        this.panelOptions = options;
    }

    /** Title getter  */
    public getTitle(): string {
        return this.title;
    }

    /**
     * get the main container div
     */
    public getContainerDiv(): HTMLDivElement {
        if (!this.containerDiv) {
            this.containerDiv = document.createElement('div');
            this.containerDiv.classList.add('modal-container');
            this.containerDiv.appendChild(this.getTopDiv());
            this.containerDiv.appendChild(this.getMiddleDiv());
            this.containerDiv.appendChild(this.getBottomDiv());
        }
        return this.containerDiv;
    }
    /**
     * get the top div contained in the container Div
     */
    public getTopDiv(): HTMLDivElement {
        if (!this.topDiv) {
            this.topDiv = document.createElement('div');
        }
        return this.topDiv;
    }

    /**
     * get the middle div contained in the container Div
     */
    public getMiddleDiv(): HTMLDivElement {
        if (!this.middleDiv) {
            this.middleDiv = document.createElement('div');
        }
        return this.middleDiv;
    }

    /**
     * get the HTML button associated with the given id
     * @param id button id
     * @returns null if not in button id list or associated HTML Button Element
     */
    public getButton(id: string): HTMLButtonElement {
        if (!id) return null;
        const modalButton: ModalWindowButtonDescriptor = this.buttonDescriptors[id];
        if (!modalButton) return null;
        let button: HTMLButtonElement = this.buttons[id];
        if (!button) {
            // if button is not already created, just create it
            button = TpzApplicationUI.createButton({
                label: modalButton.text,
                i18n: modalButton.i18n,
                id: `button-${id}`
            });
            // launch the onClickButton() method with the id button
            // if return value = true => close the modal window
            button.addEventListener('click', (evt: MouseEvent) => {
                let close: boolean = true;
                if (this.onClickButton) {
                    close = this.onClickButton(this, modalButton.id);
                }
                if (close && this.panel) {
                    this.panel.close();
                }
            });
            // store button in cache
            this.buttons[id] = button;
        }
        return button;
    }
    /**
     * get the bottom div contained in the container Div
     */
    private getBottomDiv(): HTMLDivElement {
        if (!this.bottomDiv) {
            this.bottomDiv = document.createElement('div');
            Object.keys(this.buttonDescriptors).forEach((buttonId: string) => {
                const button: HTMLButtonElement = this.getButton(buttonId);
                this.bottomDiv.appendChild(button);
            });
        }
        return this.bottomDiv;
    }

    /**
     * Add a message in the middle form.
     * recommended tags are 'ok', 'info', 'warn', 'error'
     * @param interface interface can be anything, it is added as "type=value" in <pre> tag
     * @param message String message to be added
     */
    public addMessage(type: string, message: string): void {
        const middleDiv: HTMLDivElement = this.getMiddleDiv();
        if (!middleDiv) return;
        const pre: HTMLPreElement = TpzApplicationUI.createPre();
        pre.classList.add(type);
        pre.innerHTML = message;
        middleDiv.append(pre);
    }

    /**
     * Display a message in the middle form.
     * recommended tags are 'ok', 'info', 'warn', 'error'
     * @param interface interface can be anything, it is added as "type=value" in <pre> tag
     * @param message String message to be set
     */
    public setMessage(type: string, message: string): void {
        const middleDiv: HTMLDivElement = this.getMiddleDiv();
        if (!middleDiv) return;
        // clear middle form
        middleDiv.innerHTML = '';
        // then add message
        this.addMessage(type, message);
    }
}
