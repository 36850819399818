/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ItemConfig } from '../tpz-catalog/tpz-item-config';
import { TpzDesktopManagerState } from './desktop/tpz-desktop-manager';
import { TpzApplication, TpzApplicationConfig } from './tpz-application-core';
import { TpzApplicationCommander } from './tpz-application-commander';

/**
 * Data structure received from front end user data service
 * /users/\{id\}/data/get
 * It is a set/subset of all saved application states
 */
export interface FrontEndUserData {
    userId: string;
    applicationStates: TpzApplicationState[];
}

/**
 * Application state at a given time
 * It contains
 * - a name, an id and a date
 * - the application configuration
 * - the desktops configurations
 */
export interface TpzApplicationState {
    id?: string;
    name?: string;
    date?: number;
    applicationConfig?: TpzApplicationConfig;
    desktopManagerState?: TpzDesktopManagerState;
    itemCatalogConfigs?: ItemConfig[];
}

/**
 * Helper methods to load and save application states
 */
export class TpzApplicationStateHelper {
    /**
     * save the application state at the given URL
     * @param saveStateURL URL where to save the application state
     * @param app topaz application
     * @returns
     */
    static saveApplicationState(
        saveStateURL: string,
        stateId: string,
        stateName: string,
        app: TpzApplication
    ): Promise<void> {
        if (!saveStateURL) return Promise.reject(new Error('Invalid application state URL in saveApplicationState'));
        if (!app) return Promise.reject(new Error('Invalid application in saveApplicationState'));

        const applicationState: TpzApplicationState = app.getApplicationState(stateId, stateName);
        console.log('state = ' + JSON.stringify(applicationState, null, 2));
        const options: RequestInit = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(applicationState)
        };
        return app.fetchTopazServerWithProtocolValid(saveStateURL, options).then((content: any) => {
            app.sendNotification('INFO', 'State saved correctly: ' + content);
        });
    }

    /**
     * load an application state from the given URL and set it to given application
     * @param loadStateURL URL where to retrieve the application states
     * @param app topaz application
     * @returns a promise on loaded application state
     */
    static loadApplicationState(loadStateURL: string, app: TpzApplication): Promise<TpzApplicationState> {
        if (!loadStateURL) return Promise.reject(new Error('Invalid application state URL in loadApplicationState'));
        if (!app) return Promise.reject(new Error('Invalid application in loadApplicationState'));
        return app.fetchTopazServerWithProtocolValid(loadStateURL).then((content: any) => {
            // content is a string containing a JSON description of a state
            let state: TpzApplicationState = null;
            try {
                state = JSON.parse(content) as TpzApplicationState;
            } catch (reason: any) {
                throw new Error('Application state received from ' + loadStateURL + ' is not a valid json string');
            }
            TpzApplicationCommander.setApplicationState(app, state);
            return state;
        });
    }
}
