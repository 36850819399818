/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Event management for internal communication
 */
export interface TpzApplicationEvent {
    source: string; // event source identifier
    date: number; // time at which event has been created
    type: string; // event type
    content: any; // event content (content type depends on event type)
    categories: string[]; // event categories
}

/**
 * Helper functions foe application events
 */
export class TpzApplicationEventHelper {
    /**
     * Returns a reason why the given object is not a valid TpzApplicationEvent
     * @param putativeEvent event to check
     * @returns an array of strings explaining why the object is not a TpzApplicationEvent. Returns null if event is ok
     */
    public static getInvalidEventReasons(putativeEvent: any): string[] {
        if (!putativeEvent) return ['Outgoing message is null'];
        const reasons: string[] = [];

        if (putativeEvent['source'] === undefined) reasons.push('source is not defined');
        if (typeof putativeEvent.source !== 'string') {
            reasons.push('source should be a string: ' + typeof putativeEvent.source);
        }
        if (putativeEvent.source === '') reasons.push('source should be a non empty string');

        if (putativeEvent['date'] === undefined) reasons.push('date is not defined');
        if (typeof putativeEvent.date !== 'number') {
            reasons.push('date should be a number: ' + typeof putativeEvent.date);
        }
        if (putativeEvent.date < 0) reasons.push('date should be a positive number: ' + putativeEvent.date);

        if (putativeEvent['type'] === undefined) reasons.push('type is not defined');
        if (typeof putativeEvent.type !== 'string') {
            reasons.push('type should be a string: ' + typeof putativeEvent.type);
        }
        if (putativeEvent.type === '') reasons.push('type should be a non empty string');

        if (putativeEvent['content'] === undefined) reasons.push('content is not defined');

        if (putativeEvent['categories'] === undefined) reasons.push('categories is not defined');
        if (!Array.isArray(putativeEvent.categories)) {
            reasons.push('categories should be an array: ' + typeof putativeEvent.categories);
        }

        return reasons.length === 0 ? null : reasons;
    }

    /**
     * check if given object is a valid TpzApplicationEvent
     * @param putativeEvent object to be checked
     * @returns true if it is valid, false if not
     */
    public static getValidEvent(putativeEvent: any): TpzApplicationEvent {
        if (TpzApplicationEventHelper.getInvalidEventReasons(putativeEvent)) return null;
        return putativeEvent as TpzApplicationEvent;
    }
}

/**
 * static class containing all event of a Topaz Application
 */
export class TpzApplicationEventType {
    // application
    public static readonly APPLICATION_STARTING: string = 'APPLICATION_STARTING'; // null
    public static readonly APPLICATION_PRESTARTING: string = 'APPLICATION_PRESTARTING'; // null
    public static readonly APPLICATION_POSTSTARTING: string = 'APPLICATION_POSTSTARTING'; // null
    public static readonly APPLICATION_STARTED: string = 'APPLICATION_STARTED'; // null
    public static readonly APPLICATION_STOPPING: string = 'APPLICATION_STOPPING'; // null
    public static readonly APPLICATION_STOPPED: string = 'APPLICATION_STOPPED'; // null
    public static readonly SET_APPLICATION_STATE_REQUEST: string = 'SET_APPLICATION_STATE_REQUEST'; // { applicationState: state }
    // factory management
    public static readonly ITEM_FACTORY_REGISTERED: string = 'ITEM_FACTORY_REGISTERED'; // { factory: ItemFactory }
    public static readonly ITEM_FACTORY_UNREGISTERED: string = 'ITEM_FACTORY_UNREGISTERED'; // { factoryId: string }

    // logger
    public static readonly LOGGER_CHANGED: string = 'LOGGER_CHANGED';
    // desktop
    // public static readonly CREATE_DESKTOP_REQUEST: string = "NEW_DESKTOP_REQUEST"; // {desktopId: string }
    public static readonly DESKTOP_CREATED: string = 'DESKTOP_CREATED'; // {desktopId: string }
    public static readonly DESKTOP_ADD_REQUEST: string = 'DESKTOP_START_REQUEST'; // {desktopId: string }
    public static readonly DESKTOP_SET_REQUEST: string = 'DESKTOP_START_SINGLE_REQUEST'; // {desktopId: string }
    public static readonly DESKTOP_DISPLAYED: string = 'DESKTOP_DISPLAYED'; // { desktopId: string }
    public static readonly DESKTOP_UNDISPLAYED: string = 'DESKTOP_UNDISPLAYED'; // { desktopId: string }
    public static readonly DESKTOP_CLOSE_REQUEST: string = 'DESKTOP_CLOSE_REQUEST'; // { desktopId: string }
    // public static readonly DESKTOP_STOP_IF_EMPTY_REQUEST: string = "DESKTOP_STOP_IF_EMPTY_REQUEST"; // { desktopId: string }
    public static readonly DESKTOP_CLOSE_ALL_REQUEST: string = 'DESKTOP_STOP_ALL_REQUEST'; // null
    public static readonly DESKTOP_ACTIVATE_REQUEST: string = 'DESKTOP_ACTIVATE_REQUEST'; //{ desktopId: string }
    public static readonly DESKTOP_ACTIVATED: string = 'DESKTOP_ACTIVATED'; //{ activatedDesktopId: desktopId, deactivatedDesktopId: desktopId }
    // public static readonly DESKTOP_CONFIG_REGISTERED: string = "DESKTOP_CONFIG_REGISTERED";  // { desktopConfig: TpzDesktopConfig }
    // public static readonly DESKTOP_CONFIG_UNREGISTERED: string = "DESKTOP_CONFIG_UNREGISTERED"; // { desktopId: string }
    // public static readonly START_DESKTOP_ITEM_REQUEST: string = "START_DESKTOP_ITEM_REQUEST"; // { desktopId: string, itemId: string }
    // public static readonly STOP_DESKTOP_ITEM_REQUEST: string = "STOP_DESKTOP_ITEM_REQUEST"; // { itemId: string, stopParents: void }
    // public static readonly PAUSE_DESKTOP_ITEM_REQUEST: string = "PAUSE_DESKTOP_ITEM_REQUEST"; // { itemId: string }
    // public static readonly ADD_DESKTOP_ITEM_REQUEST: string = "ADD_DESKTOP_ITEM_REQUEST"; // { desktopId: string, itemConfig: ItemConfig }
    // public static readonly REMOVE_DESKTOP_ITEM_REQUEST: string = "REMOVE_DESKTOP_ITEM_REQUEST"; // { itemId: string }

    // public static readonly CHILD_ITEM_ADDED: string = "CHILD_ITEM_ADDED"; // { parentId: string, childId: string }
    // public static readonly CHILD_ITEM_REMOVED: string = "CHILD_ITEM_REMOVED"; // { parentId: string, childId: string }

    // plugins
    public static readonly PLUGIN_STARTED: string = 'PLUGIN_STARTED';
    public static readonly PLUGIN_STOPPED: string = 'PLUGIN_STOPPED';

    // items
    public static readonly ITEM_START_REQUEST: string = 'ITEM_START_REQUEST'; // { itemId: string }
    public static readonly ITEM_PAUSE_REQUEST: string = 'ITEM_PAUSE_REQUEST'; // { itemId: string }
    public static readonly ITEM_STOP_REQUEST: string = 'ITEM_STOP_REQUEST'; // { itemId: string }

    public static readonly ITEM_REQUEST_UPDATE: string = 'ITEM_REQUEST_UPDATE'; // { itemId: string, force: boolean }
    public static readonly ITEM_STATE_CHANGED: string = 'ITEM_STATE_CHANGED'; // { itemId: string, oldState: string, newState: string }
    public static readonly ITEM_CONFIG_REGISTERED: string = 'ITEM_CONFIG_REGISTERED'; // { itemConfig: ItemConfig }
    public static readonly ITEM_CONFIG_UNREGISTERED: string = 'ITEM_CONFIG_REGISTERED'; // { itemConfig: ItemConfig }
    public static readonly ITEM_CONFIG_REGISTER_REQUEST: string = 'ITEM_CONFIG_REGISTER_REQUEST'; // { itemConfig: ItemConfig }
    public static readonly ITEM_CONFIG_UNREGISTER_REQUEST: string = 'ITEM_CONFIG_UNREGISTER_REQUEST'; // { itemId: string }
    public static readonly ITEM_CONFIG_UPDATE_REQUEST: string = 'ITEM_CONFIG_UPDATE_REQUEST'; // { itemConfig: ItemConfig }
    public static readonly INSTANCE_ITEM_CONFIG_UPDATED: string = 'INSTANCE_ITEM_CONFIG_UPDATED'; // { itemConfig: ItemConfig }
    public static readonly CATALOG_ITEM_CONFIG_UPDATED: string = 'CATALOG_ITEM_CONFIG_UPDATED'; // { itemConfig: ItemConfig }
    // public static readonly ADD_CHILD_ITEM_REQUEST: string = 'ADD_CHILD_ITEM_REQUEST'; // { parentId: string, childId: string, start: boolean }
    // public static readonly REMOVE_CHILD_ITEM_REQUEST: string = 'REMOVE_CHILD_ITEM_REQUEST'; // { parentId: string, childId: string, stopParents: boolean }

    // public static readonly ITEM_PLUG_REQUEST: string = 'ITEM_PLUG_REQUEST'; // { childId: string, parentId: string }
    // public static readonly ITEM_UNPLUG_REQUEST: string = 'ITEM_UNPLUG_REQUEST'; // { childId: string, parentId: string }
    public static readonly ITEM_PLUGGED: string = 'ITEM_PLUGGED'; // { childId: string, parentId: string }
    public static readonly ITEM_UNPLUGGED: string = 'ITEM_UNPLUGGED'; // { childId: string, parentId: string }

    // AddOns
    public static readonly ADDONS_REGISTRY_UPDATED: string = 'ADDONS_REGISTRY_UPDATED'; // null
    public static readonly ADDON_STARTED: string = 'ADDON_STARTED'; // { addOnId: string }
    public static readonly ADDON_START_ERROR: string = 'ADDON_START_ERROR'; // { addOnId: string }
    public static readonly ADDON_STOPPED: string = 'ADDON_STOPPED'; // { addOnId: string }
    public static readonly ADDON_STOP_ERROR: string = 'ADDON_STOP_ERROR'; // { addOnId: string }

    // Libraries
    public static readonly LIBRARY_SERVER_REGISTERED: string = 'LIBRARY_SERVER_REGISTERED'; // { libraryServerURL: string }
    public static readonly LIBRARY_REGISTERED: string = 'LIBRARY_REGISTERED'; // { libraryId: string }

    // Notifications
    public static readonly NOTIFICATION: string = 'NOTIFICATION'; // notification: Notification

    // Server connection
    public static readonly SERVER_INFORMATION: string = 'SERVER_INFORMATION'; // { serverInformation: TpzServerInformation }
    public static readonly SERVER_CONNECTED: string = 'SERVER_CONNECTED'; // { sessionInformation: ClientSessionInformation }
    public static readonly SERVER_CONNECTION_ERROR: string = 'SERVER_CONNECTION_ERROR'; // { url: string, error: Error }
    public static readonly SERVER_DISCONNECTED: string = 'SERVER_DISCONNECTED'; // { sessionInformation: ClientSessionInformation }
    public static readonly WEBSOCKET_CONNECTED: string = 'WEBSOCKET_CONNECTED'; // null
    public static readonly WEBSOCKET_DISCONNECTED: string = 'WEBSOCKET_DISCONNECTED'; // null

    public static readonly SET_USER_CREDENTIALS: string = 'SET_USER_CREDENTIALS'; // { authToken: string } credentials to be added to any request
    public static readonly CHANGE_LANGUAGE: string = 'CHANGE_LANGUAGE'; // { lang: string } language

    // server websocket exchanges (Server Messages)
    public static readonly SEND_SERVER_MESSAGE_REQUEST: string = 'SEND_SERVER_MESSAGE_REQUEST'; // { message: TpzServerOutgoingMessage }
    public static readonly SERVER_MESSAGE_SENT: string = 'SERVER_MESSAGE_SENT'; // { message: TpzServerOutgoingMessage }
    public static readonly SERVER_MESSAGE_RECEIVED: string = 'SERVER_MESSAGE_RECEIVED'; // { message: TpzServerOutgoingMessage } // { sessionSourceId: string, message: TpzEvent }
    public static readonly PING_PONG_RESULTS_UPDATED: string = 'PING_PONG_RESULTS_UPDATED'; // { pingPongResult: PingPongResult }
    // public static readonly SEND_SERVER_SYNC_MESSAGE_REQUEST: string = "SEND_SERVER_SYNC_MESSAGE_REQUEST"; // { message: any }
    // public static readonly SERVER_SYNC_MESSAGE_RECEIVED: string = "SERVER_SYNC_MESSAGE_RECEIVED"; // { event: any }

    // Window
    public static readonly WINDOW_RESIZED: string = 'WINDOW_RESIZED'; // null

    // View
    public static readonly VIEW_UI_UPDATED: string = 'VIEW_UI_UPDATED'; // { viewId: string}

    /**
     * This method checks if event content contains given arguments
     * @param event event content to be checked
     * @param args list of mandatory elements
     * @returns nothing, it throws exception
     * @throws an error if an argument is missing
     */
    public static checkEvent(event: TpzApplicationEvent, ...args: string[]): void {
        let nbArgs: number = 0;
        // count only non null fields
        if (args) {
            args.forEach((arg: string) => {
                if (arg) nbArgs++;
            });
        }
        // check if event is well formed
        if (!event) throw new Error('Event should be defined');
        if (!event.type) throw new Error('Event should have at list a defined type');
        // check if the content is a dictionnary
        if (event.content && event.content.constructor !== Object) {
            throw new Error(event.type + ' Event content should be a dictionary. it is a ' + typeof event.content);
        }
        // check no args => content is null or empty dictionary
        if (!args || nbArgs === 0) {
            if (event.content === null) return;
            if (Object.keys(event.content).length !== 0) {
                throw new Error(
                    event.type + ' Event content should not contain fields: ' + Object.keys(event.content).join(', ')
                );
            }
            return;
        }
        // check args count
        if ((!event.content && nbArgs !== 0) || (event.content && Object.keys(event.content).length !== nbArgs)) {
            throw new Error(
                event.type +
                    ' Event content should contain fields: ' +
                    args.map((x) => (x ? "'" + x + "'" : 'null')).join(', ') +
                    ' but contains: ' +
                    Object.keys(event.content)
                        .map((x) => (x ? "'" + x + "'" : 'null'))
                        .join(', ')
            );
        }
        // check args
        args?.forEach((arg: string) => {
            // take care:
            // (null === undefined): true
            // (typeof null === "undefined"): false
            if (arg && typeof event.content[arg] === 'undefined') {
                console.error(
                    'field ' +
                        arg +
                        ' is undefined in event ' +
                        event.type +
                        ". May be you meant 'null' instead of 'undefined'. Difference matters"
                );
                throw new Error(
                    'event ' +
                        event.type +
                        " should contain field '" +
                        arg +
                        "'. Content = " +
                        Object.keys(event.content)
                            .map((x) => (x ? "'" + x + "'" : 'null'))
                            .join(', ')
                );
            }
        });
    }
}

/**
 * static class containing events categories
 * INTERNAL is used for Events Managing the application inner process
 * Quite all Events from TpzApplicationEventType are INTERNAL
 * Events added by developpers for specific applications are likely not INTERNAL
 */
export class TpzApplicationEventCategory {
    public static readonly APPLICATION_INTERNAL_CATEGORY = 'INTERNAL';
    public static readonly USER_INTERACTION = 'USER_INTERACTION_EVENT_CATEGORY';
}
