/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzApplicationUI } from '../tpz-application-ui';
import { ProgressLoader, ProgressLoaderConfig } from './progress-loader';

/**
 * Progress Loader configuration
 */
export type HtmlProgressLoaderConfig = ProgressLoaderConfig;

/**
 * Progress Loader Object
 * Progress Loader show the progression of some long term actions
 */
export class HtmlProgressLoader extends ProgressLoader {
    public static readonly HTML_PROGRESS_LOADER_TYPE: string = 'HtmlProgressLoaderType';
    private textDiv: HTMLDivElement = null;
    private titleDiv: HTMLDivElement = null;
    private progressDiv: HTMLDivElement = null;

    /**
     * constructor
     */
    constructor(source: HTMLElement, config: HtmlProgressLoaderConfig) {
        super(source, config);
        this.getConfig().type = HtmlProgressLoader.HTML_PROGRESS_LOADER_TYPE;
    }

    /**
     * configuration getter specialization
     */
    public getConfig(): HtmlProgressLoaderConfig {
        return super.getConfig() as HtmlProgressLoaderConfig;
    }

    /**
     * source getter specialization
     */
    public getSource(): HTMLElement {
        return super.getSource() as HTMLElement;
    }

    /**
     * Description Line Text Div
     */
    private getTextDiv(): HTMLDivElement {
        if (!this.textDiv) {
            this.textDiv = TpzApplicationUI.createDiv({ classes: ['progress-loader-text'] });
        }
        return this.textDiv;
    }

    /**
     * Title Div
     */
    private getTitleDiv(): HTMLDivElement {
        if (!this.titleDiv) {
            this.titleDiv = TpzApplicationUI.createDiv({ classes: ['progress-loader-title'] });
            this.titleDiv.innerHTML = this.getConfig().title;
        }
        return this.titleDiv;
    }

    /**
     * Progress Div used to display progressBar
     */
    private getProgressDiv(): HTMLDivElement {
        if (!this.progressDiv) {
            this.progressDiv = TpzApplicationUI.createDiv({ classes: ['progress-loader-bar'] });
            this.progressDiv.style.minHeight = '1px';
        }
        return this.progressDiv;
    }

    /**
     * Parent is the source (which is an HTMLElement for this Progress Loader)
     */
    public getHTMLParentElement(): HTMLElement {
        return this.getSource();
    }

    /**
     * Create UI
     */
    protected createUI(parent: HTMLDivElement): void {
        parent.appendChild(this.getTitleDiv());
        parent.appendChild(this.getProgressDiv());
        parent.appendChild(this.getTextDiv());
    }

    /**
     * update UI Elements (do nothing if mainContainer is not already created)
     */
    protected updateUI(): void {
        if (!this.isDisplayed()) return;
        if (this.isIndeterminate()) {
            this.getMainContainer().classList.add(ProgressLoader.INDETERMINATED_CLASS);
            this.getMainContainer().classList.remove(ProgressLoader.VALUED_CLASS);
            this.getTextDiv().innerHTML = this.getDescriptionLines()
                .map((line) => `<p>${line}</p>`)
                .join('\n');
        } else {
            this.getMainContainer().classList.remove(ProgressLoader.INDETERMINATED_CLASS);
            this.getMainContainer().classList.add(ProgressLoader.VALUED_CLASS);
            const percent = this.getProgressValue() * 100;
            this.getTextDiv().innerHTML = this.getConfig().displayPercentage ? `<p>${percent}</p>` : '';
            this.getProgressDiv().style.width = `${percent}%`;
        }
    }
}
