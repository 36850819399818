/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Menu toolbar element configuration
 */
export interface MenuToolbarElementConfig {
    id: string;
    icon: string;
}

/**
 * Menu toolbar element definition
 */
export class MenuToolbarElement {
    private readonly config: MenuToolbarElementConfig = null;
    private container: HTMLDivElement = null;
    private icon: HTMLImageElement = null;

    /** Constructor */
    constructor(config: MenuToolbarElementConfig) {
        this.config = config;
    }

    /** Config getter */
    public getConfig(): MenuToolbarElementConfig {
        return this.config;
    }

    /** get main div element container */
    public getMainContainer(): HTMLDivElement {
        if (!this.container) {
            this.container = document.createElement('div');
            this.container.appendChild(this.getIcon());
        }
        return this.container;
    }

    /** get element icon */
    public getIcon(): HTMLImageElement {
        if (!this.icon) {
            this.icon = document.createElement('img');
            this.icon.src = this.getConfig().icon;
            this.icon.addEventListener('click', this.onClickEvent.bind(this));
        }
        return this.icon;
    }

    /** Action performed when icon is clicked */
    public onClickEvent(event: MouseEvent): void {
        alert('Menu Element ' + this.getConfig().id + ' clicked');
    }

    /** get UI */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }
}

/**
 * Menu toolbar contains some Menu toolbar elements (interactive icons)
 */
export class MenuToolbar {
    private readonly elements: { [id: string]: MenuToolbarElement } = {};
    private container: HTMLDivElement = null;

    /**
     * Add a menu element in toolbar
     * @param element toolbar element to add
     */
    public addElement(element: MenuToolbarElement): boolean {
        if (!element) return false;
        this.elements[element.getConfig().id] = element;
        this.updateUI();
        return true;
    }

    /** Get a toolbar element by its ID */
    public getElementById(id: string): MenuToolbarElement {
        return this.elements[id];
    }

    /**
     * Main toolbar div container
     */
    public getMainContainer(): HTMLDivElement {
        if (!this.container) {
            this.container = document.createElement('div');
        }
        return this.container;
    }

    /** get UI */
    public getUI(): HTMLElement {
        return this.getMainContainer();
    }

    /**
     * Update UI by adding all menutoolbar elements in main Container
     */
    public updateUI(): void {
        this.getMainContainer().innerHTML = '';
        Object.values(this.elements).forEach((element: MenuToolbarElement) => {
            this.getMainContainer().appendChild(element.getUI());
        });
    }
}
