/*
 * Copyright 2022, CS GROUP - France, https://www.csgroup.eu/
 *
 * This file is part of ToPaZ project: http://www.github.com/CS-SI/ToPaZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TpzValueEditor, TpzValueEditorFactory, TpzValueEditorOptions } from './tpz-value-editor';

export class TpzDefaultValueType {
    public static readonly VALUE_TYPE_REAL = 'REAL';
    public static readonly VALUE_TYPE_INTEGER = 'INTEGER';
    public static readonly VALUE_TYPE_BOOLEAN = 'BOOLEAN';
    public static readonly VALUE_TYPE_STRING = 'STRING';
    public static readonly VALUE_TYPE_STRING_ARRAY = 'STRING_ARRAY';
}

/** integer value editors */
export abstract class TpzNumberValueEditor extends TpzValueEditor<number> {
    private mainContainer: HTMLDivElement = null;
    private input: HTMLInputElement = null;

    /** Constructor */
    constructor(
        obj: any,
        property: string,
        set: (v: number) => void,
        get: () => number,
        eq: (v1: number, v2: number) => boolean,
        options: TpzValueEditorOptions<number> = {}
    ) {
        super(obj, property, set, get, eq, options);
    }

    /** create UI */
    public createUI(): HTMLElement {
        const ui: HTMLElement = this.getUI();
        this.updateUI();
        return ui;
    }

    /** create main UI */
    private getUI(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('tpz-editor');
            this.mainContainer.appendChild(this.getInput());
        }
        return this.mainContainer;
    }

    /** create input UI */
    private getInput(): HTMLInputElement {
        if (!this.input) {
            this.input = document.createElement('input');
            this.input.setAttribute('type', 'text');
            this.input.classList.add('tpz-editor');
            this.input.addEventListener('change', this.onUIValueChanged.bind(this));
        }
        return this.input;
    }

    /** update UI content */
    public updateUI(): boolean {
        this.getInput().value = String(this.getValue());
        return true;
    }

    /** callback when value changed */
    public onUIValueChanged(): boolean {
        try {
            const value: number = Number(this.input.value);
            return this.setValue(value);
        } catch (e) {
            return false;
        }
    }
}

/** integer value editors */
export class TpzIntegerValueEditor extends TpzNumberValueEditor {
    public static readonly INTEGER_VALUE_EDITOR_TYPE = 'INTEGER_VALUE_EDITOR_TYPE';

    /** Constructor */
    constructor(obj: any, property: string, options: TpzValueEditorOptions<number> = {}) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzIntegerValueEditor.compareInteger);
    }

    public static compareInteger(v1: number, v2: number): boolean {
        return v1 === v2;
    }

    /** Value Editor type */
    public getType(): string {
        return TpzIntegerValueEditor.INTEGER_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_INTEGER;
    }
}

/** real value editors */
export class TpzRealValueEditor extends TpzNumberValueEditor {
    public static readonly REAL_VALUE_EDITOR_TYPE = 'REAL_VALUE_EDITOR_TYPE';

    /** Constructor */
    constructor(obj: any, property: string, options: TpzValueEditorOptions<number> = {}) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzRealValueEditor.compareReal);
    }

    public static compareReal(v1: number, v2: number): boolean {
        return v1 === v2;
    }

    /** Value Editor type */
    public getType(): string {
        return TpzRealValueEditor.REAL_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_REAL;
    }
}

/** integer value editors */
export class TpzBooleanValueEditor extends TpzValueEditor<boolean> {
    public static readonly BOOLEAN_VALUE_EDITOR_TYPE = 'BOOLEAN_VALUE_EDITOR_TYPE';
    private mainContainer: HTMLDivElement = null;
    private input: HTMLInputElement = null;

    /** Constructor */
    constructor(obj: any, property: string, options: TpzValueEditorOptions<boolean> = {}) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzBooleanValueEditor.compareBoolean);
    }

    public static compareBoolean(v1: boolean, v2: boolean): boolean {
        return v1 === v2;
    }

    /** Value Editor type */
    public getType(): string {
        return TpzBooleanValueEditor.BOOLEAN_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_BOOLEAN;
    }

    /** create UI */
    public createUI(): HTMLElement {
        const ui: HTMLElement = this.getUI();
        this.updateUI();
        return ui;
    }

    /** create main UI */
    private getUI(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('tpz-editor');
            this.mainContainer.appendChild(this.getInput());
        }
        return this.mainContainer;
    }

    /** create input UI */
    private getInput(): HTMLInputElement {
        if (!this.input) {
            this.input = document.createElement('input');
            this.input.setAttribute('type', 'checkbox');
            this.input.classList.add('tpz-editor');
            this.input.addEventListener('change', this.onUIValueChanged.bind(this));
        }
        return this.input;
    }

    /** update UI content */
    public updateUI(): boolean {
        this.getInput().checked = this.getValue();
        return true;
    }

    /** callback when value changed */
    public onUIValueChanged(): boolean {
        try {
            const value: boolean = this.getInput().checked;
            return this.setValue(value);
        } catch (e) {
            return false;
        }
    }
}

export interface TpzValueEditorSliderOptions extends TpzValueEditorOptions<number> {
    rangeMin: number;
    rangeMax: number;
    rangeStep: number;
}

/** integer value editors */
export abstract class TpzNumberValueSliderEditor extends TpzValueEditor<number> {
    private mainContainer: HTMLDivElement = null;
    private inputSlider: HTMLInputElement = null;
    private inputValue: HTMLInputElement = null;

    /** Constructor */
    constructor(
        obj: any,
        property: string,
        set: (v: number) => void,
        get: () => number,
        eq: (v1: number, v2: number) => boolean,
        options: TpzValueEditorSliderOptions = { rangeMin: 0, rangeMax: 100, rangeStep: 0.01 }
    ) {
        super(obj, property, set, get, eq, options);
    }

    /** option getter specialization */
    public getOptions(): TpzValueEditorSliderOptions {
        return super.getOptions() as TpzValueEditorSliderOptions;
    }

    /** create UI */
    public createUI(): HTMLElement {
        const ui: HTMLElement = this.getUI();
        this.updateUI();
        return ui;
    }

    /** create main UI */
    private getUI(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('tpz-editor');
            const sliderDiv: HTMLDivElement = document.createElement('div');
            sliderDiv.style.display = 'inline-block';
            sliderDiv.style.width = '50%';
            sliderDiv.appendChild(this.getInputSlider());
            const inputDiv: HTMLDivElement = document.createElement('div');
            inputDiv.style.display = 'inline-block';
            inputDiv.style.width = '50%';
            inputDiv.appendChild(this.getInputValue());
            this.mainContainer.appendChild(sliderDiv);
            this.mainContainer.appendChild(inputDiv);
        }
        return this.mainContainer;
    }

    /** create input UI */
    private getInputValue(): HTMLInputElement {
        if (!this.inputValue) {
            this.inputValue = document.createElement('input');
            this.inputValue.setAttribute('type', 'text');
            this.inputValue.classList.add('tpz-editor-text');
            this.inputValue.addEventListener('change', this.onUITextValueChanged.bind(this));
        }
        return this.inputValue;
    }

    /** create input UI */
    private getInputSlider(): HTMLInputElement {
        if (!this.inputSlider) {
            this.inputSlider = document.createElement('input');
            this.inputSlider.setAttribute('type', 'range');
            this.inputSlider.setAttribute('min', this.getOptions().rangeMin + '');
            this.inputSlider.setAttribute('max', this.getOptions().rangeMax + '');
            this.inputSlider.setAttribute('step', this.getOptions().rangeStep + '');
            this.inputSlider.classList.add('tpz-editor-slider');
            this.inputSlider.addEventListener('change', this.onUISliderValueChanged.bind(this));
            if (this.getOptions().liveUpdate) {
                this.inputSlider.addEventListener('input', this.onUISliderValueChanged.bind(this));
            }
        }
        return this.inputSlider;
    }

    /** update UI content */
    public updateUI(): boolean {
        const v: number = this.getValue();
        this.getInputValue().value = String(v);
        this.getInputSlider().value = String(v);
        return true;
    }

    /** callback when value changed */
    public onUISliderValueChanged(): boolean {
        try {
            const value: number = Number(this.getInputSlider().value);
            return this.setValue(value);
        } catch (e) {
            return false;
        }
    }

    /** callback when value changed */
    public onUITextValueChanged(): boolean {
        try {
            const value: number = Number(this.getInputValue().value);
            return this.setValue(value);
        } catch (e) {
            return false;
        }
    }
}

/** real value editors */
export class TpzRealValueSliderEditor extends TpzNumberValueSliderEditor {
    public static readonly REAL_VALUE_EDITOR_TYPE = 'REAL_VALUE_EDITOR_TYPE';

    /** Constructor */
    constructor(
        obj: any,
        property: string,
        options: TpzValueEditorSliderOptions = { rangeMin: 0, rangeMax: 100, rangeStep: 1 }
    ) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzRealValueEditor.compareReal);
    }

    public static compareReal(v1: number, v2: number): boolean {
        return v1 === v2;
    }

    /** Value Editor type */
    public getType(): string {
        return TpzRealValueEditor.REAL_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_REAL;
    }
}

/** one line string value editors */
export class TpzStringValueEditor extends TpzValueEditor<string> {
    public static readonly STRING_VALUE_EDITOR_TYPE = 'STRING_VALUE_EDITOR_TYPE';

    private mainContainer: HTMLDivElement = null;
    private input: HTMLInputElement = null;

    /** Constructor */
    constructor(obj: any, property: string, options: TpzValueEditorOptions<string> = {}) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzStringValueEditor.compareString);
    }

    /** Value Editor type */
    public getType(): string {
        return TpzStringValueEditor.STRING_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_STRING;
    }

    public static compareString(v1: string, v2: string): boolean {
        return v1 === v2;
    }

    /** create UI */
    public createUI(): HTMLElement {
        const ui: HTMLElement = this.getUI();
        this.updateUI();
        return ui;
    }

    /** create main UI */
    private getUI(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('tpz-editor');
            this.mainContainer.appendChild(this.getInput());
        }
        return this.mainContainer;
    }

    /** create input UI */
    private getInput(): HTMLInputElement {
        const me = this;
        if (!this.input) {
            this.input = document.createElement('input');
            this.input.setAttribute('type', 'text');
            this.input.classList.add('tpz-editor');
            this.input.addEventListener('input', () => {
                if (!this.getOptions().liveUpdate) me.input.classList.add('modified');
                else me.onUIValueChanged?.call(me);
            });
            this.input.addEventListener('change', () => {
                me.onUIValueChanged?.call(me);
                me.input.classList.remove('modified');
            });
        }
        return this.input;
    }

    /** update UI content */
    public updateUI(): boolean {
        this.getInput().value = this.getValue();
        return true;
    }

    /** callback when value changed */
    public onUIValueChanged(): boolean {
        try {
            const value: string = this.input?.value;
            return this.setValue(value);
        } catch (e) {
            return false;
        }
    }
}

/** multi line string value editors */
export class TpzTextValueEditor extends TpzValueEditor<string> {
    public static readonly STRING_ARRAY_VALUE_EDITOR_TYPE = 'TEXT_VALUE_EDITOR_TYPE';

    private mainContainer: HTMLDivElement = null;
    private input: HTMLTextAreaElement = null;

    /** Constructor */
    constructor(obj: any, property: string, options: TpzValueEditorOptions<string> = {}) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzTextValueEditor.compareText);
    }

    /** Value Editor type */
    public getType(): string {
        return TpzTextValueEditor.STRING_ARRAY_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_STRING;
    }

    public static compareText(v1: string, v2: string): boolean {
        return v1 === v2;
    }

    /** create UI */
    public createUI(): HTMLElement {
        const ui: HTMLElement = this.getUI();
        this.updateUI();
        return ui;
    }

    /** create main UI */
    private getUI(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('tpz-editor');
            this.mainContainer.appendChild(this.getInput());
        }
        return this.mainContainer;
    }

    /** create input UI */
    private getInput(): HTMLTextAreaElement {
        const me = this;
        if (!this.input) {
            this.input = document.createElement('textarea');
            this.input.classList.add('tpz-editor');
            this.input.addEventListener('input', () => {
                if (!this.getOptions().liveUpdate) me.input.classList.add('modified');
                else me.onUIValueChanged?.call(me);
            });
            this.input.addEventListener('change', () => {
                me.onUIValueChanged?.call(me);
                me.input.classList.remove('modified');
            });
        }
        return this.input;
    }

    /** update UI content */
    public updateUI(): boolean {
        this.getInput().value = this.getValue() || '';
        return true;
    }

    /** callback when value changed */
    public onUIValueChanged(): boolean {
        try {
            const value: string = this.input?.value;
            return this.setValue(value);
        } catch (e) {
            return false;
        }
    }
}

/** string array value editors */
export class TpzStringArrayValueEditor extends TpzValueEditor<string[]> {
    public static readonly STRING_ARRAY_VALUE_EDITOR_TYPE = 'STRING_ARRAY_VALUE_EDITOR_TYPE';

    private mainContainer: HTMLDivElement = null;
    private input: HTMLTextAreaElement = null;

    /** Constructor */
    constructor(obj: any, property: string, options: TpzValueEditorOptions<string[]> = {}) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzStringArrayValueEditor.compareStringArray);
    }

    /** Value Editor type */
    public getType(): string {
        return TpzTextValueEditor.STRING_ARRAY_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_STRING_ARRAY;
    }

    public static compareStringArray(v1: string[], v2: string[]): boolean {
        if (!v1 && !v2) return true;
        if ((!v1 && v2) || (v1 && !v2)) return false;
        if (v1.length !== v2.length) return false;
        // compare all elements
        for (let i = 0; i < v1.length; i++) {
            if (v1[i] !== v2[i]) return false;
        }
        return true;
    }

    /** create UI */
    public createUI(): HTMLElement {
        const ui: HTMLElement = this.getUI();
        this.updateUI();
        return ui;
    }

    /** create main UI */
    private getUI(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('tpz-editor');
            this.mainContainer.appendChild(this.getInput());
        }
        return this.mainContainer;
    }

    /** create input UI */
    private getInput(): HTMLTextAreaElement {
        const me = this;
        if (!this.input) {
            this.input = document.createElement('textarea');
            this.input.classList.add('tpz-editor');
            this.input.addEventListener('input', () => {
                if (!this.getOptions().liveUpdate) me.input.classList.add('modified');
                else me.onUIValueChanged?.call(me);
            });
            this.input.addEventListener('change', () => {
                me.onUIValueChanged?.call(me);
                me.input.classList.remove('modified');
            });
        }
        return this.input;
    }

    /**
     * return the array of lines contained in input text area
     */
    private getInputLines(): string[] {
        return this.getInput().value?.split('\n');
    }

    /** update UI content */
    public updateUI(): boolean {
        this.getInput().value = this.getValue() ? this.getValue().join('\n') : '';
        return true;
    }

    /** callback when value changed */
    public onUIValueChanged(): boolean {
        try {
            const lines: string[] = this.getInputLines();
            return this.setValue(lines);
        } catch (e) {
            return false;
        }
    }
}

/** multi line string value editors */
export class TpzJsonValueEditor extends TpzValueEditor<any> {
    public static readonly JSON_VALUE_EDITOR_TYPE = 'JSON_EDITOR_TYPE';

    private mainContainer: HTMLDivElement = null;
    private input: HTMLTextAreaElement = null;

    /** Constructor */
    constructor(obj: any, property: string, options: TpzValueEditorOptions<any> = {}) {
        super(obj, property, null, null, null, options);
        this.setCompare(TpzTextValueEditor.compareText);
    }

    /** Value Editor type */
    public getType(): string {
        return TpzJsonValueEditor.JSON_VALUE_EDITOR_TYPE;
    }

    /** Edited Value type */
    public getValueType(): string {
        return TpzDefaultValueType.VALUE_TYPE_STRING;
    }

    public static compareText(v1: any, v2: any): boolean {
        return JSON.stringify(v1) === JSON.stringify(v2);
    }

    /** create UI */
    public createUI(): HTMLElement {
        const ui: HTMLElement = this.getUI();
        this.updateUI();
        return ui;
    }

    /** create main UI */
    private getUI(): HTMLDivElement {
        if (!this.mainContainer) {
            this.mainContainer = document.createElement('div');
            this.mainContainer.classList.add('tpz-editor');
            this.mainContainer.appendChild(this.getInput());
        }
        return this.mainContainer;
    }

    /** create input UI */
    private getInput(): HTMLTextAreaElement {
        const me = this;
        if (!this.input) {
            this.input = document.createElement('textarea');
            this.input.classList.add('tpz-editor');
            this.input.addEventListener('input', () => {
                if (!this.getOptions().liveUpdate) me.input.classList.add('modified');
                else me.onUIValueChanged?.call(me);
            });
            this.input.addEventListener('change', () => {
                me.onUIValueChanged?.call(me);
                me.input.classList.remove('modified');
            });
        }
        return this.input;
    }

    /** update UI content */
    public updateUI(): boolean {
        this.getInput().value = this.getValue() ? JSON.stringify(this.getValue(), null, 2) : '';
        return true;
    }

    /** callback when value changed */
    public onUIValueChanged(): boolean {
        try {
            const stringValue: string = this.input?.value;
            const value: any = stringValue ? JSON.parse(stringValue) : stringValue;
            return this.setValue(value);
        } catch (e) {
            return false;
        }
    }
}
